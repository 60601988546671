import React, { useEffect, useState } from 'react';
import CachedIcon from '@mui/icons-material/Cached';
import { Box, Card, CardContent, Divider, Grid, IconButton, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import RenderField from 'utils/RenderField';
import { submitStudLoanCal, submitStudLoanCalSuccess } from 'redux/actions/StudentLoanCalActions';
import { toast } from 'react-toastify';
import moment from 'moment';
import { getDefaultOverride } from 'redux/actions/GeneralCalActions';
import { getStudLoanSavedCal } from 'redux/actions/StudentLoanCalActions';
import { getStudLoanSavedCalSuccess } from 'redux/actions/StudentLoanCalActions';
import { getCookie } from 'utils/Utils';
import { CalCardActions } from 'assets/jss/RetirementStyle';
import { CustomButton } from 'assets/jss/ButtonStyle';
import { StudLabelGrid } from 'assets/jss/CommonCalStyle';
import { StudInputGrid } from 'assets/jss/CommonCalStyle';
const StudentLoanForm = ({ classes, studLoanData, studClasses, defaultoverrideData, ...rest }) => {
    const dispatch = useDispatch();
    const apiToken = getCookie('auth_token');
    const maxEnterYear = moment().add(20, 'years').year();
    const minEnterYear = moment().year();
    const [disabledLoadBtn, setDisabledLoadBtn] = useState(false);

    const {
        handleSubmit,
        control,
        formState: { isDirty, isSubmitting },
        setValue,
        getValues
    } = useForm({
        mode: 'onChange',
        defaultValues: {
            tuition: 50000,
            room_and_board: 18000,
            books_and_supplies: 3000,
            grad_inc: 50000,
            misc_exp: 2000,
            year_enter: 2028,
            stud_loan_pct: 55,
            max_fed_loans: 31000,
            school_term_years: 4,
            infl_rt: 3,
            fed_loans_rate: 4,
            private_loans_rate: 10,
            fed_loans_term: 10,
            private_loans_term: 15
        }
    });

    // to reset redux stored value of result when page is changing
    useEffect(() => {
        if (apiToken) dispatch(getStudLoanSavedCal());
        if (Object.keys(defaultoverrideData).length === 0) {
            dispatch(getDefaultOverride());
        }
        return () => {
            dispatch(submitStudLoanCalSuccess({}));
            dispatch(getStudLoanSavedCalSuccess({}));
        };
    }, []);

    // to load saved data in form
    const handleLoadData = () => {
        setDisabledLoadBtn(true);
        const savedData = rest.savedStudLoan;
        for (const key in savedData) {
            if (Object.hasOwnProperty.call(savedData, key)) {
                let element = savedData[key];
                setValue(key, element);
            }
        }
        onSubmit(savedData);
    };

    const onSave = (data) => {
        // if (!apiToken) {
        //     toast.error('Please login to save data!');
        //     return;
        // }
        let objToSend = {
            tuition: data.tuitionUnFormattedValue || data.tuition,
            room_and_board: data.room_and_boardUnFormattedValue || data.room_and_board,
            books_and_supplies: data.books_and_suppliesUnFormattedValue || data.books_and_supplies,
            grad_inc: data.grad_incUnFormattedValue || data.grad_inc,
            misc_exp: data.misc_expUnFormattedValue || data.misc_exp,
            year_enter: data.year_enter,
            stud_loan_pct: data.stud_loan_pctUnFormattedValue || data.stud_loan_pct,
            max_fed_loans: data.max_fed_loansUnFormattedValue || data.max_fed_loans,
            school_term_years: data.school_term_years,
            infl_rt: data.infl_rtUnFormattedValue || data.infl_rt,
            fed_loans_rate: data.fed_loans_rateUnFormattedValue || data.fed_loans_rate,
            private_loans_rate: data.private_loans_rateUnFormattedValue || data.private_loans_rate,
            fed_loans_term: data.fed_loans_termUnFormattedValue || data.fed_loans_term,
            private_loans_term: data.private_loans_termUnFormattedValue || data.private_loans_term,
            save_data: true
        };

        dispatch(submitStudLoanCal(objToSend));
    };

    //to submit the form data
    const onSubmit = (data) => {
        let objToSend = {
            tuition: data.tuitionUnFormattedValue || data.tuition,
            room_and_board: data.room_and_boardUnFormattedValue || data.room_and_board,
            books_and_supplies: data.books_and_suppliesUnFormattedValue || data.books_and_supplies,
            grad_inc: data.grad_incUnFormattedValue || data.grad_inc,
            misc_exp: data.misc_expUnFormattedValue || data.misc_exp,
            year_enter: data.year_enter,
            stud_loan_pct: data.stud_loan_pctUnFormattedValue || data.stud_loan_pct,
            max_fed_loans: data.max_fed_loansUnFormattedValue || data.max_fed_loans,
            school_term_years: data.school_term_years,
            infl_rt: data.infl_rtUnFormattedValue || data.infl_rt,
            fed_loans_rate: data.fed_loans_rateUnFormattedValue || data.fed_loans_rate,
            private_loans_rate: data.private_loans_rateUnFormattedValue || data.private_loans_rate,
            fed_loans_term: data.fed_loans_termUnFormattedValue || data.fed_loans_term,
            private_loans_term: data.private_loans_termUnFormattedValue || data.private_loans_term,
            save_data: false
        };
        dispatch(submitStudLoanCal(objToSend));
        dispatch(getStudLoanSavedCal());
    };
    // when any input field value will change it will call below method
    const handleFormInputChange = (e) => {
        if (disabledLoadBtn) {
            setDisabledLoadBtn(false);
        }
        if (isDirty && Object.keys(studLoanData).length > 0) {
            dispatch(submitStudLoanCalSuccess({}));
        }
    };

    useEffect(() => {
        // to load default data in form
        if (Object.keys(defaultoverrideData).length) {
            setValue('inflrt', defaultoverrideData.inflation);
        }
    }, [defaultoverrideData]);

    const onKeyPressHandler = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            onSubmit(getValues());
        }
    };
    return (
        <Card elevation={2}>
            <CardContent>
                {Object.keys(rest.savedStudLoan).length ? (
                    <Box color="text.primary">
                        <Typography variant="body2" component="p">
                            We have your saved data. Click button to load it.
                            <IconButton
                                color="primary"
                                aria-label="load data"
                                component="label"
                                disabled={disabledLoadBtn}
                                onClick={handleLoadData}
                            >
                                <CachedIcon color="white" />
                            </IconButton>
                        </Typography>
                        <Divider sx={{ mb: 1 }} />
                    </Box>
                ) : (
                    ''
                )}
                <form onKeyDown={onKeyPressHandler} onSubmit={handleSubmit(onSubmit)}>
                    <Grid container>
                        <StudLabelGrid item xs={12} md={5} lg={5}>
                            <Typography gutterBottom>School Term Years</Typography>
                        </StudLabelGrid>
                        <StudInputGrid item xs={12} md={7} lg={7}>
                            <RenderField
                                type="slider"
                                control={control}
                                name="school_term_years"
                                id="school_term_years"
                                max={10}
                                min={1}
                                valueLabelDisplay="on"
                                handleOnChange={handleFormInputChange}
                            />
                        </StudInputGrid>
                        <StudLabelGrid item xs={12} md={5} lg={5}>
                            <Typography gutterBottom>School Enter Years</Typography>
                        </StudLabelGrid>
                        <StudInputGrid item xs={12} md={7} lg={7}>
                            <RenderField
                                type="slider"
                                control={control}
                                name="year_enter"
                                id="year_enter"
                                max={maxEnterYear}
                                min={minEnterYear}
                                valueLabelDisplay="on"
                                handleOnChange={handleFormInputChange}
                            />
                        </StudInputGrid>
                        <Grid item xs={12} sm={6}>
                            <RenderField
                                handleOnChange={handleFormInputChange}
                                name="tuition"
                                control={control}
                                label="Tuition"
                                id="tuition"
                                type="number"
                                required
                                // prefix={"$"}
                                thousandSeparator={true}
                                customPatternName="decimalRate"
                                placeholder="100,000"
                                setValue={setValue}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <RenderField
                                handleOnChange={handleFormInputChange}
                                name="room_and_board"
                                control={control}
                                label="Room & Board"
                                id="room_and_board"
                                type="number"
                                required
                                // prefix={"$"}
                                thousandSeparator={true}
                                customPatternName="decimalRate"
                                placeholder="100,000"
                                setValue={setValue}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <RenderField
                                handleOnChange={handleFormInputChange}
                                name="books_and_supplies"
                                control={control}
                                label="Books & Supplies"
                                id="books_and_supplies"
                                type="number"
                                required
                                // prefix={"$"}
                                thousandSeparator={true}
                                customPatternName="decimalRate"
                                placeholder="100,000"
                                setValue={setValue}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <RenderField
                                handleOnChange={handleFormInputChange}
                                name="grad_inc"
                                control={control}
                                label="Graduation Income"
                                id="grad_inc"
                                type="number"
                                required
                                // prefix={"$"}
                                thousandSeparator={true}
                                customPatternName="decimalRate"
                                placeholder="100,000"
                                setValue={setValue}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <RenderField
                                handleOnChange={handleFormInputChange}
                                name="misc_exp"
                                control={control}
                                label="Miscellaneous Expenses"
                                id="misc_exp"
                                type="number"
                                required
                                thousandSeparator={true}
                                customPatternName="decimalRate"
                                placeholder="100,000"
                                setValue={setValue}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <RenderField
                                handleOnChange={handleFormInputChange}
                                name="stud_loan_pct"
                                control={control}
                                label="Student Loan %"
                                id="stud_loan_pct"
                                type="number"
                                required
                                // prefix={"$"}
                                thousandSeparator={true}
                                customPatternName="decimalRate"
                                placeholder="55"
                                setValue={setValue}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <RenderField
                                handleOnChange={handleFormInputChange}
                                name="max_fed_loans"
                                control={control}
                                label="Maximum Federal Loans"
                                id="max_fed_loans"
                                type="number"
                                required
                                // prefix={"$"}
                                thousandSeparator={true}
                                customPatternName="decimalRate"
                                placeholder="100,000"
                                setValue={setValue}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <RenderField
                                handleOnChange={handleFormInputChange}
                                name="infl_rt"
                                control={control}
                                label="Inflation Rate"
                                id="infl_rt"
                                type="number"
                                required
                                // prefix={"$"}
                                thousandSeparator={true}
                                customPatternName="decimalRate"
                                placeholder="5"
                                setValue={setValue}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <RenderField
                                handleOnChange={handleFormInputChange}
                                name="fed_loans_rate"
                                control={control}
                                label="Federal Loans Rate"
                                id="fed_loans_rate"
                                type="number"
                                required
                                // prefix={"$"}
                                thousandSeparator={true}
                                customPatternName="decimalRate"
                                placeholder="5"
                                setValue={setValue}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <RenderField
                                handleOnChange={handleFormInputChange}
                                name="private_loans_rate"
                                control={control}
                                label="Private Loans Rate"
                                id="private_loans_rate"
                                type="number"
                                required
                                // prefix={"$"}
                                thousandSeparator={true}
                                customPatternName="decimalRate"
                                placeholder="10"
                                setValue={setValue}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <RenderField
                                handleOnChange={handleFormInputChange}
                                name="fed_loans_term"
                                control={control}
                                label="Federal Loans Term"
                                id="fed_loans_term"
                                type="number"
                                required
                                // prefix={"$"}
                                thousandSeparator={true}
                                customPatternName="decimalRate"
                                placeholder="10"
                                setValue={setValue}
                                helperText={'(in years)'}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <RenderField
                                handleOnChange={handleFormInputChange}
                                name="private_loans_term"
                                control={control}
                                label="Private Loans Term"
                                id="private_loans_term"
                                type="number"
                                required
                                // prefix={"$"}
                                thousandSeparator={true}
                                customPatternName="decimalRate"
                                placeholder="15"
                                setValue={setValue}
                                helperText={'(in years)'}
                            />
                        </Grid>
                    </Grid>
                </form>
                <CalCardActions className="contactus-cardaction">
                    <Grid container style={{ width: '100%', textAlign: 'center' }}>
                        <Grid item xs={12} md={6}>
                            <CustomButton
                                type="submit"
                                ps={'9px 30px'}
                                variant="contained"
                                // onClick={() => setSaveData(false)}
                                onClick={() => {
                                    setValue('save_data', false);
                                    onSubmit(getValues());
                                }}
                                disabled={isSubmitting}
                            >
                                Calculate
                            </CustomButton>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <CustomButton
                                type="button"
                                ps={'9px 30px'}
                                variant="outlined"
                                onClick={() => {
                                    // setSaveData(true);
                                    setValue('save_data', true);
                                    onSave(getValues());
                                }}
                                disabled={isSubmitting}
                            >
                                Save
                            </CustomButton>
                        </Grid>
                    </Grid>
                </CalCardActions>
            </CardContent>
        </Card>
    );
};

export default StudentLoanForm;
