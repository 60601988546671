import { useRoutes } from 'react-router-dom';
// // routes
import { createBrowserHistory } from 'history';
import { useLocation } from 'react-router-dom';
import MainRoutes from './MainRoutes';
import { useEffect } from 'react';

// Can be used to manage navigation state outside of React components
// ex : Redux, Axios interceptors, ...
export const history = createBrowserHistory();
// // ==============================|| ROUTING RENDER ||============================== //
export default function ThemeRoutes() {
    let location = useLocation();
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        // Google Analytics
        // window.rg4js('trackEvent', {
        //     type: 'pageView',
        //     path: location.pathname
        // });
    }, [location]);
    return useRoutes(MainRoutes);
}

//code for sitemap generator
//  var a = MainRoutes.map((route) => {
//      console.log(route.path);
//      if (route.children) {
//          return route.children.map((v) => {
//              console.log(v?.path);
//              return '/' + v?.path;
//          });
//      }
//      return '/' + route.path;
//  });
//  a = a.flat(1);
//  const sitemap = `
//   <?xml version="1.0" encoding="UTF-8"?>
//   <urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
//     ${a
//         .map(
//             (path) => `
//       <url>
//         <loc>${`https://www.tpehub.com${path}`}</loc>
//       </url>
//     `
//         )
//         .join('')}
//   </urlset>
// `;