import { Autocomplete, Divider, Grid, TextField } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Tooltip from '@mui/material/Tooltip';
import AddIcon from '@mui/icons-material/Add';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { submitBudgetCalSuccess } from 'redux/actions/BudgetActions';
import { submitMutualFundTicker } from 'redux/actions/MutualFundActions';
import RenderField from 'utils/RenderField';
import CustomDialogTitle from 'components/DialogTitle';
import { CalCardActions } from 'assets/jss/RetirementStyle';
import { CustomButton } from 'assets/jss/ButtonStyle';
import { StyledAddRemoveIconBtn } from 'assets/jss/CommonCalStyle';

const AddNewTicker = (props) => {
    const { tickerList } = props;
    const [open, setOpen] = React.useState(false);
    const [tickerIndex, setIndexes] = useState([0]);
    const [counter, setCounter] = useState(0);
    const dispatch = useDispatch();
    const {
        handleSubmit,
        control,
        formState: { isSubmitting },
        setValue,
        reset
    } = useForm({
        mode: 'onChange'
    });
    const addNewLifeEvent = () => {
        const newCounter = counter + 1;
        setIndexes((prevIndexes) => [...prevIndexes, newCounter]);
        setCounter((prevCounter) => prevCounter + 1);
    };
    const removeLifeEvent = (index) => () => {
        setIndexes((prevIndexes) => [...prevIndexes.filter((item) => item !== index)]);
    };
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        reset({});
        setOpen(false);
        setIndexes([0]);
        setCounter(0);
    };
    //to submit the form data
    const onSubmit = (data) => {
        const tickerArr = [];
        tickerIndex.forEach((ele) => {
            if (data['ticker_' + ele]?.match(/[,"' ]+/g)) {
                let updatedVal = data['ticker_' + ele].replace(/[,"' ]+/g, ' ').trim();
                updatedVal = updatedVal.split(' ');
                updatedVal.forEach((subval) => {
                    tickerArr.push(subval);
                });
            } else {
                tickerArr.push(data['ticker_' + ele]);
            }
        });
        dispatch(submitMutualFundTicker({ tickers: tickerArr }, 'post'));
        handleClose();
    };

    useEffect(() => {
        // reset output when route will change
        return () => {
            dispatch(submitBudgetCalSuccess({}));
        };
    }, []);

    // const handleFormInputChange = (e) => {
    //   const val = e.target.value;
    //   console.log(val, val.match(/[,"' ]+/g));
    //   if (val.match(/[,"' ]+/g)){
    //     let updatedVal=val.replace(/[,"' ]+/g, " ").trim();
    //     updatedVal = updatedVal.split(" ");
    //     let tickerCounter = counter+1;
    //     updatedVal.forEach((subval,i) => {
    //       console.log(subval, tickerCounter, i, "ticker_" + tickerCounter , "updatedVal");
    //       setValue("ticker_" + tickerCounter, subval);
    //       tickerCounter++;
    //       addNewLifeEvent();
    //     });
    //   }
    // };
    return (
        <div>
            <Tooltip title="Add" arrow>
                <CustomButton style={{width:'100%'}} variant="text" startIcon={<AddIcon />} aria-label="add" onClick={handleClickOpen}>
                    Add New Ticker
                </CustomButton>
            </Tooltip>
            <Dialog open={open} onClose={handleClose} aria-labelledby="ticker-dialog-title">
                <CustomDialogTitle id="ticker-dialog-title" onClose={handleClose}>
                    Add Ticker(s)
                </CustomDialogTitle>
                <Divider />
                <DialogContent>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        {tickerIndex.map((index) => {
                            return (
                                <Grid container alignItems="baseline" key={'addnewticker' + index} marginBottom={2}>
                                    <Grid item xs={12} sx={{ display: 'flex' }}>
                                        {/* <RenderField
                                            // handleOnChange={handleFormInputChange}
                                            name={`ticker_${index}`}
                                            control={control}
                                            id={`ticker_${index}`}
                                            required
                                            placeholder="Ticker Name"
                                            type="text"
                                            label="Ticker"
                                            fieldName={'Ticker'}
                                            customPatternName={/^([a-z0-9A-Z,"' ]{1,})$/g}
                                            setValue={setValue}
                                        /> */}
                                        <Controller
                                            control={control}
                                            name={`ticker_${index}`}
                                            id={`ticker_${index}`}
                                            render={({ onChange, ...props }) => (
                                                <Autocomplete
                                                    style={{ width: '300px' }}
                                                    options={tickerList}
                                                    freeSolo
                                                    autoComplete
                                                    getOptionLabel={(option) =>
                                                        option.label?.toString() ? option.label?.toString() : option
                                                    }
                                                    renderInput={(params) => (
                                                        <TextField {...params} label="Tickers" placeholder="Tickers" />
                                                    )}
                                                    onInputChange={(event, newInputValue) => {
                                                        setValue(
                                                            `ticker_${index}`,
                                                            newInputValue.value ? newInputValue.value : newInputValue
                                                        );
                                                    }}
                                                    onChange={(e, data) => {
                                                        setValue(`ticker_${index}`, data.value ? data.value : data);
                                                    }}
                                                    {...props}
                                                />
                                            )}
                                        />
                                        {tickerIndex.length - 1 === tickerIndex.indexOf(index) && tickerIndex.length < 10 ? (
                                            <StyledAddRemoveIconBtn className={`addIcon`} onClick={addNewLifeEvent} disabled>
                                                <AddCircleIcon />
                                            </StyledAddRemoveIconBtn>
                                        ) : (
                                            ''
                                        )}
                                        {tickerIndex.length > 1 ? (
                                            // <GridItem
                                            //   xs={1}
                                            //   className={classes.removeIconButton}>
                                            <StyledAddRemoveIconBtn className={`removeIcon`} onClick={removeLifeEvent(index)}>
                                                <RemoveCircleIcon />
                                            </StyledAddRemoveIconBtn>
                                        ) : (
                                            // </GridItem>
                                            ''
                                        )}
                                    </Grid>
                                </Grid>
                            );
                        })}
                        <CalCardActions className="contactus-cardaction">
                            <CustomButton type="submit" ps={'9px 30px'} variant="contained" disabled={isSubmitting}>
                                Submit
                            </CustomButton>
                        </CalCardActions>
                    </form>
                </DialogContent>
            </Dialog>
        </div>
    );
};
export default AddNewTicker;
