import 'suneditor/dist/css/suneditor.min.css';
import { makeStyles } from '@mui/styles';
import { Box, Stack, Tooltip, Typography } from '@mui/material';
import Button from 'components/CustomButtons/Button';
import CustomInput from 'components/CustomInput/CustomInput';
import React, { useEffect, useState } from 'react';
import SunEditor from 'suneditor-react';
import { useDispatch, useSelector } from 'react-redux';
import { createNewBlog } from 'redux/actions/BlogTypes';
import Loader from 'components/CommonComponent/Loader';
import { fetchBlogById } from 'redux/actions/BlogTypes';
import { updateBlog } from 'redux/actions/BlogTypes';
import { Container } from '@mui/system';
import CustomSelect from 'components/CustomSelect/CustomSelect';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import blogPageStyle from 'assets/jss/material-kit-react/views/blogPage';
import { toast } from 'react-toastify';
import imageCompression from 'browser-image-compression';
import { compressUploadImage } from 'utils/Utils';

// import { Editor } from '@tinymce/tinymce-react';

const blogStyles = makeStyles(blogPageStyle);

const BlogEditor = ({ isUpdate, blogId, closeEditor }) => {
    const blogClasses = blogStyles();

    //init
    const dispatch = useDispatch();
    const { blogDetails, loader, blogCategoryList } = useSelector((state) => state.blogReducer);

    //states
    const [value, setValue] = useState('');
    const [title, setTitle] = useState('');
    const [titleError, setTitleError] = useState(false);

    const blogCategories = blogCategoryList.slice(1).map((category) => ({ label: category.name, value: category.id }));
    const [category, setCategory] = useState(blogCategories[0]?.value || '');
    const [tempCategory, setTempCategory] = useState('');
    const [newCategory, setNewCategory] = useState('');
    const [addNewCategory, setAddNewCategory] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedImagePreview, setSelectedImagePreview] = useState(null);
    // const [editorLoading, setEditorLoading] = useState(false);

    // constants
    const sortedFontOptions = [
        'Logical',
        'Salesforce Sans',
        'Garamond',
        'Sans-Serif',
        'Serif',
        'Times New Roman',
        'Helvetica',
        'Arial',
        'Comic Sans MS',
        'Courier New',
        'Impact',
        'Georgia',
        'Tahoma',
        'Trebuchet MS',
        'Verdana'
    ].sort();
    const buttonsList = [
        ['undo', 'redo'],
        ['font', 'fontSize'],
        ['paragraphStyle', 'blockquote'],
        ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
        ['fontColor', 'hiliteColor'],
        ['align', 'list', 'lineHeight'],
        ['outdent', 'indent'],
        ['table', 'horizontalRule', 'link', 'image', 'video'],
        ['fullScreen', 'showBlocks'],
        ['preview', 'print'],
        ['removeFormat']
    ];

    const handleTitleChange = (e) => {
        setTitleError(false);
        setTitle(e.target.value);
    };

    const handleTempCategoryChange = (event) => {
        setTempCategory(event.target.value);
    };

    const handleNewCat = () => {
        setNewCategory(tempCategory);
        toast.success('Category created successfully');
    };
    const handleCategorySelect = (e) => {
        setCategory(e.target.value);
    };

    const handleAddNewCatButton = () => {
        setAddNewCategory(!addNewCategory);
        setTempCategory('');
        setNewCategory('');
    };

    const compressImage = async (base64Image) => {
        const blob = await fetch(base64Image).then((res) => res.blob());
        const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1024
        };
        const compressedBlob = await imageCompression(blob, options);
        const compressedBase64 = await new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(compressedBlob);
            reader.onloadend = () => resolve(reader.result);
        });
        return compressedBase64;
    };

    const processContent = async (content) => {
        const imgRegex = /<img[^>]+src="([^">]+)"[^>]*>/g;
        let match;
        let newContent = content;

        while ((match = imgRegex.exec(content)) !== null) {
            const base64Image = match[1];
            const imgTag = match[0];

            if (base64Image.startsWith('data:image/')) {
                const compressedImage = await compressImage(base64Image);

                const newFileSize = base64ToBytes(compressedImage);

                const updatedImgTag = imgTag
                    .replace(base64Image, compressedImage)
                    .replace(/data-file-size="[^">]*"/, `data-file-size="${Math.round(newFileSize)}"`);

                newContent = newContent.replace(imgTag, updatedImgTag);
            }
        }

        return newContent;
    };

    const base64ToBytes = (base64) => {
        return (base64.length * 3) / 4 - (base64.endsWith('==') ? 2 : base64.endsWith('=') ? 1 : 0);
    };

    const handleSubmit = async (is_published = true) => {
        if (title.trim() === '') {
            setTitleError(true);
            return;
        }
        const processedContent = await processContent(value);

        let payload = {
            title: title.trim(),
            content: processedContent,
            is_published,
            // image_url: 'https://static-image-2.s3.us-west-1.amazonaws.com/notepad-laptop-concept.jpg'
            image_url: selectedImage ?? 'https://static-image-2.s3.us-west-1.amazonaws.com/women_2_finance.jpg'
        };
        if (newCategory) {
            payload = { ...payload, new_cat: newCategory };
        } else {
            payload = { ...payload, category };
        }
        if (isUpdate) {
            dispatch(updateBlog(blogId, payload, closeEditor));
        } else {
            dispatch(createNewBlog(payload, closeEditor));
        }
    };

    useEffect(() => {
        if (isUpdate) {
            const category = blogCategoryList.find((category) => category.name === blogDetails?.category_name);
            setValue(blogDetails?.content ?? '');
            setTitle(blogDetails?.title ?? '');
            setCategory(category?.id ?? '');
            setTitleError(false);
        }
    }, [blogDetails]);

    useEffect(() => {
        if (isUpdate) {
            dispatch(fetchBlogById(blogId));
        }
    }, [isUpdate, blogId]);

    const handleFileChange = async (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            try {
                if (selectedFile.type.startsWith('image/')) {
                    const compressedFiled = await compressUploadImage(selectedFile);
                    const imageUrl = URL.createObjectURL(compressedFiled);
                    setSelectedImagePreview(imageUrl);
                    setSelectedImage(imageUrl);
                } else {
                    setSelectedImagePreview(null);
                    setSelectedImage(null);
                    toast.error('Please select a valid image file.');
                }
            } catch (error) {
                console.error('Error compressing image:', error);
            }
        }
    };

    const handleButtonClick = () => {
        document.getElementById('fileInput').click();
    };

    return (
        <Container maxWidth="md">
            <Stack direction="row" justifyContent="space-between" alignItems="center" gap="30px">
                <Box width="100%">
                    <CustomInput
                        value={title}
                        labelText="Title"
                        CustomInputProps={{ width: '100%' }}
                        onChange={handleTitleChange}
                        error={titleError}
                        helperText={titleError ? 'Provide valid value for title' : null}
                    />
                </Box>
                <Box display="flex" flexDirection="row" gap="10px">
                    <Button
                        color="info"
                        size="xs"
                        onClick={() => {
                            handleSubmit(false);
                        }}
                    >
                        Save as Draft
                    </Button>
                    <Button
                        color="secondary"
                        size="xs"
                        onClick={() => {
                            handleSubmit(true);
                        }}
                    >
                        Publish
                    </Button>
                </Box>
            </Stack>
            <Box paddingTop="15px" display="flex" alignItems="start" gap="10px">
                <Typography variant="body1" marginTop="15px">
                    Category:{' '}
                </Typography>
                <Box style={{ width: '60%' }}>
                    {!addNewCategory && (
                        <CustomSelect
                            options={blogCategories}
                            disableEmptyValue={false}
                            onChange={handleCategorySelect}
                            defaultValue={category}
                            value={category}
                            disabled={addNewCategory}
                        />
                    )}
                    <Stack direction="row" alignItems="center" height={48}>
                        {addNewCategory ? (
                            <div className={blogClasses.deleteAddNewCatWapper}>
                                <CustomInput
                                    value={tempCategory}
                                    placeholder="Enter new category"
                                    onChange={handleTempCategoryChange}
                                    className={`${blogClasses.resizeSvg} deleteAddNewCat`}
                                    disabled={newCategory}
                                />
                                <div onClick={handleNewCat}>
                                    <Tooltip title="Add">
                                        <IconButton aria-label="close">
                                            <CheckCircleIcon style={{ color: !newCategory ? '' : 'green' }} />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                                <div onClick={handleAddNewCatButton}>
                                    <Tooltip title="Delete">
                                        <IconButton aria-label="close">
                                            <DeleteForeverIcon />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </div>
                        ) : (
                            <div className={blogClasses.deleteAddNewCatWapper} />
                        )}
                        {!addNewCategory && (
                            <div
                                onClick={handleAddNewCatButton}
                                style={{ margin: 'auto' }}
                                className={`${blogClasses.addNewCatBlock} ${blogClasses.resizeSvg}`}
                            >
                                <AddIcon />
                                <span>Add New Category</span>
                            </div>
                        )}
                    </Stack>
                </Box>
            </Box>
            <Stack direction="row" justifyContent="space-between" alignItems="center" paddingBottom="15px" marginTop="10px">
                <Box display="flex" flexDirection="row" gap="10px" alignItems="center">
                    <Typography>Cover Image: </Typography>
                    <Typography>{selectedImage ? selectedImage.name : 'image not uploaded'}</Typography>
                    <Button color="info" size="xs" onClick={handleButtonClick}>
                        Choose image
                    </Button>
                    <input
                        type="file"
                        accept="image/*"
                        id="fileInput"
                        style={{ display: 'none', maxWidth: '100%', height: 'auto' }}
                        onChange={handleFileChange}
                    />
                    <Button color="success" size="xs" disabled>
                        Upload
                    </Button>
                </Box>
            </Stack>

            {selectedImagePreview && (
                <Box style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
                    <img src={selectedImagePreview} alt="Selected" style={{ width: '200px', height: '200px', objectFit: 'contain' }} />
                </Box>
            )}
            <SunEditor
                setContents={value}
                onChange={setValue}
                setOptions={{
                    buttonList: buttonsList,
                    defaultTag: 'div',
                    minHeight: '600px',
                    showPathLabel: false,
                    font: sortedFontOptions,
                    linkNoPrefix: true
                }}
                placeholder="Start Blogging now, click here"
            />
            {/* {!editorLoading && <Loader />}
            <Editor
                apiKey="mz0n0rcjslwsxp53wa8qtk7mmydokzudxp723p71fzns5t4f"
                onEditorChange={(content) => {
                    setValue(content);
                }}
                value={value}
                initialValue="Start Blogging now, click here"
                onInit={() => setEditorLoading(true)}
                init={{
                    min_height: 600,
                    selector: 'textarea#file-picker',
                    // content_css: false,
                    plugins:
                        'image code   advlist  anchor  autolink autoresize autosave  charmap  code codesample directionality  emoticons    fullscreen importcss  insertdatetime link linkchecker lists media    nonbreaking pagebreak    preview quickbars save searchreplace table  template   visualblocks visualchars wordcount',
                    toolbar:
                        'undo redo | blocks | ' +
                        'bold italic forecolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | ' +
                        'removeformat | help',
                    image_title: true,
                    automatic_uploads: true,
                    file_picker_types: 'image',
                    file_picker_callback: (cb) => {
                        const input = document.createElement('input');
                        input.setAttribute('type', 'file');
                        input.setAttribute('accept', 'image/*');

                        input.addEventListener('change', (e) => {
                            const file = e.target.files[0];

                            const reader = new FileReader();
                            reader.addEventListener('load', () => {
                                cb(reader.result, { title: file.name });
                            });
                            reader.readAsDataURL(file);
                        });

                        input.click();
                    },
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }'
                }}
            /> */}

            {loader ? <Loader /> : ''}
        </Container>
    );
};

export default BlogEditor;
