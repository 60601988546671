import * as ActionTypes from "../actions/ActionTypes";

const initialState = {
  loader: false,
  peertopeerDetails: {},
  calData: {},
  indepcalData:{},
};

export const PeertoPeerCalReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SUBMIT_PEERTO_PEER_INIT:
    case ActionTypes.FETCH_PEERTO_PEER_COLL_INIT:
    case ActionTypes.SUBMIT_PEERTO_PEER_CAL_INIT:
      return { ...state, loader: true };
    case ActionTypes.FETCH_PEERTO_PEER_COLL_SUCCESS:
      return { ...state, loader: false, peertopeerDetails: action.data };
    case ActionTypes.SUBMIT_PEERTO_PEER_SUCCESS:
      return { ...state, loader: false, calData: action.data };
    case ActionTypes.SUBMIT_PEERTO_PEER_CAL_SUCCESS:
      return { ...state, loader: false, indepcalData: action.data };
    case ActionTypes.FETCH_PEERTO_PEER_COLL_ERROR:
    case ActionTypes.SUBMIT_PEERTO_PEER_ERROR:
    case ActionTypes.SUBMIT_PEERTO_PEER_CAL_ERROR:
      return { ...state, loader: false };
    default:
      return state;
  }
};
