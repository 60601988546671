import React from 'react';
import { Card, CardContent, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { QuesAnsBox } from 'assets/jss/RetirementStyle';
import { getCookie } from 'utils/Utils';
import CalSignupBlock from 'views/RetirementCalculator/CalSignupBlock';
const TaxEstiDesc = ({ classes, control, handleOnChange, setValue, ...rest }) => {
    return (
        <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12} md={(getCookie('auth_token') && 12) || 8}>
                <Card>
                    <CardContent>
                        <QuesAnsBox>
                            <Typography variant="h2" id="description" sx={{ mb: 2, display: 'flex' }}>
                                About tax estimator
                            </Typography>
                            <Typography variant="body1" className="dashboard-subheading" sx={{ mb: 1.5 }}>
                                This is a very simple Tax Estimator calculator allowing you to calculate how much you would owe in taxes for
                                the tax year you are interested in. We break your taxable income into long term capital gains/losses (taxes
                                for sales of investments) and ordinary income.
                            </Typography>
                            <Typography variant="body1" className="dashboard-subheading" sx={{ mb: 1.5 }}>
                                A fair warning, this is NOT meant to replace your normal tax advisors but rather to give you a rough
                                estimate of taxes owed. This will allow you to make additional tax payments (and avoid those nasty late fees
                                or interest). Our calculator is sufficient for those with relatively simple tax profile who want to get
                                rough estimate of what they owe. It is particularly useful for freelancers and the self employed who don’t
                                have taxes withheld and therefore risk large penalties if they don’t make quarterly estimates.
                            </Typography>
                            <Typography variant="body1" className="dashboard-subheading" sx={{ mb: 1.5 }}>
                                Our output shows your total taxes owed as well as the marginal (the highest tax rate you fall under) and
                                average tax rates (simply total taxes divided by your income). Since the US has a progressive tax system (ie
                                the US taxes higher income at a higher percentage) the marginal rate will always be higher than the average.
                            </Typography>
                            <Typography variant="body1" className="dashboard-subheading" sx={{ mb: 1.5 }}>
                                Our calculator takes into consideration your standard deduction (which is largely based on your marital
                                status).
                            </Typography>
                        </QuesAnsBox>
                    </CardContent>
                </Card>
            </Grid>
            {!getCookie('auth_token') && (
                <Grid item xs={12} md={4}>
                    <CalSignupBlock />
                </Grid>
            )}
        </Grid>
    );
};

export default TaxEstiDesc;
