import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { ResponsiveLine } from '@nivo/line';
import styles from 'assets/jss/material-kit-react/views/portfolioPage';
import Globals from 'Globals.js';
import moment from 'moment';
import { abbreviate_number, numberWithCommas, splitArrayIntoChunksOfLen } from 'utils/Utils';
import { Typography } from '@mui/material';

const useStyles = makeStyles(styles);
var dataArr = [];
const AreaChart = ({
    data,
    minVal,
    minValObj,
    legendName,
    enableArea,
    dateType,
    enablePointLabel,
    curveType,
    enablePoint,
    rotateLabel,
    customColor,
    ...rest
}) => {
    const hasWindow = typeof window !== 'undefined';
    const classes = useStyles();
    const [hideChart, setHideChart] = useState(false);
    const [graphColor, setGraphColor] = useState(Globals.Colors);
    const [refresh, setRefresh] = useState(false);
    const [minValue, setMinValue] = useState(minVal < 0 ? minVal.toString() : '0');
    let count = 20;
    const [chartSetup, setChartSetup] = useState(getWindowDimensions());
    useEffect(() => {
        customColor && setGraphColor(customColor);
    }, []);
    function getWindowDimensions() {
        const width = hasWindow ? window.innerWidth : null;
        var obj = {
            xAxisTickSlice: (data[0]?.data.length <= 3 && 0) || Math.round(data[0]?.data.length / 10) || 2,
            sliceArrCount: 3,
            legendHeight: 5,
            sliceLimitForLegendText: [14, 13],
            legendOffset: 60,
            tickRotation: 0,
            marginBottom: 0
        };
        if (rest.halfGraph) count += 35;
        if (width <= 1024 && width > 425) {
            obj = {
                ...obj,
                xAxisTickSlice: parseInt((data[0]?.data.length <= 3 && 0) || Math.round(data[0]?.data.length / 5)) || 2,
                // xAxisTickSlice: parseInt((data[0]?.data.length <= 3 && 0) || (Math.round(data[0]?.data.length / 5))) ,
                sliceArrCount: 2,
                legendOffset: 75
            };
            if (rotateLabel || rest.halfGraph) count += 12;
        } else if (width <= 425 && width > 375) {
            obj = {
                ...obj,
                xAxisTickSlice: (data[0]?.data.length <= 3 && 0) || Math.round(data[0]?.data.length / 5) || 5,
                sliceArrCount: 2,
                legendOffset: 75,
                tickRotation: rotateLabel || rest.halfGraph ? 35 : 0,
                marginBottom: rotateLabel || rest.halfGraph ? 20 : 0
            };
            if (rotateLabel || rest.halfGraph) count += 8;
        } else if (width <= 375 && width > 320) {
            obj = {
                ...obj,
                xAxisTickSlice: Math.round(data[0]?.data.length / 6) || 6,
                sliceArrCount: 2,
                legendOffset: 75,
                tickRotation: rotateLabel || rest.halfGraph ? 50 : 0,
                marginBottom: rotateLabel || rest.halfGraph ? 20 : 0
            };
            if (rotateLabel || rest.halfGraph) count += 8;
        } else if (width <= 320) {
            obj = {
                ...obj,
                xAxisTickSlice: Math.round(data[0]?.data.length / 5) || 5,
                sliceArrCount: 2,
                legendOffset: 75,
                sliceLimitForLegendText: [10, 9],
                tickRotation: rotateLabel || rest.halfGraph ? 60 : 0,
                marginBottom: rotateLabel || rest.halfGraph ? 30 : 0
            };
            if (rotateLabel || rest.halfGraph) count += 8;
        }
        if (rest.halfGraph) {
            obj = {
                ...obj,
                xAxisTickSlice: Math.round(data[0]?.data.length / 12),
                tickRotation: 50,
                marginBottom: 20
            };
            if (width <= 425 && width > 375) {
                obj = {
                    ...obj,
                    xAxisTickSlice: Math.round(data[0]?.data.length / 7)
                };
            } else if (width <= 375 && width > 320) {
                obj = {
                    ...obj,
                    xAxisTickSlice: Math.round(data[0]?.data.length / 6)
                };
            } else if (width <= 320) {
                obj = {
                    ...obj,
                    xAxisTickSlice: Math.round(data[0]?.data.length / 5)
                };
            }
        }
        return obj;
    }

    // create merged array which will include all value of x axis
    useEffect(() => {
        dataArr = [];
        data.map((val) => {
            dataArr = [...dataArr, ...val.data.map((item) => item.x)];
        });
        setRefresh(!refresh);
    }, [data]);

    useEffect(() => {
        if (hasWindow) {
            function handleResize() {
                setChartSetup(getWindowDimensions());
            }
            window.addEventListener('resize', handleResize);
            return () => window.removeEventListener('resize', handleResize);
        }
    }, [hasWindow]);

    //remove dublicate value from array
    let mergedDataset = [...new Set(dataArr)];

    // /**
    //  * to display tooltip on hover of the
    //  * @param {*} param
    //  * @returns
    //  */
    // const CustomTooltip = ({ point }) => {
    //   if (point) {
    //     const currValue = !dateType
    //       ? numberWithCommas(point.data.y.toFixed(2))
    //       : point.data.y;
    //     return (
    //         <div className={classes.customTooltip}>
    //             <Typography>{point.data.x}</Typography>
    //             <span className={classes.colorBox} style={{ background: point.serieColor }}></span>
    //             <p className={classes.tooltipLabel}>
    //                 {point.data.x} : <strong>{currValue}</strong>
    //             </p>
    //         </div>
    //     );
    //   }
    //   return null;
    // };

    /**
     * @author disha
     * @use to hide/unhide selected chart from list of area chart
     * @param {*} param0
     */
    const hideSelectedLegend = ({ id }) => {
        if (hideChart[id] === true) {
            //to display select chart back to page
            if (!dateType) {
                if (minValObj[id] === minVal && minVal < 0) {
                    // if selected chart's min value is same as overrall min value then to set back those value.
                    setMinValue(minVal.toString());
                }
            }
            setHideChart({ ...hideChart, [id]: false });
        } else {
            if (!dateType) {
                if (minValObj[id] === minVal && minVal < 0) {
                    // if selected chart's min value is same as overrall min value then to set to 0 to handle overflow of charts
                    setMinValue('0');
                }
            }
            // to hide chart from page
            setHideChart({ ...hideChart, [id]: true });
        }
    };

    const legendProps = {
        anchor: 'bottom',
        direction: 'row',
        itemWidth: 115,
        translateX: 0,
        translateY: 73,
        itemHeight: chartSetup.legendHeight,
        itemsSpacing: 0,
        itemOpacity: 0.8,
        symbolShape: 'square'
    };

    function xScale() {
        let result;
        if (mergedDataset && mergedDataset.length <= 5) {
            result = { type: 'point' };
        } else {
            result = rest.isDateAxis
                ? {
                      type: 'time',
                      format: '%Y-%m-%d',
                      precision: 'day'
                  }
                : { type: 'point' };
        }
        return result;
    }

    return (
        <ResponsiveLine
            animate={true}
            enableSlices={'x'}
            data={data.filter((val) => !hideChart[val.id])}
            xScale={xScale()}
            // xScale={{ type: 'point' }}
            // xScale={
            //     rest.isDateAxis
            //         ? {
            //               type: 'time',
            //               format: '%Y-%m-%d',
            //               precision: 'day'
            //           }
            //         : { type: 'point' }
            // }
            curve={curveType}
            enablePointLabel={enablePointLabel}
            margin={{ top: 20, right: 30, bottom: 90 + chartSetup.marginBottom || 0, left: 70 }}
            yScale={{
                type: 'linear',
                min: rest.areamin || minValue || 0,
                max: rest.rangeMax || 'auto'
            }}
            yFormat={!dateType ? ' >-.2f' : ''}
            axisTop={null}
            colors={graphColor}
            axisRight={null}
            enablePoints={enablePoint}
            // tooltip={(val) => CustomTooltip(val)}
            sliceTooltip={(d) => {
                const { slice } = d;
                return (
                    <div
                        style={{
                            background: 'white',
                            padding: '9px 12px',
                            border: '1px solid #ccc'
                        }}
                    >
                        {slice.points.length > 0 ? (
                            <Typography variant="subtitle1">
                                {rest.isDateAxis
                                    ? moment(slice.points[0].data.xFormatted).format('MM/DD/YYYY')
                                    : slice.points[0].data.xFormatted}
                            </Typography>
                        ) : (
                            ''
                        )}
                        {slice.points.map((point) => (
                            <>
                                <div
                                    key={point.id}
                                    style={{
                                        color: point.serieColor,
                                        padding: '3px 0'
                                    }}
                                >
                                    <strong>{point.serieId} :</strong> {point.data?.yFormatted}
                                </div>
                            </>
                        ))}
                    </div>
                );
            }}
            axisBottom={{
                format: (values) => {
                    if (rest.isDateAxis) {
                        return (rest?.annualData && moment(values).format('YY, MMM')) || moment(values).format('MMM DD');
                    } else {
                        // to skip/hide value to overcome overlap issue on xaxis
                        if (mergedDataset.indexOf(values) % chartSetup.xAxisTickSlice === 0) {
                            return values;
                        } else return '';
                    }
                },

                orient: 'bottom',
                tickSize: 5,
                tickPadding: 5,
                tickRotation: chartSetup.tickRotation,
                legendOffset: chartSetup.legendOffset,
                legendPosition: 'middle',
                legend: legendName
            }}
            enableGridX={false}
            axisLeft={{
                format: (tick) => (rest.currencyAbb ? numberWithCommas(abbreviate_number(tick)) : numberWithCommas(tick.toFixed(2))),
                orient: 'left',
                legend: rest.leftLegendName,
                tickSize: 5,
                legendOffset: -40,
                tickPadding: 5,
                tickRotation: 0,
                legendPosition: 'middle'
            }}
            pointSize={10}
            pointColor={{ theme: 'background' }}
            pointBorderWidth={2}
            pointBorderColor={{ from: 'serieColor' }}
            pointLabelYOffset={-12}
            enableArea={enableArea}
            // enableSlices={false}
            areaBaselineValue={40}
            lineWidth={3}
            areaOpacity={0.8}
            useMesh={true}
            legends={splitArrayIntoChunksOfLen(data, chartSetup.sliceArrCount).map((fillData, i) => {
                count += 20;
                return {
                    ...legendProps,
                    symbolSize: 10,
                    itemsSpacing: 0,
                    translateY: count,
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemTextColor: '#000',
                                itemBackground: 'rgba(0, 0, 0, .03)',
                                itemOpacity: 1
                            }
                        }
                    ],
                    data: fillData.map((cur, index) => ({
                        id: cur.id,
                        label:
                            cur.id?.length > chartSetup.sliceLimitForLegendText[0]
                                ? cur.id.slice(0, chartSetup.sliceLimitForLegendText[1]) + '...'
                                : cur.id,
                        color: splitArrayIntoChunksOfLen(graphColor, chartSetup.sliceArrCount)[i]?.[index]
                    })),
                    onClick: (d) => hideSelectedLegend(d)
                };
            })}
        />
    );
};
AreaChart.defaultProps = {
    minVal: 0,
    legendName: '',
    leftLegendName: '',
    enableArea: true,
    dateType: false,
    enablePointLabel: false,
    curveType: 'cardinal',
    enablePoint: true,
    rotateLabel: false,
    isDateAxis: false,
    currencyAbb: false
};
export default AreaChart;
