import React, { useEffect, useState } from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { CustomButton } from 'assets/jss/ButtonStyle';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Label } from 'recharts';

const formatValue = (value) => {
    if (value >= 1e9) {
        return `${(value / 1e9).toFixed(1)}B`;
    } else if (value >= 1e6) {
        return `${(value / 1e6).toFixed(1)}M`;
    } else if (value >= 1e3) {
        return `${(value / 1e3).toFixed(1)}K`;
    }
    return value;
};

export default function BiaxialDebtChart(props) {
    const { debtDetail } = props.debt;
    const [data, setData] = useState([]);
    const [chartWidth, setChartWidth] = useState(window.innerWidth * 0.8);

    useEffect(() => {
        const handleResize = () => {
            setChartWidth(window.innerWidth * 0.8);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        handleData();
    }, []);

    const handleData = () => {
        const cumulativeInterestArr = [];
        const lifetimeBalanceArr = [];
        const arr = [];
        const dt = debtDetail.debt_table;
        let length;
        for (let i = 0; i < dt.length; i++) {
            if (props.enableChart.id === dt[i].id) {
                length = i + 1;
            }
        }
        for (let obj of debtDetail['Cumulative Interest Array']) {
            for (let key of Object.keys(obj)) {
                if (key !== 'label') {
                    const newKey = props.enableChart.debttype + ' ' + length;
                    if (newKey === key) {
                        cumulativeInterestArr.push(obj[key]);
                    }
                }
            }
        }
        for (let obj of debtDetail['Lifetime Balance']) {
            for (let key of Object.keys(obj)) {
                if (key !== 'label') {
                    const newKey = props.enableChart.debttype + ' ' + length;
                    if (newKey === key) {
                        lifetimeBalanceArr.push(obj[key]);
                    }
                }
            }
        }

        const minLength = Math.min(cumulativeInterestArr.length, lifetimeBalanceArr.length);

        for (let i = 0; i < minLength; i++) {
            const obj = {
                name: String(i),
                'Total Interest': cumulativeInterestArr[i],
                'Loan Balance': lifetimeBalanceArr[i]
            };
            arr.push(obj);
        }
        setData(arr);
    };

    return (
        <>
            <CustomButton
                variant="text"
                startIcon={<ArrowBackIosIcon />}
                className="read-more back-button"
                onClick={() => props.backButtonClicked()}
                style={{ color: 'black' }}
            >
                Back to table
            </CustomButton>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div style={{ display: 'flex', alignItems: 'center', transform: 'rotate(-90deg)', color: 'gray', textWrap: 'nowrap' }}>
                    Loan Balance
                </div>
                <div style={{ width: '100%', margin: '0px -30px' }}>
                    <LineChart width={chartWidth} height={400} data={data}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" height={80}>
                            <Label value="Months" />
                        </XAxis>
                        <YAxis yAxisId="left" tickFormatter={formatValue}></YAxis>
                        <YAxis yAxisId="right" orientation="right" tickFormatter={formatValue}></YAxis>
                        <Tooltip />
                        <Legend />
                        <Line yAxisId="left" type="monotone" dataKey="Loan Balance" stroke="blue" activeDot={{ r: 8 }} />
                        <Line yAxisId="right" type="monotone" dataKey="Total Interest" stroke="orange" />
                    </LineChart>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', transform: 'rotate(-90deg)', color: 'gray', textWrap: 'nowrap' }}>
                    Total Interest
                </div>
            </div>
        </>
    );
}
