import AddIcon from '@mui/icons-material/Add';
import { Box, Divider, Grid, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import Tooltip from '@mui/material/Tooltip';
import { CustomButton } from 'assets/jss/ButtonStyle';
import { StyledDialogContent } from 'assets/jss/GeneralStyle';
import { CalCardActions } from 'assets/jss/RetirementStyle';
import CustomDialogTitle from 'components/DialogTitle';
import { CurrencyList } from 'Currency.json';
import React, { useEffect, useState, memo } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { fetchHomePurchFiles } from 'redux/actions/HomeCalActions';
import { UpdateSelectedHomePurch } from 'redux/actions/HomeCalActions';
import { smartTblPortfolio, smartTblPortfolioError } from 'redux/actions/PortfolioActions';
import RenderField from 'utils/RenderField';
import { dynamicSort } from 'utils/Utils';

const AddUserDialog = (props) => {
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();
    const homecal = useSelector((state) => state.homecal);
    const fileOptions = () => {
        return props.list.map((val) => ({ value: val.id, label: val.file_name }));
    };

    useEffect(() => {
        if (props?.open == true) {
            setOpen(true);
        }
    }, [props?.open]);

    const [fieldsDetail, setfieldsDetail] = useState([
        { name: 'id', label: 'Home Purchase File', type: 'select', required: true, disableEmptyValue: true },
        {
            name: 'file_name',
            label: 'File Name',
            type: 'text',
            required: true,
            commaSep: true,
            custompattern: /^([a-z0-9A-Z#*&_ ]{1,20})$/g,
            placeholder: 'Enter text'
        }
    ]);

    const {
        handleSubmit,
        control,
        formState: { isSubmitting },
        setValue,
        reset
    } = useForm({
        mode: 'onChange'
    });
    const handleClickOpen = () => setOpen(true);
    const handleClose = () => {
        reset({});
        props?.setIsOpen && props.setIsOpen(false);
        setOpen(false);
    };
    useEffect(() => {
        // reset all state flag whenevery form is submitted
        if (homecal?.updateSuccess === true) {
            handleClose();
            dispatch(fetchHomePurchFiles());
        }
    }, [homecal?.updateSuccess]);
    const onSubmit = (data) => {
        const objToPass = {
            new_file_name: data.file_name,
            id: data.id
        };
        dispatch(UpdateSelectedHomePurch(objToPass, 'put'));
    };

    return (
        <Box>
            {!props.open && (
                <Tooltip title="Add" arrow>
                    <CustomButton style={{ width: '100%' }} variant="text" onClick={handleClickOpen}>
                        Rename Files
                    </CustomButton>
                </Tooltip>
            )}
            <Dialog
                sx={{
                    '& .css-17i04tt-MuiPaper-root-MuiDialog-paper': {
                        maxWidth: '338px',
                        maxHeight: '270px',
                        minHeight: '270px'
                    }
                }}
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
            >
                <CustomDialogTitle id="form-dialog-title" onClose={handleClose}>
                    Rename Home Purchase files
                </CustomDialogTitle>
                <Divider />
                <StyledDialogContent>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container>
                            {fieldsDetail.map((val, i) => {
                                const optionsList = val.name === 'id' ? fileOptions() : val.options;
                                return (
                                    <Grid item xs={12} sm={12} key={i + 'addUserDialog'}>
                                        <RenderField
                                            name={val.name}
                                            control={control}
                                            label={val.label}
                                            id={val.name}
                                            type={val.type}
                                            options={optionsList}
                                            required={val.required || false}
                                            thousandSeparator={val.commaSep}
                                            customPatternName={val.custompattern}
                                            placeholder={val.placeholder}
                                            setValue={setValue}
                                            renderOption={(props, option) => {
                                                return (
                                                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                        {option.flag !== '' ? (
                                                            <img
                                                                loading="lazy"
                                                                width="20"
                                                                src={'img/flags/' + option.flag}
                                                                alt="country flags"
                                                            />
                                                        ) : (
                                                            ''
                                                        )}
                                                        <Typography variant="body2">{option.label}</Typography>
                                                    </Box>
                                                );
                                            }}
                                            {...val}
                                        />
                                    </Grid>
                                );
                            })}
                        </Grid>
                        {/* <CalCardActions className="contactus-cardaction"> */}
                        <Grid container direction="row" style={{ width: '90%', textAlign: 'center', position: 'absolute', bottom: '25px' }}>
                            <Grid item xs={12}>
                                <CustomButton type="submit" variant="contained" disabled={isSubmitting}>
                                    Submit
                                </CustomButton>
                            </Grid>
                        </Grid>
                        {/* </CalCardActions> */}
                    </form>
                </StyledDialogContent>
            </Dialog>
        </Box>
    );
};
export default memo(AddUserDialog);
