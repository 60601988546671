import * as ActionTypes from "../actions/ActionTypes";

const initialState = {
  investDashboardDetail: {},
  loader: false,
};

export const InvestDashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_INVEST_DASHBOARD_INIT:
      return { ...state, loader: true };
    case ActionTypes.FETCH_INVEST_DASHBOARD_SUCCESS:
      return {
        ...state,
        loader: false,
        investDashboardDetail: { data:action.data.data.data, sync_date: action.data.data.sync_date },
      };
    case ActionTypes.FETCH_INVEST_DASHBOARD_ERROR:
      return { ...state, loader: false };

    default:
      return state;
  }
};
