import React, { useEffect, useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import HouseAffordCalForm from './HouseAffordCalForm.jsx';
import CommonCalculator from 'components/CommonComponent/CommonCalculator.js';
import { useDispatch, useSelector } from 'react-redux';
import Loader from 'components/CommonComponent/Loader.js';
import CalDescription from 'CalDescription';
import { Container, Grid, Typography } from '@mui/material';
import { MainBox } from 'assets/jss/GeneralStyle.jsx';
import HouseAffordCalOutput from './HouseAffordCalOutput.jsx';
import HouseAffordCalDesc from './HouseAffordCalDesc.jsx';
import { CustomListItem } from 'assets/jss/HeaderStyle.jsx';
import { CustomListItemText } from 'assets/jss/HeaderStyle.jsx';
import { WidgetBox } from 'assets/jss/GeneralStyle.jsx';
import AccessControl from 'views/Authorization/AccessControl.js';
import PermissionDenied from 'views/Authorization/PermissionDenied.js';
import { fetchLoanDashBoardData } from 'redux/actions/LoanDashBoardAction.js';
import Meta from 'components/meta.jsx';
export default function HouseAffordCalculator() {
    const dispatch = useDispatch();
    const houseAffordData = useSelector((state) => state.houseAffordCal);
    const profileDetail = useSelector((state) => state.setting.profileDetail);
    const [annualIncome, setAnnualIncome] = useState(50000);
    const loanDashData = useSelector((state) => state.loanDashboard);

    const typographyRef = useRef(null);
    useEffect(() => {
        // Set the ID after the component mounts
        if (typographyRef.current) {
            typographyRef.current.id = 'homeAffCal';
        }
    }, []);

    const loanList =
        loanDashData?.loanDashboardDetail?.data?.slice(0, 2)?.map((val) => ({
            label: `${val.mort_label} (${val.mort_Values})`,
            value: val.mort_Values
        })) || [];
    useEffect(() => {
        if (profileDetail?.annualincome != 0 || profileDetail?.annualincome != undefined) {
            setAnnualIncome(profileDetail?.annualincome);
        }
        dispatch(fetchLoanDashBoardData());
    }, []);
    // console.log(profileDetail, "22")
    const render = () => {
        return (
            <>
                {houseAffordData.loader ? <Loader /> : ''}
                <AccessControl>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={4.5} className="widget-grid-item">
                            <HouseAffordCalForm houseAffordData={houseAffordData} annualIncome={annualIncome} mortgage_rate={loanList} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={7.5} className="widget-grid-item">
                            <HouseAffordCalOutput houseAffordData={houseAffordData} />
                        </Grid>
                    </Grid>
                </AccessControl>
            </>
        );
    };
    const breadcrumbs = [
        <CustomListItem className="cal-banner-menu" key="1" sx={{ m: 0 }} color="text.light">
            <CustomListItemText primary={'Home'} />
        </CustomListItem>,
        <CustomListItem className="cal-banner-menu" key="2" sx={{ m: 0 }} color="text.light">
            <CustomListItemText primary={'Real Estate'} />
        </CustomListItem>,
        <Typography ref={typographyRef} key="3" color="text.light" className="current-menu">
            House Affordability Calculator
        </Typography>
    ];
    return (
        <>
            <Meta
                title="House Affordability Calculator | TPE Hub"
                description="Calculate how much house you can afford with our House Affordability Calculator. Enter your income, expenses, and other financial details to get an estimate of the price range of homes that fit your budget. Make informed home buying decisions with ease."
                page="/house-affordability-calculator"
            />
            <CommonCalculator
                breadcrumbs={breadcrumbs}
                variantText="House Affordability Calculator"
                desc={CalDescription['House Affordability Calculator']}
            />
            <MainBox>
                <Container maxWidth="lg">
                    {render()}
                    <HouseAffordCalDesc />
                </Container>
            </MainBox>
        </>
    );
}
