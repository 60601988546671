import React, { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import { Box, Divider, Grid, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/system';
import { DashGrid, PricingBox, PricingBtn, PricingCard, PricingCardContent } from 'assets/jss/PricingStyle';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getSubcriptions } from 'redux/actions/SettingActions';
const PricingBlock = ({ classes, classNames }) => {
    const theme = useTheme();
    const dispatch = useDispatch();

    const setting = useSelector((state) => state.setting);
    const matches = useMediaQuery(theme.breakpoints.down('md'));
    useEffect(() => {
        /// to fetch the default permission list and subscription list values on page load
        dispatch(getSubcriptions());
    }, []);
    return (
        <PricingBox>
            <Typography variant="h3" sx={{ mt: 3.5, display: 'inline-block' }}>
                Our Pricing Plans
            </Typography>
            <Typography variant="subheading2" className="dashboard-subheading" sx={{ mb: 1.5 }}>
                No complex plans and No hidden fees.
            </Typography>
            <Grid container spacing={2} className="grid-container">
                {setting.subscriptionList.map((val, i) => {
                    return (
                        <DashGrid item xs={12} sm={2} md={3} key={i + '_pricingouter'}>
                            <PricingCard>
                                <PricingCardContent>
                                    <Typography variant="h5" component="div" className="title">
                                        {val.name}
                                    </Typography>
                                    <Typography variant="h2" className="sub-title" sx={{ display: 'block' }}>
                                        $ {val.price_monthly}
                                        <Typography variant="subtitle" className="sub-period">
                                            /monthly
                                        </Typography>
                                    </Typography>
                                    <Divider sx={{ mb: 1 }} />
                                    <Box className="price-feature-box" display={'grid'}>
                                        {val.plan_include.slice(0, 2).map((innerval, innerindex) => {
                                            return (
                                                <Typography variant="body1" key={innerindex + 'pricing_innnerval'}>
                                                    <CheckCircleIcon />
                                                    {innerval}
                                                </Typography>
                                            );
                                        })}
                                        <Typography variant="body1" color={'black'}>
                                            <DoubleArrowIcon />
                                            Many More features...
                                        </Typography>
                                    </Box>
                                    <PricingBtn variant="contained" component={Link} to="/subscription">
                                        Get started free
                                    </PricingBtn>
                                </PricingCardContent>
                            </PricingCard>
                        </DashGrid>
                    );
                })}
                {/* <DashGrid item xs={12} sm={2} md={3}>
                    <PricingCard id="heyyou">
                        <PricingCardContent>
                            <Typography gutterBottom variant="h2" component="div" className="title" sx={{ mb: 2 }}>
                                Silver
                            </Typography>
                            <Typography variant="subheading2" className="sub-title" sx={{ mb: 1, display: 'block' }}>
                                $ 3{' '}
                                <Typography variant="subheading2" className="sub-period">
                                    /monthly
                                </Typography>
                            </Typography>
                            <Divider sx={{ mb: 2.5 }} />
                            <Box className="price-feature-box">
                                <Typography variant="h5">
                                    <CheckCircleIcon />
                                    Free for life time
                                </Typography>
                                <Typography variant="h5">
                                    <CheckCircleIcon />
                                    Free for life time
                                </Typography>
                                <Typography variant="h5">
                                    <CheckCircleIcon />
                                    Free for life time
                                </Typography>
                            </Box>
                            <PricingBtn variant="contained">Get started free</PricingBtn>
                        </PricingCardContent>
                    </PricingCard>
                </DashGrid>
                <DashGrid item xs={12} sm={2} md={3}>
                    <PricingCard>
                        <PricingCardContent>
                            <Typography gutterBottom variant="h2" component="div" className="title" sx={{ mb: 2 }}>
                                Gold
                            </Typography>
                            <Typography variant="subheading2" className="sub-title" sx={{ mb: 1, display: 'block' }}>
                                $ 10{' '}
                                <Typography variant="subheading2" className="sub-period">
                                    /monthly
                                </Typography>
                            </Typography>
                            <Divider sx={{ mb: 2.5 }} />
                            <Box className="price-feature-box">
                                <Typography variant="h5">
                                    <CheckCircleIcon />
                                    Free for life time
                                </Typography>
                                <Typography variant="h5">
                                    <CheckCircleIcon />
                                    Free for life time
                                </Typography>
                                <Typography variant="h5">
                                    <CheckCircleIcon />
                                    Free for life time
                                </Typography>
                            </Box>
                            <PricingBtn variant="contained">Get started free</PricingBtn>
                        </PricingCardContent>
                    </PricingCard>
                </DashGrid>
                <DashGrid item xs={12} sm={2} md={3}>
                    <PricingCard id="heyyou">
                        <PricingCardContent>
                            <Typography gutterBottom variant="h2" component="div" className="title" sx={{ mb: 2 }}>
                                Platinum
                            </Typography>
                            <Typography variant="subheading2" className="sub-title" sx={{ mb: 1, display: 'block' }}>
                                $ 200{' '}
                                <Typography variant="subheading2" className="sub-period">
                                    /monthly
                                </Typography>
                            </Typography>
                            <Divider sx={{ mb: 2.5 }} />
                            <Box className="price-feature-box">
                                <Typography variant="h5">
                                    <CheckCircleIcon />
                                    Free for life time
                                </Typography>
                                <Typography variant="h5">
                                    <CheckCircleIcon />
                                    Free for life time
                                </Typography>
                                <Typography variant="h5">
                                    <CheckCircleIcon />
                                    Free for life time
                                </Typography>
                            </Box>
                            <PricingBtn variant="contained">Get started free</PricingBtn>
                        </PricingCardContent>
                    </PricingCard>
                </DashGrid> */}
            </Grid>
        </PricingBox>
    );
};

export default PricingBlock;
