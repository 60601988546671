import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Slide from '@mui/material/Slide';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import RenderField from 'utils/RenderField';
import { invertColor } from 'utils/Utils';
import AccessControl from 'views/Authorization/AccessControl';
import PermissionDenied from 'views/Authorization/PermissionDenied';
import CustomDialogTitle from 'components/DialogTitle';
import { CalCardActions } from 'assets/jss/RetirementStyle';
import { CustomButton } from 'assets/jss/ButtonStyle';
import { Grid } from '@mui/material';
import { TodoPriorityPaper } from 'assets/jss/TodoStyle';
import { useTheme } from '@mui/styles';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const PriorityList = [
    { value: 'high', label: 'High', icon: 'high-prio-20.png' },
    { value: 'medium', label: 'Medium', icon: 'medium-prio-20.png' },
    { value: 'low', label: 'Low', icon: 'low-prio-20.png' }
];
export const TodoForm = (props) => {
    const { classes } = props;
    const theme = useTheme();
    const { secondary } = theme.palette;
    const [selectedPrio, setSelectedPrio] = useState('medium');
    const [formTitle, setFormTitle] = useState('Create Todo');
    const [enableAddCat, setEnableAddCat] = useState(false);
    const [selectedColor, setColorSelection] = useState('#FFFFFF');
    var yesterday = moment().subtract(1, 'day');
    const [formName, setFormName] = useState('');

    const getElementsByIds = (ids) => {
        const elements = {};
        ids.forEach((id) => {
            elements[id] = document.getElementById(id);
        });
        return elements;
    };
    useEffect(() => {
        const elements = getElementsByIds([
            'taxEst',
            'budCal',
            'budAna',
            'tipsCal',
            'comCal',
            'recCal',
            'retCal',
            'portVal',
            'portAll',
            'bondCal',
            'orderCal',
            'mtFund',
            'clgCal',
            'fxCal',
            'peerCal',
            'stdCal',
            'debt',
            'cred',
            'mrCal',
            'lifeCal',
            'annCal',
            'homeCal',
            'homePuchCal',
            'homeAffCal',
            'propCal',
            'loanDash',
            'eqDash',
            'fixInc',
            'ecDash',
            'fnTodo',
            'knowReasch'
        ]);

        Object.keys(elements).forEach((key) => {
            if (elements[key]) {
                setFormName(elements[key].textContent);
            }
        });
    }, []);

    const frequency = [
        { id: 'D', name: 'Daily' },
        { id: 'W', name: 'Weekly' },
        { id: 'M', name: 'Monthly' }
    ];

    const {
        handleSubmit,
        control,
        formState: { isSubmitting },
        setValue
    } = useForm({
        mode: 'onChange',
        defaultValues: {
            name: props?.edit?.name ?? formName,
            progress:
                props.edit?.progress ||
                (props.addTodoPopup.name === 'progress' && 50) ||
                (props.addTodoPopup.name === 'completed' && 100) ||
                0,
            category: props.edit?.category?.id || 0,
            title: props.edit.title || '',
            status: props?.edit?.frequency || undefined
        }
    });

    useEffect(() => {
        if (formName) {
            setValue('name', props?.edit?.name ?? formName);
        }
    }, [props?.edit?.name || formName, setValue]);

    useEffect(() => {
        // if popup is open as edit form then set values
        if (Object.keys(props.edit).length > 0) {
            const color = props.edit.color ? props.edit.color : '#FFFFFF';
            setColorSelection(color);
            setFormTitle('Edit Todo');
            setValue('id', props.edit.id);
            setSelectedPrio(props.edit.priority);
        }
    }, [props.edit]);

    useEffect(() => {
        // reset all state flag whenevery form is submitted
        if (props.submitTodoSuccess) {
            setValue('id', '');
            props.handleClose();
            props.resetSubmitTodoFlag();
        }
    }, [props.submitTodoSuccess]);

    const onSubmit = (data) => {
        let objToPass = {
            due_date: data.dueDate && moment(data.dueDate, 'MM-DD-YYYY HH:mm:ss').utc().format('YYYY-MM-DD HH:mm:ss'),
            color: selectedColor,
            title: data.title,
            priority: selectedPrio,
            progress: data.progress,
            frequency: data.status,
            name: data.name
        };
        if (data.newCat) {
            objToPass = {
                ...objToPass,
                newCat: data.newCat
            };
        } else {
            objToPass = {
                ...objToPass,
                category: data.category !== 0 ? data.category : undefined
            };
        }
        if (Object.keys(props.edit).length > 0) {
            objToPass = {
                ...objToPass,
                todo_id: data.id
            };
            props.updateTodo(objToPass);
        } else {
            props.addTodo(objToPass);
        }
    };

    const handlePrioSelection = (label) => setSelectedPrio(label);

    function valid(current) {
        return current.isAfter(yesterday);
    }
    const handleCloseAddNewCat = () => {
        setEnableAddCat(!enableAddCat);
        setValue('newCat', '');
    };
    let sampleDivStyle = {};
    if (selectedColor === '#FFFFFF') {
        sampleDivStyle = { borderLeft: '5px solid ' + secondary.main };
    } else {
        sampleDivStyle = {
            background: `linear-gradient(180deg, ${selectedColor}, #FFFFFF)`
        };
    }
    return (
        <Dialog
            sx={{ marginTop: '5px', height: '102%' }}
            open={true}
            keepMounted
            TransitionComponent={Transition}
            onClose={props.handleClose}
            aria-labelledby="add-todo-form-dialog"
        >
            <CustomDialogTitle id="add-todo-form-dialog" onClose={props.handleClose}>
                {formTitle}
            </CustomDialogTitle>
            <AccessControl allowedPermissions={['create_todo']} renderNoAccess={(plan) => <PermissionDenied planname={plan} />}>
                <DialogContent dividers>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container>
                            <Grid alignItems="center" display="flex" item xs={12} sm={5} md={5} lg={5}>
                                <Typography>Task Name</Typography>
                            </Grid>
                            <Grid item xs={12} sm={7} md={7} lg={7}>
                                <RenderField
                                    name="name"
                                    control={control}
                                    label=""
                                    id="name"
                                    type="text"
                                    valid={true}
                                    required
                                    // multiline
                                    // disabled
                                    // maxRows={4}
                                    placeholder="Enter Task Name"
                                    setValue={setValue}
                                />
                            </Grid>
                            <Grid alignItems="center" display="flex" item xs={12} sm={5} md={5} lg={5}>
                                <Typography>Task Detail Description</Typography>
                            </Grid>
                            <Grid item xs={12} sm={7} md={7} lg={7}>
                                <RenderField
                                    name="title"
                                    control={control}
                                    label=""
                                    id="title"
                                    type="text"
                                    // required
                                    multiline
                                    maxRows={4}
                                    placeholder="Enter Task Detail Here"
                                    setValue={setValue}
                                />
                            </Grid>
                            <Grid alignItems="center" display="flex" item xs={12} sm={5} md={5} lg={5}>
                                <Typography>Due Date</Typography>
                            </Grid>
                            <Grid item xs={12} sm={7} md={7} lg={7}>
                                <RenderField
                                    name="dueDate"
                                    control={control}
                                    label=""
                                    id="dueDate"
                                    defaultValue={
                                        Object.keys(props.edit).length ? props.edit.due_date && moment(props.edit.due_date) : undefined
                                    }
                                    type="datetime"
                                    isValidDate={valid}
                                    className={classes.dueDateTime}
                                    placeholder="Select Due Date Time"
                                    setValue={setValue}
                                />
                            </Grid>
                            <Grid alignItems="center" display="flex" item xs={12} sm={5} md={5} lg={5} className={classes.addCateTitle}>
                                <Typography>Progress</Typography>
                            </Grid>
                            <Grid item xs={12} sm={7} md={7} lg={7} className={classes.addCateTitle}>
                                <RenderField type="slider" control={control} name="progress" id="progress" max={100} min={0} />
                            </Grid>
                            <Grid alignItems="center" display="flex" item xs={12} sm={5} md={5} lg={5} className={classes.addCateTitle}>
                                <Typography>Add to Category</Typography>
                            </Grid>
                            <Grid display="flex" item xs={12} sm={7} md={7} lg={7}>
                                {!enableAddCat && (
                                    <RenderField
                                        name="category"
                                        control={control}
                                        label="Select Category"
                                        outputField
                                        id="category"
                                        options={props.categoryList.map((val) => ({
                                            label: val.name,
                                            value: val.id
                                        }))}
                                        type="select"
                                        disabled={enableAddCat}
                                    />
                                )}
                                {!enableAddCat && (
                                    <div
                                        onClick={() => handleCloseAddNewCat()}
                                        className={`${classes.addNewCatBlock} ${classes.resizeSvg}`}
                                    >
                                        <AddIcon />
                                        <span>Add New Category</span>
                                    </div>
                                )}
                                {enableAddCat && (
                                    <div className={classes.deleteAddNewCatWapper}>
                                        <RenderField
                                            name="newCat"
                                            control={control}
                                            label="Add Category"
                                            outputField
                                            id="newCat"
                                            type="text"
                                            placeholder="Enter new category"
                                        />
                                        <div onClick={() => handleCloseAddNewCat()} className={`${classes.resizeSvg} deleteAddNewCat`}>
                                            <IconButton aria-label="close" className={classes.deleteBtn}>
                                                <DeleteForeverIcon />
                                            </IconButton>
                                        </div>
                                    </div>
                                )}
                            </Grid>
                            <Grid alignItems="center" display="flex" item xs={12} sm={5} md={5} lg={5} className={classes.addCateTitle}>
                                <Typography>Frequency</Typography>
                            </Grid>
                            <Grid item xs={12} sm={7} md={7} lg={7}>
                                <RenderField
                                    name="status"
                                    control={control}
                                    label="Select Frequency"
                                    outputField
                                    id="status"
                                    options={frequency.map((val) => ({
                                        label: val.name,
                                        value: val.id
                                    }))}
                                    type="select"
                                    // disabled={enableAddCat}
                                />
                            </Grid>
                            <Grid alignItems="center" display="flex" item xs={12} sm={5} md={5} lg={5}>
                                <Typography>Pick a color</Typography>
                            </Grid>
                            <Grid display="flex" gap={3} item xs={12} sm={7} md={7} lg={7}>
                                <input
                                    type="color"
                                    style={{ alignSelf: 'center' }}
                                    value={selectedColor}
                                    onChange={(e) => setColorSelection(e.target.value)}
                                ></input>
                                <Grid container justifyContent="flex-start">
                                    <Grid item xs={12} sm={3}>
                                        <Paper elevation={3} style={sampleDivStyle} className={`${classes.sampleColorTodo} `}>
                                            <div
                                                className="sampleDiv"
                                                style={{
                                                    color: invertColor(selectedColor, true)
                                                }}
                                            >
                                                <Typography>Sample Todo</Typography>
                                            </div>
                                        </Paper>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid display="flex" item xs={12}>
                                <Typography sx={{ width: '50%', display: 'flex', alignSelf: 'center' }} className={classes.selectPrioTitle}>
                                    Select Priority
                                </Typography>
                                <Grid container>
                                    {PriorityList.map((prio, i) => {
                                        return (
                                            <Grid sx={{ marginLeft: '20px' }} item xs={12} sm={3} key={i + '_priorityList'}>
                                                <TodoPriorityPaper
                                                    onClick={() => handlePrioSelection(prio.value)}
                                                    elevation={3}
                                                    className={`${selectedPrio === prio.value ? 'selected' : ''}`}
                                                >
                                                    <div className="prioWrapper">
                                                        <img src={`img/${prio.icon}`} alt="priority icon" />
                                                        <Typography>{prio.label}</Typography>
                                                    </div>
                                                </TodoPriorityPaper>
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                            </Grid>
                        </Grid>
                        <CalCardActions className="contactus-cardaction">
                            <CustomButton type="submit" ps={'9px 30px'} variant="contained" disabled={isSubmitting}>
                                Submit
                            </CustomButton>
                        </CalCardActions>
                    </form>
                </DialogContent>
            </AccessControl>
        </Dialog>
    );
};
