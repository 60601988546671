import { IconButton, Tooltip } from '@mui/material';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import EnhancedTable from 'components/CommonComponent/CommonTable/EnhancedTable';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { submitCreditCardCal } from 'redux/actions/CreditCardCalActions';
import { numberWithCommas } from 'utils/Utils';
import AddNewCreditCard from './AddNewCreditCard';

export const CreditCardTable = (props) => {
    const { creditCardData, dispatch } = props;
    const [enableEdit, setEnableEdit] = useState({});
    const [tableData, setTableData] = useState([]);
    const [id, setId] = useState('');
    const container = React.useRef(null);
    const { handleSubmit, control, setValue, reset } = useForm({
        mode: 'onChange'
    });

    useEffect(() => {
        let tabledata = [];
        const cco = creditCardData.creditCardDetail['credit_card_output'];
        const so = creditCardData.creditCardDetail['scenario_output'];
        const uid = creditCardData.creditCardDetail['user_input_data'];
        const newArr = [{ ...cco, ...so, ...uid }];
        tabledata = [...newArr];
        setTableData(tabledata);
        setId(creditCardData.creditCardDetail.id);
    }, [creditCardData.creditCardDetail]);

    const deleteSelectedData = () => dispatch(submitCreditCardCal({ id: id }, 'delete'));

    const columns = useMemo(() => {
        const result = [
            {
                Header: () => null,
                accessor: 'id',
                isVisible: false
            },
            {
                Header: 'Action',
                Cell: ({ row }) => {
                    return (
                        <>
                            <Tooltip title="Delete">
                                <IconButton aria-label="delete" onClick={() => deleteSelectedData()}>
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Edit">
                                <IconButton
                                    aria-label="edit"
                                    onClick={() => {
                                        setEnableEdit(row.original);
                                    }}
                                >
                                    <CreateIcon />
                                </IconButton>
                            </Tooltip>
                        </>
                    );
                }
            },
            {
                Header: 'Balance',
                width: 150,
                accessor: 'balance',
                disableFilters: true,
                Cell: ({ value, row, cell }) => {
                    return numberWithCommas(value) || '-';
                }
            },
            {
                Header: 'Interest Rate',
                accessor: 'interest Rate',
                disableFilters: true,
                Cell: ({ value, row, cell }) => {
                    return value || '-';
                }
            },
            {
                Header: 'Payment Type',
                accessor: 'payment_type',
                disableFilters: true,
                width: 200,
                Cell: ({ value, row, cell }) => {
                    const option = [
                        { label: 'USD', value: 'USD' },
                        { value: 'PCT', label: 'PCT' }
                    ];
                    return option.filter((val) => val.value.toLowerCase() === value?.toLowerCase())[0]?.label || '-';
                }
            },
            {
                Header: 'Monthly Payment(USD/PCT)',
                width: 150,
                accessor: 'monthly_payment',
                disableFilters: true,
                Cell: ({ value, row, cell }) => {
                    return numberWithCommas(value) || '-';
                }
            }
        ];
        // const apiToken = localStorage.getItem('auth_token');
        // if (!apiToken) {
        //     result.splice(1, 2);
        // }
        return result;
    }, [id]);

    return (
        <>
            {Object.keys(enableEdit).length ? (
                <AddNewCreditCard editData={enableEdit} setEnableEdit={setEnableEdit} id={creditCardData?.creditCardDetail?.id} />
            ) : (
                <EnhancedTable
                    enableSearch={false}
                    columns={columns}
                    container={container}
                    data={tableData}
                    enableSort={false}
                    enablePagination={false}
                />
            )}
        </>
    );
};
