import React, { useState } from 'react';
import { ResponsiveCirclePacking } from '@nivo/circle-packing'

const CustomTooltip = ({name, value, legendColor}) => {
    return <div style={{ padding: '0.5rem', borderRadius: '0.25rem', display: 'flex', flexDirection: 'row', alignItems: 'center', backgroundColor: 'white' }}>
        <div style={{backgroundColor: legendColor, height: '13px', width: '13px'}} />
        &nbsp;&nbsp;
        <div style={{fontWeight: '500'}}>{name}:</div>
        &nbsp;
        <div style={{fontWeight: '800'}}>{value}</div>
    </div>
}

const CirclePackingChart = ({chartData , valueKey , idKey , labelKey}) => {
    const [zoomedId, setZoomedId] = useState(null);
    return <ResponsiveCirclePacking
        data={chartData}
        margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
        id={idKey}
        label={d => d.data[labelKey]}
        value={valueKey}
        colors={{ scheme: 'nivo' }}
        zoomedId={zoomedId} 
        motionConfig="slow" 
        onClick={node => {
            setZoomedId(zoomedId === node.id ? null : node.id);
        }}
        tooltip={props => (<CustomTooltip name={props.data.name} value={props.formattedValue} legendColor={props.color} />)}
        childColor={{
            from: 'color',
            modifiers: [
                [
                    'brighter',
                    0.4
                ]
            ]
        }}
        padding={4}
        enableLabels={true}
        labelsFilter={n=>2===n.node.depth}
        labelsSkipRadius={10}
        labelTextColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    2
                ]
            ]
        }}
        borderWidth={1}
        borderColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    0.5
                ]
            ]
        }}
        defs={[
            {
                id: 'lines',
                type: 'patternLines',
                background: 'none',
                color: 'inherit',
                rotation: -45,
                lineWidth: 5,
                spacing: 8
            }
        ]}
        fill={[
            {
                match: {
                    depth: 1
                },
                id: 'lines'
            }
        ]}
/>
}

export default CirclePackingChart;