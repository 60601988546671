import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import { ChartParentGrid } from 'assets/jss/GeneralStyle';
import { OutputBox } from 'assets/jss/GeneralStyle';
import NoDataFound from 'components/CommonComponent/NoDataFound';
import React, { Fragment } from 'react';
import { numberWithCommas } from 'utils/Utils';
import { BiaxialInflationBondChart } from './BiaxialInflationBondChart';

const InflationBondOutput = ({ data }) => {
    return (
        <>
            <Card elevation={2} sx={{ mb: 3.5 }}>
                <CardContent>
                    <Typography variant="h6" sx={{ mb: 2, display: 'flex' }} gutterBottom>
                        Summary Output
                    </Typography>
                    {data.length > 0 ? (
                        <Box id="outputWrapper" className="widget-full-width">
                            <Grid container spacing={2}>
                                {data.map((val, i) => {
                                    return typeof val.value !== 'object' ? (
                                        <Fragment key={i + 'tipsoutput'}>
                                            <Grid item xs={12} md={4}>
                                                <OutputBox>
                                                    <Typography variant="body1" className="outputLabel" component="p">
                                                        {val.label}
                                                    </Typography>
                                                    <Typography variant="h6">{numberWithCommas(val.value.toFixed(2))}</Typography>
                                                </OutputBox>
                                            </Grid>
                                        </Fragment>
                                    ) : (
                                        ''
                                    );
                                })}
                            </Grid>
                        </Box>
                    ) : (
                        <NoDataFound />
                    )}
                </CardContent>
            </Card>
            {data.length > 0
                ? data.map((val, i) => {
                      return typeof val.value === 'object' ? (
                          <Card elevation={2}>
                              <CardContent>
                                  <Typography variant="h6" sx={{ mb: 2, display: 'flex' }}>
                                      Summary Graph
                                  </Typography>
                                  <Grid container spacing={2}>
                                      <ChartParentGrid item xs={12} md={12}>
                                          <Box className={'chartWapper'}>
                                              <BiaxialInflationBondChart bardata={val.value[0]} linedata={val.value[1]} />
                                          </Box>
                                      </ChartParentGrid>
                                  </Grid>
                              </CardContent>
                          </Card>
                      ) : (
                          ''
                      );
                  })
                : ''}
        </>
    );
};

export default InflationBondOutput;
