import { Box, Button, Card, Grid, IconButton, Typography } from '@mui/material';
import { styled } from '@mui/styles';

export const ContactusBox = styled(Box)(({ theme }) => ({
    "&.calFormbox":{
        textAlign:'center'
    },
    position:'relative'    
}))


export const ContactFormCard = styled(Card)(({ theme }) => ({
           margin: 'auto',
           width: '50%',
           '& .contactus-divider': {
               borderColor: theme.palette.common.black
           },
            [theme.breakpoints.down('sm')]: {
            width:'100%'           
        },
    }));
