import { Grid, Tab, Tabs, Typography, useTheme } from '@mui/material';
import { ChartCardParent, SummaryTabPaper, TopBlockBox } from 'assets/jss/PortfolioStyle';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import SwipeableViews from 'react-swipeable-views';
import { a11yProps, numberWithCommas } from 'utils/Utils';
import PortfolioTabPanel from 'views/PortfolioValueUpload/PortfolioTabPanel';
import BarCreditCardChart from './BarCreditCardChart';
import * as tooltip from 'Tooltip.json';
import CustomTooltip from 'components/CommonComponent/CustomTooltip';
import BiaxialCreditCardChart from './BiaxialCreditCardChart';
import PrincipalAndInterestOverTimeChart from './PrincipalAndInterestOverTimeChart';
import CustomScroll from 'components/CustomScroll';

const arr = ['First Monthly Payment', 'Lifetime Interest', 'Number of Payments', 'Total Annual Debt Payments'];

const tabs = [
    { label: 'Summary' },
    { label: 'Lifetime Interest' },
    { label: 'Months to Payoff' },
    { label: 'Scenario Output' },
    { label: 'Principal and Interest over time' }
];

export default function CreditCardCalTopBlock() {
    const theme = useTheme();
    const [value, setValue] = useState(0);
    const credit = useSelector((state) => state.creditCard);
    const cco = Object(credit.creditCardDetail.credit_card_output);
    const [activeTab, setActiveTab] = useState(0);
    /**
     * handle the tab switch view
     * @param {*} event
     * @param {*} newValue
     */
    const handleTabChange = (event, newValue) => typeof newValue !== 'undefined' && setValue(newValue);

    const handleTabClick = (index) => {
        setActiveTab(index);
        setValue(index);
    };

    return (
        <TopBlockBox>
            <CustomScroll tabs={tabs} activeTab={activeTab} handleTabClick={handleTabClick} />
            {/* <Tabs
                className="topblock-tabs"
                value={value}
                onChange={handleTabChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                allowScrollButtonsMobile={true}
                aria-label="debt calculator summary tabs"
            >
                <Tab label="Summary " {...a11yProps(0)} />
                <Tab label="Lifetime Interest" {...a11yProps(1)} />
                <Tab label="Months to Payoff" {...a11yProps(2)} />
                <Tab label="Scenario Output" {...a11yProps(3)} />
                <Tab label="Principal and Interest over time" {...a11yProps(4)} />
            </Tabs> */}
            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                onChangeIndex={(index) => {
                    setActiveTab(index);
                    setValue(index);
                }}
            >
                <PortfolioTabPanel value={value} index={0} dir={theme.direction}>
                    <Grid container spacing={2}>
                        {Object.keys(cco).length &&
                            arr.map((val, i) => {
                                return typeof cco[val] !== 'object' ? (
                                    <>
                                        <Grid item xs={12} sm={3} key={i + 'budgetsummarytext'} className="widget-grid-item">
                                            <SummaryTabPaper elevation={3}>
                                                <Typography variant="subtitle1" className="summary-text">
                                                    {val}
                                                    {tooltip.default?.[val] && (
                                                        <CustomTooltip
                                                            tooltipText={tooltip.default?.[val]}
                                                            customTooltipStyle={{ maxWidth: 450 }}
                                                            label={val}
                                                        />
                                                    )}
                                                </Typography>
                                                <Typography variant="subtitle1" className="summary-text">
                                                    {numberWithCommas(Math.floor(cco[val]))}
                                                </Typography>
                                            </SummaryTabPaper>
                                        </Grid>
                                    </>
                                ) : (
                                    <></>
                                );
                            })}
                    </Grid>
                </PortfolioTabPanel>
                <PortfolioTabPanel value={value} index={1} dir={theme.direction}>
                    {credit?.creditCardDetail?.scenario_output !== undefined ? (
                        <ChartCardParent elevation={2}>
                            <BarCreditCardChart credit={credit.creditCardDetail.scenario_output} keyy="Lifetime Interest" />
                        </ChartCardParent>
                    ) : (
                        ''
                    )}
                </PortfolioTabPanel>
                <PortfolioTabPanel value={value} index={2} dir={theme.direction}>
                    {credit?.creditCardDetail?.scenario_output !== undefined ? (
                        <ChartCardParent elevation={2}>
                            <BarCreditCardChart credit={credit.creditCardDetail.scenario_output} keyy="Months to Payoff" />
                        </ChartCardParent>
                    ) : (
                        ''
                    )}
                </PortfolioTabPanel>
                <PortfolioTabPanel value={value} index={3} dir={theme.direction}>
                    {credit?.creditCardDetail?.scenario_output !== undefined ? (
                        // <ChartCardParent elevation={2}>
                        <BiaxialCreditCardChart credit={credit.creditCardDetail.scenario_output} />
                    ) : (
                        // {/* </ChartCardParent> */}
                        ''
                    )}
                </PortfolioTabPanel>
                <PortfolioTabPanel value={value} index={4} dir={theme.direction}>
                    {credit?.creditCardDetail?.credit_card_output !== undefined ? (
                        <PrincipalAndInterestOverTimeChart credit={credit.creditCardDetail.credit_card_output} />
                    ) : (
                        ''
                    )}
                </PortfolioTabPanel>
            </SwipeableViews>
        </TopBlockBox>
    );
}
