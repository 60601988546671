import * as ActionTypes from './ActionTypes';
import { apiCall, handleError } from 'utils/Utils';
import Globals from 'Globals.js';
import { toast } from 'react-toastify';
import { budgetFiles, budgetAnalysis } from "Budget.json"

//handle success of api
export const submitBudgetCalSuccess = (val) => {
  return {
    type: ActionTypes.SUBMIT_BUDGET_CAL_SUCCESS,
    data: val
  };
};
// handle api error
export const submitBudgetCalError = () => {
  return {
    type: ActionTypes.SUBMIT_BUDGET_CAL_ERROR
  };
};
/**
 * @author disha
 * @param {*} data
 * @use to submit budget data
 * @returns
 */
export const submitBudgetCal = (data) => async (dispatch) => {
  try {
    dispatch({
      type: ActionTypes.SUBMIT_BUDGET_CAL_INIT
    });
    const apiResponse = await apiCall(`${Globals.API_ROOT_URL}budgetcalctool`, 'post', data);
    if (apiResponse !== undefined && apiResponse.status === 200) {
      if (data.save_data || data.budid) {
        dispatch(fetchSavedBudget());
      }
      dispatch(submitBudgetCalSuccess(apiResponse.data.data));
    } else {
      handleError(apiResponse, dispatch);
      dispatch(submitBudgetCalError());
    }
  } catch (error) {
    toast.error(Globals.errorMsg);
    dispatch(submitBudgetCalError());
    throw error;
  }
};
//handle success of api
export const uploadBudgetFileSuccess = (val) => {
  return {
    type: ActionTypes.UPLOAD_BUDGET_FILE_SUCCESS,
    data: val
  };
};
// handle api error
export const uploadBudgetFileError = () => {
  return {
    type: ActionTypes.UPLOAD_BUDGET_FILE_ERROR
  };
};
/**
 * @author disha
 * @param {*} data
 * @use to submit budget data
 * @returns
 */
export const uploadBudgetFile = (data) => async (dispatch) => {
  try {
    dispatch({
      type: ActionTypes.UPLOAD_BUDGET_FILE_INIT
    });
    const apiResponse = await apiCall(`${Globals.API_ROOT_URL}budgetanalysis/upload`, 'post', data);
    if (apiResponse !== undefined && apiResponse.status === 200) {
      toast.success(apiResponse.data.data.message);
      dispatch(uploadBudgetFileSuccess(apiResponse.data.data));
      dispatch(getBudgetFiles());
    } else {
      handleError(apiResponse, dispatch);
      dispatch(uploadBudgetFileError());
    }
  } catch (error) {
    toast.error(Globals.errorMsg);
    dispatch(uploadBudgetFileError());
    throw error;
  }
};
//handle success of api
export const getBudgetFilesSuccess = (val) => {
  return {
    type: ActionTypes.GET_BUDGET_FILE_SUCCESS,
    data: val
  };
};
// handle api error
export const getBudgetFilesError = () => {
  return {
    type: ActionTypes.GET_BUDGET_FILE_ERROR
  };
};
/**
 * @author disha
 * @param {*} data
 * @use to submit budget data
 * @returns
 */
export const getBudgetFiles = () => async (dispatch) => {
  try {
    dispatch({
      type: ActionTypes.GET_BUDGET_FILE_INIT
    });
    const apiResponse = await apiCall(`${Globals.API_ROOT_URL}budgetanalysis/upload`, 'get');
    if (apiResponse !== undefined && apiResponse.status === 200) {
      dispatch(getBudgetFilesSuccess(apiResponse.data.data));
    } else {
      if (apiResponse.status === 401) {
        dispatch(getBudgetFilesSuccess(budgetFiles));
      }
      else {
        handleError(apiResponse, dispatch);
        dispatch(getBudgetFilesError());
      }
    }
  } catch (error) {
    toast.error(Globals.errorMsg);
    dispatch(getBudgetFilesError());
    throw error;
  }
};
//handle success of api
export const deleteBudgetFilesSuccess = (val) => {
  return {
    type: ActionTypes.DELETE_BUDGET_FILE_SUCCESS,
    data: val
  };
};
// handle api error
export const deleteBudgetFilesError = () => {
  return {
    type: ActionTypes.DELETE_BUDGET_FILE_ERROR
  };
};
/**
 * @author disha
 * @param {*} data
 * @use to submit budget data
 * @returns
 */
export const deleteBudgetFiles = (data) => async (dispatch) => {
  try {
    dispatch({
      type: ActionTypes.DELETE_BUDGET_FILE_INIT
    });
    const apiResponse = await apiCall(`${Globals.API_ROOT_URL}budgetanalysis/upload`, 'delete', data);
    if (apiResponse !== undefined && apiResponse.status === 200) {
      toast.success(apiResponse.data.data.message);
      dispatch(deleteBudgetFilesSuccess());
      dispatch(getBudgetFiles());
    } else {
      handleError(apiResponse, dispatch);
      dispatch(deleteBudgetFilesError());
    }
  } catch (error) {
    toast.error(Globals.errorMsg);
    dispatch(deleteBudgetFilesError());
    throw error;
  }
};
//handle success of api
export const fetchSelectedBudgetSuccess = (val) => {
  return {
    type: ActionTypes.FETCH_SELECTED_BUDGET_FILE_SUCCESS,
    data: val
  };
};
// handle api error
export const fetchSelectedBudgetError = () => {
  return {
    type: ActionTypes.FETCH_SELECTED_BUDGET_FILE_ERROR
  };
};
/**
 * @author disha
 * @param {*} data
 * @use to submit budget data
 * @returns
 */
export const fetchSelectedBudget = (data) => async (dispatch) => {
  try {
    dispatch({
      type: ActionTypes.FETCH_SELECTED_BUDGET_FILE_INIT
    });
    const apiResponse = await apiCall(`${Globals.API_ROOT_URL}budgetanalysis`, 'post', data);
    if (apiResponse !== undefined && apiResponse.status === 200) {
      dispatch(fetchSelectedBudgetSuccess(apiResponse.data.data));
    } else {
      if (apiResponse.status === 401) {
        dispatch(fetchSelectedBudgetSuccess(budgetAnalysis));
      }
      else {
        handleError(apiResponse, dispatch);
        dispatch(fetchSelectedBudgetError());
      }
    }
  } catch (error) {
    toast.error(Globals.errorMsg);
    dispatch(fetchSelectedBudgetError());
    throw error;
  }
};
//handle success of api
export const smartTblBudgetSuccess = () => {
  return {
    type: ActionTypes.SMART_BUDGET_SUCCESS
  };
};

// handle api error
export const smartTblBudgetError = () => {
  return {
    type: ActionTypes.SMART_BUDGET_ERROR
  };
};
//api to submit/delete/update data in holding table
export const smartTblBudget =
  (data, type, ids = []) =>
    async (dispatch) => {
      try {
        // handle loader when api started to call
        dispatch({
          type: ActionTypes.SMART_BUDGET_INIT
        });
        const apiResponse = await apiCall(`${Globals.API_ROOT_URL}budgetanalysis/smarttable`, type, data);
        if (apiResponse !== undefined && apiResponse.status === 200) {
          toast.success(apiResponse.data.data.message);
          dispatch(smartTblBudgetSuccess());
          // dispatch(fetchSelectedBudget(ids));
        } else {
          handleError(apiResponse, dispatch);
          dispatch(smartTblBudgetError());
        }
      } catch (error) {
        toast.error(Globals.errorMsg);
        dispatch(smartTblBudgetError());
        throw error;
      }
    };
//handle success of api
export const fetchSavedBudgetSuccess = (val) => {
  return {
    type: ActionTypes.FETCH_SAVED_BUDGET_SUCCESS,
    data: val
  };
};
// handle api error
export const fetchSavedBudgetError = () => {
  return {
    type: ActionTypes.FETCH_SAVED_BUDGET_ERROR
  };
};
/**
 * @author disha
 * @use GET SAVED budget data
 * @returns
 */
export const fetchSavedBudget = () => async (dispatch) => {
  try {
    dispatch({
      type: ActionTypes.FETCH_SAVED_BUDGET_INIT
    });
    const apiResponse = await apiCall(`${Globals.API_ROOT_URL}budgetcalctool`, 'get');
    if (apiResponse !== undefined && apiResponse.status === 200) {
      dispatch(fetchSavedBudgetSuccess(apiResponse.data.data));
    } else {
      handleError(apiResponse, dispatch);
      dispatch(fetchSavedBudgetError());
    }
  } catch (error) {
    toast.error(Globals.errorMsg);
    dispatch(fetchSavedBudgetError());
    throw error;
  }
};
//handle success of api
export const deleteSavedBudgetCalSuccess = (val) => {
  return {
    type: ActionTypes.DELETE_SAVED_BUDGET_SUCCESS,
    data: val
  };
};
// handle api error
export const deleteSavedBudgetCalError = () => {
  return {
    type: ActionTypes.DELETE_SAVED_BUDGET_ERROR
  };
};
/**
 * @author disha
 * @use GET SAVED budget data
 * @returns
 */
export const deleteSavedBudgetCal = (data) => async (dispatch) => {
  try {
    dispatch({
      type: ActionTypes.DELETE_SAVED_BUDGET_INIT
    });
    const apiResponse = await apiCall(`${Globals.API_ROOT_URL}budgetcalctool`, 'delete', data);
    if (apiResponse !== undefined && apiResponse.status === 200) {
      dispatch(fetchSavedBudget());
      dispatch(deleteSavedBudgetCalSuccess(apiResponse.data.data));
    } else {
      handleError(apiResponse, dispatch);
      dispatch(deleteSavedBudgetCalError());
    }
  } catch (error) {
    toast.error(Globals.errorMsg);
    dispatch(deleteSavedBudgetCalError());
    throw error;
  }
};
//handle success of api
export const submitTaxEstiCalSuccess = (val) => {
  return {
    type: ActionTypes.SUBMIT_TAX_ESTI_SUCCESS,
    data: val
  };
};
// handle api error
export const submitTaxEstiCalError = () => {
  return {
    type: ActionTypes.SUBMIT_TAX_ESTI_ERROR
  };
};

/**
 * @author disha
 * @param {*} data
 * @use to calculate tax estimator
 * @returns
 */
export const submitTaxEstiCal = (data) => async (dispatch) => {
  try {
    dispatch({
      type: ActionTypes.SUBMIT_TAX_ESTI_INIT
    });
    const apiResponse = await apiCall(`${Globals.API_ROOT_URL}taxestimator`, 'post', data);
    if (apiResponse !== undefined && apiResponse.status === 200) {
      dispatch(submitTaxEstiCalSuccess(apiResponse.data.data));
    } else {
      handleError(apiResponse, dispatch);
      dispatch(submitTaxEstiCalError());
    }
  } catch (error) {
    toast.error(Globals.errorMsg);
    dispatch(submitTaxEstiCalError());
    throw error;
  }
};

export const getPlaidBankDetailsSuccess = (val) => {
  return {
    type: ActionTypes.GET_PLAID_BANK_DETAILS_SUCCESS,
    data: val
  };
};

export const getPlaidBankDetailsError = () => {
  return {
    type: ActionTypes.GET_PLAID_BANK_DETAILS_ERROR
  };
};

// get plaid bank data
export const getPlaidBankDetails = (data) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.GET_PLAID_BANK_DETAILS_INIT });
    const details = {};
    await Promise.all(
      data.map(async (bank) => {
        const response = await apiCall(`${Globals.API_ROOT_URL}bank_transaction/${bank.id}`, 'get');
        if (response !== undefined && response.status === 200) {
          const resData = response.data.data;
          if (!details[bank.id]) {
            details[bank.id] = [];
          }
          resData.forEach((res) => {
            details[bank.id].push({ ...res, id: res['Transaction Id'] });
          });
        } else {
          handleError(response, dispatch);
          dispatch(getPlaidBankDetailsError());
        }
      })
    );
    dispatch(getPlaidBankDetailsSuccess(details));
  } catch (error) {
    toast.error(Globals.errorMsg);
    dispatch(getPlaidBankDetailsError());
    throw error;
  }
};

//handle success of api
export const fetchArchivedBudgetSuccess = (val) => {
  return {
    type: ActionTypes.FETCH_ARCHIVED_BUDGET_SUCCESS,
    data: val
  };
};
// handle api error
export const fetchArchivedBudgetError = () => {
  return {
    type: ActionTypes.FETCH_ARCHIVED_BUDGET_ERROR
  };
};

export const fetchArchivedBudget = () => async (dispatch) => {
  try {
    dispatch({
      type: ActionTypes.FETCH_ARCHIVED_BUDGET_INIT
    });
    const apiResponse = await apiCall(`${Globals.API_ROOT_URL}budgetanalysis/file?archived=true`, 'get');
    if (apiResponse !== undefined && apiResponse.status === 200) {
      dispatch(fetchArchivedBudgetSuccess(apiResponse.data.data));
    } else {
      handleError(apiResponse, dispatch);
      dispatch(fetchArchivedBudgetError());
    }

  } catch (error) {
    toast.error(Globals.errorMsg);
    dispatch(fetchArchivedBudgetError());
    throw error;
  }
};