import AddIcon from '@mui/icons-material/Add';
import { Box, Divider, Grid, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import Tooltip from '@mui/material/Tooltip';
import { withStyles } from '@mui/styles';
import { CustomButton } from 'assets/jss/ButtonStyle';
import { SampleFilePaper, SampleFileParentBox, StyledAtag, StyledDialogContent } from 'assets/jss/GeneralStyle';
import { CalCardActions } from 'assets/jss/RetirementStyle';
import CustomReactDropzone from 'components/CommonComponent/CustomReactDropzone';
import CustomDialogTitle from 'components/DialogTitle';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { submitBudgetCalSuccess } from 'redux/actions/BudgetActions';
import { resetEditTableField, submitDebtCal } from 'redux/actions/DebtCalActions';
import RenderField from 'utils/RenderField';
import { parseCsv } from 'utils/Utils';
import { parseExcel } from 'utils/Utils';
import { fetchIndexTypeValue } from '../../redux/actions/DebtCalActions';
import { getCookie } from 'utils/Utils';
import RegistrationModal from './registerModal';

const debtTypeOptions = ['Auto', 'Personal', 'Student Loan', 'Mortgage'];
const indexTypeLabels = ['Prime', 'SoFR', 'Other/Custom'];

const CustomDivider = withStyles((theme) => ({
    root: {
        position: 'relative',
        '& .dividertext': {
            width: 29,
            backgroundColor: 'white',
            position: 'absolute',
            left: '45%',
            top: '-8px',
            textAlign: 'center'
        }
    }
}))(Divider);

const AddNewDebt = () => {
    const [option, setOption] = useState('');
    const [open, setOpen] = React.useState(false);
    const [resetDropzoneValue, setResetDropzoneValue] = useState(false);
    const [fileLists, setFileLists] = useState([]);
    const requiredFields = ['PrinAmount', 'Debt Type'];
    const setting = useSelector((state) => state.setting);
    const [disableUploadButton, setDisableUploadButton] = useState(false);
    const [idxVal, setIdxVal] = useState(0);
    const [indexValue, setIndexValue] = useState('');
    const [indexType, setIndexType] = useState('');
    const [creditCardOption, setCreditCardOption] = useState('');
    const [floatingOption, setFloatingOption] = useState('');
    const [show, setShow] = useState(false);
    const [fieldsDetail, setFieldsDetail] = useState([
        {
            name: 'prinAmt',
            label: 'Principal Amount',
            type: 'number',
            commaSep: true,
            custompattern: 'decimalRate',
            placeholder: '100,000'
        },
        {
            name: 'maturityYrs',
            label: 'Maturity Years',
            type: 'number',
            commaSep: true,
            custompattern: /^\d{0,2}\.?\d{1,2}$/,
            placeholder: '2',
            maxLength: 5,
            required: true
        },
        {
            name: 'repayType',
            label: 'Repay Type',
            type: 'select',
            required: true,
            options: [
                { label: 'Interest Only', value: 'io' },
                { value: 'pi', label: 'Principal and Interest' }
            ]
        },
        {
            name: 'interestRate',
            label: 'Interest Rate',
            type: 'number',
            commaSep: false,
            custompattern: 'decimalRate',
            placeholder: '10',
            // maxLength: 3,
            required: true
        },
        {
            name: 'debttype',
            label: 'Debt Type',
            type: 'select',
            options: [
                { label: 'Auto', value: 'auto' },
                { value: 'creditcard', label: 'Credit Card' },
                { label: 'Mortgage', value: 'mortgage' },
                { label: 'Personal', value: 'personal' },
                { label: 'Student Loan', value: 'studloan' }
            ]
        },
        {
            name: 'actual_pay',
            label: 'Actual payment Made',
            type: 'number',
            required: false,
            commaSep: true,
            custompattern: 'decimalRate',
            placeholder: '100,000'
        },
        {
            name: 'fixed_floating',
            label: 'Fixed/Floating',
            required: false,
            type: 'select',
            options: [
                { label: 'Fixed', value: 'fixed' },
                { value: 'floating', label: 'Floating' }
            ]
        },
        {
            name: 'index',
            label: 'Index',
            type: 'number',
            required: false,
            commaSep: true,
            custompattern: 'decimalRate',
            placeholder: '5.31',
            disabled: true
        },
        {
            name: 'margin',
            label: 'Margin',
            type: 'number',
            required: false,
            commaSep: false,
            custompattern: 'decimalRate',
            placeholder: '10.54'
        }
    ]);

    useEffect(() => {
        if (fileLists.length === 0) {
            setDisableUploadButton(true);
        } else {
            setDisableUploadButton(false);
        }
    }, [fileLists]);
    /**
     * get the selected file from dropzone
     * @param {*} fileList
     */
    const getListOfFiles = (fileList) => {
        setFileLists(fileList);
    };
    const dispatch = useDispatch();
    const {
        handleSubmit,
        control,
        formState: { isSubmitting },
        setValue,
        reset
    } = useForm({
        mode: 'onChange'
        //     defaultValues: {"prinAmt": 10000
        // ,"maturityYrs": 1,"repayType": 'io',"interestRate": 10,
        //       "debttype": 'creditcard',"actual_pay": 1200,"fixed_floating": 'fixed',"index": 2,
        //       "margin": 1},
    });
    const {
        handleSubmit: handleUploadSubmit,
        control: controlUpload,
        formState: { isSubmitting: isSubmittingUpload },
        setValue: setValueUpload,
        reset: resetUpload
    } = useForm({
        mode: 'onChange'
        //     defaultValues: {"prinAmt": 10000
        // ,"maturityYrs": 1,"repayType": 'io',"interestRate": 10,
        //       "debttype": 'creditcard',"actual_pay": 1200,"fixed_floating": 'fixed',"index": 2,
        //       "margin": 1},
    });
    const handleClickOpen = () => {
        if (!getCookie('auth_token')) {
            setShow(true);
            return;
        }
        if (Object.keys(setting.profileDetail).length) {
            if (setting.profileDetail.annualincome) {
                setOpen(true);
                dispatch(resetEditTableField(true));
            } else {
                toast.error('Please enter Annual Income.');
            }
        }
    };
    /**
     * save selected file with validating data
     * @param {*} e
     */
    const handleParseFile = async () => {
        let localDeletedData = [];
        let parsedResult = [];
        if (disableUploadButton) {
            toast.error('Please select file to upload.');
            return;
        }
        const file = fileLists[0];
        let localRes = {};
        if (file.path && (file.path.match(/.*\.xlsx?$/g) || file.type === 'application/vnd.ms-excel')) {
            localRes = await parseExcel(file);
        } else {
            localRes = await parseCsv(file);
        }
        if (localRes.data.length === 0) {
            toast.error('Please enter valid data.');
            return;
        }
        parsedResult = validateData(localRes, localDeletedData, parsedResult);
        if (localDeletedData.length) {
            toast.error(
                <div>
                    {localDeletedData.length} row(s) is removed due to missing required fields
                    <br /> Required Fields : {requiredFields.toString()}
                </div>,
                { autoClose: false }
            );
        } else {
            handleClose();
        }
        if (parsedResult) {
            dispatch(submitDebtCal(parsedResult, 'post'));
            handleClose();
        }
    };
    const validateData = (localRes, localDeletedData, parsedResult) => {
        let responseLocal = localRes;
        const localFileData = JSON.parse(JSON.stringify(responseLocal));
        let arrToSend = [...new Set(responseLocal.data)];
        for (let parsedIndex = 0; parsedIndex < responseLocal.data.length; parsedIndex++) {
            let eachData = responseLocal.data[parsedIndex];
            let objToPass = { fixed_floating: null, debttype: null, repayType: null };
            const isRequiredIncluded = requiredFields.some((val) => {
                if (eachData[val] === undefined || eachData[val] === null) {
                    return val;
                }
                // validating the field data type
                switch (val) {
                    case 'PrinAmount':
                    case 'Maturity Years':
                    case 'Interest Rate':
                        return !eachData[val].toString().match(/^[0-9,]*?(\.[0-9]{1,2})?$/g);
                    case 'Debt Type':
                    case 'Repay Type':
                        return !eachData[val].toString().match(/^([a-z0-9A-Z ]{1,40})$/g);
                    default:
                        return;
                        break;
                }
            });
            const indexToAppedAt = arrToSend.findIndex((x) => JSON.stringify(x) == JSON.stringify(eachData));
            if (eachData['Debt Type']) {
                const optionList = {
                    auto: 'auto',
                    creditcard: 'creditcard',
                    mortgage: 'mortgage',
                    personal: 'personal',
                    studentloan: 'studloan'
                };
                Object.keys(optionList).forEach((opval) => {
                    const regexPattern = new RegExp(opval, 'g');
                    if (eachData['Debt Type'].toString().toLowerCase().replaceAll(' ', '').match(regexPattern)) {
                        objToPass = {
                            ...objToPass,
                            debttype: optionList[opval]
                        };
                    }
                });
            }
            if (eachData['Repay Type']) {
                const optionList = {
                    interestonly: 'io',
                    principalandinterest: 'pi',
                    'principal&interest': 'pi'
                };
                Object.keys(optionList).forEach((opval) => {
                    const regexPattern = new RegExp(opval, 'g');
                    if (eachData['Repay Type'].toString().toLowerCase().replaceAll(' ', '').match(regexPattern)) {
                        objToPass = {
                            ...objToPass,
                            repayType: optionList[opval]
                        };
                    }
                });
            }
            if (eachData['Fixed/Floating']) {
                const optionList = {
                    fixed: 'fixed',
                    floating: 'floating'
                };
                Object.keys(optionList).forEach((opval) => {
                    const regexPattern = new RegExp(opval, 'g');
                    if (eachData['Fixed/Floating'].toString().toLowerCase().replaceAll(' ', '').match(regexPattern)) {
                        objToPass = {
                            ...objToPass,
                            fixed_floating: optionList[opval]
                        };
                    }
                });
            }
            objToPass = {
                ...objToPass,
                prinAmt: eachData['PrinAmount'],
                maturityYrs: eachData['Maturity Years'],
                interestRate: eachData['Interest Rate'],
                actual_pay: eachData['Actual Payment Made'],
                index: eachData['Index'],
                margin: eachData['Margin']
            };
            arrToSend[indexToAppedAt] = {
                ...objToPass
            };
            // save error rows in state and remove from main array of data
            if (isRequiredIncluded) {
                localDeletedData.push(eachData);
                localFileData.data.splice(parsedIndex, 1);
                arrToSend.splice(indexToAppedAt, 1);
            }
        }
        if (arrToSend.length > 0) {
            return arrToSend;
        }
    };
    const handleClose = () => {
        reset({});
        setOpen(false);
        setFileLists([]);
        setFieldsDetail(fieldsDetail.slice(0, 9));
        setOption('');
        setIndexValue('');
        setIndexType('');
        setIdxVal(0);
        setCreditCardOption('');
        setFloatingOption('');
    };
    //to submit the form data
    const onSubmit = (data) => {
        let objToSend = {
            prinAmt: parseFloat(data.prinAmtUnFormattedValue || data.prinAmt),
            debttype: data.debttype,
            fixed_floating: data.fixed_floating || 'fixed'
        };
        if (data.maturityYrs) {
            objToSend = {
                ...objToSend,
                maturityYrs: parseFloat(data.maturityYrs)
            };
        }
        if (data.repayType) {
            objToSend = {
                ...objToSend,
                repayType: data.repayType
            };
        }
        if (data.interestRate) {
            objToSend = {
                ...objToSend,
                interestRate: parseFloat(data.interestRate)
            };
        }
        if (data.actual_payUnFormattedValue || data.actual_pay) {
            objToSend = {
                ...objToSend,
                actual_pay: parseFloat(data.actual_payUnFormattedValue || data.actual_pay)
            };
        }
        if (data.indexUnFormattedValue || data.index || indexValue) {
            objToSend = {
                ...objToSend,
                index: String(data.indexUnFormattedValue || data.index || indexValue)
            };
        }
        if (data.marginUnFormattedValue || data.margin) {
            objToSend = {
                ...objToSend,
                margin: String(data.marginUnFormattedValue || data.margin)
            };
        }
        if (indexType) {
            objToSend = {
                ...objToSend,
                index_type: indexType
            };
        }
        if (data.debttype === 'creditcard') {
            objToSend = {
                ...objToSend,
                payment_type: data.paymenttype
            };
        }

        if (data.debttype !== 'creditcard') {
            if (!objToSend.maturityYrs || !objToSend.repayType) {
                return toast.error('Please fill Maturity Years and Repay Type fields.');
            }
        }
        if (objToSend.fixed_floating === 'fixed') {
            if (!objToSend.interestRate) {
                return toast.error('Please fill Interest Rate field.');
            }
        }
        if (objToSend.payment_type === 'PCT') {
            if (objToSend.actual_pay > objToSend.prinAmt) {
                return toast.error('Actual payment should not greater then principal amount.');
            }
        }
        if (indexType === 'custom' && objToSend.fixed_floating === 'floating' && !data.index) {
            return toast.error('Please fill Index field.');
        }
        if (data.margin > 50) {
            return toast.error('Margin should be less then or equal to 50');
        }
        dispatch(submitDebtCal([objToSend], 'post'));
        handleClose();
    };

    useEffect(() => {
        // reset output when route will change
        return () => {
            dispatch(submitBudgetCalSuccess({}));
            setResetDropzoneValue(!resetDropzoneValue);
        };
    }, []);

    useEffect(() => {
        const index_type = {
            name: 'indextype',
            label: 'Index Type',
            type: 'select',
            options: [
                { label: 'Prime', value: 'Prime' },
                { label: 'SoFR', value: 'SoFR' },
                { label: 'Other/Custom', value: 'custom' }
            ]
        };
        const paymentType = {
            name: 'paymenttype',
            label: 'Payment Type',
            type: 'select',
            required: false,
            options: [
                { label: 'USD', value: 'USD' },
                { label: 'PCT', value: 'PCT' }
            ]
        };

        const handleFieldsDetail = async () => {
            if (option === 'Credit Card') {
                fieldsDetail[1] = {
                    ...fieldsDetail[1],
                    required: false
                };
                fieldsDetail[2] = {
                    ...fieldsDetail[2],
                    required: false
                };
                fieldsDetail[5] = {
                    ...fieldsDetail[5],
                    required: true
                };
                setFieldsDetail([...fieldsDetail, paymentType]);
                setCreditCardOption('creditcard');
                setValue('paymenttype', 'USD');
            }
            if (debtTypeOptions.includes(option)) {
                fieldsDetail[1] = {
                    ...fieldsDetail[1],
                    required: true
                };
                fieldsDetail[2] = {
                    ...fieldsDetail[2],
                    required: true
                };
                fieldsDetail[5] = {
                    ...fieldsDetail[5],
                    required: false
                };
                const objectToRemove = {
                    name: 'paymenttype',
                    label: 'Payment Type',
                    type: 'select',
                    options: [
                        { label: 'USD', value: 'USD' },
                        { label: 'PCT', value: 'PCT' }
                    ]
                };
                let indexOfObjectToRemove = fieldsDetail.findIndex((obj) => obj.name === objectToRemove.name);

                if (indexOfObjectToRemove !== -1) {
                    fieldsDetail.splice(indexOfObjectToRemove, 1);
                    setFieldsDetail([...fieldsDetail]);
                }
                setCreditCardOption('');
            }
            if (option === 'Floating') {
                fieldsDetail[3] = {
                    ...fieldsDetail[3],
                    required: false
                };
                setFieldsDetail([...fieldsDetail, index_type]);
                setFloatingOption('floating');
            }
            if (option === 'Fixed') {
                fieldsDetail[3] = {
                    ...fieldsDetail[3],
                    required: true
                };
                let objectToRemove = {
                    name: 'indextype',
                    label: 'Index Type',
                    type: 'select',
                    options: [
                        { label: 'Prime', value: 'Prime' },
                        { label: 'SoFR', value: 'SoFR' },
                        { label: 'Other/Custom', value: 'custom' }
                    ]
                };

                let indexOfObjectToRemove = fieldsDetail.findIndex((obj) => obj.name === objectToRemove.name);

                if (indexOfObjectToRemove !== -1) {
                    fieldsDetail.splice(indexOfObjectToRemove, 1);
                    setFieldsDetail([...fieldsDetail]);
                    setIndexType('');
                    setIndexValue(idxVal);
                    setValue('indextype', '');
                }
                setFloatingOption('');
            }
            if (indexTypeLabels.includes(option)) {
                let res;
                if (option === 'Other/Custom') {
                    res = await dispatch(fetchIndexTypeValue('custom'));
                } else {
                    res = await dispatch(fetchIndexTypeValue(option.toLowerCase()));
                }
                setIndexValue(res.index_value);
                setIndexType(res.index_type);
            }
            setOption(option);
        };
        handleFieldsDetail();
    }, [option]);

    return (
        <>
            <Box>
                <Tooltip title="Add" arrow>
                    <CustomButton style={{width:'100%'}} variant="text"  startIcon={<AddIcon />} aria-label="add" onClick={handleClickOpen} id="tab-6">
                        Add New Data
                    </CustomButton>
                </Tooltip>
                <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                    <CustomDialogTitle id="form-dialog-title" onClose={handleClose}>
                        Add Debt
                    </CustomDialogTitle>
                    <Divider />
                    <StyledDialogContent>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Grid container>
                                {fieldsDetail.map((val, i) => {
                                    return (
                                        <Grid item xs={12} sm={6} key={i + 'DebtFormFields'}>
                                            <RenderField
                                                name={val.name}
                                                disableEmptyValue={true}
                                                control={control}
                                                label={val.label}
                                                id={val.name}
                                                type={val.type}
                                                options={val.options}
                                                required={val.required || true}
                                                thousandSeparator={val.commaSep}
                                                customPatternName={val.custompattern}
                                                placeholder={val.placeholder}
                                                setValue={setValue}
                                                option={option}
                                                setOption={setOption}
                                                indexValue={indexValue}
                                                setIndexValue={setIndexValue}
                                                indexType={indexType}
                                                creditCardOption={creditCardOption}
                                                floatingOption={floatingOption}
                                                {...val}
                                            />
                                        </Grid>
                                    );
                                })}
                            </Grid>
                            <CalCardActions className="contactus-cardaction">
                                <Grid container direction="row" style={{ width: '100%', textAlign: 'center' }}>
                                    <Grid item xs={12}>
                                        <CustomButton type="submit" variant="contained" disabled={isSubmitting}>
                                            Submit
                                        </CustomButton>
                                    </Grid>
                                </Grid>
                            </CalCardActions>
                        </form>
                        <Box>
                            <CustomDivider component="div">
                                <span className={'dividertext'}>or</span>
                            </CustomDivider>
                        </Box>
                        <form onSubmit={handleUploadSubmit(onSubmit)}>
                            <Grid item xs={12} mt={8}>
                                <CustomReactDropzone
                                    maxFiles={1}
                                    accept="text/csv, .csv,application/vnd.ms-excel,.xlsx,.xls"
                                    handleDroppedFiles={getListOfFiles}
                                    resetDropzoneValue={resetDropzoneValue}
                                />
                                <Box mt={'30px'}>
                                    <Typography variant="h6">
                                        Note:
                                        <Typography variant="body1"> Acceptable file types: CSV, Excel(.xls,.xlsx)</Typography>
                                    </Typography>
                                </Box>

                                <Typography gutterBottom id="sampleTitle" color={'secondary'}>
                                    Please download the sample CSV file
                                </Typography>
                                <SampleFileParentBox>
                                    <SampleFilePaper>
                                        <Box>
                                            <StyledAtag href="/CSVSample/Debt_Input.csv" download>
                                                <img src="img/csv-file-icon.svg" alt="TPE csv sample file" />
                                            </StyledAtag>
                                            <Typography variant="body1" textAlign={'center'} m="10px auto">
                                                CSV Sample
                                            </Typography>
                                        </Box>
                                    </SampleFilePaper>
                                    <SampleFilePaper>
                                        <Box>
                                            <StyledAtag href="/CSVSample/Debt_Input.xls" download>
                                                <img src="img/excel-file-icon.svg" alt="excel file icon" />
                                            </StyledAtag>
                                            <Typography variant="body1" textAlign={'center'} m="10px auto">
                                                Excel Sample
                                            </Typography>
                                        </Box>
                                    </SampleFilePaper>
                                </SampleFileParentBox>
                                <CalCardActions className="contactus-cardaction">
                                    <CustomButton
                                        onClick={handleParseFile}
                                        disabled={disableUploadButton}
                                        className={'success-btn'}
                                        variant="contained"
                                    >
                                        Upload
                                    </CustomButton>
                                </CalCardActions>
                            </Grid>
                        </form>
                    </StyledDialogContent>
                </Dialog>
            </Box>
            <RegistrationModal show={show} closeDialog={() => setShow(false)} />
        </>
    );
};
export default AddNewDebt;
