import * as ActionTypes from "./ActionTypes";
import { apiCall, handleError } from "utils/Utils";
import Globals from "Globals.js";
import { toast } from "react-toastify";
// handle loader when api started to call
export const fetchInvestDashboardInit = () => {
  return {
    type: ActionTypes.FETCH_INVEST_DASHBOARD_INIT
  };
};
//handle success of api
export const fetchInvestDashboardSuccess = val => {
  return {
    type: ActionTypes.FETCH_INVEST_DASHBOARD_SUCCESS,
    data: val
  };
};

// handle api error
export const fetchInvestDashboardError = () => {
  return {
    type: ActionTypes.FETCH_INVEST_DASHBOARD_ERROR
  };
};

/**
 * @author disha
 * @param {*} data
 * @returns
 * @use to fetch the investment dashboard data
 */
export const fetchInvestDashboardData = (reload=false) => async dispatch => {
  try {
    dispatch(fetchInvestDashboardInit());
    const apiResponse = await apiCall(
      `${Globals.API_ROOT_URL}investdashboard?reload=${reload}`,"get");
    if (apiResponse !== undefined && apiResponse.status === 200) {
      dispatch(fetchInvestDashboardSuccess(apiResponse.data));
    } else {
      handleError(apiResponse,dispatch);
      dispatch(fetchInvestDashboardError());
    }
  } catch (error) {
    toast.error(Globals.errorMsg);
    dispatch(fetchInvestDashboardError());
    throw error;
  }
};
