import * as ActionTypes from '../actions/ActionTypes';

const initialState = {
    allocationDetails: {},
    selectedPortfolioDetail: [],
    portfolioList: [],
    loader: false,
    allocationLoader: false,
    submitSmartTblSuccess: false,
    chartData: [],
    reloadPortfolio: false,
    bankPortfolioData: [],
    bankPortfolioTransactions: [],
    portfolioValuationData: {}
};

export const PortfolioReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.SUBMIT_PORTFOLIO_FILES_INIT:
        case ActionTypes.FETCH_PORTFOLIO_CHART_INIT:
            return { ...state, loader: true };
        case ActionTypes.SUBMIT_PORTFOLIO_FILES_SUCCESS:
            return {
                ...state,
                loader: false
            };
        case ActionTypes.SUBMIT_PORTFOLIO_FILES_ERROR:
        case ActionTypes.FETCH_PORTFOLIO_CHART_ERROR:
            return { ...state, loader: false };
        case ActionTypes.SUBMIT_PORT_ALLOCATION_INIT:
            return { ...state, allocationLoader: true };
        case ActionTypes.SUBMIT_PORT_ALLOCATION_SUCCESS:
            return {
                ...state,
                allocationLoader: false,
                allocationDetails: action.data
            };
        case ActionTypes.SUBMIT_PORT_ALLOCATION_ERROR:
            return { ...state, allocationLoader: false };

        case ActionTypes.FETCH_PORTFOLIO_LIST_INIT:
            return { ...state, loader: true };
        case ActionTypes.FETCH_PORTFOLIO_LIST_SUCCESS:
            return {
                ...state,
                loader: false,
                portfolioList: action.data.data
            };
        case ActionTypes.FETCH_PORTFOLIO_LIST_ERROR:
            return { ...state, loader: false };

        case ActionTypes.FETCH_PORTFOLIO_DETAILS_INIT:
            return { ...state, loader: true };
        case ActionTypes.FETCH_PORTFOLIO_DETAILS_SUCCESS:
            return {
                ...state,
                loader: false,
                selectedPortfolioDetail: action.data.data
            };
        case ActionTypes.FETCH_PORTFOLIO_DETAILS_ERROR:
            return { ...state, loader: false };

        case ActionTypes.DELETE_PORTFOLIO_INIT:
        case ActionTypes.SMART_PORTFOLIO_INIT:
            return { ...state, loader: true };
        case ActionTypes.DELETE_PORTFOLIO_SUCCESS:
        case ActionTypes.DELETE_PORTFOLIO_ERROR:
            return {
                ...state,
                loader: false
            };
        case ActionTypes.SMART_PORTFOLIO_SUCCESS:
            return {
                ...state,
                loader: false,
                submitSmartTblSuccess: true
            };
        case ActionTypes.SMART_PORTFOLIO_ERROR:
            return {
                ...state,
                loader: false,
                submitSmartTblSuccess: false
            };
        case ActionTypes.FETCH_PORTFOLIO_CHART_SUCCESS:
            return {
                ...state,
                loader: false,
                chartData: action.data
            };
        case ActionTypes.FETCH_RELOAD_PORTFOLIO_INIT:
            return { ...state, loader: true };
        case ActionTypes.FETCH_RELOAD_PORTFOLIO_SUCCESS:
            return {
                ...state,
                loader: false,
                reloadPortfolio: true
            };
        case ActionTypes.FETCH_RELOAD_PORTFOLIO_ERROR:
            return {
                ...state,
                loader: false
            };
        case ActionTypes.FETCH_INVESTMENT_HOLDINGS_INIT:
        case ActionTypes.FETCH_INVESTMENT_TRANSACTIONS_INIT:
        case ActionTypes.FETCH_PORTFOLIO_VALUATION_DATA_INIT:
            return { ...state, loader: true };
        case ActionTypes.FETCH_INVESTMENT_HOLDINGS_ERROR:
        case ActionTypes.FETCH_INVESTMENT_TRANSACTIONS_ERROR:
        case ActionTypes.FETCH_PORTFOLIO_VALUATION_DATA_ERROR:
            return { ...state, loader: false };
        case ActionTypes.FETCH_INVESTMENT_HOLDINGS_SUCCESS:
            return { ...state, loader: false, bankPortfolioData: action.data };
        case ActionTypes.FETCH_INVESTMENT_TRANSACTIONS_SUCCESS:
            return { ...state, loader: false, bankPortfolioTransactions: action.data };
        case ActionTypes.FETCH_PORTFOLIO_VALUATION_DATA_SUCCESS:
            return { ...state, loader: false, portfolioValuationData: action.data };
        default:
            return state;
    }
};
