import React from 'react';
import { Card, CardContent, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { QuesAnsBox } from 'assets/jss/RetirementStyle';
import { getCookie } from 'utils/Utils';
import CalSignupBlock from './CalSignupBlock';
const RetirementDescBlock = ({ classes, control, handleOnChange, setValue, ...rest }) => {
    return (
        <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12} md={(getCookie('auth_token') && 12) || 8}>
                <Card>
                    <CardContent>
                        <QuesAnsBox>
                            <Typography variant="h2" id="description" sx={{ mb: 2, display: 'flex' }}>
                                About retirement calculator
                            </Typography>
                            <Typography variant="body1" className="dashboard-subheading" sx={{ mb: 1.5 }}>
                                Our retirement calculator provides the optimal mix between user friendliness and detail.
                            </Typography>
                            <Typography variant="body1" className="dashboard-subheading" sx={{ mb: 1.5 }}>
                                Our calculator is prepopulated with sample data so you can hit the ground running in trying out the
                                calculator. We also allow you to save your data so that you can work on it and update it over time without
                                having to re-enter your data. Once you save the data you will see a Click-Me button at the top to reload.
                                Remember to save any changes you make.
                            </Typography>
                            <Typography variant="body1" className="dashboard-subheading" sx={{ mb: 1.5 }}>
                                Some of the inputs we use may require explaining:
                            </Typography>
                            <Typography variant="body1" className="dashboard-subheading" sx={{ mb: 1.5 }}>
                                1) <span className="bold-font">Gender and Current Age:</span> These are both used to calculate mortality.
                                The mortality age is used to assess how large your estate will be when you pass as well allowing you to
                                ensure that you don’t outlive your assets and savings. We use tables provided by the US social security
                                department to estimate mortality.
                            </Typography>
                            <Typography variant="body1" className="dashboard-subheading" sx={{ mb: 1.5 }}>
                                2) <span className="bold-font">Mortality:</span> Is a calculated field as noted above, however you can
                                override the Mortality assumptions in the Additional Optional Assumptions section at the bottom of the
                                calculator.
                            </Typography>
                            <Typography variant="body1" className="dashboard-subheading" sx={{ mb: 1.5 }}>
                                3) <span className="bold-font">Life Events:</span> Life events are additional assumptions that allow you to
                                account for certain finite expenses such as a child’s college. We allow you to set up to four life events.
                                You need to enter the number as a positive number. Future releases will allow you to include positive life
                                events such as an inheritance.
                            </Typography>
                            <Typography variant="body1" className="dashboard-subheading" sx={{ mb: 1.5 }}>
                                4) <span className="bold-font">Additional Optional Assumptions:</span> There are three additional
                                assumptions you can adjust from our recommended assumptions. TPE sets these assumptions, but you are free to
                                adjust them. These inputs include investment returns, inflation, and mortality. Further, we reduce the
                                investment returns by an estimated blended tax rate.
                            </Typography>
                            <Typography variant="body1" className="dashboard-subheading" sx={{ mb: 2 }}>
                                5)<span className="bold-font"> Taxes:</span> Though we do adjust investment returns for tax rates, we don’t
                                adjust your regular income inputs for taxes. It’s important that you consider taxes in your inputs for these
                                items. For example, income inputs should be reduced by your estimated tax rate (you can use our tax
                                calculator to give you a very rough estimate of your average tax rate). In addition, pre-tax retirement
                                funds such as 401Ks and IRAs will be entirely taxed upon distribution. This tax should be considered when
                                assessing the output. Future versions of the TPE Retirement Calculator will incorporate these items
                            </Typography>
                            <Typography variant="subheading2" sx={{ mb: 2, display: 'flex' }}>
                                About calculator outputs
                            </Typography>
                            <Typography variant="body1" className="dashboard-subheading" sx={{ mb: 1.5 }}>
                                We output four variables – Net worth at retirement, final estate - which is a nicer way of saying net worth
                                upon death, and mortality age, which will either be TPE’s calculation or your own override. We also output a
                                chart allowing you to see your net worth growth and your income and expenses before and after retirement.
                                This chart is a simplification as it doesn’t include the investment income earned or the life events. The
                                net worth will change by the difference between income and expense (before and after retirement), the effect
                                of life events, inflation and investment returns.
                            </Typography>
                        </QuesAnsBox>
                    </CardContent>
                </Card>
            </Grid>
            {!getCookie('auth_token') && (
                <Grid item xs={12} md={4}>
                    <CalSignupBlock />
                </Grid>
            )}
        </Grid>
    );
};

export default RetirementDescBlock;
