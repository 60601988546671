import { Card, CardContent, Divider, Grid, IconButton, InputAdornment } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CachedIcon from '@mui/icons-material/Cached';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { CustomButton } from 'assets/jss/ButtonStyle';
import { CalCardActions } from 'assets/jss/RetirementStyle';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { StudLabelGrid } from 'assets/jss/CommonCalStyle';
import { StudInputGrid } from 'assets/jss/CommonCalStyle';
import { fetchSavedData, fetchSavedDataSuccess, homeValuationCalSuccess, submitHomeValuationCal } from 'redux/actions/HomeCalActions';
import { StateList } from 'States.json';
import { RegionList } from 'Regions.json';
import RenderField from 'utils/RenderField';
import { getCookie } from 'utils/Utils';
import CustomTooltip from 'components/CommonComponent/CustomTooltip';
import * as tooltip from 'Tooltip.json';

const defaultValues = {
    purchase_year: '1996',
    purchase_quarter: '2',
    purchase_price: '1000000',
    property_name: '',
    adjustment_multiplier: 1
    // state: ''
    // region_name: ''
};
const ProductSection = (props) => {
    const apiToken = getCookie('auth_token');
    const dispatch = useDispatch();
    const [result, setResult] = useState([]);
    const [disabledLoadBtn, setDisabledLoadBtn] = useState(false);
    const [selectedOption, setSelectedOption] = useState('');

    const {
        handleSubmit,
        control,
        formState: { isDirty, isSubmitting },
        setValue,
        getValues
    } = useForm({
        mode: 'onChange',
        defaultValues
    });

    const [stateFieldType, setStateFieldType] = useState('searchSelect');

    const startingYear = 1991,
        endingYear = new Date().getFullYear();
    const diffYear = endingYear - startingYear;
    const fetchHomeVal = () => {
        if (apiToken) dispatch(fetchSavedData());
    };
    const onSubmit = (data) => {
        const purPrice = data.purchase_price.toString().replace(/,/g, '');
        let state;
        if(typeof(data.state) == 'object'){
            state = data.state.value
        } else{
            state = data.state
        }
        let objToPass = {
            purchase_year: data.purchase_year,
            purchase_quarter: data.purchase_quarter,
            state: state,
            region_name: data.region_name ? data.region_name.label : '',
            purchase_price: purPrice,
            property_name: data.property_name,
            adjustment_multiplier: data.adjustment_multiplier
        };
        // onSubmit(getValues())
        if (getValues('save_data')) {
            if (!apiToken) {
                toast.error('Please login to save data!');
                return;
            }
            objToPass = { ...objToPass, save_data: getValues('save_data') };
        }
        dispatch(submitHomeValuationCal(objToPass, fetchHomeVal));
    };
    /**
     * @author disha
     * @use to set the ouput of api into state
     */
    useEffect(() => {
        if (Object.keys(props.homeValuationData.homeValuationDetail).length) {
            setResult(props.homeValuationData.homeValuationDetail.data);
        } else {
            // to reset the output fields
            if (result.length > 0) setResult([]);
        }
    }, [props.homeValuationData]);

    // to reset redux stored value of result when page is changing
    useEffect(() => {
        if (apiToken) dispatch(fetchSavedData());
        return () => {
            dispatch(homeValuationCalSuccess({}));
            dispatch(fetchSavedDataSuccess({}));
        };
    }, []);

    // to load saved data in form
    const handleLoadData = () => {
        setValue('save_data', false);
        setDisabledLoadBtn(true);
        const savedData = props.homeValuationData.savedHomeValuationDetail;
        for (const key in savedData) {
            if (Object.hasOwnProperty.call(savedData, key)) {
                let element = savedData[key];
                if (key === 'state') {
                    const list = handleStateOptions();
                    element = list.filter((val) => val.value === element)[0];
                }
                if (key === 'region_name') {
                    const list = handleRegionOptions();
                    element = list.filter((val) => val.label === element)[0];
                }
                setValue(key, element);
            }
        }
    };

    const handleSelectChange = (event, label) => {
        if (event.target.innerText) {
            setSelectedOption(label);
            if (label === 'City') {
                const selectedCity = event.target.innerText;
                const stateCode = selectedCity.split(', ')[1];
                 
                if (stateCode) {
                    setStateFieldType('text');
                    setValue('state', stateCode);
                    
                }
            }
        } else {
            setSelectedOption('');
        } // Update selectedOption when the selection changes
    };

    /**
     * @author disha
     * @use to reset the output if there is modification in form
     */
    const handleFormInputChange = () => {
        if (disabledLoadBtn) {
            setDisabledLoadBtn(false);
        }
        // reset saved output from redux state
        if (isDirty && result.length > 0) {
            dispatch(homeValuationCalSuccess({}));
        }
    };

    const handleStateOptions = () => {
        const list = StateList.map((val) => {
            return {
                value: val.abb,
                label: val.name + ` (${val.abb})`
            };
        });
        return list;
    };
    const handleRegionOptions = () => {
        const list = RegionList.map((val) => {
            return {
                label: val.name
            };
        });
        return list;
    };

    return (
        <>
            <Card elevation={2}>
                <CardContent>
                    {Object.keys(props.homeValuationData.savedHomeValuationDetail).length ? (
                        <Box color="text.primary">
                            <Typography variant="body2" component="p">
                                We have your saved data. Click button to load it.
                                <IconButton
                                    color="primary"
                                    aria-label="upload picture"
                                    component="label"
                                    disabled={disabledLoadBtn}
                                    onClick={handleLoadData}
                                >
                                    <CachedIcon color="white" />
                                </IconButton>
                                <Divider sx={{ mb: 1 }} />
                            </Typography>
                        </Box>
                    ) : (
                        ''
                    )}
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container>
                            <Grid item xs={12} md={6}>
                                <RenderField
                                    handleOnChange={handleFormInputChange}
                                    name="purchase_year"
                                    control={control}
                                    label="Purchase Year"
                                    id="purchase_year"
                                    options={[...Array(diffYear + 1)].map((_, index) => {
                                        const currVal = startingYear + index;
                                        return { value: currVal, label: currVal };
                                    })}
                                    type="select"
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <RenderField
                                    handleOnChange={handleFormInputChange}
                                    name="purchase_quarter"
                                    control={control}
                                    label="Purchase Quarter"
                                    id="purchase_quarter"
                                    options={[
                                        { value: 1, label: 1 },
                                        { value: 2, label: 2 },
                                        { value: 3, label: 3 },
                                        { value: 4, label: 4 }
                                    ]}
                                    type="select"
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <RenderField
                                    handleOnChange={handleFormInputChange}
                                    name="purchase_price"
                                    control={control}
                                    label="Purchase Price"
                                    id="purchase_price"
                                    type="number"
                                    required
                                    prefix={'$'}
                                    thousandSeparator={true}
                                    customPatternName="price"
                                    placeholder="100,000"
                                    setValue={setValue}
                                />
                            </Grid>
                            <Grid display="flex" alignItems="center" item xs={12} md={6}>
                                <RenderField
                                    handleOnChange={(event) => {
                                        handleFormInputChange(event);
                                        handleSelectChange(event, 'State Code');
                                    }}
                                    name="state"
                                    control={control}
                                    label="State Code"
                                    id="state"
                                    options={handleStateOptions()}
                                    // disabled={selectedOption === 'City'}
                                    renderOption={(props, option) => {
                                        return (
                                            <Box component="li" sx={{ m: 1 }} {...props}>
                                                <Typography variant="body2">{option.label}</Typography>
                                            </Box>
                                        );
                                    }}
                                    type={stateFieldType}
                                />
                                {/* <InputAdornment position="end">
                                    <CustomTooltip tooltipText={tooltip.default?.['State Code']} label="State Code" />
                                </InputAdornment> */}
                            </Grid>
                            <Grid display="flex" alignItems="center" item xs={12} md={6}>
                                <RenderField
                                    handleOnChange={(event) => {
                                        handleFormInputChange(event);
                                        handleSelectChange(event, 'City');
                                    }}
                                    name="region_name"
                                    control={control}
                                    label="City"
                                    id="region_name"
                                    options={handleRegionOptions()}
                                    disabled={selectedOption === 'State Code'}
                                    renderOption={(props, option) => {
                                        return (
                                            <Box component="li" sx={{ m: 1 }} {...props}>
                                                <Typography variant="body2">{option.label}</Typography>
                                            </Box>
                                        );
                                    }}
                                    type="searchSelect"
                                />
                                <InputAdornment position="end">
                                    <CustomTooltip tooltipText={tooltip.default?.['City']} label="City" />
                                </InputAdornment>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <RenderField
                                    handleOnChange={handleFormInputChange}
                                    name="property_name"
                                    control={control}
                                    label="Property Name"
                                    id="property_name"
                                    type="text"
                                    setValue={setValue}
                                    style={{ marginRight: 0 }}
                                    // customPatternName = "name_no_space"
                                />
                            </Grid>
                            <StudLabelGrid item xs={12} md={7} lg={7}>
                                <Typography gutterBottom style={{ marginLeft: '8px' }}>
                                    Adjustment multiplier
                                </Typography>
                            </StudLabelGrid>
                            <StudInputGrid item xs={12} md={5} lg={5}>
                                <RenderField
                                    type="slider"
                                    control={control}
                                    name="adjustment_multiplier"
                                    id="adjustment_multiplier"
                                    max={1.5}
                                    min={0.5}
                                    step={0.1}
                                    valueLabelDisplay="on"
                                    valueLabelFormat={(value) => value.toFixed(1)}
                                    handleOnChange={handleFormInputChange}
                                />
                            </StudInputGrid>
                        </Grid>
                        <CalCardActions>
                            <Grid container style={{ width: '100%', textAlign: 'center' }}>
                                <Grid item xs={12} md={6}>
                                    <CustomButton
                                        type="submit"
                                        ps={'9px 30px'}
                                        onClick={() => setValue('save_data', false)}
                                        variant="contained"
                                        // color="rose"
                                        disabled={isSubmitting}
                                    >
                                        Calculate
                                    </CustomButton>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <CustomButton
                                        type="button"
                                        ps={'9px 30px'}
                                        onClick={() => {
                                            setValue('save_data', true);
                                            onSubmit(getValues());
                                        }}
                                        variant="outlined"
                                        // color="success"
                                        disabled={isSubmitting}
                                    >
                                        Save
                                    </CustomButton>
                                </Grid>
                            </Grid>
                        </CalCardActions>
                    </form>
                </CardContent>
            </Card>
        </>
    );
};

export default ProductSection;
