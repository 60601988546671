import React from 'react';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { IconButton, ListItem, ListItemButton, Popover, Typography } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import { StyledPortfolioBreadcrumb } from 'assets/jss/CommonCalStyle';
import { StyledBreadCrumbLink } from 'assets/jss/GeneralStyle';
import { useEffect, useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { WatchlistListParent } from 'assets/jss/DashboardStyle';
import CustomInput from 'components/CustomInput/CustomInput';
import { CustomButton } from 'assets/jss/ButtonStyle';
import DoneIcon from '@mui/icons-material/Done';
import { useDispatch } from 'react-redux';
import { saveWatchListData } from 'redux/actions/EquityDashboardActions';
import { handleNewDataFetched } from 'redux/actions/EquityDashboardActions';
import { updateWatchListData } from 'redux/actions/EquityDashboardActions';
import { deleteWatchListData } from 'redux/actions/EquityDashboardActions';
var refetchVal = false;
const WatchListBreadcrumb = (props) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const dispatch = useDispatch();
    const [watchListVal, setWatchListVal] = useState({});
    const [enableRename, setEnableRename] = useState(false);
    const [actionMenuDetail, setActionMenuDetail] = useState({});
    const [watchlistname, setListRename] = useState(null);
    const openBredcrumbDropdown = Boolean(anchorEl);
    const id = openBredcrumbDropdown ? 'simple-popover' : undefined;
    const [anchorActionEl, setAnchorActionEl] = useState(null);
    const openActionDropdown = Boolean(anchorActionEl);
    const actionpopid = openActionDropdown ? 'action-simple-popover' : undefined;

    useEffect(() => {
        //auto select first portfolio file
        if (Object.keys(watchListVal).length === 0 || refetchVal) {
            if (props.list.length > 0) {
                refetchVal = false;
                handleListItemClick(props.list?.[0]);
            }
        }
    }, [props.list]);
    const handleListItemClick = (value) => {
        props.selectedWatchlist(value);
        setWatchListVal(value);
        setAnchorEl(null);
    };
    const handleEnableEditList = () => {
        setAnchorActionEl(null);
        setListRename(actionMenuDetail.name);
        setEnableRename(actionMenuDetail);
    };
    const handleDeleteList = () => {
        setAnchorActionEl(null);
        setAnchorEl(null);
         props.deleteList(actionMenuDetail);
         if (watchListVal.id === actionMenuDetail.id) {
             setWatchListVal({});
         }
        //  dispatch(handleNewDataFetched(true));
        dispatch(deleteWatchListData({ id: actionMenuDetail.id }, 'watchlist', 'delete'));
    };
    const submitUpdatedName = (val) => {
        refetchVal = true;
        dispatch(updateWatchListData({ name: watchlistname, id: val.id }, 'watchlist', 'put'));
        setAnchorActionEl(null);
        setAnchorEl(null);
        resetRenameStates();
    };
    const handleLinkClicked = (e) => {
        setAnchorActionEl(null);
        setAnchorEl(e.currentTarget);
        resetRenameStates();
    };
    const resetRenameStates=()=>{
        setEnableRename(false);
        setListRename(null);
    }
    return (
        <>
            {Object.keys(watchListVal).length > 0 ? (
                <StyledPortfolioBreadcrumb>
                    <Link color="text.primary" underline="none" component="button">
                        <Typography variant="subtitle1">Your Watchlist(s)</Typography>
                    </Link>
                    <StyledBreadCrumbLink
                        className="list-link"
                        color="textPrimary"
                        onClick={(e) => handleLinkClicked(e)}
                        aria-describedby={id}
                        underline="none"
                    >
                        <Typography className="title-list">{watchListVal.name} </Typography>
                        {openBredcrumbDropdown ? <ExpandLess className={'arrow-icon'} /> : <ExpandMore className={'arrow-icon'} />}
                    </StyledBreadCrumbLink>
                </StyledPortfolioBreadcrumb>
            ) : (
                ''
            )}
            <Popover
                id={id}
                open={openBredcrumbDropdown}
                anchorEl={anchorEl}
                onClose={(e) => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
            >
                {props.list.map((val, listIndex) => {
                    return (
                        <WatchlistListParent
                            key={'watchlist_breadcrumbList' + listIndex}
                            component="nav"
                            aria-label="nested-list-subheader"
                        >
                            <Collapse in={openBredcrumbDropdown} timeout="auto" unmountOnExit>
                                <ListItem
                                    secondaryAction={
                                        enableRename?.id !== val.id ? (
                                            <IconButton
                                                edge="end"
                                                aria-label="Actions"
                                                onClick={(event) => {
                                                    setAnchorActionEl(event.currentTarget);
                                                    setActionMenuDetail(val);
                                                }}
                                            >
                                                <MoreVertIcon />
                                            </IconButton>
                                        ) : (
                                            <IconButton edge="end" aria-label="Save" onClick={()=>submitUpdatedName(val)}>
                                                <DoneIcon />
                                            </IconButton>
                                        )
                                    }
                                    disablePadding
                                >
                                    {enableRename?.id !== val.id ? (
                                        <ListItemButton selected={watchListVal.id === val.id} onClick={(event) => handleListItemClick(val)}>
                                            <ListItemText primary={val.name} id={val.id} />
                                        </ListItemButton>
                                    ) : (
                                        <ListItemButton selected={watchListVal.id === val.id}>
                                            <CustomInput
                                                labelText=""
                                                value={watchlistname}
                                                id="watchlist-rename"
                                                type="text"
                                                CustomLabelProps={{ shrink: true }}
                                                placeholder="Enter new name"
                                                onChange={(e) =>  setListRename(e.target.value)}
                                            />
                                        </ListItemButton>
                                    )}
                                </ListItem>
                            </Collapse>
                        </WatchlistListParent>
                    );
                })}
            </Popover>
            <Popover
                id={actionpopid}
                open={openActionDropdown}
                anchorEl={anchorActionEl}
                onClose={(e) => setAnchorActionEl(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
            >
                <List component="nav" aria-label="nested-list-subheader">
                    <ListItemButton onClick={handleDeleteList}>
                        <ListItemText primary={'Delete'} id={'delete-list'} />
                    </ListItemButton>
                </List>
                <List component="nav" aria-label="nested-list-subheader">
                    <ListItemButton onClick={() => handleEnableEditList()}>
                        <ListItemText primary={'Rename'} id={'edit-list'} />
                    </ListItemButton>
                </List>
            </Popover>
        </>
    );
};

export default WatchListBreadcrumb;
