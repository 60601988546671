import { styled } from '@mui/styles';
import { Box, CardHeader, Grid } from '@mui/material';

export const ChatbotParentBox = styled(Box)(({ theme }) => ({
           '& .botplatformbox': {
               display: 'inline',
               width: '64px',
               height: '64px',
               padding: '0px',
               backgroundColor: 'transparent',
               backgroundSize: '64px',
               backgroundImage: 'url(https://www.botlibre.com/https://alice.botlibre.com/images/green_bot_button128.png)'
           },
           '& #sdkvideoplaybutton2': {
               display: 'none'
           }
       }));
export const ChatbotFabBtn = styled(Box)(({ theme }) => ({
           bottom: theme.spacing(2),
           right: theme.spacing(2),
           boxShadow: theme.shadows[4],
           transition:
               'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
           zIndex: '99',
           position: 'fixed',
           borderRadius: '50%',
           backgroundImage: 'url(/img/chatbot-icon.svg)',
           width: '64px',
           height: '64px',
           cursor: 'pointer',
           backgroundColor: theme.palette.primary.light,
           backgroundPosition: 'center',
           backgroundRepeat: 'no-repeat',
           '&:hover': {
               backgroundColor: '#00778B'
           },
           '&.todoFab': {
               backgroundColor: theme.palette.primary.main,
               bottom: 94,
               backgroundImage: 'url(img/todoicon.png)'
           }
       }));
export const BotCardHeader = styled(CardHeader)(({ theme }) => ({
    color: 'inherit',
    background: theme.palette.grey['50'],
    padding: '10px 13px'
}));
export const TypeBox = styled(Box)(({ theme }) => ({
    display:'flex',
    justifyContent:'space-around',
   "& .type-active":{
    backgroundColor:theme.palette.primary.main,
    color:theme.palette.text.light
   }
}));
export const ChatbotFooterBox = styled(Box)(({ theme }) => ({
    display: 'inline-flex',
    padding: 0,
    width:'100%'
}));
export const ChatboxForm = styled('form')(({ theme }) => ({
        margin: 0,
        '& .MuiCardContent-root': {
            padding: '10px'
        },
        '& .MuiCardContent-root .MuiPaper-root': {
            paddingBottom: '4px',
            borderRadius: 0
        },
        '& .botplatformframebubble-div': {
            display: 'block'
        },
        '& .chat-input': {
            maxWidth: 'none',
            margin: '0 0 0 0',
            padding: '0',
            width: '100%'
        },
        '& .botplatformframechat-input': {
            maxWidth: 'none',
            margin: '0 0 0 0',
            padding: '0 8px',
            border: '1px solid #d5d5d5',
            fontSize: '13px',
            height: '33px',
            width: '100%'
        },
        '& #sendicon': { margin: '0 0 0 5px', padding: '0 8px', height: 'inherit' },
        '& .botplatformframebubble': {
            padding: '8px',
            border: '1px',
            display: 'block',
            color: '#585858',
            borderStyle: 'solid',
            borderColor: '#eeeeee',
            borderRadius: '10px',
            backgroundColor: '#eeeeee',
            '&:after': {
                content: '""',
                position: 'absolute',
                bottom: '1px',
                left: '43px',
                borderWidth: '16px 0 0 15px',
                borderStyle: 'solid',
                borderColor: '#eeeeee transparent',
                display: 'block',
                width: '0'
            },
            '&:before': {
                borderColor: '#0000008a transparent',
                content: '""',
                position: 'absolute',
                bottom: '-2px',
                left: '40px',
                borderWidth: '18px 0 0 20px',
                borderStyle: 'solid',
                display: 'block',
                width: '0'
            }
        },
        '& #botplatformframebubble-text': {
            overflow: 'hidden auto',
            overflowWrap: 'break-word',
            maxWidth: '296px',
            maxHeight: '70px'
        },
        '& .avatarBoxCenter': { display: 'flex !important', margin: 'auto' },
        '& #botplatformframebubble-div': {
            paddingBottom: '15px',
            position: 'relative'
        },
        '& #botplatformframeavatarbox': {
            display: 'flex',
            margin: 'auto'
        },
        '& #botplatformframeavatar-image-div': {
            display: 'none',
            width: '175px',
            '&img': { width: '175px' }
        },
        '& #botplatformframeavatar-video-div': {
            display: 'inline-block',
            width: '175px',
            minHeight: '200px',
            backgroundSize: '200px',
            backgroundRepeat: 'no-repeat',
            backgroundImage: 'url(https://www.botlibre.com/media/a12546662-0.png)',
            position: 'fixed',
            opacity: '0',
            zIndex: '-1',
            '&video': { background: 'transparent', width: '175px' }
        },
        '& #botplatformframeavatar-canvas-div': {
            margin: 'auto',
            display: 'inline-block',
            width: '175px',
            minHeight: '200px'
        },
        '& #botplatformframeavatar-canvas': {
            background: 'transparent',
            width: '175px'
        }
}));
// const chatBotPage = (theme) => ({
//     chatbot: {
//         opacity: '1',
//         right: '14px',
//         bottom: '10px',
//         zIndex: '1100',
//         position: 'fixed !important',
//         width: '23.44rem',
//         minHeight: '250px',
//         maxHeight: '595px',
//         boxShadow: '0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)',
//         borderRadius: '8px',
//         overflow: 'hidden',
//         top: 'revert !important',
//         transition: 'width 200ms ease 0s, height ease 0s, max-height ease 0s',
//         transform: 'unset !important',
//         left: 'revert !important',
//         willChange: 'unset !important',
//         '& .MuiButtonBase-root': {
//             padding: '6px'
//         },
//         [theme.breakpoints.down('xs')]: {
//             width: '18rem'
//         }
//     },
//     botplatformchatBot: {
//         height: '50px',
//         display: 'inline-block',
//         maxWidth: '100%'
//     },
//     chatLogAvatar: {
//         margin: 'auto',
//         width: '50px',
//         display: 'flex',
//         alignItems: 'center',
//         paddingTop: '2px',
//         paddingBottom: '4px'
//     },
//     chatLogDivider: {
//         marginTop: '-2px',
//         marginBottom: '20px',
//         border: '0',
//         borderTop: '1px solid #eee'
//     },
//     chatLogMsgWrapper: {
//         display: 'inline-block',
//         height: '250px',
//         overflowX: 'hidden',
//         width: '100%'
//     },
//     typography: {
//         padding: theme.spacing(2)
//     },
//     fab: {
//         bottom: theme.spacing(2),
//         right: theme.spacing(2),
//         boxShadow: '0 3px 5px -1px rgba(9, 170, 189, 0.51), 0 6px 10px 0px rgba(0, 0, 0, 0.12),0 1px 18px 0px rgba(0, 0, 0, 0.2)',
//         transition:
//             'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
//         zIndex: '99',
//         position: 'fixed',
//         borderRadius: '50%',
//         backgroundImage: 'url(/img/chatbot-icon.svg)',
//         display: 'inline',
//         width: '64px',
//         height: '64px',
//         padding: '0px',
//         backgroundColor: '#05acc1',
//         backgroundSize: '44px',
//         backgroundPosition: 'center',
//         backgroundRepeat: 'no-repeat',
//         '&:hover': {
//             backgroundColor: '#00778B'
//         }
//     },
//     media: {
//         height: 0,
//         paddingTop: '56.25%', // 16:9
//         width: '200px',
//         margin: 'auto'
//     },
//     expand: {
//         transform: 'rotate(0deg)',
//         marginLeft: 'auto',
//         transition: theme.transitions.create('transform', {
//             duration: theme.transitions.duration.shortest
//         })
//     },
//     expandOpen: {
//         transform: 'rotate(180deg)'
//     },
//     avatar: {
//         backgroundColor: red[500]
//     },
//     botHeader: {
//         color: 'inherit',
//         background: 'rgba(200, 200, 200, 0.2)'
//     },
//     paper: {
//         width: '80vw',
//         height: '80vh',
//         maxWidth: '500px',
//         maxHeight: '700px',
//         display: 'flex',
//         alignItems: 'center',
//         flexDirection: 'column',
//         position: 'relative'
//     },
//     paper2: {
//         width: '80vw',
//         maxWidth: '500px',
//         display: 'flex',
//         alignItems: 'center',
//         flexDirection: 'column',
//         position: 'relative'
//     },
//     container: {
//         width: '100vw',
//         height: '100vh',
//         display: 'flex',
//         alignItems: 'center',
//         justifyContent: 'center'
//     },
//     messagesBody: {
//         width: 'calc( 100% - 20px )',
//         margin: 10,
//         overflowY: 'scroll',
//         height: 'calc( 100% - 80px )'
//     },
//     messageRow: {
//         display: 'flex'
//     },
//     messageRowRight: {
//         display: 'flex',
//         justifyContent: 'flex-end'
//     },
//     messageBlue: {
//         position: 'relative',
//         marginLeft: '20px',
//         marginBottom: '10px',
//         padding: '10px',
//         backgroundColor: '#00acc136',
//         textAlign: 'left',
//         border: '1px solid  #00acc1b5',
//         borderRadius: '10px',
//         '&:after': {
//             content: "''",
//             position: 'absolute',
//             width: '0',
//             height: '0',
//             borderTop: '15px solid #ccebfe',
//             borderLeft: '15px solid transparent',
//             borderRight: '15px solid transparent',
//             top: '0',
//             left: '-15px'
//         },
//         '&:before': {
//             content: "''",
//             position: 'absolute',
//             width: '0',
//             height: '0',
//             borderTop: '17px solid #00acc1b5',
//             borderLeft: '16px solid transparent',
//             borderRight: '16px solid transparent',
//             top: '-1px',
//             left: '-17px'
//         }
//     },
//     messageOrange: {
//         position: 'relative',
//         marginRight: '20px',
//         marginBottom: '10px',
//         padding: '10px',
//         backgroundColor: '#f0689763',
//         textAlign: 'left',
//         border: '1px solid #d48ea7',
//         borderRadius: '10px',
//         '&:after': {
//             content: "''",
//             position: 'absolute',
//             width: '0',
//             height: '0',
//             borderTop: '15px solid #f9c4d7',
//             borderLeft: '15px solid transparent',
//             borderRight: '15px solid transparent',
//             top: '0',
//             right: '-15px'
//         },
//         '&:before': {
//             content: "''",
//             position: 'absolute',
//             width: '0',
//             height: '0',
//             borderTop: '17px solid #ef9ab8',
//             borderLeft: '16px solid transparent',
//             borderRight: '16px solid transparent',
//             top: '-1px',
//             right: '-17px'
//         }
//     },
//     messageContent: {
//         padding: '0 0 7px 0',
//         margin: 0
//     },
//     messageTimeStampRight: {
//         position: 'absolute',
//         fontSize: '.85em',
//         fontWeight: '300',
//         bottom: '2px',
//         right: '5px'
//     },
//     form: {
//         margin: 0,
//         '& .MuiCardContent-root': {
//             padding: '10px'
//         },
//         '& .MuiCardContent-root .MuiPaper-root': {
//             paddingBottom: '4px',
//             borderRadius: 0
//         },
//         '& .botplatformframebubble-div': {
//             display: 'block'
//         },
//         '& .chat-input': {
//             maxWidth: 'none',
//             margin: '0 0 0 0',
//             padding: '0',
//             width: '100%'
//         },
//         '& .botplatformframechat-input': {
//             maxWidth: 'none',
//             margin: '0 0 0 0',
//             padding: '0 8px',
//             border: '1px solid #d5d5d5',
//             fontSize: '13px',
//             height: '33px',
//             width: '100%'
//         },
//         '& #sendicon': { margin: '0 0 0 5px', padding: '0 8px', height: 'inherit' },
//         '& .botplatformframebubble': {
//             padding: '8px',
//             border: '1px',
//             display: 'block',
//             color: '#585858',
//             borderStyle: 'solid',
//             borderColor: '#eeeeee',
//             borderRadius: '10px',
//             backgroundColor: '#eeeeee',
//             '&:after': {
//                 content: '""',
//                 position: 'absolute',
//                 bottom: '1px',
//                 left: '43px',
//                 borderWidth: '16px 0 0 15px',
//                 borderStyle: 'solid',
//                 borderColor: '#eeeeee transparent',
//                 display: 'block',
//                 width: '0'
//             },
//             '&:before': {
//                 borderColor: '#0000008a transparent',
//                 content: '""',
//                 position: 'absolute',
//                 bottom: '-2px',
//                 left: '40px',
//                 borderWidth: '18px 0 0 20px',
//                 borderStyle: 'solid',
//                 display: 'block',
//                 width: '0'
//             }
//         },
//         '& #botplatformframebubble-text': {
//             overflow: 'hidden auto',
//             overflowWrap: 'break-word',
//             maxWidth: '296px',
//             maxHeight: '100px'
//         },
//         '& .avatarBoxCenter': { display: 'flex !important', margin: 'auto' },
//         '& #botplatformframebubble-div': {
//             paddingBottom: '15px',
//             position: 'relative'
//         },
//         '& #botplatformframeavatarbox': {
//             display: 'flex',
//             margin: 'auto'
//         },
//         '& #botplatformframeavatar-image-div': {
//             display: 'none',
//             width: '175px',
//             '&img': { width: '175px' }
//         },
//         '& #botplatformframeavatar-video-div': {
//             display: 'inline-block',
//             width: '175px',
//             minHeight: '200px',
//             backgroundSize: '200px',
//             backgroundRepeat: 'no-repeat',
//             backgroundImage: 'url(https://www.botlibre.com/media/a12546662-0.png)',
//             position: 'fixed',
//             opacity: '0',
//             zIndex: '-1',
//             '&video': { background: 'transparent', width: '175px' }
//         },
//         '& #botplatformframeavatar-canvas-div': {
//             margin: 'auto',
//             display: 'inline-block',
//             width: '175px',
//             minHeight: '200px'
//         },
//         '& #botplatformframeavatar-canvas': {
//             background: 'transparent',
//             width: '175px'
//         }
//     },
//     chatbotFooterGrid: {
//         display: 'inline-flex',
//         padding: 0
//     },
//     todoFab: {
//         bottom: 94,
//         backgroundImage: 'url(img/todoicon.png)'
//     },
//     speechRecogParent: {
//         '& .MuiButtonBase-root ': {
//             borderRadius: '100%',
//             fontSize: '3em',
//             color: roseColor,
//             position: 'relative'
//         },
//         '& .pulse-ring': {
//             width: '100%',
//             height: '100%',
//             border: '5px solid ' + roseColor,
//             borderRadius: '50%',
//             position: 'absolute',
//             animation: `$pulsate infinite 1.5s`
//         }
//     },
//     '@-webkit-keyframes pulsate': {
//         '0%': {
//             WebkitTransform: 'scale(1, 1)',
//             opacity: 1
//         },
//         '100%': {
//             WebkitTransform: 'scale(1.3, 1.3)',
//             opacity: 0
//         }
//     },
//     '@keyframes pulsate': {
//         '0%': {
//             WebkitTransform: 'scale(1, 1)',
//             opacity: 1
//         },
//         '100%': {
//             WebkitTransform: 'scale(1.3, 1.3)',
//             opacity: 0
//         }
//     }
// });

