import React from 'react';
import { Card, CardContent, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { QuesAnsBox } from 'assets/jss/RetirementStyle';
import { getCookie } from 'utils/Utils';
import CalSignupBlock from 'views/RetirementCalculator/CalSignupBlock';
const DebtCalDesc = () => {
    return (
        <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12} md={(getCookie('auth_token') && 12) || 8}>
                <Card>
                    <CardContent>
                        <QuesAnsBox>
                            <Typography variant="h2" id="description" sx={{ mb: 2, display: 'flex' }}>
                                About debt calculator
                            </Typography>
                            <Typography variant="body1" className="dashboard-subheading" sx={{ mb: 1.5 }}>
                                Our debt calculator allows you to keep track of all your debt and to incorporate the impact of new debt. Our
                                calculator allows you to input a number of individual loans. The most important information is the principal
                                amount, interest rate, maturity or term (in years) and, repayment type (interest only or principal and
                                interest). We also require loan type but we don’t use it in the calculations at this time. Further we
                                calculate your Debt Ratio (AKA debt-to-income ratio) using either the income you entered in your personal
                                profile or the income you enter into this calculator.
                            </Typography>
                            <Typography variant="body1" className="dashboard-subheading" sx={{ mb: 1.5 }}>
                                The output of the debt calculator includes Total Debt (sum of current principal amount), Total Annual Debt
                                Payments (including principal and interest), Total Lifetime Interest and Debt Ratio (annual debt service to
                                income ratio). Further, under the lifetime balance tab we show a chart of your debt over time.{' '}
                            </Typography>
                            <Typography variant="body1" className="dashboard-subheading" sx={{ mb: 1.5 }}>
                                You can add new loans in three ways: 1) add a line item directly into the table using the + sign, 2) add a
                                new line item manually by clicking the “Add New Data” link and 3) upload a file by clicking the “Add New
                                Data” link. All fields entered after Debt Type are optional and are for future development. For example, a
                                future version will handle floating rate debt as well as allow the user to input a manual payment
                                (especially important for credit card debt). Future versions will also incorporate typical credit card
                                repayment terms.
                            </Typography>
                        </QuesAnsBox>
                    </CardContent>
                </Card>
            </Grid>
            {!getCookie('auth_token') && (
                <Grid item xs={12} md={4}>
                    <CalSignupBlock />
                </Grid>
            )}
        </Grid>
    );
};

export default DebtCalDesc;
