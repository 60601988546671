import { Box, Button, Card, Grid, Paper, Typography } from '@mui/material';
import { styled } from '@mui/styles';
export const BudgetNotePaper = styled(Paper)(({ ps, theme }) => {
    return {
        display: 'flex',
        margin: '10px 0 '
    };
});
export const AllocationCard = styled(Card)(({ ps, theme }) => {
    return {
        '& .input-label': {}
    };
});
export const AllocationLabelGrid = styled(Grid)(({ ps, theme }) => {
    return {
        margin: 'auto'
    };
});
export const AllocationOutputCard = styled(Card)(({ ps, theme }) => {
    return {
        maxHeight: '500px',
        overflow: 'scroll'
    };
});
export const TipsChartBox = styled(Box)(({ ps, theme }) => {
    return {
        '& .wrapper': {
            height: '400px',
            position: 'relative'
        },
        '& .graphContainer': {
            height: '400px',
            position: 'absolute',
            top: '0px',
            width: '100%'
        },
        '& .secondGraph': {
            height: '400px',
            top: '0px',
            width: '100%'
        },
        '& .tooltip': {
            padding: '10px',
            background: 'white',
            boxShadow: '0 3px 6px rgba(0,0,0,0.16),0 3px 6px rgba(0,0,0,0.23)'
        },
        '& .tooltip___div': { textAlign: 'start' },
        '& .chartDivParent': { position: 'relative', top: 'auto', right: 'auto', width: 'auto', height: '500px', zIndex: '0' },
        '& .chartWrapper': { position: 'absolute', top: '2px', bottom: '63px', width: '100%' }
    };
});
export const BoxBarChartTooltip = styled(Box)(({ ps, theme }) => {
    return {
        display: 'inline-block',
        margin: '0px',
        padding: '10px',
        backgroundColor: 'rgb(255, 255, 255)',
        border: '1px solid rgb(204, 204, 204)',
        whiteSpace: 'nowrap'
    };
});
export const BarChartInnerBox = styled(Box)(({ ps, theme }) => {
           return {
               display: 'flex',
               "& .tooltipLabel":{
                margin:0
               },
               "& .colorBox": {
    width: "12px",
    height: "12px",
    margin: "4px 7px 0 0",
  },
           };
       });
