const settingPage = (theme) => ({
    root: {
        marginTop: -255,
        '& .settingHeader': {
            color: '#555555',
            padding: 5,
            '& .MuiTypography-root': { marginTop: '0.35em' },
            '& .settingIcon': {
                position: 'relative',
                top: 4,
                '& svg': {
                    width: '0.9em'
                }
            }
        },
        '&#pricing-parent': {
            margin: '8px 0 0 0'
        }
    },
    pricingHeader: {
        textAlign: 'center'
    },
    priceCardParent: {
        textAlign: 'center',
        // boxShadow: "none",
        padding: 10,
        background: 'transparent',
        color: 'rgba(0, 0, 0, 0.87)',
        width: '100%',
        border: '0',
        display: 'flex',
        position: 'relative',
        fontSize: '.875rem',
        minWidth: '0',
        wordWrap: 'break-word',
        marginTop: '30px',
        borderRadius: '6px',
        marginBottom: '30px',
        flexDirection: 'column',
        '& .planName': {
            fontWeight: 600
        },
        '& .chooseplanbtn': {
            color: '#ffffff',
            fontWeight: 600,
            margin: '5px 45px'
            // backgroundColor: "#FFFFFF",
        },
        '&.selected': {
            color: 'white',
            background: 'linear-gradient(60deg,#ec407a,#d81b60)',
            boxShadow: '0 16px 38px -12px rgb(0 0 0 / 56%), 0 4px 25px 0px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(0 0 0 / 20%)',
            '& $priceheader small': {
                color: 'rgba(255, 255, 255, 0.8)'
            },
            '& ul li': {
                color: '#FFF',
                borderColor: 'rgba(255, 255, 255,0.3)'
            },
            '& li b': { color: '#FFF', fontWeight: '700' },
            '& .chooseplanbtn': {
                color: '#ffffff',
                fontWeight: 600,
                margin: '5px 45px',
                backgroundColor: '#e4b8c8a3'
            }
        },
        '&:hover': {
            color: 'white',
            background: 'linear-gradient(60deg,#ec407a,#d81b60)',
            boxShadow: '0 16px 38px -12px rgb(0 0 0 / 56%), 0 4px 25px 0px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(0 0 0 / 20%)',
            '& $priceheader small': {
                color: 'rgba(255, 255, 255, 0.8)'
            },
            '& ul li': {
                color: '#FFF',
                borderColor: 'rgba(255, 255, 255,0.3)'
            },
            '& li b': { color: '#FFF', fontWeight: '700' },
            '& .chooseplanbtn': {
                color: '#ffffff',
                fontWeight: 600,
                margin: '5px 45px',
                backgroundColor: '#e4b8c8a3'
            }
        },
        '& ul': {
            margin: 0,
            // margin: "10px auto",
            padding: 12,
            maxWidth: '100%',
            listStyle: 'none',
            '& li': {
                padding: '12px 0px',
                textAlign: 'center',
                borderBottom: '1px solid rgba(153, 153, 153,0.3)'
            },
            '& li b': { color: '#3C4858' }
        }
    },
    priceheader: {
        marginTop: 12,
        '& small': {
            height: '0',
            display: 'inline-flex',
            fontSize: 18,
            '&:first-child': {
                top: '-17px',
                position: 'relative',
                fontSize: '26px'
            }
        }
    },

    priceSelectBtn: {
        borderRadius: 30,
        color: '#999',
        backgroundColor: '#FFF'
    },
    fileInput: {
        textAlign: 'center',
        display: 'inline-block',
        marginBottom: '9px',
        marginTop: 9,
        '& input[type=file]': {
            display: 'none'
        },

        '& .thumbnail': {
            display: 'inline-block',
            marginBottom: '10px',
            overflow: 'hidden',
            textAlign: 'center',
            verticalAlign: 'middle',
            maxWidth: '360px',
            boxShadow: '0 5px 15px -8px rgb(0 0 0 / 24%), 0 8px 10px -5px rgb(0 0 0 / 20%)'
        },
        '& .thumbnail.img-circle': {
            borderRadius: '50%',
            maxWidth: '100px',
            marginTop: 15
        },
        '& .thumbnail>img': {
            maxHeight: '100%',
            width: '100%',
            minHeight: 100
        }
    },
    saveProfilebtn: {
        margin: 'auto',
        textAlign: 'center'
    },
    labelPlacementStart: {
        width: '100%',
        justifyContent: 'space-between'
    },
    switchlabel: {
        color: '#555555',
        cursor: 'pointer',
        paddingLeft: '0',
        fontSize: '14px',
        lineHeight: '1.428571429',
        fontWeight: '400',
        display: 'inline-flex',
        transition: '0.3s ease all',
        letterSpacing: 'unset'
    },
    activitySubLabel: {
        marginTop: 9
    },
    emailPushCol: { textAlign: 'center' },
    notiParent: {
        '& .notiCard': { marginTop: 5 },
        '& .notiSubHeaderDesc': { color: '#777', lineHeight: '1.2em' }
    },
    resetPassWrapper: {
        marginTop: 15
    },
    liOffertext: {
        textTransform: 'capitalize',
        wordBreak: 'break-all',
        display: 'flex',
        'align-items': 'flex-start',
        justifyContent: 'start',
        margin: '0 5px',
        '& svg': { height: '0.8em' },
        '& p': { margin: '0 15px 0 0' }
    },
    subscriptionTitle: {
        marginTop: 30
    },
    adminWrapper: {
        padding: 0
    },
    goldCard: {
        // background:
        //   "radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #9f7928 30%, #8A6E2F 40%, transparent 80%),\n                radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 8%, #D1B464 25%, #5d4a1f 62.5%, #5d4a1f 100%)",
        background: 'linear-gradient(to right, #BF953F, #FCF6BA, #B38728, #FBF5B7, #AA771C)',
        '& .moduleTitle': {
            marginTop: 10
        }
    },
    silverCard: {
        backgroundColor: '#b3cdd1',
        backgroundImage: 'linear-gradient(to right, #C0C0C0,#a9a9a9 ,#d3d3d3 ,#dcdcdc, #a9a9a9,#C0C0C0)'
    },

    platinumCard: {
        backgroundColor: '#b3cdd1',
        backgroundImage: 'linear-gradient(to right,#d3d1cc,#e2e5e4,#b3b0b0 ,#cbcaca, #e2e5e4,#cdcac6)'
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        maxWidth: 300
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
        maxHeight: 140,
        overflowY: 'scroll'
    },
    chip: {
        margin: 2
    },
    noLabel: {
        marginTop: theme.spacing(3)
    },
    planTitle: {
        fontWeight: 600,
        textAlign: 'center'
    },
    moduleSelect: {
        width: 192
    },
    trafficlightCard: {
        '& .tlcardcontent': {
            overflow: 'scroll'
        },
        '& .tlContainer': {
            [theme.breakpoints.down('xs')]: {
                width: 'max-content'
            }
        }
    },
    addnewDiv: {
        display: 'flex',
        '& .title': { marginTop: 14 }
    },
    subscriptionTable: {
        '& .MuiButtonBase-root p': {
            width: 'auto'
        }
    },
    billingHistoryWrapper: {
        marginTop: 20
    }
    // pricingRoot: {
    //   border:'1px solid',
    //   '& $commonCalContainer': {
    //     justifyContent: 'center',
    //     "& .MuiTypography-root": {
    //       margin:'auto'
    //     }
    //   }
    // }
});
export default settingPage;
