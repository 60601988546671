import { Card, CardContent, Dialog, DialogContent, Divider, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import NoDataFound from "components/CommonComponent/NoDataFound";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { getTrafficLight, handleDialogVisibility, submitTrafficLight } from "redux/actions/SettingActions";
import RenderField from "utils/RenderField";
import CustomDialogTitle from "components/DialogTitle";
import { CustomButton } from "assets/jss/ButtonStyle";
const moduleOption = [{ label: "Annuity/Pension Analysi", value: "annuitytool" }, { "label": "Budget Analysis", "value": "budget_ana" }, { "label": "Budget Calculator", "value": "budget_cal" }, { "label": "Debt Calculator", "value": "debtcalculator" }, { "label": "Equity Dashboard", "value": "equity_dash" }, { "label": "Fixed Income Dashboard", "value": "income_dash" }, { "label": "Fx Calculator", "value": "fx_cal" }, { "label": "Home Valuation Tool", "value": "question" }, { "label": "Life Insurance Calculator", "value": "life_insur_cal" }, { "label": "Loan Dashboard", "value": "loan_dash" }, { "label": "Option Pricing Calculator", "value": "option_pri_cal" }, { "label": "Portfolio Allocation", "value": "port_allo" }, { "label": "Portfolio Valuation", "value": "port_val" }, { "label": "Property Investment Tool", "value": "invest_cal" }, { "label": "Retirement Calculator", "value": "retire_cal" }, { "label": "Student Loan Calculator", "value": "student_loan_cal" }, { "label": "Tips Calculator", "value": "tips_cal" }, { "label": "Todo", "value": "todo" }];
const fieldsDetail = [
  { name: "module", label: "Module Category", type: 'select', options: moduleOption },
  { name: "green", label: "Green Light", type: 'number', custompattern: 'decimalRate', placeholder: '10',maxLength: 3 },
  { name: "yellow", label: "Yellow Light", type: 'number', custompattern: 'decimalRate', placeholder: '10',maxLength: 3 },
  { name: "red", label: "Red Light", type: 'number', custompattern: 'decimalRate', placeholder: '10',maxLength: 3 },
];

const TrafficLight = (props) => {
  const { classes, setting } = props;
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const {
    handleSubmit,
    control, reset,
    formState: { isSubmitting },
    setValue,
  } = useForm({
    mode: "onChange"
  });
  // submit the form
  const onSubmit = (data) => {
    const type = data.id ? 'put' : 'post';
    const objToSend = {
      green: data.greenUnFormattedValue || data.green,
      red: data.redUnFormattedValue || data.red,
      yellow: data.yellowUnFormattedValue || data.yellow,
      module: data.module,
      id: data.id
    }
    dispatch(submitTrafficLight(objToSend, type));
  }
  useEffect(() => {
    // once traffic light popup will submit to close that popup when api return success
    if (setting.trafficDialogVisiblity) {
      handleClose();
      dispatch(handleDialogVisibility(false));
    }
  }, [setting.trafficDialogVisiblity]);
  useEffect(() => {
    /// to fetch the default traffic light values on page load
    dispatch(getTrafficLight())
  }, []);

  const handleClickOpen = () => setOpen(true);
  // delete selected traffic module
  const deleteSelectedData = id => dispatch(submitTrafficLight({ 'id': id }, 'delete'));
  // enable to edit traffic module
  const handleEditData = val => {
    setOpen(true);
    // to load saved data in form
    for (const key in val) {
      if (Object.hasOwnProperty.call(val, key)) {
        let element = val[key];
        setValue(key, element);
      }
    }
  }
  //close popup
  const handleClose = () => {
   const resetObj = fieldsDetail.reduce((acc, cur) => ({ ...acc, [cur.name]: '' }), {});
    reset(resetObj || {});
    setOpen(false);
  }

  return (
      <>
          <Card elevation={2}>
              <CardContent>
                  <Tooltip title="Add" arrow>
                      <CustomButton variant="text" className="read-more" startIcon={<AddIcon />} aria-label="add" onClick={handleClickOpen}>
                          Add New Data
                      </CustomButton>
                  </Tooltip>
                  <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                      <CustomDialogTitle id="form-dialog-title" onClose={handleClose}>
                          Add New Traffic Module
                      </CustomDialogTitle>
                      <Divider />
                      <DialogContent>
                          <form onSubmit={handleSubmit(onSubmit)}>
                              <Grid container>
                                  {fieldsDetail.map((val, i) => {
                                      const optionsList = val.options;
                                      return (
                                          <Grid item xs={12} sm={6} key={i + 'addUserDialog'}>
                                              <RenderField
                                                  name={val.name}
                                                  control={control}
                                                  label={val.label}
                                                  id={val.name}
                                                  timeFormat={false}
                                                  type={val.type}
                                                  options={optionsList}
                                                  required={val.required || true}
                                                  thousandSeparator={val.commaSep}
                                                  customPatternName={val.custompattern}
                                                  placeholder={val.placeholder}
                                                  setValue={setValue}
                                                  className={val.name === 'date' ? classes.budgetDate : ''}
                                                  {...val}
                                              />
                                          </Grid>
                                      );
                                  })}
                              </Grid>
                              <Grid container direction="row" style={{ width: '100%', textAlign: 'center' }}>
                                  <Grid item xs={12}>
                                      <CustomButton type="submit" ps={'9px 30px'} variant="contained" disabled={isSubmitting}>
                                          Submit
                                      </CustomButton>
                                  </Grid>
                              </Grid>
                          </form>
                      </DialogContent>
                  </Dialog>

                  {setting.trafficlightData.length > 0 ? (
                      <Card className={`${classes.trafficlightCard} notiCard`}>
                          <CardContent className="tlcardcontent">
                              <Grid container spacing={2} className="tlContainer">
                                  <Grid item xs={12}>
                                      <Grid container spacing={0}>
                                          <Grid item xs={4} sm={3}>
                                              <Typography variant="h6">
                                                  Actions
                                              </Typography>
                                          </Grid>
                                          <Grid item xs={2} sm={3}>
                                              <Typography variant="h6">
                                                  Activity
                                              </Typography>
                                          </Grid>
                                          <Grid item xs={2}>
                                              <Typography variant="h6" textAlign={'center'}>
                                                  Green
                                              </Typography>
                                          </Grid>
                                          <Grid item xs={2}>
                                              <Typography variant="h6" textAlign={'center'}>
                                                  Yellow
                                              </Typography>
                                          </Grid>
                                          <Grid item xs={2}>
                                              <Typography variant="h6" textAlign={'center'}>
                                                  Red
                                              </Typography>
                                          </Grid>
                                      </Grid>
                                      <Divider sx={{ marginTop: '8px' }} />
                                      <Grid container spacing={2}>
                                          {setting.trafficlightData.map((val, i) => {
                                              return (
                                                  <>
                                                      <Grid item xs={4} sm={3}>
                                                          <Tooltip title="Delete">
                                                              <IconButton aria-label="delete" onClick={() => deleteSelectedData(val.id)}>
                                                                  <DeleteIcon />
                                                              </IconButton>
                                                          </Tooltip>
                                                          <Tooltip title="Edit">
                                                              <IconButton aria-label="edit" onClick={(e) => handleEditData(val)}>
                                                                  <CreateIcon />
                                                              </IconButton>
                                                          </Tooltip>
                                                      </Grid>
                                                      <Grid item xs={2} sm={3}>
                                                          <Typography component="p" className={classes.activitySubLabel}>
                                                                  {moduleOption.find((subval) => subval.value === val.module)?.label}
                                                          </Typography>
                                                      </Grid>
                                                      <Grid item xs={2} className={`${classes.emailPushCol} ${classes.activitySubLabel}`}>
                                                          {val.green}
                                                      </Grid>
                                                      <Grid item xs={2} className={`${classes.emailPushCol} ${classes.activitySubLabel}`}>
                                                          {' '}
                                                          {val.yellow}
                                                      </Grid>
                                                      <Grid item xs={2} className={`${classes.emailPushCol} ${classes.activitySubLabel}`}>
                                                          {' '}
                                                          {val.red}
                                                      </Grid>
                                                  </>
                                              );
                                          })}
                                      </Grid>
                                  </Grid>
                              </Grid>
                          </CardContent>
                      </Card>
                  ) : (
                      <NoDataFound msg="Please add new module to display here." />
                  )}
              </CardContent>
          </Card>
      </>
  );
};

export default TrafficLight;
