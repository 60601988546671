import React from 'react';
import { InputAdornment } from '@mui/material';
import { makeStyles } from '@mui/styles';
import SearchIcon from '@mui/icons-material/Search';
import CustomInput from 'components/CustomInput/CustomInput';
import PropTypes from 'prop-types';
import { numberWithCommas } from 'utils/Utils';
import { limitWords } from 'utils/Utils';
import CustomTooltip from '../CustomTooltip';
import { SearchStyledTableContainer } from 'assets/jss/TableStyle';
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center'
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1
    },
    title: {
        flex: '1 1 70%'
    },
    searchbox: {
        margin: '10px 0 0 0'
    },
    portTotalValItem: {
        margin: 'auto'
    },
    tooltipContent: {
        '& .MuiButton-label': {
            textAlign: 'left'
        }
    },
    tooltipLabel: {
        fontSize: '1.3rem',
        top: '21px',
        position: 'absolute'
    },
    HeaderTooltipLabel: {
        fontSize: '1.3rem',
        top: '17px',
        position: 'absolute',
        marginLeft: 2
    },
    dataTooltipLabel: {
        fontSize: '1.3rem',
        position: 'absolute',
        marginLeft: 2
    },
    table: {
        '& .MuiTableRow-head': {
            backgroundColor: '#e1e1e1'
        },
        '& .MuiTableCell-head': {
            fontWeight: 600,
            textTransform: 'capitalize'
        }
    }
}));
let _timeout = 0;
const TableSearch = (props) => {
    const classes = useStyles();

    const { finalTableData, sendFilteredData, enableCellColor, budgetAllData } = props;

    /**
     * search data from all rows and columns of table
     * @param {*} event
     */
    const handleSearchOnChange = (event) => {
        const inputValue = event.target.value;
        const dataForFilter = finalTableData || [];
        let filteredData = [];
        //call search logic after typing is stopped
        if (_timeout) clearTimeout(_timeout);
        _timeout = setTimeout(() => {
            dataForFilter.forEach((value) => {
                const rowData = Object.keys(value).filter((val) =>
                    formatValue(value[val]).toString().toLowerCase().includes(inputValue.toLowerCase())
                );
                if (rowData.length) {
                    filteredData.push(value);
                }
            });
            if (inputValue.trim()===''){
              filteredData=  budgetAllData;
            }
            // finalTableData = filteredData.slice(0);
            sendFilteredData(filteredData);
        }, 1000);
    };
    // to add green or red color to text according to its value
    const colorText = (fval, original) => {
        const cellVal =
            Math.sign(original) === -1 ? (
                <span className="negativeVal">{fval}</span>
            ) : Math.sign(original) === 1 ? (
                <span className="positiveVal">{fval}</span>
            ) : (
                fval
            );
        return enableCellColor ? cellVal : fval;
    };
    /**
     * format value of column according to its type ( int, string , float)
     * @param {*} val
     * @returns
     */
    const formatValue = (val) => {
        let formattedValue =
            val !== null && val !== undefined ? (
                Math.floor(val) === val ? (
                    colorText(numberWithCommas(val), val)
                ) : !isNaN(val) && val.toString().indexOf('.') !== -1 ? (
                    colorText(val.toFixed(2), val)
                ) : limitWords(val, 5) !== val ? (
                    <CustomTooltip tooltipText={val} textTooltip={false} textLimit={5} align="left" />
                ) : (
                    val
                )
            ) : (
                '-'
            );
        return formattedValue;
    };

    return (
        <SearchStyledTableContainer>
            <CustomInput
                className={classes.margin}
                id="tableSearch"
                type="search"
                labelText=""
                placeholder="Full table search"
                onChange={(e) => handleSearchOnChange(e)}
                CustomInputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    )
                }}
            />
            {/* {portfolioTotal && (
                            <Grid item xs={12} sm={6} md={6} className={classes.portTotalValItem}>
                                {portfolioTotal}
                            </Grid>
                        )} */}
        </SearchStyledTableContainer>
    );
};
TableSearch.defaultProps = {
    enablePagination: true,
    colspan: 6,
    enableSorting: true,
    enableHeader: true,
    defaultSort: '',
    parentHeader: [],
    searchTable: false,
    enableCellColor: false,
    frozenCol: []
};

TableSearch.propTypes = {
    enablePagination: PropTypes.bool,
    enableSorting: PropTypes.bool,
    data: PropTypes.array.isRequired,
    tableColumns: PropTypes.array.isRequired
};
export default TableSearch;
