import React from 'react';
import { Card, CardContent, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { QuesAnsBox } from 'assets/jss/RetirementStyle';
import { getCookie } from 'utils/Utils';
import CalSignupBlock from 'views/RetirementCalculator/CalSignupBlock';
const InflationBondCalDesc = (props) => {
    return (
        <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12} md={(getCookie('auth_token') && 12) || 8}>
                <Card>
                    <CardContent>
                        <QuesAnsBox>
                            <Typography variant="h2" id="description" sx={{ mb: 2, display: 'flex' }}>
                                About Inflation Bond (TIPs) calculator
                            </Typography>
                            <Typography variant="body1" className="dashboard-subheading" sx={{ mb: 1.5 }}>
                                TIPS stand for Treasury Inflation Protection Securities and are issued and guaranteed by the US government.
                                They are issued with a fixed coupon or interest rate but where the principal is indexed to inflation. In
                                this calculator you can see how the returns and principal changes with inflation assumptions.
                            </Typography>
                        </QuesAnsBox>
                    </CardContent>
                </Card>
            </Grid>
            {!getCookie('auth_token') && (
                <Grid item xs={12} md={4}>
                    <CalSignupBlock />
                </Grid>
            )}
        </Grid>
    );
};

export default InflationBondCalDesc;
