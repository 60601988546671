import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import { OutputBox } from 'assets/jss/GeneralStyle';
import NoDataFound from 'components/CommonComponent/NoDataFound';
import React, { Fragment } from 'react';
import { numberWithCommas } from 'utils/Utils';

const updateDataKeys = (data) => {
    const result = data?.data ?? {};
    const updatedData = {};
    Object.keys(result).forEach((key) => {
        // Replace hyphens with spaces and capitalize first letter of each word
        const newKey = key.replace(/_/g, ' ').replace(/\b\w/g, (match) => match.toUpperCase());
        updatedData[newKey] = result[key];
    });
    return updatedData;
};

const RecurringCalcOutput = ({ data }) => {
    const result = data?.data ?? {};
    const updatedData = updateDataKeys(data);

    const getName = (key) => {
        switch (key) {
            case 'D':
                return 'daily'
            case 'W':
                return 'weekly'
            case 'M':
                return 'monthly'
            case 'A':
                return 'yearly'
        }
    }

    return (
        <>
            <Card elevation={2} sx={{ mb: 3.5 }}>
                <CardContent>
                    <Typography variant="h6" sx={{ mb: 2, display: 'flex' }} gutterBottom>
                        Summary Output
                    </Typography>
                    {Object.keys(result).length > 0 ? (
                        // <Box id="outputWrapper" className="widget-full-width">
                        //     <Grid container spacing={2}>
                        //         <Fragment key={"Future Value"}>
                        //             <Grid item xs={12} md={4}>
                        //                 <OutputBox>
                        //                     <Typography variant="body1" className="outputLabel" component="p">
                        //                         {"Future Value"}
                        //                     </Typography>
                        //                     <Typography variant="h6">${numberWithCommas(result["Future Value"])}</Typography>
                        //                 </OutputBox>
                        //             </Grid>
                        //         </Fragment>
                        //     </Grid>
                        // </Box>
                        <p style={{ fontSize: '18px' }}>Your <b>{getName(result['Payments Per Year String'])}</b> payment
                            of <b>{result['recurring_payment']}</b> will cost you{" "}
                            <b>{result['Future Value']}</b> over <b>{result['Number of Periods (Years)']}</b> years. </p>
                    ) : (
                        <NoDataFound />
                    )}
                </CardContent>
            </Card>
        </>
    );
};

export default RecurringCalcOutput;
