import * as ActionTypes from '../actions/ActionTypes';

const initialState = {
    fixedIncomeHistoricalDetail: {},
    loader: false
};

export const FixedIncomeHistoricalReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.FETCH_FIXED_INCOME_HISTORY_INIT:
            return { ...state, loader: true };
        case ActionTypes.FETCH_FIXED_INCOME_HISTORY_SUCCESS:
            return {
                ...state,
                fixedIncomeHistoricalDetail: { data: action.data }
            };
        case ActionTypes.FETCH_FIXED_INCOME_HISTORY_ERROR:
            return { ...state, loader: false };

        default:
            return state;
    }
};
