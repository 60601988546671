import { Accordion, Box, Breadcrumbs, Button, Card, Grid, IconButton, Rating, Typography } from '@mui/material';
import { styled } from '@mui/styles';
export const LoginBox = styled(Box)(({ minheight, theme }) => ({
           minHeight: minheight+'px'||'746px',
           height: '100%',
           position: 'relative'
       }));
export const LeftRightGrid = styled(Grid)(({ theme }) => {
    return {
        '& .logo-menu': {
            display: 'inline-block'
        },
        '&.form-grid': {
            backgroundColor:theme.palette.background.paper,
            boxShadow: theme.shadows[4]
        },
        '&.right-side': {
            position:'relative'
        },
        "& .login-tpe-logo":{
            width:'84px',
            height:'24px'
        }
    };});
      export const DividerWithTextBox = styled('div')(({ theme }) => ({
          width: '100%',
          ...theme.typography.body2,
          '& > :not(style) + :not(style)': {
              marginTop: theme.spacing(2)
          }
      }));
export const SSOSignGrid = styled(Grid)(({ theme }) => ({
           border: `1px solid ${theme.palette.text.dark}`,
           borderRadius: '7px',
           marginBottom:15
       }));
export const LoginCard = styled(Card)(({ theme }) => ({
           minWidth: '420px',
           width:'min-content',
           margin:'auto',
           '& .gridcontainer-SSO': {
               display: 'flex',
               justifyContent: 'space-around'
           },
           [theme.breakpoints.down('sm')]: {
               minWidth:'300px'
           }
       }));
export const SSOSignBtn = styled(IconButton)(({ theme }) => ({
           width:'100%',
           borderRadius:'unset',
       }));
export const SubboxWrapperBox = styled(Box)(({ theme }) => ({
    margin:'8rem auto',
    textAlign:'center',
        //    position: 'absolute',
        //        top: '36%',
        //        left: '50%',
        //        transform: 'translate(-50%, -50%)'
        "& .login-piggy":{
            maxWidth: "100% !important",
    height: "auto",
    display: "block",
    margin: "auto",
        }
       }));
export const BgBox = styled(Box)(({ img, theme }) => ({
           backgroundImage: (img && `url(${img})`) || `url(/img/login-bg.svg)`,
           backgroundRepeat: 'no-repeat',
           backgroundSize: 'cover',
           backgroundPosition: 'center',
           height: '100%',
           minHeight: '746px',
           width: '100%',
           position: 'relative',
           backgroundColor: theme.palette.primary.light
       }));
export const PiggyBox = styled(Box)(({ img, theme }) => ({
           backgroundImage: `url(/img/login-piggy.png)`,
           backgroundRepeat: 'no-repeat',
           backgroundSize: 'cover',
           backgroundPosition: 'center',
           height: '100%',
           minHeight: '746px',
           width: '100%',
           [theme.breakpoints.down('sm')]: {
               minHeight: '160px'
           }
       }));
