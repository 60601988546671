import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, styled, withStyles } from '@mui/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { Button, Typography } from '@mui/material';
import styles from 'assets/jss/material-kit-react/views/componentsSections/javascriptStyles.js';
import { limitWords } from 'utils/Utils';
import { TooltipWithImg } from 'assets/jss/GeneralStyle';
import HelpIcon from '@mui/icons-material/Help';
import { StyledTooltipHelpIconSpan } from 'assets/jss/GeneralStyle';

const customUseStyles = makeStyles((theme) => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9'
    },
    toolTipText: {
        '& .MuiButton-label p': {
            margin: '0',
            textOverflow: 'ellipsis',
            width: '100px',
            overflow: 'hidden',
            whiteSpace: 'nowrap'
        }
    },
    alignTooltipleftText: {
        '& .MuiButton-label': {
            textAlign: 'left'
        }
    }
}));

const HtmlTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: (props) => ({
        backgroundColor: 'white ',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: props?.customTooltipStyle?.maxWidth || 220,
        fontSize: theme.typography.pxToRem(12),
        border: `2px solid ${theme.palette.primary.main}`,
        // maxHeight: '150px',overflowY:'scroll',
        ...props?.customTooltipStyle,
        position: 'relative'
    }),
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.primary.main
    }
}));
const StyledButton = withStyles({
    root: {
        padding: 0,
        color: 'inherit',
        textTransform: 'unset',
        '&:hover': {
            backgroundColor: 'unset'
        }
    }
})(Button);
const tooltipStyle = makeStyles(styles);
const CustomTooltip = (props) => {
    const customClasses = customUseStyles();
    const tooltipClasses = tooltipStyle();
    return props.imgTooltip ? (
        <HtmlTooltip
            {...props}
            className={props.className}
            arrow
            customTooltipStyle={props.customTooltipStyle}
            enterTouchDelay={0}
            disableFocusListener
            title={
                <React.Fragment>
                    <Typography gutterBottom variant="body">
                        <b>{props.label}</b>
                    </Typography>
                    <Typography gutterBottom variant="body2">
                        {props.tooltipText}
                    </Typography>
                </React.Fragment>
            }
        >
            <TooltipWithImg>{props.img}</TooltipWithImg>
        </HtmlTooltip>
    ) : props.textTooltip ? (
        <HtmlTooltip
            {...props}
            className={props.className}
            customTooltipStyle={props.customTooltipStyle}
            arrow
            // open={true}
            enterTouchDelay={0}
            title={
                <React.Fragment>
                    <Typography gutterBottom className="tooltip-html-label">
                        <b>{props.label}</b>
                    </Typography>
                    <Typography gutterBottom variant="body2" className="tooltip-html-text">
                        {props.tooltipText}
                    </Typography>
                </React.Fragment>
            }
        >
            <StyledTooltipHelpIconSpan customcolor={props.svgColor}>
                <HelpIcon />
            </StyledTooltipHelpIconSpan>
        </HtmlTooltip>
    ) : props.customIconTooltip ? (
        <Tooltip
            id="tooltip-right"
            // open={true}
            arrow
            {...props}
            title={props.tooltipText}
            enterTouchDelay={0}
            placement="bottom"
            classes={{
                tooltip: tooltipClasses.tooltip,
                tooltipPlacementBottom: tooltipClasses.bottomTooltip
            }}
        >
            {/* <Typography gutterBottom className={`${!props.textLimit && customClasses.toolTipText} tooltip-html-label`}>
              <StyledButton
                  size="small"
                  className={`${props.align && customClasses['alignTooltip' + props.align + 'Text']} tooltip-html-button`}
              > */}
            {props.children}
            {/* </StyledButton> */}
            {/* </Typography> */}
        </Tooltip>
    ) : (
        <Tooltip
            id="tooltip-right"
            // open={true}
            arrow
            {...props}
            title={props.tooltipText}
            enterTouchDelay={0}
            placement="bottom"
            classes={{
                tooltip: tooltipClasses.tooltip,
                tooltipPlacementBottom: tooltipClasses.bottomTooltip
            }}
        >
            <Typography gutterBottom className={`${!props.textLimit && customClasses.toolTipText} tooltip-html-label`}>
                <StyledButton
                    size="small"
                    className={`${props.align && customClasses['alignTooltip' + props.align + 'Text']} tooltip-html-button`}
                >
                    <Typography variant="body2" className="tooltip-html-text">
                        {props.textLimit ? limitWords(props.tooltipText, props.textLimit) : props.mannualTrimText || props.tooltipText}
                    </Typography>
                </StyledButton>
            </Typography>
        </Tooltip>
    );
};
CustomTooltip.defaultProps = {
    textTooltip: true,
    customIconTooltip: false
};
CustomTooltip.propTypes = {
    label: PropTypes.string,
    tooltipText: PropTypes.string
};

export default CustomTooltip;
