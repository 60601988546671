import React, { useEffect, useState } from "react";
import { Box, Card, CardContent, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import NoDataFound from "components/CommonComponent/NoDataFound";
import { useSelector } from "react-redux";
import { OutputBox } from "assets/jss/GeneralStyle";
import { numberWithCommas } from "utils/Utils";
const FxCalOutput = (props) => {
    const fxcalData = useSelector((state) => state.fxcal);
  const [result, setResult] = useState({});

    /**
   * @author disha
   * @use to set the ouput of api into state
   */
  useEffect(() => {
    if (Object.keys(fxcalData.fxCalDetail).length) {
      setResult(fxcalData.fxCalDetail);
    } else {
      // to reset the output fields
      if (Object.keys(result).length > 0) setResult({});
    }
  }, [fxcalData]);

  return (
      <Card elevation={2} sx={{ mb: 3.5 }}>
          <CardContent>
              <Typography variant="h6" sx={{ mb: 2, display: 'flex' }} gutterBottom>
                  Summary Output
              </Typography>
              <Box id="outputWrapper" className="widget-full-width">
                      {Object.keys(result).length === 0 ?
                      <NoDataFound />
                      :
                        <Grid container spacing={2}>
                          {Object.keys(result).map((val, i) => {
                              const currValue = result[val] !== undefined ? result[val].toFixed(2) : 0;
                              const currKey = val;
                              const appendPrefix = currKey === 'Buy Amount' && props.currSign['currTo'] ? props.currSign['currTo'] : '';
                              return (
                                  <React.Fragment key={i + 'FxOutputForm'}>
                                      <Grid item xs={12} md={4}>
                                          <OutputBox>
                                              <Typography variant="body1" className="outputLabel" component="p">
                                                  {currKey}
                                              </Typography>
                                              <Typography variant="h6">
                                                  <span style={{marginRight:'5px'}}> {appendPrefix}</span>
                                                  {numberWithCommas(currValue)}
                                              </Typography>
                                          </OutputBox>
                                      </Grid>
                                  </React.Fragment>
                              );
                            })}
                            </Grid>}
              </Box>
          </CardContent>
      </Card>
  );
};

export default FxCalOutput;
