import { urlB64ToUint8Array } from "./Utils";
import { toast } from "react-toastify";

let isSubscribed = false;
let swRegistration = null;

const applicationServerPublicKey = process.env.REACT_APP_VAPID_PUB_KEY;
export function initServiceWorker(
  updateSubscriptionOnServer,
  isNotiEnable = null
) {
  if ("serviceWorker" in navigator && "PushManager" in window) {
    isSubscribed = isNotiEnable === null ? false : !isNotiEnable;
    navigator.serviceWorker
      .register("./sw.js")
      .then(function (swReg) {
        swRegistration = swReg;
        if (isSubscribed) {
          // TODO: Unsubscribe user
          unsubscribeUser(updateSubscriptionOnServer);
        } else {
          subscribeUser(updateSubscriptionOnServer);
        }
      })
      .catch(function (error) {
        console.error("Service Worker Error", error);
      });
  } else {
        toast.error("Push notification is not supported.");
    console.warn("Push messaging is not supported");
  }
}

const subscribeUser = (updateSubscriptionOnServer) => {
  const applicationServerKey = urlB64ToUint8Array(applicationServerPublicKey);
  swRegistration.pushManager
    .subscribe({
      userVisibleOnly: true,
      applicationServerKey: applicationServerKey,
    })
    .then(function (subscription) {
      console.log("User is subscribed.");
      console.log(
        swRegistration.pushManager,subscription,
        "expirationTime"
      );
      updateSubscriptionOnServer(subscription);
      isSubscribed = true;
    })
    .catch(function (err) {
      console.log("Failed to subscribe the user: ", err);
    });
};

export function unsubscribeUser(updateSubscriptionOnServer) {
  // console.log(subscription, "subscription");
  swRegistration.pushManager
    .getSubscription()
    .then(function (subscription) {
      if (subscription) {
        return subscription.unsubscribe();
      }
    })
    .catch(function (error) {
      console.log("Error unsubscribing", error);
    })
    .then(function () {
      updateSubscriptionOnServer(null);
      console.log("User is unsubscribed.");
      isSubscribed = false;
    });
}
