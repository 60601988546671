import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Dialog, DialogContent, Divider, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import CustomDialogTitle from 'components/DialogTitle';
import CustomInput from 'components/CustomInput/CustomInput';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import { createNewTable, fetchTableList } from 'redux/actions/ResearchTableTypes';
import { useDispatch, useSelector } from 'react-redux';
import { updateTable } from 'redux/actions/ResearchTableTypes';
import { toast } from 'react-toastify';
import { Add } from '@mui/icons-material';
// import { createNewBlog } from 'redux/actions/BlogTypes';
import { fetchBlogsList } from 'redux/actions/BlogTypes';
import { CustomButton } from 'assets/jss/ButtonStyle';
import { updateBlog } from 'redux/actions/BlogTypes';
import FileUpload from 'react-material-file-upload';
import { uploadTable } from 'redux/actions/ResearchTableTypes';

const TableDialog = ({ open, setOpen, currentTableData, setCurrentTableData }) => {
    const dispatch = useDispatch();
    const editorRef = useRef(null);
    const [title, setTitle] = useState('');
    const [value, setValue] = useState('');
    const [isTableMode, setIsTableMode] = useState(false);

    const handleClose = () => {
        setOpen(false);
        dispatch(fetchTableList());
        setTitle('');
        setValue('');
        setCurrentTableData(null);
    };

    useEffect(() => {
        if (currentTableData) {
            setTitle(currentTableData.table_name);
            // Convert array of objects to HTML table string
            const data = currentTableData.table_data;
            const tableHTML = `
                <table>
                    <thead>
                        <tr>
                            ${Object.keys(data[0])
                                .map((key) => `<th>${key}</th>`)
                                .join('')}
                        </tr>
                    </thead>
                    <tbody>
                        ${data
                            .filter((row) => !Object.values(row).includes(null)) // Filter out rows with null values
                            .map(
                                (row) => `
                            <tr>
                                ${Object.values(row)
                                    .map((value) => `<td>${value}</td>`)
                                    .join('')}
                            </tr>
                        `
                            )
                            .join('')}
                    </tbody>
                </table>
            `;
            setValue(tableHTML);
        }
    }, [currentTableData]);

    const handleEditorClick = (e) => {
        if (editorRef.current) {
            const clickedElement = e.target;

            if (clickedElement.closest('table')) {
                setIsTableMode(true);
            } else {
                setIsTableMode(false);
            }
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (editorRef.current && !editorRef.current.contains(event.target)) {
                setIsTableMode(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleEditorChange = (content) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(content, 'text/html');
        const table = doc.querySelector('table');
        if (table) {
            setValue(table.outerHTML);
        }
    };

    const handleSubmit = () => {
        if (title.trim() === '' || value.trim() === '') {
            toast.error('Please create a Table first');
            return;
        }
        let payload = {
            table_name: title,
            table_data: value
        };
        if (currentTableData) {
            dispatch(updateTable(currentTableData.id, payload, handleClose));
            toast.success('Table updated successfully.');
        } else {
            dispatch(createNewTable(payload, handleClose));
            toast.success('Table created successfully.');
        }
    };

    return (
        <Dialog
            sx={{
                '& .css-17i04tt-MuiPaper-root-MuiDialog-paper': {
                    maxWidth: '1200px !important',
                    minWidth: '1200px',
                    width: 'maxContent'
                }
            }}
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
        >
            <DialogContent>
                <div
                    sx={{
                        height: '520px'
                    }}
                >
                    <Box display="flex" flexDirection="row" gap="10px" justifyContent="space-between">
                        <Box width="-webkit-fill-available">
                            <CustomDialogTitle id="form-dialog-title" onClose={handleClose}>
                                Add New Research Table
                                <CustomInput
                                    value={title}
                                    labelText="Table Name"
                                    CustomInputProps={{ width: '100%' }}
                                    onChange={(e) => setTitle(e.target.value)}
                                    // error={titleError}
                                    // helperText={titleError ? 'Provide valid value for title' : null}
                                />
                            </CustomDialogTitle>
                        </Box>
                        <Button color="secondary" size="xs" sx={{ marginTop: '50px', height: '42px' }} onClick={handleSubmit}>
                            {currentTableData ? 'Update' : 'Save'}
                        </Button>
                    </Box>
                    <div style={{ width: '50%', margin: 'auto' }}>
                        <marquee>
                            <Typography sx={{ color: 'crimson' }}>You can directly paste your Table Below here...</Typography>
                        </marquee>
                    </div>
                    <div onClick={handleEditorClick}>
                        <SunEditor
                            ref={editorRef}
                            setOptions={{
                                minHeight: '355px',
                                buttonList: [['table']]
                            }}
                            onBlur={() => setIsTableMode(false)}
                            onChange={handleEditorChange}
                            defaultValue={value}
                        />
                        <style>
                            {`
                .sun-editor .se-line-breaker {
                    display: none !important;
                }
                .sun-editor-editable table, .sun-editor-editable table * {
                pointer-events: auto;
                }
                .sun-editor-editable p {
                    -webkit-user-modify: read-only !important;
                    pointer-events: none;
                    color: gray;
                }
               
            `}
                            {/* .sun-editor-editable {
            pointer-events: ${isTableMode ? 'auto' : 'none'};
            } */}
                        </style>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default TableDialog;

export const PublishDialog = ({ openPublish, setOpenPublish, filteredAndSortedTableData }) => {
    const dispatch = useDispatch();
    const { blogsList } = useSelector((state) => state.blogReducer);
    const [blog, setBlog] = useState('');

    useEffect(() => {
        dispatch(fetchBlogsList());
    }, []);

    const handleClose = () => {
        setOpenPublish(false);
    };

    const handleBlogChange = (event) => {
        const selectedBlog = event.target.value;
        setBlog(JSON.parse(selectedBlog));
    };

    const handleClickPublish = (id, is_published = true) => {
        let payload = {
            title: blog?.title?.trim(),
            content: blog?.content,
            is_published,
            image_url: blog?.image_url,
            research_table_id: id
        };
        if (payload.title === undefined) {
            toast.error('Please select Blog first..');
            return;
        }
        // dispatch(createNewBlog(payload));
        dispatch(updateBlog(blog.id, payload, handleClose));
    };

    return (
        <Dialog
            sx={{
                '& .css-17i04tt-MuiPaper-root-MuiDialog-paper': {
                    width: '100%',
                    minHeight: '200px'
                }
            }}
            open={openPublish}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
        >
            <CustomDialogTitle id="form-dialog-title" onClose={handleClose}>
                Publish Your Table
            </CustomDialogTitle>
            <Divider />
            <DialogContent>
                <FormControl sx={{ minWidth: '100%', maxWidth: 350 }} size="small">
                    <InputLabel id="demo-simple-select-autowidth-label">Select Blog </InputLabel>
                    <Select
                        labelId="demo-simple-select-autowidth-label"
                        id="demo-simple-select-autowidth"
                        // value={blog?.title}
                        label="Select Blog"
                        onChange={handleBlogChange}
                    >
                        <MenuItem value="" disabled>
                            <em>Select Blog..</em>
                        </MenuItem>
                        {blogsList?.map((blg, idx) => (
                            <MenuItem key={idx} value={JSON.stringify(blg)}>
                                {blg?.title}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {filteredAndSortedTableData.map((rows) => {
                    return (
                        <CustomButton
                            type="submit"
                            onClick={() => {
                                handleClickPublish(rows.id, blog.is_published === true ? true : false);
                            }}
                            // ps={'9px 30px'}
                            style={{ display: 'flex', margin: 'auto', marginTop: '25px' }}
                            variant="contained"
                        >
                            <Add />
                            PUBLISH
                        </CustomButton>
                    );
                })}
            </DialogContent>
        </Dialog>
    );
};

export const UploadDialog = ({ openUpload, setOpenUpload }) => {
    const dispatch = useDispatch();
    const [files, setFiles] = useState([]);

    const handleClose = () => {
        setOpenUpload(false);
        setFiles([]);
    };

    const handleUpload = () => {
        if (files.length > 0) {
            const file = files[0];
            const formData = new FormData();

            formData.append('table_file', file);

            dispatch(uploadTable(formData, handleClose));
        } else {
            toast.error('No file selected.');
        }
    };

    return (
        <Dialog
            sx={{
                '& .css-17i04tt-MuiPaper-root-MuiDialog-paper': {
                    width: '100%',
                    minHeight: '200px'
                }
            }}
            open={openUpload}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
        >
            <CustomDialogTitle id="form-dialog-title" onClose={handleClose}>
                Upload Your Table File
            </CustomDialogTitle>
            <Divider />
            <DialogContent>
                <FileUpload value={files} onChange={setFiles} />
                {/* <input type="file" onChange={handleFileChange} /> */}

                <Button color="secondary" size="xs" sx={{ display: 'flex', margin: 'auto', marginTop: '25px' }} onClick={handleUpload}>
                    Save
                </Button>
            </DialogContent>
        </Dialog>
    );
};
