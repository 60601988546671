import { roseColor } from "assets/jss/material-kit-react";
import { infoColor } from "assets/jss/material-kit-react";
import generalPageStyle from "./generalPage";

const homeCalPageStyle = (theme) => ({
  ...generalPageStyle(theme),
  link: {
    display: "flex",
    fontSize: "inherit",
    fontWeight: 600,
    "& .MuiBadge-root": {
      marginRight: "20px",
    },
    "& svg": {
      marginLeft: "0.5vh",
    },
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
  submitPortSelectionBtn: {
    display: "flex",
    "& .MuiButtonBase-root": {
      margin: "auto",
      bottom: "6px",
    },
  },
  addFlex: {
    display: "flex",
  },
  fileViewLabel: {
    "& .viewTitle": {
      marginTop: 13,
      marginRight: 8,
    },
  },
  homepurchAccordion: {
    display: "inherit",
  },
    customMargin: {
      marginTop:19
  },
  autoMargin: {
      margin:'auto'
    }
});

export default homeCalPageStyle;
