import React, { useEffect, useState } from 'react';
import { CardContent, Grid, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';

import RenderField from 'utils/RenderField';
import { submitPortAllocationData } from 'redux/actions/PortfolioActions';
import { CustomButton } from 'assets/jss/ButtonStyle';
import { CalCardActions } from 'assets/jss/RetirementStyle';
import { SliderParentGrid } from 'assets/jss/RetirementStyle';
import { TwoValSliderGrid } from 'assets/jss/RetirementStyle';
import { CustomDivider } from 'assets/jss/GeneralStyle';
import { AllocationCard } from 'assets/jss/CommonCalStyle';
import { AllocationLabelGrid } from 'assets/jss/CommonCalStyle';
import { getPortfolioValuationData } from 'redux/actions/PortfolioActions';
const AllocationForm = ({ classes, allocationData }) => {
    const dispatch = useDispatch();
    const portfolioData = useSelector((state) => state.portfolio);
    const setting = useSelector((state) => state.setting);

    const [assetTotal, setAssetTotal] = useState(0);
    const [assetTotalVal, setAssetTotVal] = useState({
        equities: 1000000,
        fixedIncome: 2000000,
        commodities: 500000,
        cash: 1000000
    });
    const {
        handleSubmit,
        control,
        formState: { isSubmitting },
        setValue
    } = useForm({
        mode: 'onChange',
        defaultValues: {
            ages: [55, 65],
            riskTolerance: 0,
            equities: assetTotalVal.equities,
            fixedIncome: assetTotalVal.fixedIncome,
            commodities: assetTotalVal.commodities,
            cash: assetTotalVal.cash
        }
    });
    const onSubmit = (data) => {
        const objToSend = {
            cur_age: data.ages[0],
            retire_age: data.ages[1],
            Eq_Amt: typeof data.equities === 'string' ? data.equities.replace(/,/g, '') : data.equities,
            FI_Amt: typeof data.fixedIncome === 'string' ? data.fixedIncome.replace(/,/g, '') : data.fixedIncome,
            Com_Amt: typeof data.commodities === 'string' ? data.commodities.replace(/,/g, '') : data.commodities,
            Cash_Amt: typeof data.cash === 'string' ? data.cash.replace(/,/g, '') : data.cash,
            Risk_Tol: data.riskTolerance
        };
        dispatch(submitPortAllocationData(objToSend));
    };
    // when any input field value will change it will call below method
    const handleFormInputChange = (e) => {
        if (Object.keys(allocationData).length > 0) {
            // dispatch(submitPortAllocationDataSuccess({}, false));
        }
        const name = e.target?.id;
        const value = e.target?.value;
        // to do the addition of the below field if they are changed
        const fieldsForSum = ['equities', 'fixedIncome', 'commodities', 'cash'];
        if (fieldsForSum.indexOf(name) > -1) {
            const newValues = {
                ...assetTotalVal,
                [name]: value.replace(/,/g, '') || 0
            };
            setAssetTotVal(newValues);
            calc_total(newValues);
        }
    };
    // sum of the fields and assign value to state to update in asset total field
    const calc_total = (newValues) => {
        const { commodities, cash, equities, fixedIncome } = newValues;
        const newTotal = parseInt(equities) + parseInt(fixedIncome) + parseInt(commodities) + parseInt(cash);
        setAssetTotal(newTotal);
    };
    // call the method to sum when page is loaded for first time
    useEffect(() => {
        calc_total(assetTotalVal);
    }, []);

    const { portfolioValuationData } = portfolioData;
    useEffect(() => {
        if (portfolioValuationData && Object.keys(portfolioValuationData).length > 0) {
            const { equities, fixed_income, commodities, cash } = portfolioValuationData;
            setValue('equities', equities || 0);
            setValue('fixedIncome', fixed_income || 0);
            setValue('commodities', commodities || 0);
            setValue('cash', cash || 0);
            const newValues = {
                ...assetTotalVal,
                equities: equities || 0,
                fixedIncome: fixed_income || 0,
                commodities: commodities || 0,
                cash: cash || 0
            };
            setAssetTotVal(newValues);
            calc_total(newValues);
        }
    }, [portfolioValuationData]);

    const loadPortfolioData = () => {
        dispatch(getPortfolioValuationData());
    };

    return (
        <AllocationCard elevation={2} className={`${classes?.widgetCalCard}`}>
            <CardContent>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container>
                        <Grid item xs={12}>
                            <SliderParentGrid container>
                                <Grid item xs={12} md={5} lg={5}>
                                    <Typography gutterBottom className="bold-font">
                                        Current Age
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={5} lg={5} textAlign={'end'}>
                                    <Typography gutterBottom className="bold-font">
                                        Retirement Age
                                    </Typography>
                                </Grid>
                                <TwoValSliderGrid item xs={12}>
                                    <RenderField
                                        type="slider"
                                        control={control}
                                        name="ages"
                                        id="ages"
                                        max={100}
                                        min={18}
                                        valueLabelDisplay="on"
                                        handleOnChange={handleFormInputChange}
                                    />
                                </TwoValSliderGrid>
                            </SliderParentGrid>
                        </Grid>
                        <AllocationLabelGrid item xs={12} sm={5} md={5} lg={5}>
                            <Typography id="riskToleLabel">Risk Tolerance</Typography>
                        </AllocationLabelGrid>
                        <Grid item xs={12} sm={7} md={7} lg={7}>
                            <RenderField
                                handleOnChange={handleFormInputChange}
                                name="riskTolerance"
                                control={control}
                                outputField
                                label=""
                                id="riskTolerance"
                                options={[
                                    { value: 0, label: 'Hate Risk' },
                                    { value: 1, label: 'Normal' },
                                    { value: 2, label: 'Occasionally Role the Dice' },
                                    { value: 3, label: 'Vegas Baby' }
                                ]}
                                type="select"
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={12} sm={5} md={5} lg={5}>
                                <Typography gutterBottom>Type</Typography>
                            </Grid>
                            <Grid item xs={12} sm={7} md={7} lg={7}>
                                <Typography gutterBottom>Amount</Typography>
                            </Grid>
                            <CustomDivider />
                            <AllocationLabelGrid item xs={12} sm={5} md={5} lg={5} id="equityLabel">
                                <Typography>Equities</Typography>
                            </AllocationLabelGrid>
                            <Grid item xs={12} sm={7} md={7} lg={7}>
                                <RenderField
                                    handleOnChange={handleFormInputChange}
                                    name="equities"
                                    fieldName="Equities"
                                    control={control}
                                    label=""
                                    id="equities"
                                    type="number"
                                    required
                                    prefix={'$'}
                                    thousandSeparator={true}
                                    customPatternName="decimalRate"
                                    placeholder="100,000"
                                    setValue={setValue}
                                    padTop={5}
                                />
                            </Grid>
                            <AllocationLabelGrid item xs={12} sm={5} md={5} lg={5}>
                                <Typography>Fixed Income</Typography>
                            </AllocationLabelGrid>
                            <Grid item xs={12} sm={7} md={7} lg={7}>
                                <RenderField
                                    handleOnChange={handleFormInputChange}
                                    name="fixedIncome"
                                    fieldName="Fixed Income"
                                    control={control}
                                    label=""
                                    id="fixedIncome"
                                    type="number"
                                    required
                                    prefix={'$'}
                                    thousandSeparator={true}
                                    customPatternName="decimalRate"
                                    placeholder="100,000"
                                    setValue={setValue}
                                />
                            </Grid>
                            <AllocationLabelGrid item xs={12} sm={5} md={5} lg={5}>
                                <Typography>Commodities</Typography>
                            </AllocationLabelGrid>
                            <Grid item xs={12} sm={7} md={7} lg={7}>
                                <RenderField
                                    handleOnChange={handleFormInputChange}
                                    name="commodities"
                                    fieldName="Commodities"
                                    control={control}
                                    label=""
                                    id="commodities"
                                    type="number"
                                    required
                                    prefix={'$'}
                                    thousandSeparator={true}
                                    customPatternName="decimalRate"
                                    placeholder="100,000"
                                    setValue={setValue}
                                />
                            </Grid>
                            <AllocationLabelGrid item xs={12} sm={5} md={5} lg={5}>
                                <Typography>Cash</Typography>
                            </AllocationLabelGrid>
                            <Grid item xs={12} sm={7} md={7} lg={7}>
                                <RenderField
                                    handleOnChange={handleFormInputChange}
                                    name="cash"
                                    fieldName="Cash"
                                    control={control}
                                    label=""
                                    id="cash"
                                    type="number"
                                    required
                                    prefix={'$'}
                                    thousandSeparator={true}
                                    customPatternName="decimalRate"
                                    placeholder="100,000"
                                    setValue={setValue}
                                />
                            </Grid>
                            <CustomDivider />
                            <AllocationLabelGrid item xs={12} sm={5} md={5} lg={5}>
                                <Typography>Asset Total</Typography>
                            </AllocationLabelGrid>
                            <Grid item xs={12} sm={7} md={7} lg={7}>
                                <RenderField
                                    handleOnChange={handleFormInputChange}
                                    name="assetTotal"
                                    control={control}
                                    label=""
                                    id="assetTotal"
                                    type="number"
                                    OverrideValue={assetTotal}
                                    prefix={'$'}
                                    disabled
                                    fieldName="Asset Total"
                                    thousandSeparator={true}
                                    customPatternName="decimalRate"
                                    placeholder="100,000"
                                    setValue={setValue}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <CalCardActions className="contactus-cardaction">
                        {setting.profileDetail.role === 'admin' && (
                            <CustomButton
                                ps={'9px 10px'}
                                w={'260px'}
                                variant="contained"
                                disabled={isSubmitting}
                                onClick={loadPortfolioData}
                            >
                                Use Your Portfolio Valuation Data
                            </CustomButton>
                        )}

                        <CustomButton
                            type="submit"
                            ps={'9px 30px'}
                            variant="contained"
                            // color="rose"
                            disabled={isSubmitting}
                        >
                            Calculate
                        </CustomButton>
                    </CalCardActions>
                </form>
            </CardContent>
        </AllocationCard>
    );
};

export default AllocationForm;
