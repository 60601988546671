import React from 'react';
import { Box, Typography } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import { CustomButton } from 'assets/jss/ButtonStyle';
import { NeedHelpCard } from 'assets/jss/PricingStyle';
import { useNavigate } from 'react-router-dom';

const NeedHelpBlock = () => {
    const navigate = useNavigate();
    return (
        <NeedHelpCard elevation={3}>
            <CardContent className="needhelp-card-content">
                <Box className="text-box">
                    <Typography variant="body1" color="text.orange">
                        Need help finding the right plan?
                    </Typography>
                    <Typography variant="heading">We are here to help you.</Typography>
                    <Typography variant="body1" mt={1}>
                        Can’t find which plan to choose? No worries. Click this link and let us know what you’re looking for and happy to
                        help.
                    </Typography>
                </Box>
                <Box className="get-started-box">
                    <CustomButton ps={'9px 30px'} variant="contained" onClick={() => navigate('/contact-us')}>
                        Get Started
                    </CustomButton>
                </Box>
            </CardContent>
        </NeedHelpCard>
    );
};
export default NeedHelpBlock;
