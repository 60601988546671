import React from 'react';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// @mui/material components
import MenuItem from '@mui/material/MenuItem';
import { makeStyles, withStyles } from '@mui/styles';
import styles from 'assets/jss/material-kit-react/components/customInputStyle.js';
import { infoColor } from 'assets/jss/material-kit-react.js';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';
import { Box } from '@mui/material';
const useStyles = makeStyles(styles);
const MyMenuItem = withStyles({
    root: {
        '&:hover': {
            backgroundColor: infoColor,
            color: 'white'
        }
    },
    selected: {
        '&.Mui-selected': {
            backgroundColor: infoColor,
            color: 'white',
            fontWeight: 600
        },
        '&:hover': {
            backgroundColor: `${infoColor} !important`,
            color: 'white'
        }
    }
})(MenuItem);
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        }
    }
};
export default function CustomSelect(props) {
    const classes = useStyles();
    const { formControlProps, labelText, id, inputProps, error, options, chipSelect = false, CustomInputProps, ...rest } = props;
    const handleChange = (param) => {
        if (props && props.onChange) {
            props.onChange(param);
        }
    };
    return (
        <Box
            sx={{
                width: '98%',
                '& > :not(style)': { m: 1, width: 'inherit' }
            }}
            required={props.required}
            variant="standard"
            {...formControlProps}
            disabled={props.disabled}
            style={props.style}
            error={error}
        >
            {chipSelect ? (
                <>
                    <InputLabel id="demo-multiple-chip-label">{labelText}</InputLabel>
                    <Select
                        id={id}
                        labelId="demo-multiple-chip-label"
                        name={props.name || id}
                        defaultValue={props.defaultValue}
                        className={classes.moduleSelect}
                        multiple
                        displayEmpty
                        helperText={props.helperText}
                        error={error}
                        disabled={props.disabled}
                        fullWidth
                        inputRef={rest.inputRef}
                        value={rest.value || []}
                        onChange={(e) => handleChange(e)}
                        input={<Input id="select-multiple-chip" />}
                        renderValue={(selected) => {
                            if (selected.length === 0) {
                                return <em>Select...</em>;
                            }
                            return (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                    {selected.map((value, i) => {
                                        return <Chip key={i + '_ChipSelect'} label={options[value]} className={classes.chip} />;
                                    })}
                                </Box>
                            );
                        }}
                        MenuProps={MenuProps}
                    >
                        <MyMenuItem disabled value="">
                            <em>Select...</em>
                        </MyMenuItem>
                        {Object.keys(options).map((val, selectKey) => (
                            <MyMenuItem key={`${val}_${selectKey}_chipselect`} value={val} id={val}>
                                {options[val]}
                            </MyMenuItem>
                        ))}
                    </Select>
                </>
            ) : (
                <>
                    <TextField
                        select
                        disabled={props.disabled}
                        variant="standard"
                        labelId={props.name || id}
                        id={props.name || id}
                        name={props.name || id}
                        value={props.value || []}
                        label={`${labelText && props.required ? labelText + ' *' : labelText ? labelText : ''}`}
                        onChange={handleChange}
                        InputLabelProps={props?.addNew ? (props.value || props.value === 0 ? { shrink: true } : { shrink: false }) : null}
                        error={error}
                        ref={rest.inputRef}
                        multiple={props.multiple}
                        helperText={props.helperText || ''}
                        InputProps={CustomInputProps}
                        defaultValue={props.defaultValue}
                    >
                        {!props.disableEmptyValue && (
                            <MenuItem disabled value="">
                                <em>Select...</em>
                            </MenuItem>
                        )}
                        {props.mutualFundFilter != undefined
                            ? options.map((val, selectKey) => (
                                  <MenuItem
                                      key={`${id}_${selectKey}`}
                                      id={val.value}
                                      disabled={val.disabled || false}
                                      value={val.value}
                                      onClick={() => props.selectOwnedFund(val.value)}
                                  >
                                      {val.label}
                                  </MenuItem>
                              ))
                            : options.map((val, selectKey) => (
                                  <MenuItem
                                      key={`${id}_${selectKey}`}
                                      id={val.value}
                                      disabled={val.disabled || false}
                                      value={val.value}
                                      onClick={() => props.setOption != undefined && props.setOption(val.label)}
                                  >
                                      {val.label}
                                  </MenuItem>
                              ))}
                    </TextField>
                </>
            )}
        </Box>
    );
}
CustomSelect.propTypes = {
    labelText: PropTypes.node,
    id: PropTypes.string,
    inputProps: PropTypes.object,
    formControlProps: PropTypes.object,
    inputRootCustomClasses: PropTypes.string,
    error: PropTypes.bool,
    success: PropTypes.bool,
    white: PropTypes.bool,
    inputType: PropTypes.string,
    options: PropTypes.array,
    onClick: PropTypes.func
};
