import { primaryColor, roseColor ,infoColor,successColor} from "assets/jss/material-kit-react.js";
import landingPageStyle from "assets/jss/material-kit-react/views/landingPage"
const generalPageStyle = (theme) => ({
  ...landingPageStyle(theme),
  multiFieldWrapper: {
    "& .addIcon": {
      color: infoColor,
      "&:hover": {
        backgroundColor: "rgb(0 172 193 / 13%)",
      },
    },
    "& .removeIcon": {
      color: roseColor,
      "&:hover": {
        backgroundColor: "rgb(233 30 99 / 13%)",
      },
    },
  },
  commonCalContainer: {
    display: "flex",
    "& .infoIcon .MuiSvgIcon-root": {
      fontSize: "1.8rem",
    },
  },
  portroot: {
    "& #sampleTitle.MuiTypography-gutterBottom": {
      color: primaryColor,
    },
    "& .MuiGrid-spacing-xs-8 > .MuiGrid-item": {
      padding: "32px",
    },
    "& .MuiDropzonePreviewList-image": {
      height: "auto",
      color: "rgba(0, 0, 0, 0.87)",
      width: "initial",
      maxWidth: "100%",
    },
    "& .MuiDropzonePreviewList-removeButton": {
      padding: "10px 10px 0px 1px",
      color: roseColor,
      zIndex: 2,
    },
    "& .MuiTypography-body1": {
      fontSize: "0.9rem",
      marginRight: "8px",
    },
    "& .MuiSnackbarContent-root": {
      color: "rgb(102, 60, 0)",
      backgroundColor: "rgb(255, 244, 229)",
      transition: ".5s ease",
      boxShadow:
        "0 12px 20px -10px rgb(255 244 229 / 28%), 0 4px 20px 0px rgb(0 0 0 / 12%), 0 7px 8px -5px rgb(255 152 0 / 20%)",
    },
    "& .MuiSnackbarContent-message .MuiSvgIcon-root": {
      color: "#ff9800",
    },
    "&  .MuiTabs-indicator": {
      backgroundColor: `${infoColor} !important`,
    },
    "& .MuiTab-textColorPrimary.Mui-selected": {
      color: `${infoColor} !important`,
    },
  },
  appbarTab: {
    "& .MuiTab-wrapper": {
      fontWeight: 600,
    },
  },
  pieChartWrapper: {
    position: "absolute",
    top: "60px",
    bottom: "15px",
    width: "100%",
  },
  chartDivParent: {
    position: "relative",
    top: "auto",
    right: "auto",
    width: "auto",
    height: "500px",
    zIndex: "0",
    marginTop: 15,
  },
  statusChip: {
    "& svg": {
      color: "inherit",
      width: "0.8em",
    },
    "&.success": {
      color: successColor,
      backgroundColor: "#4caf5052",
    },
    "&.fail": {
      color: roseColor,
      backgroundColor: "#e91e632e",
    },
    "&.cancelChip": {
      fontSize: "0.7rem",
    },
  },
  newTaskRibbon: {
    position: "relative",
    "& .wrap": {
      width: "100%",
      height: 188,
      position: "absolute",
      top: "-8px",
      overflow: "hidden",
      "&::before": {
        content: '""',
        position: "absolute",
        width: "40px",
        height: "8px",
        left: "93px",
        background: "#96224a",
        borderRadius: "0px 8px 0px 0px",
      },
      "&::after": {
        width: "8px",
        height: "40px",
        left: "0px",
        top: "93px",
        background: "#96224a",
        borderRadius: "8px 0px 0px 8px",
        content: '""',
        position: "absolute",
      },
      "&.addRighPosition": { right: 7 },
    },
    "& .ribbonLabel": {
      width: "200px",
      height: "40px",
      lineHeight: "40px",
      position: "absolute",
      top: "23px",
      left: "-50px",
      zIndex: "2",
      overflow: "hidden",
      WebkitTransform: "rotate(316deg)",
      transform: "rotate(316deg)",
      border: "1px dashed",
      boxShadow: "0 0 0 3px #e91e63, 0px 21px 5px -18px rgb(0 0 0 / 60%)",
      background: "#e91e63",
      textAlign: "center",
      color: "white",
      fontSize: "0.9rem",
    },
  },
  widgetCalCard: {},
  // searchBoxWrapper:{},
  widgetCalIndivGrid: {
    maxWidth: "100%",
    "& .MuiGrid-item": {
      maxWidth: "inherit",
      padding: 4,
    },
    "& $widgetCalCard": {
      margin: 0,
    },
    "& .MuiToolbar-root": { minHeight: 115 },
    "& .tablesearchbox": { top: "unset" },
  },
});
export default generalPageStyle;
