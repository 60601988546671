import React, { Fragment } from "react";
import {Box, Card, CardContent, Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import NoDataFound from "components/CommonComponent/NoDataFound";
import { OutputBox } from "assets/jss/GeneralStyle";
import { numberWithCommas } from "utils/Utils";
import CustomTooltip from "components/CommonComponent/CustomTooltip";
import * as tooltip from 'Tooltip.json';

const MortgageRefinanceCalOutput = (props) => { 
    const data = props?.mortgageRefinanceData?.mortgageRefinanceData?.data?.data?.output_box;

    const containerStyle = {
    display: "flex",
    };

    const columnStyle = {
        paddingLeft: "16px",
        flex: 1
      };

    const labelStyle = {
    paddingLeft: "6px",
    paddingTop: "6px",
    paddingRight: "6px",
    };
    

    const allowedPrefix = true; // Replace this with your actual logic to determine whether to display a prefix



  return (
    <>
    <Card elevation={2} sx={{ mb: 3.5 }}>
      <CardContent>
        <Typography variant="h6" sx={{ mb: 2, display: 'flex' }} gutterBottom>
        Refinancing Recommendation
        </Typography>
        <Box id="outputWrapper" className="widget-full-width">
          {props?.mortgageRefinanceData?.mortgageRefinanceData?.data?.data?.response?.length ? (
            <Grid container spacing={2}>
              <Typography variant="b2" sx={{ mb: 2, display: 'flex', padding: "17px" }}>{props.mortgageRefinanceData.mortgageRefinanceData.data.data.response}</Typography>
            </Grid>
          ) : (
            <NoDataFound />
          )}
        </Box>
      </CardContent>
    </Card>
  
    {/* Second Box */}
    {props?.mortgageRefinanceData?.mortgageRefinanceData?.data?.data?.response?.length ? (
  <Card elevation={2} sx={{ mb: 3.5 }}>
    <CardContent>
    <Typography variant="h6" sx={{ mb: 2, display: 'flex' }} gutterBottom>
        Summary Output
        </Typography>
        <div style={containerStyle}>
          <div style={{flex: 1}}>
            <OutputBox>
              <Typography><span style={labelStyle}>Current Monthly Mortgage</span></Typography>
              <Typography variant="body2" className="outputLabel" component="p"></Typography>
              <Typography variant="h5">
                {allowedPrefix ? "$" : ""}
                {numberWithCommas(data.current_monthly_payment)}
              </Typography>
            </OutputBox>
          </div>
          <div style={columnStyle}>
            <OutputBox>
              <Typography><span style={labelStyle}>New Monthly Mortgage</span></Typography>
              <Typography variant="body2" className="outputLabel" component="p"></Typography>
              <Typography variant="h5">
                {allowedPrefix ? "$" : ""}
                {numberWithCommas(data.refinance_monthly_payment)}
              </Typography>
            </OutputBox>
          </div>
          <div style={columnStyle}>
            <OutputBox>
              <Typography><span style={labelStyle}>Monthly Savings</span></Typography>
              <Typography variant="body2" className="outputLabel" component="p"></Typography>
              <Typography variant="h5">
                {allowedPrefix ? "$" : ""}
                {numberWithCommas(data.monthly_saving)}
              </Typography>
            </OutputBox>
          </div>
        </div>
     
    </CardContent>
  </Card>
  ) : ('')}
</>
  
  );
};

export default MortgageRefinanceCalOutput;
