const tableStylePage = theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  title: {
    flex: "1 1 70%",
  },
  searchbox: {
    margin: "10px 0 0 0",
  },
  portTotalValItem: {
    margin: "auto",paddingLeft:0
  },
  tooltipContent: {
    "& .MuiButton-label": {
      textAlign: "left"
    }
  },
  tooltipLabel: {
    fontSize: "1.3rem",
    top: "11px",
    position: "absolute",
  },
  table: {
    "& .MuiTableRow-head": {
      backgroundColor: "#e1e1e1"
    },
    "& .MuiTableCell-head": {
      fontWeight: 600,
      textTransform: "capitalize"
    }
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.common.white
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    "& .MuiFormControl-root": {
      padding: 0
    },
    "& .MuiInputBase-root": {
      marginTop: 0
    }
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: 200
    }
  },
  toolbarroot: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: theme.palette.secondary.light
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark
        },
  toolbartitle: {
    flex: "1 1 100%",
  },
  titleItem: {
    paddingTop: 6,paddingLeft:3
  },
  loadDataItem: {
    padding: 0,
    textAlign: "end",
    [theme.breakpoints.down("xs")]: {
      textAlign: "inherit"
    }
  },
  tablestickyhead: {
    position: "sticky",
    top: 0,
    zIndex: 5,
  },
  maintable: {
    maxHeight: 400,
    overflowY: "scroll",
    display: "grid",
  },
  noDataDiv: { height: 265, marginLeft: 15 },
  sortHeaderWrapper: {
    // display: 'flex',
    // alignItem: 'center',
    // justifyContent:'center'
  }, searchBoxWrapper: {
     [theme.breakpoints.up("md")]: {
      position: 'absolute',
       right:'0',top:10
    }
  }, addNewItemWrapper: {
     [theme.breakpoints.up("md")]: {
      position: 'absolute',
       left:'0',top:10
    }
  }
});

export default tableStylePage;
