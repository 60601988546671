import { Container, Grid, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import CommonCalculator from 'components/CommonComponent/CommonCalculator.js';
import Loader from 'components/CommonComponent/Loader.js';
import CompoundCalcForm from './RecurringCalcForm';
import { MainBox } from 'assets/jss/GeneralStyle';
import { CustomListItem } from 'assets/jss/HeaderStyle.jsx';
import { CustomListItemText } from 'assets/jss/HeaderStyle.jsx';
import { WidgetBox } from 'assets/jss/GeneralStyle';
import CompoundCalcOutput from './RecurringCalcOutput';
import RecurringCalDesc from './RecurringCalDesc';
import Meta from 'components/meta';

export default function RecurringExpenseCalculator(props) {
    const generalCal = useSelector((state) => state.generalCal);
    const general = useSelector((state) => state.generalCal);

    const typographyRef = useRef(null);
    useEffect(() => {
        // Set the ID after the component mounts
        if (typographyRef.current) {
            typographyRef.current.id = 'recCal';
        }
    }, []);

    const render = () => {
        return (
            <>
                {generalCal.loader ? <Loader /> : ''}
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4.5} className="widget-grid-item">
                        <CompoundCalcForm compoundCalData={generalCal.compoundCalData} />
                        {/* <CompoundCalcForm /> */}
                    </Grid>
                    <Grid item xs={12} sm={6} md={7.5} className="widget-grid-item">
                        <CompoundCalcOutput data={generalCal.compoundCalData} />
                    </Grid>
                </Grid>
            </>
        );
    };
    const breadcrumbs = [
        <CustomListItem className="cal-banner-menu" key="1" sx={{ m: 0 }} color="text.light">
            <CustomListItemText primary={'Home'} />
        </CustomListItem>,
        <CustomListItem className="cal-banner-menu" key="2" sx={{ m: 0 }} color="text.light">
            <CustomListItemText primary={'Budget'} />
        </CustomListItem>,
        <Typography ref={typographyRef} key="3" color="text.light" className="current-menu">
            Recurring Expense Calculator
        </Typography>
    ];
    return (
        (props.widgetcal && <WidgetBox>{render()}</WidgetBox>) || (
            <>
                <Meta 
                title="Recurring Expense Calculator | TPE Hub"
                description="Calculate and manage your recurring expenses with ease using our Recurring Expense Calculator. Enter your monthly expenses and get a clear picture of your ongoing financial commitments. Simplify budgeting and track your regular costs efficiently."
                page="/recurringexpensecalculator"
            />
                <CommonCalculator variantText="Recurring Expense Calculator" breadcrumbs={breadcrumbs} />
                <MainBox>
                    <Container maxWidth="lg">
                        {render()}
                        <RecurringCalDesc />{' '}
                    </Container>
                </MainBox>
            </>
        )
    );
}
