import React, { useState, useEffect } from "react";
import { makeStyles } from '@mui/styles';
import { ResponsiveBar } from "@nivo/bar";
import styles from "assets/jss/material-kit-react/views/portfolioPage";
import Globals from "Globals.js";
import { numberWithCommas, splitArrayIntoChunksOfLen } from "utils/Utils";
import { abbreviate_number } from "utils/Utils";
var dataArr = [];
const useStyles = makeStyles(styles);
function BarChart({ chartData, marginProp = null, colorProps, legendName, bottomLegendName, ...rest }) {
  let count = 20;
  const { data, min } = chartData;
  const classes = useStyles();
  const [refresh, setRefresh] = useState(false);
  const hasWindow = typeof window !== "undefined";
  const [graphColor, setGraphColor] = useState(Globals.Colors);
  const [chartSetup, setChartSetup] = useState(getWindowDimensions());
 useEffect(() => {
   rest.customColor && setGraphColor(rest.customColor);
 }, []);
  function getWindowDimensions() {
    const width = hasWindow ? window.innerWidth : null;
    var obj = {
      sliceArrCount: 3,
      legendHeight: 5,
      xAxisTickSlice: (data.length <= 4 && 0) || Math.round(data.length / 10),
      // xAxisTickSlice: (data.length <= 3 && 0) || (Math.round(data.length / 10) ===0 && data.length || Math.round(data.length / 10)),
      tickRotation: 0,
      marginBottom: 90,
    };
    if (width <= 1024 && width > 425) {
      obj = {
        ...obj,
        xAxisTickSlice: (data.length <= 3 && 0) ||( Math.round(data.length / 5) ===0 && data.length ||  Math.round(data.length / 5)),
      };
    }else if (width <= 425 && width > 375) {
      obj = {
        ...obj,
        //below less than 3 count will reflect in budget analysis bar chart
        xAxisTickSlice: (data.length <= 3 && 0) ||( Math.round(data.length / 5) ===0 && data.length ||  Math.round(data.length / 5)),
        sliceArrCount: rest.divideLegend ? 2 : 1,
        marginBottom: rest.rotateLabel ? 40 : 90,
        tickRotation: rest.rotateLabel ? 35 : 0,
      };
      if (rest.rotateLabel) count += 8;

    } else if (width <= 375 && width > 320) {
      obj = {
        ...obj,
        xAxisTickSlice: Math.round(data.length / 6),
        sliceArrCount: 2,
        legendOffset: 75,
        tickRotation: rest.rotateLabel? 50 : 90,
        marginBottom: 90,
      };
      if (rest.rotateLabel) count += 12;

    } else if (width <= 320) {
        count += 20;
      obj = {
        ...obj,
        sliceArrCount:rest.divideLegend?2: 1,
        xAxisTickSlice:( Math.round(data.length / 5) ===0 && data.length ||  Math.round(data.length / 5)),
        tickRotation: rest.rotateLabel? 60 : 90,
        marginBottom: rest.rotateLabel? 50 : 90,
      };
    }
    if (rest.halfGraph) {
      count = rest.initCount || 35;
      obj = {
        ...obj,
        xAxisTickSlice: Math.round(data.length / 12) ,
        tickRotation: rest.rotateLabel || rest.halfGraph ? 60 : 0,
        marginBottom: 95,
      };
      if (width <= 1024 && width > 425) {
        obj = {
          ...obj,
          xAxisTickSlice: 3
        };
      } else if (width <= 425 && width > 375) {
        obj = {
          ...obj,
          xAxisTickSlice: Math.round(data.length / 7),
          sliceArrCount: 0,
          tickRotation: rest.rotateLabel || rest.halfGraph ? 70 : 0,
        };
      } else if (width <= 375 && width > 320) {
        count = 65;
        obj = {
          ...obj,
          xAxisTickSlice: Math.round(data.length / 6),
        };
      } else if (width <= 320) {
       count = 50
        obj = {
          ...obj,
          sliceArrCount: 1,
          xAxisTickSlice:( Math.round(data.length / 5) ===0 && data.length ||  Math.round(data.length / 5)),
          tickRotation: rest.rotateLabel || rest.halfGraph ? 60 : 0,
          marginBottom: rest.rotateLabel || rest.halfGraph ? 120 : 90,
        };
      }
      if (rest.biaxialchart) {
        count = 20;
      }
    }
    if (rest.customCount) {
      count =rest.customCount;
    }
    return obj;
  }

  // create merged array which will include all value of x axis
  useEffect(() => {
    dataArr = [];
    dataArr = data.map(({ label }) => label);
    setRefresh(!refresh);
  }, [data]);

  useEffect(() => {
    if (hasWindow) {
      function handleResize() {
        setChartSetup(getWindowDimensions());
      }
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }
  }, [hasWindow]);

  //remove dublicate value from array
  let mergedDataset = [...new Set(dataArr)];
  /**
   * to display tooltip on hover of the
   * @param {*} param
   * @returns
   */
  const CustomTooltip = (data) => {
    if (data) {
      let value = data.value;
      if (!isNaN(data.value) && data.value.toString().indexOf(".") !== -1) {
      value=data.value.toFixed(2)
    }
      const currValue = numberWithCommas(value);
      return (
        <div className={classes.customBarChartTooltip}>
          <div className={classes.barChartInnerParent}>
            <span
              className={classes.colorBox}
              style={{ background: data.color }}></span>
            <span>
              <strong>{data.id}</strong>
            </span>
          </div>
          <p className={classes.tooltipLabel}>
            {data.indexValue} : <strong>{currValue}</strong>
          </p>
        </div>
      );
    }
    return null;
  };

  const legendProps = {
    anchor: "bottom",
    direction: "row",
    itemWidth: rest.divideLegend ?50:120,
    translateX: 0,
    translateY: 73,
    itemHeight: chartSetup.legendHeight,
    itemsSpacing: 0,
    itemOpacity: 0.8,
    symbolShape: "square",
  };
  const chartKey = data.map(({ label, ...rest }) => Object.keys(rest))[0];
  return (
    <ResponsiveBar
      data={data}
      indexBy='label'
      keys={chartKey}
      margin={marginProp || { top: 20, right: 20, bottom: chartSetup.marginBottom, left: 30 }}
      padding={0.3}
      groupMode={rest.groupMode}
      minValue={min < 0 ? parseInt(min / 10, 10) * 10 - 10 : "auto"}
      valueFormat={{ format: " >-.2f", enabled: true }}
      valueScale={{ type: "linear" }}
      indexScale={{ type: "band", round: true }}
      // valueFormat={{ format: "", enabled: false }}
      colors={colorProps || Globals.Colors}
      borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
      axisTop={null}
      // axisRight={null}
      tooltip={(val) => CustomTooltip(val)}
      axisBottom={{
        format: (values) => {
          const formattedVal = values.toString().replaceAll(/_/g, ' ');
          // to skip/hide value to overcome overlap issue on xaxis
          if (chartSetup.xAxisTickSlice > 0) {
            if (mergedDataset.indexOf(values) % chartSetup.xAxisTickSlice === 0) {
              return rest.formatXAxis ? numberWithCommas(values.toFixed()) : formattedVal;
            } else return "";
          } else return formattedVal;
        },
        tickSize: 5,
        tickPadding: 5,
        legend: bottomLegendName,
        tickRotation: chartSetup.tickRotation,
        legendPosition: "middle",
        legendOffset: 32,
      }}
      axisLeft={{
        format: (tick) =>rest.formatYAxis? numberWithCommas(abbreviate_number(tick)) :tick,
        tickSize: 5,
        legend: legendName || "",
        tickPadding: 5,
        tickRotation: 0,
        legendPosition: "middle",
        legendOffset: -40,
      }}
      enableLabel={false}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
      // legends={rest.enableLegend?splitArrayIntoChunksOfLen(
      //   data.map(({ label, ...rest }) => Object.keys(rest))[0] || [],
      //   chartSetup.sliceArrCount
      // ).map((fillData, i) => {
      //   count += 20;
      //   return {
      //     ...legendProps,
      //     symbolSize: 10,
      //     justify: false,
      //     itemsSpacing: 0,
      //     dataFrom: "keys",
      //     translateY: count,
      //     effects: [
      //       {
      //         on: "hover",
      //         style: {
      //           itemTextColor: "#000",
      //           itemBackground: "rgba(0, 0, 0, .03)",
      //           itemOpacity: 1,
      //         },
      //       },
      //     ],
      //     data: fillData.map((cur, index) => {
      //       return {
      //         id: cur,
      //         label: cur,
      //         color: splitArrayIntoChunksOfLen(
      //           colorProps || Globals.Colors,
      //           chartSetup.sliceArrCount
      //         )[i]?.[index],
      //       };
      //     }),
      //   };
      // }):[]}
      legends={[
        {
          ...legendProps,
          data: chartKey.map((key, i) => ({
            id: key,
            label: key,
            color: Globals.Colors[i] 
          }))
        }
      ]}
    />
  );
}
BarChart.defaultProps = {
  minVal: 0,
  legendName: "",
  colorProps: null,
  bottomLegendName: "",
  enableLegend: true,
  groupMode: "grouped",
  formatYAxis:true
};
export default React.memo(BarChart);
