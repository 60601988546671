import React from 'react';
import { Card, CardContent, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { QuesAnsBox } from 'assets/jss/RetirementStyle';
import { getCookie } from 'utils/Utils';
import CalSignupBlock from 'views/RetirementCalculator/CalSignupBlock';
const CollegeCalDesc = () => {
    return (
        <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12} md={(getCookie('auth_token') && 12) || 8}>
                <Card>
                    <CardContent>
                        <QuesAnsBox>
                            <Typography variant="h2" id="description" sx={{ mb: 2, display: 'flex' }}>
                                About College Score Calculator
                            </Typography>
                            <Typography variant="body1" className="dashboard-subheading" sx={{ mb: 1.5 }}>
                                This calculator provides a search function that returns basic financial information about a college you may be considering. The information provided includes 1) graduation rate, 2) median earnings, and 3) average annual cost. This tool also allows you to compare various schools.
                                We source the data directly from a US government repository. Below we provide a description of the outputs.
                                In future versions of TPE we will integrate this data into our student loan calculator.
                            </Typography>
                            <Typography variant="subheading3" className="dashboard-subheading" sx={{ mb: 2 }}>
                                <b>Average Annual Cost  </b>
                            </Typography>
                            <Typography variant="body1" className="dashboard-subheading" sx={{ mb: 1.5 }}>
                                This is the average annual cost to attend net of typical grants and scholarships. The annual cost for public schools uses in state tuition.
                            </Typography>
                            <Typography variant="subheading3" className="dashboard-subheading" sx={{ mb: 2 }}>
                                <b>Graduation Rate</b>
                            </Typography>
                            <Typography variant="body1" className="dashboard-subheading" sx={{ mb: 1.5 }}>
                                The graduation rate is the share of students who graduated within 8 years of entering this school for the first time. Generally, a higher graduation rate is better, but some schools may have a lower graduation rate owing to a high transfer rate. Therefore, a low graduation rate doesn’t means students are dropping out but rather could mean students are transferring to other schools.
                            </Typography>
                            <Typography variant="subheading3" className="dashboard-subheading" sx={{ mb: 2 }}>
                                <b>Median Earnings </b>
                            </Typography>
                            <Typography variant="body1" className="dashboard-subheading" sx={{ mb: 1.5 }}>
                                The median annual earnings of individuals that received federal student aid and began college at this institution 10 years ago, regardless of their completion status.
                            </Typography>
                        </QuesAnsBox>
                    </CardContent>
                </Card>
            </Grid>
            {!getCookie('auth_token') && (
                <Grid item xs={12} md={4}>
                    <CalSignupBlock />
                </Grid>
            )}
        </Grid>
    );
};

export default CollegeCalDesc;
