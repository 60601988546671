import { Container, Grid, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import CommonCalculator from 'components/CommonComponent/CommonCalculator.js';
import Loader from 'components/CommonComponent/Loader.js';
import InflationBondForm from './InflationBondForm';
import InflationBondOutput from './InflationBondOutput';
import { MainBox } from 'assets/jss/GeneralStyle';
import InflationBondCalDesc from './InflationBondCalDesc';
import { CustomListItem } from 'assets/jss/HeaderStyle.jsx';
import { CustomListItemText } from 'assets/jss/HeaderStyle.jsx';
import { WidgetBox } from 'assets/jss/GeneralStyle';
import Meta from 'components/meta';
export default function StudentLoanIndex(props) {
    const generalCal = useSelector((state) => state.generalCal);
    const general = useSelector((state) => state.generalCal);

    const typographyRef = useRef(null);
    useEffect(() => {
        // Set the ID after the component mounts
        if (typographyRef.current) {
            typographyRef.current.id = 'bondCal';
        }
    }, []);

    const render = () => {
        return (
            <>
                {generalCal.loader ? <Loader /> : ''}
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4.5} className="widget-grid-item">
                        <InflationBondForm
                            inflationCalData={generalCal.inflationCalData}
                            defaultoverrideData={general.defaultoverrideData}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={7.5} className="widget-grid-item">
                        <InflationBondOutput data={generalCal.inflationCalData} />
                    </Grid>
                </Grid>
            </>
        );
    };
    const breadcrumbs = [
        <CustomListItem className="cal-banner-menu" key="1" sx={{ m: 0 }} color="text.light">
            <CustomListItemText primary={'Home'} />
        </CustomListItem>,
        <CustomListItem className="cal-banner-menu" key="2" sx={{ m: 0 }} color="text.light">
            <CustomListItemText primary={'Investment'} />
        </CustomListItem>,
        <Typography ref={typographyRef} key="3" color="text.light" className="current-menu">
            Inflation Bond Calculator
        </Typography>
    ];
    return (
        (props.widgetcal && <WidgetBox>{render()}</WidgetBox>) || (
            <>
                <Meta
                    title="Inflation Bond Calculator | TPE Hub"
                    description="Use our Inflation Bond Calculator to estimate the returns on your inflation-linked bonds. Easily input your bond details and get accurate projections to make informed investment decisions."
                    page="/inflation-bond-calculator"
                />
                <CommonCalculator variantText="Inflation Bond Calculator" breadcrumbs={breadcrumbs} />
                <MainBox>
                    <Container maxWidth="lg">
                        {render()}
                        <InflationBondCalDesc />{' '}
                    </Container>
                </MainBox>
            </>
        )
    );
}
