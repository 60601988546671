import { Card, CardContent, Grid } from '@mui/material';
import { CustomButton } from 'assets/jss/ButtonStyle';
import { CalCardActions } from 'assets/jss/RetirementStyle';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import {
    fetchPeertoPeerCollData,
    submitPeertoPeerCalData,
    submitPeertoPeerCalSuccess,
    submitPeertoPeerData
} from 'redux/actions/PeertoPeerCalAction';
import RenderField from 'utils/RenderField';

const fieldsDetail = [
    {
        name: 'borrower_or_lender',
        label: 'Peer Type',
        type: 'select',
        option: [
            { value: 'borrower', label: 'Borrower' },
            { value: 'lender', label: 'Lender' }
        ],
        helperText: 'Are you the borrower or the lender?'
    },
    {
        name: 'borrower_annual_income',
        label: 'Borrower Annual Income',
        type: 'number',
        commaSep: true,
        custompattern: 'decimalRate',
        placeholder: '100,000'
    },
    { name: 'loan_amount', label: 'Loan Amount', type: 'number', commaSep: true, custompattern: 'decimalRate', placeholder: '100,000' },
    {
        name: 'payback_type',
        label: 'Payback Type',
        type: 'select',
        option: [
            { value: 'io', label: 'Princial and Interest' },
            { value: 'am', label: 'Interest Only' }
        ]
    },
    { name: 'interest_rate', label: 'Interest Rate', type: 'number', commaSep: true, custompattern: 'decimalRate', placeholder: '100,000' },
    { name: 'maturity_years', label: 'Maturity (Years)', type: 'number', maxLength: 2, commaSep: true, custompattern: 'non0Num' },
    {
        name: 'repayment_fq',
        label: 'Repayment Frequency',
        type: 'select',
        option: [
            { value: 'monthly', label: 'Monthly' },
            { value: 'quarterly', label: 'Quarterly' },
            { value: 'annually', label: 'Annually' }
        ]
    }
];
const PeerToPeerForm = ({ classes, peertopeer, ...rest }) => {
    const dispatch = useDispatch();
    const {
        handleSubmit,
        control,
        formState: { isDirty, isSubmitting },
        setValue
    } = useForm({
        mode: 'onChange',
        defaultValues: {
            borrower_annual_income: 100000,
            borrower_or_lender: 'borrower',
            loan_amount: 10000,
            interest_rate: 5,
            maturity_years: 10,
            payback_type: 'am',
            repayment_fq: 'monthly'
        }
    });

    //to submit the form data
    const onSubmit = (data) => {
        let type = 'post';
        let objToSend = {
            borrower_or_lender: data.borrower_or_lender,
            borrower_annual_income: data.borrower_annual_incomeUnFormattedValue || data.borrower_annual_income,
            loan_amount: data.loan_amountUnFormattedValue || data.loan_amount,
            payback_type: data.payback_type,
            interest_rate: data.interest_rateUnFormattedValue || data.interest_rate,
            maturity_years: data.maturity_years,
            repayment_fq: data.repayment_fq
        };
        if (data.sendterm) {
            type = 'put';
            objToSend = { ...objToSend, id: data.id, term_sent_from: data.term_sent_from };
            setValue('sendterm', false);
            rest.resetEnableSendBtn(false);
            dispatch(submitPeertoPeerData(objToSend, type));
        } else {
            dispatch(submitPeertoPeerCalData(objToSend, type));
        }
    };
    // when any input field value will change it will call below method
    const handleFormInputChange = (e) => {
        if (isDirty && Object.keys(peertopeer.indepcalData).length > 0) {
            // dispatch(submitPeertoPeerCalSuccess({}));
        }
    };
    useEffect(() => {
        if (Object.keys(peertopeer.peertopeerDetails).length > 0) {
            if (typeof peertopeer.peertopeerDetails.self !== 'undefined') {
                const val = peertopeer.peertopeerDetails?.self?.loan_term && peertopeer.peertopeerDetails?.self.loan_term.id;
                const uid = peertopeer.peertopeerDetails?.self?.uid;
                setValue('id', val);
                setValue('term_sent_from', uid);
            }
        }
    }, [peertopeer.peertopeerDetails]);
    useEffect(() => {
        dispatch(fetchPeertoPeerCollData());
        // reset output when route will change
        return () => {
            dispatch(submitPeertoPeerCalSuccess({}));
        };
    }, []);
    useEffect(() => {
        if (Object.keys(rest.triggerLoad).length > 0) {
            const val = rest.triggerLoad;
            const from = rest.triggerLoad.uid;
            setValue('term_sent_from', from);
            // to load saved data in form
            for (const key in val.loan_term) {
                if (Object.hasOwnProperty.call(val.loan_term, key)) {
                    let element = val.loan_term[key];
                    setValue(key, element);
                }
            }
        }
    }, [rest.triggerLoad]);
    return (
        <Card className={`${classes.widgetCalCard}`} elevation={2}>
            <CardContent>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container>
                        {fieldsDetail.map((val, i) => {
                            return (
                                <Grid item xs={12} sm={6} key={i + 'lifeInsForm'}>
                                    <RenderField
                                        handleOnChange={handleFormInputChange}
                                        name={val.name}
                                        control={control}
                                        label={val.label}
                                        id={val.name}
                                        type={val.type}
                                        options={val.option}
                                        required={val.required || true}
                                        thousandSeparator={val.commaSep}
                                        customPatternName={val.custompattern}
                                        placeholder={val.placeholder}
                                        setValue={setValue}
                                        {...val}
                                    />
                                </Grid>
                            );
                        })}
                    </Grid>
                    <CalCardActions className="contactus-cardaction">
                        <Grid container sx={{ textAlign: 'center' }}>
                            <Grid item xs={12} md={6}>
                                <CustomButton type="submit" ps={'9px 30px'} variant="contained" disabled={isSubmitting}>
                                    Calculate
                                </CustomButton>
                            </Grid>
                            {(peertopeer.peertopeerDetails?.self?.status === 'accepted' &&
                                !peertopeer.peertopeerDetails?.self?.loan_term.loan_amount) ||
                            rest.enableSendBtn ? (
                                <Grid item xs={12} md={6}>
                                    <CustomButton
                                        type="submit"
                                        style={{ width: 'inherit' }}
                                        ps={'9px 30px'}
                                        onClick={() => setValue('sendterm', true)}
                                        variant="outlined"
                                        disabled={isSubmitting}
                                    >
                                        Send Term
                                    </CustomButton>
                                </Grid>
                            ) : (
                                ''
                            )}
                        </Grid>
                    </CalCardActions>
                </form>
            </CardContent>
        </Card>
    );
};

export default PeerToPeerForm;
