import React from 'react';
import { Card, CardContent, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { QuesAnsBox } from 'assets/jss/RetirementStyle';
import { getCookie } from 'utils/Utils';
import CalSignupBlock from 'views/RetirementCalculator/CalSignupBlock';
const HomePurchaseDesc = () => {
    return (
        <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12} md={(getCookie('auth_token') && 12) || 8}>
                <Card>
                    <CardContent>
                        <QuesAnsBox>
                            <Typography variant="h2" id="description" sx={{ mb: 2, display: 'flex' }}>
                                About home purchase tool
                            </Typography>
                            <Typography variant="body1" className="dashboard-subheading" sx={{ mb: 1.5 }}>
                                Get rid of that spreadsheet!
                            </Typography>
                            <Typography variant="body1" className="dashboard-subheading" sx={{ mb: 1.5 }}>
                                A home purchase is one of the most important decisions you will make in your life. Our tool uses a
                                calculator based on the excel spreadsheet the founder created for his own home purchases in London and NYC.
                                This tool allows you to readily upload data you may have put in a spreadsheet, edit and compare homes on a
                                qualitative and quantitative basis.
                            </Typography>
                            <Typography variant="body1" className="dashboard-subheading" sx={{ mb: 1.5 }}>
                                Our home purchase tool automatically compares your home on several measures including buyer rating, price
                                per square foot and debt ratio. Our debt ratio is automatically calculated using current mortgage rates and
                                the income you input into our calculator (or if you included income in your settings we will automatically
                                grab it). Further (in a feature coming soon), our tool uses our home valuation calculator to adjust prior
                                sales to current values based on standard home price indices. This will allow you to compare aged sales as
                                if they were recent sales
                            </Typography>
                            <Typography variant="body1" className="dashboard-subheading" sx={{ mb: 1.5 }}>
                                One of the most important aspects of buying a house is ensuring your house is fairly priced. The best way to
                                do that is to compare it to other homes. Usually, buyers use other listings to compare. However, in a
                                difficult market, similar listings may not reflect the value transactions are occurring. Further, the
                                listings may be too few to be reliable. Using actual sales (updated to current market valuations) may add
                                important data points to ensure your price is fair.
                            </Typography>
                            <Typography variant="body1" className="dashboard-subheading" sx={{ mb: 1.5 }}>
                                Recent sales are generally limited in number and may have occurred months or years ago. Our tool will all
                                you to enter the sale date and price and we will produce a current value for the home based on price changes
                                since the sale date. While this method isn’t perfect it is better than using a old price or ignoring older
                                sales altogether from the comps.
                            </Typography>
                            <Typography variant="body1" className="dashboard-subheading" sx={{ mb: 1.5 }}>
                                Our columns include viewing status, sale date (for prior sales) and a number of data items it would be
                                useful to keep track of.
                            </Typography>
                            <Typography variant="body1" className="dashboard-subheading" sx={{ mb: 1.5 }}>
                                Further our spreadsheet like format allows you to readily filter and sort your information.
                            </Typography>
                        </QuesAnsBox>
                    </CardContent>
                </Card>
            </Grid>
            {!getCookie('auth_token') && (
                <Grid item xs={12} md={4}>
                    <CalSignupBlock />
                </Grid>
            )}
        </Grid>
    );
};

export default HomePurchaseDesc;
