import React from "react";
import { makeStyles } from '@mui/styles';
import Paper from "@mui/material/Paper";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

export default function CustomPaper(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Paper {...props} />
    </div>
  );
}
