import * as ActionTypes from "./ActionTypes";
import { apiCall, handleError } from "utils/Utils";
import Globals from "Globals.js";
import { toast } from "react-toastify";
import { category, todos, periodicallyGraph, lifeGraph } from "Todos.json"

//handle success of api
export const fetchTodosSuccess = val => {
  return {
    type: ActionTypes.FETCH_TODOS_SUCCESS,
    data: val
  };
};
// handle api error
export const fetchTodosError = () => {
  return {
    type: ActionTypes.FETCH_TODOS_ERROR
  };
};
//fetch list of todos
export const fetchTodos = (innerCall = null, sendGif = null) => async dispatch => {
  try {
    dispatch({
      type: ActionTypes.FETCH_TODOS_INIT,
    });
    const apiResponse = await apiCall(
      `${Globals.API_ROOT_URL}financetodo`,
      "get"
    );
    if (apiResponse !== undefined && apiResponse.status === 200) {
      dispatch(fetchTodosSuccess(apiResponse.data.data));
      innerCall === 'update' && dispatch(updateTodoSuccess());
      innerCall === "submit" && dispatch(submitTodoSuccess());
      sendGif && dispatch(triggerTodoGif(true));
    } else {
      if (apiResponse.status === 401) {
        dispatch(fetchTodosSuccess(todos));
      }
      else {
        handleError(apiResponse, dispatch);
        dispatch(fetchTodosError());
      }
    }
  } catch (error) {
    toast.error(Globals.errorMsg);
    dispatch(fetchTodosError());
    throw error;
  }
};


//handle success of api
export const fetchTodoCategorySuccess = val => {
  return {
    type: ActionTypes.FETCH_TODO_CATEGORY_SUCCESS,
    data: val
  };
};
// handle api error
export const fetchTodoCategoryError = () => {
  return {
    type: ActionTypes.FETCH_TODO_CATEGORY_ERROR
  };
};
export const fetchTodoCategory = () => async dispatch => {
  try {
    dispatch({
      type: ActionTypes.FETCH_TODO_CATEGORY_INIT,
    });
    const apiResponse = await apiCall(
      `${Globals.API_ROOT_URL}financetodo/category`,
      "get"
    );
    if (apiResponse !== undefined && apiResponse.status === 200) {
      dispatch(fetchTodoCategorySuccess(apiResponse.data.data));
    } else {
      if (apiResponse.status === 401) {
        dispatch(fetchTodoCategorySuccess(category));
      }
      else {
        handleError(apiResponse, dispatch);
        dispatch(fetchTodoCategoryError());
      }
    }
  } catch (error) {
    toast.error(Globals.errorMsg);
    dispatch(fetchTodoCategoryError());
    throw error;
  }
};


// handle api error
export const updateTodoError = () => {
  return {
    type: ActionTypes.UPDATE_TODO_ERROR
  };
};
export const triggerTodoGif = (flag) => {
  return {
    type: ActionTypes.TRIGGER_TODO_GIF,
    data: flag
  };
};
export const updateTodo = (data, editTodoData = null) => async dispatch => {
  let sendGif = false;
  // set state to true to display gif
  // if todo progress is changed to 100 and prevent displyaing gif if todo progress is not changed in edit mode
  if (editTodoData) {
    if (editTodoData.progress !== 100 && data.progress === 100) { sendGif = true; }
  } else {
    if (data.progress === 100) {
      sendGif = true;
    }
  }
  try {
    dispatch({
      type: ActionTypes.UPDATE_TODO_INIT,
    });
    const apiResponse = await apiCall(
      `${Globals.API_ROOT_URL}financetodo`,
      "put", data
    );
    if (apiResponse !== undefined && apiResponse.status === 200) {
      toast.success(apiResponse.data.data.message);
      dispatch(fetchTodos("update", sendGif));
      dispatch(fetchTodoCategory());
      dispatch(fetchTodoLifeGraph());
      dispatch(fetchTodoPeriodicallyGraph('month'));
    } else {
      handleError(apiResponse, dispatch);
      dispatch(updateTodoError());
    }
  } catch (error) {
    toast.error(Globals.errorMsg);
    dispatch(updateTodoError());
    throw error;
  }
};

//handle success of api
export const updateTodoSuccess = val => {
  return {
    type: ActionTypes.UPDATE_TODO_SUCCESS
  };
};

//handle success of api
export const submitTodoSuccess = () => {
  return {
    type: ActionTypes.SUBMIT_TODO_SUCCESS
  };
};
// handle api error
export const submitTodoError = () => {
  return {
    type: ActionTypes.SUBMIT_TODO_ERROR
  };
};
export const submitTodo = data => async dispatch => {
  try {
    dispatch({
      type: ActionTypes.SUBMIT_TODO_INIT,
    });
    const apiResponse = await apiCall(
      `${Globals.API_ROOT_URL}financetodo`,
      "post", data
    );
    if (apiResponse !== undefined && apiResponse.status === 200) {
      toast.success(apiResponse.data.data.message);
      let sendGif = false;
      if (data.progress === 100) {
        sendGif = true;
      }
      dispatch(fetchTodos("submit", sendGif));
      dispatch(fetchTodoCategory());
      dispatch(fetchTodoLifeGraph());
      dispatch(fetchTodoPeriodicallyGraph());
    } else {
      handleError(apiResponse, dispatch);
      dispatch(submitTodoError());
    }
  } catch (error) {
    toast.error(Globals.errorMsg);
    dispatch(submitTodoError());
    throw error;
  }
};

//handle success of api
export const deleteTodoSuccess = () => {
  return {
    type: ActionTypes.DELETE_TODO_SUCCESS
  };
};
// handle api error
export const deleteTodoError = () => {
  return {
    type: ActionTypes.DELETE_TODO_ERROR
  };
};
export const deleteTodo = data => async dispatch => {
  try {
    dispatch({
      type: ActionTypes.DELETE_TODO_INIT,
    });
    const apiResponse = await apiCall(
      `${Globals.API_ROOT_URL}financetodo`,
      "delete", data
    );
    if (apiResponse !== undefined && apiResponse.status === 200) {
      toast.success(apiResponse.data.data.message);
      dispatch(deleteTodoSuccess());
      dispatch(fetchTodos());
      dispatch(fetchTodoLifeGraph());
      dispatch(fetchTodoPeriodicallyGraph());
    } else {
      handleError(apiResponse, dispatch);
      dispatch(deleteTodoError());
    }
  } catch (error) {
    toast.error(Globals.errorMsg);
    dispatch(deleteTodoError());
    throw error;
  }
};



//handle success of api
export const searchTodoSuccess = val => {
  return {
    type: ActionTypes.SEARCH_TODO_SUCCESS,
    data: val
  };
};
// handle api error
export const searchTodoError = () => {
  return {
    type: ActionTypes.SEARCH_TODO_ERROR
  };
};
export const searchTodo = data => async dispatch => {
  try {
    dispatch({
      type: ActionTypes.SEARCH_TODO_INIT,
    });
    const apiResponse = await apiCall(
      `${Globals.API_ROOT_URL}financetodo/search`,
      "post",
      data
    );
    if (apiResponse !== undefined && apiResponse.status === 200) {
      dispatch(searchTodoSuccess(apiResponse.data.data));
    } else {
      handleError(apiResponse, dispatch);
      dispatch(searchTodoError());
    }
  } catch (error) {
    toast.error(Globals.errorMsg);
    dispatch(searchTodoError());
    throw error;
  }
};


//handle success of api
export const todoLifeGraphSuccess = val => {
  return {
    type: ActionTypes.TODO_LIFE_GRAPH_SUCCESS,
    data: val
  };
};
// handle api error
export const todoLifeGraphError = () => {
  return {
    type: ActionTypes.TODO_LIFE_GRAPH_ERROR
  };
};
export const fetchTodoLifeGraph = () => async dispatch => {
  try {
    dispatch({
      type: ActionTypes.TODO_LIFE_GRAPH_INIT,
    });
    const apiResponse = await apiCall(
      `${Globals.API_ROOT_URL}financetodo/statusgraph`,
      "get"
    );
    if (apiResponse !== undefined && apiResponse.status === 200) {
      dispatch(
        todoLifeGraphSuccess(apiResponse.data.data))
    } else {
      if (apiResponse.status === 401) {
        dispatch(todoLifeGraphSuccess(lifeGraph))
      }
      else {
        handleError(apiResponse, dispatch);
        dispatch(todoLifeGraphError());
      }
    }
  } catch (error) {
    toast.error(Globals.errorMsg);
    dispatch(todoLifeGraphError());
    throw error;
  }
};

//handle success of api
export const fetchTodoPeriodicallyGraphSuccess = val => {
  return {
    type: ActionTypes.TODO_PERIODICALLY_GRAPH_SUCCESS,
    data: val
  };
};
// handle api error
export const fetchTodoPeriodicallyGraphError = () => {
  return {
    type: ActionTypes.TODO_PERIODICALLY_GRAPH_ERROR
  };
};

export const fetchTodoPeriodicallyGraph = (time = 'month') => async dispatch => {
  try {
    dispatch({
      type: ActionTypes.TODO_PERIODICALLY_GRAPH_INIT,
    });
    const apiResponse = await apiCall(
      `${Globals.API_ROOT_URL}financetodo/countgraph/${time}`,
      "get"
    );
    if (apiResponse !== undefined && apiResponse.status === 200) {
      dispatch(
        fetchTodoPeriodicallyGraphSuccess(apiResponse.data.data))

    } else {
      if (apiResponse.status === 401) {
        dispatch(fetchTodoPeriodicallyGraphSuccess(periodicallyGraph))
      }
      else {
        handleError(apiResponse, dispatch);
        dispatch(fetchTodoPeriodicallyGraphError());
      }
    }
  } catch (error) {
    toast.error(Globals.errorMsg);
    dispatch(fetchTodoPeriodicallyGraphError());
    throw error;
  }
};
