import React, { useEffect, useRef, useState } from 'react';
import { Divider, Grid, Paper, Switch, Typography } from '@mui/material';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import { withStyles } from '@mui/styles';
import GetAppIcon from '@mui/icons-material/GetApp';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import SendIcon from '@mui/icons-material/Send';
import SubjectIcon from '@mui/icons-material/Subject';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import { infoColor } from 'assets/jss/material-kit-react';
import NoDataFound from 'components/CommonComponent/NoDataFound';
import Button from 'components/CustomButtons/Button';
import { jsPDF } from 'jspdf';
import moment from 'moment';
import { toast } from 'react-toastify';
import { sendChatBotMessage } from 'redux/actions/ChatBotActions';
import { splitArrayIntoChunksOfLen } from 'utils/Utils';
import ChatBubble from './ChatBubble';
import parse from 'html-react-parser';
import { CustomButton } from 'assets/jss/ButtonStyle';
import { TypeBox } from 'assets/jss/ChatBotStyle';
import { ChatboxForm } from 'assets/jss/ChatBotStyle';
import { ChatbotFooterGrid } from 'assets/jss/ChatBotStyle';
import { ChatbotFooterBox } from 'assets/jss/ChatBotStyle';
import CustomTooltip from 'components/CommonComponent/CustomTooltip';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { getCookie } from 'utils/Utils';
import MemoryIcon from '@mui/icons-material/Memory';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import DialogContentText from '@mui/material/DialogContentText';
import { Link } from 'react-router-dom';
import { handleSignInPermissionDialog } from 'redux/actions/GeneralCalActions';
import CustomDialogTitle from 'components/DialogTitle';
import { StyledPermissionDialog } from 'assets/jss/GeneralStyle';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { useDispatch, useSelector } from 'react-redux';
import { getBudgetFiles } from 'redux/actions/BudgetActions';
import { fetchPortfolioList } from 'redux/actions/PortfolioActions';
import { sendChatBotAnalysisMessage } from 'redux/actions/BudgetAnalysisBotActions';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import PsychologyIcon from '@mui/icons-material/Psychology';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { styled } from '@mui/system';

const defaultMsg = "Hi There! My name is Maya and welcome to the People's Economist.  How can I help you?";
const ChatLogLoader = withStyles({
    colorPrimary: {
        backgroundColor: '#9cd0d6'
    },
    barColorPrimary: {
        backgroundColor: infoColor
    }
})(LinearProgress);
const ChatBoxPopup = ({ classes, chatBotData }) => {
    const userInput = useRef(null);
    const chatLogMsgParent = useRef(null);
    const sendIconRef = useRef(null);
    const dispatch = useDispatch();
    const [web, setweb] = useState(null);
    const [user, setchat] = useState(null);
    const [sdk, setsdk] = useState(null);
    const budget = useSelector((state) => state.budget);
    const portfolioData = useSelector((state) => state.portfolio);
    const [enableChatLog, setEnableChatlog] = useState(false);
    const [toggelListenVal, setToggleListen] = useState(false);
    const [toggleSpeakVal, setToggleSpeak] = useState(false);
    const [togglePauseVal, setTogglePause] = useState(false);
    const [gptVersionVal, setGptVersionVal] = useState('gpt-3.5-turbo');
    const [userMsgvalue, setUserMsgVal] = useState('');
    const [mayaMessage, setMayaMessage] = useState('Loading...');
    const [quesTypeMessage, setQuesType] = useState('Loading...');
    const [chatTopicType, setChatTopicType] = useState('general');
    const handleTopicClick = function (e, type) {
        setChatTopicType(type);
    };

    const [open, setOpen] = useState(false);
    const [documentType, setDocumentType] = useState('');
    const [documentIds, setDocumentIds] = useState([]);
    const [selecteDdocumentIds, setSelectedDocumentIds] = useState([]);
    const [userFiles, setUserFiles] = useState([]);
    const [userToken, setUserToken] = useState(null);
    const [userMsgs, setUserMsgs] = useState(chatBotData?.chatDetail);

    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;

    const handleGPTIconClick = () => {
        setGptVersionVal((prevVal) => (prevVal === 'gpt-3.5-turbo' ? 'gpt-4' : 'gpt-3.5-turbo'));
    };

    const handleGPTChange = (event) => {
        setGptVersionVal(event.target.checked ? 'gpt-4' : 'gpt-3.5-turbo');
    };

    const handleDocumentTypeChange = (event) => {
        setDocumentType(event.target.value || '');
    };

    const handleDocumentIdsChange = (event) => {
        setDocumentIds(event.target.value || '');
    };

    useEffect(() => {
        const document_ids = [];
        documentIds.map((document) => {
            document_ids.push(document.id);
        });
        setSelectedDocumentIds(document_ids || '');
    }, [documentIds]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            setOpen(false);
        }
    };
    const token = getCookie('auth_token');

    useEffect(() => {
        //fetch the list of uploaded files
        if (token) {
            dispatch(getBudgetFiles());
        }
    }, []);

    useEffect(() => {
        //fetch the list of uploaded files
        if (token) {
            dispatch(fetchPortfolioList());
        }
    }, []);

    useEffect(() => {
        if (documentType === 'portfolio') {
            setDocumentIds([]);
            setUserFiles([]);
            const portfolio_data = portfolioData.portfolioList;
            const portfolio_files = [];
            if (Object.keys(portfolio_data).length > 0) {
                portfolio_data.map((info) => {
                    portfolio_files.push({ name: info.file_name, id: info.id });
                });
            }
            setUserFiles(portfolio_files);
        }
        if (documentType === 'budget') {
            setDocumentIds([]);
            const budget_data = budget.budgetFileList;
            const budget_files = [];
            if (Object.keys(budget_data).length > 0) {
                budget_data.map((info) => {
                    budget_files.push({ name: info.file_name, id: info.id });
                });
            }
            setUserFiles(budget_files);
        }
    }, [documentType]);

    // to add chatbot script with other scripts of html page
    const listOfAllScript = () => {
        const srcList = [];
        let allscripts = window.document.getElementsByTagName('script');
        for (var key in allscripts) {
            if (allscripts.hasOwnProperty(key)) {
                //store all src in array
                if (allscripts[key].src) {
                    srcList.push(allscripts[key].src);
                }
            }
        }
        return srcList;
    };

    /**
     * to load sdk script in dom
     * @param {*} src url of chatbot sdk
     * @param {*} c callback method
     */

    // const loadJS = async (src, cb) => {
    //     const srcList = listOfAllScript();
    //     if (srcList.indexOf(src) <= -1) {
    //         //if chatbot sdk is not loaded
    //         var ref = window.document.getElementsByTagName('script')[0];
    //         var script = window.document.createElement('script');
    //         script.src = src;
    //         script.async = false;
    //         ref.parentNode.insertBefore(script, ref);
    //         if (cb) {
    //             script.addEventListener(
    //                 'load',
    //                 function (e) {
    //                     cb(null, e);
    //                 },
    //                 false
    //             );
    //         }
    //     }
    // };

    const loadJS = async (src, cb) => {
        const srcList = listOfAllScript();
        if (srcList.indexOf(src) <= -1) {
            // If the chatbot SDK is not loaded
            var ref = window.document.getElementsByTagName('script')[0];
            var script = window.document.createElement('script');
            script.src = src;
            script.async = false;
            ref.parentNode.insertBefore(script, ref);
            script.addEventListener(
                'load',
                (e) => {
                    if (cb) {
                        cb(null, e);
                    }
                },
                false
            );
            script.addEventListener(
                'error',
                (e) => {
                    console.error('Failed to load the chatbot SDK script:', e);
                    if (cb) {
                        cb(new Error('SDK script load error'), e);
                    }
                },
                false
            );
        } else if (cb) {
            cb(null);
        }
    };

    useEffect(() => {
        const sdkUrl = 'https://www.botlibre.com/scripts/sdk.js';
        loadJS(sdkUrl, function () {
            renderInit();
        });
        return () => {
            //remove script from dom else avatar will not display in chatbot
            const srcList = listOfAllScript();
            const scriptIndex = srcList.indexOf(sdkUrl);
            window.document
                .getElementsByTagName('script')
                [scriptIndex].parentNode.removeChild(window.document.getElementsByTagName('script')[scriptIndex]);
            setweb(null);
            setchat(null);
            setsdk(null);
        };
    }, []);

    // /**
    //  * initialize chatbot instance
    //  */
    // useEffect(() => {
    //     const sdkUrl = 'https://www.botlibre.com/scripts/sdk.js';
    //     let sdkLoaded = false;

    //     loadJS(sdkUrl, function () {
    //         sdkLoaded = true;
    //         renderInit();
    //     });

    //     return () => {
    //         const scriptList = listOfAllScript();
    //         const scriptIndex = scriptList.indexOf(sdkUrl);
    //         if (scriptIndex > -1) {
    //             window.document
    //                 .getElementsByTagName('script')
    //                 [scriptIndex].parentNode.removeChild(window.document.getElementsByTagName('script')[scriptIndex]);
    //         }
    //         setweb(null);
    //         setchat(null);
    //         setsdk(null);
    //     };
    // }, []);

    // var isEmbedded = true;
    // web.avatar = '20595840';
    // web.avatar = '11557990';
    // web.voice = '';
    // web.voiceMod = '';

    // const renderInit = () => {
    //     const me = window;
    //     if (me.SDK) {
    //         me.SDK.applicationId = '594565074446569060';
    //         var sdk = new me.SDKConnection();
    //         var user = new me.UserConfig();
    //         var web = new me.WebAvatar();
    //         web.connection = sdk;
    //         web.instance = '20873';
    //         web.userName = 'You';
    //         web.contactInfo = '';
    //         var isEmbedded = true;
    //         web.bubble = true;
    //         web.prefix = 'botplatformframe';
    //         // web.avatar = '20595840';
    //         web.avatar = '11785382';
    //         // web.avatar = '11557990';
    //         web.voice = '';
    //         web.voiceMod = '';
    //         web.width = '400';
    //         web.chatLog = true;
    //         web.avatarExpandable = true;
    //         web.speak = true;
    //         web.nativeVoice = false;
    //         web.nativeVoiceName = 'Google UK English Female';
    //         setweb(web);
    //         setchat(user);
    //         setsdk(sdk);
    //         setTimeout(() => {
    //             // open box will open avatar at specific place but element will not found instantly so need to use timeout to hold for few sec
    //             setMayaMessage(defaultMsg);
    //             web.openBox();
    //         }, 1000);
    //     }
    // };

    const activateFallbackMode = () => {
        setMayaMessage("The advanced chatbot features are currently unavailable. Please type your message, and I'll do my best to assist.");
        setweb(null); // Disable advanced features
        setchat(null);
        setsdk(null);
    };
    const renderInit = () => {
        const me = window;
        if (me.SDK) {
            try {
                me.SDK.applicationId = '594565074446569060';
                var sdk = new me.SDKConnection();
                var user = new me.UserConfig();
                var web = new me.WebAvatar();
                web.connection = sdk;
                web.instance = '20873';
                web.userName = 'You';
                web.contactInfo = '';
                var isEmbedded = true;
                web.bubble = true;
                web.prefix = 'botplatformframe';
                web.avatar = '11785382';
                web.voice = '';
                web.voiceMod = '';
                web.width = '400';
                web.chatLog = true;
                web.avatarExpandable = true;
                web.speak = true;
                web.nativeVoice = false;
                web.nativeVoiceName = 'Google UK English Female';
                setweb(web);
                setchat(user);
                setsdk(sdk);
                setTimeout(() => {
                    setMayaMessage(defaultMsg);
                    web.openBox();
                }, 1000);
            } catch (error) {
                console.error('Failed to initialize chatbot:', error);
                activateFallbackMode();
            }
        } else {
            activateFallbackMode();
        }
    };

    useEffect(() => {
        // pass message to avatar to speak
        if (web && user) {
            if (user.user != null) {
                sdk.connect(user, function (user) {
                    web.addMessage('Loading...');
                    web.processMessages();
                });
            } else {
                web.addMessage(mayaMessage);
                web.processMessages();
            }
        }
    }, [mayaMessage]);

    // whenever api will get the response , pass those message to avatar to speak
    useEffect(() => {
        if (chatBotData.roboResponse.length && web) {
            setMayaMessage(chatBotData.roboResponse.toString());
            web.addMessage(chatBotData.roboResponse.toString());
            web.processMessages();
            if (web.pause) {
                togglePause();
            }
        }
    }, [chatBotData.roboResponse]);

    // to scroll down to latest chat while chatlog is enabled
    useEffect(() => {
        if (chatLogMsgParent.current) {
            const chatLogDiv = chatLogMsgParent.current;
            chatLogDiv.scrollTop = chatLogDiv.scrollHeight;
        }
    }, [chatBotData, enableChatLog]);

    // to download chat log into pdf format
    const exportChat = () => {
        var text = '';
        var pageWidth = 8.5,
            lineHeight = 1.5,
            margin = 0.5,
            ptsPerInch = 72,
            fontSize = 14,
            oneLineHeight = (fontSize * lineHeight) / ptsPerInch,
            maxLineWidth = pageWidth - margin * 2;
        if (chatBotData.chatDetail.length) {
            chatBotData.chatDetail.forEach((chat) => {
                const date = moment.unix(chat.date).format('MM/DD/YYYY h:mm a');
                const userType = chat.type === 'robo' ? 'Finbot' : 'You';
                const chatString = `${date} ${userType}: ${converter.makeMarkdown(chat.msg.toString())}`;
                text += chatString + '\n' + '\n';
            });
            let doc = new jsPDF({
                format: 'a4',
                unit: 'in',
                lineHeight: lineHeight
            }).setProperties({ title: 'String Splitting' });
            const add_watermark = () => {
                doc.saveGraphicsState();
                doc.setGState(new doc.GState({ opacity: 0.2 }));
                var img = new Image();
                img.src = '/img/logo.png';
                doc.addImage(img, 'PNG', 1.6, 5, 5, 1.8);
                doc.restoreGraphicsState();
            };
            add_watermark();

            // splitTextToSize takes your string and turns it in to an array of strings,
            // each of which can be displayed within the specified maxLineWidth.
            var textLines = doc.setFontSize(fontSize).splitTextToSize(text, maxLineWidth);
            // to dived text into chunk so that only that lines can be visible to page and will not overflow text or cropout text
            const lineArr = splitArrayIntoChunksOfLen(textLines, 35);
            lineArr.forEach((val, i) => {
                if (i > 0) {
                    doc.addPage();
                    add_watermark();
                }
                doc.text(val, margin, margin + 2 * oneLineHeight);
            });

            doc.save('finbot_Chatlog.pdf');
        } else {
            toast.error('You can not export empty chat.');
        }
    };
    /**
     * to take audio/speech from user
     * @returns
     */

    useEffect(() => {
        setUserMsgs(chatBotData?.chatDetail);
    }, [chatBotData?.chatDetail]);

    // useEffect(() => {
    //     if (toggelListenVal && !userInput.current.value) {
    //         setTimeout(() => {
    //             setToggleListen(false);
    //             setTogglePause(false);
    //             setToggleSpeak(false);
    //             window.SDK.stopSpeechRecognition();
    //             document.getElementById('botplatformframeavatar-video').pause();
    //         }, 5000);
    //     }
    // }, [toggelListenVal]);

    const toggleListen = () => {
        let listen = !toggelListenVal;
        setToggleListen(listen);
        if (web) {
            window.SDK.registerSpeechRecognition(userInput.current);
        }
        if (listen) {
            window.SDK.startSpeechRecognition();
        } else {
            window.SDK.stopSpeechRecognition();
            setUserMsgVal(userInput.current.value);
        }
        return false;
    };
    // to handle avatar audio
    const toggleSpeak = () => {
        const speak = !toggleSpeakVal;
        web.speak = !web.speak;
        setToggleSpeak(speak);
        if (window.SDK.audio != null) {
            window.SDK.audio.muted = speak;
        }
        if (window.SDK.currentAudio != null) {
            window.SDK.currentAudio.muted = speak;
        }
    };

    const showdown = require('showdown');
    const converter = new showdown.Converter();

    // const togglePause = () => {
    //     let pause = !togglePauseVal;
    //     web.pause = !web.pause;
    //     setTogglePause(pause);
    //     if (window.SDK.audio != null) {
    //         window.SDK.audio.pause();
    //     }
    //     if (window.SDK.currentAudio != null) {
    //         window.SDK.currentAudio.pause();
    //     }
    //     if (!pause) {
    //         window.SDK.currentAudio.play();
    //         document.getElementById('botplatformframeavatar-video').play();
    //     } else {
    //         document.getElementById('botplatformframeavatar-video').pause();
    //     }
    // };

    const togglePause = () => {
        let pause = !togglePauseVal;
        web.pause = !web.pause;
        setTogglePause(pause);
        if (window.SDK.audio != null) {
            window.SDK.audio.pause();
        }
        if (window.SDK.currentAudio != null) {
            window.SDK.currentAudio.pause();
        }
        if (window.SDK && window.SDK.currentAudio) {
            const videoElement = document.getElementById('botplatformframeavatar-video');
            if (videoElement) {
                if (!pause) {
                    if (window.SDK.currentAudio.play) {
                        window.SDK.currentAudio.play();
                    }
                    videoElement.play();
                } else {
                    videoElement.pause();
                }
            } else {
                console.warn('Video element not found');
            }
        } else {
            console.warn('SDK or currentAudio is not available');
        }
    };

    // //submit entered message
    // const onSubmit = (e) => {
    //     e.preventDefault();
    //     const value = userInput.current.value;
    //     if (!value) return;
    //     // Check if SDK is available before proceeding
    //     if (window.SDK && typeof window.SDK.initAudio === 'function') {
    //         window.SDK.initAudio();
    //         web.addMessage();
    //         web.processMessages();
    //     } else {
    //         console.warn('SDK is not available. Cannot initialize audio or process messages.');
    //         // Optionally, handle the fallback scenario here
    //         setMayaMessage('Sorry, the chat service is currently unavailable.');
    //         return; // Exit early since SDK is not available
    //     }
    //     web.addMessage();
    //     web.processMessages();
    //     if (chatTopicType === 'general') {
    //         setUserMsgs([...userMsgs, { date: Math.floor(new Date().getTime() / 1000).toString(), msg: value, type: 'user' }]);
    //         dispatch(sendChatBotMessage({ user_response: value, type: chatTopicType, gpt_model: gptVersionVal }));
    //         setUserMsgVal('');
    //         // setUserMsgVal(value);
    //         // setTimeout(() => {
    //         // dispatch(sendChatBotMessage({ user_response: value, type: chatTopicType }));
    //         setToggleListen(false);
    //         setTogglePause(false);
    //         setToggleSpeak(false);
    //         window.SDK.stopSpeechRecognition();
    //         document.getElementById('botplatformframeavatar-video').pause();
    //         // }, 1000);
    //     }
    //     if (chatTopicType === 'document-analysis') {
    //         dispatch(sendChatBotAnalysisMessage({ documentType: documentType, ids: selecteDdocumentIds, question: value }));
    //     }
    //     // setUserMsgVal('');
    //     setMayaMessage("I'm writing my response...");
    //     if (web.pause) {
    //         togglePause();
    //     }
    // };

    const onSubmit = (e) => {
        e.preventDefault();
        const value = userInput.current.value;
        if (!value) return;

        if (sdk) {
            // Advanced mode: SDK is available
            try {
                if (typeof window.SDK.initAudio === 'function') {
                    window.SDK.initAudio();
                }
                web.addMessage();
                web.processMessages();

                if (chatTopicType === 'general') {
                    setUserMsgs([...userMsgs, { date: Math.floor(new Date().getTime() / 1000).toString(), msg: value, type: 'user' }]);
                    dispatch(sendChatBotMessage({ user_response: value, type: chatTopicType, gpt_model: gptVersionVal }));
                    setUserMsgVal('');
                    setToggleListen(false);
                    setTogglePause(false);
                    setToggleSpeak(false);
                    if (typeof window.SDK.stopSpeechRecognition === 'function') {
                        window.SDK.stopSpeechRecognition();
                    }
                    const videoElement = document.getElementById('botplatformframeavatar-video');
                    if (videoElement) {
                        videoElement.pause();
                    }
                }

                if (chatTopicType === 'document-analysis') {
                    dispatch(sendChatBotAnalysisMessage({ documentType: documentType, ids: selecteDdocumentIds, question: value }));
                }
            } catch (error) {
                console.error('Error in SDK processing:', error);
                activateFallbackMode();
            }
        } else {
            // Fallback mode: SDK is unavailable
            setUserMsgs([...userMsgs, { date: Math.floor(new Date().getTime() / 1000).toString(), msg: value, type: 'user' }]);
            setMayaMessage("I'm currently unable to process advanced requests. Please type your message and I will do my best to assist.");
        }

        setMayaMessage("I'm writing my response...");
        if (web && web.pause) {
            togglePause();
        }
    };

    // box of ui where avatar will be visible when openBox will called
    const renderBox = () => {
        return (
            <div id="botplatformframeavatarbox" className={`botplatformframeavatarbox  ${enableChatLog ? 'hide' : 'avatarBoxCenter'}`}>
                <div id="botplatformframeavatar-image-div">
                    <img id="botplatformframeavatar" />
                </div>
                <div id="botplatformframeavatar-video-div">
                    <video
                        muted={true}
                        id="botplatformframeavatar-video"
                        autoPlay
                        preload="auto"
                        src="https://www.botlibre.com/media/a32498661-2389797142708236741.webm"
                    >
                        Video format not supported by your browser (try Chrome)
                    </video>
                </div>
                <div id="botplatformframeavatar-canvas-div">
                    <canvas id="botplatformframeavatar-canvas" width={400} height={400}>
                        Canvas not supported by your browser (try Chrome)
                    </canvas>
                </div>
            </div>
        );
    };

    return (
        <ChatboxForm>
            <CardContent>
                <TypeBox>
                    <CustomButton
                        w="154px"
                        className={`${chatTopicType === 'general' && 'type-active'}`}
                        variant="outlined"
                        onClick={(e) => handleTopicClick(e, 'general')}
                    >
                        General
                    </CustomButton>
                    <CustomButton
                        w="154px"
                        onClick={(e) => {
                            handleTopicClick(e, 'document-analysis');
                            handleClickOpen();
                        }}
                        variant="outlined"
                        className={`${chatTopicType === 'document-analysis' && 'type-active'}`}
                    >
                        Document Analysis
                    </CustomButton>
                    {token ? (
                        <Dialog disableEscapeKeyDown open={open} onClose={handleClose}>
                            <DialogTitle>Select documents for analysis.</DialogTitle>
                            <DialogContent>
                                <Box component="form" sx={{ display: 'flex', flexWrap: 'wrap' }}>
                                    <FormControl sx={{ m: 1, width: 120 }}>
                                        <InputLabel id="demo-dialog-select-label">File Type</InputLabel>
                                        <Select
                                            labelId="demo-dialog-select-label"
                                            id="demo-dialog-select"
                                            value={documentType}
                                            onChange={handleDocumentTypeChange}
                                            input={<OutlinedInput label="Document type" />}
                                        >
                                            <MenuItem value={'portfolio'}>Portfolio</MenuItem>
                                            <MenuItem value={'budget'}>Budget</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <FormControl sx={{ m: 1, minWidth: 120, maxWidth: 154 }}>
                                        <InputLabel id="demo-dialog-select-label">File Name</InputLabel>
                                        <Select
                                            labelId="demo-dialog-select-label"
                                            id="demo-dialog-select"
                                            value={documentIds}
                                            multiple
                                            displayEmpty
                                            onChange={handleDocumentIdsChange}
                                            input={<OutlinedInput label="Documents" />}
                                            renderValue={(selected) => selected.map((x) => x.name).join(', ')}
                                        >
                                            {documentType ? (
                                                userFiles.map((file_info) => {
                                                    return (
                                                        <MenuItem key={file_info.name} value={file_info}>
                                                            <Checkbox
                                                                checked={documentIds.findIndex((item) => item.id === file_info.id) >= 0}
                                                            />
                                                            <ListItemText primary={file_info.name} />
                                                        </MenuItem>
                                                    );
                                                })
                                            ) : (
                                                <MenuItem value="">
                                                    <em>Select the File Type first</em>
                                                </MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </Box>
                            </DialogContent>
                            <DialogActions>
                                <CustomButton variant="outlined" onClick={handleClose}>
                                    Cancel
                                </CustomButton>
                                <CustomButton variant="outlined" onClick={handleClose}>
                                    Ok
                                </CustomButton>
                            </DialogActions>
                        </Dialog>
                    ) : (
                        <>
                            <StyledPermissionDialog
                                open={open}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <CustomDialogTitle id="alert-dialog-title">Sign In</CustomDialogTitle>
                                <Divider />
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">Please sign in to use this feature.</DialogContentText>
                                </DialogContent>
                                <DialogActions className={classes.dialogActionBtn}>
                                    <Button
                                        component={Link}
                                        onClick={() => dispatch(handleSignInPermissionDialog(false))}
                                        color="primary"
                                        size="xs"
                                        to="/login"
                                        variant="contained"
                                        rel="noopener noreferrer"
                                        style={{ marginRight: '37%' }}
                                    >
                                        Login
                                    </Button>
                                </DialogActions>
                            </StyledPermissionDialog>
                        </>
                    )}

                    {/* <CustomButton
                        w="154px"
                        className={`${chatTopicType === 'tpe-hub' && 'type-active'}`}
                        variant="outlined"
                        onClick={(e) => handleTopicClick(e, 'tpe-hub')}
                    >
                        TPE-Hub
                    </CustomButton> */}
                </TypeBox>
                <TypeBox style={{ justifyContent: 'center' }}>
                    <Grid item>
                        <Typography variant="body2">GPT3.5-Turbo</Typography>
                    </Grid>
                    <Grid item>
                        <Switch checked={gptVersionVal === 'gpt-4'} onChange={handleGPTChange} value="checked" size="small" />
                    </Grid>
                    <Grid item>
                        <Typography variant="body2">GPT4</Typography>
                    </Grid>
                </TypeBox>

                <Paper elevation={0} className={enableChatLog ? '' : 'hide'}>
                    {web && (
                        <div className={classes.chatLogAvatar}>
                            <CardMedia
                                className={classes.botplatformchatBot}
                                component="img"
                                src={'https://www.botlibre.com/media/a12546662-0.png'}
                            />
                        </div>
                    )}
                    <Divider className={classes.chatLogDivider} />
                    <div className={classes.chatLogMsgWrapper} ref={chatLogMsgParent}>
                        {userMsgs?.length > 0 ? (
                            <ChatBubble chatData={userMsgs} classes={classes} />
                        ) : (
                            <NoDataFound msg="No chat log found." />
                        )}
                    </div>
                    {chatBotData.loader ? <ChatLogLoader /> : ''}
                </Paper>
                <div id="chatbot-container">
                    {/* Render the chatbot box only if the SDK is loaded */}
                    {web && (
                        <>
                            {renderBox()}
                            <div id="botplatformframebubble-div" className={`botplatformframebubble-div ${enableChatLog ? 'hide' : ''}`}>
                                <div id="botplatformframebubble" className="botplatformframebubble">
                                    <div id="botplatformframebubble-text" className="botplatformframebubble-text">
                                        <span id="botplatformframeresponse" className="botplatformframeresponse">
                                            {parse(converter.makeHtml(mayaMessage))}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
                <ChatbotFooterBox>
                    <input
                        value={userMsgvalue}
                        ref={userInput}
                        id="botplatformframechat"
                        className="botplatformframechat-input"
                        placeholder="Enter your question here..."
                        type="text"
                        name="input"
                        onChange={(e) => {
                            setUserMsgVal(e.target.value);
                        }}
                    />
                    <Button onClick={onSubmit} id="sendicon" type="submit" justIcon variant="contained" color="rose">
                        <SendIcon />
                    </Button>
                </ChatbotFooterBox>
            </CardContent>
            <CardActions disableSpacing>
                {/* <IconButton
                    aria-label='language'
                    component='span'
                    className='dropt'>
                    <TranslateIcon />
                    <div className={classes.demo} id='subMenu-Chatbot'>
                      <List>
                        <ListItem button>
                          <select
                            id='chooselanguage'
                            onChange={(e) => handleLangChange(e)}>
                            <option value='none'>Choose Language</option>
                            {langList.map((list) => (
                              <option
                                value={list.value}
                                key={list.value + "langList"}>
                                {list.label}
                              </option>
                            ))}
                          </select>
                        </ListItem>
                      </List>
                    </div>
                  </IconButton> */}
                <CustomTooltip
                    tooltipText={
                        // 'Handle finbot audio speech (speech requires an HTML5 audio supporting browser, such as Chrome or Firefox)'
                        'Enable/Disable speaker'
                    }
                    customIconTooltip={true}
                    textTooltip={false}
                >
                    <IconButton aria-label="speak" onClick={toggleSpeak}>
                        {toggleSpeakVal ? <VolumeOffIcon /> : <VolumeUpIcon />}
                    </IconButton>
                </CustomTooltip>
                <CustomTooltip tooltipText={'Play/Pause'} customIconTooltip={true} textTooltip={false}>
                    <IconButton aria-label="pause" onClick={togglePause}>
                        {togglePauseVal ? <PlayArrowIcon /> : <PauseIcon />}
                    </IconButton>
                </CustomTooltip>
                {/* </Tooltip> */}
                <CustomTooltip
                    tooltipText="Click this button to record your question. Click it again when you are finished. Please be aware, your browser may ask permission to record the first time you record in our app"
                    customIconTooltip={true}
                    textTooltip={false}
                >
                    <div className={toggelListenVal && classes.speechRecogParent}>
                        <IconButton aria-label="listen" onClick={toggleListen}>
                            <div className="pulse-ring"></div>
                            {!toggelListenVal ? <MicOffIcon /> : <MicIcon className="micOn" />}
                        </IconButton>
                    </div>
                </CustomTooltip>

                <CustomTooltip
                    tooltipText={
                        "We save the data in a secure database for future use only and do not share it outside of TPE. The accuracy of the information isn't guaranteed and/or may have changed from the time we sourced the information. Please seek professional finance advice."
                    }
                    customIconTooltip={true}
                    textTooltip={false}
                >
                    <IconButton aria-label="fui">
                        <PrivacyTipIcon color="grey" />
                    </IconButton>
                </CustomTooltip>

                {/* <Tooltip title='Upload and resize an image to send to the bot'>
            <IconButton
              aria-label='send image'
              onClick={(e) => sendImage()}>
              <ImageIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title='Upload a file or full size image to send to the bot'>
            <IconButton
              aria-label='send attachment'
              onClick={(e) => sendAttachment()}>
              <AttachFileIcon />
            </IconButton>
          </Tooltip> */}
                <CustomTooltip tooltipText={'Chat log'} customIconTooltip={true} textTooltip={false}>
                    <IconButton aria-label="chat log" onClick={(e) => setEnableChatlog(!enableChatLog)}>
                        <SubjectIcon />
                    </IconButton>
                </CustomTooltip>

                <CustomTooltip tooltipText={'Export Chat'} customIconTooltip={true} textTooltip={false}>
                    <IconButton aria-label="export chat" onClick={exportChat}>
                        <GetAppIcon />
                    </IconButton>
                </CustomTooltip>

                <CustomTooltip
                    tooltipText={gptVersionVal === 'gpt-3.5-turbo' ? 'GPT3.5 Turbo' : 'GPT4'}
                    customIconTooltip={true}
                    textTooltip={false}
                >
                    <IconButton aria-label="swith gpt version" onClick={handleGPTIconClick}>
                        {gptVersionVal === 'gpt-4' ? <PsychologyIcon /> : <ManageAccountsIcon />}
                    </IconButton>
                </CustomTooltip>
                {!token && (
                    <>
                        <CustomTooltip
                            tooltipText={'Registration is Required to Access Full Memory Feature.'}
                            customIconTooltip={true}
                            textTooltip={false}
                        >
                            <IconButton aria-label="memory feature.">
                                <MemoryIcon />
                            </IconButton>
                        </CustomTooltip>
                    </>
                )}
            </CardActions>
        </ChatboxForm>
    );
};

export default ChatBoxPopup;
