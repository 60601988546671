import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import feedbackPage from "assets/jss/material-kit-react/views/feedbackPage";
import React, { useState, Fragment } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { submitFeedback } from "redux/actions/GeneralCalActions";
import RenderField from "utils/RenderField";
import { CustomButton } from "assets/jss/ButtonStyle";
import { FeedbackContainerBox } from "assets/jss/GeneralStyle";
import CustomDialogTitle from "components/DialogTitle";
import { Grid } from "@mui/material";
import { CalCardActions } from "assets/jss/RetirementStyle";
import Loader from "components/CommonComponent/Loader";

const Transition = React.forwardRef(function (props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});
const fieldsDetail = [
  {name:'rate',label:"",type:"rating",size:"large"},
  { name: "feedback_cate", label: "Feedback Category", type: 'select', required: true, options: [{ value: 'question', label: "Question" }, {value:"bug",label:"Report Bug"},{value:"feature",label:"Feature Request"},{"value":"general",label:"General"},{value:"specific",label:"For specific page"}] },
  { name: "from_page", label: "Module Category", type: 'select', options: [{label:"Annuity/Pension Analysi",value:"annuitytool"},{"label": "Budget Analysis","value": "budget_ana"},{"label": "Budget Calculator","value": "budget_cal"},{"label": "Debt Calculator","value": "debtcalculator"},{"label": "Equity Dashboard","value": "equity_dash"},{"label": "Fixed Income Dashboard","value": "income_dash"},{"label": "Fx Calculator","value": "fx_cal"},{"label": "Home Valuation Tool","value": "question"},{"label": "Life Insurance Calculator","value": "life_insur_cal"},{"label": "Loan Dashboard","value": "loan_dash"},{"label": "Option Pricing Calculator","value": "option_pri_cal"},{"label": "Portfolio Allocation","value": "port_allo"},{"label": "Portfolio Valuation","value": "port_val"},{"label": "Property Investment Tool","value": "invest_cal"},{"label": "Retirement Calculator","value": "retire_cal"},{"label": "Student Loan Calculator","value": "student_loan_cal"},{"label": "Tips Calculator","value": "tips_cal"},{"label": "Todo","value": "todo"},{"label": "Contact us","value": "contact_us"}] },
  { name: "email", label: "Email", type: 'email', placeholder: 'Enter email address',custompattern:'email' },
  { name: "message", label: "Message", type: 'text', required: true, placeholder: 'Enter feedback message',multiline:true,maxRows:8,custompattern:/^([a-z0-9A-Z!;,.?' ]{1,100})$/g}
];
const useStyles = makeStyles(feedbackPage);
const FeedbackIndex = props => {
    const dispatch = useDispatch();
    const history = useLocation();
  const general = useSelector((state) => state.generalCal);
  const [open, setOpen] = useState(false);
  const [enablePageOption, setEnablePageOption] = useState(false);
  const {
    handleSubmit,
    control,reset,
    formState: {  isSubmitting },
    setValue,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      rate: 0,
      feedback_cate: "general",
      from_page: "",
      email: '',
      message: "",
    },
  });
  // useEffect(() => {
  //   if (Object.keys(props.setting.profileDetail).length) {
  //     setValue("email", props.setting.profileDetail.email);
  //   }
  // }, [props.setting.profileDetail]);

  const classes = useStyles();
  const handleClickOpen = () => {
    if (Object.keys(props.setting.profileDetail).length) {
      setValue("email", props.setting.profileDetail.email);
    }
    setOpen(true);
  };
  /// to close the dialog
  const handleClose = () => {
    setOpen(false);
    reset()
  };
   //to submit the form data
  const onSubmit = (data) => {
    const pagelist = {
      "/": "Landing Page",
      "/home-valuation-calculator": "Home Valuation Tool",
      "/home-purchase-tool": "Home Purchase Tool",
      "/option-pricing-calculator": "Option Pricing Calculator",
      "/retirement-calculator": "Retirement Calculator",
      "/portfolio-valuation-calculator": "Portfolio Valuation",
      "/portfolio-allocation-calculator": "Portfolio Allocation",
      "/fx-conversion-calculator": "Fx Calculator",
      "/student-loan-calculator": "Student Loan Calculator",
      "/inflation-bond-calculator": "Inflation Bond Calculator",
      // "/tips-calculator": "Tips Calculator",
      "/budget-calculator": "Budget Calculator",
      "/budget-analysis-calculator": "Budget Analysis",
      "/property-investment-tool": "Property Investment Tool",
      "/life-insurance-calculator": "Life Insurance Calculator",
      "/loan-dashboard": "Loan Dashboard",
      "/fixed-income-dashboard": "Fixed Income",
      "/equity-dashboard": "Equity Dashboard",
      "/about-us": "About us",
      "/contact-us": "Contact us",
      "/finance-task-list": "Todo",
      "/mutual-fund-etf-tool": "Mutual Fund",
      "/annuity-calculator": "Annuity/Pension Analysi",
      "/debt-calculator": "Debt Calculator",
      "/login": "Signin",
    };
    const objToSend = {
      email: data.email,
      feedback_cate: data.feedback_cate,
      from_page:
        data.feedback_cate === "specific"
          ? data.from_page || pagelist[history.pathname]
          : pagelist[history.pathname],
      message: data.message,
      rate: data.rate || 0,
    };
    dispatch(submitFeedback(objToSend));
    handleClose();
  };
   // when any input field value will change it will call below method
  const handleFormInputChange = (e, fieldname = null) => {
    // to keep open specific page dropdown option untill feedback category will change
    if (fieldname === "feedback_cate" && e.target.value==="specific") {
      setEnablePageOption(true);
    } else if (fieldname !== "email" && fieldname !== "message" && fieldname!=='from_page' && fieldname !== 'rate') {
      setEnablePageOption(false);
    }
  };
  return (
      <FeedbackContainerBox>
          {general.loader ? <Loader /> : ''}
          <CustomButton variant="contained" onClick={handleClickOpen} className="feedbackbtn">
              <Typography variant="subtitle1">Feedback</Typography>
          </CustomButton>
          <Dialog
              open={open}
              TransitionComponent={Transition}
              keepMounted
              onClose={handleClose}
              aria-labelledby="feedback dialog"
              aria-describedby="TPE Feedback "
          >
              <CustomDialogTitle id="customized-dialog-title" onClose={handleClose}></CustomDialogTitle>
              <form onSubmit={handleSubmit(onSubmit)}>
                  <DialogContent>
                      <Grid container>
                          {fieldsDetail.map((val, i) => {
                              return !enablePageOption && val.name === 'from_page' ? (
                                  <Fragment key={i + 'feedbackField_fragment'}></Fragment>
                              ) : (
                                  <Grid
                                      item
                                      xs={12}
                                      sm={val.name === 'rate' ? 12 : 6}
                                      key={i + 'feedbackField'}
                                      className={val.name === 'rate' && classes.ratingdiv}
                                  >
                                      <RenderField
                                          handleOnChange={(e) => handleFormInputChange(e, val.name)}
                                          name={val.name}
                                          control={control}
                                          label={val.label}
                                          id={val.name + '_feedback'}
                                          type={val.type}
                                          options={val.options}
                                          required={val.required || false}
                                          thousandSeparator={val.commaSep}
                                          customPatternName={val.custompattern}
                                          placeholder={val.placeholder}
                                          setValue={setValue}
                                          {...val}
                                      />
                                  </Grid>
                              );
                          })}
                      </Grid>
                  </DialogContent>
                  <CalCardActions className="contactus-cardaction">
                      <CustomButton type="submit" variant="contained" disabled={isSubmitting}>
                          Submit
                      </CustomButton>
                  </CalCardActions>
              </form>
          </Dialog>
      </FeedbackContainerBox>
  );
};
export default FeedbackIndex;
