import * as ActionTypes from "../actions/ActionTypes";

const initialState = {
    mutualFundData: [],
    paginationInfo: {},
    loader: false,
    tickerList: [],
    comparisonList: {},
    portfolioMutualTicker: {},
    closeViewPopup: false
};

export const MutualFundReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.FETCH_MUTUAL_TICKER_INIT:
        case ActionTypes.FETCH_ALL_TICKERS_INIT:
        case ActionTypes.GET_SELECTED_MFTICKER_INIT:
            return { ...state, loader: true };
        case ActionTypes.FETCH_MUTUAL_TICKER_SUCCESS:
            return { ...state, loader: false, mutualFundData: action.data.data, paginationInfo: action.data.pagination_detail };
        case ActionTypes.FETCH_ALL_TICKERS_SUCCESS:
            return { ...state, loader: false, tickerList: action.data };
        case ActionTypes.GET_SELECTED_MFTICKER_SUCCESS:
            let filteredData = action.data;
            let formatChartData = [];
            if (filteredData.length > 0) {
                let temp = {};
                filteredData.forEach((val, i) => {
                    const tempTicker = val.Ticker;
                    Object.keys(val).forEach((v) => {
                        if (v !== 'Ticker' && v !== 'id') {
                            if (temp[v]) {
                                const to = { [tempTicker]: val[v] };
                                temp[v] = { ...temp[v], ...to };
                            } else {
                                temp[v] = { 'key spec': v, [tempTicker]: val[v] };
                            }
                        }
                    });
                });
                filteredData = Object.values(temp);
                const ignoreFields = ['id'];
                Object.keys(filteredData[0]).forEach((subVal) => {
                    if (!ignoreFields.includes(subVal)) {
                        formatChartData.push({
                            Header: subVal,
                            sticky: subVal === 'key spec' ? true : false,
                            accessor: subVal,
                            cellColorArr: (subVal === 'key spec' && ['Sector/Industry 1', 'Top holdings 1', 'Country Exposure 1']) || [],
                            disableFilters: true
                        });
                    }
                });
            }
            return { ...state, loader: false, comparisonList: { column: formatChartData, data: filteredData } };
        case ActionTypes.SUBMIT_MUTUAL_TICKER_INIT:
            return { ...state, loader: true, portfolioMutualTicker: {} };
        case ActionTypes.SUBMIT_MUTUAL_TICKER_SUCCESS:
            return { ...state, loader: false, portfolioMutualTicker: action.data };
        case ActionTypes.SUBMIT_MUTUAL_TICKER_ERROR:
            return { ...state, loader: false, portfolioMutualTicker: {}, closeViewPopup: true };

        case ActionTypes.RESET_CLOSE_POPUP:
            return { ...state, closeViewPopup: false };

        case ActionTypes.FETCH_ALL_TICKERS_ERROR:
        case ActionTypes.FETCH_MUTUAL_TICKER_ERROR:
        case ActionTypes.GET_SELECTED_MFTICKER_ERROR:
            return { ...state, loader: false };
        default:
            return state;
    }
};
