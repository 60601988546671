import AddCircleIcon from '@mui/icons-material/AddCircle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CachedIcon from '@mui/icons-material/Cached';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { AccordionDetails, AccordionSummary, Box, Card, CardContent, Divider, Grid, IconButton } from '@mui/material';
import Typography from '@mui/material/Typography';
import { CustomButton } from 'assets/jss/ButtonStyle';
import { AccordianGridItem, CalAccordion } from 'assets/jss/GeneralStyle';
import {
    AddIconButtonGridItem,
    CalCardActions,
    RemoveIconButtonGridItem,
    SliderParentGrid,
    TwoValSliderGrid
} from 'assets/jss/RetirementStyle';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { getDefaultOverride } from 'redux/actions/GeneralCalActions';
import { fetchSavedData, fetchSavedDataSuccess, submitRetirementCal, SubmitRetirementCalSuccess } from 'redux/actions/RetirementCalActions';
import RenderField from 'utils/RenderField';
import { getCookie } from 'utils/Utils';
import * as tooltip from 'Tooltip.json';
import CustomTooltip from '../../components/CommonComponent/CustomTooltip';
import OptionalAssumption from './OptionalAssumption';
import { StudLabelGrid } from 'assets/jss/CommonCalStyle';
import { StudInputGrid } from 'assets/jss/CommonCalStyle';
import { numberWithCommas } from 'utils/Utils';
import { fetchCurrentNetworth } from 'redux/actions/RetirementCalActions';

var defaultVal = {
    gender: 'F',
    currentNetworth: 1000000,
    requiredNetworth: 0,
    requiredNetworth_slider: 0,
    incBeforeRetire: 85000,
    incAfterRetire: 40000,
    expBeforeRetire: 48000,
    expAfterRetire: 24000,
    investReturn: 7.0,
    inflation: 7.0,
    amountPerYear_0: 1,
    // endYear_0: new Date().getFullYear() + 1,
    // startYear_0: new Date().getFullYear(),
    endYear_0: 1,
    startYear_0: 0,
    mortalityAge: 0,
    currentAge: 55,
    ages: [45, 65],
    retirementAge: 65,
    eventName_0: '',
    taxRate: 25.0,
    estateGoal: 0,
    estateGoal_slider: 0,
    expense_solver: 'true',
    income_solver: 'true',
    invest_solver: 'true'
};
function RetirementCalForm(props) {
    let apiToken = getCookie('auth_token');
    const [lifeEventIndexes, setIndexes] = useState([0]);
    let [counter, setCounter] = useState(0);
    const [disabledLoadBtn, setDisabledLoadBtn] = useState(false);
    const [result, setResult] = useState([]);
    const dispatch = useDispatch();
    const {
        handleSubmit,
        control,
        formState: { isDirty, isSubmitting },
        setValue,
        getValues
    } = useForm({
        mode: 'onChange',
        defaultValues: defaultVal
    });
    const currYear = new Date().getFullYear();

    const handleFetchCurrentNetworth = async () => {
        const res = await fetchCurrentNetworth();
        if (res?.market_value) {
            setValue('currentNetworth', res.market_value);
        }
    };

    const onSubmit = (data) => {
        setDisabledLoadBtn(true);
        // if (data.mortalityAge !== 0 && data.mortalityAge <= data.currentAge) {
        //     toast.error('Mortality age should be greater than current age.');
        //     return;
        // }
        const lifeEventFieldNames = ['id', 'amountPerYear', 'endYear', 'eventName', 'eventType', 'startYear'];
        let finalLifeEventValues = [];
        // create array object format to store all life event data
        let lifeEventError = 0;
        let lifeEventErrorMsg = '';
        lifeEventIndexes.forEach((fieldIndex) => {
            let valToAppend = {};
            lifeEventFieldNames.filter((fieldName) => {
                if (fieldName === 'amountPerYear') {
                    const unformattedValue = parseInt(data[fieldName + '_' + fieldIndex + 'UnFormattedValue'], 10);
                    const formattedValue = parseInt(data[fieldName + '_' + fieldIndex], 10);
                    valToAppend = {
                        ...valToAppend,
                        [fieldName]: unformattedValue || formattedValue
                    };
                } else if (
                    fieldName === 'id' &&
                    props?.retirementCalData?.savedRetirementDetail?.lifeEvent?.length > 0 &&
                    props?.retirementCalData?.savedRetirementDetail?.lifeEvent[fieldIndex] != undefined
                ) {
                    valToAppend = {
                        ...valToAppend,
                        [fieldName]: props?.retirementCalData?.savedRetirementDetail?.lifeEvent[fieldIndex]['id']
                    };
                } else if (fieldName === 'endYear') {
                    valToAppend = {
                        ...valToAppend,
                        [fieldName]: data[fieldName + '_' + fieldIndex] === undefined ? 1 : data[fieldName + '_' + fieldIndex]
                    };
                } else if (fieldName === 'startYear') {
                    valToAppend = {
                        ...valToAppend,
                        [fieldName]: data[fieldName + '_' + fieldIndex] === undefined ? 0 : data[fieldName + '_' + fieldIndex]
                    };
                } else {
                    valToAppend = {
                        ...valToAppend,
                        [fieldName]: data[fieldName + '_' + fieldIndex + 'UnFormattedValue'] || data[fieldName + '_' + fieldIndex]
                    };
                }
                return finalLifeEventValues; // Not sure what finalLifeEventValues is used for here
            });

            const yearToLive = data.mortalityAge - data.currentAge;
            // valToAppend.endYear = valToAppend.endYear -  currYear
            // valToAppend.startYear = valToAppend.startYear - currYear
            if (valToAppend.amountPerYear > 0) {
                valToAppend.eventType = 'income_increase';
            } else {
                valToAppend.eventType = 'expense_increase';
                valToAppend.amountPerYear = Math.abs(valToAppend.amountPerYear);
            }
            const rangeOfStartEndYear = valToAppend.endYear - valToAppend.startYear;
            if (data.mortalityAge !== 0 && data.mortalityAge !== null && yearToLive < valToAppend.endYear) {
                lifeEventError++;
                lifeEventErrorMsg = 'Endyear should occur before morality age.';
                return;
            } else if (rangeOfStartEndYear < 0) {
                lifeEventErrorMsg = 'Startyear should be less than than endyear.';
                lifeEventError++;
                return;
            } else {
                finalLifeEventValues.push(valToAppend);
            }
        });
        if (lifeEventError > 0) {
            toast.error(lifeEventErrorMsg);
            return;
        }
        let objToPass = {
            gender: data.gender,
            current_age: data.ages[0],
            retire_age: data.ages[1],
            mort_age: data.mortalityAge,
            current_nw: data.currentNetworthUnFormattedValue || data.currentNetworth,
            inc_before_retire: data.incBeforeRetireUnFormattedValue || data.incBeforeRetire,
            inc_after_retire: data.incAfterRetireUnFormattedValue || data.incAfterRetire,
            exp_before_retire: data.expBeforeRetireUnFormattedValue || data.expBeforeRetire,
            exp_after_retire: data.expAfterRetireUnFormattedValue || data.expAfterRetire,
            life_event: finalLifeEventValues,
            inv_rt: data.investReturn,
            infl_rt: data.inflation,
            tax_rate: data.taxRate,
            estate_goal: typeof data.estateGoal === 'string' ? parseInt(data.estateGoal.replace(/,/g, '')) : 0,
            income_solver: data.income_solver === 'true' ? true : false,
            expense_solver: data.expense_solver === 'true' ? true : false,
            invest_solver: data.invest_solver === 'true' ? true : false,
            required_net_worth: typeof data.requiredNetworth === 'string' ? parseInt(data.requiredNetworth.replace(/,/g, '')) : 0
        };
        if (data.save_data) {
            if (!apiToken) {
                toast.error('Please login to save data!');
                return;
            }
            objToPass = { ...objToPass, save_data: data.save_data };
        }
        dispatch(submitRetirementCal(objToPass, apiToken));
    };

    /**
     * @author disha
     * @use to set the ouput of api into state
     */
    useEffect(() => {
        if (Object.keys(props.retirementCalData.retirementCalDetail).length) {
            setDisabledLoadBtn(false);
            setResult(props.retirementCalData.retirementCalDetail.data);
        } else {
            // to reset the output fields
            if (result?.length > 0) setResult([]);
        }
    }, [props.retirementCalData]);

    // to reset redux stored value of result when page is changing
    useEffect(() => {
        if (apiToken) dispatch(fetchSavedData());
        dispatch(getDefaultOverride());
        return () => {
            dispatch(SubmitRetirementCalSuccess({}));
            dispatch(fetchSavedDataSuccess({}));
        };
    }, []);
    useEffect(() => {
        if (Object.keys(props.defaultoverrideData).length) {
            setValue('investReturn', props.defaultoverrideData.investReturn);
            setValue('inflation', props.defaultoverrideData.inflation);
        }
    }, [props.defaultoverrideData]);

    const addNewLifeEvent = (val) => {
        const newCounter = counter + 1;
        setValue('endYear_' + newCounter, val.endYear);
        setValue('startYear_' + newCounter, val.startYear);
        setIndexes((prevIndexes) => [...prevIndexes, newCounter]);
        setCounter((prevCounter) => prevCounter + 1);
    };
    const removeLifeEvent = (index) => () => {
        setIndexes((prevIndexes) => [...prevIndexes.filter((item) => item !== index)]);
    };

    const handleFormInputChange = (e, fieldName = null) => {
        // it will remove the comma for set the value
        if (fieldName === 'estateGoal_slider' || fieldName === 'estateGoal') {
            // Remove commas and set the value
            const fieldVal = e?.target?.value.replace(/,/g, '') || e;
            if (fieldName === 'estateGoal_slider') {
                setValue('estateGoal', numberWithCommas(fieldVal));
            } else {
                if (fieldVal > 10000000) {
                    toast.error('Please enter value less than 10M');
                }
                const unformattedval = fieldVal.toString().replace(/,/g, '');
                setValue('estateGoal_slider', parseFloat(unformattedval));
            }
        } else if (fieldName === 'requiredNetworth_slider' || fieldName === 'requiredNetworth') {
            // Remove commas and set the value
            const netWorkthFieldVal = e?.target?.value.replace(/,/g, '') || e;
            if (fieldName === 'requiredNetworth_slider') {
                setValue('requiredNetworth', numberWithCommas(netWorkthFieldVal));
            } else {
                if (netWorkthFieldVal > 10000000) {
                    toast.error('Please enter value less than 10M');
                }
                const unformattedval = netWorkthFieldVal.toString().replace(/,/g, '');
                setValue('requiredNetworth_slider', parseFloat(unformattedval));
            }
        }
        if (disabledLoadBtn) {
            setDisabledLoadBtn(false);
        }
        if (isDirty && result?.length > 0) {
            setDisabledLoadBtn(true);
            // dispatch(SubmitRetirementCalSuccess({}));
        }
    };
    /**
     * to load saved data in form
     */
    const handleLoadData = async (e) => {
        setDisabledLoadBtn(true);
        const savedData = props.retirementCalData.savedRetirementDetail;
        let agearr = [];
        for (const key in savedData) {
            if (Object.hasOwnProperty.call(savedData, key)) {
                const element = savedData[key];
                if (key === 'lifeEvent') {
                    counter = 0;
                    await setIndexes([0]);
                    element.forEach((val, i) => {
                        i > 0 && addNewLifeEvent(val);
                        if (val.eventType === 'expense_increase') {
                            let amountPerYear = -val.amountPerYear;
                            setValue(`amountPerYear_${i}`, amountPerYear);
                        } else {
                            setValue(`amountPerYear_${i}`, val.amountPerYear);
                        }
                        setValue(`endYear_${i}`, val.endYear);
                        setValue(`startYear_${i}`, val.startYear);
                        setValue(`eventName_${i}`, val.eventName);
                        setValue(`eventType_${i}`, val.eventType);
                        setValue(`eventId_${i}`, val.id);
                    });
                } else {
                    if (key === 'currentAge') {
                        agearr[0] = element;
                    } else if (key === 'retirementAge') {
                        agearr[1] = element;
                    } else {
                        setValue(key, element);
                    }
                }
            }
        }
        setValue('ages', agearr);
        onSubmit(getValues());
    };
    return (
        <Card elevation={2}>
            <CardContent>
                {Object.keys(props.retirementCalData.savedRetirementDetail).length ? (
                    <Box color="text.primary">
                        <Typography variant="body2" component="p">
                            We have your saved data. Click button to load it.
                            <IconButton
                                color="primary"
                                aria-label="load data"
                                component="label"
                                disabled={disabledLoadBtn}
                                onClick={handleLoadData}
                            >
                                <CachedIcon color="white" />
                            </IconButton>
                        </Typography>
                        <Divider sx={{ mb: 1 }} />
                    </Box>
                ) : (
                    ''
                )}
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container>
                        <Grid item xs={12}>
                            <SliderParentGrid container>
                                <Grid item xs={12} md={5} lg={5}>
                                    <Typography gutterBottom className="bold-font">
                                        Current Age
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={5} lg={5} textAlign={'end'}>
                                    <Typography gutterBottom className="bold-font">
                                        Retirement Age
                                    </Typography>
                                </Grid>
                                <TwoValSliderGrid item xs={12}>
                                    <RenderField
                                        type="slider"
                                        control={control}
                                        name="ages"
                                        id="ages"
                                        max={100}
                                        min={18}
                                        valueLabelDisplay="on"
                                        handleOnChange={handleFormInputChange}
                                    />
                                </TwoValSliderGrid>
                            </SliderParentGrid>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <RenderField
                                type="number"
                                control={control}
                                name="estateGoal"
                                id="estateGoal"
                                label="Estate Goal"
                                max={10000000}
                                min={0}
                                customPatternName="price"
                                thousandSeparator={true}
                                valueLabelDisplay="on"
                                setValue={setValue}
                                handleOnChange={(e) => handleFormInputChange(e, 'estateGoal')}
                            />
                        </Grid>
                        <StudInputGrid item xs={12} md={6} lg={6} sx={{ padding: '10px', top: '5px' }}>
                            <RenderField
                                type="slider"
                                control={control}
                                name="estateGoal_slider"
                                id="estateGoal_slider"
                                defaultTooltip
                                max={5000000}
                                step={1}
                                min={0}
                                setValue={setValue}
                                valueLabelDisplay="on"
                                valueLabelFormat={(value) => '$' + value}
                                handleOnChange={(e) => handleFormInputChange(e, 'estateGoal_slider')}
                            />
                        </StudInputGrid>
                        <Grid item xs={12} sm={6}>
                            <RenderField
                                type="number"
                                control={control}
                                name="requiredNetworth"
                                id="requiredNetworth"
                                label="Required Networth"
                                max={10000000}
                                min={0}
                                valueLabelDisplay="on"
                                customPatternName="price"
                                thousandSeparator={true}
                                setValue={setValue}
                                handleOnChange={(e) => handleFormInputChange(e, 'requiredNetworth')}
                            />
                        </Grid>
                        <StudInputGrid item xs={12} md={6} lg={6} sx={{ padding: '10px' }}>
                            <RenderField
                                type="slider"
                                control={control}
                                name="requiredNetworth_slider"
                                id="requiredNetworth_slider"
                                max={5000000}
                                step={1}
                                min={0}
                                valueLabelDisplay="on"
                                valueLabelFormat={(value) => '$' + value}
                                setValue={setValue}
                                handleOnChange={(e) => handleFormInputChange(e, 'requiredNetworth_slider')}
                            />
                        </StudInputGrid>
                        <Grid item xs={12} sm={6} sx={{ m: 'auto' }}>
                            <Typography className="bold-font">
                                Expense Solver{' '}
                                <CustomTooltip
                                    tooltipText={tooltip['Expense Solver']}
                                    label={'Expense Solver'}
                                    customTooltipStyle={{ maxWidth: 450 }}
                                />
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} sx={{ m: 'auto' }}>
                            <RenderField
                                handleOnChange={handleFormInputChange}
                                name="expense_solver"
                                row
                                label={'Expense Solver'}
                                hideLabel={true}
                                control={control}
                                id="expense_solver"
                                list={[
                                    { value: true, label: 'Pre' },
                                    { value: false, label: 'Post' }
                                ]}
                                type="radio"
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} sx={{ m: 'auto' }}>
                            <Typography className="bold-font">
                                Investment Solver{' '}
                                <CustomTooltip
                                    tooltipText={tooltip['Investment Solver']}
                                    label={'Investment Solver'}
                                    customTooltipStyle={{ maxWidth: 450 }}
                                />
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} sx={{ m: 'auto' }}>
                            <RenderField
                                handleOnChange={handleFormInputChange}
                                name="invest_solver"
                                row
                                label={'Investment Solver'}
                                hideLabel={true}
                                control={control}
                                id="invest_solver"
                                list={[
                                    { value: true, label: 'Pre' },
                                    { value: false, label: 'Post' }
                                ]}
                                type="radio"
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} sx={{ m: 'auto' }}>
                            <Typography className="bold-font">
                                Income Solver{' '}
                                <CustomTooltip
                                    tooltipText={tooltip['Income Solver']}
                                    label={'Income Solver'}
                                    customTooltipStyle={{ maxWidth: 450 }}
                                />
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} sx={{ m: 'auto' }}>
                            <RenderField
                                handleOnChange={handleFormInputChange}
                                name="income_solver"
                                row
                                label={'Income Solver'}
                                hideLabel={true}
                                control={control}
                                id="income_solver"
                                list={[
                                    { value: true, label: 'Pre' },
                                    { value: false, label: 'Post' }
                                ]}
                                type="radio"
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} sx={{ m: 'auto' }}>
                            <Typography className="bold-font"> Gender</Typography>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            sx={{
                                m: 'auto',
                                '& label.MuiFormControlLabel-root': {
                                    marginRight: '10px'
                                }
                            }}
                        >
                            <RenderField
                                handleOnChange={handleFormInputChange}
                                name="gender"
                                row
                                label={'Gender'}
                                hideLabel={true}
                                control={control}
                                id="gender"
                                list={[
                                    { value: 'F', label: 'Female' },
                                    { value: 'M', label: 'Male' }
                                ]}
                                type="radio"
                                required
                            />
                        </Grid>
                        <Grid item xs={12} lg={12} md={6}>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <RenderField
                                    handleOnChange={handleFormInputChange}
                                    name="currentNetworth"
                                    control={control}
                                    label="Current Networth"
                                    id="currentNetworth"
                                    type="number"
                                    required
                                    prefix={'$'}
                                    thousandSeparator={true}
                                    customPatternName="price"
                                    placeholder="100,000"
                                    setValue={setValue}
                                />
                                <CustomButton
                                    style={{ width: 'auto', margin: 'auto' }}
                                    type="button"
                                    onClick={handleFetchCurrentNetworth}
                                    variant="outlined"
                                >
                                    Get Market Value
                                </CustomButton>
                            </div>
                        </Grid>
                        <Grid item xs={12} sx={{ mb: 1 }}>
                            <Typography className="bold-font"> Income</Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <RenderField
                                handleOnChange={handleFormInputChange}
                                name="incBeforeRetire"
                                control={control}
                                label="Before Retirement"
                                id="incBeforeRetire"
                                type="number"
                                required
                                prefix={'$'}
                                thousandSeparator={true}
                                customPatternName="price"
                                placeholder="100,000"
                                setValue={setValue}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <RenderField
                                handleOnChange={handleFormInputChange}
                                name="incAfterRetire"
                                control={control}
                                label="After Retirement"
                                id="incAfterRetire"
                                type="number"
                                prefix={'$'}
                                required
                                thousandSeparator={true}
                                customPatternName="price"
                                placeholder="100,000"
                                setValue={setValue}
                            />
                        </Grid>
                        <Grid item xs={12} sx={{ mb: 1 }}>
                            <Typography className="bold-font"> Expenses</Typography>
                        </Grid>

                        <Grid item xs={12} md={6} className="bottom-space-input">
                            <RenderField
                                handleOnChange={handleFormInputChange}
                                name="expBeforeRetire"
                                control={control}
                                label="Before Retirement"
                                id="expBeforeRetire"
                                type="number"
                                prefix={'$'}
                                required
                                thousandSeparator={true}
                                customPatternName="price"
                                placeholder="100,000"
                                setValue={setValue}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} className="bottom-space-input">
                            <RenderField
                                handleOnChange={handleFormInputChange}
                                name="expAfterRetire"
                                control={control}
                                label="After Retirement"
                                id="expAfterRetire"
                                type="number"
                                prefix={'$'}
                                required
                                thousandSeparator={true}
                                customPatternName="price"
                                placeholder="100,000"
                                setValue={setValue}
                                // CustomLabelProps={{ shrink: false }}
                            />
                        </Grid>

                        <Grid item xs={12} md={12} lg={12} className="bottom-space-input">
                            <OptionalAssumption setValue={setValue} control={control} handleOnChange={handleFormInputChange} />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <CalAccordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                                    <Typography>
                                        Life Events
                                        <span>
                                            <CustomTooltip tooltipText={tooltip.default.Life_Event} label={'Life Event'} />
                                        </span>
                                    </Typography>
                                </AccordionSummary>

                                <AccordionDetails>
                                    <Grid container spacing={2}>
                                        {lifeEventIndexes.map((index) => {
                                            return (
                                                <React.Fragment key={`eventLifeDynamic_${index}`}>
                                                    {lifeEventIndexes?.length > 1 ? (
                                                        <RemoveIconButtonGridItem item xs={12}>
                                                            <IconButton onClick={removeLifeEvent(index)}>
                                                                <RemoveCircleIcon />
                                                            </IconButton>
                                                        </RemoveIconButtonGridItem>
                                                    ) : (
                                                        ''
                                                    )}
                                                    <AccordianGridItem item xs={12} sm={5} md={5} lg={5} disableSpacing>
                                                        <Typography gutterBottom>Event Name</Typography>
                                                    </AccordianGridItem>
                                                    <AccordianGridItem item xs={12} sm={6} ms={6}>
                                                        <RenderField
                                                            handleOnChange={handleFormInputChange}
                                                            name={`eventName_${index}`}
                                                            control={control}
                                                            fieldName="Event Name"
                                                            id={`eventName_${index}`}
                                                            type="text"
                                                            defaultValue=""
                                                            customPatternName="validString"
                                                            placeholder="College"
                                                        />
                                                    </AccordianGridItem>
                                                    <AccordianGridItem item xs={12} sm={5} md={5} lg={5} disableSpacing>
                                                        <Typography gutterBottom>
                                                            Amount Per Year{' '}
                                                            <CustomTooltip
                                                                tooltipText={tooltip['Amount Per Year']}
                                                                label={'Amount Per Year'}
                                                                customTooltipStyle={{ maxWidth: 450 }}
                                                            />
                                                        </Typography>
                                                    </AccordianGridItem>
                                                    <AccordianGridItem item xs={12} sm={6} ms={6}>
                                                        <RenderField
                                                            handleOnChange={handleFormInputChange}
                                                            name={`amountPerYear_${index}`}
                                                            control={control}
                                                            fieldName="Amount Per Year"
                                                            id={`amountPerYear_${index}`}
                                                            required
                                                            placeholder="100,000"
                                                            defaultValue={1}
                                                            type="text"
                                                            prefix={'$'}
                                                            thousandSeparator={true}
                                                            customPatternName={/^-?(?:0|\d+(\.\d+)?)$/g}
                                                            setValue={setValue}
                                                        />
                                                    </AccordianGridItem>
                                                    <AccordianGridItem item xs={12} sm={4} disableSpacing>
                                                        <Typography gutterBottom>Beginning Year</Typography>
                                                    </AccordianGridItem>
                                                    <AccordianGridItem item xs={12} sm={8}>
                                                        <RenderField
                                                            handleOnChange={handleFormInputChange}
                                                            type="slider"
                                                            control={control}
                                                            name={`startYear_${index}`}
                                                            id={`startYear_${index}`}
                                                            // defaultValue={0}
                                                            // min={new Date().getFullYear()}
                                                            // max={new Date().getFullYear() + 100}
                                                            max={20}
                                                        />
                                                    </AccordianGridItem>
                                                    <AccordianGridItem item xs={12} sm={4} disableSpacing>
                                                        <Typography gutterBottom>Ending Year</Typography>
                                                    </AccordianGridItem>
                                                    <AccordianGridItem item xs={12} sm={8}>
                                                        <RenderField
                                                            handleOnChange={handleFormInputChange}
                                                            // defaultValue={2}
                                                            type="slider"
                                                            control={control}
                                                            name={`endYear_${index}`}
                                                            id={`endYear_${index}`}
                                                            // min={new Date().getFullYear()+1}
                                                            // max={new Date().getFullYear() + 101}
                                                            max={30}
                                                        />
                                                    </AccordianGridItem>
                                                    <Divider className="divider" />
                                                    <AddIconButtonGridItem type="submit" item xs={12} sm={12} md={12}>
                                                        {lifeEventIndexes?.length - 1 === lifeEventIndexes.indexOf(index) &&
                                                        lifeEventIndexes?.length < 4 ? (
                                                            <IconButton onClick={addNewLifeEvent}>
                                                                <AddCircleIcon />
                                                            </IconButton>
                                                        ) : (
                                                            ''
                                                        )}
                                                    </AddIconButtonGridItem>
                                                </React.Fragment>
                                            );
                                        })}
                                    </Grid>
                                </AccordionDetails>
                            </CalAccordion>
                        </Grid>
                    </Grid>
                    <CalCardActions className="contactus-cardaction">
                        <Grid container>
                            <Grid item xs={12} md={6}>
                                <CustomButton
                                    type="submit"
                                    ps={'9px 30px'}
                                    onClick={() => {
                                        setValue('save_data', false);
                                        onSubmit(getValues());
                                    }}
                                    variant="contained"
                                    // color="rose"
                                    disabled={isSubmitting}
                                >
                                    Calculate
                                </CustomButton>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <CustomButton
                                    type="button"
                                    ps={'9px 30px'}
                                    onClick={() => {
                                        setValue('save_data', true);
                                        onSubmit(getValues());
                                    }}
                                    variant="outlined"
                                    // color="success"
                                    disabled={isSubmitting}
                                >
                                    Save
                                </CustomButton>
                            </Grid>
                        </Grid>
                    </CalCardActions>
                </form>
            </CardContent>
        </Card>
    );
}

export default RetirementCalForm;
