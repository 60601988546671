import React from 'react';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import { BannerBox, TypographySubHeading } from 'assets/jss/BannerStyle';
import { ContactusBox } from 'assets/jss/ContactusStyle';
import { DownIconButton } from 'assets/jss/LandingStyle';

const ContactUsBanner = (props) => {
    return (
        <ContactusBox position={'relative'}>
            <BannerBox img={'img/contactus-banner.webp'} customheight={'93vh'}>
                <Box className="contact-banner-text-box">
                    <TypographySubHeading variant="subheading2">Reach out to us</TypographySubHeading>
                    <Typography variant="h1">We’d love to talk to you</Typography>
                    <TypographySubHeading variant="subheading2">Let us know how can we help you</TypographySubHeading>

                    <DownIconButton onClick={props.handleScrollBtn} className="scroll-down-link scroll-down-arrow" aria-label="upload picture" component="label">
                        <KeyboardDoubleArrowDownIcon />
                    </DownIconButton>
                </Box>
            </BannerBox>
        </ContactusBox>

        //   <BannerBox sx={{ flexGrow: 1 }}>
        //       <img src="img/banner.svg" style={{ width: '100%', height: 'auto' }} />
        //       <CustomGrid container spacing={2} minHeight={160}>
        //           <Grid display="flex" justifyContent="center" alignItems="center" className="banner-text">
        //               <Typography variant="h1" component="h1" sx={{ fontFamily: 'Times New Roman', mb: 3.3 }}>
        //                   Welcome to The People's Economist
        //               </Typography>
        //               <TypographySubHeading variant="subheading2">
        //                   TPE is the world's first fully integrated lifecycle suite of personal finance tools and dashboards
        //               </TypographySubHeading>
        //               <TypographySubHeading variant="subheading2" sx={{mb: 3.3}}>“Your Financial Future Starts With Us”</TypographySubHeading>
        //               <Button variant="contained" className="signup-btn">Sign Up - It’s Free</Button>
        //           </Grid>
        //       </CustomGrid>
        //   </BannerBox>
        // <Parallax
        //   filter
        //   image={require("assets/img/landing-image.jpg")}
        //   darkenShadow={true}
        //   className={classes.landingImgParal}>
        //   <div
        //     className={` ${classes.container} ${classes.landingContainer} ${classes.bannerWrapper}`}>
        //     <GridContainer spacing={2}>
        //       <CustomGridItem xs={12} sm container>
        //         <CustomGridItem xs container direction='column' spacing={2}>
        //           <CustomGridItem xs className={classes.subHeaderBanner}>
        //             <Typography gutterBottom variant='h4' className='topheader'>
        //               Welcome to The People's Economist
        //             </Typography>
        //             <Typography variant='body1' gutterBottom className='subheader'>
        //               TPE is the world's first fully integrated lifecycle suite of
        //               personal finance tools and dashboards
        //             </Typography>
        //             <Typography variant='subtitle2'>
        //               Your Financial Future Starts With Us
        //             </Typography>
        //             {!getCookie("auth_token") && <CustomGridItem className={classes.bannerSigninButtons}>
        //               <Button
        //                 to='/signup'
        //                 color='primary'
        //                 size='sm'
        //                 component={Link}>
        //                 Sign up - it's free
        //               </Button>
        //             </CustomGridItem>}
        //           </CustomGridItem>
        //         </CustomGridItem>
        //       </CustomGridItem>
        //       <CustomGridItem className={classes.bannerSigninButtons}>
        //         <iframe
        //           width='300'
        //           height='200'
        //           src='https://www.youtube.com/embed/nqwgGxjoSPk'
        //           title='YouTube video player'
        //           frameborder='0'
        //           allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
        //           allowfullscreen></iframe>
        //       </CustomGridItem>
        //     </GridContainer>
        //   </div>
        // </Parallax>
    );
};

export default ContactUsBanner;
