import React, { useEffect, useMemo, useRef, useState } from 'react';
import { getCookie } from 'utils/Utils';
// nodejs library that concatenates classes
import CalculatorBlock from './CalculatorBlock';
import DashboardBlock from './DashboardBlock';
import GetStarted from './GetStarted';
import LandingBanner from './LandingBanner';
import LatestUpdateBlock from './LatestUpdateBlock';
import PricingBlock from './PricingBlock';
import RatingBlock from './RatingBlock';
import TodoBlock from './TodoBlock';
import { MainBox } from 'assets/jss/GeneralStyle.jsx';
import { Navigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

export default function LandingPage() {
    const apiToken = getCookie('auth_token');
    const navigate = useNavigate();

    const location = useLocation();
    //   useEffect(() => {
    //     console.log(location, 'location');
    //       toast.success(location.state?.success);
    //       navigate(location.pathname, { replace: true, state: null });
    //   }, [location.state]);

    return (
        <>
            <MainBox>
                <LandingBanner />
                <CalculatorBlock />
                <DashboardBlock />
                <LatestUpdateBlock />
                <TodoBlock />
                {/* <PricingBlock /> */}
                <RatingBlock />
                {!apiToken && <GetStarted />}
            </MainBox>
        </>
    );
}
