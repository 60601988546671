import React from 'react';
import Slider from '@mui/material/Slider';
import * as tooltip from 'Tooltip.json';
import { Box, InputAdornment } from '@mui/material';
import CustomTooltip from './CustomTooltip';

const CustomSlider = (props) => {
    const handleChange = (param) => {
        if (props && props.onChange) {
            props.onChange(param);
        }
    };
    const tooltipKey = props.label || null;
    return (
        <Box display="flex">
            <Slider
                {...props}
                getAriaLabel={() => 'Minimum distance'}
                getAriaValueText={props.valuetext}
                disableSwap
                color="primary"
                aria-label="tpe slider"
                // value={[10,50]}
                value={props.defaultslidervalue}
                onChange={(e, val) => handleChange(val)}
                min={props.min}
                max={props.max}
                valueLabelDisplay={props.valueLabelDisplay || 'auto'}
            />
            {tooltipKey
                ? tooltip.default?.[tooltipKey] && (
                      <InputAdornment position="end">
                          <CustomTooltip tooltipText={tooltip.default?.[tooltipKey]} label={tooltipKey} />
                      </InputAdornment>
                  )
                : ''}
        </Box>
    );
};
export default CustomSlider;
