import { Box, Button, Typography } from '@mui/material';
import { styled } from '@mui/styles';

export const BannerBox = styled(Box)(({ img, customheight, theme }) => ({
    backgroundImage: (img && `url(${img})`) || `url(/img/banner.svg)`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    minHeight: customheight || '71vh',
    // height: 698,
    width: '100%',
    display: 'flex',
    // marginBottom: 100,
    marginBottom: '-143px',

    justifyContent: 'center',
    '&#aboutus-banner': {
        marginBottom: 0,
        justifyContent: 'unset'
    },
    '& .banner-text-box': {
        width: '100%',
        p: 20,
        margin: 'auto',
        textAlign: 'center'
    },
    '& .contact-banner-text-box': {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    },
    '& .about-banner-text-box': {
        margin: 'auto 50px auto',
        width: '30%',
        [theme.breakpoints.down('sm')]: {
            margin: 'auto 30px'
        }
    },
    '& .about-banner-heading': {
        marginTop: '-100px',
        width: '420px',
        [theme.breakpoints.down('sm')]: {
            width: '120px'
        }
    },
    '& .about-banner-video-box': {
        width: 'revert-layer',
        height: '259px',
        [theme.breakpoints.down('sm')]: {
            height: '179px',
            width: '320px'
        }
    }
}));
export const ComputerBox = styled('img')(({ theme }) => ({
    height: 'auto',
    // width: '50%',
    maxWidth: '25%',
    display: 'flex',
    justifyContent: 'center',
    margin: 'auto',
    marginTop: '10px',
    [theme.breakpoints.down('sm')]: {
        // width: '100%',
        maxWidth: '100%'
    }
}));

export const TypographySubHeading = styled(Typography)(({ theme }) => ({
    width: '73%',
    margin: 'auto',
    display: 'flex',
    textAlign: 'center',
    lineHeight: '26px',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
        width: '100%'
    }
}));
export const LeftAlignTypographySubHeading = styled(Typography)(({ theme }) => ({
    width: '73%',
    display: 'flex',
    lineHeight: '26px',
    [theme.breakpoints.down('md')]: {
        width: '100%',
        color: 'white'
    }
}));
export const BannerSigninButton = styled(Button)(({ theme }) => ({
    borderRadius: '50px',
    height: '40px',
    width: 'max-content',
    padding: 8,
    [theme.breakpoints.down('sm')]: {
        fontSize: '0.7rem'
    }
}));
