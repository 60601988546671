import { Box, Card, CardContent, Divider, Grid, Typography } from '@mui/material';
import { CustomButton } from 'assets/jss/ButtonStyle';
import { NewTaskRibbon } from 'assets/jss/GeneralStyle';
import { CalCardActions } from 'assets/jss/RetirementStyle';
import { StyledProfileGrid } from 'assets/jss/SettingsStyle';
import { StyledFileInputBox } from 'assets/jss/SettingsStyle';
import { CurrencyList } from 'Currency.json';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { fetchProfile, submitProfile } from 'redux/actions/SettingActions';
import RenderField from 'utils/RenderField';
import { dynamicSort } from 'utils/Utils';
import { ResetPasswordIndex } from './ResetPasswordIndex';
import { getBillingDetails } from 'redux/actions/SettingActions';
var currList = [...CurrencyList];
currList.sort(dynamicSort('code'));

function handleOptions() {
    const list = currList.map((val) => {
        return {
            value: val.name,
            label: val.name
        };
    });
    return list;
}
const fieldsDetail = [
    {
        name: 'fname',
        label: 'Full Name',
        required: true,
        type: 'text',
        placeholder: 'Full Name',
        maxLength: '20',
        customPatternName: 'validString'
    },
    { name: 'birthdate', label: 'Birthdate', disabled: true, type: 'date', placeholder: 'Birthdate', CustomLabelProps: { shrink: true } },
    {
        name: 'gender',
        label: 'Gender',
        type: 'select',
        options: [
            { value: 'F', label: 'Female' },
            { value: 'M', label: 'Male' },
            { value: 'O', label: 'Other' }
        ]
    },
    { name: 'email', label: 'Email', type: 'email', disabled: true, placeholder: 'Email', customPatternName: 'email' },
    { name: 'retirementage', label: 'Retirement Age', type: 'number', placeholder: 'Retirement Age', maxLength: '2' },
    { name: 'annicome', label: 'Annual Income', type: 'number', placeholder: 'Annual Income' },
    {
        name: 'country',
        label: 'Country',
        type: 'searchSelect',
        options: handleOptions(),
        disabled: true,
        customAutoInput: { padding: 0 },
        autoSelect: true
    },
    { name: 'phone', label: 'Mobile Num.', type: 'phone', placeholder: 'Mobile Number', maxLength: '15' }
];

export const ProfileForm = ({ classes, setting }) => {
    const dispatch = useDispatch();
    const [selectedFile, setSelectedFile] = React.useState(null);
    const {
        handleSubmit,
        control,
        formState: { isDirty, isSubmitting },
        setValue
    } = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
        defaultValues: {
            fname: '',
            birthdate: '',
            gender: '',
            email: '',
            retirementage: '',
            annicome: '',
            country: '',
            phone: ''
        }
    });
    useEffect(() => {
        if (Object.keys(setting.profileDetail).length > 0) {
            const data = setting.profileDetail;
            const list = handleOptions();
            const country = list.filter((val) => val.value === data.country)[0];
            setValue('fname', data.fname);
            setValue('birthdate', data.birthdate);
            setValue('gender', data.gender);
            setValue('email', data.email);
            setValue('retirementage', data.retirementAge);
            setValue('annicome', data.annualincome);
            setValue('country', country);
            setValue('phone', data.phone.toString());
        }
    }, [setting.profileDetail]);
    const handleCapture = ({ target }) => {
        // const fileReader = new FileReader();
        // fileReader.readAsDataURL(target.files[0]);
        // fileReader.onload = (e) => {
        //   const resized = compressFile(target.files[0], "",800);
        //   resized.then(blob => {
        //   })
        //   setSelectedFile(e.target.result);
        // };
    };
    const onSubmit = (data) => {
        const objToPass = {
            gender: data.gender,
            // birthdate:data.birthdate
            retire_age: data.retirementage,
            annualincome: data.annicomeUnFormattedValue || data.annicome || 0,
            country: data.country ? data.country.value : '',
            fname: data.fname,
            phone: data.phone
        };
        dispatch(submitProfile(objToPass));
    };
    useEffect(() => {
        dispatch(fetchProfile());
        dispatch(getBillingDetails());
    }, []);

    return (
        <Card elevation={2}>
            <CardContent>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={3}>
                            <StyledFileInputBox>
                                <NewTaskRibbon>
                                    <div className="wrap addRighPosition profile-photo">
                                        <span className="ribbonLabel">Coming Soon</span>
                                    </div>
                                </NewTaskRibbon>
                                <input
                                    disabled
                                    className={classes.input}
                                    id="contained-button-file"
                                    hidden
                                    accept="image/*"
                                    multiple
                                    type="file"
                                    onChange={handleCapture}
                                />

                                <div className="thumbnail img-circle">
                                    <img src={selectedFile || 'img/user.png'} alt="..." />
                                </div>
                            </StyledFileInputBox>
                        </Grid>
                        <StyledProfileGrid item xs={9}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography gutterBottom variant="h3">
                                        Hi, {(Object.keys(setting.profileDetail).length > 0 && setting.profileDetail.fname) || ''}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <label htmlFor="contained-button-file">
                                        <CustomButton type="button" disabled variant="contained" className="read-more">
                                            Upload New Profile
                                        </CustomButton>
                                    </label>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <CustomButton disabled type="submit" ps={'9px 30px'} variant="contained" className="read-more">
                                        Delete Photo
                                    </CustomButton>
                                </Grid>
                            </Grid>
                        </StyledProfileGrid>
                        <Grid item xs={12}>
                            <Typography variant={'subheading2'}>User Details</Typography>
                            <Divider sx={{ marginTop: '8px' }} />
                            <Grid container spacing={2}>
                                {fieldsDetail.map((val, i) => {
                                    return (
                                        <Grid item xs={12} sm={6} key={i + 'budgetFormFields'}>
                                            <RenderField
                                                name={val.name}
                                                control={control}
                                                label={val.label}
                                                id={val.name + '_profile'}
                                                type={val.type}
                                                required={val.required || false}
                                                placeholder={val.placeholder}
                                                setValue={setValue}
                                                disabled={val.disabled}
                                                profilePhone={true}
                                                renderOption={(option, selected) => (
                                                    <p className={classes.currencyCountry}>{option.label}</p>
                                                )}
                                                {...val}
                                            />
                                        </Grid>
                                    );
                                })}
                            </Grid>
                            <CalCardActions className="contactus-cardaction">
                                <CustomButton
                                    type="submit"
                                    ps={'9px 30px'}
                                    variant="contained"
                                    // color="rose"
                                    disabled={isSubmitting}
                                >
                                    Update
                                </CustomButton>
                            </CalCardActions>
                        </Grid>
                    </Grid>
                </form>
                <ResetPasswordIndex setting={setting} />
            </CardContent>
        </Card>
    );
};
