import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { makeStyles, withStyles } from '@mui/styles';
import styles from 'assets/jss/material-kit-react/views/landingPage.js';
import Button from 'components/CustomButtons/Button.js';
import Parallax from 'components/Parallax/Parallax.js';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import Meta from 'components/meta';

const useStyles = makeStyles(styles);
const WhiteTextTypography = withStyles({
    root: {
        color: '#FFFFFF',
        fontWeight: 'bold'
    }
})(Typography);
const PageNotFound = () => {
    const classes = useStyles();
    return (
        <>
            <Meta title="404 - Page Not Found | TPE Hub" description="We couldn’t find the page that you are looking for." page="/err" />
            <Parallax filter image={require('assets/img/landing-image.jpg')} darkenShadow={true}>
                <div className={classes.container} justify="center">
                    <WhiteTextTypography variant="h1" align="center" fontWeight="fontWeightMedium">
                        404
                    </WhiteTextTypography>
                    <p align="center">We couldn’t find the page that you are looking for</p>
                    <Grid container justify="center">
                        <Button component={Link} color="primary" size="lg" to="/" variant="contained" rel="noopener noreferrer">
                            <HomeIcon />
                            Go To Home
                        </Button>
                    </Grid>
                </div>
            </Parallax>
        </>
    );
};
export default PageNotFound;
