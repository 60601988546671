import { Box, createStyles, Divider, Grid, Tab, Tabs, TextField, Typography, useTheme } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { makeStyles, withStyles } from '@mui/styles';
import Tooltip from '@mui/material/Tooltip';
import AddIcon from '@mui/icons-material/Add';
import feedbackPage from 'assets/jss/material-kit-react/views/feedbackPage';
import homeCalPageStyle from 'assets/jss/material-kit-react/views/homeCalPage';
import portfolioPageStyle from 'assets/jss/material-kit-react/views/portfolioPage';
import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import SwipeableViews from 'react-swipeable-views';
import { resetEditTableField } from 'redux/actions/DebtCalActions';
import Alert from '@mui/material/Alert';
import {
    fetchHomePurchFiles,
    submitHomePurch,
    submitHomePurchSuccess,
    submitHomePurchSmartTable,
    HomePurchaseUrl
} from 'redux/actions/HomeCalActions';
import { StateList } from 'States.json';
import RenderField from 'utils/RenderField';
import PortfolioTabPanel from 'views/PortfolioValueUpload/PortfolioTabPanel';
import HomePurchaseUpload from './HomePurchaseUpload';
import CustomDialogTitle from 'components/DialogTitle';
import { CustomButton } from 'assets/jss/ButtonStyle';
import { TopBlockBox } from 'assets/jss/PortfolioStyle';
import { CalCardActions } from 'assets/jss/RetirementStyle';

const mergedStyle = makeStyles((theme) =>
    createStyles({
        ...homeCalPageStyle(theme),
        ...portfolioPageStyle(theme),
        ...feedbackPage(theme)
    })
);

const handleStateOptions = () => {
    const list = StateList.map((val) => {
        return {
            value: val.abb,
            label: val.name + ` (${val.abb})`
        };
    });
    return list;
};
const numberOptions = (limit = 10, appendPlus = false) => {
    return [...Array(limit)].map((e, i) => {
        let val = ++i;
        const value = val;
        if (appendPlus && val === limit) {
            val = val + '+';
        }
        return { value: value, label: val };
    });
};

const a11yProps = (index) => {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`
    };
};
const AddNewHomePurchase = (props) => {
    const theme = useTheme();
    const classes = mergedStyle();
    const dispatch = useDispatch();
    const homecal = useSelector((state) => state.homecal);
    const [selectedTab, setSelectedTab] = useState(0);
    const [open, setOpen] = useState(false);
    const handleTabChange = (event, newValue) => setSelectedTab(newValue);
    const {
        handleSubmit,
        control,
        formState: { isDirty, isSubmitting },
        setValue,
        reset
    } = useForm({ mode: 'onChange' });
    const [fieldsDetail, setFieldsDetail] = useState([
        { name: 'favorites', fieldName: 'Favorites', type: 'rating', size: 'large', maxStar: 1, precision: 1 },
        {
            name: 'property_link',
            label: 'Property Link',
            type: 'text',
            custompattern: /^(https?:\/\/)?([\w-]+\.)+[\w-]+(\/[\w-./?%&=:]*)?$/g,
            maxLength: 300,
            multiline: true,
            maxRows: 6
        },
        { name: 'homePurchFileId', label: 'Home Purchase File', type: 'select', disableEmptyValue: true },
        {
            name: 'sold_listed_date',
            label: 'Sold or Listed Date',
            helperText: 'Sold or Listed Date',
            type: 'datetime',
            timeFormat: false,
            placeholder: 'Select Date'
        },
        { name: 'view_date', label: 'View Date', helperText: 'View Date', type: 'datetime', timeFormat: false, placeholder: 'Select Date' },
        {
            name: 'address',
            label: 'Address',
            type: 'text',
            placeholder: 'Enter Address',
            multiline: true,
            maxRows: 4,
            maxLength: 150,
            custompattern: /^([a-z0-9A-Z,-_@~`& \n]{0,150})$/g
        },
        { name: 'state', label: 'State', type: 'searchSelect', options: handleStateOptions() },
        { name: 'zipcode', label: 'Zipcode', type: 'number', custompattern: /^[0-9]{0,6}$/g, maxLength: 6 },
        {
            name: 'price',
            label: 'Price',
            type: 'number',
            required: true,
            commaSep: true,
            custompattern: 'decimalRate',
            placeholder: '100,000'
        },
        {
            name: 'size',
            label: 'Size',
            type: 'number',
            helperText: '(Square Ft.)',
            required: true,
            commaSep: true,
            custompattern: 'decimalRate',
            placeholder: '100,000'
        },
        { name: 'beds', label: 'Beds', type: 'select', options: numberOptions(10) },
        { name: 'baths', label: 'Baths', type: 'select', options: numberOptions(10) },
        { name: 'year', label: 'Year', type: 'number', maxLength: 4, commaSep: false },
        { name: 'floor', label: 'Floor', type: 'select', options: numberOptions(50, true) },
        {
            name: 'comments',
            label: 'Comments',
            type: 'text',
            placeholder: 'Enter Comments',
            multiline: true,
            maxRows: 4,
            maxLength: 150,
            custompattern: /^([a-z0-9A-Z,-_@~`& \n]{0,150})$/g
        },
        {
            name: 'fees_tax_insu_monthly',
            required: true,
            helperText: 'Taxes /Insurance ',
            label: 'Monthly Fees ',
            type: 'number',
            commaSep: true,
            custompattern: 'decimalRate',
            placeholder: '100,000'
        },
        { name: 'condition', label: 'Condition', required: true, type: 'select', options: numberOptions(10) },
        { name: 'view', label: 'View', required: true, type: 'select', options: numberOptions(10) },
        { name: 'location', required: true, label: 'Location', type: 'select', options: numberOptions(10) },
        {
            name: 'viewing_status',
            label: 'View Status',
            type: 'select',
            options: [
                { value: 'viewed', label: 'Viewed' },
                { value: 'scheduled', label: 'Scheduled' },
                { value: 'na', label: 'NA' }
            ]
        },
        {
            name: 'listing_status',
            label: 'Listing status',
            type: 'select',
            options: [
                { value: 'inContract', label: 'In Contract' },
                { value: 'sold', label: 'Sold' },
                { value: 'active', label: 'Active' }
            ]
        },
        {
            name: 'parking',
            label: 'Parking',
            type: 'select',
            defaultValue: 0,
            options: [
                { value: 0, label: 0 },
                { value: 1, label: 1 },
                { value: 2, label: 2 }
            ]
        },
        { name: 'broker', label: 'Broker', type: 'text', customPatternName: /^([a-z0-9A-Z,-_@~`& \n]{0,150})$/g, maxLength: 100 },
        { name: 'property_id', label: 'Property ID', type: 'text', maxLength: 100 },
        {
            name: 'amenities',
            label: 'Amenities',
            type: 'select',
            options: {
                ['Balcony']: 'Balcony',
                ['Pool']: 'Pool',
                ['Washer/Dryer']: 'Washer/Dryer',
                ['Garden/Outdoor space']: 'Garden/Outdoor space'
            },
            chipSelect: true
        },
        {
            name: 'neighboar_hood',
            label: 'Neighbourhood',
            type: 'text',
            customPatternName: /^([a-z0-9A-Z,-_@~`& \n]{0,150})$/g,
            maxLength: 100
        },
        { name: 'agent_name', label: 'Agent Name', type: 'text', customPatternName: /^[a-zA-Z0-9@&()]+$/, maxLength: 100 }
    ]);
    const updateFieldDetails = [...fieldsDetail.slice(0, 1), ...fieldsDetail.slice(2)];
    const { setRowValue, setInputValue } = props;

    const tabLabel = props.open ? 'Edit item' : 'Single Upload';

    //open add new purchase popup
    const handleClickOpen = () => {
        setOpen(true);
        dispatch(resetEditTableField(true));
    };

    const matchfile = (filename) => {
        // Use find instead of map for searching
        const matchingFile = homecal?.homePurchFiles?.find((item) => item.file_name === filename);

        // Check if a matching file was found
        if (matchingFile) {
            return matchingFile.id;
        }
    };

    useEffect(() => {
        if (props?.open) {
            setOpen(true);

            const fieldValues = props.rowValue;

            // Set values for multiple fields in the form
            for (const key in fieldValues) {
                if (Object.prototype.hasOwnProperty.call(fieldValues, key)) {
                    let value = fieldValues[key];

                    // Check if the value is a date string and convert it to a Date object
                    if (key === 'sold_listed_date' || key === 'view_date') {
                        value = value ? new Date(value) : null;
                    }
                    if (key === 'amenities') {
                        value = value !== '' ? value?.split(',') || [] : [];
                    }

                    setValue(key, value);
                }
            }

            setFieldsDetail(updateFieldDetails);
        }
    }, [props.open]);
    //close add new purchase popup
    const handleClose = () => {
        if (props.rowValue != undefined) {
            setInputValue(false);
            setRowValue('');
        }
        reset({});
        setOpen(false);
    };

    //to submit the Property Link data
    const handleOnChange = async (e) => {
        const value = e.target;
        if (value.id === 'property_link') {
            if (value.value.startsWith('https://www.zillow.com/homedetails/')) {
                const response = await dispatch(HomePurchaseUrl({ url: value.value }));
                setValue('address', response?.address);
                setValue('state', response?.state);
                setValue('zipcode', response?.zipcode);
                setValue('price', response?.price);
                setValue('size', response?.size);
                setValue('beds', response?.beds);
                setValue('baths', response?.baths);
                setValue('year', response?.year);
                setValue('fees_tax_insu_monthly', response?.fees_tax_insu_monthly);
                setValue('listing_status', response?.listing_status);
                setValue('parking', response?.parking);
                setValue('broker', response?.broker);
                setValue('property_id', response?.property_id);
                setValue('amenities', [response?.amenities]);
                setValue('neighboar_hood', response?.neighbour_hood);
            }
        }
    };

    //to submit the form data
    const onSubmit = (data) => {
        let objToPass = {
            file_id:
                data.homePurchFileId === undefined && props.open == true
                    ? matchfile(data.homePurchFileRls.file_name)
                    : data.homePurchFileId,
            id: data.id,
            favorites: data.favorites || false,
            sold_listed_date: (data.sold_listed_date && moment(data.sold_listed_date, 'MM/DD/YYYY').format('YYYY/MM/DD')) || null,
            view_date: (data.view_date && moment(data.view_date, 'MM/DD/YYYY').format('YYYY/MM/DD')) || null,
            address: data.address,
            state: data.state?.value,
            zipcode: data.zipcode,
            price:
                data.price && typeof data.price == 'number' ? data.price : data.priceUnFormattedValue || data.price.replace(/\,/g, '') || 0,
            size:
                data.size && typeof data.size == 'number'
                    ? data.size
                    : Math.round(data.sizeUnFormattedValue) || Math.round(data.size.replace(/\,/g, '')) || 0,
            listing_status: data.listing_status,
            beds: data.beds || undefined,
            baths: data.baths || undefined,
            year: data.year,
            floor: data.floor || undefined,
            comments: data.comments || undefined,
            fees_tax_insu_monthly:
                data.fees_tax_insu_monthly !== undefined &&
                data.fees_tax_insu_monthly !== null &&
                (typeof data.fees_tax_insu_monthly === 'number'
                    ? data.fees_tax_insu_monthly
                    : data.fees_tax_insu_monthlyUnFormattedValue || data.fees_tax_insu_monthly.replace(/\,/g, '') || 0),
            condition: data.condition,
            view: data.view,
            location: data.location,
            viewing_status: data.viewing_status || undefined,
            buyer_score: data.buyer_score,
            parking: data.parking ? data.parking : 0,
            amenities: data?.amenities?.length ? data?.amenities.join(',') : '',
            agent_name: data.agent_name,
            neighbour_hood: data.neighboar_hood || '',
            property_link: data.property_link || '',
            broker: data.broker,
            property_id: data.property_id
        };
        let edit_data = objToPass;
        objToPass = [{ data: [objToPass] }];

        if (data && props.open && props.rowValue) {
            dispatch(submitHomePurchSmartTable(edit_data, 'put'));
            setInputValue(false);
            setRowValue('');
        } else {
            dispatch(submitHomePurch(objToPass, 'post'));
        }
    };
    // create format to pass file options
    const fileOptions = () => {
        return homecal.homePurchFiles.map((val) => ({
            value: val.id,
            label: val.file_name
        }));
    };
    useEffect(() => {
        // reset all state flag whenever form is submitted
        if (homecal?.submitSuccess) {
            handleClose();
            dispatch(fetchHomePurchFiles());
            dispatch(submitHomePurchSuccess(false));
        }
    }, [homecal?.submitSuccess]);
    return (
        <Box>
            {!props.open && (
                <Tooltip title="Add" arrow>
                    <CustomButton
                        style={{ width: '100%' }}
                        variant="text"
                        startIcon={<AddIcon />}
                        aria-label="add"
                        onClick={handleClickOpen}
                    >
                        Add New Data
                    </CustomButton>
                </Tooltip>
            )}
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <CustomDialogTitle id="form-dialog-title" onClose={handleClose}>
                    {props.open ? 'Edit Home Purchase' : 'Add New Home Purchase'}
                </CustomDialogTitle>
                <Divider />
                <TopBlockBox>
                    <Tabs
                        className="topblock-tabs"
                        value={selectedTab}
                        onChange={handleTabChange}
                        indicatorColor="primary"
                        textColor="primary"
                        // variant="scrollable"
                        allowScrollButtonsMobile={true}
                        aria-label="full width tabs example"
                    >
                        <Tab label={tabLabel} {...a11yProps(0)} className={classes.appbarTab} />
                        {!props.open && <Tab label="Batch Upload" {...a11yProps(1)} className={classes.appbarTab} />}
                    </Tabs>
                    <SwipeableViews
                        className={classes.tabBody}
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={selectedTab}
                        onChangeIndex={(index) => setSelectedTab(index)}
                    >
                        <PortfolioTabPanel value={selectedTab} index={0} dir={theme.direction}>
                            {/* <CustomPaper elevation={3}> */}
                            <form onSubmit={handleSubmit(onSubmit)} style={{ padding: '6px' }}>
                                <Grid container spacing={2}>
                                    {fieldsDetail.map((val, i) => {
                                        const optionsList = val.name == 'homePurchFileId' ? fileOptions() : val.options;
                                        return val.name === 'favorites' ? (
                                            <Fragment key={val.name + '_' + i}>
                                                <Alert sx={{ marginLeft: '15px' }} severity="info" color="info">
                                                    If you enter your property link from Zillow we will automatically populate the data
                                                    available in the link
                                                </Alert>

                                                <Grid item xs={6} sm={3} className={classes.customMargin}>
                                                    <Typography variant="body2" className={`outputLabel`} component="p">
                                                        {val.fieldName}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6} sm={3} className={`${classes.ratingdiv} ${classes.customMargin}`}>
                                                    <RenderField
                                                        name={val.name}
                                                        control={control}
                                                        id={val.name}
                                                        fieldName={val.fieldName}
                                                        type={val.type}
                                                        placeholder={val.placeholder}
                                                        setValue={setValue}
                                                        {...val}
                                                    />
                                                </Grid>
                                            </Fragment>
                                        ) : (
                                            <Grid
                                                item
                                                xs={12}
                                                sm={6}
                                                key={i + 'addNewHomePuchField'}
                                                className={val.name === 'sold_listed_date' ? classes.autoMargin : ''}
                                            >
                                                <RenderField
                                                    name={val.name}
                                                    disableEmptyValue={true}
                                                    control={control}
                                                    label={val.label}
                                                    handleOnChange={handleOnChange}
                                                    id={val.name}
                                                    type={val.type}
                                                    options={optionsList}
                                                    required={val.required || false}
                                                    thousandSeparator={val.commaSep}
                                                    customPatternName={val.custompattern}
                                                    placeholder={val.placeholder}
                                                    setValue={setValue}
                                                    renderOption={(option, selected) => (
                                                        <>
                                                            <p className={classes.currencyCountry}>{option.label}</p>
                                                        </>
                                                    )}
                                                    addNew="addNew"
                                                    {...val}
                                                />
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                                <CalCardActions className="contactus-cardaction">
                                    <CustomButton type="submit" variant="contained" disabled={isSubmitting}>
                                        Submit
                                    </CustomButton>
                                </CalCardActions>
                            </form>
                            {/* </CustomPaper> */}
                        </PortfolioTabPanel>
                        <PortfolioTabPanel value={selectedTab} index={1} dir={theme.direction}>
                            <HomePurchaseUpload dispatch={dispatch} a11yProps={a11yProps}></HomePurchaseUpload>
                        </PortfolioTabPanel>
                    </SwipeableViews>
                </TopBlockBox>
            </Dialog>
        </Box>
    );
};
export default AddNewHomePurchase;
