import React from 'react';
import { Box, Card, CardContent, Grid, InputAdornment, Typography } from '@mui/material';
import { Fragment, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { submitTaxEstiCal, submitTaxEstiCalSuccess } from 'redux/actions/BudgetActions';
import { StateList } from 'States.json';
import RenderField from 'utils/RenderField';
import { CustomButton } from 'assets/jss/ButtonStyle';
import { CalCardActions } from 'assets/jss/RetirementStyle';
import CustomTooltip from 'components/CommonComponent/CustomTooltip';
import * as tooltip from 'Tooltip.json';

const handleStateOptions = () => {
    const list = StateList.map((val) => {
        return {
            value: val.abb,
            label: val.name + ` (${val.abb})`
        };
    });
    return list;
};
const minYear = new Date().getFullYear() - 3,
    maxYear = new Date().getFullYear();
const diffYear = maxYear - minYear;
const taxYearOptions = [...Array(diffYear + 1)].map((_, index) => {
    const currVal = minYear + index;
    return { value: currVal, label: currVal };
});
const fieldsDetail = [
    {
        name: 'Filing_Status',
        label: 'Filing Status',
        type: 'select',
        required: true,
        options: [
            { value: 'Married', label: 'Married/Filed Together' },
            { value: 'Single', label: 'Single/Married Filing Separate' },
            { value: 'Hoh', label: 'Head of Household' }
        ]
    },
    {
        name: 'Salary_and_wages',
        label: 'Salary & Wages',
        type: 'number',
        required: true,
        commaSep: true,
        custompattern: 'decimalRate',
        placeholder: '100,000'
    },
    {
        name: 'Int_and_Div',
        label: 'Int & Div',
        type: 'number',
        required: true,
        commaSep: true,
        custompattern: 'decimalRate',
        placeholder: '100,000'
    },
    {
        name: 'Cap_Gains_Inc_LT',
        label: 'Cap Gains Inc LT',
        type: 'number',
        required: true,
        commaSep: true,
        custompattern: 'decimalRate',
        placeholder: '100,000'
    },
    { name: 'Number_Children', label: 'Number of Children', type: 'slider', min: 0, max: 50 },
    { name: 'Tax_Year', label: 'Tax Year', type: 'select', options: taxYearOptions, required: true },
    { name: 'State', label: 'State', type: 'searchSelect', options: handleStateOptions() }
];

const TaxEstiForm = ({ classes, taxEstiData, defaultoverrideData }) => {
    const dispatch = useDispatch();
    const {
        handleSubmit,
        control,
        formState: { isDirty, isSubmitting },
        setValue
    } = useForm({
        mode: 'onChange',
        defaultValues: {
            Filing_Status: 'Single',
            Salary_and_wages: 6000.0,
            Int_and_Div: 0.0,
            Cap_Gains_Inc_LT: 20000.0,
            Tax_Year: 2022,
            Number_Children: 0,
            State: 'NY'
        }
    });
    //to submit the form data
    const onSubmit = (data) => {
        const objToSend = {
            Filing_Status: data.Filing_Status,
            Salary_and_wages: data.Salary_and_wagesUnFormattedValue || data.Salary_and_wages,
            Int_and_Div: data.Int_and_DivUnFormattedValue || data.Int_and_Div,
            Cap_Gains_Inc_LT: data.Cap_Gains_Inc_LTUnFormattedValue || data.Cap_Gains_Inc_LT,
            Tax_Year: data.Tax_Year,
            Number_Children: data.Number_Children,
            State: data.State?.value
        };
        dispatch(submitTaxEstiCal(objToSend));
    };
    // when any input field value will change it will call below method
    const handleFormInputChange = (e) => {
        if (isDirty && Object.keys(taxEstiData).length > 0) {
            dispatch(submitTaxEstiCalSuccess({}));
        }
    };
    useEffect(() => {
        const list = handleStateOptions();
        let element = list.filter((val) => val.value === 'NY')[0];
        setValue('State', element);
        // reset output when route will change
        return () => {
            dispatch(submitTaxEstiCalSuccess({}));
        };
    }, []);

    return (
        <Card className={`${classes.widgetCalCard}`} elevation={3}>
            <CardContent>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container>
                        {fieldsDetail.map((val, i) => {
                            if (val.type === 'slider') {
                                return (
                                    <Fragment key={i + 'lifeInsForm'}>
                                        <Grid item xs={12} md={5} lg={5} className={classes.insSliderLabel}>
                                            <Typography gutterBottom sx={{ ml: 1 }}>
                                                {val.label}
                                                {val.label
                                                    ? tooltip.default?.[val.label] && (
                                                          <InputAdornment position="end" sx={{ margin: '20px 0' }}>
                                                              <CustomTooltip tooltipText={tooltip.default?.[val.label]} label={val.label} />
                                                          </InputAdornment>
                                                      )
                                                    : ''}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={7} lg={7} className={classes.insSliderField}>
                                            <RenderField
                                                type="slider"
                                                control={control}
                                                name={val.name}
                                                id={val.name}
                                                max={val.max}
                                                min={val.min}
                                                disabled={val.disabled}
                                                valueLabelDisplay="on"
                                                handleOnChange={handleFormInputChange}
                                            />
                                        </Grid>
                                    </Fragment>
                                );
                            }
                            return (
                                <Grid item xs={12} sm={6} key={i + 'taxestiform'}>
                                    <RenderField
                                        name={val.name}
                                        control={control}
                                        label={val.label}
                                        id={val.name + '_taxestiform'}
                                        type={val.type}
                                        thousandSeparator={val.commaSep}
                                        customPatternName={val.custompattern}
                                        required={val.required || false}
                                        placeholder={val.placeholder}
                                        setValue={setValue}
                                        renderOption={(props, option) => {
                                            return (
                                                <Box component="li" sx={{ m: 1 }} {...props}>
                                                    {option.label}
                                                </Box>
                                            );
                                        }}
                                        {...val}
                                    />
                                </Grid>
                            );
                        })}
                    </Grid>
                    <CalCardActions>
                        <Grid container style={{ width: '100%', textAlign: 'center' }}>
                            <Grid item xs={12}>
                                <CustomButton type="submit" ps={'9px 30px'} variant="contained" disabled={isSubmitting}>
                                    Calculate
                                </CustomButton>
                            </Grid>
                        </Grid>
                    </CalCardActions>
                </form>
            </CardContent>
        </Card>
    );
};

export default TaxEstiForm;
