/**
 * Typography used in theme
 * @param {JsonObject} theme theme customization object
 */

export default function themeTypography(theme) {
    return {
        fontFamily: theme?.customization?.fontFamily || theme?.fontFamily,
        h6: {
            //16px
            fontWeight: 700,
            color: theme.colors?.textBlack,
            fontFamily: 'Montserrat',
            fontSize: '1rem',
            lineHeight: '20px'
        },
        h5: {
            //20px
            fontFamily: 'Montserrat',
            fontSize: '1.25rem',
            color: theme.colors?.textBlack,
            fontWeight: 600,
            lineHeight: '24px',
            '@media (max-width: 425px)': {
                fontSize: '1rem'
            }
        },
        h4: {
            //20px
            fontSize: '1.563rem',
            color: theme.colors?.textBlack,
            fontWeight: 700,
            lineHeight: '26px'
        },
        h3: {
            //31.25px
            fontSize: '1.953rem',
            color: theme.colors?.textBlack,
            fontFamily: '"PT Serif", "Montserrat", "Arial", sans-serif',
            fontWeight: 700,
            lineHeight: '41px'
        },
        h2: {
            //39.06px
            fontSize: '2.441rem',
            fontFamily: 'Montserrat',
            color: theme.colors?.textBlack,
            fontWeight: 600,
            lineHeight: '48px',
            '@media (max-width: 768px)': {
                fontSize: '1.9rem'
            },
            '@media (max-width: 425px)': {
                fontSize: '1.5rem'
            },
            '@media (max-width: 375px)': {
                fontSize: '1rem'
            }
        },
        h1: {
            //39.06px
            fontFamily: '"PT Serif", "Montserrat", "Arial", sans-serif',
            fontSize: '2.441rem',
            color: theme.colors?.textBlack,
            fontWeight: 700,
            lineHeight: '52px',
            '@media (max-width: 768px)': {
                fontSize: '1.9rem'
            },
            '@media (max-width: 425px)': {
                fontSize: '1.5rem',
                lineHeight: '23px'
            },
            '@media (max-width: 375px)': {
                fontSize: '1rem'
            }
        },
        subtitle1: {
            //h6 // 16px
            fontSize: '1rem',
            fontFamily: 'Montserrat',
            lineHeight: '20px',
            fontWeight: 600,
            color: theme.colors?.textDark,
            textTransform: 'none'
        },
        subtitle2: {
            fontSize: '0.75rem',
            fontFamily: '"PT Serif", "Montserrat", "Arial", sans-serif',
            fontWeight: 400,
            color: theme.colors?.greyMain
        },
        caption: {
            fontSize: '0.75rem',
            fontFamily: '"PT Serif", "Montserrat", "Arial", sans-serif',
            color: theme.colors?.greyMain,
            fontWeight: 400
        },
        heading: {
            //25px
            fontSize: '1.563rem',
            color: theme.colors?.textDark,
            fontFamily: 'Montserrat',
            fontWeight: 700,
            lineHeight: '30px',
            '@media (max-width: 1240px)': {
                fontSize: '0.9rem'
            }
        },
        subheading2: {
            //25px
            fontSize: '1.563rem',
            color: theme.colors?.textDark,
            fontWeight: 700,
            fontFamily: '"PT Serif", "Montserrat", "Arial", sans-serif',
            lineHeight: '33px',
            '@media (max-width: 1240px)': {
                fontSize: '0.9rem'
            }
        },
        subheading: {
            fontSize: '1.1rem',
            color: theme.colors?.textDark,
            fontWeight: 400,
            fontFamily: '"PT Serif", "Montserrat", "Arial", sans-serif',
            '@media (max-width: 1240px)': {
                fontSize: '0.9rem'
            }
        },
        body1: {
            fontSize: '1rem', //16px
            fontWeight: 500,
            fontFamily: 'Montserrat',
            lineHeight: '20px',
            '@media (max-width: 320px)': {
                fontSize: '0.7rem'
            }
        },
        body2: {
            letterSpacing: '0em',
            fontWeight: 400,
            fontFamily: '"PT Serif", "Montserrat", "Arial", sans-serif',
            lineHeight: '1.5em',
            color: theme.colors?.textDark
        },
        button: {
            fontWeight: 700,
            fontFamily: '"PT Serif", "Montserrat", "Arial", sans-serif',
            textTransform: 'capitalize'
        },
        customInput: {
            marginTop: 1,
            marginBottom: 1,
            '& > label': {
                top: 23,
                left: 0,
                color: theme.colors.greyMain,
                '&[data-shrink="false"]': {
                    top: 5
                }
            },
            '& > div > input': {
                padding: '30.5px 14px 11.5px !important'
            },
            '& legend': {
                display: 'none'
            },
            '& fieldset': {
                top: 0
            }
        },
        cardHeader: {
            fontSize: '0.7rem',
            color: theme.colors?.textDark,
            fontWeight: 700,
            lineHeight: '13px',
            '@media (max-width: 1240px)': {
                fontSize: '0.9rem'
            }
        },
        cardSubHeader: {
            fontSize: '0.5rem',
            color: theme.colors?.textDark,
            fontWeight: 500,
            lineHeight: '9px',
            '@media (max-width: 1240px)': {
                fontSize: '0.7rem'
            }
        },
        mainContent: {
            backgroundColor: theme.colors.primaryLight,
            width: '100%',
            minHeight: 'calc(100vh - 88px)',
            flexGrow: 1,
            padding: '20px',
            marginTop: '88px',
            marginRight: '20px',
            borderRadius: `${theme?.customization?.borderRadius}px`
        },
        menuCaption: {
            fontSize: '0.875rem',
            fontWeight: 500,
            color: theme.colors?.textBlack,
            padding: '6px',
            textTransform: 'capitalize',
            marginTop: '10px'
        },
        subMenuCaption: {
            fontSize: '0.6875rem',
            fontWeight: 500,
            color: theme.greyMain,
            textTransform: 'capitalize'
        },
        commonAvatar: {
            cursor: 'pointer',
            borderRadius: '8px'
        },
        smallAvatar: {
            width: '22px',
            height: '22px',
            fontSize: '1rem'
        },
        mediumAvatar: {
            width: '34px',
            height: '34px',
            fontSize: '1.2rem'
        },
        largeAvatar: {
            width: '44px',
            height: '44px',
            fontSize: '1.5rem'
        }
    };
}
