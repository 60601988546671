import React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment } from '@mui/material';
import { StyledSearchBox } from 'assets/jss/TableStyle';
import CustomInput from 'components/CustomInput/CustomInput';
const GlobalFilter = ({ globalFilter, setGlobalFilter }) => {
    return (
        <StyledSearchBox>
            <CustomInput
                inputProps={{ 'aria-label': 'search' }}
                value={globalFilter || ''}
                id="tableSearch"
                type="search"
                labelText=""
                placeholder="Full search"
                onChange={(e) => {
                    setGlobalFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
                }}
                CustomInputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    )
                }}
            />
        </StyledSearchBox>
    );
};
export default GlobalFilter;
