import React from 'react';
import { Card, CardContent, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { QuesAnsBox } from 'assets/jss/RetirementStyle';

const TipsCalDesc = (props) => {
    return (
        <Grid container spacing={2} sx={{ mt: 2 }}>
                <Card>
                    <CardContent>
                        <QuesAnsBox>
                            <Typography variant="h2" id="description" sx={{ mb: 2, display: 'flex' }}>
                                Restaurant Bill Splitting and Tips Calculator

                            </Typography>
                            <Typography variant="body1" className="dashboard-subheading" sx={{ mb: 1.5 }}>
                                This calculator allows you to calculate the restaurant tip amount and split bills with friends.
                                No more mental gymnastics, no more burdening your math major friends with the arduous task of
                                calculating the tip or splitting the bill. The handy slider and drop down will allow you
                                to do this so f@#king fast, your friends will think you did it in your head. You’re welcome.
                            </Typography>
                        </QuesAnsBox>
                    </CardContent>
                </Card>
            </Grid>
    );
};

export default TipsCalDesc;
