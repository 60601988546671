import {
    Button, CardContent,
    createStyles, Grid, Tooltip
} from "@mui/material";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Fade from "@mui/material/Fade";
import IconButton from "@mui/material/IconButton";
import Popper from "@mui/material/Popper";
import { makeStyles } from '@mui/styles';
import BorderColorIcon from "@mui/icons-material/BorderColor";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import styles from "assets/jss/material-kit-react/views/chatBotPage";
import widgetstyle from "assets/jss/material-kit-react/views/widgetPage";
import classNames from "classnames";
import React,{ useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchWidgetUserCalList } from "redux/actions/SettingActions";
import MainRoutes from 'views/Routes/MainRoutes';
import { toast } from "react-toastify";
const mergedStyle = makeStyles((theme) =>
  createStyles({
    ...styles(theme),
    ...widgetstyle(theme),
  })
);

const WidgetCal = (props) => {
  const dispatch = useDispatch();
  const classes = mergedStyle();
  const [anchorEl, setAnchorEl] = useState(null);
  const [widComponent, setWidComponent] = useState({});
  const [openPopperVal, setOpenPopperVal] = useState({});
  const [openWidSubMenu, setWidSubMenu] = useState(false);
  const [parentOpen, setParentOpen] = useState(false);
  const [childOpen, setChildOpen] = useState('');
  const [open, setOpen] = useState(false);
  const setting = useSelector((state) => state.setting);
// fetch the list of user selected widget
  useEffect(() => {
    dispatch(fetchWidgetUserCalList());
  }, []);

  //close  popup
  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
    setWidSubMenu(false);
  };
  //open popup
  const handleClick = () => {
    if (setting.userWidCalList.length === 0)
        return toast.info('Add widget calcualtor(s) from setting page.');
    setWidSubMenu(!openWidSubMenu);
  }
  const openPopper = (e, val) => {
    setAnchorEl(e.currentTarget);
    setOpen(!open);
    setOpenPopperVal(val);
    const openPopperComponent = MainRoutes[0].children.find((rval) => rval.path === val.path);
    setWidComponent(openPopperComponent);
  };
  
  return (
      <div className={classes.root}>
          <Tooltip
              open={!childOpen && parentOpen}
              disableHoverListener
              title={setting.userWidCalList.length > 0 ? 'Widget Calculator(s)' : 'Add widget calcualtor(s) from setting page.'}
          >
              <Button
                  aria-label={'Widget Calculator'}
                  onMouseEnter={() => setParentOpen(true)}
                  onMouseLeave={() => setParentOpen(false)}
                  color={'primary'}
                  className={`${classes.widgetFab} ${classes.fab} ${classes.main} ${openWidSubMenu && 'open'}`}
                  onClick={handleClick}
              >
                  <div className={'cir'}>
                      {setting.userWidCalList.map((val, i) => {
                          return (
                              <Tooltip
                                  title={val.name}
                                  aria-label={val.name}
                                  key={i + '_submenuwidcal'}
                                  open={childOpen === val.name || false}
                                  disableHoverListener
                              >
                                  <Button
                                      aria-label={val.name}
                                      onMouseEnter={() => setChildOpen(val.name)}
                                      onMouseLeave={() => setChildOpen('')}
                                      color={'primary'}
                                      style={{ backgroundImage: `url(img/widcal${i + 1}.png)` }}
                                      className={`${classes.widgetFab} ${classes.fab}`}
                                      onClick={(e) => openPopper(e, val)}
                                  />
                              </Tooltip>
                          );
                      })}
                  </div>
              </Button>
          </Tooltip>
          <Popper
              open={open}
              anchorEl={anchorEl}
              placement={'top-end'}
              transition
              className={classNames(classes.chatbot, classes.widgetCalWrapper)}
          >
              {({ TransitionProps }) => (
                  <Fade {...TransitionProps} timeout={350}>
                      <Card className={`${(classes.root, classes.chatBotPopover)}`}>
                          <CardHeader
                              className={`${classes.botHeader} ${classes.todoPopupHeader} ${classes.todotitle}`}
                              avatar={<BorderColorIcon />}
                              action={
                                  <IconButton aria-label="settings" onClick={handleClose}>
                                      <ExpandMoreIcon />
                                  </IconButton>
                              }
                              title={openPopperVal.name}
                          />
                          <CardContent className={classes.todoPopupContent} id="widget_card_content">
                              <Grid container className="ongoingContainer">
                                  <Grid item xs={12}>
                                      <widComponent.component widgetcal={true} />
                                  </Grid>
                              </Grid>
                          </CardContent>
                      </Card>
                  </Fade>
              )}
          </Popper>
      </div>
  );
};

export default WidgetCal;
