import { Tab, Tabs, Typography, useTheme } from '@mui/material';
import { WidgetBox } from 'assets/jss/GeneralStyle';
import { LargeContainer, MainBox } from 'assets/jss/GeneralStyle';
import { CustomListItem, CustomListItemText } from 'assets/jss/HeaderStyle';
import { TopBlockBox } from 'assets/jss/PortfolioStyle';
import CommonCalculator from 'components/CommonComponent/CommonCalculator.js';
import Loader from 'components/CommonComponent/Loader.js';
import React, { useEffect, useRef, useState } from 'react';
import { a11yProps } from 'utils/Utils';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import CollegeComparsion from './CollegeComparsion';
import CollegeCalDesc from './CollegeCalDesc';
import Tour from 'reactour';
import './index.css';
import Meta from 'components/meta';

export default function FundComparison(props) {
    const theme = useTheme();
    const collegeData = useSelector((state) => {
        return state.collegeCal;
    });
    const [selectedTab, setSelectedTab] = useState(0);
    const [isTourOpen, setIsTourOpen] = useState(false);
    const [currentStep, setCurrentStep] = useState(0);

    const typographyRef = useRef(null);
    useEffect(() => {
        // Set the ID after the component mounts
        if (typographyRef.current) {
            typographyRef.current.id = 'clgCal';
        }
    }, []);

    const handleButtons = () => {
        return (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {currentStep !== 0 && (
                    <button
                        style={{
                            padding: '10px',
                            border: '1px solid black',
                            backgroundColor: 'transparent',
                            borderRadius: '10px',
                            fontSize: '18px',
                            cursor: 'pointer'
                        }}
                        onClick={() => setCurrentStep(currentStep - 1)}
                    >
                        Back
                    </button>
                )}
                {currentStep !== steps.length - 1 ? (
                    <button
                        style={{
                            padding: '10px',
                            backgroundColor: 'black',
                            color: 'white',
                            borderRadius: '10px',
                            fontSize: '18px',
                            border: 'none',
                            cursor: 'pointer'
                        }}
                        onClick={() => setCurrentStep(currentStep + 1)}
                    >
                        Continue
                    </button>
                ) : (
                    <button
                        style={{
                            padding: '10px',
                            backgroundColor: 'black',
                            color: 'white',
                            borderRadius: '10px',
                            fontSize: '18px',
                            border: 'none',
                            cursor: 'pointer'
                        }}
                        onClick={() => closeTour()}
                    >
                        Finish
                    </button>
                )}
            </div>
        );
    };

    const steps = [
        {
            selector: '#tab-0',
            content: () => (
                <>
                    <h4>Comparison</h4>
                    <p>Welcome to Comparison! here you can see the School field, Comparison and Reset Result buttons.</p>
                    {handleButtons()}
                </>
            )
        },
        {
            selector: '#school',
            content: () => (
                <>
                    <h4>School</h4>
                    <p>
                        {' '}
                        Now, let me introduce you to School field! here by clicking on this field, you can see the list of school options
                        and select one or more than one option at one time.
                    </p>
                    {handleButtons()}
                </>
            )
        },
        {
            selector: '#btn-1',
            content: () => (
                <>
                    <h4>Comparison</h4>
                    <p>
                        Now, let me introduce you to Comparison Button! here by clicking on this button, you can see the table below with
                        the comparison between multiple schools including Graduation, Average Annual Cost and Median Earnings.
                    </p>
                    {handleButtons()}
                </>
            )
        },
        {
            selector: '#btn-2',
            content: () => (
                <>
                    <h4>Reset Result</h4>
                    <p>Now, let me introduce you to Reset Result Button! here by clicking on this button. you can reset the result.</p>
                    {handleButtons()}
                </>
            )
        }
    ];

    const closeTour = () => {
        setIsTourOpen(false);
        document.body.style.overflow = 'auto';
    };

    const handleTour = () => {
        setIsTourOpen(true);
        document.body.style.overflow = 'hidden';
    };
    // fetch debt detail on page load
    const render = () => {
        return (
            <>
                <div>
                    <Tour
                        steps={steps}
                        isOpen={isTourOpen}
                        onRequestClose={closeTour}
                        goToStep={currentStep}
                        showButtons={false}
                        showNavigation={true}
                        showNumber={false}
                        closeWithMask={false}
                        closeWithEsc={false}
                        rounded={10}
                        maskSpace={10}
                    />
                </div>
                <TopBlockBox>
                    <Tabs
                        className="topblock-tabs"
                        value={selectedTab}
                        indicatorColor="primary"
                        textColor="primary"
                        // variant="scrollable"
                        allowScrollButtonsMobile={true}
                        aria-label="full width tabs example"
                    >
                        <Tab label="Comparison" {...a11yProps(1)} id="tab-0" />
                    </Tabs>
                </TopBlockBox>
                {collegeData.loader ? <Loader /> : ''}
                {/* <TopBlockBox> */}
                <CollegeComparsion collegeData={collegeData} />
                {/* </TopBlockBox> */}
            </>
        );
    };
    const breadcrumbs = [
        <CustomListItem className="cal-banner-menu" key="1" sx={{ m: 0 }} color="text.light">
            <CustomListItemText primary={'Home'} />
        </CustomListItem>,
        <CustomListItem className="cal-banner-menu" key="2" sx={{ m: 0 }} color="text.light">
            <CustomListItemText primary={'Investments'} />
        </CustomListItem>,
        <Typography ref={typographyRef} key="3" color="text.light" className="current-menu">
            College Score Calculator
        </Typography>
    ];
    return (
        (props.widgetcal && <WidgetBox>{render()}</WidgetBox>) || (
            <>
                <Meta
                    title="College Cost Calculator | TPE Hub"
                    description="Estimate your college costs accurately with our College Calculator. Get a detailed breakdown of tuition, fees, and additional expenses."
                    page="/college-score-calculator"
                />
                <CommonCalculator variantText="College Score Calculator" breadcrumbs={breadcrumbs} handleTour={handleTour} />
                <MainBox>
                    <LargeContainer maxWidth="xl">
                        {render()}
                        <CollegeCalDesc />
                    </LargeContainer>
                </MainBox>
            </>
        )
    );
}
