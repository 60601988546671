import { Card, CardContent, Dialog, DialogContent, Divider, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import NoDataFound from 'components/CommonComponent/NoDataFound';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { submitEquityTickerSuccess } from 'redux/actions/SettingActions';
import { getEquityTicker, handleDialogVisibility, submitEquityTicker } from 'redux/actions/SettingActions';
import RenderField from 'utils/RenderField';
import CustomDialogTitle from 'components/DialogTitle';
import { CustomButton } from 'assets/jss/ButtonStyle';

const EquityTicker = (props) => {
    const { classes, setting } = props;
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const {
        handleSubmit,
        control,
        reset,
        formState: { isSubmitting },
        setValue
    } = useForm({
        mode: 'onChange'
    });
    // submit the form
    const onSubmit = (data) => {
        const type = data.id ? 'put' : 'post';
        const objToSend = {
            ticker: data.ticker,
            company_name: data.company_name,
            id: data.id || undefined
        };
        dispatch(submitEquityTicker(objToSend, type));
    };
    useEffect(() => {
        // once traffic light popup will submit to close that popup when api return success
        if (setting.equityTickerFormDialog) {
            handleClose();
            dispatch(submitEquityTickerSuccess(false));
        }
    }, [setting.equityTickerFormDialog]);
    useEffect(() => {
        /// to fetch the default traffic light values on page load
        dispatch(getEquityTicker());
    }, []);

    const handleClickOpen = () => setOpen(true);
    // delete selected traffic module
    const deleteSelectedData = (id) => dispatch(submitEquityTicker({ id: id }, 'delete'));
    // enable to edit traffic module
    const handleEditData = (val) => {
        setOpen(true);
        // to load saved data in form
        for (const key in val) {
            if (Object.hasOwnProperty.call(val, key)) {
                let element = val[key];
                setValue(key, element);
            }
        }
    };
    //close popup
    const handleClose = () => {
        reset({ ticker: '', company_name: '' });
        setOpen(false);
    };

    return (
        <>
            <Card elevation={2}>
                <CardContent>
                    <Tooltip title="Add" arrow>
                        <CustomButton
                            variant="text"
                            className="read-more"
                            startIcon={<AddIcon />}
                            aria-label="add"
                            onClick={handleClickOpen}
                        >
                            Add New Data
                        </CustomButton>
                    </Tooltip>
                    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                        <CustomDialogTitle id="form-dialog-title" onClose={handleClose}>
                            Add New Ticker
                        </CustomDialogTitle>
                        <Divider />
                        <DialogContent>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <RenderField
                                            name="ticker"
                                            control={control}
                                            label={'Equity Ticker'}
                                            id={'eticker'}
                                            type={'text'}
                                            required={true}
                                            placeholder={'Enter Ticker Name'}
                                            setValue={setValue}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <RenderField
                                            name="company_name"
                                            control={control}
                                            label={'Company Name'}
                                            id={'cname'}
                                            type={'text'}
                                            required={true}
                                            placeholder={'Enter Company Name'}
                                            setValue={setValue}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container direction="row" style={{ width: '100%', textAlign: 'center' }}>
                                    <Grid item xs={12}>
                                        <CustomButton type="submit" variant="contained" disabled={isSubmitting}>
                                            Submit
                                        </CustomButton>
                                    </Grid>
                                </Grid>
                            </form>
                        </DialogContent>
                    </Dialog>

                    {setting.equityTickerData.length > 0 ? (
                        <Card className={`${classes.trafficlightCard} notiCard`}>
                            <CardContent className="">
                                <Grid container spacing={2} className="tlContainer">
                                    <Grid item xs={12}>
                                        <Grid container spacing={0}>
                                            <Grid item xs={12} sm={3}>
                                                <Typography variant="h6">Actions</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={4} textAlign="center">
                                                <Typography variant="h6">Name</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={4} textAlign="center">
                                                <Typography variant="h6">Company Name</Typography>
                                            </Grid>
                                        </Grid>
                                        <Divider />
                                        <Grid container maxHeight="300px" overflow="auto" alignItems="center">
                                            {setting.equityTickerData.map((val, i) => {
                                                return (
                                                    <>
                                                        <Grid item xs={12} sm={3}>
                                                            <Tooltip title="Delete">
                                                                <IconButton aria-label="delete" onClick={() => deleteSelectedData(val.id)}>
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Tooltip title="Edit">
                                                                <IconButton aria-label="edit" onClick={(e) => handleEditData(val)}>
                                                                    <CreateIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Grid>
                                                        <Grid item xs={12} sm={4}>
                                                            <Typography component="p" textAlign={'center'}>
                                                                {val.ticker}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12} sm={4}>
                                                            <Typography component="p" textAlign={'center'}>
                                                                {val.company_name}
                                                            </Typography>
                                                        </Grid>
                                                    </>
                                                );
                                            })}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    ) : (
                        <NoDataFound msg="Please add new module to display here." />
                    )}
                </CardContent>
            </Card>
        </>
    );
};

export default EquityTicker;
