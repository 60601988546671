import * as ActionTypes from '../actions/ActionTypes';

const initialState = {
    homeValuationDetail: {},
    savedHomeValuationDetail: {},
    loader: false,
    submitSuccess: false,
    updateSucess: false,
    homePurchFiles: [],
    homePurchSelected: [],
    homePurchaseCollDetails: {},
    indepcalData: {},
    homePurchFileData: []
};

export const HomeCalReducers = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.HOME_VALUATION_CAL_INIT:
        case ActionTypes.FETCH_HOME_PURCH_FILES_INIT:
        case ActionTypes.SUBMIT_HOME_PURCH_SMART_TABLE_INIT:
        case ActionTypes.SUBMIT_HOME_PURCH_INIT:
        case ActionTypes.FETCH_HOME_PURCH_SELECTED_INIT:
        case ActionTypes.FETCH_HOME_PURCHASE_FILE_DATA_INIT:
        case ActionTypes.SUBMIT_HOME_PURCHASE_COLL_INIT:
        case ActionTypes.SUBMIT_HOME_PURCHASE_FILE_DATA_INIT:
            return { ...state, loader: true };
        case ActionTypes.HOME_VALUATION_CAL_SUCCESS:
            let formattedResponse = [];
            if (Object.keys(action.data).length > 0) {
                for (let index = 0; index < action.data.data.length; index++) {
                    const labels = [
                        'Home Price Appreciation',
                        'Current Home Value',
                        'Home Price Appreciation - Adjusted',
                        'Current Home Value - Adjusted'
                    ];
                    // const label =
                    //   index === 0 ? "Home Price Appreciation" : "Current Home Value";
                    const value = action.data.data[index];
                    formattedResponse.push({ label: labels[index], value });
                }
            }
            return {
                ...state,
                loader: false,
                homeValuationDetail: { data: formattedResponse }
            };
        case ActionTypes.HOME_VALUATION_CAL_ERROR:
        case ActionTypes.SUBMIT_HOME_PURCH_ERROR:
        case ActionTypes.SUBMIT_HOME_PURCH_SMART_TABLE_ERROR:
        case ActionTypes.SUBMIT_HOME_PURCHASE_FILE_DATA_ERROR:
        case ActionTypes.FETCH_HOME_PURCH_FILES_ERROR:
        case ActionTypes.FETCH_HOME_PURCHASE_FILE_DATA_ERROR:
        case ActionTypes.FETCH_HOME_PURCH_SELECTED_ERROR:
        case ActionTypes.UPDATE_HOME_PURCH_SELECTED_ERROR:
            return { ...state, loader: false };
        case ActionTypes.FETCH_SAVED_DATA_INIT:
        case ActionTypes.FETCH_HOME_PURCHASE_COLL_INIT:
            return { ...state, loader: true };
        case ActionTypes.FETCH_SAVED_DATA_SUCCESS:
            return {
                ...state,
                loader: false,
                savedHomeValuationDetail: action.data
            };
        case ActionTypes.FETCH_SAVED_DATA_ERROR:
            return { ...state, loader: false };
        case ActionTypes.SUBMIT_HOME_PURCH_SUCCESS:
        case ActionTypes.SUBMIT_HOME_PURCH_SMART_TABLE_SUCCESS:
            return { ...state, loader: false, submitSuccess: action.data };
        case ActionTypes.FETCH_HOME_PURCH_FILES_SUCCESS:
            return { ...state, loader: false, homePurchFiles: action.data, updateSuccess: false };
        case ActionTypes.FETCH_HOME_PURCH_SELECTED_SUCCESS:
            return { ...state, loader: false, homePurchSelected: action.data };
        case ActionTypes.UPDATE_HOME_PURCH_SELECTED_INIT:
            return { ...state, loader: true };
        case ActionTypes.UPDATE_HOME_PURCH_SELECTED_SUCCESS:
            return { ...state, loader: false, updateSuccess: true };
        case ActionTypes.FETCH_HOME_PURCHASE_COLL_SUCCESS:
            return { ...state, loader: false, homePurchaseCollDetails: action.data };
        case ActionTypes.FETCH_HOME_PURCHASE_COLL_ERROR:
        case ActionTypes.SUBMIT_HOME_PURCHASE_COLL_SUCCESS:
            return { ...state, loader: false, indepcalData: action.data };
        case ActionTypes.SUBMIT_HOME_PURCHASE_COLL_ERROR:
        case ActionTypes.FETCH_HOME_PURCHASE_FILE_DATA_SUCCESS:
            return { ...state, loader: false, homePurchFileData: action.data };
        case ActionTypes.SUBMIT_HOME_PURCHASE_FILE_DATA_SUCCESS:
            return { ...state, loader: false, sharedFileData: action.data };
        default:
            return state;
    }
};
