import {
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import Switch from "@mui/material/Switch";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { subscirbeForPushNoti } from "redux/actions/GeneralCalActions";
import {submitProfile, submitProfileSettings} from "redux/actions/SettingActions";
import { initServiceWorker } from "utils/PushNotification";
import { getCookie } from "utils/Utils";
import CustomTooltip from "../../components/CommonComponent/CustomTooltip";

const NotificationSetting = ({ setting }) => {
  const dispatch = useDispatch();
  const [webswitchVal, setWebSwitch] = useState(false);
  const [emailswitchVal, setEmailSwitch] = useState(false);
  const [smsSwitchVal, setSmsSwitch] = useState(false);
  const [blogEmailSwitchVal, setBlogEmailSwitchVal] = useState(false);
  const [econDashDailySwitch, setEconDashDailySwitch] = useState(false);
  const [econDashWeeklySwitch, setEconDashWeeklySwitch] = useState(false);
  const [dailyExpensesSwitch, setDailyExpensesSwitch] = useState(false);
  const [weeklyExpensesSwitch, setWeeklyExpensesSwitch] = useState(false);
  const [portfolioDailySwitchVal, setPortfolioDailySwitchVal] = useState(false);
  const [dailyMarketUpdateSwitchVal, setDailyMarketUpdateSwitchVal] = useState(false);

    useEffect(() => {
    setEmailSwitch(setting.profileDetail.emailnoti);
    setSmsSwitch(setting.profileDetail.sms_noti);
    setBlogEmailSwitchVal(setting.profileDetail.blog_notify);
    setPortfolioDailySwitchVal(setting.profileDetail.portfolio_notify);
    setDailyMarketUpdateSwitchVal(setting.profileDetail.market_update);
    if (setting.profileDetail.blog_notify !== "Off") {
        setBlogEmailSwitchVal(true);
    }
    if (setting.profileDetail.blog_notify === "Off") {
        setBlogEmailSwitchVal(false);
    }
    if (setting.profileDetail.econ_dash_notify === "Daily") {
        setEconDashDailySwitch(true);
    }
    if (setting.profileDetail.econ_dash_notify === "Weekly") {
        setEconDashWeeklySwitch(true);
    }
    if (setting.profileDetail.econ_dash_notify === "Daily, Weekly" || setting.profileDetail.econ_dash_notify === "Weekly, Daily") {
        setEconDashDailySwitch(true);
        setEconDashWeeklySwitch(true);
    }

    if (setting.profileDetail.expenses_notify === "Daily") {
        setDailyExpensesSwitch(true);
    }
    if (setting.profileDetail.expenses_notify === "Weekly") {
        setWeeklyExpensesSwitch(true);
    }
    if (setting.profileDetail.expenses_notify === "Daily, Weekly" || setting.profileDetail.expenses_notify === "Weekly, Daily") {
        setDailyExpensesSwitch(true);
        setWeeklyExpensesSwitch(true);
    }

    if (setting.profileDetail.portfolio_notify === "Daily") {
        setPortfolioDailySwitchVal(true)
    }
    if (setting.profileDetail.portfolio_notify === "Off") {
        setPortfolioDailySwitchVal(false)
    }
    if (setting.profileDetail.market_update === "Daily") {
        setDailyMarketUpdateSwitchVal(true)
    }
    if (setting.profileDetail.market_update === "Off") {
        setDailyMarketUpdateSwitchVal(false)
    }
  }, [setting.profileDetail]);

  useEffect(() => {
    if (getCookie("subscription_json")) {
      setWebSwitch(true);
    } else {
      setWebSwitch(false);
    }
  }, []);
  // call api to subscription on server for notification
  const updateSubscriptionOnServer = (subscription) => {
    // TODO: Send subscription to application server
    if (subscription) {
      dispatch(
        subscirbeForPushNoti({
          subscription_json: JSON.stringify(subscription),
        })
      );
    } else {
      dispatch(
        subscirbeForPushNoti(
          { subscription_json: getCookie("subscription_json") },
          "delete"
        )
      );
    }
  };
    //handle toggle button
  const handleTodoNoti = (e) => {
    setWebSwitch(e.target.checked);
    initServiceWorker(updateSubscriptionOnServer, e.target.checked);
  };
  //handle toggle button
  const handleTodoEmailNoti = (e) => {
    setEmailSwitch(e.target.checked);
    dispatch(submitProfile({ "todo_email_notify": e.target.checked }));
  };
  //handle toggle button for sms notifications
  const handleTodoSmsNoti = (e) => {
    setSmsSwitch(e.target.checked);
    dispatch(submitProfile({ "todo_sms_notify": e.target.checked }));
  };

  const handleBlogEmailNotify = (e) => {
    setBlogEmailSwitchVal(e.target.checked);
    dispatch(submitProfileSettings({ "blog_email_notify": e.target.checked }));
  };

  const handleEconDashDailyNotify = (e) => {
      setEconDashDailySwitch(e.target.checked);
      dispatch(submitProfileSettings({ "econ_dash_daily_notify": e.target.checked, "econ_dash_weekly_notify": econDashWeeklySwitch }));
  };

    const handleEconDashWeeklyNotify = (e) => {
        setEconDashWeeklySwitch(e.target.checked);
        dispatch(submitProfileSettings({ "econ_dash_weekly_notify": e.target.checked, "econ_dash_daily_notify": econDashDailySwitch }));
    };


    const handleDailyExpensesNotify = (e) => {
        setDailyExpensesSwitch(e.target.checked);
        dispatch(submitProfileSettings({ "daily_expenses_notify": e.target.checked, "weekly_expenses_notify": weeklyExpensesSwitch }));
    };

    const handleWeeklyExpensesNotify = (e) => {
        setWeeklyExpensesSwitch(e.target.checked);
        dispatch(submitProfileSettings({ "weekly_expenses_notify": e.target.checked, "daily_expenses_notify": dailyExpensesSwitch }));
    };


    const handlePortfolioDailyNotify = (e) => {
        setPortfolioDailySwitchVal(e.target.checked);
        dispatch(submitProfileSettings({ "portfolio_daily_notify": e.target.checked}));

    }


    const handleDailyMarketUpdateNotify = (e) => {
        setDailyMarketUpdateSwitchVal(e.target.checked);
        dispatch(submitProfileSettings({ "market_update_daily": e.target.checked}));

    }

  return (
      <Card elevation={2}>
          <CardContent>
              {/* <Typography variant="subheading2" sx={{ mb: 2, display: 'flex' }} gutterBottom className="top-heading-text">
                  Browser
              </Typography>
              <Divider/> */}
              <Grid container spacing={2}>
                  <Grid item xs={4} sm={5} lg={5}>
                      <Typography variant="h6">User Specific Notifications</Typography>
                  </Grid>
                  <Grid item xs={'auto'}>
                      <Typography variant="h6" textAlign={'center'}>
                          Email
                      </Typography>
                  </Grid>
                  <Grid item xs={3} sm={2}>
                      <Typography variant="h6" textAlign={'center'}>
                          SMS
                      </Typography>
                  </Grid>
                  <Grid item xs={'auto'}>
                      <Typography variant="h6" textAlign={'center'}>
                          Daily
                      </Typography>
                  </Grid>
                  <Grid item xs={3} sm={2}>
                      <Typography variant="h6" textAlign={'center'}>
                          Weekly
                      </Typography>
                  </Grid>
              </Grid>
              <Divider sx={{ marginTop: '8px' }} />
              <Grid container spacing={2}>
                  <Grid item xs={3} sm={5} lg={5} sx={{ marginTop: '8px' }}>
                      <Typography variant="body1">Todo Due Date Notifications</Typography>
                  </Grid>
                  <Grid item xs={'auto'} textAlign='center'>
                      <Switch
                          checked={emailswitchVal}
                          onChange={(event) => handleTodoEmailNoti(event)}
                          value="emailswitchVal"
                      />
                  </Grid>
                  <Grid item xs={3} sm={2} textAlign='center'>
                      <Switch
                          checked={smsSwitchVal}
                          onChange={(event) => handleTodoSmsNoti(event)}
                          value="smsSwitchVal"
                      />
                  </Grid>

                  <Grid item xs={8} sm={8} lg={8} sx={{ marginTop: '-13px' }}>
                      <Typography variant="body1">
                          Portfolio Notifications
                          <CustomTooltip style={{marginLeft: "3px"}} tooltipText={"Currently, notifications for Portfolio will only be sent daily at 9AM EST. Stay updated!"} customIconTooltip={false} textTooltip={true}/>
                      </Typography>
                  </Grid>
                  <Grid item xs={'auto'} textAlign='center' sx={{ marginTop: '-23px' }}>
                      <Switch
                          checked={portfolioDailySwitchVal}
                          onChange={(event) => handlePortfolioDailyNotify(event)}
                          value="portfolioDailySwitchVal"
                      />
                  </Grid>
                  <Grid item xs={8} sm={8} lg={8}>
                      <Typography variant="body1">User Expenses</Typography>
                  </Grid>
                  <Grid item xs={'auto'} textAlign='center' sx={{ marginTop: '-8px' }}>
                      <Switch
                          checked={dailyExpensesSwitch}
                          onChange={(event) => handleDailyExpensesNotify(event)}
                          value="dailyExpensesSwitch"
                      />
                  </Grid>
                  <Grid item xs={3} sm={2} textAlign='center' sx={{ marginTop: '-8px' }}>
                      <Switch
                          checked={weeklyExpensesSwitch}
                          onChange={(event) => handleWeeklyExpensesNotify(event)}
                          value="weeklyExpensesSwitch"
                      />
                  </Grid>
              </Grid>
              <Divider sx={{ marginTop: '8px', marginBottom: '8px'  }} />
              <Grid container spacing={2}>
                  <Grid item xs={6} sm={8} lg={8}>
                      <Typography variant="h6">General Notifications</Typography>
                  </Grid>
                  <Grid item xs={'auto'}>
                      <Typography variant="h6" textAlign={'center'}>
                          Daily
                      </Typography>
                  </Grid>
                  <Grid item xs={3} sm={2}>
                      <Typography variant="h6" textAlign={'center'}>
                          Weekly
                      </Typography>
                  </Grid>
              </Grid>
              <Grid container spacing={2}>
                  <Grid item xs={6} sm={8} lg={8} sx={{ marginTop: '8px', }}>
                      <Typography variant="body1">
                          Economic Dashboard Notifications
                          <CustomTooltip style={{marginLeft: "3px"}} tooltipText={"You will receive email updates of the Economic Dashboard based on your chosen frequency."} customIconTooltip={false} textTooltip={true}/>
                      </Typography>
                  </Grid>
                  <Grid item xs={'auto'} textAlign='center'>
                      <Switch
                          checked={econDashDailySwitch}
                          onChange={(event) => handleEconDashDailyNotify(event)}
                          value="econDashDailySwitch"
                      />
                  </Grid>
                  <Grid item xs={3} sm={2} textAlign='center'>
                      <Switch
                          checked={econDashWeeklySwitch}
                          onChange={(event) => handleEconDashWeeklyNotify(event)}
                          value="econDashWeeklySwitch"
                      />
                  </Grid>
                  <Grid item xs={6} sm={8} lg={8} sx={{ marginTop: '-8px' }}>
                      <Typography variant="body1">
                          Daily Market Update
                          <CustomTooltip style={{marginLeft: "3px"}} tooltipText={"You will receive email updates of the Equity and Fixed Income Dashboards once a day."} customIconTooltip={false} textTooltip={true}/>
                      </Typography>
                  </Grid>
                  <Grid item xs={'auto'} textAlign='center' sx={{ marginTop: '-18px' }}>
                      <Switch
                          checked={dailyMarketUpdateSwitchVal}
                          onChange={(event) => handleDailyMarketUpdateNotify(event)}
                          value="dailyMarketUpdateSwitchVal"
                      />
                  </Grid>
              <Grid item xs={6} sm={8} lg={8} sx={{ marginTop: '-8px' }}>
                  <Typography variant="body1">
                      Knowledge & Research Notifications
                      <CustomTooltip style={{marginLeft: "3px"}} tooltipText={"Currently, notifications for Knowledge & Research will only be sent when new articles are published. Stay updated!"} customIconTooltip={false} textTooltip={true}/>
                  </Typography>
              </Grid>
              <Grid item xs={'auto'} textAlign='center' sx={{ marginTop: '-18px' }}>
                  <Switch
                      checked={blogEmailSwitchVal}
                      onChange={(event) => handleBlogEmailNotify(event)}
                      value="blogEmailSwitchVal"
                  />
              </Grid>
              </Grid>
          </CardContent>
      </Card>
  );
};
export default NotificationSetting;
