import React from 'react';
import { Box, Grid, useMediaQuery } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/system';
import { NewTaskRibbon } from 'assets/jss/GeneralStyle';
import { CustomImg, DashBox, DashCard, DashGrid } from 'assets/jss/LandingStyle';
import { useNavigate } from 'react-router-dom';
import { toolsDetails } from './ToolDetails';

const DashboardBlock = () => {
    const theme = useTheme();
    const navigate = useNavigate();

    const matches = useMediaQuery(theme.breakpoints.down('md'));
    return (
        <DashBox>
            <Box className="title-box">
                <Typography variant="h3" sx={{ mt: 3.5, display: 'inline-block' }}>
                    TPE Dashboards
                </Typography>
                {/* <Typography variant="heading" className="dashboard-subheading" sx={{ mb: 1.5 }}>
                    To make your life easier with simplistic view
                </Typography> */}
            </Box>
            <Grid container spacing={2} className="grid-container">
                {toolsDetails[1].children.map((val, i) => {
                    return (
                        <DashGrid id={`dash-${i}`} item xs={12} sm={6} md={3} key={i + '_dashboardclockcard'}>
                            <DashCard onClick={() => navigate(val.to)} className="dashBlock">
                                {val.to === '/comingSoon' ? (
                                    <NewTaskRibbon>
                                        <div className="wrap addRighPosition">
                                            <span className="ribbonLabel">Coming Soon</span>
                                        </div>
                                    </NewTaskRibbon>
                                ) : (
                                    ''
                                )}
                                <CustomImg src={`img/${val.image}`} alt="green iguana" />
                                <CardContent>
                                    <Typography gutterBottom variant="body1" component="div">
                                        {val.name}
                                    </Typography>
                                    {/* <Typography variant="cardSubHeader">A loan term is the duration of the loan.</Typography> */}
                                </CardContent>
                            </DashCard>
                        </DashGrid>
                    );
                })}
            </Grid>
            {/* <DashCardBox id="heybox" sx={{ flexGrow: 1 ,mb:1.5}}>
                <DashCard elevation={2}>
                    <CustomImg src="img/loanicon.svg" alt="green iguana"  />
                    <CardContent>
                        <Typography gutterBottom variant="cardHeader" component="div">
                            Loan
                        </Typography>
                        <Typography variant="cardSubHeader">
                            A loan term is the duration of the loan, given that required minimum payments are made each month.
                        </Typography>
                    </CardContent>
                </DashCard>
                <DashCard elevation={2}>
                    <CustomImg src="img/athericon.svg" alt="green iguana"  />
                    <CardContent>
                        <Typography gutterBottom variant="cardHeader" component="div">
                            Loan
                        </Typography>
                        <Typography variant="cardSubHeader">
                            A loan term is the duration of the loan, given that required minimum payments are made each month.
                        </Typography>
                    </CardContent>
                </DashCard>
                <DashCard elevation={2}>
                    <CustomImg src="img/economicicon.svg" alt="green iguana"  />
                    <CardContent>
                        <Typography gutterBottom variant="cardHeader" component="div">
                            Loan
                        </Typography>
                        <Typography variant="cardSubHeader">
                            A loan term is the duration of the loan, given that required minimum payments are made each month.
                        </Typography>
                    </CardContent>
                </DashCard>
                <DashCard elevation={2}>
                    <CustomImg src="img/fxicon.svg" alt="green iguana"  />
                    <CardContent>
                        <Typography gutterBottom variant="cardHeader" component="div">
                            Loan
                        </Typography>
                        <Typography variant="cardSubHeader">
                            A loan term is the duration of the loan, given that required minimum payments are made each month.
                        </Typography>
                    </CardContent>
                </DashCard>
            </DashCardBox>
            <DashCardBox>
                <DashCard row="second" elevation={2}>
                    <CustomImg src="img/loanicon.svg" alt="green iguana"  />
                    <CardContent>
                        <Typography gutterBottom variant="cardHeader" component="div">
                            Loan
                        </Typography>
                        <Typography variant="cardSubHeader">
                            A loan term is the duration of the loan, given that required minimum payments are made each month.
                        </Typography>
                    </CardContent>
                </DashCard>
                <DashCard row="second" elevation={2}>
                    <CustomImg src="img/loanicon.svg" alt="green iguana"  />
                    <CardContent>
                        <Typography gutterBottom variant="cardHeader" component="div">
                            Loan
                        </Typography>
                        <Typography variant="cardSubHeader">
                            A loan term is the duration of the loan, given that required minimum payments are made each month.
                        </Typography>
                    </CardContent>
                </DashCard>
                <DashCard row="second" elevation={2}>
                    <CustomImg src="img/loanicon.svg" alt="green iguana"  />
                    <CardContent>
                        <Typography gutterBottom variant="cardHeader" component="div">
                            Loan
                        </Typography>
                        <Typography variant="cardSubHeader">
                            A loan term is the duration of the loan, given that required minimum payments are made each month.
                        </Typography>
                    </CardContent>
                </DashCard>
                <DashCard row="second" elevation={2}>
                    <CustomImg src="img/loanicon.svg" alt="green iguana"  />
                    <CardContent>
                        <Typography gutterBottom variant="cardHeader" component="div">
                            Loan
                        </Typography>
                        <Typography variant="cardSubHeader">
                            A loan term is the duration of the loan, given that required minimum payments are made each month.
                        </Typography>
                    </CardContent>
                </DashCard>
            </DashCardBox> */}
        </DashBox>
    );
};

export default DashboardBlock;
