import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import { OutputBox } from "assets/jss/GeneralStyle";
import CustomTooltip from "components/CommonComponent/CustomTooltip";
import NoDataFound from "components/CommonComponent/NoDataFound";
import React from "react";
import { numberWithCommas } from "utils/Utils";
import * as tooltip from 'Tooltip.json';

const TaxOutput = ({ data }) => {
  return (
      <Card elevation={2} sx={{ mb: 3.5 }}>
          <CardContent>
              <Typography variant="h6" gutterBottom sx={{ mb: 2, display: 'flex' }}>
                  Summary Output
              </Typography>
              <Box id="outputWrapper" className="widget-full-width">
                  {Object.keys(data).length === 0 ? (
                      <NoDataFound />
                  ) : (
                      <Grid container spacing={2}>
                          {Object.keys(data).map((val, i) => {
                              const value = data[val];
                              const isPer = ['Top Marginal Tax Rate', 'Average Tax Rate', 'Long Term Cap Gains Tax Rate'];;
                              const appendSign=(isPer.includes(val) && '%') || '$';
                              const finalVal = (val =='Average Tax Rate') ? numberWithCommas(value.toFixed(2)) : (isPer.includes(val) && Math.round(value)) || numberWithCommas(value.toFixed(2));
                              return typeof val.value !== 'object' ? (
                                  <React.Fragment key={i + 'RetirementOutput'}>
                                      <Grid item xs={12} md={4}>
                                          <OutputBox>
                                              <Typography variant="body1" className="outputLabel" component="p">
                                                  {val}
                                                  {tooltip.default?.[val] && (
                                                      <CustomTooltip
                                                          tooltipText={tooltip.default?.[val]}
                                                          customTooltipStyle={{ maxWidth: 450 }}
                                                          label={val}
                                                      />
                                                  )}
                                              </Typography>
                                              <Typography variant="h6">
                                                  {appendSign} {finalVal}
                                              </Typography>
                                          </OutputBox>
                                      </Grid>
                                  </React.Fragment>
                              ) : (
                                  ''
                              );
                          })}
                      </Grid>
                  )}
              </Box>
          </CardContent>
      </Card>
  );
};

export default TaxOutput;
