import { apiCall } from "utils/Utils"
import { handleError } from "utils/Utils"
import { toast } from "react-toastify"
import * as ActionTypes from "./ActionTypes";
import Globals from "Globals.js"

// handle success of api
export const fetchBlogsListSuccess = (val) => {
  return {
    type: ActionTypes.FETCH_ALL_BLOGS_SUCCESS,
    data: val
  }
}

// handle api error
export const fetchBlogsListError = () => {
  return {
    type: ActionTypes.FETCH_ALL_BLOGS_ERROR
  }
}

// fetch all blogs list
export const fetchBlogsList = () => async dispatch => {
  try {
    dispatch({
      type: ActionTypes.FETCH_ALL_BLOGS_INIT
    });
    const apiResponse = await apiCall(`${Globals.API_ROOT_URL}blogs/`, "get");
    if (apiResponse !== undefined && apiResponse.status === 200) {
      dispatch(fetchBlogsListSuccess(apiResponse.data.data));
    } else {
      handleError(apiResponse, dispatch);
      dispatch(fetchBlogsListError());
    }
  } catch (error) {
    toast.error(Globals.errorMsg);
    dispatch(fetchBlogsListError());
    throw error;
  }
}

export const fetchDashboardBlogsListSuccess = (val) => {
  return {
    type: ActionTypes.FETCH_ALL_DASHBOARD_BLOGS_SUCCESS,
    data: val
  }
}

// handle api error
export const fetchDashboardBlogsListError = () => {
  return {
    type: ActionTypes.FETCH_ALL_DASHBOARD_BLOGS_ERROR
  }
}

// fetch all blogs list for non-registered user
export const fetchDashboardBlogsList = () => async dispatch => {
  try {
    dispatch({
      type: ActionTypes.FETCH_ALL_DASHBOARD_BLOGS_INIT
    });
    const apiResponse = await apiCall(`${Globals.API_ROOT_URL}blogs_dashboard/`, "get");
    if (apiResponse !== undefined && apiResponse.status === 200) {
      dispatch(fetchDashboardBlogsListSuccess(apiResponse.data.data));
    } else {
      handleError(apiResponse, dispatch);
      dispatch(fetchDashboardBlogsListError());
    }
  } catch (error) {
    toast.error(Globals.errorMsg);
    dispatch(fetchDashboardBlogsListError());
    throw error;
  }
}

// handle success of api
export const fetchBlogByIdSuccess = (val) => {
  return {
    type: ActionTypes.FETCH_BLOG_BY_ID_SUCCESS,
    data: val
  }
}

// handle api error
export const fetchBlogByIdError = () => {
  return {
    type: ActionTypes.FETCH_BLOG_BY_ID_ERROR
  }
}

// fetch blog details by id
export const fetchBlogById = (id) => async dispatch => {
  try {
    dispatch({
      type: ActionTypes.FETCH_BLOG_BY_ID_INIT
    });
    const apiResponse = await apiCall(`${Globals.API_ROOT_URL}blogs/${id}`, "get");
    if (apiResponse !== undefined && apiResponse.status === 200) {
      dispatch(fetchBlogByIdSuccess(apiResponse.data.data));
    } else {
      handleError(apiResponse, dispatch);
      dispatch(fetchBlogByIdError());
    }
  } catch (error) {
    toast.error(Globals.errorMsg);
    dispatch(fetchBlogByIdError());
    throw error;
  }
}

export const fetchDashboardBlogByIdSuccess = (val) => {
  return {
    type: ActionTypes.FETCH_DASHBOARD_BLOG_BY_ID_SUCCESS,
    data: val
  }
}

// handle api error
export const fetchDashboardBlogByIdError = () => {
  return {
    type: ActionTypes.FETCH_DASHBOARD_BLOG_BY_ID_ERROR
  }
}

// fetch blog details by id for non-registered users
export const fetchDashboardBlogById = (id) => async dispatch => {
  try {
    dispatch({
      type: ActionTypes.FETCH_DASHBOARD_BLOG_BY_ID_INIT
    });
    const apiResponse = await apiCall(`${Globals.API_ROOT_URL}blogs_dashboard/${id}`, "get");
    if (apiResponse !== undefined && apiResponse.status === 200) {
      dispatch(fetchDashboardBlogByIdSuccess(apiResponse.data.data));
    } else {
      handleError(apiResponse, dispatch);
      dispatch(fetchDashboardBlogByIdError());
    }
  } catch (error) {
    toast.error(Globals.errorMsg);
    dispatch(fetchDashboardBlogByIdError());
    throw error;
  }
}

// handle success of api
export const createNewBlogSuccess = (val) => {
  return {
    type: ActionTypes.CREATE_NEW_BLOG_SUCCESS,
    data: val
  }
}

// handle api error
export const createNewBlogError = () => {
  return {
    type: ActionTypes.CREATE_NEW_BLOG_ERROR
  }
}

// create new blog
export const createNewBlog = (data, closeEditor) => async dispatch => {
  try {
    dispatch({
      type: ActionTypes.CREATE_NEW_BLOG_INIT
    });
    const apiResponse = await apiCall(`${Globals.API_ROOT_URL}blogs/`, "post", data);
    if (apiResponse !== undefined && apiResponse.status === 201) {
      toast.success(apiResponse.data.data.message)
      dispatch(createNewBlogSuccess(apiResponse.data.data));
      closeEditor()
    } else {
      handleError(apiResponse, dispatch);
      dispatch(createNewBlogError());
    }
  } catch (error) {
    toast.error(Globals.errorMsg);
    dispatch(createNewBlogError());
    throw error;
  }
}

// handle success of api
export const updateBlogSuccess = (val) => {
  return {
    type: ActionTypes.UPDATE_BLOG_SUCCESS,
    data: val
  }
}

// handle api error
export const updateBlogError = () => {
  return {
    type: ActionTypes.UPDATE_BLOG_ERROR
  }
}

// update blog by id
export const updateBlog = (id,data,closeEditor) => async dispatch => {
  try {
    dispatch({
      type: ActionTypes.UPDATE_BLOG_INIT
    });
    const apiResponse = await apiCall(`${Globals.API_ROOT_URL}blogs/${id}`, "put", data);
    if (apiResponse !== undefined && apiResponse.status === 200) {
      toast.success(apiResponse.data.data.message);
      dispatch(updateBlogSuccess(apiResponse.data.data));
      closeEditor()
    } else {
      handleError(apiResponse, dispatch);
      dispatch(updateBlogError());
    }
  } catch (error) {
    toast.error(Globals.errorMsg);
    dispatch(updateBlogError());
    throw error;
  }
}

export const updateBlogCategorySuccess = (val) => {
  return {
    type: ActionTypes.UPDATE_BLOG_CATEGORY_SUCCESS,
    data: val
  }
}

// handle api error
export const updateBlogCategoryError = () => {
  return {
    type: ActionTypes.UPDATE_BLOG_CATEGORY_ERROR
  }
}

// update blog by id
export const updateBlogCategory = (id,data) => async dispatch => {
  try {
    dispatch({
      type: ActionTypes.UPDATE_BLOG_CATEGORY_INIT
    });
    const apiResponse = await apiCall(`${Globals.API_ROOT_URL}blogs/category/${id}`, "put", data);
    if (apiResponse !== undefined && apiResponse.status === 200) {
      toast.success(apiResponse.data.data.message);
      dispatch(updateBlogCategorySuccess(apiResponse.data.data));
      dispatch(fetchBlogCategory())
      dispatch(fetchBlogsList())
    } else {
      handleError(apiResponse, dispatch);
      dispatch(updateBlogCategoryError());
    }
  } catch (error) {
    toast.error(Globals.errorMsg);
    dispatch(updateBlogCategoryError());
    throw error;
  }
}

export const deleteBlogSuccess = (val) => {
  return {
    type: ActionTypes.DELETE_BLOG_SUCCESS,
    data: val
  }
}

export const deleteBlogError = () => {
  return {
    type: ActionTypes.DELETE_BLOG_ERROR
  }
}

export const deleteBlog = (id) => async dispatch => {
  try {
    dispatch({
      type: ActionTypes.DELETE_BLOG_INIT
    });
    const apiResponse = await apiCall(`${Globals.API_ROOT_URL}blogs/${id}`, "delete");
    if (apiResponse !== undefined && apiResponse.status === 200) {
      toast.success(apiResponse.data.data.message);
      dispatch(deleteBlogSuccess(apiResponse.data.data));
      dispatch(fetchBlogsList());
    } else {
      handleError(apiResponse, dispatch);
      dispatch(deleteBlogError());
    }
  } catch (error) {
    toast.error(Globals.errorMsg);
    dispatch(deleteBlogError());
    throw error;
  }
}

// delete blog category
export const deleteBlogCategorySuccess = (val) => {
  return {
    type: ActionTypes.DELETE_BLOG_CATEGORY_SUCCESS,
    data: val
  }
}

export const deleteBlogCategoryError = () => {
  return {
    type: ActionTypes.DELETE_BLOG_CATEGORY_ERROR
  }
}

export const deleteBlogCategory = (id) => async dispatch => {
  try {
    dispatch({
      type: ActionTypes.DELETE_BLOG_CATEGORY_INIT
    });
    const apiResponse = await apiCall(`${Globals.API_ROOT_URL}blogs/category/${id}`, "delete");
    if (apiResponse !== undefined && apiResponse.status === 200) {
      toast.success(apiResponse.data.data.message);
      dispatch(deleteBlogCategorySuccess(apiResponse.data.data));
      dispatch(fetchBlogsList())
      dispatch(fetchBlogCategory());
    } else {
      handleError(apiResponse, dispatch);
      dispatch(deleteBlogCategoryError());
    }
  } catch (error) {
    toast.error(Globals.errorMsg);
    dispatch(deleteBlogCategoryError());
    throw error;
  }
}

//handle success of api
export const fetchBlogCategorySuccess = val => {
  const AllCategoryObject  = [{name: "All"}, ...val]
  return {
    type: ActionTypes.FETCH_BLOG_CATEGORY_SUCCESS,
    data: AllCategoryObject
  };
};
// handle api error
export const fetchBlogCategoryError = () => {
  return {
    type: ActionTypes.FETCH_BLOG_CATEGORY_ERROR
  };
};
export const fetchBlogCategory = () => async dispatch => {
  try {
    dispatch({
      type: ActionTypes.FETCH_BLOG_CATEGORY_INIT,
    });
    const apiResponse = await apiCall(
      `${Globals.API_ROOT_URL}blogs/category`,
      "get"
    );
    if (apiResponse !== undefined && apiResponse.status === 200) {
      dispatch(fetchBlogCategorySuccess(apiResponse.data.data));
    } else {
      handleError(apiResponse,dispatch);
      dispatch(fetchBlogCategoryError());
    }
  } catch (error) {
    toast.error(Globals.errorMsg);
    dispatch(fetchBlogCategoryError());
    throw error;
  }
};

// fetch blog dashboard categories
export const fetchBlogDashboardCategorySuccess = val => {
  const AllCategoryObject  = [{name: "All"}, ...val]

  return {
    type: ActionTypes.FETCH_BLOG_DASHBOARD_CATEGORY_SUCCESS,
    data: AllCategoryObject
  };
};
// handle api error
export const fetchBlogDashboardCategoryError = () => {
  return {
    type: ActionTypes.FETCH_BLOG_DASHBOARD_CATEGORY_ERROR
  };
};
export const fetchBlogDashboardCategory = () => async dispatch => {
  try {
    dispatch({
      type: ActionTypes.FETCH_BLOG_DASHBOARD_CATEGORY_INIT,
    });
    const apiResponse = await apiCall(
      `${Globals.API_ROOT_URL}blogs/category_dashboard`,
      "get"
    );
    if (apiResponse !== undefined && apiResponse.status === 200) {
      dispatch(fetchBlogDashboardCategorySuccess(apiResponse.data.data));
    } else {
      handleError(apiResponse,dispatch);
      dispatch(fetchBlogDashboardCategoryError());
    }
  } catch (error) {
    toast.error(Globals.errorMsg);
    dispatch(fetchBlogDashboardCategoryError());
    throw error;
  }
};