import {
  CardContent, Typography
} from "@mui/material";
import Tabs from "@mui/material/Tabs";
import { makeStyles, useTheme } from "@mui/styles";
import { Box } from "@mui/system";
import { CustomButton } from "assets/jss/ButtonStyle";
import { SampleFilePaper, SampleFileParentBox, StyledAtag, StyledTextareaAutosize, TopHeadingPaper, UploadCardParent, UploadStyledAppBar } from "assets/jss/GeneralStyle";
import portfolioPageStyle from "assets/jss/material-kit-react/views/portfolioPage";
import { PortUploadTab } from "assets/jss/PortfolioStyle";
import { CalCardActions } from "assets/jss/RetirementStyle";
import CustomReactDropzone from "components/CommonComponent/CustomReactDropzone";
import moment from "moment";
import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import SwipeableViews from "react-swipeable-views";
import { toast } from "react-toastify";
import { submitHomePurch, submitHomePurchSuccess } from "redux/actions/HomeCalActions";
import { a11yProps, parseCsv, parseExcel } from "utils/Utils";
import PortfolioTabPanel from "views/PortfolioValueUpload/PortfolioTabPanel";
const useStyles = makeStyles(portfolioPageStyle);
const HomePurchaseUpload = (props) => {
  const requiredFields = ["Price", "Size","Monthly Fees /Taxes / Insurance","Condition","View","Location"];
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const homecal = useSelector((state) => state.homecal);
  const [fileLists, setFileLists] = useState([]);
  const [disableUploadButton, setDisableUploadButton] = useState(false);
  const [resetDropzoneValue, setResetDropzoneValue] = useState(false);
  const [
      stringCsv,
      setStringCsv
  ] = useState(`Favorites,Date,Address,State,Zipcode,Price,Size,Listing status,Beds,Baths,Year, Floor ,Comments,Monthly Fees /Taxes / Insurance,Condition,View,Location,Viewing status
,,413 grand F1704,NY,10002,1150000,1000,Subect,3,2,2020,3,,500,2,9,5,viewed
Yes,,264 East Broadway C1206,NY,10002,1170000,1000,In Contract,1,3,2020,4,,500,3,9,5,viewed
Yes,07/29/2019,J1106 - 210 East Broadway,NY,10002,1086000,1000,Sold,2,2,2019,11,Not great condition,500,4,8,5,NA
,05/17/2019,G704 - 210 East Broadway,NY,10002,1180000,1000,Sold,2,1,2019,7,Not in Streeteasy,500,5,7,5,NA
,04/22/2019,C507 - 264 East Broadway,NY,10002,1250000,1000,Active,1,1,2019,5,Excellent condition,500,6,6,5,NA
`);

  useEffect(() => {
    if (
      (fileLists.length === 0 && value === 1) ||
      (stringCsv === "" && value === 0)
    ) {
      setDisableUploadButton(true);
    } else {
      setDisableUploadButton(false);
    }
  }, [fileLists, stringCsv]);

  /**
   * handle the tab switch view
   * @param {*} event
   * @param {*} newValue
   */
  const handleTabChange = (event, newValue) => {
    setFileLists([]);
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };
  /**
   * get the selected file from dropzone
   * @param {*} fileList
   */
  const getListOfFiles = (fileList) => {
    setFileLists(fileList);
  };
  
  const passFileToParse = async (
    tabIndex,
    file,
    localDeletedData,
    parsedResult,
    skipParsed = false
  ) => {
    let localRes = {};
    const localSkipParsed = skipParsed;
    if (
      file.path &&
      (file.path.match(/.*\.xlsx?$/g) ||
        file.type === "application/vnd.ms-excel")
    ) {
      localRes = await parseExcel(file);
    } else {
      if (!localSkipParsed) {
        localRes = await parseCsv(file);
      } else {
        localRes = file;
      }
    }
    if (localRes.data.length === 0) {
      toast.error("Please enter valid data.");
      return;
    }
    // let reg = "";
    // if (tabIndex === 0) {
    //   reg = new RegExp(localRes.fileName, "g");
    // } else {
    //   reg = new RegExp("^[" + localRes.fileName + "]+$", "g");
    // }
    validateData(localRes, localDeletedData, parsedResult);
  };
  /**
   * convert excel string format to json format
   * @param {*} data
   * @returns
   */
  const convertStringExcelToJson = (data) => {
    let jsonData = [];
    let rows = data.split("\n");
    const header = [];
    for (var rowIndex in rows) {
      if (rowIndex !== undefined) {
        let cells = rows[rowIndex].split("\t");
        if (parseInt(rowIndex, 10) !== 0) {
          jsonData[rowIndex - 1] = {};
        }
        for (let cellIndex in cells) {
          if (rowIndex === 0) {
            header.push(cells[cellIndex]);
          } else {
            jsonData[rowIndex - 1][header[cellIndex]] =
              cells[cellIndex] || null;
          }
        }
      }
    }
    return { data: jsonData, fileName: "Text Data" };
  };
  const validateData = (localRes, localDeletedData, parsedResult) => {
    let responseLocal = localRes;
    const timeStamp = moment().format("x");
    // to add number of occurance file after the file name to make it unique name
    const currFileName = responseLocal.fileName.split(".");
    const fileExt =
      currFileName.length > 1
        ? "." + currFileName[currFileName.length - 1]
        : "";
    const fileNameWithoutExt = responseLocal.fileName.replace(fileExt, "");
    responseLocal = {
      ...responseLocal,
      fileName: `${fileNameWithoutExt}_${timeStamp}${fileExt}`,
    };
    const localFileData = JSON.parse(JSON.stringify(responseLocal));
    let arrToSend = [...new Set(responseLocal.data)];
    for (
      let parsedIndex = 0;
      parsedIndex < responseLocal.data.length;
      parsedIndex++
    ) {
      let eachData = responseLocal.data[parsedIndex];
      let objToPass = {};
      const isRequiredIncluded = requiredFields.some((val) => {
        if (eachData[val] === undefined || eachData[val] === null) {
          return val;
        }
        // validating the field data typezv b
        switch (val.toLowerCase()) {
          case "date":
            return !eachData[val].toString().match(/^[0-9]{4}(?:(\/|-|\.))(1[0-2]|0[1-9])(?:(\/|-|\.))(3[01]|[12][0-9]|0[1-9])$/g);
          case "state":
            return !eachData[val].toString().match(/^(a-zA-Z){0,2}$/g);
          case "listing status":
            return !eachData[val].toString().match(/^(a-zA-Z){0,30}$/g);
          case "zipcode":
            return !eachData[val].toString().match(/^[0-9]{0,6}$/g);
          case "beds":
          case "baths":
          case "floor":
          case "condition":
          case "view":
          case "location":
            return !eachData[val].toString().match(/^[0-9]{0,2}$/g);
          case "year":
            return !eachData[val].toString().match(/^[0-9]{0,4}$/g);
          case "favorites":
            return !eachData[val].toString().match(/^(?:tru|fals)e$/gi);
          case "price":
          case "monthly fees/taxes/ insuracnse":
          case "size":
            return !eachData[val].toString().match(/^[0-9,]*?(\.[0-9]{1,2})?$/g);
          // case "Description":
          //   return !eachData[val].toString()?.trim()
          default:
            return;
        }
      });
      const indexToAppedAt = arrToSend.findIndex(
        (x) => JSON.stringify(x) === JSON.stringify(eachData)
      );
        objToPass = {
          ...objToPass,
          favorites: eachData["Favorites"] || false,
          sold_listed_date:  (eachData["Date"] && moment(eachData["Date"], "MM/DD/YYYY").format("YYYY/MM/DD")) || null,
          address: eachData["Address"],
          state: eachData["State"],
          zipcode: eachData["Zipcode"],
          price: eachData["Price"]?.toString().replace(/[, ]+/g, " ").trim(),
          size: Math.round(eachData["Size"]?.toString().replace(/[, ]+/g, " ").trim()),
          beds: eachData["Beds"] || undefined,
          baths: eachData["Baths"] || undefined,
          year: eachData["Year"],
          floor: eachData["Floor"] || undefined,
          comments: eachData["Comments"] || undefined,
          fees_tax_insu_monthly: eachData["Monthly Fees /Taxes / Insurance"]
            ?.toString()
            .replace(/[, ]+/g, " ")
            .trim(),
          condition: eachData["Condition"],
          view: eachData["View"],
          location: eachData["Location"],
          viewing_status: eachData["Viewing status"] || undefined,
          property_link : eachData["Property Link"],
          listing_status : eachData["Listing status  (Active/Sold/In Contract)"],
          parking: eachData["Parking"],
          amenities: eachData["Amenities"],
          agent_name: eachData["Agent name"],
          neighboar_hood: eachData["Neighbourhood"],
        };
      if (eachData["Listing status"]) {
        const optionList = {
          'In Contract': "inContract",
          Sold: "sold",
          Active: "active",
        };
        Object.keys(optionList).forEach((opval) => {
          const regexPattern = new RegExp(opval, "g");
          if (
            eachData["Listing status"].toString().toLowerCase().replaceAll(" ", "").match(regexPattern)
          ) {
            objToPass = {
              ...objToPass,
              listing_status: optionList[opval],
            };
          }
        });
      }
      
      arrToSend[indexToAppedAt] = {
        ...objToPass,
      };
      // save error rows in state and remove from main array of data
      if (isRequiredIncluded) {
        localDeletedData[responseLocal.fileName] =
          localDeletedData[responseLocal.fileName] || [];
        localDeletedData[responseLocal.fileName].push(eachData);
        localFileData.data.splice(parsedIndex, 1);
        arrToSend.splice(indexToAppedAt, 1);
      }
    }
    if (arrToSend.length > 0) {
      parsedResult.push({
        fileName: localFileData.fileName,
        data: arrToSend,
      });
    }
  };
  useEffect(() => {
    // reset all state flag whenever form is submitted
    if (homecal?.submitSuccess) {
       setFileLists([]);
       setResetDropzoneValue(!resetDropzoneValue);
      props.dispatch(submitHomePurchSuccess(false));
    }
  }, [homecal?.submitSuccess]);
  /**
   * save selected file with validating data
   * @param {*} e
   */
  const handleParseFile = async (e) => {
    let localDeletedData = {};
    let parsedResult = [];
    if (disableUploadButton) {
      toast.error("Please select file to upload.");
      return;
    }
    switch (value) {
      //text data
      case 0:
        var fileData = "";
        var skipParsed = false;
        if (stringCsv.split(",").length === 1) {
          fileData = convertStringExcelToJson(stringCsv);
          skipParsed = true;
        } else {
          fileData = stringCsv;
        }
        await passFileToParse(0,fileData,localDeletedData,parsedResult,skipParsed);
        break;
      case 1:
        //local files
        for (let index = 0; index < fileLists.length; index++) {
          const file = fileLists[index];
          await passFileToParse(1, file, localDeletedData, parsedResult);
        }
        
        break;
      default:
        break;
    }
    if (Object.keys(parsedResult).length > 0) {
      props.dispatch(submitHomePurch(parsedResult,"post"));
    }
    setFileLists([]);
    setResetDropzoneValue(!resetDropzoneValue);
    if (Object.keys(localDeletedData).length > 1) {
      return toast.error(<div className={classes.deletedFileDetail}>
        Below rows are removed due to missing required fields ({requiredFields.toString()}),<br />
        <br />
        {Object.keys(localDeletedData).map((val, deletedIndex) => {
          return (
            <div style={{ wordBreak: 'break-all' }} key={ deletedIndex+'alertPopup'}>
              {deletedIndex + 1}. {val} :{" "}
              {localDeletedData[val].length % 2
                ? localDeletedData[val].length + " row"
                : localDeletedData[val].length + " rows"}
            </div>
          );
        })}</div>, { autoClose: false })
    }

  };
  return (
      <>
          <TopHeadingPaper elevation={2}>
              <Typography variant="h6" sx={{ mb: 2, display: 'flex' }} gutterBottom className="top-heading-text">
                  Input Form
              </Typography>
          </TopHeadingPaper>

          <UploadCardParent elevation={2}>
              <CardContent>
                  <UploadStyledAppBar position="static" color="default">
                      <Tabs
                          value={value}
                          onChange={handleTabChange}
                          indicatorColor="primary"
                          textColor="primary"
                          // variant="scrollable"
                          allowScrollButtonsMobile={true}
                          aria-label="full width tabs example"
                      >
                          <PortUploadTab label="Text Data" {...a11yProps(0)} />
                          <PortUploadTab label="Local File(s)" {...a11yProps(1)} />
                      </Tabs>
                  </UploadStyledAppBar>
                  <SwipeableViews axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'} index={value} onChangeIndex={handleChangeIndex}>
                      <PortfolioTabPanel value={value} index={0} dir={theme.direction}>
                          <StyledTextareaAutosize
                              className={classes.textarea}
                              aria-label="minimum height"
                              minRows={20}
                              placeholder="String input"
                              defaultValue={stringCsv}
                              onChange={(e) => setStringCsv(e.target.value)}
                          />
                          <Box mt={'30px'}>
                              <Typography variant="h6">{'Note:'}</Typography>
                              <Typography variant="body1">
                                  1. You can copy and paste your csv file text or excel file text in above box.
                              </Typography>
                              <Typography variant="body1">2. Only 5 files can be saved.</Typography>
                          </Box>
                      </PortfolioTabPanel>
                      <PortfolioTabPanel value={value} index={1} dir={theme.direction}>
                          <CustomReactDropzone
                              accept="text/csv, .csv,application/vnd.ms-excel,.xlsx,.xls"
                              handleDroppedFiles={getListOfFiles}
                              resetDropzoneValue={resetDropzoneValue}
                          />
                          <Box mt={'30px'}>
                              <Typography variant="h6">{'Note:'}</Typography>
                              <Typography variant="body1">1. Acceptable file types: CSV, Excel(.xls,.xlsx)</Typography>
                              <Typography variant="body1">2. Only 5 files can be saved.</Typography>
                          </Box>
                          {fileLists.length === 0 ? (
                              <>
                                  <Typography gutterBottom id="sampleTitle" color={'secondary'}>
                                      Please download the sample CSV / Excel file
                                  </Typography>
                                  <SampleFileParentBox>
                                      <SampleFilePaper>
                                          <Box>
                                              <StyledAtag href="/CSVSample/Home_Purchase_Input.csv" download>
                                                  <img src="img/csv-file-icon.svg" alt='TPE csv file'/>
                                              </StyledAtag>
                                              <Typography variant="body1" textAlign={'center'} m="10px auto">
                                                  CSV Sample
                                              </Typography>
                                          </Box>
                                      </SampleFilePaper>
                                      <SampleFilePaper>
                                          <Box>
                                              <StyledAtag href="/CSVSample/Home_Purchase_Input.xls" download>
                                                  <img src="img/excel-file-icon.svg" alt='TPE excel file'/>
                                              </StyledAtag>
                                              <Typography variant="body1" textAlign={'center'} m="10px auto">
                                                  Excel Sample
                                              </Typography>
                                          </Box>
                                      </SampleFilePaper>
                                  </SampleFileParentBox>
                              </>
                          ) : (
                              ''
                          )}
                      </PortfolioTabPanel>
                  </SwipeableViews>
                  <CalCardActions className="contactus-cardaction">
                      <CustomButton
                          type="submit"
                          onClick={handleParseFile}
                          disabled={disableUploadButton}
                          className={'success-btn'}
                          variant="contained"
                      >
                          Upload
                      </CustomButton>
                  </CalCardActions>
              </CardContent>
          </UploadCardParent>
      </>
  );
};

export default HomePurchaseUpload;
