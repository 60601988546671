import { Card, CardContent, Dialog, DialogContent, Divider, Grid, IconButton, Paper, Tooltip, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import NoDataFound from 'components/CommonComponent/NoDataFound';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { submit_portfolio_allocation_admin_Success } from 'redux/actions/SettingActions';
import RenderField from 'utils/RenderField';
import CustomDialogTitle from 'components/DialogTitle';
import { CustomButton } from 'assets/jss/ButtonStyle';
import { submit_portfolio_allocation_admin } from 'redux/actions/SettingActions';
import { getPortfolioAllocation } from 'redux/actions/SettingActions';

const PortfolioAllocationfunds = (props) => {
    const { classes, setting } = props;
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const {
        handleSubmit,
        control,
        reset,
        formState: { isSubmitting },
        setValue
    } = useForm({
        mode: 'onChange'
    });

    // submit the form
    const onSubmit = (data) => {
        const type = data.id ? 'put' : 'post';
        const objToSend = {
            Symbol: data?.Symbol,
            Broad_Type: data?.Broad_Type,
            Narrow_Type: data?.Narrow_Type,
            Asset_Class_Alloc: parseFloat(data?.Asset_Class_AllocUnFormattedValue || data?.Asset_Class_Alloc),
            id: data?.id || undefined
        };

        dispatch(submit_portfolio_allocation_admin(objToSend, type));
    };
    useEffect(() => {
        if (setting.portfolioAllocationFormDialog) {
            handleClose();
            dispatch(submit_portfolio_allocation_admin_Success(false));
        }
    }, [setting.portfolioAllocationFormDialog]);

    useEffect(() => {
        dispatch(getPortfolioAllocation());
    }, []);
    const handleClickOpen = () => setOpen(true);

    // // delete selected ticker module
    const deleteSelectedData = (id) => dispatch(submit_portfolio_allocation_admin({ id: id }, 'delete'));

    // // enable to edit ticker module
    const handleEditData = (val) => {
        setOpen(true);
        for (const key in val) {
            if (Object.hasOwnProperty.call(val, key)) {
                let element = val[key];
                setValue(key, element);
            }
        }
    };

    // //close popup
    const handleClose = () => {
        reset({
            Symbol: '',
            Broad_Type: '',
            Narrow_Type: '',
            Asset_Class_Alloc: ''
        });
        setOpen(false);
    };

    return (
        <>
            <Card elevation={2}>
                <CardContent>
                    <Tooltip title="Add" arrow>
                        <CustomButton
                            variant="text"
                            className="read-more"
                            startIcon={<AddIcon />}
                            aria-label="add"
                            onClick={handleClickOpen}
                        >
                            Add New Data
                        </CustomButton>
                    </Tooltip>
                    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                        <CustomDialogTitle id="form-dialog-title" onClose={handleClose}>
                            Add New Ticker
                        </CustomDialogTitle>
                        <Divider />
                        <DialogContent>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <RenderField
                                            name="Symbol"
                                            control={control}
                                            label={'Symbol'}
                                            id={'Symbol'}
                                            type={'text'}
                                            required={true}
                                            placeholder={'Enter Symbol'}
                                            setValue={setValue}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <RenderField
                                            name="Broad_Type"
                                            control={control}
                                            label={'Broad Type'}
                                            id={'Broad_Type'}
                                            required={true}
                                            type={'text'}
                                            placeholder={'Enter Broad Type'}
                                            setValue={setValue}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <RenderField
                                            name="Narrow_Type"
                                            control={control}
                                            label={'Narrow Type'}
                                            id={'Narrow_Type'}
                                            required={true}
                                            type={'text'}
                                            placeholder={'Enter Narrow Type'}
                                            setValue={setValue}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <RenderField
                                            type="number"
                                            control={control}
                                            name="Asset_Class_Alloc"
                                            id="Asset_Class_Alloc"
                                            label="Asset Class Allocation"
                                            required
                                            placeholder="Enter Asset Class Allocation"
                                            setValue={setValue}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container direction="row" style={{ width: '100%', textAlign: 'center' }}>
                                    <Grid item xs={12}>
                                        <CustomButton type="submit" variant="contained" disabled={isSubmitting}>
                                            Submit
                                        </CustomButton>
                                    </Grid>
                                </Grid>
                            </form>
                        </DialogContent>
                    </Dialog>

                    {setting.portfolioAllocationData.length > 0 ? (
                        <Card>
                            <CardContent className="tlcardcontent">
                                <Grid container spacing={2} className="tlContainer">
                                    <Grid item xs={12}>
                                        <Grid container spacing={0}>
                                            <Grid item xs={12} sm={2}>
                                                <Typography variant="h6">Actions</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={2} textAlign="center">
                                                <Typography variant="h6">Symbol</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={2} textAlign="center">
                                                <Typography variant="h6">Broad Type</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={3} textAlign="center">
                                                <Typography variant="h6">Narrow Type</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={3} textAlign="center">
                                                <Typography variant="h6">Asset Class Allocation</Typography>
                                            </Grid>
                                        </Grid>
                                        <Divider />
                                        {setting.portfolioAllocationData.map((val, index) => {
                                            return (
                                                <Grid
                                                    container
                                                    key={val.id}
                                                    style={{
                                                        backgroundColor: index % 2 === 0 ? 'rgb(233 233 233)' : 'white',
                                                        padding: '10px',
                                                        transition: 'background-color 0.4s ease'
                                                    }}
                                                >
                                                    <Grid item xs={12} sm={2}>
                                                        <Tooltip title="Delete">
                                                            <IconButton aria-label="delete" onClick={() => deleteSelectedData(val.id)}>
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip title="Edit">
                                                            <IconButton aria-label="edit" onClick={(e) => handleEditData(val)}>
                                                                <CreateIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Grid>
                                                    <Grid item xs={12} sm={2}>
                                                        <Typography component="p" textAlign={'center'}>
                                                            {val.Symbol}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={2}>
                                                        <Typography component="p" textAlign={'center'}>
                                                            {val.Broad_Type}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={3}>
                                                        <Typography component="p" textAlign={'center'}>
                                                            {val.Narrow_Type}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={3}>
                                                        <Typography component="p" textAlign={'center'}>
                                                            {val.Asset_Class_Alloc}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            );
                                        })}
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    ) : (
                        <NoDataFound msg="Please add new module to display here." />
                    )}
                </CardContent>
            </Card>
        </>
    );
};

export default PortfolioAllocationfunds;
