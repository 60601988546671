import { Tooltip as MuiTooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Label } from 'recharts';
import InfoIcon from '@mui/icons-material/Info';

const CustomXAxisLabel = ({ viewBox, chartWidth }) => {
    const centerX = chartWidth / 2;

    return (
        <g transform={`translate(${centerX}, ${viewBox.y + 40})`}>
            <text textAnchor="middle" fill="#000">
                Scenario Multiplier
            </text>
            <MuiTooltip title="These multipliers apply to the monthly payments." arrow>
                <svg x={90} y={-18} width="24" height="24" style={{ cursor: 'pointer', color: 'rgb(39, 169, 224)' }}>
                    <InfoIcon fontSize="small" />
                </svg>
            </MuiTooltip>
        </g>
    );
};

const formatValue = (value) => {
    if (value >= 1e9) {
        return `${(value / 1e9).toFixed(1)}B`;
    } else if (value >= 1e6) {
        return `${(value / 1e6).toFixed(1)}M`;
    } else if (value >= 1e3) {
        return `${(value / 1e3).toFixed(1)}K`;
    }
    return value;
};

const BarCreditCardChart = ({ credit, keyy }) => {
    const [data, setData] = useState([]);
    const [chartWidth, setChartWidth] = useState(window.innerWidth * 0.8);

    useEffect(() => {
        const handleResize = () => {
            setChartWidth(window.innerWidth * 0.8);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        handleData();
    }, []);

    const handleData = () => {
        let arr = [];
        const minLength = Math.floor(credit['Multiplier'].length, credit[keyy].length);
        for (let i = 0; i < minLength; i++) {
            let obj = {
                index: credit['Multiplier'][i],
                [keyy]: credit[keyy][i]
            };
            arr.push(obj);
        }
        setData(arr);
    };
    return (
        <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <BarChart width={chartWidth} height={300} data={data} margin={{ top: 20, bottom: 10, left: 10, right: 10 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="index" height={60}>
                    <Label content={<CustomXAxisLabel chartWidth={chartWidth} />} position="insideBottom" />
                </XAxis>
                <YAxis tickFormatter={formatValue} />
                <Tooltip />
                <Legend />
                <Bar dataKey={keyy} fill="#FFA412" />
            </BarChart>
        </div>
    );
};

export default BarCreditCardChart;
