import { MainBox } from 'assets/jss/GeneralStyle';
import Loader from 'components/CommonComponent/Loader';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import ContactUsBanner from './ContactUsBanner';
import ContactUsForm from './ContactUsForm';
import FinbotBlock from './FinbotBlock';
import Meta from 'components/meta';
export default function ContactUs(props) {
    const general = useSelector((state) => state.generalCal);
    const [refVal, setRefVal] = useState(null);
    const handleRef = (currref) => {
        if (refVal === null) {
            setRefVal(currref);
        }
    };
    const handleScrollBtn = () => {
        refVal.current.scrollIntoView();
    };
    return (
        <>
            <MainBox>
                <Meta
                    title="Contact Us | TPE Hub"
                    description="Get in touch with the TPE Hub team. Find our contact information, office location, and ways to reach us for inquiries, support, and more."
                    page="/contact-us"
                />
                <ContactUsBanner handleScrollBtn={handleScrollBtn} />
                <FinbotBlock passRef={handleRef} />
                {general.loader ? <Loader /> : ''}
                <ContactUsForm />
            </MainBox>
        </>
    );
}
