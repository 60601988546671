import { styled } from '@mui/system';
import { Box, Button, Card, CardContent, FormControl} from '@mui/material';
export const FooterBox = styled(Box)(({ boxheight, theme }) => ({
           textAlign: 'center',
           minHeight: boxheight || 260,
           [theme.breakpoints.down('sm')]: {
               padding:'0 15px',
           },
           '& .dashboard-subheading': {
               fontWeight: 500,
               textTransform: 'capitalize',
               lineHeight: '25px',
               display: 'block'
           },
           '& .first-half': {}
       }));
export const FooterCardContent = styled(CardContent)(({ theme }) => ({
           textAlign: 'center',
           minHeight: 300,
           '& .dashboard-subheading': {
               fontWeight: 500,
               textTransform: 'capitalize',
               lineHeight: '25px',
               display: 'block'
           },
           '& .grid-container': {
               justifyContent: 'center',
           }
       }));
export const GetStartedBtn = styled(Button)(({ theme }) => ({
    "& .btn-divider":{
        height:'auto',marginRight:5
    },
    border:'1px solid'
}));
export const FooterCard = styled(Card)(({ theme }) => ({
           borderRadius: 0,
           background: '#DAEBFF',
           display: 'flex',
           justifyContent: 'center',
           '& .todo-image': {
               width: 'unset',
               objectFit: 'none',
               marginTop: 'auto',
               [theme.breakpoints.down('sm')]: {
                width:'100%',
                objectFit:'cover'
               }
           },
           [theme.breakpoints.down('sm')]: {
            display:'block'
           }
       }));
export const FooterLastBox = styled(Box)(({ theme }) => ({
           margin: '32px 160px',
           textAlign: 'center',
           '& .grid-container': {
               height: 'auto',
               //    height:'inherit',
               justifyContent: 'space-between',
               margin: 0
           },
           '& .footer-subtext': {
               fontWeight: theme.typography.fontWeightRegular,
               marginTop: 12
           },
           '& .social-icons': {
               padding: 0,
               button: {
                   padding: 6
               }
           },
           '& .MuiGrid-root': {
               height: 'auto',
               [theme.breakpoints.between('md', 'lg')]: {
                   img: {
                       width: '100%',
                       height: 'auto'
                   }
               },
               [theme.breakpoints.down('md')]: {
                width:'fit-content'
               }
           },
           '& .menu-grid-item': {
               '& .list-item li': {
                   margin: 0,
                   textAlign: 'center',
                   '& .MuiButtonBase-root': {
                       padding: 0,
                       textAlign: 'inherit'
                   }
               }
           },
           '& .subscribe-gird-item': {
               textAlign: 'left',
               [theme.breakpoints.down('md')]: {
                   textAlign: 'center'
               }
           },
           [theme.breakpoints.down('md')]: {
                  margin: '32px 5px'
           },
           [theme.breakpoints.down('sm')]: {
               //    margin: 20
               margin: 0
           }
       }));
export const FooterFormControl = styled(FormControl)(({ theme }) => ({
    margin:'0 !important',
    "& .MuiInputBase-root":{
        padding:0,
        height:32,
        'input':{
            padding:6
        },
        'button':{
            height:32,
            padding:5
        }
    }
         
       }));
export const ScrollableEmailSignupBox = styled(Box)(({ theme }) => ({
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    display: 'flex',
    zIndex: theme.zIndex.drawer,
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.1)',
    '& .inner-box': {
        textAlign: 'center'
    },
    '& .MuiInputBase-root': {
        marginBottom: '8px'
    }
}));
export const DisclaimerBox = styled(Box)(({ theme }) => ({
    border: `1px solid ${theme.palette.text.dark}`,
    borderRadius: theme.borderRadius.round,
    margin: '0px -140px 10px -140px',
    padding: '15px',
    textAlign: 'justify',
    [theme.breakpoints.down('md')]: {
        margin: '0 0 5px',
        '& p': {
            fontSize: theme.typography.cardSubHeader.fontSize
        }
    }
}));
