import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import { CustomButton } from 'assets/jss/ButtonStyle';
import moment from 'moment';
import { RatingDiv } from 'assets/jss/CalculatorStyle';
import { Tooltip } from '@mui/material';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4
};

export default function ReadMoreRating({ data }) {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <>
            <CustomButton onClick={handleOpen}>Read More</CustomButton>
            <Modal
                keepMounted
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <Typography variant="h6" component="h2" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            {data.name}
                            <Box className="rating-footer">
                                <RatingDiv name="simple-controlled" value={data.rate} />
                                {/* <FacebookRoundedIcon /> */}
                            </Box>
                        </Typography>
                        {/* <Tooltip
                            TransitionComponent={Fade}
                            TransitionProps={{ timeout: 600 }}
                            title={moment(data.time).format('MM/DD/YYYY HH:mm:ss')}
                        >
                            <Typography gutterBottom variant="cardHeader" component="div" sx={{ mb: 2 }}>
                                {moment(data.time, 'MM/DD/YYYY h:mm:ss').fromNow()}
                            </Typography>
                        </Tooltip>{' '} */}
                        <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                            {data.message}
                        </Typography>
                    </Box>
                </Fade>
            </Modal>
        </>
    );
}
