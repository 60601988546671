import { makeStyles } from '@mui/styles';
import { Container, createStyles, Grid, Typography } from '@mui/material';
import styles from 'assets/jss/material-kit-react/views/generalPage';
import { Link as RouterLink } from 'react-router-dom';
import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import CommonCalculator from 'components/CommonComponent/CommonCalculator.js';
import Loader from 'components/CommonComponent/Loader.js';
import PropertyInvestForm from './PropertyInvestForm';
import PropertyInvestOuput from './PropertyInvestOutput';
import CalDescription from 'CalDescription';
import { MainBox } from 'assets/jss/GeneralStyle';
import PropertyInvestDesc from './PropertyInvestDesc';
import { CustomListItem } from 'assets/jss/HeaderStyle';
import { CustomListItemText } from 'assets/jss/HeaderStyle';
import { WidgetBox } from 'assets/jss/GeneralStyle';
import Meta from 'components/meta';

const mergedStyle = makeStyles((theme) =>
    createStyles({
        ...styles(theme)
    })
);
export default function PropertyInvestIndex(props) {
    const generalCal = useSelector((state) => state.generalCal);

    const typographyRef = useRef(null);
    useEffect(() => {
        // Set the ID after the component mounts
        if (typographyRef.current) {
            typographyRef.current.id = 'propCal';
        }
    }, []);

    const classes = mergedStyle();
    const render = () => {
        return (
            <>
                {generalCal.propertyInvestData.loader ? <Loader /> : ''}
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4.5} className="widget-grid-item">
                        <PropertyInvestForm classes={classes} propertyInvestData={generalCal.propertyInvestData?.calData} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={7.5} className="widget-grid-item">
                        <PropertyInvestOuput data={generalCal.propertyInvestData?.calData} />
                    </Grid>
                </Grid>
            </>
        );
    };
    const breadcrumbs = [
        <CustomListItem className="cal-banner-menu" key="1" sx={{ m: 0 }} color="text.light">
            <CustomListItemText primary={'Home'} />
        </CustomListItem>,
        <CustomListItem className="cal-banner-menu" key="2" sx={{ m: 0 }} color="text.light">
            <CustomListItemText primary={'Real Estate'} />
        </CustomListItem>,
        <Typography ref={typographyRef} key="3" color="text.light" className="current-menu">
            Property Investment Tool
        </Typography>
    ];
    return (
        (props.widgetcal && <WidgetBox>{render()}</WidgetBox>) || (
            <>
               <Meta
                title="Property Investment Tool | TPE Hub"
                description="Discover how to maximize your real estate returns with our Property Investment Tool. Analyze potential investments, calculate profitability, and make informed decisions with our easy-to-use tool. Start optimizing your property investments today."
                page="/property-investment-tool"
            />
                <CommonCalculator
                    breadcrumbs={breadcrumbs}
                    variantText="Property Investment Tool"
                    desc={CalDescription['Investment Property Calculator']}
                />
                <MainBox>
                    <Container maxWidth="lg">
                        {render()}
                        <PropertyInvestDesc />
                    </Container>
                </MainBox>
            </>
        )
    );
}
