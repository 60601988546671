import { Box, Button, List, Paper, Typography } from '@mui/material';
import { styled } from '@mui/styles';
export const SyncTitleTypography = styled(Typography)(({ ps, theme }) => {
           return {
               padding: '10px 0 5px 0px'
           };
       });
export const BreadcrumbsAddNewBox = styled(Box)(({ ps, theme }) => {
    return {
        display:'flex',
        "& .add-new-list":{
            margin:'auto 0'
        }
    };
});
export const WatchlistListParent = styled(List)(({ ps, theme }) => {
    return {
        width:200
    };
});
export const RangeButton = styled(Button)(({ ps, theme }) => {
    return {
        "&.selected":{
            backgroundColor:theme.palette.primary.light
        }
    };
});
export const WatchListChartPaper = styled(Paper)(({ ps, theme }) => {
    return {
        '& .back-button': {
            color: theme.palette.text.primary,
            margin: '15px 20px 0'
        }
    };
});
export const ZoomSliderBox = styled(Box)(({ ps, theme }) => {
    return {
        width:'inherit',
        height:'inherit',
        display:'flex',
        '& #yaxis-zoom-slider':{
            marginTop:10,
            height:'85%',
            "& .MuiSlider-valueLabel":{
                right:'unset',
                left:'30px',
                "&:before":{
                    left:'0',
                    right:'unset'
                }
            }
        }
    };
});
