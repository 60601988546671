import { styled } from '@mui/system';
import { Box, Card, CardActions, CardContent, IconButton, List, ListItemText, Paper } from '@mui/material';
import Grid from '@mui/material/Grid';

export const DashBox = styled(Box)(({ theme }) => ({
    textAlign: 'center',
    background: '#DAEBFF',
    minHeight: 300,
    //    minHeight: 442,
    //    marginBottom: 100,
    marginTop: 50,
    paddingBottom: 16,
    '& .title-box': {
        marginLeft: '200px',
        textAlign: 'left',
        marginBottom: 24,
        [theme.breakpoints.down('sm')]: {
            margin: '0'
        },
        [theme.breakpoints.down('md')]: {
            margin: '0 0 0 21px'
        }
    },
    '& .dashboard-subheading': {
        fontWeight: 500,
        textTransform: 'capitalize',
        lineHeight: '25px',
        display: 'block'
    },
    '& .grid-container': {
        justifyContent: 'space-between',
        width: 'inherit',
        margin: 'auto 100px',
        //    '& .MuiGrid-item:nth-of-type(even)': {
        //        margin: '0',
        //        marginTop: '160px',
        [theme.breakpoints.down('sm')]: {
            margin: 'auto'
        }
        //    }
    }
}));
export const DashCardBox = styled(Box)(({ theme }) => ({
    //    textAlign: 'center',
    //    margin: '0 100px',
    minHeight: '143px',
    //    display: 'flex',
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
        display: 'inline-block',
        margin: 0
    }
}));
export const DashSecondCardBox = styled(Box)(({ theme }) => ({
    //    textAlign: 'center',
    //    margin: '0 100px',
    minHeight: '143px',
    margin: 'auto',
    //    display: 'flex',

    [theme.breakpoints.down('sm')]: {
        display: 'inline-block'
    }
}));
export const DashCard = styled(Card)(({ row, bgcolor = false, theme }) => {
    return {
        // ...(row === 'second' ? { marginLeft: '12.5%' } : { marginRight: '12%' }),
        // width: '140px',
        width: 'inherit',
        display: 'inline-block',
        height: '144px',
        margin: '0 10%',
        background: bgcolor ? theme.palette.secondary.light : theme.palette.background.paper,
        //    boxShadow: '1.04499px 1.04499px 4.70244px rgba(0, 0, 0, 0.25)',
        //    borderRadius: '5.22493px',
        textAlign: 'center',
        '& .card-icon': {
            width: '25%',
            height: 'auto',
            margin: '18px auto'
        },
        '& .MuiCardContent-root': {
            padding: '0px 10px',
            lineHeight: 'normal',
            '&:last-child': {
                paddingBottom: 2
            }
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px',
            marginBottom: '6px'
            // ...(row === 'second' ? { marginLeft: '2%' } : { marginRight: '2%' })
        },
        '&.dashBlock': {
            cursor: 'pointer'
        }
    };
});
export const CustomImg = styled('img')(({ theme }) => ({
    width: '25%',
    height: 'auto',
    margin: '18px auto',
    maxHeight: '50px'
}));

export const DashGrid = styled(Grid)(({ disablepadd = false, row, theme }) => {
    return {
        width: '200px',
        // width: '140px',
        display: 'inline-block',
        height: '144px',
        padding: disablepadd ? '' : '0 !important',
        '& .MuiPaper-root': {
            margin: 0
        },
        [theme.breakpoints.down('md')]: {
            marginTop: 5
        },
        '&.calBlock': {
            width: 125,
            cursor: 'pointer'
        }
    };
});
export const LatestGrid = styled(Grid)(({ row, theme }) => {
    return {
        display: 'flex',
        '& .blog-image-box': {
            position: 'relative',
            '& .MuiButtonBase-root': {
                background: theme.palette.background.paper,
                position: 'absolute',
                right: 25,
                bottom: '-20px',
                boxShadow: '0px 1.52159px 2.28238px rgb(0 0 0 / 25%)'
            },
            '& .blog-image': {
                height: 'auto',
                maxHeight: '236px',
                minHeight: '236px',
                marginBottom: 10
            }
        },
        '@media (max-width: 320px)': {
            padding: '0 !important'
        }
    };
});

export const Item = styled(Paper)(({ theme }) => ({
    margin: '0',
    textAlign: 'center',
    color: theme.palette.text.secondary
}));
export const TodoBox = styled(Box)(({ theme }) => ({
    // marginBottom: 100,
    textAlign: 'center',
    minHeight: 530,
    '& .MuiCard-root': {
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            display: 'inline-block'
        }
    },
    '& .todo-image': {
        width: '100%',
        maxWidth: '489px',
        height: 'auto'
    }
}));
export const TodoListTag = styled(List)(({ theme }) => ({
    listStyle: 'disc',
    '& .MuiListItem-root': {
        display: 'list-item',
        padding: 0
    }
}));
export const TodoCardContent = styled(CardContent)(({ theme }) => ({
    textAlign: 'start',
    margin: 'auto 0',
    '&.finbot-content': {
        width: 282
    },
    '& .todo-grid-item': {
        padding: 0,
        display: 'contents'
    },
    '& .MuiGrid-container': {
        marginLeft: 12
    },
    '& .finbot-subheading': {
        lineHeight: '22px'
    }
}));
export const TodoListItemtext = styled(ListItemText)(({ theme }) => ({
    '& .MuiTypography-root': {
        lineHeight: '27px',
        fontSize: theme.typography.body1.fontSize,
        fontWeight: theme.typography.fontWeightMedium
    }
}));
export const LatestUpdateBox = styled(Box)(({ theme }) => ({
    minHeight: 398,
    height: 'auto',
    margin: '20px 300px',
    '& .title-div': {
        display: 'flex',
        justifyContent: 'space-between'
    },
    '& .grid-container': {
        margin: 0,
        width: '100%'
    },
    [theme.breakpoints.down('md')]: {
        margin: 0
    }
}));
export const LatestUpdateCard = styled(Card)(({ theme }) => ({
    '& .blog-date': {
        '&:before': {
            content: '""',
            marginRight: 10,
            borderLeft: `3px solid ${theme.palette.primary.main}`
        }
    },
    '& .read-more-box': {
        justifyContent: 'space-between'
    },
    '& .desc-card-content': {
        padding: 5
    }
}));
export const LatestUpdateCardAction = styled(CardActions)(({ theme }) => ({
    padding: 0
}));
export const AnimationTickerPaper = styled(Paper)(({ theme }) => ({
    // margin: "3px",
    height: '30px',
    position: 'relative',
    overflow: 'hidden',

    '& .tickerWrapper': {
        display: 'block',
        position: 'absolute',
        left: '32px',
        overflow: 'hidden',
        width: 'calc(100% - 32px)',
        height: '30px',
        WebkitBackfaceVisibility: 'hidden',
        backfaceVisibility: 'hidden'
    },
    '& .tickerWrapperInner': {
        position: 'absolute',
        width: '100%',
        height: '32px',
        display: 'table'
    }
}));
export const TickerHeader = styled('div')(({ theme }) => ({
    display: 'inline-block',
    position: 'absolute',
    zIndex: '1',
    backgroundColor: 'white',
    padding: '3px'
    //    boxShadow:theme.shadows[3]
}));
export const TickerValue = styled('div')(({ theme }) => ({
    display: 'inline-flex',
    marginTop: '-3px',
    '& .positiveVal': {
        fontSize: 14,
        color: 'green'
    },
    '& .postitiveTickerArr': {
        width: '0.5em',
        color: theme.palette.success.dark,
        margin: '-3px 0px 0 6px'
    },
    '& .negativeVal': {
        color: theme.palette.error.dark
    },
    '& .negativeTickerArr': {
        width: '0.5em',
        color: theme.palette.error.dark,
        margin: '-3px 0px 0 6px'
    }
}));
export const TickerList = styled(List)(({ theme }) => ({
    '&.firstTicker': {
        listStyle: 'none',
        position: 'absolute',
        display: 'inline-flex',
        WebkitTransform: 'translateX(0)',
        transform: 'translateX(0)',
        margin: '0',
        padding: '0',
        width: 'max-content'
    },
    '&.secondTicker': {
        listStyle: 'none',
        position: 'absolute',
        display: 'inline-flex',
        WebkitTransform: 'translateX(100%)',
        transform: 'translateX(100%)',
        margin: '0',
        padding: '0',
        width: 'max-content'
    },
    '&.firstTickerAnimation': {
        //    display: 'block',
        transition: '150s linear',
        WebkitAnimation: `animateFirstList 150s linear`,
        animation: `animateFirstList 150s linear`,
        WebkitAnimationIterationCount: 'infinite',
        animationIterationCount: 'infinite'
    },
    '&.secondTickerAnimation': {
        //    display: 'block',
        WebkitAnimation: `animateSecondList 150s linear`,
        animation: `animateSecondList 150s linear`,
        WebkitAnimationIterationCount: 'infinite',
        transition: '150s linear',
        animationIterationCount: 'infinite'
    },
    '& .MuiListItemText-root .MuiTypography-root': {
        display: 'inline-flex'
    },
    '&.playPauseAnimation': {
        animationPlayState: 'paused !important'
    },
    '& .liItems': {
        display: 'inline-flex',
        lineHeight: '30px',
        padding: '0 10px',
        fontFamily: 'BWHaasGrotesk-75Bold-Web,sans-serif',
        fontSize: '12px',
        color: '#000',
        textDecoration: 'none',
        width: 'auto',
        marginTop: '-18px',
        '& .ticker-icon': {
            marginTop: 14,
            height: 20,
            padding: 3,
            '& img': {
                width: 'inherit',
                height: 'inherit'
            }
        }
    },
    '& .tickerLabel': {
        fontWeight: '600'
    },
    '& .ticker-company-name': {
        width: 'max-content',
        display: 'flex',
        fontWeight: theme.typography.fontWeightMedium
    },
    '@-webkit-keyframes animateFirstList': {
        '0%': {
            '-webkit-transform': 'translateX(0)',
            transform: 'translateX(0)',
            opacity: 1
        },
        '50%': {
            '-webkit-transform': 'translateX(-100%)',
            transform: 'translateX(-100%)',
            opacity: 1
        },
        '50.1%': {
            '-webkit-transform': 'translateX(-100%)',
            transform: 'translateX(-100%)',
            opacity: 0
        },
        '50.2%': {
            '-webkit-transform': 'translateX(100%)',
            transform: 'translateX(100%)',
            opacity: 0
        },
        '50.3%': {
            '-webkit-transform': 'translateX(100%)',
            transform: 'translateX(100%)',
            opacity: 1
        },
        to: {
            '-webkit-transform': 'translateX(0)',
            transform: 'translateX(0)',
            opacity: 1
        }
    },
    '@keyframes animateFirstList': {
        '0%': {
            '-webkit-transform': 'translateX(0)',
            transform: 'translateX(0)',
            opacity: 1
        },
        '50%': {
            '-webkit-transform': 'translateX(-100%)',
            transform: 'translateX(-100%)',
            opacity: 1
        },
        '50.1%': {
            '-webkit-transform': 'translateX(-100%)',
            transform: 'translateX(-100%)',
            opacity: 0
        },
        '50.2%': {
            '-webkit-transform': 'translateX(100%)',
            transform: 'translateX(100%)',
            opacity: 0
        },
        '50.3%': {
            '-webkit-transform': 'translateX(100%)',
            transform: 'translateX(100%)',
            opacity: 1
        },
        to: {
            '-webkit-transform': 'translateX(0)',
            transform: 'translateX(0)',
            opacity: 1
        }
    },
    '@-webkit-keyframes animateSecondList': {
        '0%': {
            WebkitTransform: 'translateX(100%)',
            transform: 'translateX(100%)',
            opacity: '1'
        },
        '50%': {
            WebkitTransform: 'translateX(0)',
            transform: 'translateX(0)',
            opacity: '1'
        },
        '99.7%': {
            WebkitTransform: 'translateX(-100%)',
            transform: 'translateX(-100%)',
            opacity: '1'
        },
        '99.8%': {
            WebkitTransform: 'translateX(-100%)',
            transform: 'translateX(-100%)',
            opacity: '0'
        },
        '99.9%': {
            WebkitTransform: 'translateX(100%)',
            transform: 'translateX(100%)',
            opacity: '0'
        },
        to: {
            WebkitTransform: 'translateX(100%)',
            transform: 'translateX(100%)',
            opacity: '1'
        }
    },
    '@keyframes animateSecondList': {
        '0%': {
            WebkitTransform: 'translateX(100%)',
            transform: 'translateX(100%)',
            opacity: '1'
        },
        '50%': {
            WebkitTransform: 'translateX(0)',
            transform: 'translateX(0)',
            opacity: '1'
        },
        '99.7%': {
            WebkitTransform: 'translateX(-100%)',
            transform: 'translateX(-100%)',
            opacity: '1'
        },
        '99.8%': {
            WebkitTransform: 'translateX(-100%)',
            transform: 'translateX(-100%)',
            opacity: '0'
        },
        '99.9%': {
            WebkitTransform: 'translateX(100%)',
            transform: 'translateX(100%)',
            opacity: '0'
        },
        to: {
            WebkitTransform: 'translateX(100%)',
            transform: 'translateX(100%)',
            opacity: '1'
        }
    }
}));
export const PlayPauseIconButton = styled(IconButton)(({ theme }) => ({
    padding: 0,
    color: theme.palette.primary.main
}));
export const DownIconButton = styled(Box)(({ theme }) => ({
    position: 'absolute',
    zIndex: '100',
    cursor: 'pointer',
    margin: '0px 0 0 -40px',
    lineHeight: '60px',
    left: '50%',
    top: '200px',
    textAlign: 'center',
    textDecoration: 'none',
    textShadow: '0px 0px 3px rgba(0, 0, 0, 0.4)',
    WebkitAnimation: 'fade_move_down 2s ease-in-out infinite',
    MozAnimation: 'fade_move_down 2s ease-in-out infinite',
    animation: 'fade_move_down 2s ease-in-out infinite',
    svg: {
        fontSize: '3em'
    },
    '@-webkit-keyframes fade_move_down': {
        ' 0% ': { '-webkit-transform': 'translate(0,-20px)', opacity: 0 },
        '50% ': { opacity: 1 },
        '100% ': { '-webkit-transform': 'translate(0,20px)', opacity: 0 }
    },
    '@-moz-keyframes fade_move_down': {
        ' 0% ': { '-moz-transform': 'translate(0,-20px)', opacity: 0 },
        '50% ': { opacity: 1 },
        '100% ': { '-moz-transform': 'translate(0,20px)', opacity: 0 }
    },
    '@keyframes fade_move_down': {
        ' 0% ': { transform: 'translate(0,-20px)', opacity: 0 },
        '50% ': { opacity: 1 },
        '100% ': { transform: 'translate(0,20px)', opacity: 0 }
    },
    '&.aboutus-scroll': {
        bottom: 100,
        top: 'unset',
        color: theme.palette.background.paper
    }
}));
