import { container, title } from "assets/jss/material-kit-react.js";
import styles from "assets/jss/material-kit-react/views/componentsSections/typographyStyle.js";
const landingPageStyle = (theme) => ({
  container: {
    zIndex: "12",
    color: "#FFFFFF",
    ...container
  },
  title: {
    ...title,
    display: "inline-block",
    position: "relative",
    marginTop: "30px",
    minHeight: "32px",
    color: "#FFFFFF",
    textDecoration: "none"
  },
  subtitle: {
    fontSize: "1.313rem",
    maxWidth: "500px",
    margin: "10px auto 0"
  },
  main: {
    background: "#FFFFFF",
    position: "relative",
    zIndex: "3"
  },
  mainRaised: {
    margin: "-50px 30px 0px",
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
    [theme.breakpoints.down("xs")]: {
      margin: "8px 0 0 0",
    }
  },
  alignCenter: {
    textAlign: "center"
  },
  typography: {
    ...styles
  },
  bannerSigninButtons: {
    marginTop:15,
     [theme.breakpoints.down("sm")]: {
      margin: "auto"
     }
  },
  helpBoxesTitle: {
    color: "rgba(0, 0, 0, 0.87)",
    textAlign: "center",
    textTransform: "capitalize",
    marginTop: "0.35rem"
  },
  toolIcon: {
    width: "5.2rem",
    height: "84px",
    margin: "auto",
    top: "0.8rem",
    position: "relative"
  },
  toolContainer: {
    textAlign: "center"
  },
  toolTitleWrapper: {
    display: "inline-flex"
  },
  toolPaper: {
    marginBottom: "0.35em",
    minHeight: "143px",cursor:"pointer"
  },
  toolTitle: {
    marginBottom: "0.35em",
    marginTop: "0.9rem",
    fontSize: "1rem",
    fontWeight: "400",
    lineHeight: "1.5",
    letterSpacing: "0.00938em",
    textTransform: "revert",
    padding: "0.5rem"
  },
  tickerTitle: {
    display: "inline-flex",
    fontWeight: "600"
  },
  
 
 
  toolListItem: {
    [theme.breakpoints.down("sm")]: {
      "&:after": {
        backgroundColor: "transparent !important"
      }
    }
  },
  "@-webkit-keyframes animateFirstList": {
    "0%": {
      "-webkit-transform": "translateX(0)",
      transform: "translateX(0)",
      opacity: 1
    },
    "50%": {
      "-webkit-transform": "translateX(-100%)",
      transform: "translateX(-100%)",
      opacity: 1
    },
    "50.1%": {
      "-webkit-transform": "translateX(-100%)",
      transform: "translateX(-100%)",
      opacity: 0
    },
    "50.2%": {
      "-webkit-transform": "translateX(100%)",
      transform: "translateX(100%)",
      opacity: 0
    },
    "50.3%": {
      "-webkit-transform": "translateX(100%)",
      transform: "translateX(100%)",
      opacity: 1
    },
    to: {
      "-webkit-transform": "translateX(0)",
      transform: "translateX(0)",
      opacity: 1,
    }
  },
  "@keyframes animateFirstList": {
    "0%": {
      "-webkit-transform": "translateX(0)",
      transform: "translateX(0)",
      opacity: 1
    },
    "50%": {
      "-webkit-transform": "translateX(-100%)",
      transform: "translateX(-100%)",
      opacity: 1
    },
    "50.1%": {
      "-webkit-transform": "translateX(-100%)",
      transform: "translateX(-100%)",
      opacity: 0
    },
    "50.2%": {
      "-webkit-transform": "translateX(100%)",
      transform: "translateX(100%)",
      opacity: 0
    },
    "50.3%": {
      "-webkit-transform": "translateX(100%)",
      transform: "translateX(100%)",
      opacity: 1
    },
    to: {
      "-webkit-transform": "translateX(0)",
      transform: "translateX(0)",
      opacity: 1,
    }
  },
  "@-webkit-keyframes animateSecondList": {
    "0%": {
      WebkitTransform: "translateX(100%)",
      transform: "translateX(100%)",
      opacity: "1"
    },
    "50%": {
      WebkitTransform: "translateX(0)",
      transform: "translateX(0)",
      opacity: "1"
    },
    "99.7%": {
      WebkitTransform: "translateX(-100%)",
      transform: "translateX(-100%)",
      opacity: "1"
    },
    "99.8%": {
      WebkitTransform: "translateX(-100%)",
      transform: "translateX(-100%)",
      opacity: "0"
    },
    "99.9%": {
      WebkitTransform: "translateX(100%)",
      transform: "translateX(100%)",
      opacity: "0"
    },
    to: {
      WebkitTransform: "translateX(100%)",
      transform: "translateX(100%)",
      opacity: "1",
    }
  },
  "@keyframes animateSecondList": {
    "0%": {
      WebkitTransform: "translateX(100%)",
      transform: "translateX(100%)",
      opacity: "1"
    },
    "50%": {
      WebkitTransform: "translateX(0)",
      transform: "translateX(0)",
      opacity: "1"
    },
    "99.7%": {
      WebkitTransform: "translateX(-100%)",
      transform: "translateX(-100%)",
      opacity: "1"
    },
    "99.8%": {
      WebkitTransform: "translateX(-100%)",
      transform: "translateX(-100%)",
      opacity: "0"
    },
    "99.9%": {
      WebkitTransform: "translateX(100%)",
      transform: "translateX(100%)",
      opacity: "0"
    },
    to: {
      WebkitTransform: "translateX(100%)",
      transform: "translateX(100%)",
      opacity: "1",
    }
  },
 
  ticketContainer: {
    width: "100%",
    // position: "absolute !important",
    // margin: "73px auto !important",
    zIndex: 1
  },
  ticketGridItem: {
    padding: 0
  },
  bannerWrapper: { marginTop: 50 },
 insSliderLabel: {
    marginTop:30,
    [theme.breakpoints.down("sm")]: {
       marginTop:0
    }
  }, insSliderField: {
    marginTop:34
  }, descSummary: {
    overflow: 'hidden',
    cursor:"pointer",
    textOverflow: 'ellipsis',"&.trimDesc":{whiteSpace:'nowrap'}
  }, detailDesc: {
    //maxHeight: 100, overflow: 'hidden', overflowY: 'scroll'
    width:250,padding:17
  }, landingImgParal: {
    minHeight: "450px", height: "auto",
    [theme.breakpoints.down("sm")]: {
       minHeight: 630
    }
  }, landingContainer: {
    [theme.breakpoints.down("sm")]: {
       textAlign:'center'
    }
  }, subHeaderBanner: {
    "& .topheader": {
      [theme.breakpoints.down("xs")]: {
        fontSize: '1.5rem'
      }
    }, "& .subheader": {
      maxWidth: 'fit-content'

    }
  }, syncTitle: {
    padding:'10px 0 5px 20px'
  }, permissionDialogParent: {
    "& .MuiBackdrop-root": {
      top:83,
    }, "&.MuiDialog-root": {
      zIndex:"1000 !important"
    }
  }, dialogActionBtn: {
    justifyContent:"center"
  }, settingContainer: {
    minHeight:235
  }, amountSlider: {
    padding:"13px 22px 0 22px"
  }, annuityAmountLbl: {
    margin: 'auto',
    display:'flex'
  }
});

export default landingPageStyle;
