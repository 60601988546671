import { Person } from '@mui/icons-material';
import ContactsIcon from '@mui/icons-material/Contacts';
import Email from '@mui/icons-material/Email';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {
    Box,
    Button,
    CardContent, Grid, IconButton, MenuItem,
    Typography
} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import { CustomButton } from 'assets/jss/ButtonStyle';
import { CustomImg } from 'assets/jss/HeaderStyle';
import { BgBox, LeftRightGrid, LoginBox, LoginCard, SubboxWrapperBox } from 'assets/jss/LoginStyle';
import styles from 'assets/jss/material-kit-react/views/loginPage.js';
import { CalCardActions } from 'assets/jss/RetirementStyle';
import { StyledInputAdornment } from 'assets/jss/TextFieldStyle';
import Loader from 'components/CommonComponent/Loader';
import useWindowDimensions from 'components/CommonComponent/UseWindowDimensions';
import Meta from 'components/meta';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { submitRegisterData } from 'redux/actions/AuthActions';
import RenderField from 'utils/RenderField';
import { getCookie } from 'utils/Utils';

const useStyles = makeStyles(styles);
const MyIconButton = withStyles({
    root: {
        padding: 0
    }
})(IconButton);
export default function Signup(props) {
    const { height } = useWindowDimensions();
    const auth = useSelector((state) => state.login);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { handleSubmit, control, getValues } = useForm();
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = (e) => setShowPassword({ ...showPassword, [e]: !showPassword[e] });
    const classes = useStyles();
    useEffect(() => {
        if (getCookie('auth_token') !== null) {
            navigate('/');
        }
    }, []);
    
    const handleRegister = (data) => {
        const objToPass = {
            user_name: data.userName,
            email: data.email,
            password: data.password,
            phone: data.phone,
            type: 'tpe'
        };
        dispatch(submitRegisterData(objToPass, navigate));
    };
    return (
        <BgBox>
                 <Meta
                title="Sign Up | TPE Hub"
                description="Sign up to create your account and start using our services. Provide your details to register and gain access to personalized features and tools."
                page="/sign-up"
            />
            <ToastContainer />
            <Grid container>
                {auth.loader ? <Loader /> : ''}
                <LeftRightGrid item xs={12} md={5} className="form-grid">
                    <MenuItem component={Link} disableGutters to={'/'} className="logo-menu">
                        <IconButton
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            disableRipple={true}
                        >
                            <CustomImg src="img/TPE-logo.svg" className="login-tpe-logo" />
                        </IconButton>
                    </MenuItem>
                    <LoginBox minheight={height}>
                        <LoginCard>
                            <CardContent>
                                <Typography variant="subtitle1" sx={{ mb: 2 }} textAlign="center">
                                    Welcome to TPE
                                </Typography>
                                {/* <Typography variant="body1" sx={{ mb: 1 }}>
                                    Register now
                                </Typography>
                                <Grid container className="gridcontainer-SSO">
                                    <SSOSignGrid item xs={12} sm={4}>
                                        <SSOSignBtn>
                                            <img src="img/facebook-login.svg" />
                                        </SSOSignBtn>
                                    </SSOSignGrid>
                                    <SSOSignGrid item xs={12} sm={4}>
                                        {' '}
                                        <SSOSignBtn>
                                            <img src="img/google-login.svg" />
                                        </SSOSignBtn>
                                    </SSOSignGrid>
                                </Grid>
                                <DividerWithTextBox>
                                    <Divider>or login with</Divider>
                                </DividerWithTextBox> */}
                                <form onSubmit={handleSubmit(handleRegister)}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <RenderField
                                                name="userName"
                                                control={control}
                                                label="Name"
                                                id="userName"
                                                type="text"
                                                required
                                                fullWidth
                                                adornment={true}
                                                BootstrapInput={true}
                                                customPatternName="validString"
                                                placeholder="Satoshi Nakamoto"
                                                endAdornment={
                                                    <StyledInputAdornment position="end">
                                                        <Person className={classes.inputIconsColor} />
                                                    </StyledInputAdornment>
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <RenderField
                                                name="email"
                                                control={control}
                                                label="Email"
                                                id="email"
                                                type="text"
                                                required
                                                fullWidth
                                                BootstrapInput={true}
                                                adornment={true}
                                                customPatternName="email"
                                                placeholder="trump@maralago.com"
                                                endAdornment={
                                                    <StyledInputAdornment position="end">
                                                        <Email className={classes.inputIconsColor} />
                                                    </StyledInputAdornment>
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <RenderField
                                                name="phone"
                                                control={control}
                                                label="Phone Number"
                                                id="phonenum"
                                                type="phone"
                                                required
                                                fullWidth
                                                BootstrapInput={true}
                                                adornment={true}
                                                // customPatternName='email'
                                                // placeholder='trump@maralago.com'
                                                endAdornment={
                                                    <StyledInputAdornment position="end">
                                                        <ContactsIcon className={classes.inputIconsColor} />
                                                    </StyledInputAdornment>
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <RenderField
                                                name="password"
                                                control={control}
                                                label="Password"
                                                asterisk
                                                id="password"
                                                fullWidth
                                                BootstrapInput={true}
                                                adornment={true}
                                                type={showPassword['password'] ? 'text' : 'password'}
                                                required
                                                customPatternName="password"
                                                helperText="Use 8 or more characters with a mix of capital and small letters, numbers & symbols"
                                                placeholder="Enter password"
                                                endAdornment={
                                                    <StyledInputAdornment position="end">
                                                        <MyIconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={(e) => handleClickShowPassword('password')}
                                                        >
                                                            {showPassword['password'] ? <Visibility /> : <VisibilityOff />}
                                                        </MyIconButton>
                                                    </StyledInputAdornment>
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <RenderField
                                                name="confirmpassword"
                                                control={control}
                                                label="Confirm Password"
                                                asterisk
                                                id="confirmpassword"
                                                type={showPassword['confirmpassword'] ? 'text' : 'password'}
                                                required
                                                fullWidth
                                                BootstrapInput={true}
                                                adornment={true}
                                                getValues={getValues}
                                                customPatternName="confirmPassword"
                                                placeholder="Repeat password"
                                                endAdornment={
                                                    <StyledInputAdornment position="end">
                                                        <MyIconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={(e) => handleClickShowPassword('confirmpassword')}
                                                        >
                                                            {showPassword['confirmpassword'] ? <Visibility /> : <VisibilityOff />}
                                                        </MyIconButton>
                                                    </StyledInputAdornment>
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                    <CalCardActions>
                                        <CustomButton
                                            className="register-btn"
                                            type="submit"
                                            ps={'9px 30px'}
                                            variant="contained"
                                            // color="rose"
                                            // disabled={isSubmitting}
                                        >
                                            Sign Up
                                        </CustomButton>
                                    </CalCardActions>
                                    <Box textAlign={'end'}>
                                        <Typography variant="h6">
                                            Already have account?
                                            <Button simple color="primary" size="small" component={Link} to="/login">
                                                Log In
                                            </Button>
                                        </Typography>
                                    </Box>
                                </form>
                            </CardContent>
                        </LoginCard>
                        {/* </SubboxWrapperBox> */}
                    </LoginBox>
                </LeftRightGrid>
                <LeftRightGrid item xs={12} md={7} className="right-side">
                    <SubboxWrapperBox>
                        <Typography variant="subtitle1">Make best decision of the day</Typography>
                        <Typography variant="subtitle1" ml={'37%'}>
                            its time to join TPE today
                        </Typography>
                        {/* <PiggyBox></PiggyBox> */}
                        <img src="/img/register-vector.png" className="login-piggy" alt='register icon' />
                    </SubboxWrapperBox>
                </LeftRightGrid>
            </Grid>
        </BgBox>
    );
}
