/** @format */
import React, { useEffect, useState } from 'react';
import { Box, Card, CardContent, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useDispatch } from 'react-redux';
import { OptionPricingCalSuccess } from 'redux/actions/OptionPricingCalActions';
import NoDataFound from 'components/CommonComponent/NoDataFound';
import { numberWithCommas } from 'utils/Utils';
import { OutputBox } from 'assets/jss/GeneralStyle';
import * as tooltip from 'Tooltip.json';
import CustomTooltip from 'components/CommonComponent/CustomTooltip';

function OptionPricingOutput(props) {
    const dispatch = useDispatch();
    let [result, setResult] = useState([]);

    /**
     * @author disha
     * @use to set the ouput of api into state
     */
    useEffect(() => {
        if (Object.keys(props.optionPricingCalData.optionPricingCalDetail).length) {
            setResult(props.optionPricingCalData.optionPricingCalDetail.ans);
        } else {
            // to reset the output fields
            if (result.length > 0) setResult([]);
        }
    }, [props.optionPricingCalData]);
    // to reset redux stored value of result when page is changing
    useEffect(() => {
        return () => {
            dispatch(OptionPricingCalSuccess({}));
        };
    }, []);
   
    return (
        <Card elevation={2} sx={{ mb: 3.5 }}>
            <CardContent>
                <Typography variant="h6" gutterBottom sx={{ mb: 2, display: 'flex' }}>
                    Summary Output
                </Typography>
                <Box id="outputWrapper" className="widget-full-width">
                    {result.length === 0 ? (
                        <NoDataFound />
                    ) : (
                        <Grid container spacing={2}>
                            {result.map((val, i) => {
                                const currValue = typeof val.value === 'object' ? val.value : val.value.toFixed(2);
                                return typeof val.value !== 'object' ? (
                                    <React.Fragment key={i + 'optionpricingoutput'}>
                                        <Grid item xs={12} md={4}>
                                            <OutputBox>
                                                <Typography variant="body1" className="outputLabel" component="p">
                                                    {val.label}
                                                    {tooltip.default?.[val.label] && (
                                                        <CustomTooltip
                                                            tooltipText={tooltip.default?.[val.label]}
                                                            customTooltipStyle={{ maxWidth: 450 }}
                                                            label={val.label}
                                                        />
                                                    )}
                                                </Typography>
                                                <Typography variant="h6">$ {numberWithCommas(currValue)}</Typography>
                                            </OutputBox>
                                        </Grid>
                                    </React.Fragment>
                                ) : (
                                    ''
                                );
                            })}
                        </Grid>
                    )}
                </Box>
            </CardContent>
        </Card>
    );
}

export default OptionPricingOutput;
