import { container, title } from "assets/jss/material-kit-react.js";
import styles from "assets/jss/material-kit-react/views/componentsSections/typographyStyle.js";
import { grayColor, infoColor } from "assets/jss/material-kit-react";
const studentCalPageStyle = (theme) => ({
  sliderLabel: {
    paddingTop: "39px",
  },
  sliderInput: {
    paddingLeft: "28px",
    paddingTop: "35px",
  },
  outputBody: {
    padding: "0.5rem 1rem",
  },
  largeAmtTitle: {
    textAlign: "center",
    "& .outputVal": {
      fontWeight: 700,
    },
  },
  badgesPurpose: {
    float: "right",
    "@media (max-width:320px)": {
      float: "unset",
    },
  },
  noDataMsg: {
    padding: "0.5rem 1rem",
    flex: "1 1 auto",
  },
  loanPayWrapper: {
    "& div": {
      display: "flex",
      "& svg": {
        width: "0.8em",
        height: "0.8em",
        marginLeft: 8,
      },
    },
  },
  tipsOutputinputWrapper: {
    "& .MuiFormControl-root": {
      margin:0,padding:0
    }
  }, investFieldSet: {
    marginBottom:12
  }
});

export default studentCalPageStyle;
