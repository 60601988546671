import * as ActionTypes from "../actions/ActionTypes";

const initialState = {
  debtDetail: {},
  loader: false,
  disableEditTblField:false
};

export const DebtReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SUBMIT_DEBT_CAL_INIT:
    case ActionTypes.FETCH_DEBT_CAL_INIT:
      return { ...state, loader: true };
    case ActionTypes.FETCH_DEBT_CAL_SUCCESS:
      const data = action.data;
      // const arrrToPass = [];
      // if (Object.keys(data).length > 0) {
      //   data["Lifetime Balance"].forEach((val, i) => {
      //     arrrToPass.push({ ...val, label: i });
      //   });
      //   data["Lifetime Balance"] = arrrToPass;
      // }
        return {
          ...state,
          loader: false,
          debtDetail: data,
        };
    case ActionTypes.SUBMIT_DEBT_CAL_SUCCESS:
    case ActionTypes.SUBMIT_DEBT_CAL_ERROR:
    case ActionTypes.FETCH_DEBT_CAL_ERROR:
      return { ...state, loader: false };
    case ActionTypes.RESET_EDIT_TABLE_FIELD:
      return{ ...state,disableEditTblField:action.data}
    default:
      return state;
  }
};
