import React from 'react';
import BlogCategoryDescription from './BlogCategoryDescription';
import BlogList from './BlogList';

const BlogCategory = ({ blogCategory, blogsList, allCategories }) => {
    return (
        <section>
            <BlogCategoryDescription blogCategory={blogCategory} />
            <BlogList blogsList={blogsList} blogCategory={blogCategory} allCategoriesColumn={allCategories} />
        </section>
    );
};

export default BlogCategory;
