import { Container, Grid, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import CommonCalculator from 'components/CommonComponent/CommonCalculator.js';
import Loader from 'components/CommonComponent/Loader.js';
import TipsCalForm from './TipsCalcForm';
import { MainBox } from 'assets/jss/GeneralStyle';
import { CustomListItem } from 'assets/jss/HeaderStyle.jsx';
import { CustomListItemText } from 'assets/jss/HeaderStyle.jsx';
import { WidgetBox } from 'assets/jss/GeneralStyle';
import TipsCalcOutput from './TipsCalcOutput';
import TipsCalDesc from './TipsCalDesc';
import Meta from 'components/meta';

export default function TipsCalculator(props) {
    const generalCal = useSelector((state) => state.generalCal);
    const general = useSelector((state) => state.generalCal);

    const typographyRef = useRef(null);
    useEffect(() => {
        // Set the ID after the component mounts
        if (typographyRef.current) {
            typographyRef.current.id = 'tipsCal';
        }
    }, []);

    const render = () => {
        return (
            <>
                {generalCal.loader ? <Loader /> : ''}
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4.5} className="widget-grid-item">
                        <TipsCalForm tipsCalData={generalCal.tipsCalData} defaultoverrideData={general.defaultoverrideData} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={7.5} className="widget-grid-item">
                        <TipsCalcOutput data={generalCal.tipsCalData} />
                    </Grid>
                </Grid>
            </>
        );
    };
    const breadcrumbs = [
        <CustomListItem className="cal-banner-menu" key="1" sx={{ m: 0 }} color="text.light">
            <CustomListItemText primary={'Home'} />
        </CustomListItem>,
        <CustomListItem className="cal-banner-menu" key="2" sx={{ m: 0 }} color="text.light">
            <CustomListItemText primary={'Budget'} />
        </CustomListItem>,
        <Typography ref={typographyRef} key="3" color="text.light" className="current-menu">
            Tips Calculator
        </Typography>
    ];
    return (
        (props.widgetcal && <WidgetBox>{render()}</WidgetBox>) || (
            <>
               <Meta
                title="Tips Calculator | TPE Hub"
                description="Use our Tips Calculator to quickly and accurately calculate tips for your bills. Enter the total amount and your desired tip percentage to see how much you should leave. Simplify your tipping with our easy-to-use tool."
                page="/tips-calculator"
            />
                <CommonCalculator variantText="Tips Calculator" breadcrumbs={breadcrumbs} />
                <MainBox>
                    <Container maxWidth="lg">
                        {render()}
                        <TipsCalDesc />{' '}
                    </Container>
                </MainBox>
            </>
        )
    );
}
