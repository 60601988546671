import React, { useEffect, useRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// Sections for this page
import CommonCalculator from 'components/CommonComponent/CommonCalculator.js';
import { useSelector } from 'react-redux';
import Loader from 'components/CommonComponent/Loader.js';
import { MainBox } from 'assets/jss/GeneralStyle';
import { Container, Grid, Typography } from '@mui/material';
import FxCalDesc from './FxCalDesc';
import { CustomListItem } from 'assets/jss/HeaderStyle';
import { CustomListItemText } from 'assets/jss/HeaderStyle';
import FxCalOutput from './FxCalOutput';
import FxCalForm from './FxCalForm';
import { useState } from 'react';
import { WidgetBox } from 'assets/jss/GeneralStyle';
import Meta from 'components/meta';

export default function FxCalIndex(props) {
    const fxcalData = useSelector((state) => state.fxcal);
    const [currSign, setCurrSign] = useState({});

    const typographyRef = useRef(null);
    useEffect(() => {
        // Set the ID after the component mounts
        if (typographyRef.current) {
            typographyRef.current.id = 'fxCal';
        }
    }, []);

    const render = () => {
        return (
            <>
                {fxcalData.loader ? <Loader /> : ''}
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4.5} className="widget-grid-item">
                        <FxCalForm setCurrSignVal={(val) => setCurrSign(val)} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={7.5} className="widget-grid-item">
                        <FxCalOutput currSign={currSign} />
                    </Grid>
                </Grid>
            </>
        );
    };
    const breadcrumbs = [
        <CustomListItem className="cal-banner-menu" key="1" sx={{ m: 0 }} color="text.light">
            <CustomListItemText primary={'Home'} />
        </CustomListItem>,
        <CustomListItem className="cal-banner-menu" key="2" sx={{ m: 0 }} color="text.light">
            <CustomListItemText primary={'FX Calculator'} />
        </CustomListItem>,
        <Typography ref={typographyRef} key="3" color="text.light" className="current-menu">
            FX Calculator
        </Typography>
    ];
    return (
        (props.widgetcal && <WidgetBox>{render()}</WidgetBox>) || (
            <>
                <Meta
                    title="FX Calculator - Real-Time Foreign Exchange Rates | TPE Hub"
                    description="Calculate real-time foreign exchange rates with our user-friendly FX calculator. Get accurate currency conversions and stay updated with the latest rates at TPE Hub."
                    page="/fx-conversion-calculator"
                />
                <CommonCalculator variantText="Fx Calculator" breadcrumbs={breadcrumbs} />
                <MainBox>
                    <Container maxWidth="lg">
                        {render()}
                        <FxCalDesc />
                    </Container>
                </MainBox>
            </>
        )
    );
}
