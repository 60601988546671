import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { makeStyles, withStyles } from '@mui/styles';
import styles from 'assets/jss/material-kit-react/views/componentsSections/javascriptStyles.js';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { limitWords, numberWithCommas } from 'utils/Utils';
import CustomPaper from '../CustomPaper';
import EnhancedTableToolbar from './EnhancedTableToolbar';

import { Button, Typography } from '@mui/material';
import { CustomTablePagination } from 'assets/jss/TableStyle';
import CustomTooltip from '../CustomTooltip';
import TableSearch from './TableSearch';
const tooltipStyle = makeStyles(styles);

const regex = /^\d+(\.\d+)?%$/;

const StyledTableCell = withStyles((theme) => ({
    body: {
        fontSize: 14,
        textTransform: 'capitalize'
    }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        // "&:nth-of-type(odd)": {
        //   backgroundColor: theme.palette.action.hover,
        // }
    }
}))(TableRow);
const StickyTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#ddd',
        left: 0,
        position: 'sticky',
        zIndex: theme.zIndex.appBar + 2
    },
    body: {
        backgroundColor: '#ebeaea',
        minWidth: '50px',
        left: 0,
        position: 'sticky',
        zIndex: theme.zIndex.appBar + 1
    }
}))(TableCell);

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center'
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1
    },
    title: {
        flex: '1 1 70%'
    },
    searchbox: {
        margin: '10px 0 0 0'
    },
    portTotalValItem: {
        margin: 'auto'
    },
    tooltipContent: {
        '& .MuiButton-label': {
            textAlign: 'left'
        }
    },
    tooltipLabel: {
        fontSize: '1.3rem',
        top: '21px',
        position: 'absolute'
    },
    HeaderTooltipLabel: {
        fontSize: '1.3rem',
        top: '17px',
        position: 'absolute',
        marginLeft: 2
    },
    dataTooltipLabel: {
        fontSize: '1.3rem',
        position: 'absolute',
        marginLeft: 2
    },
    table: {
        '& .MuiTableRow-head': {
            backgroundColor: '#e1e1e1'
        },
        '& .MuiTableCell-head': {
            fontWeight: 600,
            textTransform: 'capitalize'
        }
    }
}));
const CommonTable = (props) => {
    const classes = useStyles();

    const {
        data,
        label,
        tableColumns,
        captionText,
        enablePagination,
        enableSorting,
        enableHeader,
        parentHeader,
        searchTable,
        enableCellColor,
        portfolioTotal,
        colspan,
        frozenCol,
        ...rest
    } = props;
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [filteredTableData, setFilteredData] = useState([]);

    let tableData = data;
    let finalTableData = tableData;

    useEffect(() => {
        if (tableData.length > 0) {
            let currTableData = tableData;
            if (enablePagination) {
                currTableData = currTableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
            }
            setFilteredData(currTableData);
        }
    }, [tableData]);

    const descendingComparator = (a, b, orderBy) => {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    };

    const getComparator = (order, orderBy) => {
        return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
    };

    const stableSort = (array, comparator) => {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    };

    // to sort the table data
    const createSortHandler = (property) => (event) => {
        const isAsc = orderBy === property && order === 'asc';
        const currOrder = isAsc ? 'desc' : 'asc';
        setOrder(currOrder);
        setOrderBy(property);
        setFilteredData(stableSort(filteredTableData, getComparator(currOrder, property)));
    };

    /**
     * to handle the row count if count of visible row per page is changed
     * @param {*} event
     */
    const handleChangeRowsPerPage = (event) => {
        const currPageRange = parseInt(event.target.value, 10);
        setRowsPerPage(currPageRange);
        UpdatePaginationTableData(0, currPageRange);
    };

    /**
     * called when page is changed using prev or next button
     * @param {*} newPage
     * @param {*} range
     */
    const UpdatePaginationTableData = (newPage, range) => {
        setPage(newPage);
        // to cut table data if pagination is enable
        setFilteredData(tableData.slice(newPage * range, newPage * range + range));
    };

    // to add green or red color to text according to its value
    const colorText = (fval, original) => {
        const cellVal =
            Math.sign(original) === -1 ? (
                <span className="negativeVal">{fval}</span>
            ) : Math.sign(original) === 1 ? (
                <span className="positiveVal">{fval}</span>
            ) : (
                fval
            );
        return enableCellColor ? cellVal : fval;
    };
    /**
     * format value of column according to its type ( int, string , float)
     * @param {*} val
     * @returns
     */
    const formatValue = (val) => {
        let formattedValue =
            val !== null && val !== undefined ? (
                Math.floor(val) === val ? (
                    colorText(numberWithCommas(val), val)
                ) : !isNaN(val) && val.toString().indexOf('.') !== -1 ? (
                    colorText(val.toFixed(2), val)
                ) : limitWords(val, 5) !== val ? (
                    <CustomTooltip tooltipText={val} textTooltip={false} textLimit={5} align="left" />
                ) : (
                    val
                )
            ) : (
                '-'
            );
        return formattedValue;
    };
    const renderCellHeadDetail = (columnState, columnLabel) => {
        return enableSorting ? (
            <TableSortLabel
                active={orderBy === columnState}
                direction={orderBy === columnState ? order : 'asc'}
                onClick={createSortHandler(columnState)}
            >
                {columnLabel}
                {orderBy === columnState ? (
                    <span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>
                ) : null}
            </TableSortLabel>
        ) : (
            columnLabel
        );
    };
    return (
        <CustomPaper>
            {enableHeader && <EnhancedTableToolbar {...props} classes={classes} />}
            <TableContainer component={Paper}>
                {searchTable ||
                    (portfolioTotal && (
                        <TableSearch
                            finalTableData={finalTableData}
                            sendFilteredData={(val) => setFilteredData(val)}
                            enableCellColor={enableCellColor}
                        />
                    ))}

                <Table className={classes.table} aria-label={label} stickyHeader>
                    {captionText && <caption>{captionText}</caption>}
                    <TableHead>
                        <TableRow>
                            {parentHeader.map((sub, subindex) => {
                                return (
                                    <TableCell key={subindex} colSpan={props.colSpan} align={props.HeaderAlign}>
                                        {sub}
                                        {rest?.tooltipColumns[sub] ? (
                                            <span className={classes.wrapperTooltip}>
                                                <CustomTooltip
                                                    // customTooltipStyle={{ maxWidth: 450 }}
                                                    className={`${classes.HeaderTooltipLabel} `}
                                                    tooltipText={rest?.tooltipColumns[sub]}
                                                    label={sub}
                                                />
                                            </span>
                                        ) : (
                                            ''
                                        )}
                                    </TableCell>
                                );
                            })}
                        </TableRow>

                        <TableRow>
                            {tableColumns.map((column, columnIndex) => {
                                let columnState = column[0];
                                let columnLabel = column[1];
                                if (frozenCol.length && frozenCol.indexOf(columnState) > -1) {
                                    return (
                                        <StickyTableCell
                                            key={columnIndex + column[0]}
                                            sortDirection={orderBy === columnState ? order : false}
                                        >
                                            {renderCellHeadDetail(columnState, columnLabel)}
                                        </StickyTableCell>
                                    );
                                } else {
                                    return (
                                        <StyledTableCell
                                            key={columnIndex + column[0]}
                                            sortDirection={orderBy === columnState ? order : false}
                                        >
                                            {renderCellHeadDetail(columnState, columnLabel)}
                                        </StyledTableCell>
                                    );
                                }
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredTableData
                            ? filteredTableData.map((row, i) =>
                                  row ? (
                                      <StyledTableRow key={i} hover>
                                          {tableColumns.map((column, columnIndex) => {
                                              let columnState = column[0];
                                              let columnLabel = column[1];
                                              const currValue =
                                                  row[columnLabel] ||
                                                  (row[columnState] === undefined && row[columnLabel] === 0
                                                      ? row[columnLabel]
                                                      : row[columnState]);
                                              if (frozenCol.length && frozenCol.indexOf(columnState) > -1) {
                                                  return <StickyTableCell>{formatValue(currValue)}</StickyTableCell>;
                                              } else {
                                                  return (
                                                      <StyledTableCell component="th" scope="row" key={columnState}>
                                                          <span
                                                              style={{
                                                                  cursor:
                                                                      label === 'Latest Loan Rates' && !regex.test(formatValue(currValue))
                                                                          ? 'pointer'
                                                                          : '',
                                                                  color:
                                                                      label === 'Latest Loan Rates' && !regex.test(formatValue(currValue))
                                                                          ? '#27A9E0'
                                                                          : '',
                                                                  fontWeight:
                                                                      label === 'Latest Loan Rates' && !regex.test(formatValue(currValue))
                                                                          ? 'bold'
                                                                          : ''
                                                              }}
                                                              onClick={() =>
                                                                  label === 'Latest Loan Rates' && !regex.test(formatValue(currValue))
                                                                      ? rest.handleSymbolClick(currValue)
                                                                      : {}
                                                              }
                                                          >
                                                              {formatValue(currValue)}
                                                          </span>
                                                          {rest?.tooltipColumns && rest?.tooltipColumns[currValue] && (
                                                              <span style={{ position: 'relative' }}>
                                                                  <CustomTooltip
                                                                      customTooltipStyle={{
                                                                          maxWidth: rest?.tooltipColumns[currValue].length > 300 ? 450 : 220
                                                                      }}
                                                                      className={`${classes.dataTooltipLabel} `}
                                                                      tooltipText={rest?.tooltipColumns[currValue]}
                                                                      label={currValue}
                                                                  />
                                                              </span>
                                                          )}
                                                      </StyledTableCell>
                                                  );
                                              }
                                          })}
                                      </StyledTableRow>
                                  ) : (
                                      <></>
                                  )
                              )
                            : ''}
                        {filteredTableData.length === 0 && (
                            <StyledTableRow
                                style={{
                                    height: 53 * 5
                                }}
                            >
                                <StyledTableCell colSpan={colspan} component="th" scope="row" align="center">
                                    No rows found.
                                </StyledTableCell>
                            </StyledTableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            {enablePagination && (
                <CustomTablePagination
                    showFirstButton={false}
                    showLastButton={false}
                    SelectProps={{
                        inputProps: { 'aria-label': 'rows per page' },
                        native: true
                    }}
                    labelDisplayedRows={({ from, to, count }) => {
                        return (
                            <Typography variant="body1">
                                {from} - {to} of {count}
                            </Typography>
                        );
                    }}
                    labelRowsPerPage={<Typography variant="body1">Rows per page: </Typography>}
                    rowsPerPageOptions={[5, 10, 25, 50, { value: -1, label: 'All' }]}
                    component="div"
                    count={data ? data.length : 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={(e, newPage) => UpdatePaginationTableData(newPage, rowsPerPage)}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            )}
        </CustomPaper>
    );
};
CommonTable.defaultProps = {
    enablePagination: true,
    colspan: 6,
    enableSorting: true,
    enableHeader: true,
    defaultSort: '',
    parentHeader: [],
    searchTable: false,
    enableCellColor: false,
    frozenCol: []
};

CommonTable.propTypes = {
    enablePagination: PropTypes.bool,
    enableSorting: PropTypes.bool,
    data: PropTypes.array.isRequired,
    tableColumns: PropTypes.array.isRequired
};
export default CommonTable;
