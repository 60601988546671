import * as ActionTypes from "./ActionTypes";
import { apiCall } from "utils/Utils";
import { toast } from "react-toastify";
import Globals from "Globals.js";
import { handleError } from "utils/Utils";
import { eraseCookie } from "utils/Utils";
import { setCookie } from "utils/Utils";
/**
 * @author disha
 * @param {*} data
 * @use to login in project
 * @returns
 */
export const submitLoginData = (data, navigate = null, keepPass = null) => async (dispatch) => {
  try {
    dispatch(submitLoginDataInit());
    const apiResponse = await apiCall(
      `${Globals.API_ROOT_URL}login`,
      "post",
      data
    );
    //to get date after 5 years from now to set exipre date of cookie
    var expireDate = new Date();
    expireDate.setFullYear(expireDate.getFullYear() + 5);
    if (apiResponse !== undefined && apiResponse.status === 200) {
      dispatch(submitLoginDataSuccess(apiResponse.data));
      setCookie("auth_token", apiResponse.data.data.auth_token, expireDate);
      // keepPass && localStorage.setItem("user_pass", data.password);
      if (navigate !== null) {
        navigate("/");
      }
    } else {
      handleError(apiResponse, dispatch);
      dispatch(submitLoginDataError());
    }
  } catch (error) {
    toast.error(Globals.errorMsg);
    dispatch(submitLoginDataError());
    throw error;
  }
};
// handle loader when api is started to call
export const submitLoginDataInit = () => {
  return {
    type: ActionTypes.SUBMIT_LOGIN_INIT,
  };
};
// save success data in reducer after login
export const submitLoginDataSuccess = (val) => {
  return {
    type: ActionTypes.SUBMIT_LOGIN_SUCCESS,
    data: val,
  };
};

export const submitLoginDataError = () => {
  return {
    type: ActionTypes.SUBMIT_LOGIN_ERROR,
  };
};

/**
 * @author disha
 * @param {*} data
 * @use to register user
 * @returns
 */
export const submitRegisterData = (data, navigate = null) => async (dispatch) => {
  try {
    dispatch(submitRegisterDataInit());
    const apiResponse = await apiCall(
      `${Globals.API_ROOT_URL}register`,
      "post",
      data
    );
    //to get date after 5 years from now to set exipre date of cookie
    var expireDate = new Date();
    expireDate.setFullYear(expireDate.getFullYear() + 5);
    if (apiResponse.data.code === 200) {
      let success = "You have been successfully registered";
      dispatch(submitRegisterDataSuccess(apiResponse.data));
      setCookie("auth_token", apiResponse.data.data.auth_token, expireDate);
      if (navigate !== null) {
        navigate("/", { state: { success } })
      }
    } else {
      handleError(apiResponse, dispatch);
      dispatch(submitRegisterDataError());
    }
  } catch (error) {
    toast.error(Globals.errorMsg);
    dispatch(submitRegisterDataError());
    throw error;
  }
};
export const submitRegisterDataInit = () => {
  return {
    type: ActionTypes.SUBMIT_REGISTER_INIT,
  };
};
export const submitRegisterDataSuccess = (val) => {
  return {
    type: ActionTypes.SUBMIT_REGISTER_SUCCESS,
    data: val,
  };
};

export const submitRegisterDataError = () => {
  return {
    type: ActionTypes.SUBMIT_REGISTER_ERROR,
  };
};

/**
 * @author disha
 * @param {*} history
 * @returns
 */
export const handleLogout = (navigate) => async (dispatch) => {
  try {
    dispatch(handleLogoutInit());
    const apiResponse = await apiCall(
      `${Globals.API_ROOT_URL}logout`,
      "get"
    );
    if (apiResponse.status === 200) {
      dispatch(handleLogoutSuccess());
    } else {
      handleError(apiResponse, dispatch);
      dispatch(handleLogoutError());
    }
    eraseCookie('auth_token');
    navigate("/login")
  } catch (error) {
    toast.error(Globals.errorMsg);
    dispatch(handleLogoutError());
    throw error;
  }
};
export const handleLogoutInit = () => {
  return {
    type: ActionTypes.HANDLE_LOGOUT_INIT,
  };
};
export const handleLogoutSuccess = () => {
  return {
    type: ActionTypes.HANDLE_LOGOUT_SUCCESS
  };
};

export const handleLogoutError = () => {
  return {
    type: ActionTypes.HANDLE_LOGOUT_ERROR
  };
};

export const submitForgotResetPasswordSuccess = () => {
  return {
    type: ActionTypes.SUBMIT_FORGOT_RESET_PASS_SUCCESS
  };
};

export const submitForgotResetPasswordError = () => {
  return {
    type: ActionTypes.SUBMIT_FORGOT_RESET_PASS_ERROR
  };
};
export const submitForgotResetPassword = (data, callfrom = null) => async (
  dispatch
) => {
  try {
    dispatch({ type: ActionTypes.SUBMIT_FORGOT_RESET_PASS_INIT });
    let type = 'put';
    if (callfrom === 'reset') {
      type = "post"
    }
    const apiResponse = await apiCall(
      `${Globals.API_ROOT_URL}forgotpassword`, type, data);
    if (apiResponse.data.code === 200) {
      toast.success(apiResponse.data.data.message);
      dispatch(submitForgotResetPasswordSuccess());
    } else {
      handleError(apiResponse, dispatch);
      dispatch(submitForgotResetPasswordError());
    }
  } catch (error) {
    toast.error(Globals.errorMsg);
    dispatch(submitForgotResetPasswordError());
    throw error;
  }
};
