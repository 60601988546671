import React, { useEffect, Fragment } from 'react';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import RenderField from 'utils/RenderField';
import { submitLifeIns } from 'redux/actions/GeneralCalActions';
import { submitLifeInsSuccess } from 'redux/actions/GeneralCalActions';
import { SliderParentGrid } from 'assets/jss/RetirementStyle';
import { TwoValSliderGrid } from 'assets/jss/RetirementStyle';
import { CustomButton } from 'assets/jss/ButtonStyle';
import { CalCardActions } from 'assets/jss/RetirementStyle';
const fieldsDetail = [
    // { name: "Mort", label: "Mortality Age", type: 'slider',min:18,max:100, },
    { name: 'Ages', label: 'Age', label2: 'Mortality Age', type: 'slider', min: 18, max: 110 },
    { name: 'Premium', label: 'Annual Premium', type: 'number', commaSep: true, custompattern: 'decimalRate', placeholder: '100,000' },
    { name: 'Amount', label: 'Amount', type: 'number', commaSep: true, custompattern: 'decimalRate', placeholder: '100,000' }
];
const LifeInsForm = ({ classes, lifeinsCalData }) => {
    const dispatch = useDispatch();
    const {
        handleSubmit,
        control,
        formState: { isDirty, isSubmitting },
        setValue
    } = useForm({
        mode: 'onChange',
        defaultValues: { Ages: [58, 85], Premium: 8000, Amount: 1000000 }
    });
    //to submit the form data
    const onSubmit = (data) => {
        const objToSend = {
            Mort: data.Ages[1],
            Age: data.Ages[0],
            Premium: data.PremiumUnFormattedValue || data.Premium,
            Amount: data.AmountUnFormattedValue || data.Amount
        };
        dispatch(submitLifeIns(objToSend));
    };
    // when any input field value will change it will call below method
    const handleFormInputChange = (e) => {
        if (isDirty && lifeinsCalData && Object.keys(lifeinsCalData).length > 0) {
            // dispatch(submitLifeInsSuccess({}));
        }
    };
    useEffect(() => {
        // reset output when route will change
        return () => {
            dispatch(submitLifeInsSuccess({}));
        };
    }, []);

    return (
        <Card elevation={2} className={`${classes.BudgetForm} ${classes.widgetCalCard}`}>
            <CardContent>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container>
                        {fieldsDetail.map((val, i) => {
                            if (val.type === 'slider') {
                                return (
                                    <Fragment key={i + 'lifeInsForm'}>
                                        <Grid item xs={12} className={classes.insSliderLabel}>
                                            <SliderParentGrid container>
                                                <Grid item xs={12} md={5} lg={5}>
                                                    <Typography gutterBottom className="bold-font">
                                                        {val.label}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} md={5} lg={5} textAlign={'end'}>
                                                    <Typography gutterBottom className="bold-font">
                                                        {val.label2}
                                                    </Typography>
                                                </Grid>
                                                <TwoValSliderGrid item xs={12}>
                                                    <RenderField
                                                        control={control}
                                                        name={val.name}
                                                        id={val.name}
                                                        max={val.max}
                                                        min={val.min}
                                                        type="slider"
                                                        valueLabelDisplay="on"
                                                        handleOnChange={handleFormInputChange}
                                                    />
                                                </TwoValSliderGrid>
                                            </SliderParentGrid>
                                        </Grid>
                                    </Fragment>
                                );
                            }
                            return (
                                <Grid item xs={12} md={6} key={i + 'lifeInsForm'}>
                                    <RenderField
                                        handleOnChange={handleFormInputChange}
                                        name={val.name}
                                        control={control}
                                        label={val.label}
                                        id={val.name}
                                        type={val.type}
                                        required={val.required || true}
                                        thousandSeparator={val.commaSep}
                                        customPatternName={val.custompattern}
                                        placeholder={val.placeholder}
                                        setValue={setValue}
                                    />
                                </Grid>
                            );
                        })}
                    </Grid>
                    <CalCardActions className="contactus-cardaction">
                        <CustomButton type="submit" ps={'9px 30px'} variant="contained" disabled={isSubmitting}>
                            Calculate
                        </CustomButton>
                    </CalCardActions>
                </form>
            </CardContent>
        </Card>
    );
};

export default LifeInsForm;
