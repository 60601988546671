import { withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Parallax from 'components/Parallax/Parallax.js';
import React, { useRef } from 'react';

import { Link } from 'react-router-dom';
import { getCookie } from 'utils/Utils';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import ListSubheader from '@mui/material/ListSubheader';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import { Box, Card, Grid, List, ListItem, ListItemText } from '@mui/material';
import { CustomGrid } from 'assets/jss/LandingStyle';
import { Button, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/system';
import { BannerContainer } from 'assets/jss/BannerStyle';
import { BannerImage } from 'assets/jss/BannerStyle';
import { BannerContent } from 'assets/jss/BannerStyle';
import { BannerTitle } from 'assets/jss/BannerStyle';
import { BannerDescription } from 'assets/jss/BannerStyle';
import { BannerShopButton } from 'assets/jss/BannerStyle';
import { BannerBox } from 'assets/jss/BannerStyle';
import { TypographySubHeading } from 'assets/jss/BannerStyle';
import { BannerSigninButton } from 'assets/jss/BannerStyle';
import { ComputerBox } from 'assets/jss/BannerStyle';
import { DashBox } from 'assets/jss/LandingStyle';
import { Item } from 'assets/jss/LandingStyle';
import { DashGrid } from 'assets/jss/LandingStyle';
import { DashCardBox } from 'assets/jss/LandingStyle';
import { DashGridBox } from 'assets/jss/LandingStyle';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { DashCard } from 'assets/jss/LandingStyle';
import { DashSecondCardBox } from 'assets/jss/LandingStyle';
import { TodoBox } from 'assets/jss/LandingStyle';
import { TodoListTag } from 'assets/jss/LandingStyle';
import { TodoCardContent } from 'assets/jss/LandingStyle';
import { TodoListItemtext } from 'assets/jss/LandingStyle';
import { useNavigate } from 'react-router-dom';
import { CustomButton } from 'assets/jss/ButtonStyle';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { openChatBotPopup } from 'redux/actions/ChatBotActions';
const FinbotBlock = (props) => {
    const trackButton = useRef();
    const navigate = useNavigate();
    const dispatch = useDispatch();
useEffect(() => {
    props.passRef(trackButton);
}, [trackButton]);

const handleAskQuesBtn=()=>{
    dispatch(openChatBotPopup(true));
}
    return (
        <TodoBox>
            <Card ref={trackButton}>
                <CardMedia
                    component="img"
                    onClick={() => navigate('/finance-task-list')}
                    className="todo-image add-pointer"
                    image="img/finbot.webp"
                    alt="Live from space album cover"
                />
                <TodoCardContent className="finbot-content">
                    <Typography
                        variant="h1"
                        sx={{ mb: 3, display: 'inline-block' }}
                        className="add-pointer"
                        onClick={() => navigate('/finance-task-list')}
                    >
                        FinBot
                    </Typography>
                    <Typography variant="subheading2" className="finbot-subheading" sx={{ mb: 1.5, display: 'block' }}>
                        Welcome to TPE's Finance chatbot or finbot. Meet Maya, our finbot. She has all the answers.
                    </Typography>
                    <CustomButton
                        variant="contained"
                        ps={15}
                        sx={{ mt: 1 }}
                        startIcon={<ChatBubbleOutlineIcon />}
                        onClick={handleAskQuesBtn}
                        className="read-more"
                    >
                        Ask your question
                    </CustomButton>
                </TodoCardContent>
            </Card>
        </TodoBox>
    );
};

export default FinbotBlock;
