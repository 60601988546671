import { Grid, CardActions, Box, TableCell, TableRow, TablePagination, IconButton, TableContainer, Toolbar } from '@mui/material';
import MaUTable from '@mui/material/Table';
import { styled } from '@mui/styles';
export const StyledTableCell = styled(TableCell)(({ props, theme }) => {
    return {
        '&.sample-table-ui': {
            backgroundColor: theme.palette.primary[200]
        },
        '&.MuiTableCell-body': {
            textTransform: 'capitalize',
            wordBreak: 'break-all',
            // textAlign:'center'
            '& .breakWordCell': {
                fontSize: 14,
                textTransform: 'capitalize',
                wordBreak: 'break-all',
                whiteSpace: 'normal !important'
            }
        },
        '&.MuiTableCell-head': {
            fontWeight: '600',
            textTransform: 'capitalize',
            backgroundColor: props?.headerColor || theme.palette.background.paper,
            '&.sample-table-ui': {
                backgroundColor: theme.palette.primary[200]
            }
        },
        padding: '7px 6px 5px 6px',
        margin: '3px',
        backgroundColor: props?.bgColor,
        '& .MuiFormControl-root': { margin: 0, padding: 0 },
        display: props.display,
        flexDirection: props.flexDirection
    };
});
export const StickyTableCell = styled(TableCell)(({ props, theme }) => {
    return {
        '&.sample-table-ui': {
            backgroundColor: theme.palette.primary[200]
        },
        '&.MuiTableCell-head': {
            // textAlign:'center',
            backgroundColor: props?.headerColor || theme.palette.background.paper,
            left: 0,
            position: 'sticky',
            fontWeight: '600',
            textTransform: 'capitalize',
            zIndex: theme.zIndex.appBar + 2,
            '&:nth-of-type(2)': {
                position: 'sticky',
                width: '120px',
                left: '0',
                zIndex: '2'
            },
            '&:nth-of-type(3)': {
                position: 'sticky',
                width: '120px',
                left: '128px',
                zIndex: '3'
            }
        },
        '&.MuiTableCell-body': {
            // textAlign:'center',
            backgroundColor: props?.cellColor || theme.palette.background.paper,
            minWidth: '50px',
            left: 0,
            position: 'sticky',
            // zIndex: theme.zIndex.appBar + 1,
            '&:nth-of-type(2)': {
                position: 'sticky',
                width: '120px',
                left: '0',
                zIndex: '2'
            },
            '&:nth-of-type(3)': {
                position: 'sticky',
                width: '120px',
                left: '128px',
                zIndex: '3'
            }
        },
        padding: '7px 6px 5px 6px',
        margin: '3px',
        '& .MuiFormControl-root': { margin: 0, padding: 0 }
    };
});
export const CustomTableRow = styled(TableRow)(({ props, theme }) => {
    return {
        marginBottom: '3px',
        boxShadow: theme.shadows[2],
        backgroundColor: theme.palette.background.paper,
        '&.MuiTableRow-head': {
            margin: '0 0 10px 0',
            boxShadow: theme.shadows[3],
            '&.sample-table-ui': {
                backgroundColor: theme.palette.primary[200]
            }
        },
        '&.sample-table-ui-row': {
            margin: 0,
            boxShadow: 'none',
            borderBottom: `1px solid ${theme.palette.text.dark}`
        }
    };
});
export const CustomTablePagination = styled(TablePagination)(({ props, theme }) => {
    return {
        padding: '0'
    };
});
export const PaginationIconButton = styled(IconButton)(({ theme }) => {
    return {
        marginRight: '75px'
    };
});
export const PaginationButtonBox = styled(Box)(({ theme }) => {
    return {
        flexShrink: '0'
    };
});
export const SearchStyledTableContainer = styled(TableContainer)(({ theme }) => {
    return {
        marginLeft: 'auto'
    };
});
export const TableStyledContainer = styled(TableContainer)(({ theme }) => {
    return {
        //    marginTop: '12px'
    };
});
export const StyledMaUTable = styled(MaUTable)(({ customheight, theme }) => {
    return {
        maxHeight: customheight || 400,
        overflowY: 'scroll',
        display: 'grid'
    };
});
export const StyledSearchBox = styled(Box)(({ customheight, theme }) => {
    return {
        [theme.breakpoints.down('md')]: {
            width: '155px'
        }
    };
});
