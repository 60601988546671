import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import AppRoute from 'views/Routes/index';
import { ThemeProvider } from '@mui/material/styles';
import theme from 'assets/themes/index';
import { StyledEngineProvider } from '@mui/material';
const mode ='light';
export default function AppTheme() {
    // const [mode, setMode] = useState('light');
    // const customization = useSelector((state) => state.customization);

    //   const classes = useStyles();
    return (
        <>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme({ mode })}>
                    <CssBaseline />
                    <AppRoute />
                </ThemeProvider>
            </StyledEngineProvider>
        </>
    );
}
