import React, { useMemo } from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import NoDataFound from 'components/CommonComponent/NoDataFound';
import EnhancedTable from 'components/CommonComponent/CommonTable/EnhancedTable';
import { Box, Tooltip, IconButton } from '@mui/material';
import moment from 'moment';
import { TodoTitleTypography } from 'assets/jss/TodoStyle';

function BlogsListView({ blogsList, allCategoriesColumn }) {
    const columns = useMemo(() => {
        const namedCategoriesResult = [
            {
                Header: 'Title',
                accessor: 'title',
                disableFilters: true,
                Cell: ({ value, row }) =>
                    (
                        <a
                            href={`blog/${row.original.title
                                .toLowerCase()
                                .replace(/\s+/g, '-')
                                .replace(/[^a-z0-9\-]/g, '')}`}
                            target="_blank"
                        >
                            <TodoTitleTypography>{value}</TodoTitleTypography>
                        </a>
                    ) || '-'
            },
            {
                Header: 'Publish Date',
                width: 100,
                accessor: 'created_at',
                disableFilters: true,
                Cell: ({ value }) => moment(value, 'DD-MM-YYYY HH:mm:ss').format('MMMM D, YYYY h:mm A') || '-'
            },
            {
                Header: 'Action',
                width: 30,
                Cell: ({ row }) => (
                    <div className={'actionWrapper'}>
                        <Tooltip title="Read">
                            <a
                                href={`blog/${row.original.title
                                    .toLowerCase()
                                    .replace(/\s+/g, '-')
                                    .replace(/[^a-z0-9\-]/g, '')}`}
                                target="_blank"
                            >
                                <IconButton aria-label="read">
                                    <VisibilityIcon />
                                </IconButton>
                            </a>
                        </Tooltip>
                    </div>
                )
            }
        ];

        const AllCategoryResult = [
            ...namedCategoriesResult,
            {
                Header: 'Category',
                accessor: 'category_name',
                disableFilters: true,
                Cell: ({ value, row }) => <div>{value}</div>
            }
        ];
        const result = allCategoriesColumn === true ? AllCategoryResult : namedCategoriesResult;
        return result;
    });

    return (
        <Box>
            {blogsList?.length ? (
                <EnhancedTable columns={columns} data={blogsList || []} showPagination={false} />
            ) : (
                <NoDataFound msg="No articles found." style={{ padding: '20px', fontWeight: '700' }} />
            )}
        </Box>
    );
}

export default BlogsListView;
