import React from 'react';
import { Container, Typography, Box } from '@mui/material';

const PrivacyPolicy = () => {
    return (
        <Container maxWidth="md">
            <Box my={4}>
                <Typography variant="h4" gutterBottom>
                    Privacy Policy
                </Typography>
                <Typography variant="body1" paragraph>
                    Welcome to our Privacy Policy page. Your privacy is critically important to us.
                </Typography>
                <Typography variant="h6" gutterBottom>
                    Information We Collect
                </Typography>
                <Typography variant="body1" paragraph>
                    We only collect information about you if we have a reason to do so – for example, to provide our services, to
                    communicate with you, or to improve our services.
                </Typography>
                <Typography variant="h6" gutterBottom>
                    How We Use Information
                </Typography>
                <Typography variant="body1" paragraph>
                    We use the information we collect in various ways, including to provide, operate, and maintain our website, improve and
                    personalize our website, and develop new products, services, features, and functionality.
                </Typography>
                <Typography variant="h6" gutterBottom>
                    Sharing Information
                </Typography>
                <Typography variant="body1" paragraph>
                    We do not share your personal information with any third parties except as necessary to provide our services, comply
                    with the law, or protect our rights.
                </Typography>
                <Typography variant="h6" gutterBottom>
                    Security
                </Typography>
                <Typography variant="body1" paragraph>
                    We take precautions to protect your information. When you submit sensitive information via the website, your information
                    is protected both online and offline.
                </Typography>
                <Typography variant="h6" gutterBottom>
                    Changes to This Policy
                </Typography>
                <Typography variant="body1" paragraph>
                    We may update this privacy policy from time to time. We will notify you of any changes by posting the new privacy policy
                    on this page.
                </Typography>
                <Typography variant="h6" gutterBottom>
                    Contact Us
                </Typography>
                <Typography variant="body1" paragraph>
                    If you have any questions about this privacy policy, please contact us.
                </Typography>
            </Box>
        </Container>
    );
};

export default PrivacyPolicy;
