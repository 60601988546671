import { Autocomplete, CircularProgress, Divider, Grid, TextField } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Tooltip from '@mui/material/Tooltip';
import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { submitBudgetCalSuccess } from 'redux/actions/BudgetActions';
import RenderField from 'utils/RenderField';
import CustomDialogTitle from 'components/DialogTitle';
import { CalCardActions } from 'assets/jss/RetirementStyle';
import { CustomButton } from 'assets/jss/ButtonStyle';
import SearchIcon from '@mui/icons-material/Search';
import { fetchWatchListChart, fetchWatchListCompanyName, fetchTickerList } from '../../redux/actions/EquityDashboardActions';
import WatchListChart from './WatchListChart';

// Debounce function for limiting API requests while typing
function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [value]);

    return debouncedValue;
}

const GetAQuote = (props) => {
    const [open, setOpen] = React.useState(false);
    const dispatch = useDispatch();
    const [enableChart, setEnableChart] = useState({});
    const [inputValue, setInputValue] = useState('');
    const debouncedInput = useDebounce(inputValue, 300);
    const { tickers, loader } = useSelector((state) => state.equityDashboard);
    const [offset, setOffset] = useState(0);
    const limit = 20;
    const {
        handleSubmit,
        control,
        formState: { isSubmitting },
        setValue,
        reset
    } = useForm({
        mode: 'onChange'
    });

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        reset({});
        setOpen(false);
    };

    //to submit the form data
    const onSubmit = (data) => {
        dispatch(fetchWatchListCompanyName({ symbol: data.symbol }));
        dispatch(fetchWatchListChart(data.symbol));
    };

    useEffect(() => {
        if (props.equityDashboardData?.equity_dash_anon_data) {
            setEnableChart(props.equityDashboardData.equity_dash_anon_data);
        }
    }, [props.equityDashboardData?.equity_dash_anon_data]);

    const handleRangeSelection = (range) => {
        dispatch(fetchWatchListChart(enableChart.symbol, range));
    };

    useEffect(() => {
        // reset output when route will change
        return () => {
            dispatch(submitBudgetCalSuccess({}));
        };
    }, []);

    useEffect(() => {
        dispatch(fetchTickerList({ filter: debouncedInput, offset: offset, limit: limit }));
    }, [debouncedInput]);

    useEffect(() => {
        if (debouncedInput) {
            dispatch(fetchTickerList({ filter: inputValue }));
        }
    }, [debouncedInput]);

    const handleScroll = (event) => {
        const threshold = 100;
        const bottomReached = event.target.scrollHeight - event.target.scrollTop <= event.target.clientHeight + threshold;

        if (bottomReached && !loader) {
            setOffset((prevOffset) => prevOffset + limit);
            dispatch(fetchTickerList({ filter: debouncedInput, offset: offset, limit: limit }));
        }
    };

    return (
        <div style={{ width: '100%', color: 'red' }}>
            <Tooltip title="Get a Quote" arrow>
                <CustomButton variant="text" startIcon={<SearchIcon />} aria-label="add" onClick={handleClickOpen}>
                    Get A Quote
                </CustomButton>
            </Tooltip>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="ticker-dialog-title"
                maxWidth="2xl"
                sx={{
                    '& .MuiPaper-root.MuiDialog-paperWidth2xl': {
                        maxWidth: Object.keys(enableChart).length > 0 ? '1000px' : '250px',
                        width: '100%'
                    }
                }}
            >
                {Object.keys(enableChart).length > 0 ? (
                    <WatchListChart
                        chartdetails={enableChart}
                        backButtonClicked={() => {
                            setEnableChart({});
                            handleClose();
                        }}
                        rangeSelectionClicked={(val) => handleRangeSelection(val)}
                    />
                ) : (
                    <>
                        <CustomDialogTitle id="ticker-dialog-title" onClose={handleClose}>
                            Enter Symbol
                        </CustomDialogTitle>
                        <Divider />
                        <DialogContent>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <Grid container alignItems="baseline" key={'addnewticker'} marginBottom={2}>
                                    <Grid item xs={12} sx={{ display: 'flex' }}>
                                        {/* <RenderField
                                            name={`symbol`}
                                            control={control}
                                            id={`symbol`}
                                            required
                                            placeholder="Ticker Name"
                                            type="text"
                                            label="Symbol"
                                            fieldName={'Symbol'}
                                            customPatternName={/^([a-z0-9A-Z,"' ]{1,})$/g}
                                            setValue={setValue}
                                        /> */}
                                        <Controller
                                            name="symbol"
                                            control={control}
                                            defaultValue=""
                                            render={({ field: { onChange, value } }) => (
                                                <Autocomplete
                                                    style={{ width: '300px' }}
                                                    freeSolo
                                                    options={tickers}
                                                    getOptionLabel={(option) => option.ticker}
                                                    loading={loader}
                                                    inputValue={value || ''}
                                                    onInputChange={(event, newInputValue) => {
                                                        setInputValue(newInputValue);
                                                        onChange(newInputValue);
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Search Symbol"
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                endAdornment: (
                                                                    <>
                                                                        {loader ? <CircularProgress color="inherit" size={20} /> : null}
                                                                        {params.InputProps.endAdornment}
                                                                    </>
                                                                )
                                                            }}
                                                        />
                                                    )}
                                                    ListboxProps={{
                                                        onScroll: handleScroll,
                                                        style: { maxHeight: 200, overflow: 'auto' }
                                                    }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                                <CalCardActions className="contactus-cardaction">
                                    <CustomButton type="submit" ps={'9px 30px'} variant="contained" disabled={isSubmitting}>
                                        Submit
                                    </CustomButton>
                                </CalCardActions>
                            </form>
                        </DialogContent>
                    </>
                )}
            </Dialog>
        </div>
    );
};

export default GetAQuote;
