import AddCircleIcon from '@mui/icons-material/AddCircle';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import CreateIcon from '@mui/icons-material/Create';
import DateRangeIcon from '@mui/icons-material/DateRange';
import SearchIcon from '@mui/icons-material/Search';
import SettingsIcon from '@mui/icons-material/Settings';
import { Box, Divider, InputAdornment, List, ListItem, ListItemText, Popover, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CustomSlider from 'components/CommonComponent/CustomSlider';
import NoDataFound from 'components/CommonComponent/NoDataFound';
// import CustomPaper from "components/CommonComponent/CustomPaper";
import { Grid } from '@mui/material';
import {
    ProgressTodoWrapperBox,
    SelectedTodoCardBtnBox,
    StyledSettingCardHeader,
    StyledTodoCardParent,
    TickStyledIconBtn,
    TimeDurationWrapperBox,
    TodoBodyCardBox,
    TodoCardWrapperBox,
    TodoDateWrapperTypography,
    TodoTitleTypography,
    TypographyTodoCount
} from 'assets/jss/TodoStyle';
import Loader from 'components/CommonComponent/Loader.js';
import CustomInput from 'components/CustomInput/CustomInput';
import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { deleteTodo, fetchTodoCategory, fetchTodos, searchTodo, updateTodo } from 'redux/actions/TodoActions';
import { invertColor, limitLetters } from 'utils/Utils';
import TodoGifs from './TodoGifs';
// import todoPageStyle from "assets/jss/material-kit-react/views/todoPage";
// import CustomPaper from "components/CommonComponent/CustomPaper";
import { CustomButton } from 'assets/jss/ButtonStyle';
import {
    AddTodoBlockBox,
    SettingBoxWrapper,
    StageColumnPaper,
    TodoActionGrid,
    TodoGridContainer,
    TodosSearchBoxGrid
} from 'assets/jss/TodoStyle';
import { useTheme } from '@mui/styles';
import { useSearchParams } from 'react-router-dom';
import { Padding } from '@mui/icons-material';

const stages = [
    ['planned', 'Planned'],
    ['progress', 'Progress'],
    ['completed', 'Completed'],
    ['dues', 'Overdue']
];
const timeDuration = [
    ['today', 'Today'],
    ['thisWeek', 'This Week'],
    ['nextWeek', 'Next Week'],
    ['later', 'Later'],
    ['nodates', 'No Date']
];
let _searchtimeout = 0;
let startTouch = 0;
let allowEndApi = false;
let checkFirstMove = false;
export const TodoCards = (props) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const [searchParams, setSearchParams] = useSearchParams();
    const [confirmDeleteDialog, setConfirmDeleteDialog] = useState(false);
    const [stopScrolling, setStopScrolling] = useState(false);
    const [cardStepOver, setCardStepOver] = useState({ id: '', step_num: '', stage: '' });
    const [stageVal, setStageVal] = useState(1);
    const [anchorEl, setAnchorEl] = useState(null);
    const todoProps = useSelector((state) => state.todo);
    const [todoSelectionList, setTodoSelection] = useState([]);
    const [todoList, setTodoList] = useState({});
    const [searchValue, setSearchValue] = useState('');
    const open = Boolean(anchorEl);
    const settingId = open ? 'action-popup' : undefined;

    // to open action popup
    const handlePopoverOpen = (event) => setAnchorEl(event.currentTarget);

    // to close action popup on outside click
    const handlePopoverClose = () => setAnchorEl(null);

    useEffect(() => {
        dispatch(fetchTodos());
        dispatch(fetchTodoCategory());
    }, []);

    useEffect(() => {
        if (searchParams.has('id')) {
            const todoId = searchParams.get('id');
            const todo = document.getElementById(`todo-${todoId}`);
            if (todo) {
                todo.click();
            }
        }
    }, [todoList, searchParams]);

    useEffect(() => {
        // set todo list data in state
        setTodoList(todoProps.todoList);
    }, [todoProps.todoList]);

    useEffect(() => {
        // if searched data is availabled then display those data else display all todo list
        if (Object.keys(todoProps.searchTodoList).length) {
            setTodoList(todoProps.searchTodoList);
        } else {
            setTodoList(todoProps.todoList);
        }
    }, [todoProps.searchTodoList]);

    // store all checked todo cards
    const handleTodoSelection = (id) => {
        if (todoSelectionList.indexOf(id) > -1) {
            setTodoSelection(todoSelectionList.filter((val) => val !== id));
        } else {
            setTodoSelection([...todoSelectionList, id]);
        }
    };
    // handle when card will dropped/touch end
    const handleDropEnd = (e, data, stage, type) => {
        let prev_index = cardStepOver.id,
            prev_step = cardStepOver.step_num,
            prev_stage = stageVal;
        setStopScrolling(false);
        if (type === 'touch') {
            if (e.target.closest('.MuiSlider-root')) {
                return;
            }
            var evt = typeof e.originalEvent === 'undefined' ? e : e.originalEvent;
            var touch = evt.touches[0] || evt.changedTouches[0];
            let x = touch.clientX;
            let y = touch.clientY;
            if (document.elementsFromPoint) {
                const currEle = document.elementsFromPoint(x, y).length ? document.elementsFromPoint(x, y)[0].closest('.todoCards') : '';
                if (currEle) {
                    prev_index = currEle.getAttribute('att_prev_index');
                    prev_step = currEle.getAttribute('att_prev_step');
                    prev_stage = currEle.getAttribute('att_prev_stage');
                }
            }
        }
        if (prev_stage === 'dues') {
            toast.error('You can not move todo in Overdue');
            return;
        } else if (stage === 'dues') {
            toast.error('You can not move overdue todo. Please update due date');
            return;
        }
        let objToPass = {
            dragged_todo: true,
            todo_id: data.id,
            step_num: prev_step,
            progress: (prev_stage === 'planned' && 0) || (prev_stage === 'progress' && 50) || (prev_stage === 'completed' && 100) || 0,
            prev_todo_id: prev_index,
            prev_step_num: data.step_num
        };
        if (prev_index) {
            dispatch(updateTodo(objToPass));
        }
    };

    const handleDropEnter = (id) => setStageVal(id);
    const renderEmptyCard = (stage) => {
        return (
            <div className={`todoCardWrapper`}>
                <Card className={`todoCards clearfix kanban-card-empty`} variant="outlined" style={{ borderLeft: `5px solid #c1c1c1` }}>
                    <CardContent>
                        <NoDataFound msg={`${stage} todo is not found.`} />
                    </CardContent>
                </Card>
            </div>
        );
    };

    const allowDrop = (ev, prevDivData, stage) => {
        if (cardStepOver.id !== prevDivData.id && stage !== 'dues') {
            setCardStepOver({
                id: prevDivData.id,
                step_num: prevDivData.step_num,
                stage
            });
        }
        ev.preventDefault();
    };

    const renderCards = (data, stage) => {
        const { secondary, warning, success, grey } = theme.palette;

        return data.map((plannedVal, pv) => {
            const todoid = plannedVal.id;
            const name = plannedVal.name;
            const title = plannedVal.title;
            const progress = plannedVal.progress;
            const catname = plannedVal?.category?.name;
            const todoType = plannedVal?.todo_type;
            let color = plannedVal.color;
            let cardStyle = {};
            if (!plannedVal.color || plannedVal.color === '#FFFFFF') {
                color =
                    (stage === 'planned' && secondary.main) ||
                    (stage === 'progress' && warning.dark) ||
                    (stage === 'completed' && success.main) ||
                    (stage === 'dues' && grey[500]);
                cardStyle = { borderLeft: `5px solid ${color}` };
            } else {
                cardStyle = {
                    color: invertColor(color, true),
                    background: `linear-gradient(180deg, ${color}, #FFFFFF)`
                };
            }
            const dueDate = plannedVal.due_date && moment.utc(plannedVal.due_date).local().format('L LT');
            return (
                <TodoCardWrapperBox
                    index={todoid}
                    key={`${todoid}_${pv}_stagecards`}
                    className={`${todoSelectionList.indexOf(todoid) > -1 ? 'selected' : ''}`}
                >
                    <SelectedTodoCardBtnBox onClick={() => handleTodoSelection(todoid)} className="todocard-btn-box">
                        <TickStyledIconBtn color="primary" aria-label="add an alarm">
                            <CheckCircleRoundedIcon />
                        </TickStyledIconBtn>
                    </SelectedTodoCardBtnBox>

                    <StyledTodoCardParent
                        className={`todoCards clearfix`}
                        variant="outlined"
                        att_prev_index={todoid}
                        att_prev_step={plannedVal.step_num}
                        att_prev_stage={stage}
                        onTouchStart={(e) => {
                            startTouch = e.timeStamp;
                            checkFirstMove = true;
                        }}
                        onDragStart={(ev) => {
                            // Add this element's id to the drag payload so the drop handler will
                            // know which element to add to its tree
                            ev.dataTransfer.setData('text', ev.target.id);
                            ev.dataTransfer.effectAllowed = 'move';
                        }}
                        // onTouchStart={(event) => handleDragStart(event)}
                        id={`kanban-card` + todoid}
                        draggable={stage !== 'dues' && true}
                        onDragEnd={(e) => handleDropEnd(e, plannedVal, stage, 'drag')}
                        onTouchEnd={(e) => {
                            if (allowEndApi) {
                                allowEndApi = false;
                                setStopScrolling(true);
                                handleDropEnd(e, plannedVal, stage, 'touch');
                            }
                        }}
                        // onDragOver={(ev) => {
                        //   ev.preventDefault();
                        //   // Set the dropEffect to move
                        //   ev.dataTransfer.dropEffect = "move";
                        // }}
                        onDragOver={(event) => allowDrop(event, plannedVal, stage)}
                        style={cardStyle}
                        // onDrop={(event) => drop(event)}
                        onTouchMoveCapture={(e) => {
                            if (checkFirstMove && (e.timeStamp - startTouch) / 1000 >= 2) {
                                checkFirstMove = false;
                                allowEndApi = true;
                                setStopScrolling(true);
                            }
                        }}
                    >
                        <StyledSettingCardHeader
                            sx={{ padding: '4px' }}
                            id={`todo-${todoid}`}
                            onClick={() => props.handleEditTodo(plannedVal)}
                            action={
                                <IconButton aria-label="settings">
                                    <CreateIcon />
                                </IconButton>
                            }
                        />
                        <CardContent className="cardcontenttodo">
                            <TodoTitleTypography>
                                <span className="priorityBlock">
                                    <img src={`img/${plannedVal.priority}-prio-20.png`} alt="priority icon" className="slick-image" />
                                </span>
                                <span>{limitLetters(name, 30)}</span>
                            </TodoTitleTypography>
                            <TodoDateWrapperTypography
                                // onClick={() => openDateTime()}
                                color="textSecondary"
                                variant="body2"
                            >
                                {dueDate ? (
                                    <>
                                        <span className={'datePickerIcon'}>
                                            <DateRangeIcon />
                                        </span>
                                        <span className={'todoCardDate'}>{dueDate}</span>
                                    </>
                                ) : (
                                    ''
                                )}
                            </TodoDateWrapperTypography>
                            <TodoDateWrapperTypography color="textSecondary" variant="body2">
                                {catname ? <TypographyTodoCount>{limitLetters(catname, 15)}</TypographyTodoCount> : ''}
                                {todoType ? <TypographyTodoCount>{limitLetters(todoType, 15)}</TypographyTodoCount> : ''}
                            </TodoDateWrapperTypography>
                            <Box style={{ width: '100%' }}>
                                <Typography variant="body2">Progress</Typography>
                                <ProgressTodoWrapperBox>
                                    <CustomSlider
                                        id={`progressTodo-${todoid}`}
                                        defaultValue={props.todoProgress[todoid] || progress || 0}
                                        onChange={(e) => props.handleProgressChange(e, plannedVal)}
                                        min={0}
                                        valueLabelDisplay="off"
                                        max={100}
                                    />
                                    <Typography variant="body2" className="progressPer">
                                        {props.todoProgress[todoid] || progress || 0}%
                                    </Typography>
                                </ProgressTodoWrapperBox>
                            </Box>
                        </CardContent>
                    </StyledTodoCardParent>
                </TodoCardWrapperBox>
            );
        });
    };
    //handle delete todo button
    const handleDeleteConfirmation = () => {
        if (todoSelectionList.length === 0) {
            toast.error('Please select at least one todo to delete.');
        } else {
            setConfirmDeleteDialog(true);
        }
    };

    const handleCancelDeleteConfirmation = () => {
        handlePopoverClose();
        setConfirmDeleteDialog(false);
    };

    const handleDeleteTodos = () => {
        setTodoSelection([]);
        setSearchValue('');
        dispatch(deleteTodo(todoSelectionList));
        handleCancelDeleteConfirmation();
    };

    // call api to get searched data
    const handleSearchOnChange = (e) => {
        const inputValue = e.target.value;
        setSearchValue(inputValue);
        if (_searchtimeout) clearTimeout(_searchtimeout);
        _searchtimeout = setTimeout(() => {
            dispatch(searchTodo({ search_value: inputValue }));
        }, 1000);
    };
    return (
        <>
            {todoProps.enableGif && !props.isPopupOpen ? <TodoGifs dispatch={dispatch} /> : ''}
            {todoProps.loader && !props.isPopupOpen ? <Loader /> : ''}
            {/* style={{ overflow: stopScrolling ? 'hidden' : 'scroll' }} */}
            <Box>
                <TodoActionGrid container direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                    <TodosSearchBoxGrid container>
                        <Grid item xs={3} container>
                            <CustomInput
                                id="tableSearch"
                                type="search"
                                labelText=""
                                placeholder="Todo Search"
                                value={searchValue}
                                onChange={(e) => handleSearchOnChange(e)}
                                CustomInputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Grid>
                    </TodosSearchBoxGrid>
                    <Grid item xs={'auto'}>
                        <SettingBoxWrapper className={`resizeSvg`}>
                            <CustomButton size="xs" onClick={handlePopoverOpen} simple aria-describedby={settingId}>
                                <SettingsIcon color="white" /> Action
                            </CustomButton>
                        </SettingBoxWrapper>
                        <Popover
                            id={settingId}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handlePopoverClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center'
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center'
                            }}
                        >
                            <List component="nav" aria-label="main mailbox folders">
                                <ListItem button onClick={(event) => handleDeleteConfirmation()}>
                                    <ListItemText primary="Delete" />
                                </ListItem>
                            </List>
                        </Popover>
                    </Grid>
                    {/* </GridContainer> */}
                    {/* <GridItem xs={12}>
            <GridContainer direction='column' xs>
              <GridItem xs className={todoClasses.searchBox}>
                {/* <GridItem xs={12} sm={3} > */}

                    {/* </GridItem> */}
                    {/* <Paper elevation={0} style={{ height: "200px" }}> */}
                    {/* <Typography variant='h6' component='h2' gutterBottom>
                Most Used Categories
              </Typography>
              <div className={todoClasses.mostUsedCat}>
                <Typography gutterBottom className={todoClasses.todoCount}>
                  Debt
                </Typography>
                <Typography gutterBottom className={todoClasses.todoCount}>
                  budget
                </Typography>
                <Typography gutterBottom className={todoClasses.todoCount}>
                  investments
                </Typography>
              </div> */}
                    {/* <AreaChart data={categoryChart} enableArea={false} />
              </GridItem>

              <GridItem></GridItem>
            </GridContainer>
          </GridItem> */}
                </TodoActionGrid>
                <TodoGridContainer spacing={0} style={{ overflow: stopScrolling ? 'hidden' : 'scroll' }}>
                    {stages.map((stage, si) => {
                        const currStage = Object.keys(todoList).length
                            ? Object.keys(todoList.stage).length
                                ? todoList.stage[stage[0]]
                                : []
                            : [];
                        const stageCount =
                            stage[0] === 'planned' || stage[0] === 'progress'
                                ? Object.keys(currStage).length > 0
                                    ? currStage['today'].length +
                                      currStage['nextWeek'].length +
                                      currStage['thisWeek'].length +
                                      currStage['later'].length +
                                      currStage['nodates']?.length
                                    : 0
                                : currStage.length;
                        return (
                            <StageColumnPaper
                                key={si + 'stages'}
                                onDragEnter={() => {
                                    handleDropEnter(stage[0]);
                                }}
                                onDragExit={(e) => {}}
                                elevation={3}
                                className={`${`todo${stage[1]}Stage`}`}
                            >
                                <div className="todoCardHeader">
                                    <Typography variant="h6" component="h2" gutterBottom>
                                        {stage[1]}
                                        <TypographyTodoCount gutterBottom>{stageCount || 0}</TypographyTodoCount>
                                    </Typography>
                                    <Divider />
                                </div>
                                <AddTodoBlockBox onClick={() => props.handleAddTodo(stage[0])}>
                                    <CustomButton size="xs" simple>
                                        <AddCircleIcon color="white" /> Add Todo
                                    </CustomButton>
                                    <Divider />
                                </AddTodoBlockBox>
                                <TodoBodyCardBox>
                                    {stage[0] === 'planned' || stage[0] === 'progress'
                                        ? stageCount !== 0 && Object.keys(currStage).length > 0
                                            ? timeDuration.map((timeDur, td) => {
                                                  return currStage[timeDur[0]]?.length ? (
                                                      <Fragment key={td + 'bodyColumn'}>
                                                          <TimeDurationWrapperBox>
                                                              <Typography gutterBottom component="span">
                                                                  {timeDur[1]}
                                                              </Typography>
                                                          </TimeDurationWrapperBox>
                                                          {renderCards(currStage[timeDur[0]], stage[0])}
                                                      </Fragment>
                                                  ) : (
                                                      ''
                                                  );
                                              })
                                            : stageCount === 0
                                            ? renderEmptyCard(stage[1])
                                            : ''
                                        : currStage.length
                                        ? renderCards(currStage, stage[0])
                                        : renderEmptyCard(stage[1])}
                                </TodoBodyCardBox>
                            </StageColumnPaper>
                        );
                    })}
                </TodoGridContainer>
                <Dialog
                    open={confirmDeleteDialog}
                    onClose={handleCancelDeleteConfirmation}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{'Are you sure?'}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Do you really want to delete these todo(s) ? This process can not be undone.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <CustomButton onClick={handleCancelDeleteConfirmation}>Cancel</CustomButton>
                        <CustomButton autoFocus onClick={handleDeleteTodos}>
                            Delete
                        </CustomButton>
                    </DialogActions>
                </Dialog>
            </Box>
        </>
    );
};
