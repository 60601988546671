import EnhancedTable from "components/CommonComponent/CommonTable/EnhancedTable";
import NoDataFound from "components/CommonComponent/NoDataFound";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Card, CardContent, Rating } from "@mui/material";
import { fetchFeedback } from "redux/actions/SettingActions";
import moment from "moment";
const FeedbackSetting = (props) => {
  const { classes, setting } = props;
  const dispatch = useDispatch();
  const [moduleColl, setModuleColl] = React.useState([]);
  useEffect(() => {
     //to create array of column to pass in table
    if (setting.feedbackList.length>0) {
       const ignoreFields = ["id"];
      let column = [];
      Object.keys(setting.feedbackList[0]).forEach(keys => {
        const formattedKey = keys.replaceAll(/_/g, " ");
        if (!ignoreFields.includes(keys)) {
          column.push({
            Header: (keys === 'feedback_cate' && 'Category' )|| formattedKey || keys,
            accessor: keys,
            disableFilters: true,
            Cell: ({ value, row, cell }) =>{
              return cell.column.id === 'rate' ? (
                  <div className={classes.ratingdiv} style={{ alignItems: 'inherit' }}>
                      <Rating
                          key={'rate' + row.original.id}
                          name={'rate' + row.original.id}
                          value={value}
                          precision={1}
                          size="small"
                          readOnly
                      />{' '}
                  </div>
              ) : (
                  cell.column.id === 'created_at'?
                  moment.utc(value).local().format("MM/DD/YYYY h:mm a")
                  :(value || '-')
              );
            }
          });
        }
      });
      setModuleColl(column);
    }
  }, [setting.feedbackList]);
  useEffect(() => {
    /// to fetch the default data on page load
    dispatch(fetchFeedback())
  }, []);
  return (
    <>
      <Card elevation={2}>
              <CardContent>
                {setting.feedbackList.length > 0 ? (
                    <EnhancedTable
                      enableSearch={false}
                      // handleLoadData={getInvestDashboardData}
                      columns={moduleColl}
                      data={setting.feedbackList || []}
                      enableSort={true}
                    />
                ) : (
                  <NoDataFound msg='Result will be shown after adding module permission.' />
                )}{" "}
            </CardContent>
            </Card>
    </>
  );
};

export default FeedbackSetting;
