import React, { useEffect } from "react";
import { StyledPermissionDialog } from "assets/jss/GeneralStyle";
import Typography from '@mui/material/Typography';
import { CardActions, CardContent, CardMedia, IconButton } from '@mui/material';
import { BannerSigninButton } from "assets/jss/BannerStyle";
import { useNavigate } from 'react-router-dom';
import { CalSignUpCard } from "assets/jss/GeneralStyle";
import { StyledCloseDialog } from "assets/jss/GeneralStyle";
import { StyledDialogContentBox } from "assets/jss/GeneralStyle";
import { Close } from "@mui/icons-material";
import { useState } from "react";
import { getCookie } from "utils/Utils";

function DoRegistration() {
  const navigate = useNavigate();
  const [time, setTime] = useState(15); // Initially 15 seconds
const [show, setShow] = useState(false);

const showDialog = () => {
  setTimeout(() => {
    setShow(true);
  }, time * 1000);
};

const closeDialog = () => {
  if (time < 86400) { // 86400 seconds = 24 hours
    setTime(86400); // Set delay to 24 hours
  }
  setShow(false);
  showDialog();
};

useEffect(() => {
  if (!getCookie("auth_token")) {
    showDialog();
  }
}, []);


  return (
    <StyledPermissionDialog open={show}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <CalSignUpCard >
        <StyledDialogContentBox>
          <StyledCloseDialog>
            <IconButton onClick={closeDialog}>
              <Close />
            </IconButton>
          </StyledCloseDialog>
          <CardMedia component="img" image="img/cal-signin-img.png" title="cal-signin-img" className="signin-img" />
          <CardContent>
            <Typography variant="heading" display={'block'}>
              Looking for more features?
            </Typography>
            <Typography variant="subheading">Register your account now, save and track all your finances</Typography>
          </CardContent>
          <CardActions>
            <BannerSigninButton
              variant="contained"
              className="cal-signup-btn"
              align="center"
              sx={{ mb: 3.3 }}
              onClick={() => navigate('/sign-up')}
            >
              Sign Up
            </BannerSigninButton>
          </CardActions>
        </StyledDialogContentBox>
      </CalSignUpCard >
    </StyledPermissionDialog>
  )
}

export default DoRegistration