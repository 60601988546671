import * as ActionTypes from "./ActionTypes";
import { apiCall, handleError } from "utils/Utils";
import Globals from "Globals.js";
import { toast } from "react-toastify";

//handle success of api
export const fetchPeertoPeerCollSuccess = (val) => {
  return {
    type: ActionTypes.FETCH_PEERTO_PEER_COLL_SUCCESS,
    data: val,
  };
};

// handle api error
export const fetchPeertoPeerCollError = () => {
  return {
    type: ActionTypes.FETCH_PEERTO_PEER_COLL_ERROR,
  };
};

/**
 * @author disha
 * @param {*} data
 * @returns
 * @use to fetch the investment dashboard data
 */
export const fetchPeertoPeerCollData = (data) => async (dispatch) => {
  try {
    dispatch({
      type: ActionTypes.FETCH_PEERTO_PEER_COLL_INIT
    });
    const apiResponse = await apiCall(
      `${Globals.API_ROOT_URL}peertopeercollaborator`,
      "get"
    );
    if (apiResponse !== undefined && apiResponse.status === 200) {
      dispatch(fetchPeertoPeerCollSuccess(apiResponse.data.data));
    } else {
      if (apiResponse.status === 401) {
        dispatch(fetchPeertoPeerCollSuccess({}));
      }
      else {
        handleError(apiResponse, dispatch);
        dispatch(fetchPeertoPeerCollError());
      }
    }
  } catch (error) {
    toast.error(Globals.errorMsg);
    dispatch(fetchPeertoPeerCollError());
    throw error;
  }
};

//handle success of api
export const submitPeertoPeerSuccess = (val) => {
  return {
    type: ActionTypes.SUBMIT_PEERTO_PEER_SUCCESS,
    data: val,
  };
};

// handle api error
export const submitPeertoPeerError = () => {
  return {
    type: ActionTypes.SUBMIT_PEERTO_PEER_ERROR,
  };
};

/**
 * @author disha
 * @param {*} data
 * @returns
 * @use to fetch the investment dashboard data
 */
export const submitPeertoPeerData = (data, type) => async (dispatch) => {
  try {
    dispatch({
      type: ActionTypes.SUBMIT_PEERTO_PEER_INIT
    });
    const apiResponse = await apiCall(`${Globals.API_ROOT_URL}peertopeercollaborator`, type, data);
    if (apiResponse !== undefined && apiResponse.status === 200) {
      toast.success(apiResponse.data.data.message);
      dispatch(submitPeertoPeerSuccess());
      dispatch(fetchPeertoPeerCollData());
      //   dispatch(submitPeertoPeerSuccess(apiResponse.data.data));
    } else {
      handleError(apiResponse, dispatch);
      dispatch(submitPeertoPeerError());
    }
  } catch (error) {
    toast.error(Globals.errorMsg);
    dispatch(submitPeertoPeerError());
    throw error;
  }
};
//handle success of api
export const submitPeertoPeerCalSuccess = (val) => {
  return {
    type: ActionTypes.SUBMIT_PEERTO_PEER_CAL_SUCCESS,
    data: val,
  };
};

// handle api error
export const submitPeertoPeerCalError = () => {
  return {
    type: ActionTypes.SUBMIT_PEERTO_PEER_CAL_ERROR,
  };
};

/**
 * @author disha
 * @param {*} data
 * @returns
 * @use to fetch the investment dashboard data
 */
export const submitPeertoPeerCalData = (data, type) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.SUBMIT_PEERTO_PEER_CAL_INIT });
    const apiResponse = await apiCall(`${Globals.API_ROOT_URL}peertopeercal`, type, data);
    if (apiResponse !== undefined && apiResponse.status === 200) {
      dispatch(submitPeertoPeerCalSuccess(apiResponse.data.data));
    } else {
      handleError(apiResponse, dispatch);
      dispatch(submitPeertoPeerCalError());
    }
  } catch (error) {
    toast.error(Globals.errorMsg);
    dispatch(submitPeertoPeerCalError());
    throw error;
  }
};
