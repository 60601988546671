import React, { useEffect } from "react";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import RenderField from "utils/RenderField";
import { CalAccordion } from "assets/jss/GeneralStyle";
import { Grid } from "@mui/material";
import { AccordianGridItem } from "assets/jss/GeneralStyle";
const fieldsDetail = [
    {name : "taxRate", fieldName : "Tax Rate ", type : 'number' , prefix:'%', defaultValue : 25,customPatternName:'decimalRate'},
  { name: "mortalityAge", fieldName: "Mortality Age", type: 'text',placeholder: "Mortality Age",maxLength:'3',customPatternName:'number'  },
  { name: "investReturn", fieldName: "Investment return", type: 'number',prefix:'%', placeholder: "3.0",customPatternName:'decimalRate' },
  { name: "inflation", fieldName: "Inflation", type: 'number',prefix:'%', placeholder: "2.0",customPatternName:'decimalRate' },
];
const OptionalAssumption = ({ control, handleOnChange, setValue }) => {
    return (
        <CalAccordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                <Typography className="accordion-title">Optional Assumption</Typography>
            </AccordionSummary>

            <AccordionDetails>
                <Grid container spacing={2}>
                    {fieldsDetail.map((val, i) => {
                        return (
                            <>
                                <AccordianGridItem item xs={12} sm={5} md={5} lg={5} disablePadding>
                                    <Typography gutterBottom>{val.fieldName}</Typography>
                                </AccordianGridItem>
                                <AccordianGridItem item xs={12} sm={7} md={7} lg={7} disablePadding key={i + '_optionalassumptions'}>
                                    <RenderField
                                        name={val.name}
                                        control={control}
                                        fieldName={val.fieldName}
                                        id={val.name + '_optionalassumptions'}
                                        type={val.type}
                                        // required={val.required || false}
                                        placeholder={val.placeholder}
                                        setValue={setValue}
                                        handleOnChange={handleOnChange}
                                        {...val}
                                    />
                                </AccordianGridItem>
                            </>
                        );
                    })}
                    
                </Grid>
            </AccordionDetails>
        </CalAccordion>
    );
};

export default OptionalAssumption;
