import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import React, { useEffect, useState } from "react";
import { triggerTodoGif } from "redux/actions/TodoActions";
import { StyledTodoBackdrop } from "assets/jss/TodoStyle";
const gifArr = [
  "https://c.tenor.com/g0cnLPpQJHsAAAAi/great-job.gif",
  "https://c.tenor.com/6uQexcKj0SMAAAAi/great-good.gif",
  "https://c.tenor.com/2BynbZdWAGYAAAAi/ang-ku-kueh-girl-akkg.gif",
  "https://c.tenor.com/pniOLMRGtb8AAAAi/snoopy-dancing.gif",
  "https://c.tenor.com/sqzO66Tv7eMAAAAi/cute-bear.gif",
  "https://c.tenor.com/m6UfUAgWoMAAAAAi/animal-dance.gif",
  "https://c.tenor.com/cK-FKS3X5SAAAAAi/awesome-gojill.gif",
  "https://c.tenor.com/In22Z8GS5I0AAAAi/awesome-johnny-loughran.gif",
  "https://c.tenor.com/1HrwGLqYR6UAAAAi/bt21-rj.gif",
  "https://c.tenor.com/Ymh9aBtYvnIAAAAi/idol-gorilla.gif",
  "https://c.tenor.com/VodkIJ3sYfUAAAAi/stickergiant-great-job.gif",
  "https://c.tenor.com/9m7YAQPhZMgAAAAi/cute-bear.gif",
  "https://c.tenor.com/VvGiMvtdX6IAAAAi/mothers-day-love-you-mom.gif",
  "https://c.tenor.com/UJakVJChQGEAAAAi/quickbooks-backing-you.gif",
  "https://c.tenor.com/rf4A7P9SZxMAAAAi/%E4%B8%80%E4%BC%91%E3%81%BF-%E4%BC%91%E6%86%A9.gif",
];
let _timeout = 0;
export default function TodoGifs({ dispatch }) {
  const [isGifvisible, setGifVisible] = useState(true);
  const [currGifSrc, setCurrGifSrc] = useState(gifArr[0]);

  // close gif popup
  const handleTodoGifClose = () => {
    setGifVisible(false);
  };
  useEffect(() => {
    //get the random gif and save in state to display
    setCurrGifSrc(gifArr[Math.floor(Math.random() * gifArr.length)]);
    if (isGifvisible) {
      if (_timeout) clearTimeout(_timeout);
      _timeout = setTimeout(() => {
        setGifVisible(false);
        // reset gif flag which used to on after submit/update the todo
        dispatch(triggerTodoGif(false));
      }, 3000);
    }
    return () => {
      if (_timeout) clearTimeout(_timeout);
    };
  }, []);
  return (
      <StyledTodoBackdrop
        open={isGifvisible}
        onClick={handleTodoGifClose}>
        <div className={'gifWrapper'}>
          <img src={currGifSrc} width='452' height='498' alt='todo gifs' />
          <span>
            <IconButton
              className='gifClosebtn'
              onClick={() => handleTodoGifClose()}>
              <CancelIcon />
            </IconButton>
          </span>
        </div>
      </StyledTodoBackdrop>
  );
}
