import React, { useState, useEffect } from 'react';
import { Box, Card, CardContent, Grid, Tooltip, Typography } from '@mui/material';
import { BiaxialRetirementChart } from './BiaxialRetirementChart';
import NoDataFound from 'components/CommonComponent/NoDataFound';
import { OutputBox } from 'assets/jss/GeneralStyle';
import { abbreviate_number, numberWithCommas } from 'utils/Utils';
import * as tooltip from 'Tooltip.json';
import CustomTooltip from 'components/CommonComponent/CustomTooltip';
import { Bar, CartesianGrid, ComposedChart, Legend, Line, XAxis, YAxis } from 'recharts';

const RetirementOutput = (props) => {
    const { data, classes } = props;
    const [formatOutputData, setOutputData] = useState([]);
    const [chartData, setChartData] = useState([]);
    const startingYear = new Date().getFullYear();
    const isMobile = window.innerWidth < 768;
    const chartWidth = isMobile ? window.innerWidth * 0.9 : window.innerWidth * 0.51;

    // useEffect(() => {
    //   let fieldLists = [],
    //     chartDataLists = { "bardata": [] },
    //     minVal = [],
    //     minValObj = {},
    //     barchartDataArr = [];
    //   if (data.length > 0) {
    //     // to list all fields which needs to show as disable input box
    //     fieldLists = data.filter((val) => typeof val.value !== "object");

    //     // to create format for chart
    //     data.forEach((val, i) => {
    //       if (typeof val.value == "object") {
    //         if (val.label === "Year by year Networth") {
    //           let innerData = [];
    //           let yList = [];
    //           let objToAppend = {
    //             id: val.label,
    //           };
    //           // create format for chart which will have x and y axis data with id
    //           if (val) {
    //             val.value.filter((innerVal, innerIndex) => {
    //               let currVal = startingYear + innerIndex;
    //               yList.push(innerVal);
    //               return innerData.push({ x: currVal, y: innerVal });
    //             });
    //           }
    //           const individualMinValue = Math.min(...yList);
    //           // to find every chart's min value from array and store it
    //           minVal.push(individualMinValue);
    //           // add individual min value
    //           minValObj = {
    //             ...minValObj,
    //             [val.label]: individualMinValue,
    //           };
    //           objToAppend = {
    //             ...objToAppend,
    //             data: innerData,
    //             min: individualMinValue,
    //           };
    //           if (chartDataLists["data"]) {
    //             chartDataLists["data"].push(objToAppend);
    //           } else {
    //             chartDataLists["data"] = [];
    //             chartDataLists["data"].push(objToAppend);
    //           }
    //           return chartDataLists;
    //         } else {
    //           val.value.filter((innerVal, innerIndex) => {
    //             let currVal = startingYear + innerIndex;
    //             if (barchartDataArr[innerIndex]) {
    //               if (barchartDataArr[innerIndex].label === currVal) {
    //                 return (barchartDataArr[innerIndex] = {
    //                   ...barchartDataArr[innerIndex],
    //                   [val.label]: innerVal,
    //                 });
    //               }
    //             } else {
    //               return barchartDataArr.push({ label: currVal, [val.label]: innerVal });
    //             }
    //           });

    //         }
    //       }
    //     });
    //     chartDataLists["bardata"] = barchartDataArr;
    //     // to find min value from array of all chart's min values (to findout lowest one)
    //     chartDataLists["min"] = Math.min(...minVal);
    //     // store min value object to match lowest min value belongs to which id to handle hide and show chart on click on legends
    //     chartDataLists["minValObj"] = minValObj;
    //     setOutputData(fieldLists);
    //     setChartData(chartDataLists);
    //   }
    // }, [data]);

    useEffect(() => {
        let fieldLists = [],
            chartDataLists = { bardata: [] },
            minVal = [],
            minValObj = {},
            barchartDataArr = [];

        if (data.length > 0) {
            fieldLists = data.filter((val) => typeof val.value !== 'object');
            data.forEach((val, i) => {
                if (typeof val.value === 'object') {
                    if (val.label === 'Year by year Networth') {
                        let yList = [];
                        val.value.forEach((innerVal, innerIndex) => {
                            const currYear = startingYear + innerIndex;
                            const scaledInnerVal = innerVal;
                            barchartDataArr.push({
                                name: currYear,
                                uv: scaledInnerVal,
                                amt: scaledInnerVal
                            });
                            yList.push(scaledInnerVal);
                        });
                        const individualMinValue = Math.min(...yList);
                        minVal.push(individualMinValue);
                        minValObj[val.label] = individualMinValue;
                    } else {
                        val.value.forEach((innerVal, innerIndex) => {
                            const currYear = startingYear + innerIndex;
                            if (barchartDataArr[innerIndex]) {
                                barchartDataArr[innerIndex][val.label] = innerVal;
                            } else {
                                const newData = { name: currYear };
                                newData[val.label] = innerVal;
                                barchartDataArr.push(newData);
                            }
                        });
                    }
                }
            });

            chartDataLists.bardata = barchartDataArr;
            chartDataLists.min = Math.min(...minVal);
            chartDataLists.minValObj = minValObj;

            setOutputData(fieldLists);
            setChartData(barchartDataArr);
        }
    }, [data]);

    function formatToMillion(value) {
        return `${(value / 1000000).toFixed(1)}M`;
    }

    return (
        <>
            <Card elevation={2} sx={{ mb: 3.5 }}>
                <CardContent>
                    <Typography variant="h6" sx={{ mb: 2, display: 'flex' }} gutterBottom>
                        Summary Output
                    </Typography>
                    <Box id="outputWrapper" className="widget-full-width">
                        {data.length === 0 ? (
                            <NoDataFound />
                        ) : (
                            <Grid container spacing={2}>
                                {formatOutputData.map((val, i) => {
                                    const AddSuffix = (val) => {
                                        let allowedSuffix = '';
                                        if (val === 'Investment return rate required to reach goal') {
                                            allowedSuffix = '%';
                                        }
                                        return allowedSuffix;
                                    };
                                    const AddPrefix = (val) => {
                                        let allowedPrefix = '';

                                        if (val !== 'Mortality Age' && val !== 'Investment return rate required to reach goal') {
                                            allowedPrefix = '$';
                                        }

                                        return allowedPrefix;
                                    };
                                    const currValue =
                                        val.label !== 'Mortality Age' && typeof val.value === 'number' ? val.value.toFixed(2) : val.value;
                                    return typeof val.value !== 'object' ? (
                                        <React.Fragment key={i + 'RetirementOutput'}>
                                            <Grid item xs={12} md={4}>
                                                <OutputBox>
                                                    <Typography variant="body1" className="outputLabel" component="p">
                                                        {val.label}
                                                        {tooltip.default?.[val.label] && (
                                                            <CustomTooltip
                                                                tooltipText={tooltip.default?.[val.label]}
                                                                customTooltipStyle={{ maxWidth: 450 }}
                                                                label={val.label}
                                                            />
                                                        )}
                                                    </Typography>
                                                    <Typography variant="h6">
                                                        {AddPrefix(val.label)}
                                                        {numberWithCommas(currValue)}
                                                        {AddSuffix(val.label)}
                                                    </Typography>
                                                </OutputBox>
                                            </Grid>
                                        </React.Fragment>
                                    ) : (
                                        ''
                                    );
                                })}
                            </Grid>
                        )}
                    </Box>
                </CardContent>
            </Card>
            {data.length !== 0 ? (
                <Card elevation={2}>
                    <CardContent>
                        <Typography variant="h6" sx={{ mb: 2, display: 'flex' }}>
                            Summary Graph
                        </Typography>
                        <Grid container spacing={2}>
                            {Object.keys(chartData).length > 0 ? (
                                <Grid item xs={12} md={12}>
                                    {/* <BiaxialRetirementChart classes={classes} bardata={chartData.bardata} linedata={chartData} /> */}
                                    <ComposedChart
                                        width={chartWidth}
                                        height={window.innerHeight * 0.78}
                                        data={chartData || []}
                                        margin={{ right: isMobile ? 10 : 0, left: isMobile ? -20 : 0 }}
                                    >
                                        <CartesianGrid stroke="#f5f5f5" />
                                        <XAxis dataKey="name" />
                                        <YAxis yAxisId="left" tickFormatter={(tick) => numberWithCommas(abbreviate_number(tick))} />
                                        <YAxis yAxisId="right" orientation="right" tickFormatter={formatToMillion} />
                                        <Tooltip
                                            formatter={(value, name) => {
                                                if (name === 'uv') {
                                                    return `${value}K`;
                                                }
                                                return value;
                                            }}
                                        />
                                        <Legend formatter={(value) => (value === 'uv' ? 'Year by year Networth' : value)} />
                                        <Bar yAxisId="left" dataKey="Future Expense" barSize={20} fill="#ff7300" />
                                        <Bar yAxisId="left" dataKey="Future Income" barSize={20} fill="#82ca9d" />
                                        <Line yAxisId="right" type="monotone" dataKey="uv" stroke="#413ea0" />
                                    </ComposedChart>
                                </Grid>
                            ) : (
                                ''
                            )}
                        </Grid>
                    </CardContent>
                </Card>
            ) : (
                ''
            )}
        </>
    );
};

export default RetirementOutput;
