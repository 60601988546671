import * as ActionTypes from "./ActionTypes";
import { apiCall, handleError } from "utils/Utils";
import Globals from "Globals.js";
import { toast } from "react-toastify";

/**
 * @author disha
 * @param {*} data
 * @returns
 * @use submit option pricing calculator data
 */
export const submitOptionPricingCal = (data) => async (dispatch) => {
  try {
    dispatch(OptionPricingCalInit());
    const apiResponse = await apiCall(
      `${Globals.API_ROOT_URL}optionpricingcalculator`,
      "post",
      data
    );
    if (apiResponse !== undefined && apiResponse.status === 200) {
      dispatch(OptionPricingCalSuccess(apiResponse.data));
    } else {
      handleError(apiResponse,dispatch);
      dispatch(OptionPricingCalError());
    }
  } catch (error) {
    toast.error(Globals.errorMsg);
    dispatch(OptionPricingCalError());
    throw error;
  }
};
// handle loader when api started to call
export const OptionPricingCalInit = () => {
  return {
    type: ActionTypes.OPTION_PRICING_CAL_INIT,
  };
};
//handle success of api
export const OptionPricingCalSuccess = (val) => {
  return {
    type: ActionTypes.OPTION_PRICING_CAL_SUCCESS,
    data: val,
  };
};

// handle api error
export const OptionPricingCalError = () => {
  return {
    type: ActionTypes.OPTION_PRICING_CAL_ERROR,
  };
};
