import React, { useState, useEffect } from 'react';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import WidgetsIcon from '@mui/icons-material/Widgets';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import { createStyles, Tab, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles/';
import { Container } from '@mui/system';
import { MainBox } from 'assets/jss/GeneralStyle';
import CustomInputStyles from 'assets/jss/material-kit-react/components/customInputStyle.js';
import styles from 'assets/jss/material-kit-react/views/generalPage';
import settingstyles from 'assets/jss/material-kit-react/views/settingPage.js';
import { SettingTabBox, SettingTabs } from 'assets/jss/SettingsStyle';
import Loader from 'components/CommonComponent/Loader';
import { useSelector, useDispatch } from 'react-redux';
import SwipeableViews from 'react-swipeable-views';
import { a11yProps } from 'utils/Utils';
import AccessControl from 'views/Authorization/AccessControl';
import PermissionDenied from 'views/Authorization/PermissionDenied';
import PortfolioTabPanel from 'views/PortfolioValueUpload/PortfolioTabPanel';
import AdminPanel from './Admin';
import NotificationSetting from './NotificationSetting';
import { ProfileForm } from './ProfileForm';
import { SubscriptionIndex } from './SubscriptionIndex';
import WidgetSetting from './WidgetSetting';
import { IntegrationIndex } from './IntegrationIndex';
import { setActiveSettingsTab } from 'redux/actions/SettingActions';
import Meta from 'components/meta';
const mergedStyle = makeStyles((theme) =>
    createStyles({
        ...settingstyles(theme),
        ...styles(theme),
        ...CustomInputStyles
    })
);

const tabs = [
    { label: 'Profile', icon: <PermIdentityIcon /> },
    { label: 'Notification', icon: <NotificationsNoneIcon /> },
    { label: 'Widgets', icon: <WidgetsIcon /> },
    { label: 'Integrations', icon: <IntegrationInstructionsIcon /> }
];

export default function SettingIndex() {
    const setting = useSelector((state) => state.setting);
    const { loader: blogsLoader } = useSelector((state) => state.blogReducer);
    const theme = useTheme();
    const dispatch = useDispatch();
    const classes = mergedStyle();
    const activeSettingsTab = setting.activeSettingsTab;
    const [scrollPosition, setScrollPosition] = useState(0);
    const [activeTab, setActiveTab] = useState(0);

    const handleTabChange = (event, newValue) => dispatch(setActiveSettingsTab(newValue));

    useEffect(() => {
        const scrollContainer = document.querySelector('.scroll');
        const lastTab = document.querySelector('.scroll > div:last-child');
        if (!scrollContainer || !lastTab) return;

        const containerRight = scrollContainer.getBoundingClientRect().right;
        const lastTabRight = lastTab.getBoundingClientRect().right;

        const isLastTabVisible = lastTabRight <= containerRight || lastTabRight <= window.innerWidth;
        if (isLastTabVisible) {
            setScrollPosition(3);
        }
    }, [tabs]);

    const handleScroll = (event) => {
        const { scrollLeft, scrollWidth, clientWidth } = event.target;
        const isEnd = scrollLeft + clientWidth >= scrollWidth - 1;
        if (scrollLeft === 0) {
            setScrollPosition(0);
        } else if (isEnd) {
            setScrollPosition(2);
        } else {
            setScrollPosition(1);
        }
    };

    const handleTabNavigation = (direction) => {
        const scrollContainer = document.querySelector('.scroll');
        if (!scrollContainer) return;

        const { scrollLeft, clientWidth } = scrollContainer;

        if (direction === 'backward') {
            scrollContainer.scrollTo({
                left: Math.max(0, scrollLeft - clientWidth),
                behavior: 'smooth'
            });
        } else if (direction === 'forward') {
            scrollContainer.scrollTo({
                left: scrollLeft + clientWidth,
                behavior: 'smooth'
            });
        }
    };

    const handleTabClick = (index) => {
        setActiveTab(index);
        dispatch(setActiveSettingsTab(index));
    };

    return (
        <>
          <Meta
                title="Settings | TPE Hub"
                description="Manage your account settings and customize your preferences on our Settings page. Update your profile information, adjust security settings, and configure notifications to suit your needs."
                page="/settings"
            />
            {setting.loader || blogsLoader ? <Loader /> : ''}
            <MainBox>
                {/* <SettingTabBox sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <SettingTabs> */}
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <div
                        style={{
                            cursor: 'pointer',
                            marginLeft: '20px',
                            marginRight: '20px',
                            visibility: scrollPosition !== 0 && scrollPosition !== 3 ? 'visible' : 'hidden'
                        }}
                        onClick={() => handleTabNavigation('backward')}
                    >
                        {' '}
                        &lt;
                    </div>
                    <div
                        style={{
                            overflowX: 'auto',
                            display: 'flex'
                        }}
                        className="scroll"
                        onScroll={handleScroll}
                    >
                        {tabs.map((tab, index) => (
                            <div
                                key={index}
                                style={{
                                    display: 'flex',
                                    gap: 4,
                                    alignItems: 'center',
                                    flex: '0 0 auto',
                                    padding: '10px 20px',
                                    cursor: 'pointer',
                                    borderBottom: '3px solid transparent',
                                    fontSize: '14px',
                                    fontWeight: 'bold',
                                    borderBottomColor: activeTab === index ? 'rgb(39, 169, 224)' : 'transparent',
                                    color: activeTab === index ? 'rgb(39, 169, 224)' : 'black'
                                }}
                                onClick={() => handleTabClick(index)}
                            >
                                <div style={{ display: 'flex', alignItems: 'center' }}>{tab.icon}</div>
                                <div>{tab.label}</div>
                            </div>
                        ))}
                        {Object.keys(setting.profileDetail).length > 0
                            ? setting.profileDetail.role === 'admin' && (
                                  <div
                                      style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          gap: 4,
                                          flex: '0 0 auto',
                                          padding: '10px 20px',
                                          cursor: 'pointer',
                                          borderBottom: '3px solid transparent',
                                          fontSize: '14px',
                                          fontWeight: 'bold',
                                          borderBottomColor: activeTab === 4 ? 'rgb(39, 169, 224)' : 'transparent',
                                          color: activeTab === 4 ? 'rgb(39, 169, 224)' : 'black'
                                      }}
                                      onClick={() => handleTabClick(4)}
                                  >
                                      <img alt="billing icon" src="img/billing-icon.svg" />
                                      {'Billing'}
                                  </div>
                              )
                            : ''}
                        {Object.keys(setting.profileDetail).length > 0
                            ? setting.profileDetail.role === 'admin' && (
                                  <div
                                      style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          gap: 4,
                                          flex: '0 0 auto',
                                          padding: '10px 20px',
                                          cursor: 'pointer',
                                          borderBottom: '3px solid transparent',
                                          fontSize: '14px',
                                          fontWeight: 'bold',
                                          borderBottomColor: activeTab === 5 ? 'rgb(39, 169, 224)' : 'transparent',
                                          color: activeTab === 5 ? 'rgb(39, 169, 224)' : 'black'
                                      }}
                                      onClick={() => handleTabClick(5)}
                                  >
                                      <img alt="admin panel" src="img/admin-panel-icon.svg" />
                                      {'Admin Panel'}
                                  </div>
                              )
                            : ''}
                    </div>
                    <div
                        style={{
                            cursor: 'pointer',
                            marginLeft: '20px',
                            marginRight: '20px',
                            visibility: scrollPosition !== 2 && scrollPosition !== 3 ? 'visible' : 'hidden'
                        }}
                        onClick={() => handleTabNavigation('forward')}
                    >
                        &gt;
                    </div>
                </div>
                {/* </SettingTabs> */}
                {/* <SettingTabs
                      value={activeSettingsTab}
                      className="setting-tab"
                      onChange={handleTabChange}
                      indicatorColor="primary"
                      textColor="primary"
                    //   variant="scrollable"
                      scrollButtons={false}
                      aria-label="basic tabs example"
                  >
                      <Tab iconPosition="start" icon={<PermIdentityIcon />} label="Public Profile" {...a11yProps(0)} />
                      <Tab iconPosition="start" icon={<NotificationsNoneIcon />} label="Notification" {...a11yProps(1)} />
                      <Tab iconPosition="start" icon={<WidgetsIcon />} label="Widgets" {...a11yProps(2)} />
                      <Tab iconPosition="start" icon={<IntegrationInstructionsIcon />} label="Integrations" {...a11yProps(3)} />
                      {Object.keys(setting.profileDetail).length > 0
                          ? setting.profileDetail.role === 'admin' && (
                            <Tab
                                iconPosition="start"
                                icon={<img alt="billing icon" src="img/billing-icon.svg" />}
                                label="Billing"
                                {...a11yProps(4)}
                            />
                            )
                          : ''}
                      {Object.keys(setting.profileDetail).length > 0
                          ? setting.profileDetail.role === 'admin' && (
                                <Tab
                                    iconPosition="start"
                                    icon={<img alt="admin panel" src="img/admin-panel-icon.svg" />}
                                    label="Admin Panel"
                                    {...a11yProps(5)}
                                />
                            )
                          : ''}
                  </SettingTabs> */}
                {/* </SettingTabBox> */}
                <Container maxWidth="md">
                    <SwipeableViews
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={activeSettingsTab}
                        onChangeIndex={(index) => {
                            setActiveTab(index);
                            dispatch(setActiveSettingsTab(index));
                        }}
                    >
                        <PortfolioTabPanel value={activeSettingsTab} index={0} dir={theme.direction}>
                            <ProfileForm classes={classes} setting={setting} />
                        </PortfolioTabPanel>
                        <PortfolioTabPanel value={activeSettingsTab} index={1} dir={theme.direction}>
                            <NotificationSetting classes={classes} setting={setting} />
                        </PortfolioTabPanel>
                        <PortfolioTabPanel value={activeSettingsTab} index={2} dir={theme.direction}>
                            <WidgetSetting classes={classes} setting={setting} />
                        </PortfolioTabPanel>
                        <PortfolioTabPanel value={activeSettingsTab} index={3} dir={theme.direction}>
                            <IntegrationIndex setting={setting} />
                        </PortfolioTabPanel>
                        <PortfolioTabPanel value={activeSettingsTab} index={4} dir={theme.direction}>
                            <SubscriptionIndex classes={classes} setting={setting} />
                        </PortfolioTabPanel>
                        {Object.keys(setting.profileDetail).length > 0
                            ? setting.profileDetail.role === 'admin' && (
                                  <PortfolioTabPanel value={activeSettingsTab} index={5} dir={theme.direction}>
                                      <AccessControl allowedRoles={['admin']} renderNoAccess={() => <PermissionDenied />}>
                                          <AdminPanel classes={classes} setting={setting} />
                                      </AccessControl>
                                  </PortfolioTabPanel>
                              )
                            : ''}
                    </SwipeableViews>
                </Container>
            </MainBox>
        </>
    );
}
