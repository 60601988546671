import React from 'react';
import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import { OutputBox } from 'assets/jss/GeneralStyle';
import NoDataFound from 'components/CommonComponent/NoDataFound';
import { numberWithCommas } from 'utils/Utils';

const PeerToPeerOutput = ({ peertopeer }) => {
    return (
        <Card elevation={2} sx={{ mb: 3.5 }}>
            <CardContent>
                <Typography variant="h6" sx={{ mb: 2, display: 'flex' }} gutterBottom>
                    Summary Output
                </Typography>
                <Box id="outputWrapper" className="widget-full-width">
                    {Object.keys(peertopeer?.indepcalData).length > 0 ? (
                        <Grid container spacing={2}>
                            {Object.keys(peertopeer.indepcalData).map((val, i) => {
                                const label = val;
                                const value = typeof peertopeer.indepcalData[val] === 'object' ? 0 : peertopeer.indepcalData[val];
                                return (
                                    <Grid item xs={12} md={4} key={i + 'peertopeeroutput'}>
                                        <OutputBox>
                                            <Typography variant="body1" className="outputLabel" component="p">
                                                {label}
                                            </Typography>
                                            <Typography variant="h6">{numberWithCommas(value.toFixed(2))}</Typography>
                                        </OutputBox>
                                    </Grid>
                                );
                            })}
                        </Grid>
                    ) : (
                        <NoDataFound />
                    )}
                </Box>
            </CardContent>
        </Card>
    );
};

export default PeerToPeerOutput;
