import React from "react";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/system';

import {
  infoColor,
} from "assets/jss/material-kit-react.js";
export const TpeBackdrop = styled(Backdrop)(({ theme }) => {
    return({color: theme.palette.primary.main, 
    width: '100%',
    zIndex:  theme.zIndex.drawer + 1})
});
export const RootBackdrop = styled('div')(({ theme }) => {
           return {  '& > * + *': {
            marginTop: theme.spacing(2)
        },
        '& .MuiCircularProgress-colorPrimary': {
            color: infoColor
        }};
       });
export default function Loader() {
  return (
          <TpeBackdrop
              open={true}
              // invisible={true}
          >
              <CircularProgress color="inherit" thickness={5} />
          </TpeBackdrop>
  );
}
