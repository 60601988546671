import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import FormControl from "@mui/material/FormControl";
import { StyledTextField } from "assets/jss/TextFieldStyle";
import { Box, FormHelperText, InputLabel } from "@mui/material";
import { BootstrapInput } from "assets/jss/TextFieldStyle";
export default function CustomInput(props) {
  const {
    formControlProps,
    labelText,
    id,
    inputProps,
    error,
    inputType,
    CustomInputProps,
    CustomLabelProps,
    required,
    ...rest
  } = props;

  const handleChange = (param) => {
    if (props && props.onChange) {
      props.onChange(param);
    }
  };
  return (
      <Box
          // component="form"
          sx={{
              '& > :not(style)': { m: 1 }
          }}
          noValidate
          className="customtext-field-box"
          autoComplete="off"
      >
          {props.BootstrapInput ? (
              <FormControl variant="standard" fullWidth error={error} disabled={props.disabled}>
                  <InputLabel shrink htmlFor="bootstrap-input">
                      {labelText && required ? labelText + ' *' : labelText ? labelText : ''}
                  </InputLabel>
                  <BootstrapInput
                      id={id}
                      className={rest.adornment ? 'text-with-adornment':''}
                      defaultValue={props.defaultValue}
                      type={inputType}
                      value={props.value}
                      onChange={(e) => handleChange(e)}
                      inputProps={inputProps}
                      InputLabelProps={CustomLabelProps}
                      {...rest}
                  />
                  {props.helperText ? <FormHelperText>{props.helperText}</FormHelperText> : ''}
              </FormControl>
          ) : (
              <StyledTextField
                  variant="standard"
                  id={id}
                  defaultValue={props.defaultValue}
                  error={error}
                  type={inputType}
                  disabled={props.disabled}
                  label={`${labelText && required ? labelText + ' *' : labelText ? labelText : ''}`}
                  value={props.value}
                  onChange={(e) => handleChange(e)}
                  helperText={props.helperText}
                  placeholder={props.placeholder}
                  inputProps={inputProps}
                  InputProps={CustomInputProps}
                  InputLabelProps={CustomLabelProps}
                  {...rest}
              />
          )}
      </Box>
  );
}

CustomInput.propTypes = {
  labelText: PropTypes.node,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  error: PropTypes.bool,
  inputType: PropTypes.string,
};
