import React, { useEffect, useState } from 'react';
import CommonTable from 'components/CommonComponent/CommonTable/CommonTable.js';
import { useDispatch } from 'react-redux';
import {
    fetchLoanDashBoardData,
    fetchLoanDashBoardHistoricalData,
    reloadLoanDashBoardData
} from 'redux/actions/LoanDashBoardAction';
import { Card, Grid, Typography } from '@mui/material';
import moment from 'moment';
import * as tooltip from 'Tooltip.json';
import LoanDashChart from './LoanDashChart';
import { CustomButton } from '../../assets/jss/ButtonStyle';
import { toast } from 'react-toastify';

const arr = ['30 YR Fixed Conforming Mtg', '15 YR Fixed Conforming Mtg', '30 YR Fixed Jumbo Mtg', 'Auto', 'Credit card'];

function LoanDashTable(props) {
    const { classes } = props;
    const parentHeader = [['Mortgage Rates'], ['Consumer Loan Rates']];
    const colList = [
        ['mort_label', 'Names'],
        ['mort_Values', 'Values'],
        ['consum_label', 'Names'],
        ['consum_Values', 'Values']
    ];
    const dispatch = useDispatch();
    const [result, setResult] = useState({});
    const [enableChart, setEnableChart] = useState({});
    const handleRangeSelection = async(range) => {
        const res = await dispatch(fetchLoanDashBoardHistoricalData(enableChart.ticker, range));
        setEnableChart(res);
    };

    const handleSymbolClick = async (data) => {
        if (!arr.includes(data)) {
            return toast.error('No chart data found.');
        }
        const res = await dispatch(fetchLoanDashBoardHistoricalData(data, '1y'));
        setEnableChart(res);
    };

    const getLoanDashboardData = () => {
        dispatch(fetchLoanDashBoardData());
    };

    const updateLoanDashboardData = () => {
        dispatch(reloadLoanDashBoardData());
    }

    useEffect(() => {
        getLoanDashboardData();
    }, []);

    useEffect(() => {
        if (Object.keys(props.loanDashboardData.loanDashboardDetail).length) {
            setResult(props.loanDashboardData.loanDashboardDetail);
        }
    }, [props.loanDashboardData]);

    return (
        <>
            {Object.keys(enableChart ?? {}).length > 0 ? (
                <LoanDashChart
                    chartdetails={enableChart}
                    backButtonClicked={() => setEnableChart({})}
                    rangeSelectionClicked={(val) => handleRangeSelection(val)}
                />
            ) : (
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                        <Card elevation={2}>
                            <Typography variant="subtitle1" className={classes.syncTitle}>
                                The Last Sync occured on {moment.utc(result?.sync_date).local().format('L LT')}
                            </Typography>
                            <CommonTable
                                colSpan={2}
                                HeaderAlign="center"
                                parentHeader={parentHeader}
                                tableColumns={colList}
                                tooltipColumns={{
                                    'Mortgage Rates': tooltip['Mortgage Rates'],
                                    Auto: tooltip['Auto Loan Rate'],
                                    'Student loan - Direct': tooltip['Student Loan Rates']
                                }}
                                title={'Latest Loan Rates'}
                                data={result?.data || []}
                                label="Latest Loan Rates"
                                handleLoadData={updateLoanDashboardData}
                                handleSymbolClick={handleSymbolClick}
                                customRender={{
                                    // Define custom rendering for specific cells
                                    mort_Values: ({ value }) => {
                                        return value !== '15 YR Fixed Jumbo Mtg' &&
                                            value !== 'Student Loan - Direct' &&
                                            value !== 'Student Loan - Grad' &&
                                            value !== 'Student Loan - Parent' ? (
                                            <CustomButton variant="text" onClick={() => handleSymbolClick(value)}>
                                                {value}
                                            </CustomButton>
                                        ) : (
                                            value // Render value if it's 'Rating/Premium', or 'Maturity'
                                        );
                                    }
                                    // Define custom rendering for other cells if needed
                                }}
                            />
                        </Card>
                    </Grid>
                </Grid>
            )}
        </>
    );
}

export default LoanDashTable;
