import { infoColor, roseColor, warningColor, successColor } from 'assets/jss/material-kit-react';
const todoPageStyle = (theme) => ({
    todoContainer: {
        // width: "max-content",
        height: 510,
        overflow: 'scroll',
        margin: 'auto',
        [theme.breakpoints.down('md')]: {
            width: 'max-content'
        },
        // display: "inline-table",
        '& .stageColumn': {
            width: 255,
            // display: "table-cell",
            float: 'left'
        },
        '& .todoCardHeader': {
            padding: '3px 8px 0',
            backgroundColor: '#FFFFFF',
            '& .MuiTypography-gutterBottom': {
                // margin: "0",
            }
        },
        '& .todoBodyCard': {
            backgroundColor: '#9999992e',
            padding: '1px 0',
            '& .todoCards': {
                margin: 5,
                borderRadius: '12px',
                cursor: 'grab',
                boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
                '& .priorityBlock': {
                    bottom: 2,
                    marginRight: 4,
                    position: 'relative',
                    '& img': {
                        width: 18,
                        height: 18
                    }
                },
                '& .MuiCardContent-root': {
                    padding: '12px 12px 5px 12px'
                }
            },
            '& .todoCardWrapper': {
                position: 'relative',
                '&:hover': {
                    WebkitAnimation: `$wiggle 400ms`,
                    animation: `$wiggle 400ms`
                },
                '&:hover $selectTodoCardBtn': {
                    opacity: 1
                },
                '& .kanban-card-empty': {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: 100
                },
                '&.selected': {
                    '& $selectTodoCardBtn': {
                        opacity: 1
                    },
                    '& .todoCards': {
                        border: `2px solid ${infoColor}`
                    }
                }
            }
        }
    },

    timeDurationWrapper: {
        // width: "auto",
        fontSize: '19px',
        color: '#fff',
        position: 'relative',
        textAlign: 'left',
        zIndex: '0',
        width: 113,
        height: 28,
        lineHeight: 1.5,
        paddingLeft: 15,
        background: '#e46a76',
        boxShadow: '0 4px 43px 0 rgba(0, 0, 0, 0.2), 0 2px 10px 0 rgba(0, 0, 0, 0.19)',
        WebkitFilter: 'drop-shadow(0 1px 2px rgba(0, 0, 0, 0.3))',
        filter: 'drop-shadow(0 1px 2px rgba(0, 0, 0, 0.3))',
        '&:after': {
            left: 113,
            content: '""',
            position: 'absolute',
            borderTop: '13px solid #e46a76',
            borderBottom: '15px solid #e46a76',
            borderRight: '15px solid transparent'
        },
        '& span': {
            padding: '0 0 0 7px'
        }
    },
    mostUsedCat: {
        '& $todoCount': {
            margin: '0 7px 0 0'
        }
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    },
    addCateTitle: {
        marginTop: 15
    },
    selectPrioTitle: {
        marginBottom: 5
    },
    dueDateTime: {
        '& input': {
            fontSize: '1rem !important'
        }
    },
    cardHeader: {
        float: 'right',
        '& .MuiCardHeader-action .MuiButtonBase-root': { padding: 7 }
    },
    todoCardContent: {
        padding: 5
    },
    addNewCatBlock: {
        float: 'right',
        display: 'flex',
        color: roseColor,
        cursor: 'pointer',
        '&:hover': {
            color: '#e82366b3'
        },
        '& span': { fontWeight: 600 }
    },
    resizeSvg: {
        '& svg': {
            width: '0.8em',
            height: '0.8em'
        }
    },
    progressTodoWrapper: {
        display: 'flex',
        alignItems: 'center',
        '& .MuiSlider-root': {
            width: '85%',
            marginRight: 8,
            color: infoColor,
            padding: 0
        },
        '& .progressPer': {
            minWidth: 35,
            marginLeft: 15,
            marginBottom: 5,
            color: 'rgba(0, 0, 0, 0.54)'
        },
        '& .MuiSlider-rail': { height: 4 },
        '& .MuiSlider-track': { height: 4 },
        '& .MuiSlider-thumb': {
            width: 16,
            height: 16,
            marginTop: -7,
            marginLeft: 0
        }
    },
    deleteAddNewCatWapper: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        '& .deleteAddNewCat': {}
    },
    sampleColorTodo: {
        marginTop: 5,
        display: 'flex',
        height: 45,
        width: 150,
        '& .sampleDiv': { margin: 'auto', textAlign: 'center' }
    },
    progressTodoPopupWrapper: {
        '& #progressTodo': {
            marginTop: 5
        },
        '& .MuiSlider-root': {
            marginRight: 0
        },
        '& .progressPer': {
            marginLeft: 10
        },
        '& .MuiSlider-thumb': { marginLeft: -8 }
    },
    todoPopup: {
        '& .MuiCardContent-root': {
            padding: '12px 12px 5px 12px'
        }
    },
    ongoingTask: {
        '& $timeDurationWrapper': {
            width: 155,
            '&:after': {
                left: 155
            }
        },
        '& .cardContentWrapper': {
            height: 185,
            '& $todoDateWrapper': {
                '& .priorityBlock img': { width: 16 },
                '& .datePickerIcon svg': {
                    width: '0.65em',
                    position: 'relative',
                    bottom: 2
                }
            }
        },
        '& .ongoingContainer': {
            marginTop: 2
        }
    },
    todoPopupContent: {
        '& $timeDurationWrapper': {
            // marginLeft: 3,
            '&:before': {
                width: 150
            }
        }
    },
    todoPopupBodyWrapper: {
        marginTop: 10,
        maxHeight: 200,
        overflowY: 'scroll',
        '& .timeDurationParent': {
            position: 'fixed',
            zIndex: 2,
            background: 'white',
            width: '19em'
        },
        '& $timeDurationWrapper': {
            width: 165,
            '&:after': {
                left: 165
            }
        },
        '& .MuiGrid-container': {
            marginRight: 0,
            marginTop: 34
        },
        '& .priorityBlock': {
            bottom: '2px',
            position: 'relative',
            marginRight: '4px'
        }
    },
    addTodoBlock: {
        textAlign: 'center',
        background: '#FFFFFF',
        '& .MuiButtonBase-root': {
            width: '100%',
            padding: 5
        }
    },
    todoPopupHeader: { padding: '10px 16px ' },
    todoCardAction: {
        padding: 0,
        display: 'block',
        background: '#f4f4f4',
        '& .MuiButtonBase-root': { padding: 3 }
    },
    '@-webkit-keyframes wiggle': {
        '0%': { '-webkit-transform': 'rotate(0deg)' },
        '25%': { '-webkit-transform': 'rotate(3deg)' },
        '75%': { '-webkit-transform': 'rotate(-3deg)' },
        '100%': { '-webkit-transform': 'rotate(0deg)' }
    },
    '@keyframes wiggle': {
        '0%': { transform: 'rotate(0deg)' },
        '25%': { transform: 'rotate(3deg)' },
        '75%': { transform: 'rotate(-3deg)' },
        '100%': { transform: 'rotate(0deg)' }
    },
    emptyTodoCard: {
        '& .cardContentWrapper': {
            display: 'flex !important',
            justifyContent: 'center',
            alignItems: 'center',
            '& p': {
                textAlign: 'center',
                color: 'inherit'
            }
        }
    },
    todotitle: {
        // textDecoration: "line-through",
        cursor: 'pointer'
    },
    listPaperWrapper: {
        [theme.breakpoints.down('xs')]: {
            display: 'flex',
            overflow: 'scroll'
        }
    },
    actionContainer: {
        margin: 0,
        marginBottom: 5,
        maxWidth: '100%',
        width: 'calc(100% + 16px)',
        '& .MuiGrid-item': {
            maxWidth: '100%',
            flexBasis: 0,
            minHeight: 'unset'
        },
        [theme.breakpoints.down('xs')]: {
            '& .MuiGrid-item': {
                flexGrow: 0,
                flexBasis: '100%'
            }
        }
    },
    todobackdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff'
    },
    gifWrapper: {
        maxWidth: '100%',
        width: 300,
        height: 350,
        display: 'flex',
        '& img': {
            margin: 'auto',
            width: '100%',
            height: '100%'
        },
        '& .gifClosebtn': {
            color: 'white'
        }
    },
    todoChartCard: {
        margin: 0
    }
});

export default todoPageStyle;
