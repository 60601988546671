import { Grid, Box, IconButton, Card, Paper, Typography, CardHeader, Backdrop } from '@mui/material';
import { styled } from '@mui/material/styles';
export const SelectedTodoCardBtnBox = styled(Box)(({ props, theme }) => {
    return {
        position: 'absolute',
        opacity: 0,
        '& .MuiButtonBase-root': {
            zIndex: 1,
            padding: 0,
            bottom: 2,
            borderRadius: '50%'
        }
    };
});

export const StyledTodoCardParent = styled(Card)(({ theme }) => {
    return {};
});
export const TickStyledIconBtn = styled(IconButton)(({ theme }) => {
    return {
        fontSize: 14,
        textTransform: 'capitalize',
        color: theme.palette.primary.main
    };
});
export const StyledTodoBackdrop = styled(Backdrop)(({ theme }) => {
    return {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
        '& .gifWrapper': {
            maxWidth: '100%',
            width: 300,
            height: 350,
            display: 'flex',
            '& img': {
                margin: 'auto',
                width: '100%',
                height: '100%'
            },
            '& .gifClosebtn': {
                color: 'white'
            }
        }
    };
});
export const SettingBoxWrapper = styled(Box)(({ theme }) => {
    return {
        margin: '16px 0 0 0',
        '& .MuiButtonBase-root': {
            fontSize: '1rem',
            padding: 5
        }
    };
});
export const TodoBodyCardBox = styled(Box)(({ theme }) => {
    return {
        backgroundColor: '#9999992e',
        padding: '1px 0',
        '& .todoCards': {
            margin: 5,
            borderRadius: '12px',
            cursor: 'grab',
            boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
            '& .priorityBlock': {
                bottom: 2,
                marginRight: 4,
                position: 'relative',
                '& img': {
                    width: 18,
                    height: 18
                }
            },
            '& .MuiCardContent-root': {
                padding: '12px 12px 5px 12px'
            }
        }
    };
});
export const TodosSearchBoxGrid = styled(Card)(({ theme }) => {
    return {
        marginLeft: -3,
        '& .MuiFormControl-root': {
            margin: '13px 0 0px 0',
            padding: 0
        }
    };
});
export const TodoCardWrapperBox = styled(Box)(({ theme }) => {
    return {
        position: 'relative',
        '@-webkit-keyframes wiggle': {
            '0%': { '-webkit-transform': 'rotate(0deg)' },
            '25%': { '-webkit-transform': 'rotate(3deg)' },
            '75%': { '-webkit-transform': 'rotate(-3deg)' },
            '100%': { '-webkit-transform': 'rotate(0deg)' }
        },
        '@keyframes wiggle': {
            '0%': { transform: 'rotate(0deg)' },
            '25%': { transform: 'rotate(3deg)' },
            '75%': { transform: 'rotate(-3deg)' },
            '100%': { transform: 'rotate(0deg)' }
        },
        '&:hover': {
            WebkitAnimation: `wiggle 400ms`,
            animation: `wiggle 400ms`,
            '& .todocard-btn-box': {
                opacity: 1
            }
        },
        '& .kanban-card-empty': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 100
        },
        '&.selected': {
            '& .todocard-btn-box': {
                opacity: 1
            },
            '& .todoCards': {
                border: `2px solid ${theme.palette.primary.main}`
            }
        }
    };
});
export const TodoActionGrid = styled(Grid)(({ theme }) => {
    return {
        margin: 0,
        marginBottom: 5,
        maxWidth: '100%',
        width: 'calc(100% + 16px)',
        '& .MuiGrid-item': {
            maxWidth: '100%',
            flexBasis: 0,
            minHeight: 'unset'
        },
        [theme.breakpoints.down('xs')]: {
            '& .MuiGrid-item': {
                flexGrow: 0,
                flexBasis: '100%'
            }
        }
    };
});
export const TodoTitleTypography = styled(Typography)(({ props, theme }) => {
    return { cursor: 'pointer' };
});
export const TodoDateWrapperTypography = styled(Typography)(({ props, theme }) => {
    return {
        display: 'flex',
        fontSize: '12px',
        border: '1px solid transparent',
        '& $todoCount': {
            marginLeft: 0,
            color: 'inherit'
        },
        '& .datePickerIcon': {
            fontSize: 0,
            '& .MuiSvgIcon-root': {
                width: '0.7em'
            }
        },
        '& .todoCardDate': {
            margin: '2px 8px 0 5px',
            textTransform: 'capitalize'
        },
        '&.MuiTypography-colorTextSecondary': {
            color: 'inherit'
        }
    };
});
export const StyledSettingCardHeader = styled(CardHeader)(({ props, theme }) => {
    return {
        float: 'right',
        '& .MuiCardHeader-action .MuiButtonBase-root': { padding: 7 },
        '&.resizeSvg': {
            '& svg': {
                width: '0.8em',
                height: '0.8em'
            }
        }
    };
});
export const OngoingTaskBox = styled(Box)(({ props, theme }) => {
    return {
        '& .todo-title': {
            width: 155,
            '&:after': {
                left: 155
            }
        },
        '& .cardContentWrapper': {
            height: 185,
            '& $todoDateWrapper': {
                '& .priorityBlock img': { width: 16 },
                '& .datePickerIcon svg': {
                    width: '0.65em',
                    position: 'relative',
                    bottom: 2
                }
            }
        },
        '& .ongoingContainer': {
            marginTop: 2
        }
    };
});
export const TimeDurationWrapperBox = styled(Box)(({ props, theme }) => {
    return {
        // width: "auto",
        fontSize: '19px',
        color: '#fff',
        position: 'relative',
        textAlign: 'left',
        zIndex: '0',
        width: 113,
        height: 28,
        lineHeight: 1.5,
        paddingLeft: 15,
        background: '#e46a76',
        boxShadow: '0 4px 43px 0 rgba(0, 0, 0, 0.2), 0 2px 10px 0 rgba(0, 0, 0, 0.19)',
        WebkitFilter: 'drop-shadow(0 1px 2px rgba(0, 0, 0, 0.3))',
        filter: 'drop-shadow(0 1px 2px rgba(0, 0, 0, 0.3))',
        '&:after': {
            left: 113,
            content: '""',
            position: 'absolute',
            borderTop: '13px solid #e46a76',
            borderBottom: '15px solid #e46a76',
            borderRight: '15px solid transparent'
        },
        '& span': {
            padding: '0 0 0 7px'
        }
    };
});
export const AddTodoBlockBox = styled(Box)(({ props, theme }) => {
    return {
        textAlign: 'center',
        background: '#FFFFFF',
        '& .MuiButtonBase-root': {
            width: '100%',
            padding: 5
        }
    };
});
export const EmptyTodoCard = styled(Card)(({ props, theme }) => {
    return {
        '& .cardContentWrapper': {
            display: 'flex !important',
            justifyContent: 'center',
            alignItems: 'center',
            '& p': {
                textAlign: 'center',
                color: 'inherit'
            }
        }
    };
});
export const ProgressTodoWrapperBox = styled(Box)(({ theme }) => {
    return {
        display: 'flex',
        alignItems: 'center',
        width: 'inherit',
        //    '& .MuiSlider-root': {
        //        width: '85%',
        //        marginRight: 8,
        //        color: theme.palette.primary.main,
        //        padding: 0
        //    },
        '& .css-k008qs': {
            width: '-webkit-fill-available'
        },
        '& .progressPer': {
            minWidth: 35,
            marginLeft: 15,
            marginBottom: 5,
            color: 'rgba(0, 0, 0, 0.54)'
        },
        //    '& .MuiSlider-rail': { height: 4 },
        //    '& .MuiSlider-track': { height: 4 },
        '& .MuiSlider-thumb': {
            width: 16,
            height: 16
            //    marginTop: -7,
            //    marginLeft: 0
        }
    };
});
export const TypographyTodoCount = styled(Typography)(({ props, theme }) => {
    return {
        position: 'relative',
        marginLeft: '10px',
        bottom: '2px',
        color: 'rgb(0 0 0 / 87%)',
        border: '1px solid #c1c1c1',
        display: 'inline-block',
        padding: '5px 12px',
        fontSize: '12px',
        fontWeight: '600',
        lineHeight: '1',
        marginRight: '3px',
        borderRadius: '12px',
        marginTop: 3,
        height: 'min-content',
        wordWrap: 'break-word',
        maxWidth: 100
    };
});
export const StageColumnPaper = styled(Paper)(({ props, theme }) => {
    return {
        width: 266,
        // display: "table-cell",
        float: 'left',
        '&.todoPlannedStage': {
            borderTop: `4px solid ${theme.palette.secondary.main}`,
            background: '#ededed',
            marginLeft: 5
        },
        '&.todoProgressStage': {
            borderTop: `4px solid ${theme.palette.warning.dark}`,
            background: '#ededed',
            position: 'relative',
            left: 10
        },
        '&.todoCompletedStage': {
            borderTop: `4px solid ${theme.palette.success.main}`,
            background: '#ededed',
            position: 'relative',
            left: 20
        },
        '&.todoOverdueStage': {
            borderTop: `4px solid ${theme.palette.grey[500]}`,
            background: '#ededed',
            position: 'relative',
            left: 30
        }
    };
});
export const TodoGridContainer = styled(Grid)(({ props, theme }) => {
    return {
        // width: "max-content",
        // height: 355,
        maxHeight: '500px',
        minHeight: '355px',
        overflow: 'scroll',
        margin: 'auto',
        display: 'flex',
        margin: 'inherit',
        width: '70rem',
        [theme.breakpoints.down('md')]: {
            width: 'max-content'
        },
        '& .todoCardHeader': {
            padding: '3px 8px 0',
            backgroundColor: '#FFFFFF',
            '& .MuiTypography-gutterBottom': {
                // margin: "0",
            }
        }
    };
});
export const AddNewCatBlockBox = styled(Box)(({ props, theme }) => {
    return {
        float: 'right',
        display: 'flex',
        color: theme.palette.primary.main,
        cursor: 'pointer',
        '&:hover': {
            color: theme.palette.primary.dark
        },
        '& span': { fontWeight: 600, margin: 'auto' },
        '& .resizeSvg': {
            '& svg': {
                width: '0.8em',
                height: '0.8em'
            }
        }
    };
});
export const AddNewCatFieldBoxWrapper = styled(Box)(({ props, theme }) => {
    return {
        display: 'flex',
        width: '100%',
        alignItems: 'center'
    };
});
export const TodoPriorityPaper = styled(Paper)(({ props, theme }) => {
    return {
        width: 100,
        height: 65,
        margin: 'auto',
        textAlign: 'center',
        marginTop: 8,
        position: 'relative',
        '&.selected': {
            backgroundColor: theme.palette.primary.light
        },
        '&:hover': {
            boxShadow: theme.shadows[4],
            backgroundColor: theme.palette.primary.light,
            cursor: 'pointer'
        },
        '& .prioWrapper': {
            position: 'absolute',
            top: '50%',
            left: '50%',
            MozTransform: 'translateX(-50%) translateY(-50%)',
            WebkitTransform: 'translateX(-50%) translateY(-50%)',
            transform: 'translateX(-50%) translateY(-50%)'
        }
    };
});
// todoCount: {
//     position: "relative",
//     marginLeft: "10px",
//     bottom: "2px",
//     color: "rgb(0 0 0 / 87%)",
//     border: "1px solid #c1c1c1",
//     display: "inline-block",
//     padding: "5px 12px",
//     fontSize: "12px",
//     fontWeight: "600",
//     lineHeight: "1",
//     marginRight: "3px",
//     borderRadius: "12px",
//     marginTop: 3,
//     height: "min-content",
//     wordWrap: "break-word",
//     maxWidth: 100,
//   },
// searchBox: {
//     marginLeft: -3,
//     "& .MuiFormControl-root": {
//       margin: "13px 0 0px 0",
//       padding: 0,
//     },
//   },
