import { Card, CardContent, Dialog, DialogContent, Divider, Grid, IconButton, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import EnhancedTable from "components/CommonComponent/CommonTable/EnhancedTable";
import NoDataFound from "components/CommonComponent/NoDataFound";
import React, { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { fetchWidgetCalList, submitWidgetCal, submitWidgetDialog } from "redux/actions/SettingActions";
import RenderField from "utils/RenderField";
import CustomDialogTitle from "components/DialogTitle";
import { CustomButton } from "assets/jss/ButtonStyle";
const WidgetCalSetting = (props) => {
  const { setting } = props;
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const {handleSubmit,control, reset,formState: { isSubmitting },setValue,} = useForm({mode: "onChange"});
  // submit the form
  const onSubmit = (data) => {
    const type = data.id ? 'put' : 'post';
      const objToSend = {
        name: data.widCalName,
        status:data.status ==='active' ?true:false,
        path: data.widCalPath,
      id: data.id || undefined
    }
    dispatch(submitWidgetCal(objToSend, type));
  }
  useEffect(() => {
    // once add new cal popup will submit to close that popup when api return success
    if (setting.widgetDialogFlag) {
      handleClose();
      dispatch(submitWidgetDialog(false));
    }
  }, [setting.widgetDialogFlag]);

  useEffect(() => {
    //fetch the data on page load
    dispatch(fetchWidgetCalList());
  }, []);

  const handleClickOpen = () => setOpen(true);
  // delete selected data
  const deleteSelectedData = id => dispatch(submitWidgetCal({ 'id': id }, 'delete'));
  // enable to edit data
  const handleEditData = val => {
    setOpen(true);
    setValue('id', val.id);
    setValue('widCalName', val.name);
    setValue("widCalPath", val.path);
    setValue('status', val.status ? 'active' : 'inactive');
  }
  //close popup
  const handleClose = () => {
    reset({widCalName:'',status:[]});
    setOpen(false);
  }
  const columns = useMemo(() => {
    const result = [
      {
        Header: "Action",
        width: 120,
        Cell: ({ row }) => (
          <div className={"actionWrapper"}>
            <Tooltip title='Delete'>
              <IconButton
                aria-label='delete'
                onClick={() => deleteSelectedData(row.original.id)}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title='Edit'>
              <IconButton
                aria-label='edit'
                onClick={(e) => handleEditData(row.original)}>
                <CreateIcon />
              </IconButton>
            </Tooltip>
          </div>
        ),
      },
      {
        Header: "Name",
        accessor: 'name',
        disableFilters: true,
        Cell: ({ value }) => value || '-',
      },
      {
        Header: "Component Path Name",
        accessor: 'path',
        disableFilters: true,
        Cell: ({ value }) => value || '-',
      },
      {
        Header: "Status",
        accessor: 'status',
        disableFilters: true,
        Cell: ({ value }) => value===true ? 'Active' :value===false ?'Inactive':'',
      },
    ];
    return result;
  });
  const renderStatusOption=()=>[{ value: 'active', label: 'Active' }, { value: 'inactive', label: 'Inactive' }]
  return (
      <>
           <Card elevation={2}>
            <CardContent>
                             <Tooltip title="Add" arrow>
                                <CustomButton variant="text" className='read-more' startIcon={<AddIcon />} aria-label="add" onClick={handleClickOpen}>
                                    Add New Data
                                </CustomButton>
                            </Tooltip>
                              <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                                  <CustomDialogTitle id="form-dialog-title" onClose={handleClose}>
                                      Add New Calculator
                                  </CustomDialogTitle>
                                  <Divider />
                                  <DialogContent>
                                      <form onSubmit={handleSubmit(onSubmit)}>
                                          <Grid container>
                                              <Grid item xs={12}>
                                                  <RenderField
                                                      name="widCalName"
                                                      control={control}
                                                      label={'Calculator Name'}
                                                      type={'text'}
                                                      required={true}
                                                      placeholder={'Enter Calculator Name'}
                                                      setValue={setValue}
                                                  />
                                              </Grid>
                                              <Grid item xs={12}>
                                                  <RenderField
                                                      name="widCalPath"
                                                      control={control}
                                                      label={'Calculator Path'}
                                                      type={'text'}
                                                      required={true}
                                                      placeholder={'Enter Calculator Path'}
                                                      setValue={setValue}
                                                  />
                                              </Grid>
                                              <Grid item xs={12}>
                                                  <RenderField
                                                      name="status"
                                                      control={control}
                                                      disableEmptyValue={true}
                                                      label={'Calculator status'}
                                                      options={renderStatusOption()}
                                                      type={'select'}
                                                      required={true}
                                                      setValue={setValue}
                                                  />
                                              </Grid>
                                          </Grid>
                                          <Grid container direction="row" style={{ width: '100%', textAlign: 'center' }}>
                                              <Grid item xs={12}>
                                                  <CustomButton type="submit" ps={'9px 30px'} variant="contained" disabled={isSubmitting}>
                                                      Submit
                                                  </CustomButton>
                                              </Grid>
                                          </Grid>
                                      </form>
                                  </DialogContent>
                              </Dialog>
                              {setting.widgetCalList.length > 0 ? (
                                      <EnhancedTable
                                          enableSearch={false}
                                          columns={columns}
                                          data={setting.widgetCalList || []}
                                          enableSort={true}
                                          enablePagination={false}
                                      />
                              ) : (
                                  <NoDataFound msg="Result will be shown after adding module permission." />
                              )}
          </CardContent>
          </Card>
      </>
  );
};

export default WidgetCalSetting;
