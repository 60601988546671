import * as ActionTypes from './ActionTypes';
import { apiCall, handleError } from 'utils/Utils';
import Globals from 'Globals.js';
import { toast } from 'react-toastify';

// handle loader when api started to call
export const mortgageRefinanceCalInit = () => {
    return {
        type: ActionTypes.MORTGAGE_REFINANCE_CAL_INIT
    };
};
//handle success of api
export const mortgageRefinanceCalSuccess = (val) => {
    return {
        type: ActionTypes.MORTGAGE_REFINANCE_CAL_SUCCESS,
        data: val
    };
};
// handle api error
export const mortgageRefinanceCalError = () => {
    return {
        type: ActionTypes.MORTGAGE_REFINANCE_CAL_ERROR
    };
};
/**
 * @author Ulyana
 * @param {*} data
 * @use to get mortgage refinance cal
 * @returns
 */
export const SumbitMortgageRefinanceCal = (data) => async (dispatch) => {
    try {
        dispatch(mortgageRefinanceCalInit());
        const apiResponse = await apiCall(`${Globals.API_ROOT_URL}mortgagerefinancecal`, 'post', data);
        if (apiResponse !== undefined && apiResponse.status === 200) {
            dispatch(mortgageRefinanceCalSuccess(apiResponse?.data));
        } else {
            handleError(apiResponse, dispatch);
            dispatch(mortgageRefinanceCalError());
        }
    } catch (error) {
        toast.error(Globals.errorMsg);
        dispatch(mortgageRefinanceCalError());
        throw error;
    }
};
