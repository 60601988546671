import {Grid, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { CustomButton } from 'assets/jss/ButtonStyle';
import { PermissionPaper } from 'assets/jss/GeneralStyle';

export default function PermissionDenied({planname}) {
    return (
        <PermissionPaper elevation={2}>
            <Grid container className={'permissionGridParent'}>
                <Grid item xs className={'lockGrid'}>
                    <img alt="lock" src="img/lock.png" />
                </Grid>
                <Grid item>
                    <Typography gutterBottom variant="body1" component="p">
                        Sorry, you do not have permission to view this page. Please select <b>{planname} </b>plan to access this.
                    </Typography>
                </Grid>
                <Grid item>
                    <CustomButton variant="contained" size="sm" component={Link} to="/subscription">
                        Click here to upgrade
                    </CustomButton>
                </Grid>
            </Grid>
        </PermissionPaper>
    );
}
