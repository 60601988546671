import React, { Fragment } from "react";
import {Box, Card, CardContent, Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import NoDataFound from "components/CommonComponent/NoDataFound";
import { OutputBox } from "assets/jss/GeneralStyle";
import { numberWithCommas } from "utils/Utils";
import CustomTooltip from "components/CommonComponent/CustomTooltip";
import * as tooltip from 'Tooltip.json';

const HouseAffordCalOutput = (props) => { 
  return (
      <Card elevation={2} sx={{ mb: 3.5 }}>
          <CardContent>
              <Typography variant="h6" sx={{ mb: 2, display: 'flex' }} gutterBottom>
                  Summary Output
              </Typography>
              <Box id="outputWrapper" className="widget-full-width">
                  {props?.houseAffordData?.houseAffordDetails?.data?.length ? (
                      <Grid container spacing={2}>
                           {props.houseAffordData.houseAffordDetails.data.map((val, i) => {
                                const allowedPrefix = true;
                              return (
                                  <Fragment>
                                      <Grid item xs={12} md={4}>
                                          <OutputBox>
                                            <Typography>{val?.maximum_mortgage_amount ? "Maximum Mortgage Amount" : "Maximum Home Price"}</Typography>
                                              <Typography variant="body2" className="outputLabel" component="p">
                                                  {/* {tooltip.default?.val?.Maximum_Mortgage_Amount ? "Maximum Mortgage Amount" : "Maximum Home Price" &&
                                                  <CustomTooltip
                                                      tooltipText={tooltip.default?.val?.Maximum_Mortgage_Amount ? "Maximum Mortgage Amount" : "Maximum Home Price"}
                                                      customTooltipStyle={{ maxWidth: 450 }}
                                                      label={val?.Maximum_Mortgage_Amount ? "Maximum Mortgage Amount" : "Maximum Home Price"}
                                                  />} */}
                                              </Typography>
                                              <Typography variant="h5">
                                                  {allowedPrefix ? '$' : ''}
                                                  {val?.maximum_mortgage_amount ? numberWithCommas(val?.maximum_mortgage_amount) : numberWithCommas(val?.maximum_home_price)}
                                              </Typography>
                                          </OutputBox>
                                      </Grid>
                                  </Fragment>
                              );
                            })}
                      </Grid>
                  ) : (
                      <NoDataFound />
                  )
                  }
              </Box>
          </CardContent>
      </Card>
  );
};

export default HouseAffordCalOutput;
