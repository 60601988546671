import * as ActionTypes from './ActionTypes';
import { apiCall, handleError } from 'utils/Utils';
import Globals from 'Globals.js';
import { toast } from 'react-toastify';
import { banks } from 'Plaid.json';

//handle success of api
export const fetchProfileSuccess = (val) => {
    return {
        type: ActionTypes.FETCH_PROFILE_SUCCESS,
        data: val
    };
};
// handle api error
export const fetchProfileError = () => {
    return {
        type: ActionTypes.FETCH_PROFILE_ERROR
    };
};

/**
 * @author disha
 * @param {*} data
 * @use to calculate student loan
 * @returns
 */
export const fetchProfile = () => async (dispatch) => {
    try {
        dispatch({
            type: ActionTypes.FETCH_PROFILE_INIT
        });
        const apiResponse = await apiCall(`${Globals.API_ROOT_URL}profile`, 'get');
        if (apiResponse !== undefined && apiResponse.status === 200) {
            dispatch(fetchProfileSuccess(apiResponse.data.data));
        } else {
            handleError(apiResponse, dispatch);
            dispatch(fetchProfileError());
        }
    } catch (error) {
        toast.error(Globals.errorMsg);
        dispatch(fetchProfileError());
        throw error;
    }
};

//handle success of api
export const submitProfileSuccess = (val) => {
    return {
        type: ActionTypes.SUBMIT_PROFILE_SUCCESS,
        data: val
    };
};
// handle api error
export const submitProfileError = () => {
    return {
        type: ActionTypes.SUBMIT_PROFILE_ERROR
    };
};

/**
 * @author disha
 * @param {*} data
 * @use to calculate student loan
 * @returns
 */
export const submitProfile =
    (data, cb = null) =>
    async (dispatch) => {
        try {
            dispatch({
                type: ActionTypes.SUBMIT_PROFILE_INIT
            });
            const apiResponse = await apiCall(`${Globals.API_ROOT_URL}profile`, 'put', data);
            if (apiResponse !== undefined && apiResponse.status === 200) {
                toast.success(apiResponse.data.data.message);
                dispatch(submitProfileSuccess());
                if (cb) {
                    cb();
                }
                dispatch(fetchProfile());
            } else {
                handleError(apiResponse, dispatch);
                dispatch(submitProfileError());
            }
        } catch (error) {
            toast.error(Globals.errorMsg);
            dispatch(submitProfileError());
            throw error;
        }
    };

//handle success of api
export const submitProfileSettingsSuccess = (val) => {
    return {
        type: ActionTypes.SUBMIT_PROFILE_SETTINGS_SUCCESS,
        data: val
    };
};
// handle api error
export const submitProfileSettingsError = () => {
    return {
        type: ActionTypes.SUBMIT_PROFILE_SETTINGS_ERROR
    };
};

/**
 * @author Ulyana
 * @param {*} data
 * @use to calculate student loan
 * @returns
 */
export const submitProfileSettings =
    (data, cb = null) =>
    async (dispatch) => {
        try {
            dispatch({
                type: ActionTypes.SUBMIT_PROFILE_SETTINGS_INIT
            });
            const apiResponse = await apiCall(`${Globals.API_ROOT_URL}profile_settings`, 'put', data);
            if (apiResponse !== undefined && apiResponse.status === 200) {
                toast.success(apiResponse.data.data.message);
                dispatch(submitProfileSettingsSuccess());
                if (cb) {
                    cb();
                }
                dispatch(fetchProfile());
            } else {
                handleError(apiResponse, dispatch);
                dispatch(submitProfileSettingsError());
            }
        } catch (error) {
            toast.error(Globals.errorMsg);
            dispatch(submitProfileSettingsError());
            throw error;
        }
    };

// //handle success of api
// export const submitProfileSettingsSuccess = (val) => {
//     return {
//         type: ActionTypes.SUBMIT_PROFILE_SETTINGS_SUCCESS,
//         data: val
//     };
// };
// // handle api error
// export const submitProfileSettingsError = () => {
//     return {
//         type: ActionTypes.SUBMIT_PROFILE_SETTINGS_ERROR
//     };
// };

/**
 * @author Ulyana
 * @param {*} data
 * @use to calculate student loan
 * @returns
 */
// export const submitProfileSettings =
//     (data, cb = null) =>
//     async (dispatch) => {
//         try {
//             dispatch({
//                 type: ActionTypes.SUBMIT_PROFILE_SETTINGS_INIT
//             });
//             const apiResponse = await apiCall(`${Globals.API_ROOT_URL}profile_settings`, 'put', data);
//             if (apiResponse !== undefined && apiResponse.status === 200) {
//                 toast.success(apiResponse.data.data.message);
//                 dispatch(submitProfileSettingsSuccess());
//                 if (cb) {
//                     cb();
//                 }
//                 dispatch(fetchProfile());
//             } else {
//                 handleError(apiResponse, dispatch);
//                 dispatch(submitProfileSettingsError());
//             }
//         } catch (error) {
//             toast.error(Globals.errorMsg);
//             dispatch(submitProfileSettingsError());
//             throw error;
//         }
//     };

//handle success of api
export const submitResetPassSuccess = (val) => {
    return {
        type: ActionTypes.SUBMIT_PROFILE_SUCCESS,
        data: val
    };
};
// handle api error
export const submitResetPassError = () => {
    return {
        type: ActionTypes.SUBMIT_PROFILE_ERROR
    };
};

/**
 * @author disha
 * @param {*} data
 * @use to submit reset password
 * @returns
 */
export const submitResetPass = (data) => async (dispatch) => {
    try {
        dispatch({
            type: ActionTypes.SUBMIT_PROFILE_INIT
        });
        const apiResponse = await apiCall(`${Globals.API_ROOT_URL}resetpassword`, 'post', data);
        if (apiResponse !== undefined && apiResponse.status === 200) {
            toast.success(apiResponse.data.data.message);
            dispatch(submitProfileSuccess());
        } else {
            handleError(apiResponse, dispatch);
            dispatch(submitProfileError());
        }
    } catch (error) {
        toast.error(Globals.errorMsg);
        dispatch(submitProfileError());
        throw error;
    }
};
//handle success of api
export const submitTrafficLightSuccess = () => {
    return {
        type: ActionTypes.SUBMIT_TRAFFIC_LIGHT_SUCCESS
    };
};
// handle api error
export const submitTrafficLightError = () => {
    return {
        type: ActionTypes.SUBMIT_TRAFFIC_LIGHT_ERROR
    };
};
export const handleDialogVisibility = (flag) => {
    return {
        type: ActionTypes.HANDLE_DIALOG_VISIBLITY,
        data: flag
    };
};

/**
 * @author disha
 * @param {*} data
 * @use to submit Traffic light admin panel setting
 * @returns
 */
export const submitTrafficLight = (data, type) => async (dispatch) => {
    try {
        dispatch({
            type: ActionTypes.SUBMIT_TRAFFIC_LIGHT_INIT
        });
        const apiResponse = await apiCall(`${Globals.API_ROOT_URL}trafficlightsetting`, type, data);
        if (apiResponse !== undefined && apiResponse.status === 200) {
            toast.success(apiResponse.data.data.message);
            dispatch(submitTrafficLightSuccess());
            if (type !== 'delete') {
                dispatch(handleDialogVisibility(true));
            }
            dispatch(getTrafficLight());
        } else {
            handleError(apiResponse, dispatch);
            dispatch(submitTrafficLightError());
        }
    } catch (error) {
        toast.error(Globals.errorMsg);
        dispatch(submitTrafficLightError());
        throw error;
    }
};
//handle success of api
export const getTrafficLightSuccess = (val) => {
    return {
        type: ActionTypes.FETCH_TRAFFIC_LIGHT_SUCCESS,
        data: val
    };
};
// handle api error
export const getTrafficLightError = () => {
    return {
        type: ActionTypes.FETCH_TRAFFIC_LIGHT_ERROR
    };
};

/**
 * @author disha
 * @param {*} data
 * @use to calculate student loan
 * @returns
 */
export const getTrafficLight = () => async (dispatch) => {
    try {
        dispatch({
            type: ActionTypes.FETCH_TRAFFIC_LIGHT_INIT
        });
        const apiResponse = await apiCall(`${Globals.API_ROOT_URL}trafficlightsetting`, 'get');
        if (apiResponse !== undefined && apiResponse.status === 200) {
            dispatch(getTrafficLightSuccess(apiResponse.data.data));
        } else {
            handleError(apiResponse, dispatch);
            dispatch(getTrafficLightError());
        }
    } catch (error) {
        toast.error(Globals.errorMsg);
        dispatch(getTrafficLightError());
        throw error;
    }
};
//handle success of api
export const submitTpeModuleSuccess = (flag) => {
    return {
        type: ActionTypes.SUBMIT_TPE_MODULE_SUCCESS,
        data: flag
    };
};
// handle api error
export const submitTpeModuleError = () => {
    return {
        type: ActionTypes.SUBMIT_TPE_MODULE_ERROR
    };
};
/**
 * @author disha
 * @param {*} data
 * @use to submit permission
 * @returns
 */
export const submitTpeModule = (data, type) => async (dispatch) => {
    try {
        dispatch({
            type: ActionTypes.SUBMIT_TPE_MODULE_INIT
        });
        const apiResponse = await apiCall(`${Globals.API_ROOT_URL}tpemodules`, type, data);
        if (apiResponse !== undefined && apiResponse.status === 200) {
            toast.success(apiResponse.data.data.message);
            let dialogFlag = false;
            if (type !== 'delete') {
                dialogFlag = true;
            }
            dispatch(submitTpeModuleSuccess(dialogFlag));
            dispatch(getTpeModule());
        } else {
            handleError(apiResponse, dispatch);
            dispatch(submitTpeModuleError());
        }
    } catch (error) {
        toast.error(Globals.errorMsg);
        dispatch(submitTpeModuleError());
        throw error;
    }
};
//handle success of api
export const getTpeModuleSuccess = (val) => {
    return {
        type: ActionTypes.FETCH_TPE_MODULE_SUCCESS,
        data: val
    };
};
// handle api error
export const getTpeModuleError = () => {
    return {
        type: ActionTypes.FETCH_TPE_MODULE_ERROR
    };
};

/**
 * @author disha
 * @param {*} data
 * @use to get the list of all permissions
 * @returns
 */
export const getTpeModule = () => async (dispatch) => {
    try {
        dispatch({
            type: ActionTypes.FETCH_TPE_MODULE_INIT
        });
        const apiResponse = await apiCall(`${Globals.API_ROOT_URL}tpemodules`, 'get');
        if (apiResponse !== undefined && apiResponse.status === 200) {
            // const apiResponse={data:{"data": [{"id": "BoMd", "name": "test", "module_unique_id": "trt", "subscription": {"id": "QGwQ", "price": 546.0, "name": "sub paln", "plan_include": ["sdfsd", "fggfgsd"], "modules": [{"id": "BNLd", "name": "Save Retirement", "module_unique_id": "save_retirement"}, {"id": "BoMd", "name": "test", "module_unique_id": "trt"}]}}, {"id": "Bq2Q", "name": "Save Homevaluation", "module_unique_id": "save_home_tool", "subscription": {"id": "B3ne", "price": 34343.0, "name": "test", "plan_include": ["dfsd", "sdfsd", "fggfgsd"], "modules": [{"id": "Bq2Q", "name": "Save Homevaluation", "module_unique_id": "save_home_tool"}]}}, {"id": "BNLd", "name": "Save Retirement", "module_unique_id": "save_retirement", "subscription": {"id": "QGwQ", "price": 546.0, "name": "sub paln", "plan_include": ["sdfsd", "fggfgsd"], "modules": [{"id": "BNLd", "name": "Save Retirement", "module_unique_id": "save_retirement"}, {"id": "BoMd", "name": "test", "module_unique_id": "trt"}]}}, {"id": "d96e", "name": "Create Todo update", "module_unique_id": "create_todo_update", "subscription": {}}], "code": 200}}
            dispatch(getTpeModuleSuccess(apiResponse.data.data));
        } else {
            handleError(apiResponse, dispatch);
            dispatch(getTpeModuleError());
        }
    } catch (error) {
        toast.error(Globals.errorMsg);
        dispatch(getTpeModuleError());
        throw error;
    }
};
//handle success of api
export const submitSubscriptionSuccess = (flag) => {
    return {
        type: ActionTypes.SUBMIT_SUBSCRIPTION_SUCCESS,
        data: flag
    };
};
// handle api error
export const submitSubscriptionError = () => {
    return {
        type: ActionTypes.SUBMIT_SUBSCRIPTION_ERROR
    };
};
/**
 * @author disha
 * @param {*} data
 * @useto to save/update the subscription
 * @returns
 */
export const submitSubscription = (data, type) => async (dispatch) => {
    try {
        dispatch({
            type: ActionTypes.SUBMIT_SUBSCRIPTION_INIT
        });
        const apiResponse = await apiCall(`${Globals.API_ROOT_URL}subscriptions`, type, data);
        if (apiResponse !== undefined && apiResponse.status === 200) {
            toast.success(apiResponse.data.data.message);
            let dialogFlag = false;
            if (type !== 'delete') {
                dialogFlag = true;
            }
            dispatch(submitSubscriptionSuccess(dialogFlag));
            dispatch(getSubcriptions());
        } else {
            handleError(apiResponse, dispatch);
            dispatch(submitSubscriptionError());
        }
    } catch (error) {
        toast.error(Globals.errorMsg);
        dispatch(submitSubscriptionError());
        throw error;
    }
};
//handle success of api
export const getSubcriptionsSuccess = (val) => {
    return {
        type: ActionTypes.FETCH_SUBSCRIPTION_SUCCESS,
        data: val
    };
};
// handle api error
export const getSubcriptionsError = () => {
    return {
        type: ActionTypes.FETCH_SUBSCRIPTION_ERROR
    };
};

/**
 * @author disha
 * @param {*} data
 * @use to get the list of all subscriptions plan
 * @returns
 */
export const getSubcriptions = () => async (dispatch) => {
    try {
        dispatch({
            type: ActionTypes.FETCH_SUBSCRIPTION_INIT
        });
        const apiResponse = await apiCall(`${Globals.API_ROOT_URL}subscriptions`, 'get');
        if (apiResponse !== undefined && apiResponse.status === 200) {
            // const apiResponse={data:{"data": [{"id": "QbyB", "price": 4344.0, "name": "ddds", "plan_include": ["dfdf"], "modules": []}, {"id": "QGwQ", "price": 546.0, "name": "sub paln", "plan_include": ["sdfsd", "fggfgsd"], "modules": [{"id": "BNLd", "name": "Save Retirement", "module_unique_id": "save_retirement"}, {"id": "BoMd", "name": "test", "module_unique_id": "trt"}]}, {"id": "B3ne", "price": 34343.0, "name": "test", "plan_include": ["dfsd", "sdfsd", "fggfgsd"], "modules": [{"id": "Bq2Q", "name": "Save Homevaluation", "module_unique_id": "save_home_tool"}]}, {"id": "eDWd", "price": 410.0, "name": "Gold", "plan_include": ["business calculators", "Business dashboard", "Message support", "Customer support in 24 hours"], "modules": []}], "code": 200}}
            dispatch(getSubcriptionsSuccess(apiResponse.data.data));
        } else {
            handleError(apiResponse, dispatch);
            dispatch(getSubcriptionsError());
        }
    } catch (error) {
        toast.error(Globals.errorMsg);
        dispatch(getSubcriptionsError());
        throw error;
    }
};

//handle success of api
export const submitEquityTickerSuccess = (flag) => {
    return {
        type: ActionTypes.SUBMIT_EQUITY_TICKER_SUCCESS,
        data: flag
    };
};
// handle api error
export const submitEquityTickerError = () => {
    return {
        type: ActionTypes.SUBMIT_EQUITY_TICKER_ERROR
    };
};
/**
 * @author disha
 * @param {*} data
 * @useto to save/update the subscription
 * @returns
 */
export const submitEquityTicker = (data, type) => async (dispatch) => {
    try {
        dispatch({
            type: ActionTypes.SUBMIT_EQUITY_TICKER_INIT
        });
        const apiResponse = await apiCall(`${Globals.API_ROOT_URL}equityticker`, type, data);
        if (apiResponse !== undefined && apiResponse.status === 200) {
            toast.success(apiResponse.data.data.message);
            let dialogFlag = false;
            if (type !== 'delete') {
                dialogFlag = true;
            }
            dispatch(submitEquityTickerSuccess(dialogFlag));
            dispatch(getEquityTicker());
        } else {
            handleError(apiResponse, dispatch);
            dispatch(submitEquityTickerError());
        }
    } catch (error) {
        toast.error(Globals.errorMsg);
        dispatch(submitEquityTickerError());
        throw error;
    }
};
//handle success of api
export const getEquityTickerSuccess = (val) => {
    return {
        type: ActionTypes.FETCH_EQUITY_TICKER_SUCCESS,
        data: val
    };
};
// handle api error
export const getEquityTickerError = () => {
    return {
        type: ActionTypes.FETCH_EQUITY_TICKER_ERROR
    };
};

/**
 * @author disha
 * @param {*} data
 * @use to get the list of all subscriptions plan
 * @returns
 */
export const getEquityTicker = () => async (dispatch) => {
    try {
        dispatch({
            type: ActionTypes.FETCH_EQUITY_TICKER_INIT
        });
        const apiResponse = await apiCall(`${Globals.API_ROOT_URL}equityticker`, 'get');
        if (apiResponse !== undefined && apiResponse.status === 200) {
            // const apiResponse={data:{"data": [{"id": "QbyB", "price": 4344.0, "name": "ddds", "plan_include": ["dfdf"], "modules": []}, {"id": "QGwQ", "price": 546.0, "name": "sub paln", "plan_include": ["sdfsd", "fggfgsd"], "modules": [{"id": "BNLd", "name": "Save Retirement", "module_unique_id": "save_retirement"}, {"id": "BoMd", "name": "test", "module_unique_id": "trt"}]}, {"id": "B3ne", "price": 34343.0, "name": "test", "plan_include": ["dfsd", "sdfsd", "fggfgsd"], "modules": [{"id": "Bq2Q", "name": "Save Homevaluation", "module_unique_id": "save_home_tool"}]}, {"id": "eDWd", "price": 410.0, "name": "Gold", "plan_include": ["business calculators", "Business dashboard", "Message support", "Customer support in 24 hours"], "modules": []}], "code": 200}}
            dispatch(getEquityTickerSuccess(apiResponse.data.data));
        } else {
            handleError(apiResponse, dispatch);
            dispatch(getEquityTickerError());
        }
    } catch (error) {
        toast.error(Globals.errorMsg);
        dispatch(getEquityTickerError());
        throw error;
    }
};
//handle success of api
export const submitEconomicTickerSuccess = (flag) => {
    return {
        type: ActionTypes.SUBMIT_ECONOMIC_TICKER_SUCCESS,
        data: flag
    };
};
// handle api error
export const submitEconomicTickerError = () => {
    return {
        type: ActionTypes.SUBMIT_ECONOMIC_TICKER_ERROR
    };
};
/**
 * @author gaurav
 * @param {*} data
 * @useto to save/update the economy ticker
 * @returns
 */
export const submitEconomicTicker = (data, type) => async (dispatch) => {
    try {
        dispatch({
            type: ActionTypes.SUBMIT_ECONOMIC_TICKER_INIT
        });
        const apiResponse = await apiCall(`${Globals.API_ROOT_URL}econticker`, type, data);
        if (apiResponse !== undefined && apiResponse.status === 200) {
            toast.success(apiResponse.data.data.message);
            let dialogFlag = false;
            if (type !== 'delete') {
                dialogFlag = true;
            }
            dispatch(submitEconomicTickerSuccess(dialogFlag));
            dispatch(getEconomicTicker());
        } else {
            handleError(apiResponse, dispatch);
            dispatch(submitEconomicTickerError());
        }
    } catch (error) {
        toast.error(Globals.errorMsg);
        dispatch(submitEconomicTickerError());
        throw error;
    }
};

//handle success of api
export const submit_portfolio_allocation_admin_Success = (flag) => {
    return {
        type: ActionTypes.SUBMIT_PORTFOLIO_ALLOCATION_SUCCESS,
        data: flag
    };
};
// handle api error
export const submit_portfolio_allocation_admin_Error = () => {
    return {
        type: ActionTypes.SUBMIT_PORTFOLIO_ALLOCATION_ERROR
    };
};

export const submit_portfolio_allocation_admin = (data, type) => async (dispatch) => {
    try {
        dispatch({
            type: ActionTypes.SUBMIT_PORTFOLIO_ALLOCATION_INIT
        });
        const apiResponse = await apiCall(`${Globals.API_ROOT_URL}portfolio_allocation_admin`, type, data);
        if (apiResponse !== undefined && apiResponse.status === 200) {
            toast.success(apiResponse.data.data.message);
            let dialogFlag = false;
            if (type !== 'delete') {
                dialogFlag = true;
            }
            dispatch(submit_portfolio_allocation_admin_Success(dialogFlag));
            dispatch(getPortfolioAllocation());
        } else {
            handleError(apiResponse, dispatch);
            dispatch(submit_portfolio_allocation_admin_Error());
        }
    } catch (error) {
        toast.error(Globals.errorMsg);
        dispatch(submit_portfolio_allocation_admin_Error());
        throw error;
    }
};

//handle success of api
export const getEconomicTickerSuccess = (val) => {
    return {
        type: ActionTypes.FETCH_ECONOMIC_TICKER_SUCCESS,
        data: val
    };
};
// handle api error
export const getEconomicTickerError = () => {
    return {
        type: ActionTypes.FETCH_ECONOMIC_TICKER_ERROR
    };
};
/**
 * @author gaurav
 * @param {*} data
 * @use to get the list of all economy tickers
 * @returns
 */
export const getEconomicTicker = () => async (dispatch) => {
    try {
        dispatch({
            type: ActionTypes.FETCH_ECONOMIC_TICKER_INIT
        });
        const apiResponse = await apiCall(`${Globals.API_ROOT_URL}econticker`, 'get');
        if (apiResponse !== undefined && apiResponse.status === 200) {
            dispatch(getEconomicTickerSuccess(apiResponse.data.data));
        } else {
            handleError(apiResponse, dispatch);
            dispatch(getEconomicTickerError());
        }
    } catch (error) {
        toast.error(Globals.errorMsg);
        dispatch(getEconomicTickerError());
        throw error;
    }
};

//handle success of api
export const getPortfolioAllocationSuccess = (val) => {
    return {
        type: ActionTypes.FETCH_PORTFOLIO_ALLOCATION_SUCCESS,
        data: val
    };
};
// handle api error
export const getPortfolioAllocationError = () => {
    return {
        type: ActionTypes.FETCH_PORTFOLIO_ALLOCATION_ERROR
    };
};

export const getPortfolioAllocation = () => async (dispatch) => {
    try {
        dispatch({
            type: ActionTypes.FETCH_PORTFOLIO_ALLOCATION_INIT
        });
        const apiResponse = await apiCall(`${Globals.API_ROOT_URL}portfolio_allocation_admin`, 'get');
        if (apiResponse !== undefined && apiResponse.status === 200) {
            dispatch(getPortfolioAllocationSuccess(apiResponse.data.data));
        } else {
            handleError(apiResponse, dispatch);
            dispatch(getPortfolioAllocationError());
        }
    } catch (error) {
        toast.error(Globals.errorMsg);
        dispatch(getPortfolioAllocationError());
        throw error;
    }
};

//handle success of api
export const submitBillingUpdateSuccess = (flag) => {
    return {
        type: ActionTypes.SUBMIT_BILLING_UPDATE_SUCCESS,
        data: flag
    };
};
// handle api error
export const submitBillingUpdateError = () => {
    return {
        type: ActionTypes.SUBMIT_BILLING_UPDATE_ERROR
    };
};
/**
 * @author disha
 * @param {*} data
 * @useto to save/update the subscription
 * @returns
 */
export const submitBillingUpdate = (data, type) => async (dispatch) => {
    try {
        dispatch({
            type: ActionTypes.SUBMIT_BILLING_UPDATE_INIT
        });
        const apiResponse = await apiCall(`${Globals.API_ROOT_URL}billing`, type, data);
        if (apiResponse !== undefined && apiResponse.status === 200) {
            toast.success(apiResponse.data.data.message);
            dispatch(submitBillingUpdateSuccess());
            dispatch(getBillingDetails());
        } else {
            handleError(apiResponse, dispatch);
            dispatch(submitBillingUpdateError());
        }
    } catch (error) {
        toast.error(Globals.errorMsg);
        dispatch(submitBillingUpdateError());
        throw error;
    }
};
//handle success of api
export const getInvoiceHistorySuccess = (val) => {
    return {
        type: ActionTypes.FETCH_INVOICE_HISTORY_SUCCESS,
        data: val
    };
};
// handle api error
export const getInvoiceHistoryError = () => {
    return {
        type: ActionTypes.FETCH_INVOICE_HISTORY_ERROR
    };
};

/**
 * @author disha
 * @param {*} data
 * @use to get the list of all subscriptions plan
 * @returns
 */
export const getInvoiceHistory = () => async (dispatch) => {
    try {
        dispatch({
            type: ActionTypes.FETCH_INVOICE_HISTORY_INIT
        });
        const apiResponse = await apiCall(`${Globals.API_ROOT_URL}billing/history`, 'get');
        if (apiResponse !== undefined && apiResponse.status === 200) {
            dispatch(getInvoiceHistorySuccess(apiResponse.data.data));
        } else {
            handleError(apiResponse, dispatch);
            dispatch(getInvoiceHistoryError());
        }
    } catch (error) {
        toast.error(Globals.errorMsg);
        dispatch(getInvoiceHistoryError());
        throw error;
    }
};
//handle success of api
export const getBillingDetailsSuccess = (val) => {
    return {
        type: ActionTypes.FETCH_BILLING_DETAILS_SUCCESS,
        data: val
    };
};
// handle api error
export const getBillingDetailsError = () => {
    return {
        type: ActionTypes.FETCH_BILLING_DETAILS_ERROR
    };
};

/**
 * @author disha
 * @param {*} data
 * @use to get the list of all subscriptions plan
 * @returns
 */
export const getBillingDetails = () => async (dispatch) => {
    try {
        dispatch({
            type: ActionTypes.FETCH_BILLING_DETAILS_INIT
        });
        const apiResponse = await apiCall(`${Globals.API_ROOT_URL}billing`, 'get');
        if (apiResponse !== undefined && apiResponse.status === 200) {
            // const apiResponse={data:{"data": [{"id": "QbyB", "price": 4344.0, "name": "ddds", "plan_include": ["dfdf"], "modules": []}, {"id": "QGwQ", "price": 546.0, "name": "sub paln", "plan_include": ["sdfsd", "fggfgsd"], "modules": [{"id": "BNLd", "name": "Save Retirement", "module_unique_id": "save_retirement"}, {"id": "BoMd", "name": "test", "module_unique_id": "trt"}]}, {"id": "B3ne", "price": 34343.0, "name": "test", "plan_include": ["dfsd", "sdfsd", "fggfgsd"], "modules": [{"id": "Bq2Q", "name": "Save Homevaluation", "module_unique_id": "save_home_tool"}]}, {"id": "eDWd", "price": 410.0, "name": "Gold", "plan_include": ["business calculators", "Business dashboard", "Message support", "Customer support in 24 hours"], "modules": []}], "code": 200}}
            dispatch(getBillingDetailsSuccess(apiResponse.data.data));
        } else {
            handleError(apiResponse, dispatch);
            dispatch(getBillingDetailsError());
        }
    } catch (error) {
        toast.error(Globals.errorMsg);
        dispatch(getBillingDetailsError());
        throw error;
    }
};
export const loadSubScriptionSetting = (flag) => {
    return {
        type: ActionTypes.LOAD_SUB_SETTING_PAGE,
        data: flag
    };
};
//handle success of api
export const fetchFeedbackSuccess = (data) => {
    return {
        type: ActionTypes.FETCH_FEEDBACK_SUCCESS,
        data
    };
};
// handle api error
export const fetchFeedbackError = () => {
    return {
        type: ActionTypes.FETCH_FEEDBACK_ERROR
    };
};
// api to get feedback
export const fetchFeedback = () => async (dispatch) => {
    try {
        dispatch({
            type: ActionTypes.FETCH_FEEDBACK_INIT
        });
        const apiResponse = await apiCall(`${Globals.API_ROOT_URL}feedback`, 'get');
        if (apiResponse !== undefined && apiResponse.status === 200) {
            dispatch(fetchFeedbackSuccess(apiResponse.data.data));
        } else {
            handleError(apiResponse, dispatch);
            dispatch(fetchFeedbackError());
        }
    } catch (error) {
        toast.error(Globals.errorMsg);
        dispatch(fetchFeedbackError());
        throw error;
    }
};
//handle success of api
export const fetchWidgetCalListSuccess = (data) => {
    return {
        type: ActionTypes.FETCH_WIDGET_CAL_LIST_SUCCESS,
        data
    };
};
// handle api error
export const fetchWidgetCalListError = () => {
    return {
        type: ActionTypes.FETCH_WIDGET_CAL_LIST_ERROR
    };
};
// api to get widget calculator list
export const fetchWidgetCalList = () => async (dispatch) => {
    try {
        dispatch({
            type: ActionTypes.FETCH_WIDGET_CAL_LIST_INIT
        });
        const apiResponse = await apiCall(`${Globals.API_ROOT_URL}widgetcalculator`, 'get');
        if (apiResponse !== undefined && apiResponse.status === 200) {
            dispatch(fetchWidgetCalListSuccess(apiResponse.data.data));
        } else {
            handleError(apiResponse, dispatch);
            dispatch(fetchWidgetCalListError());
        }
    } catch (error) {
        toast.error(Globals.errorMsg);
        dispatch(fetchWidgetCalListError());
        throw error;
    }
};
//handle success of api
export const fetchWidgetUserCalListSuccess = (data) => {
    return {
        type: ActionTypes.FETCH_WIDGET_USER_CAL_LIST_SUCCESS,
        data
    };
};
// handle api error
export const fetchWidgetUserCalListError = () => {
    return {
        type: ActionTypes.FETCH_WIDGET_USER_CAL_LIST_ERROR
    };
};
// api to get all widget calculator list which is selected or added by the user
export const fetchWidgetUserCalList = () => async (dispatch) => {
    try {
        dispatch({
            type: ActionTypes.FETCH_WIDGET_USER_CAL_LIST_INIT
        });
        const apiResponse = await apiCall(`${Globals.API_ROOT_URL}widgetusercalculator`, 'get');
        if (apiResponse !== undefined && apiResponse.status === 200) {
            dispatch(fetchWidgetUserCalListSuccess(apiResponse.data.data));
        } else {
            handleError(apiResponse, dispatch);
            dispatch(fetchWidgetUserCalListError());
        }
    } catch (error) {
        toast.error(Globals.errorMsg);
        dispatch(fetchWidgetUserCalListError());
        throw error;
    }
};
//handle success of api
export const submitWidgetCalSuccess = (flag) => {
    return {
        type: ActionTypes.SUBMIT_WIDGET_CAL_SUCCESS,
        data: flag
    };
};
// handle api error
export const submitWidgetCalError = () => {
    return {
        type: ActionTypes.SUBMIT_WIDGET_CAL_ERROR
    };
};
export const submitWidgetDialog = (flag) => {
    return {
        type: ActionTypes.SUBMIT_WIDGET_DIALOG_SUCCESS,
        data: flag
    };
};
/**
 * @author disha
 * @param {*} data
 * @useto to save/update/delete the widget calculator (will call from admin panel)
 * @returns
 */
export const submitWidgetCal = (data, type) => async (dispatch) => {
    try {
        dispatch({
            type: ActionTypes.SUBMIT_WIDGET_CAL_INIT
        });
        const apiResponse = await apiCall(`${Globals.API_ROOT_URL}widgetcalculator`, type, data);
        if (apiResponse !== undefined && apiResponse.status === 200) {
            toast.success(apiResponse.data.data.message);
            dispatch(submitWidgetCalSuccess());
            dispatch(fetchWidgetCalList());
            let dialogFlag = false;
            if (type !== 'delete') {
                dialogFlag = true;
            }
            dispatch(submitWidgetDialog(dialogFlag));
        } else {
            handleError(apiResponse, dispatch);
            dispatch(submitWidgetCalError());
        }
    } catch (error) {
        toast.error(Globals.errorMsg);
        dispatch(submitWidgetCalError());
        throw error;
    }
};
//handle success of api
export const submitWidgetUserCalSuccess = (flag) => {
    return {
        type: ActionTypes.SUBMIT_WIDGET_USER_CAL_SUCCESS,
        data: flag
    };
};
// handle api error
export const submitWidgetUserCalError = () => {
    return {
        type: ActionTypes.SUBMIT_WIDGET_USER_CAL_ERROR
    };
};
/**
 * @author disha
 * @param {*} data
 * @useto to save/update/delete the selection of the widget which is done by the user
 * @returns
 */
export const submitWidgetUserCal = (data, type) => async (dispatch) => {
    try {
        dispatch({
            type: ActionTypes.SUBMIT_WIDGET_USER_CAL_INIT
        });
        const apiResponse = await apiCall(`${Globals.API_ROOT_URL}widgetusercalculator`, type, data);
        if (apiResponse !== undefined && apiResponse.status === 200) {
            toast.success(apiResponse.data.data.message);
            dispatch(submitWidgetUserCalSuccess());
            dispatch(fetchWidgetUserCalList());
            let dialogFlag = false;
            if (type !== 'delete') {
                dialogFlag = true;
            }
            dispatch(submitWidgetDialog(dialogFlag));
        } else {
            handleError(apiResponse, dispatch);
            dispatch(submitWidgetUserCalError());
        }
    } catch (error) {
        toast.error(Globals.errorMsg);
        dispatch(submitWidgetUserCalError());
        throw error;
    }
};
//handle success of api
export const generatePlaidTokenSuccess = (flag) => {
    return {
        type: ActionTypes.GENERATE_PLAID_TOKEN_SUCCESS,
        data: flag
    };
};
// handle api error
export const generatePlaidTokenError = () => {
    return {
        type: ActionTypes.GENERATE_PLAID_TOKEN_ERROR
    };
};
/**
 * @author disha
 * @param {*} data
 * @useto to save/update/delete the selection of the widget which is done by the user
 * @returns
 */
export const generatePlaidToken = (data, type) => async (dispatch) => {
    try {
        dispatch({
            type: ActionTypes.GENERATE_PLAID_TOKEN_INIT
        });
        const apiResponse = await apiCall(`${Globals.API_ROOT_URL}create_link_token`, 'GET');
        if (apiResponse !== undefined && apiResponse.status === 200) {
            dispatch(generatePlaidTokenSuccess(apiResponse.data.data));
        } else {
            handleError(apiResponse, dispatch);
            dispatch(generatePlaidTokenError());
        }
    } catch (error) {
        toast.error(Globals.errorMsg);
        dispatch(generatePlaidTokenError());
        throw error;
    }
};
//handle success of get api
export const getConnectedAccountDetailSuccess = (flag) => {
    return {
        type: ActionTypes.GET_CONNECTED_ACC_DETAIL_SUCCESS,
        data: flag
    };
};
//handle success of delete api
export const deleteConnectedAccountDetailSuccess = (flag) => {
    return {
        type: ActionTypes.DELETE_CONNECTED_ACC_DETAIL_SUCCESS,
        data: flag
    };
};
// handle api error
export const getConnectedAccountDetailError = () => {
    return {
        type: ActionTypes.GET_CONNECTED_ACC_DETAIL_ERROR
    };
};
/**
 * @author disha
 * @param {*} data
 * @useto to save/update/delete the selection of the widget which is done by the user
 * @returns
 */
export const getConnectedAccountDetail =
    (data = {}, type = 'get') =>
    async (dispatch) => {
        try {
            dispatch({
                type: ActionTypes.GET_CONNECTED_ACC_DETAIL_INIT
            });
            const apiResponse = await apiCall(`${Globals.API_ROOT_URL}connectedbanks`, type, data);
            if (apiResponse !== undefined && apiResponse.status === 200) {
                if (type === 'get') dispatch(getConnectedAccountDetailSuccess(apiResponse.data.data));
                if (type === 'delete') {
                    dispatch(deleteConnectedAccountDetailSuccess(data.id));
                    dispatch(getConnectedAccountDetail());
                }
            } else {
                if (type === 'get' && apiResponse.status === 401) {
                    dispatch(getConnectedAccountDetailSuccess(banks));
                } else {
                    handleError(apiResponse, dispatch);
                    dispatch(getConnectedAccountDetailError());
                }
            }
        } catch (error) {
            toast.error(Globals.errorMsg);
            dispatch(getConnectedAccountDetailError());
            throw error;
        }
    };
//handle success of api
export const connectPlaidSuccess = () => {
    return {
        type: ActionTypes.CONNECT_PLAID_SUCCESS
    };
};
// handle api error
export const connectPlaidError = () => {
    return {
        type: ActionTypes.CONNECT_PLAID_ERROR
    };
};
/**
 * @author disha
 * @param {*} data
 * @useto to save/update/delete the selection of the widget which is done by the user
 * @returns
 */
export const connectPlaid = (data) => async (dispatch) => {
    try {
        dispatch({
            type: ActionTypes.CONNECT_PLAID_INIT
        });
        const apiResponse = await apiCall(`${Globals.API_ROOT_URL}exchange_public_token`, 'POST', data);
        if (apiResponse !== undefined && apiResponse.status === 200) {
            toast.success(apiResponse.data.message);
            dispatch(connectPlaidSuccess());
            dispatch(getConnectedAccountDetail());
        } else {
            handleError(apiResponse, dispatch);
            dispatch(connectPlaidError());
        }
    } catch (error) {
        toast.error(Globals.errorMsg);
        dispatch(connectPlaidError());
        throw error;
    }
};

// to set selected tab in settings menu
export const setActiveSettingsTab = (tab) => {
    return {
        type: ActionTypes.SET_ACTIVE_SETTINGS_TAB,
        data: tab
    };
};

// to set selected tab of admin panel in settings menu
export const setActiveSettingsAdminPanelTab = (tab) => {
    return {
        type: ActionTypes.SET_ACTIVE_SETTINGS_ADMIN_PANEL_TAB,
        data: tab
    };
};

//handle success of api
export const getChatMessagesSuccess = (val) => {
    return {
        type: ActionTypes.FETCH_CHAT_MESSAGES_SUCCESS,
        data: val
    };
};
// handle api error
export const getChatMessagesError = () => {
    return {
        type: ActionTypes.FETCH_CHAT_MESSAGES_ERROR
    };
};
/**
 * @author Ulyana
 * @param {*} data
 * @use to get the list of all economy tickers
 * @returns
 */
export const getChatMessages = (data) => async (dispatch) => {
    try {
        dispatch({
            type: ActionTypes.FETCH_CHAT_MESSAGES_INIT
        });
        const apiResponse = await apiCall(`${Globals.API_ROOT_URL}finbot`, 'patch', data);
        if (apiResponse !== undefined && apiResponse.status === 200) {
            dispatch(getChatMessagesSuccess(apiResponse.data));
        } else {
            handleError(apiResponse, dispatch);
            dispatch(getChatMessagesError());
        }
    } catch (error) {
        toast.error(Globals.errorMsg);
        dispatch(getChatMessagesError());
        throw error;
    }
};

//handle success of api
export const getDeleteChatMessagesSuccess = (val) => {
    return {
        type: ActionTypes.DELETE_CHAT_MESSAGES_SUCCESS,
        data: val
    };
};
// handle api error
export const getDeleteChatMessagesError = () => {
    return {
        type: ActionTypes.DELETE_CHAT_MESSAGES_ERROR
    };
};
/**
 * @author Ulyana
 * @param {*} data
 * @use to get the list of all economy tickers
 * @returns
 */
export const getDeleteChatMessages = (message_id, data) => async (dispatch) => {
    try {
        dispatch({
            type: ActionTypes.DELETE_CHAT_MESSAGES_INIT
        });
        const apiResponse = await apiCall(`${Globals.API_ROOT_URL}finbot`, 'delete', { message_id });
        if (apiResponse !== undefined && apiResponse.status === 200) {
            toast.success(apiResponse.data.data.message);
            dispatch(getDeleteChatMessagesSuccess(apiResponse.data));
            dispatch(getChatMessages({ data }));
        } else {
            handleError(apiResponse, dispatch);
            dispatch(getDeleteChatMessagesError());
        }
    } catch (error) {
        toast.error(Globals.errorMsg);
        dispatch(getDeleteChatMessagesError());
        throw error;
    }
};

// export const deleteBlog = (id) => async dispatch => {
//   try {
//     dispatch({
//       type: ActionTypes.DELETE_BLOG_INIT
//     });
//     const apiResponse = await apiCall(`${Globals.API_ROOT_URL}blogs/${id}`, "delete");
//     if (apiResponse !== undefined && apiResponse.status === 200) {
//       toast.success(apiResponse.data.data.message);
//       dispatch(deleteBlogSuccess(apiResponse.data.data));
//       dispatch(fetchBlogsList());
//     } else {
//       handleError(apiResponse, dispatch);
//       dispatch(deleteBlogError());
//     }
//   } catch (error) {
//     toast.error(Globals.errorMsg);
//     dispatch(deleteBlogError());
//     throw error;
//   }
// }

//handle success of api
export const getValidatedChatMessagesSuccess = (val) => {
    return {
        type: ActionTypes.FETCH_VALIDATED_CHAT_MESSAGES_SUCCESS,
        data: val
    };
};
// handle api error
export const getValidatedChatMessagesError = () => {
    return {
        type: ActionTypes.FETCH_VALIDATED_CHAT_MESSAGES_ERROR
    };
};
/**
 * @author Ulyana
 * @param {*} data
 * @use to get the list of all economy tickers
 * @returns
 */
export const getValidatedChatMessages = () => async (dispatch) => {
    try {
        dispatch({
            type: ActionTypes.FETCH_VALIDATED_CHAT_MESSAGES_INIT
        });
        const apiResponse = await apiCall(`${Globals.API_ROOT_URL}validated_messages`, 'get');
        if (apiResponse !== undefined && apiResponse.status === 200) {
            dispatch(getValidatedChatMessagesSuccess(apiResponse.data.data));
        } else {
            handleError(apiResponse, dispatch);
            dispatch(getValidatedChatMessagesError());
        }
    } catch (error) {
        toast.error(Globals.errorMsg);
        dispatch(getValidatedChatMessagesError());
        throw error;
    }
};

//handle success of api
export const putChatMessagesSuccess = (val) => {
    return {
        type: ActionTypes.UPDATE_CHAT_MESSAGES_SUCCESS,
        data: val
    };
};
// handle api error
export const putChatMessagesError = () => {
    return {
        type: ActionTypes.UPDATE_CHAT_MESSAGES_ERROR
    };
};
export const putChatMessages = (data, document) => async (dispatch) => {
    try {
        dispatch({
            type: ActionTypes.UPDATE_CHAT_MESSAGES_INIT
        });
        const apiResponse = await apiCall(`${Globals.API_ROOT_URL}finbot`, 'put', data);
        if (apiResponse !== undefined && apiResponse.status === 200) {
            dispatch(putChatMessagesSuccess(apiResponse.data));
            dispatch(getChatMessages(document));
        } else {
            handleError(apiResponse, dispatch);
            dispatch(putChatMessagesError());
        }
    } catch (error) {
        toast.error(Globals.errorMsg);
        dispatch(putChatMessagesError());
        throw error;
    }
};

//handle success of api
export const putValidateChatMessagesSuccess = (val) => {
    return {
        type: ActionTypes.UPDATE_CHAT_VALIDATE_MESSAGES_SUCCESS,
        data: val
    };
};
// handle api error
export const putValidateChatMessagesError = () => {
    return {
        type: ActionTypes.UPDATE_CHAT_VALIDATE_MESSAGES_ERROR
    };
};
export const putValidateChatMessages = (data) => async (dispatch) => {
    try {
        dispatch({
            type: ActionTypes.UPDATE_CHAT_VALIDATE_MESSAGES_INIT
        });
        const apiResponse = await apiCall(`${Globals.API_ROOT_URL}validated_messages`, 'put', data);
        if (apiResponse !== undefined && apiResponse.status === 200) {
            dispatch(putValidateChatMessagesSuccess(apiResponse.data));
            dispatch(getValidatedChatMessages());
        } else {
            handleError(apiResponse, dispatch);
            dispatch(putValidateChatMessagesError());
        }
    } catch (error) {
        toast.error(Globals.errorMsg);
        dispatch(putValidateChatMessagesError());
        throw error;
    }
};

//handle success of api
export const getValidateDeleteChatMessagesSuccess = (val) => {
    return {
        type: ActionTypes.DELETE_CHAT_MESSAGES_SUCCESS,
        data: val
    };
};
// handle api error
export const getValidateDeleteChatMessagesError = () => {
    return {
        type: ActionTypes.DELETE_CHAT_MESSAGES_ERROR
    };
};
/**
 * @author Ulyana
 * @param {*} data
 * @use to get the list of all economy tickers
 * @returns
 */
export const getValidateDeleteChatMessages = (message_id) => async (dispatch) => {
    try {
        dispatch({
            type: ActionTypes.DELETE_CHAT_MESSAGES_INIT
        });
        const apiResponse = await apiCall(`${Globals.API_ROOT_URL}validated_messages`, 'delete', { message_id });
        if (apiResponse !== undefined && apiResponse.status === 200) {
            toast.success(apiResponse.data.data.message);
            dispatch(getValidateDeleteChatMessagesSuccess(apiResponse.data));
            dispatch(getValidatedChatMessages());
        } else {
            handleError(apiResponse, dispatch);
            dispatch(getValidateDeleteChatMessagesError());
        }
    } catch (error) {
        toast.error(Globals.errorMsg);
        dispatch(getValidateDeleteChatMessagesError());
        throw error;
    }
};

//handle success of api
export const updatePamentMethodSuccess = (val) => {
    return {
        type: ActionTypes.UPDATE_PAYMENT_SUCCESS,
        data: val
    };
};
// handle api error
export const updatePamentMethodError = () => {
    return {
        type: ActionTypes.UPDATE_PAYMENT_ERROR
    };
};

export const updatePamentMethod = (data) => async (dispatch) => {
    try {
        dispatch({
            type: ActionTypes.UPDATE_PAYMENT_INIT
        });
        const apiResponse = await apiCall(`${Globals.API_ROOT_URL}update_card_detail`, 'POST', data);
        if (apiResponse !== undefined && apiResponse.status === 200) {
            toast.success(apiResponse.data.data.message);
            dispatch(updatePamentMethodSuccess());
        } else {
            handleError(apiResponse, dispatch);
            dispatch(updatePamentMethodError());
        }
    } catch (error) {
        toast.error(Globals.errorMsg);
        dispatch(updatePamentMethodError());
        throw error;
    }
};

//handle success of api
export const getCardInfoSuccess = (val) => {
    return {
        type: ActionTypes.GET_CARD_SUCCESS,
        data: val
    };
};
// handle api error
export const getCardInfoError = () => {
    return {
        type: ActionTypes.GET_CARD_ERROR
    };
};

export const getCardInfo = (data) => async (dispatch) => {
    try {
        dispatch({
            type: ActionTypes.GET_CARD_INIT
        });
        const apiResponse = await apiCall(`${Globals.API_ROOT_URL}get_card_detail`, 'POST', data);
        if (apiResponse !== undefined && apiResponse.status === 200) {
            toast.success(apiResponse.data.data.message);
            dispatch(getCardInfoSuccess(apiResponse.data.data));
        } else {
            handleError(apiResponse, dispatch);
            dispatch(getCardInfoError());
        }
    } catch (error) {
        toast.error(Globals.errorMsg);
        dispatch(getCardInfoError());
        throw error;
    }
};
