import React, { useEffect, useState } from 'react';
import './ResearchTable.css';
import { Box, Button, Card, CardContent, FormControl, IconButton, NativeSelect, Pagination, Tooltip, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import NoDataFound from 'components/CommonComponent/NoDataFound';
import { CustomButton } from 'assets/jss/ButtonStyle';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import CustomInput from 'components/CustomInput/CustomInput';
import TableDialog, { PublishDialog, UploadDialog } from './TableDialog';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTableList } from 'redux/actions/ResearchTableTypes';
import { deleteReasearchTable } from 'redux/actions/ResearchTableTypes';
import { fetchBlogsList } from 'redux/actions/BlogTypes';

export const capitalize = (str) => str?.replace(/\b\w/g, (substr) => substr.toUpperCase());

const KnowledgeResearchTable = () => {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const { tableList } = useSelector((state) => state.ResearchTableReducer);
    const [currentTableData, setCurrentTableData] = useState(null);
    const [openPublish, setOpenPublish] = useState(false);
    const [openUpload, setOpenUpload] = useState(false);
    const handleClickOpenPublish = () => setOpenPublish(true);
    const handleClickOpenUpload = () => setOpenUpload(true);

    const handleEdit = (tableData) => {
        setCurrentTableData(tableData);
        setOpen(true);
    };

    useEffect(() => {
        dispatch(fetchTableList());
        dispatch(fetchBlogsList());
    }, []);

    const handleClickOpen = () => setOpen(true);

    const deleteTable = (id) => dispatch(deleteReasearchTable(id, 'delete'));

    // ---------------------------- PAGINATION & SEREACRH BAR & SORTING BY CATAGORY or ORDER ---------------------------------------------------

    const [currentPage, setCurrentPage] = useState(1);
    const [searchInput, setSearchInput] = useState('');
    const [selectTable, setSelectTable] = useState('');
    const [sortType, setSortType] = useState('');
    const [sortOrder, setSortOrder] = useState('asc');
    const tablesPerPage = 1;

    // Handle search input change
    const handleSearchChange = (event) => {
        setSearchInput(event.target.value);
    };

    // Handle sorting change
    const handleSortChange = (event) => {
        const selectedSort = event.target.value;
        setSortType(selectedSort);
    };

    // Handle sort order toggle
    const handleSortOrderToggle = () => {
        setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'));
    };

    // Calculate the current tables to display
    const indexOfLastTable = currentPage * tablesPerPage;
    const indexOfFirstTable = indexOfLastTable - tablesPerPage;
    const currentTables = tableList.slice(indexOfFirstTable, indexOfLastTable);

    // Filter and sort current table data based on search input, sort type, and sort order
    const filteredAndSortedTableData = currentTables.map((table) => {
        // Filter based on search input
        let filteredData = table.table_data.filter((row) => {
            return Object.values(row).some((value) => {
                return value?.toString().toLowerCase().includes(searchInput.toLowerCase());
            });
        });

        // Sort based on selected sort type and order
        if (sortType) {
            filteredData.sort((a, b) => {
                if (a[sortType] < b[sortType]) return sortOrder === 'asc' ? -1 : 1;
                if (a[sortType] > b[sortType]) return sortOrder === 'asc' ? 1 : -1;
                return 0;
            });
        }

        return { ...table, table_data: filteredData };
    });

    // Pagination change handler
    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    // -----------------------------------------

    // Handle select Table
    const handleSelectTable = (event) => {
        const selectedTable = JSON.parse(event.target.value);
        const selectedIndex = tableList.findIndex((table) => table.id === selectedTable.id);
        const selectedPage = Math.floor(selectedIndex / tablesPerPage) + 1;
        setCurrentPage(selectedPage);
        setSelectTable(selectedTable);
    };

    return (
        <>
            <Card elevation={2}>
                <CardContent sx={{ minHeight: '450px' }}>
                    <Box display="flex" justifyContent="space-between">
                        <Box>
                            <CustomButton
                                variant="text"
                                className="read-more"
                                sx={{ alignSelf: 'center' }}
                                startIcon={<AddIcon />}
                                aria-label="add"
                                onClick={handleClickOpen}
                            >
                                Create New Table
                            </CustomButton>
                            <CustomButton
                                onClick={handleClickOpenUpload}
                                variant="text"
                                className="read-more"
                                sx={{ alignSelf: 'center' }}
                                startIcon={<AddIcon />}
                                aria-label="add"
                            >
                                Upload Table
                            </CustomButton>
                        </Box>
                        <Tooltip title="Add Your Table" arrow>
                            <Button
                                onClick={handleClickOpenPublish}
                                sx={{ backgroundColor: '#12a5fe !important', color: 'white' }}
                                // color="white"
                                size="xs"
                            >
                                PUBLISH
                            </Button>
                        </Tooltip>
                    </Box>
                    <Box style={{ margin: '10px 0px 10px' }}>
                        <FormControl sx={{ width: '38%' }}>
                            <NativeSelect
                                labelId="demo-simple-select-label"
                                id={'table'}
                                label={'Search Table'}
                                onChange={handleSelectTable}
                                value={selectTable ? JSON.stringify(selectTable) : ''}
                                // control={control}
                                // setValue={setValue}
                            >
                                {tableList?.map((entry, index) => (
                                    <option key={index} value={JSON.stringify(entry)}>
                                        {entry.table_name}
                                    </option>
                                ))}
                            </NativeSelect>
                        </FormControl>
                    </Box>
                    {/* <Box display="flex" gap="25px">
                        <Box width="30%">
                            <CustomInput
                                // value={title}
                                labelText="Search..."
                                // CustomInputProps={{ width: '100%' }}
                                onChange={handleSearchChange}
                                // error={titleError}
                                // helperText={titleError ? 'Provide valid value for title' : null}
                            />
                        </Box>
                        <FormControl style={{ width: '30%', marginTop: '24px' }}>
                            <NativeSelect
                                labelId="demo-simple-select-label"
                                id={'Sort'}
                                label={'Sort'}
                                onChange={handleSortChange}
                                // value={stype}
                                // control={control}
                                // setValue={setValue}
                            >
                                {Object?.keys((filteredAndSortedTableData[0]?.table_data[0] || currentTables[0]?.table_data[0]) ?? {}).map(
                                    (entry, index) => (
                                        <option value={entry} key={index}>
                                            Order by {entry.charAt(0).toUpperCase() + entry.slice(1)}
                                        </option>
                                    )
                                )}
                            </NativeSelect>
                        </FormControl>
                        <Button style={{ marginTop: '10px' }} color="primary" size="xs" onClick={handleSortOrderToggle}>
                            Switch order ({sortOrder === 'asc' ? 'Asc' : 'Desc'})
                        </Button>
                    </Box> */}
                    {/* ---------------------------Dynamic Table--------------------------------------------------------- */}

                    {filteredAndSortedTableData?.length > 0 ? (
                        filteredAndSortedTableData.map((rows, index) => {
                            return (
                                <div style={{ width: '100%', overflow: 'hidden' }}>
                                    <div style={{ height: '500px', overflowY: 'auto' }}>
                                        <table
                                            border="1"
                                            // cellPadding="5"
                                            cellSpacing="0"
                                            style={{ width: '100%', backgroundColor: '#dedede', margin: '0px' }}
                                        >
                                            <thead>
                                                <tr
                                                    style={{
                                                        color: 'black',
                                                        backgroundColor: 'rgb(181 224 255)',
                                                        position: 'sticky',
                                                        top: '-1px'
                                                    }}
                                                >
                                                    <th
                                                        colSpan={
                                                            Object.keys((rows?.table_data[0] || currentTables[0]?.table_data[0]) ?? {})
                                                                .length || ''
                                                        }
                                                    >
                                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                            <Typography sx={{ display: 'flex', alignSelf: 'center' }} variant="h5">
                                                                {rows?.table_name}
                                                            </Typography>
                                                            <Box>
                                                                <Tooltip title="Edit">
                                                                    <IconButton
                                                                        aria-label="edit"
                                                                        onClick={() => handleEdit(currentTables[0])}
                                                                    >
                                                                        <CreateIcon />
                                                                    </IconButton>
                                                                </Tooltip>
                                                                <Tooltip title="Delete">
                                                                    <IconButton aria-label="delete" onClick={() => deleteTable(rows.id)}>
                                                                        <DeleteIcon />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Box>
                                                        </div>
                                                    </th>
                                                </tr>

                                                <tr
                                                    key={index}
                                                    style={{
                                                        backgroundColor: 'rgb(225 242 254)',
                                                        color: 'black',
                                                        position: 'sticky',
                                                        top: '59px'
                                                    }}
                                                >
                                                    {Object.keys(currentTables[0].table_data[0] ?? {}).map((key) => (
                                                        <th style={{ textAlign: 'center' }} key={key}>
                                                            {key}
                                                        </th>
                                                    ))}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {rows?.table_data.map((row, index) => (
                                                    <tr key={index}>
                                                        {Object.values(row ?? {}).map((value, idx) => {
                                                            const numberValue = Number(value);
                                                            let displayValue;

                                                            if (isNaN(numberValue)) {
                                                                displayValue = value;
                                                            } else {
                                                                displayValue = Number.isInteger(numberValue)
                                                                    ? numberValue
                                                                    : numberValue.toFixed(2); // Format to two decimal places only if necessary
                                                            }
                                                            return (
                                                                <td
                                                                    style={{
                                                                        textAlign: 'center'
                                                                    }}
                                                                    key={idx}
                                                                >
                                                                    {displayValue}
                                                                </td>
                                                            );
                                                        })}
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            );
                        })
                    ) : (
                        <NoDataFound msg="Please add new module to display here." />
                    )}
                </CardContent>

                {/* Pagination */}
                <Pagination
                    count={Math.ceil(tableList.length / tablesPerPage)}
                    page={currentPage}
                    onChange={handlePageChange}
                    sx={{ marginBottom: '10px', display: 'flex', justifyContent: 'center' }}
                />
            </Card>
            <TableDialog open={open} setOpen={setOpen} currentTableData={currentTableData} setCurrentTableData={setCurrentTableData} />
            <PublishDialog
                openPublish={openPublish}
                setOpenPublish={setOpenPublish}
                filteredAndSortedTableData={filteredAndSortedTableData}
            />
            <UploadDialog openUpload={openUpload} setOpenUpload={setOpenUpload} />
        </>
    );
};
export default KnowledgeResearchTable;
