import { useDispatch, useSelector } from 'react-redux';
import RenderField from 'utils/RenderField';
import { CardContent, Divider, Grid, Typography } from '@mui/material';
import React,{ useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { submitContactUs } from 'redux/actions/GeneralCalActions';
import { CustomButton } from 'assets/jss/ButtonStyle';
import { ContactFormCard, ContactusBox } from 'assets/jss/ContactusStyle';
import { CalCardActions } from 'assets/jss/RetirementStyle';
import ReCAPTCHA from 'react-google-recaptcha';
import { toast } from 'react-toastify';
import { submitContactUsError } from 'redux/actions/GeneralCalActions';

export default function ContactUsForm() {
    const dispatch = useDispatch();
    const general = useSelector((state) => state.generalCal);
    const [captchaToken, setCaptchaToken] = useState(null);
    let captcha;

    const {
        handleSubmit,
        control,
        formState: { isSubmitting },
        setValue,
        reset
    } = useForm({
        mode: 'onChange',
        defaultValues: {
            email: '',
            helpdesc: '',
            name: '',
            number: ''
        }
    });
    const onSubmit = (data) => {
        if (!captchaToken) {
            return toast.error('Please complete captcha.');
        } else {
            const objToPass = {
                ...data,
                token: captchaToken
            };
            dispatch(submitContactUs(objToPass));
            captcha.reset();
        }
    };
    useEffect(() => {
        if (general.submitContactUsSuccess) {
            reset({});
            //reset after submitting the form
            dispatch(submitContactUsError());
        }
    }, [general.submitContactUsSuccess]);
    // set the captcha ref
    const setCaptchaRef = (ref) => {
        if (ref) {
            return (captcha = ref);
        }
    };
    return (
        <ContactusBox className="calFormbox">
            <Typography variant="h1" sx={{ mb: 2 }}>
                Get In Touch
            </Typography>
            <Typography variant="subheading2" className="finbot-subheading" sx={{ mb: 1.5, display: 'block' }}>
                Feel free to ask us anything
            </Typography>
            <ContactFormCard elevation={3}>
                <CardContent>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container>
                            <Grid item xs={12} md={6}>
                                <RenderField
                                    name="name"
                                    control={control}
                                    label="Name"
                                    id="contact_us_name"
                                    type="text"
                                    required
                                    fullWidth
                                    BootstrapInput={true}
                                    customPatternName="validString"
                                    placeholder="Enter your name"
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <RenderField
                                    name="number"
                                    control={control}
                                    label="Phone Number"
                                    id="contact_us_number"
                                    required
                                    type="phone"
                                    placeholder="Enter phone number"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <RenderField
                                    name="email"
                                    control={control}
                                    label="Email ID"
                                    id="contact_us_email"
                                    type="text"
                                    required
                                    fullWidth
                                    BootstrapInput={true}
                                    customPatternName="email"
                                    placeholder="Enter email address"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <RenderField
                                    BootstrapInput={true}
                                    name="helpdesc"
                                    control={control}
                                    label="Message"
                                    id="helpdesc"
                                    type="text"
                                    required
                                    multiline
                                    rows={4}
                                    maxRows={4}
                                    maxLength={250}
                                    customPatternName={/^([a-z0-9A-Z!? '. ;]{1,250})$/g}
                                    placeholder="Describe your question here."
                                    setValue={setValue}
                                />
                            </Grid>
                            <Grid item xs={12} sx={{ ml: 1 }}>
                                {process.env.REACT_APP_CAPTCHA_SITE_KEY && (
                                    <ReCAPTCHA
                                        ref={(r) => setCaptchaRef(r)}
                                        sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                                        onChange={(val) => setCaptchaToken(val)}
                                    />
                                )}
                            </Grid>
                            <Grid item xs={12} display={'flex'} sx={{ my: 2, position: 'relative' }}>
                                <RenderField
                                    control={control}
                                    id="accept_checkbox"
                                    type="checkbox"
                                    name="accept_checkbox"
                                    label="Acknowledge terms"
                                    required
                                    setValue={setValue}
                                    formstyle={{margin:'0 8px'}}
                                />
                                <Typography textAlign={'left'}>
                                    I'd like to receive more information about The People’s Economist; I understand and agree to the privacy
                                    policy.
                                </Typography>
                            </Grid>
                        </Grid>
                        <Divider className="contactus-divider" />
                        <CalCardActions className="contactus-cardaction">
                            <CustomButton
                                type="submit"
                                ps={'9px 30px'}
                                variant="contained"
                                // color="rose"
                                disabled={isSubmitting}
                            >
                                Submit
                            </CustomButton>
                        </CalCardActions>
                    </form>
                    <Typography>
                        *For further questions please email <b>support@tpehub.com</b>{' '}
                    </Typography>
                </CardContent>
            </ContactFormCard>
        </ContactusBox>
    );
}
