import React, { useCallback, useEffect, useMemo, useState } from "react";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import {
  Box, CardContent, IconButton,
  Typography
} from "@mui/material";
import { DropzoneCardActions, DropzonePreviewCard, DropzoneTextBox, PreviewFileGrid, StyledCardHeader } from "assets/jss/ReactDropzoneStyle";
import { useDropzone } from "react-dropzone";
import CustomTooltip from "./CustomTooltip";
const baseStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#757575",
  borderStyle: "solid",
  height:'288px',
  backgroundColor: "#fafafa",
  // color: "#bdbdbd",
  transition: "border .3s ease-in-out",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};
const CustomReactDropzone = (props) => {
  const [files, setFiles] = useState([]);

  const onDrop = useCallback((acceptedFiles) => {
    if (props.onDrop) {
      props.onDrop(acceptedFiles);
    }
    const selectedFiles = acceptedFiles.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );
    setFiles(selectedFiles);
    props.handleDroppedFiles(selectedFiles);
  }, []);

  useEffect(() => {
    setFiles([]);
  }, [props.resetDropzoneValue]);

  const handleRemoveFile = (e, file) => {
    const newFiles = [...files]; // make a var for the new array
    const removeFileIndex = newFiles.indexOf(file);
    newFiles.splice(removeFileIndex, 1); // remove the file from the array
    setFiles(newFiles);
    props.handleDroppedFiles(newFiles);
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    accept: props.accept,
    maxFiles: props.maxFiles|| 5,
    // accept: "image/jpeg, image/png",
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );
  const thumbs = files.map((file, i) => (
      <DropzonePreviewCard
          key={`${file.name}_${i}_droppedFile`}
      >
          <StyledCardHeader
              action={
                  <IconButton className="MuiDropzonePreviewList-removeButton" onClick={(e) => handleRemoveFile(e, file)}>
                      <RemoveCircleIcon />
                  </IconButton>
              }
          />
          <CardContent>
              <InsertDriveFileIcon className="MuiDropzonePreviewList-image" />
          </CardContent>
          <DropzoneCardActions className="contactus-cardaction">
              <CustomTooltip tooltipText={file.name} textTooltip={false} />
          </DropzoneCardActions>
      </DropzonePreviewCard>
  ));

  // clean up
  useEffect(
    () => () => {
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  return (
      <>
          <Box {...getRootProps({ style })}>
              <input {...getInputProps()} />
              <DropzoneTextBox>
                  <Typography>Drag and drop your files here to upload.</Typography>
                  <Typography>or</Typography>
                  <Typography>Click here to upload from local drive.</Typography>
                  <em>({props.maxFiles || 5} files are the maximum number of file(s) you can drop here)</em>
              </DropzoneTextBox>
          </Box>
          {files.length > 0 ? (
              <PreviewFileGrid container spacing={3}>
                  {thumbs}
              </PreviewFileGrid>
          ) : (
              ''
          )}
      </>
  );
};

export default CustomReactDropzone;
