import { makeStyles } from '@mui/styles';
import { ResponsiveLine } from '@nivo/line';
import styles from 'assets/jss/material-kit-react/views/portfolioPage';
import { SecondGraphDiv } from 'assets/jss/RetirementStyle';
import { WrapperChartDiv } from 'assets/jss/RetirementStyle';
import BarChart from 'components/Charts/BarChart';
import React, { useState } from 'react';
import Globals from 'Globals.js';

import { abbreviate_number, numberWithCommas } from 'utils/Utils';
const useStyles = makeStyles(styles);
export const BiaxialRetirementChart = ({ bardata, linedata, classes }) => {
    const hasWindow = typeof window !== 'undefined';
    const chartclasses = useStyles();
    let count = 75;
    const [chartSetup, setChartSetup] = useState(getWindowDimensions());
    function getWindowDimensions() {
        const width = hasWindow ? window.innerWidth : null;
        let obj = {
            marginBottom: 150,
            sliceLimitForLegendText: [14, 13],
            sliceArrCount: 2
        };
        if (width <= 425 && width > 375) {
            count += 8;
        } else if (width <= 375 && width > 320) {
            count += 8;
        } else if (width <= 320) {
            count += 19;
            obj = {
                ...obj,
                sliceLimitForLegendText: [10, 9]
                // marginBottom: 100,
            };
        }
        return obj;
    }
    /**
     * to display tooltip on hover of the
     * @param {*} param
     * @returns
     */
    const CustomTooltip = (tProps) => {
        if (tProps) {
            const x = tProps.point.data.x;
            const color = tProps.point.color;
            const currValueleft = numberWithCommas(tProps.point.data.y.toFixed(2));
            let objToDisplay = {};
            bardata.forEach((element) => {
                if (element.label === x) {
                    objToDisplay = {
                        'Future Expense': element['Future Expense'],
                        'Future Income': element['Future Income']
                    };
                }
            });
            return (
                <div className={chartclasses.customBarChartTooltip}>
                    <div className={chartclasses.barChartInnerParent}>
                        <span>
                            <p className={chartclasses.tooltipLabel}>
                                {'Year'}:<strong>{x}</strong>
                            </p>
                        </span>
                    </div>
                    <div className={chartclasses.barChartInnerParent}>
                        <span className={chartclasses.colorBox} style={{ background: '#10bed394' }}></span>
                        <span>
                            <p className={chartclasses.tooltipLabel}>
                                Future Income : <strong>{objToDisplay['Future Income']}</strong>
                            </p>
                        </span>
                    </div>
                    <div className={chartclasses.barChartInnerParent}>
                        <span className={chartclasses.colorBox} style={{ background: '#ff9800' }}></span>
                        <span>
                            <p className={chartclasses.tooltipLabel}>
                                Future Expense : <strong>{objToDisplay['Future Expense']}</strong>
                            </p>
                        </span>
                    </div>
                    <div className={chartclasses.barChartInnerParent}>
                        <span className={chartclasses.colorBox} style={{ background: color }}></span>
                        <span>
                            {' '}
                            <p className={chartclasses.tooltipLabel}>
                                {tProps.point.serieId} : <strong>{currValueleft}</strong>
                            </p>
                        </span>
                    </div>
                </div>
            );
        }
        return null;
    };
    return (
        <WrapperChartDiv>
            <div className={'graph-container'}>
                <BarChart
                    halfGraph={true}
                    chartData={{ data: bardata }}
                    customCount={55}
                    colorProps={Globals.Colors}
                    marginProp={{ top: 50, right: 70, bottom: 100, left: 50 }}
                />
            </div>
            <SecondGraphDiv>
                <ResponsiveLine
                    data={[linedata.data[0]]}
                    useMesh={true}
                    colors={Globals.Colors[3]}
                    padding={10}
                    margin={{ top: 50, right: 70, bottom: 100, left: 50 }}
                    axisRight={{
                        format: (tick) => numberWithCommas(abbreviate_number(tick)),
                        legend: 'Year by year Networth',
                        legendPosition: 'middle',
                        legendOffset: 60
                    }}
                    yScale={{
                        type: 'linear',
                        min: 'auto',
                        max: 'auto',
                        stacked: false,
                        reverse: false
                    }}
                    axisLeft={null}
                    enableGridX={false}
                    enableGridY={false}
                    axisBottom={null}
                    tooltip={(val) => CustomTooltip(val)}
                    legends={[
                        {
                            anchor: 'bottom',
                            direction: 'row',
                            itemWidth: 120,
                            translateX: 0,
                            translateY: 48,
                            itemHeight: 0,
                            itemsSpacing: 0,
                            itemOpacity: 0.8,
                            symbolSize: 10,
                            justify: false,
                            dataFrom: 'keys',
                            symbolShape: 'square'
                        }
                    ]}
                />
            </SecondGraphDiv>
        </WrapperChartDiv>
    );
};

