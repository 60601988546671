import * as ActionTypes from "../actions/ActionTypes";

const initialState = {
  loginDetail: {},
  registerData: {},
  loader: false,
  passwordUpdated:false
};

export const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SUBMIT_LOGIN_INIT:
    case ActionTypes.SUBMIT_FORGOT_RESET_PASS_INIT:
    case ActionTypes.SUBMIT_REGISTER_INIT:
    case ActionTypes.HANDLE_LOGOUT_INIT:
      return { ...state, loader: true };
    case ActionTypes.SUBMIT_LOGIN_SUCCESS:
      return { ...state, loader: false, loginDetail: action.data };
    case ActionTypes.HANDLE_LOGOUT_SUCCESS:
    case ActionTypes.HANDLE_LOGOUT_ERROR:
    case ActionTypes.SUBMIT_LOGIN_ERROR:
    case ActionTypes.SUBMIT_REGISTER_ERROR:
      return { ...state, loader: false };
    case ActionTypes.SUBMIT_REGISTER_SUCCESS:
      return { ...state, loader: false, registerData: action.data };
    case ActionTypes.SUBMIT_FORGOT_RESET_PASS_SUCCESS:
      return { ...state, passwordUpdated: true,loader: false };
    case ActionTypes.SUBMIT_FORGOT_RESET_PASS_ERROR:
      return { ...state, passwordUpdated: false,loader: false };

    default:
      return state;
  }
};
