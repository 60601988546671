import { Box, IconButton, Typography } from '@mui/material';
import { ScrollableEmailSignupBox } from 'assets/jss/FooterStyle';
import React, { useState, useEffect } from 'react';
import EmailSub from './EmailSub';
import DraftsOutlinedIcon from '@mui/icons-material/DraftsOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { useLocation } from 'react-router-dom';

export const ScrollableEmailSub = (props) => {
    const [showPopup, setShowPopup] = useState(false);
    const history = useLocation();
    useEffect(() => {
        const footer = document.getElementById('tpe-footer');
        if (footer && history.pathname === '/') {
            function handleScroll() {
                const popup = document.getElementById('scroll-singup-box');
                const popupRect = popup?.getBoundingClientRect();
                const footerRect = footer?.getBoundingClientRect();
                const isOverlapping = popupRect?.top + popupRect?.height > footerRect.top;
                const isPopupClosed = localStorage.getItem('popupClosed');
                if (window.scrollY > 500 && !isOverlapping && (isPopupClosed == 'false' || isPopupClosed == null)) {
                    setShowPopup(true);
                } else {
                    setShowPopup(false);
                }
            }
            window.addEventListener('scroll', handleScroll);
            return () => {
                setShowPopup(false);
                // localStorage.setItem('popupClosed', false);
                window.removeEventListener('scroll', handleScroll);
            };
        }
    }, [history.pathname]);
    const onCloseScrollablePopup = () => {
        setShowPopup(false);
        localStorage.setItem('popupClosed', true);
    };
    return (
        <ScrollableEmailSignupBox id={'scroll-singup-box'} sx={{ visibility: (showPopup && 'show') || 'hidden' }}>
            <IconButton
                aria-label="close"
                onClick={onCloseScrollablePopup}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500]
                }}
            >
                <CloseIcon />
            </IconButton>
            <Box className="inner-box">
                <DraftsOutlinedIcon sx={{ fontSize: 40 }} color="primary" />
                <Typography variant="h4" mb={1}>
                    Stay in touch
                </Typography>
                <Typography variant="body1" mb={1}>
                    Keep informed with the most important events in market and advanced calculators.
                </Typography>
                <EmailSub handleSubscribeSubmit={onCloseScrollablePopup} />
            </Box>
        </ScrollableEmailSignupBox>
    );
};
