import * as ActionTypes from '../actions/ActionTypes';

const initialState = {
    loader: false,
    CollegeDetails: {},
    collegeNames: {}
};

export const CollegeCalReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.FETCH_COLLEGE_CAL_INIT:
            return { ...state, loader: true };
        case ActionTypes.FETCH_COLLEGE_CAL_SUCCESS:
            let filteredData = action.data.data;
            let formatChartData = [
                {
                    Header: 'Key Spec',
                    sticky: true,
                    accessor: 'School Name',
                    disableFilters: true
                }
            ];
            if (filteredData.length > 0) {
                Object.keys(filteredData[0]).forEach((subVal) => {
                    if (subVal !== 'School Name')
                        formatChartData.push({
                            Header: subVal,
                            sticky: false,
                            accessor: subVal,
                            colorScaleArr: filteredData.map((val) => val[subVal]).filter((val) => val !== null),
                            reverseSort: subVal === 'Average Annual Cost',
                            isMiddleColorYellow: true,
                            disableFilters: true
                        });
                });
                filteredData = filteredData.map((obj) => ({ ...obj, 'Graduation Rate': `${obj['Graduation Rate']}%` }));
            }
            return {
                ...state,
                loader: false,
                collegeDetails: action.data,
                comparisonList: { column: formatChartData, data: filteredData }
            };

        case ActionTypes.FETCH_COLLEGE_CAL_ERROR:
            return { ...state, loader: true };
        case ActionTypes.GET_SCHOOL_NAMES_INIT:
            return { ...state, loader: true };
        case ActionTypes.SEARCH_SCHOOL_NAMES:
            return { ...state, loader: false, SearchNames: action.data };
        case ActionTypes.GET_SCHOOL_NAMES_ERROR:
            return { ...state, loader: false }; // Optionally handle error state
        default:
            return state;
    }
};
