import { Box, CardActions, CardContent, Divider, Grid, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Fade from '@mui/material/Fade';
import IconButton from '@mui/material/IconButton';
import Popper from '@mui/material/Popper';
import { makeStyles, useTheme } from '@mui/styles';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import CategoryIcon from '@mui/icons-material/Category';
import CreateIcon from '@mui/icons-material/Create';
import DateRangeIcon from '@mui/icons-material/DateRange';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styles from 'assets/jss/material-kit-react/views/chatBotPage';
import todoPageStyle from 'assets/jss/material-kit-react/views/todoPage';
import classNames from 'classnames';
import CustomSlider from 'components/CommonComponent/CustomSlider';
import Loader from 'components/CommonComponent/Loader';
import NoDataFound from 'components/CommonComponent/NoDataFound';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Slider from 'react-slick';
import { fetchTodoCategory, fetchTodos } from 'redux/actions/TodoActions';
import { invertColor, limitLetters } from 'utils/Utils';
import TodoGifs from './TodoGifs';
import { useNavigate } from 'react-router-dom';
import { ChatbotParentBox } from 'assets/jss/ChatBotStyle';
import { ChatbotFabBtn } from 'assets/jss/ChatBotStyle';
import { CustomButton } from 'assets/jss/ButtonStyle';
import { TodoTitleTypography } from 'assets/jss/TodoStyle';
import { StyledSettingCardHeader } from 'assets/jss/TodoStyle';
import { TodoDateWrapperTypography } from 'assets/jss/TodoStyle';
import { TypographyTodoCount } from 'assets/jss/TodoStyle';
import { ProgressTodoWrapperBox } from 'assets/jss/TodoStyle';
import { EmptyTodoCard } from 'assets/jss/TodoStyle';
import { TimeDurationWrapperBox } from 'assets/jss/TodoStyle';
import { OngoingTaskBox } from 'assets/jss/TodoStyle';

const todoUseStyles = makeStyles(todoPageStyle);
const useStyles = makeStyles(styles);
function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div className={className} style={{ ...style, display: 'block' }} onClick={onClick}>
            <IconButton aria-label="next arrow">
                <ArrowForwardIosIcon />
            </IconButton>
        </div>
    );
}

function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div className={className} style={{ ...style, display: 'block' }} onClick={onClick}>
            <IconButton aria-label="prev arrow">
                <ArrowBackIosIcon />
            </IconButton>
        </div>
    );
}
const QuickTodo = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const theme = useTheme();
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [placement, setPlacement] = useState();
    const [open, setOpen] = useState(false);
    const [progData, setProgData] = useState([]);
    const todoClasses = todoUseStyles();
    const [currPlan, setCurrPlan] = useState({ planName: 'Today', data: [] });
    const todoProps = useSelector((state) => state.todo);
    //close todo popup
    const handleClose = () => {
        setAnchorEl(null);
        setOpen(false);
        setPlacement('');
    };
    //open todo popup
    const handleClick = (newPlacement) => (event) => {
        setAnchorEl(event.currentTarget);
        setOpen((prev) => placement !== newPlacement || !prev);
        setPlacement(newPlacement);
        dispatch(fetchTodos());
        dispatch(fetchTodoCategory());
    };
    useEffect(() => {
        //send the status of popup so that todo main page gif can be hide and only popup gif can be display
        props.sendPopupVal(open);
    }, [open]);
    const settings = {
        accessibility: false,
        dots: false,
        infinite: false,
        draggable: false,
        // swipeToSlide: false,
        touchMove: false,
        // swipe:false,
        speed: 500,
        slidesToShow: 1.5,
        slidesToScroll: 1,
        autoplay: false,
        prevArrow: <PrevArrow />,
        nextArrow: <NextArrow />
    };
    useEffect(() => {
        if (todoProps.todoList.stage?.planned) {
            //to fetch data to display as upcoming todos
            const plannedList = todoProps.todoList.stage.planned;
            if (plannedList.today.length > 0) {
                setCurrPlan({ planName: "Today's Plan", data: plannedList.today });
            } else if (plannedList.thisWeek.length > 0) {
                setCurrPlan({
                    planName: "This Week's Plan",
                    data: plannedList.thisWeek
                });
            } else if (plannedList.nextWeek.length > 0) {
                setCurrPlan({
                    planName: "Next Week's Plan",
                    data: plannedList.nextWeek
                });
            } else if (plannedList.later.length > 0) {
                setCurrPlan({ planName: "Later's Plan", data: plannedList.later });
            } else if (plannedList.nodates.length > 0) {
                setCurrPlan({ planName: 'No Date', data: plannedList.nodates });
            }
        }
        if (todoProps.todoList.stage?.progress) {
            const arr = ['today', 'thisWeek', 'nextWeek', 'later', 'nodates'];
            const progData = todoProps.todoList.stage.progress;
            const newarr = arr.map((val) => progData[val]);
            setProgData(newarr.flat());
        }
    }, [todoProps.todoList?.stage]);

    // redirect to todo main page
    const handleTodoTitleClick = () => {
        navigate('/todo');
    };
    return (
        <ChatbotParentBox>
            {todoProps.enableGif && open ? <TodoGifs dispatch={dispatch} /> : ''}
            {todoProps.loader && open ? <Loader /> : ''}
            <ChatbotFabBtn aria-label={'Tpe Todo'} color={'primary'} className={`todoFab`} onClick={handleClick('top-end')} />
            <Popper
                open={open}
                anchorEl={anchorEl}
                placement={placement || 'top-end'}
                transition
                className={classNames('chatBotPopoverWrapper', classes.chatbot)}
            >
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <Card className={classNames(classes.root, classes.chatBotPopover)}>
                            <CardHeader
                                // onClick={handleTodoTitleClick}
                                className={`${classes.botHeader} ${todoClasses.todoPopupHeader} ${todoClasses.todotitle}`}
                                avatar={<BorderColorIcon />}
                                action={
                                    <IconButton aria-label="settings" onClick={handleClose}>
                                        <ExpandMoreIcon />
                                    </IconButton>
                                }
                                title="Finance Todo"
                            />
                            <CardContent className={todoClasses.todoPopupContent}>
                                <OngoingTaskBox>
                                    <TimeDurationWrapperBox className="todo-title">
                                        <Typography gutterBottom component="span">
                                            Progress Todo(s)
                                        </Typography>
                                    </TimeDurationWrapperBox>
                                    <Grid container className="ongoingContainer">
                                        <Grid item xs={12}>
                                            <Slider {...settings}>
                                                {progData.length > 0 ? (
                                                    progData.map((inpro, ip) => {
                                                        const { warning } = theme.palette;
                                                        const todoid = inpro.id;
                                                        let color = inpro.color;
                                                        let cardStyle = {};
                                                        if (!inpro.color || inpro.color === '#FFFFFF') {
                                                            color = warning.dark;
                                                            cardStyle = { borderLeft: `5px solid ${color}` };
                                                        } else {
                                                            cardStyle = {
                                                                color: invertColor(color, true),
                                                                background: `linear-gradient(180deg, ${color}, #FFFFFF)`
                                                            };
                                                        }
                                                        const title = inpro.title;
                                                        const name = inpro.name;

                                                        const catname = inpro?.category?.name;
                                                        const dueDate = inpro.due_date && moment.utc(inpro.due_date).local().format('L LT');
                                                        const progress = inpro.progress;
                                                        const todoType = inpro?.todo_type;
                                                        return (
                                                            <Card
                                                                className={`${classes.root} todoCards clearfix ${todoClasses.todoPopup}`}
                                                                variant="outlined"
                                                                key={`ongoing_todo_${todoid}_${ip}`}
                                                                id={`ongoing_todo_${todoid}_${ip}`}
                                                            >
                                                                <div className="cardContentWrapper" style={cardStyle}>
                                                                    <StyledSettingCardHeader
                                                                        onClick={() => props.handleEditTodo(inpro)}
                                                                        action={
                                                                            <IconButton aria-label="settings">
                                                                                <CreateIcon />
                                                                            </IconButton>
                                                                        }
                                                                    />
                                                                    <CardContent>
                                                                        <TodoTitleTypography
                                                                            onClick={handleTodoTitleClick}
                                                                            className={todoClasses.todotitle}
                                                                        >
                                                                            {limitLetters(name, 15)}
                                                                        </TodoTitleTypography>
                                                                        {dueDate ? (
                                                                            <TodoDateWrapperTypography
                                                                                color="textSecondary"
                                                                                variant="body2"
                                                                            >
                                                                                <span className={'datePickerIcon'}>
                                                                                    <DateRangeIcon />
                                                                                </span>
                                                                                <span className={'todoCardDate'}>{dueDate}</span>
                                                                            </TodoDateWrapperTypography>
                                                                        ) : (
                                                                            ''
                                                                        )}
                                                                        {catname ? (
                                                                            <TypographyTodoCount variant="body2">
                                                                                <span className={'datePickerIcon'}>
                                                                                    <CategoryIcon />
                                                                                </span>{' '}
                                                                                <span className={'todoCardDate'}>
                                                                                    {limitLetters(catname, 15)}
                                                                                </span>
                                                                            </TypographyTodoCount>
                                                                        ) : (
                                                                            ''
                                                                        )}
                                                                        {todoType ? (
                                                                            <TypographyTodoCount variant="body2">
                                                                                <span className={'todoCardDate'}>
                                                                                    {limitLetters(todoType, 15)}
                                                                                </span>
                                                                            </TypographyTodoCount>
                                                                        ) : (
                                                                            ''
                                                                        )}
                                                                        <TodoDateWrapperTypography variant="body2">
                                                                            <span className="priorityBlock">
                                                                                <img
                                                                                    src={`img/${inpro.priority}-prio-20.png`}
                                                                                    alt="First slide"
                                                                                    className="slick-image"
                                                                                />
                                                                            </span>
                                                                            <span className={'todoCardDate'}>
                                                                                {inpro.priority} Priority
                                                                            </span>
                                                                        </TodoDateWrapperTypography>
                                                                        <Box style={{ width: '100%' }}>
                                                                            <ProgressTodoWrapperBox>
                                                                                <CustomSlider
                                                                                    id={`progressTodo-popup-${todoid}-${ip}`}
                                                                                    defaultslidervalue={
                                                                                        props.todoProgress[todoid] || progress || 0
                                                                                    }
                                                                                    onChange={(e) => props.handleProgressChange(e, inpro)}
                                                                                    min={0}
                                                                                    valueLabelDisplay="off"
                                                                                    max={100}
                                                                                />
                                                                                <Typography variant="body2" className="progressPer">
                                                                                    {props.todoProgress[todoid] || progress || 0}%
                                                                                </Typography>
                                                                            </ProgressTodoWrapperBox>
                                                                        </Box>
                                                                    </CardContent>
                                                                </div>
                                                            </Card>
                                                        );
                                                    })
                                                ) : (
                                                    <EmptyTodoCard variant="outlined" id={`ongoing_todo_empty`}>
                                                        <div
                                                            className="cardContentWrapper"
                                                            style={{
                                                                color: '#000000de',
                                                                background: `linear-gradient(180deg, #c1c1c1, #FFFFFF)`
                                                            }}
                                                        >
                                                            <NoDataFound msg="Start working on todo(s) to display here." />
                                                        </div>
                                                    </EmptyTodoCard>
                                                )}
                                            </Slider>
                                            {/* </Card> */}
                                        </Grid>
                                    </Grid>
                                </OngoingTaskBox>
                                <div className={todoClasses.todoPopupBodyWrapper} id="hey">
                                    <div className="timeDurationParent">
                                        <div className={todoClasses.timeDurationWrapper}>
                                            <Typography gutterBottom component="span">
                                                {currPlan.planName}
                                            </Typography>
                                        </div>
                                    </div>
                                    <Grid container>
                                        {currPlan.data.length > 0 ? (
                                            currPlan.data.map((todayVal, tv) => {
                                                const { secondary } = theme.palette;
                                                const title = todayVal.title;
                                                const todoid = todayVal.id;
                                                const priority = todayVal.priority;
                                                const bordercolor = (todayVal.color !== '#FFFFFF' && todayVal.color) || secondary.main;
                                                return (
                                                    <Grid item xs={12} key={`todayPlanBlock_${todoid}`}>
                                                        <Card
                                                            className={todoClasses.todoPopup}
                                                            variant="outlined"
                                                            id={`today_plan_${todoid}_${tv}`}
                                                            style={{
                                                                borderLeft: `5px solid ${bordercolor}`
                                                            }}
                                                        >
                                                            <CardHeader
                                                                className={`${todoClasses.cardHeader} ${todoClasses.resizeSvg} test`}
                                                                onClick={() => props.handleEditTodo(todayVal)}
                                                                action={
                                                                    <IconButton aria-label="settings">
                                                                        <CreateIcon />
                                                                    </IconButton>
                                                                }
                                                            />
                                                            <CardContent>
                                                                <Typography
                                                                    className={todoClasses.todotitle}
                                                                    onClick={handleTodoTitleClick}
                                                                >
                                                                    <span className="priorityBlock">
                                                                        <img
                                                                            src={`img/${priority}-prio-20.png`}
                                                                            alt="First slide"
                                                                            className="slick-image"
                                                                        />
                                                                    </span>
                                                                    {title}
                                                                </Typography>
                                                            </CardContent>
                                                        </Card>
                                                    </Grid>
                                                );
                                            })
                                        ) : (
                                            <Grid item xs={12}>
                                                {' '}
                                                <Card
                                                    className={todoClasses.todoPopup}
                                                    variant="outlined"
                                                    id={`nodata`}
                                                    style={{ borderLeft: `5px solid #c1c1c1` }}
                                                >
                                                    <CardContent>
                                                        <NoDataFound msg="Please add new todo to display here." />
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                        )}
                                    </Grid>
                                </div>
                            </CardContent>
                            <Divider />
                            <CardActions className={todoClasses.todoCardAction}>
                                <div className={todoClasses.addTodoBlock} onClick={() => props.handleAddTodo('planned')}>
                                    <CustomButton size="xs" simple>
                                        <AddCircleIcon color="white" /> Add Todo
                                    </CustomButton>
                                </div>
                            </CardActions>
                        </Card>
                    </Fade>
                )}
            </Popper>
        </ChatbotParentBox>
    );
};

export default QuickTodo;
