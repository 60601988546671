import { Card, CardContent, Grid } from '@mui/material';
import { CustomButton } from 'assets/jss/ButtonStyle';
import { CalCardActions } from 'assets/jss/RetirementStyle';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { getDefaultOverride, submitInflationCal, submitInflationCalSuccess } from 'redux/actions/GeneralCalActions';
import RenderField from 'utils/RenderField';
const InflationBondForm = ({ classes, inflationCalData, defaultoverrideData }) => {
    const dispatch = useDispatch();
    const {
        handleSubmit,
        control,
        formState: { isDirty, isSubmitting },
        setValue
    } = useForm({
        mode: 'onChange',
        defaultValues: {
            initial_Principal: 100000,
            coupon: 4,
            ten_Year_Treasury: 1.5,
            tax_rate: 20,
            maturity: 10,
            inflrt: 3
        }
    });
    //to submit the form data
    const onSubmit = (data) => {
        const objToSend = {
            initial_Principal: data.initial_PrincipalUnFormattedValue || data.initial_Principal,
            inf_rate: data.inflrtUnFormattedValue || data.inflrt,
            coupon: data.couponUnFormattedValue || data.coupon,
            ten_Year_Treasury: data.ten_Year_TreasuryUnFormattedValue || data.ten_Year_Treasury || 0,
            tax_rate: data.tax_rateUnFormattedValue || data.tax_rate || 0,
            maturity: data.maturityUnFormattedValue || data.maturity
        };
        dispatch(submitInflationCal(objToSend));
    };
    // when any input field value will change it will call below method
    const handleFormInputChange = (e) => {
        if (isDirty && Object.keys(inflationCalData).length > 0) {
            dispatch(submitInflationCalSuccess({}));
        }
    };
    useEffect(() => {
        if (Object.keys(defaultoverrideData).length === 0) {
            dispatch(getDefaultOverride());
        }
        // reset output when route will change
        return () => {
            dispatch(submitInflationCalSuccess({}));
        };
    }, []);

    useEffect(() => {
        // to load default data in form
        if (Object.keys(defaultoverrideData).length) {
            setValue('inflrt', defaultoverrideData.inflation);
        }
    }, [defaultoverrideData]);

    return (
        <Card className={`${classes?.widgetCalCard}`} elevation={2}>
            <CardContent>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container>
                        <Grid item xs={12} sm={6}>
                            <RenderField
                                handleOnChange={handleFormInputChange}
                                name="initial_Principal"
                                control={control}
                                label="Initial Principal"
                                id="initial_Principal"
                                type="number"
                                required
                                // prefix={"$"}
                                thousandSeparator={true}
                                customPatternName="decimalRate"
                                placeholder="100,000"
                                setValue={setValue}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <RenderField
                                handleOnChange={handleFormInputChange}
                                name="coupon"
                                control={control}
                                label="Coupon"
                                id="coupon"
                                type="number"
                                required
                                // prefix={"$"}
                                thousandSeparator={true}
                                customPatternName="decimalRate"
                                placeholder="10"
                                setValue={setValue}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <RenderField
                                handleOnChange={handleFormInputChange}
                                name="ten_Year_Treasury"
                                control={control}
                                label="Ten Year Treasury"
                                id="ten_Year_Treasury"
                                type="number"
                                // prefix={"$"}
                                thousandSeparator={true}
                                customPatternName="decimalRate"
                                placeholder="10"
                                setValue={setValue}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <RenderField
                                handleOnChange={handleFormInputChange}
                                name="tax_rate"
                                control={control}
                                label="Tax Rate"
                                id="tax_rate"
                                type="number"
                                // prefix={"$"}
                                thousandSeparator={true}
                                customPatternName="decimalRate"
                                placeholder="10"
                                setValue={setValue}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <RenderField
                                handleOnChange={handleFormInputChange}
                                name="maturity"
                                control={control}
                                label="Maturity"
                                id="maturity"
                                type="number"
                                required
                                thousandSeparator={true}
                                customPatternName="non0Num"
                                placeholder="10"
                                maxLength={2}
                                setValue={setValue}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <RenderField
                                handleOnChange={handleFormInputChange}
                                name="inflrt"
                                control={control}
                                label="Inflation Rate"
                                id="inflrt"
                                type="number"
                                required
                                prefix={'%'}
                                thousandSeparator={true}
                                errorMessage={'You can pass 2 digit (+/-) number with decimal value only.'}
                                customPatternName={/^-?\d{0,2}(\.\d{1,2})?$/g}
                                placeholder="10"
                                setValue={setValue}
                            />
                        </Grid>
                    </Grid>
                    <CalCardActions className="contactus-cardaction">
                        <CustomButton
                            type="submit"
                            ps={'9px 30px'}
                            variant="contained"
                            // color="rose"
                            disabled={isSubmitting}
                        >
                            Calculate
                        </CustomButton>
                    </CalCardActions>
                </form>
            </CardContent>
        </Card>
    );
};

export default InflationBondForm;
