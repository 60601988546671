import React, {useState} from 'react';
import {
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    Paper,
    Table,
    TableCell,
    TableContainer,
    TableRow,
    Typography
} from '@mui/material';
import NoDataFound from 'components/CommonComponent/NoDataFound';
import Danger from 'components/Typography/Danger';
import Success from 'components/Typography/Success';
import Warning from 'components/Typography/Warning';
import CustomTooltip from 'components/CommonComponent/CustomTooltip';
import { OutputBox } from 'assets/jss/GeneralStyle';
import formatNumberWithComma from 'utils/formatNumberWithComma';
import Collapse from "@mui/material/Collapse";
import TableBody from "@mui/material/TableBody";
import * as tooltip from "../../Tooltip.json";
const msg =
    'This is our traffic light system warning you when your finances are OK (Green), warning zone (yellow) or danger zone (red). The threshold varies per calculator. You can improve your assumptions and the traffic light will improve when you recalculate.';
const components = {
    red: { name: Danger, msg: msg, imgname: 'red' },
    yellow: { name: Warning, msg: msg, imgname: 'yellow' },
    green: { name: Success, msg: msg, imgname: 'green' }
};
const StudLoanOuput = ({ data, classes }) => {
    const CompoColorType = components[data['Traffic Light']?.toLowerCase()];
    const [expanded, setExpanded] = useState([
        { bool: false, key: 'Total Loans (Principal)' },
        { bool: false, key: 'Total Loans (Interest)' },
    ]);
    const handleExpandClick = (index) => {
        setExpanded((prevExpanded) => {
            const newExpanded = [...prevExpanded];
            newExpanded[index] = { ...newExpanded[index], bool: !newExpanded[index]?.bool };
            return newExpanded;
        });
    };
    return (
        <Card elevation={2} sx={{ mb: 3.5 }}>
            <CardContent>
                <Typography variant="h6" sx={{ mb: 2, display: 'flex' }} gutterBottom>
                    Summary Output
                </Typography>
                {Object.keys(data).length > 0 ? (
                    <>
                        <Box id="outputWrapper" className="widget-full-width">
                            <Grid container spacing={2} sx={{paddingBottom: "16px"}}>
                                <Grid item xs={12} md={6}>
                                    <OutputBox>
                                        <Typography variant="body1" className="outputLabel" component="p">
                                            First Year Cost To Attend
                                            <CustomTooltip
                                                tooltipText={tooltip.default?.['First Year Cost To Attend']}
                                                customTooltipStyle={{ maxWidth: 450 }}
                                                label={'First Year Cost To Attend'}
                                            />
                                        </Typography>
                                        <Typography variant="h6">{data['Annual Cost']}</Typography>
                                    </OutputBox>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <OutputBox>
                                        <Typography variant="body1" className="outputLabel" component="p">
                                            Annual Loan Payment After Graduation
                                            <CustomTooltip
                                                tooltipText={tooltip.default?.['Annual Loan Payment After Graduation']}
                                                customTooltipStyle={{ maxWidth: 450 }}
                                                label={'Annual Loan Payment After Graduation'}
                                            />
                                        </Typography>
                                        <Typography variant="h6">{data['annualTotalPayment']}</Typography>
                                        <Typography variant="caption" component="p">
                                            (Annually)
                                        </Typography>
                                    </OutputBox>
                                </Grid>
                                </Grid>

                            <Grid container spacing={2} sx={{ marginBottom: { xs: "16px", md: "6px" } }}>
                                <Grid item xs={12} md={6}>
                                    <OutputBox>
                                        <Typography variant="body1" className="outputLabel" component="p">
                                            Total Cost to attend (for the entire period)
                                            <CustomTooltip
                                                tooltipText={tooltip.default?.['Total Cost to attend (for the entire period)']}
                                                customTooltipStyle={{ maxWidth: 450 }}
                                                label={'Total Cost to attend (for the entire period)'}
                                            />
                                        </Typography>
                                        <Typography variant="h6">{data['Total Cost']}</Typography>
                                    </OutputBox>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <OutputBox>
                                        <Typography variant="body1" className="outputLabel" component="p">
                                            Student Loan Payment to Income Percent
                                            <CustomTooltip
                                                tooltipText={tooltip.default?.['Student Loan Payment to Income Percent']}
                                                customTooltipStyle={{ maxWidth: 450 }}
                                                label={'Student Loan Payment to Income Percent'}
                                            />
                                        </Typography>
                                        {CompoColorType !== undefined ? (
                                            <CompoColorType.name className="value-with-signal" mt={2}>
                                                <Typography variant="h6" className="outputLabel" component="p" color={'inherit'}>
                                                    {data['loanPayToIcomePer']}
                                                </Typography>
                                                <CustomTooltip
                                                    style={{ margin: '3px 5px ' }}
                                                    tooltipText={CompoColorType.msg}
                                                    imgTooltip={true}
                                                    img={<img src={`img/Signal-${CompoColorType.imgname}.png`} alt="tpe traffic signal" />}
                                                />
                                            </CompoColorType.name>
                                        ) : (
                                            ''
                                        )}
                                        <Typography variant="caption" component="p">
                                            (Annually)
                                        </Typography>
                                    </OutputBox>
                                </Grid>
                            </Grid>

                            <Grid container spacing={2} sx={{paddingBottom: "16px"}}>
                                <Grid item xs={12} md={6}>
                                    <OutputBox>
                                        <Typography variant="body1" className="outputLabel" component="p">
                                            Parent Contribution
                                            <CustomTooltip
                                                tooltipText={tooltip.default?.['Parent Contribution']}
                                                customTooltipStyle={{ maxWidth: 450 }}
                                                label={'Parent Contribution'}
                                            />
                                        </Typography>
                                        <Typography variant="h6">{data['Parent Contribution']}</Typography>
                                    </OutputBox>
                                </Grid>
                            </Grid>

                            <Grid container spacing={2} sx={{paddingBottom: "16px"}}>
                            <Grid item xs={12} md={6}>
                                    <OutputBox>
                                        <Typography variant="body1" className="outputLabel" component="p">
                                            Total Loans (Principal)
                                        </Typography>
                                        <Typography variant="h6">{data['Total Loans (Principal)']}</Typography>
                                        <Button onClick={() => handleExpandClick(1)} size="small" color="primary">
                                            {expanded[1]?.bool ? 'Less Info' : 'More Info'}
                                        </Button>
                                            {expanded[1]?.bool && (
                                                <Collapse in={expanded[1]?.bool} timeout="auto" unmountOnExit>
                                                        <TableContainer component={Paper}>
                                                            <Table>
                                                                <TableBody>
                                                                    <TableRow>
                                                                        <TableCell align="left">{'Federal (Principal)'}</TableCell>
                                                                        <TableCell align="right">
                                                                            {data['Federal (Principal)']}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell align="left">{'Private (Principal)'}</TableCell>
                                                                        <TableCell align="right">
                                                                            {data['Private (Principal)']}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer>
                                                </Collapse>
                                            )}
                                    </OutputBox>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} sx={{paddingBottom: "16px"}}>

                            <Grid item xs={12} md={6}>
                                    <OutputBox>
                                        <Typography variant="body1" className="outputLabel" component="p">
                                            Total Loans (Interest)
                                        </Typography>
                                        <Typography variant="h6">{data['Total Loans (Interest)']}</Typography>
                                        <Button onClick={() => handleExpandClick(0)} size="small" color="primary">
                                            {expanded[0]?.bool ? 'Less Info' : 'More Info'}
                                        </Button>
                                        {expanded[0]?.bool && (
                                            <Collapse in={expanded[0]?.bool} timeout="auto" unmountOnExit>
                                                <TableContainer component={Paper}>
                                                    <Table>
                                                        <TableBody>
                                                            <TableRow>
                                                                <TableCell align="left">{'Federal (Interest)'}</TableCell>
                                                                <TableCell align="right">
                                                                    {data['Federal (Interest)']}
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell align="left">{'Private (Interest)'}</TableCell>
                                                                <TableCell align="right">
                                                                    {data['Private (Interest)']}
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </Collapse>
                                        )}
                                    </OutputBox>
                                </Grid>
                            </Grid>
                        </Box>
                    </>
                ) : (
                    <NoDataFound />
                )}
            </CardContent>
        </Card>
    );
};

export default StudLoanOuput;
