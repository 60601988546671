import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import { OutputBox } from "assets/jss/GeneralStyle";
import NoDataFound from "components/CommonComponent/NoDataFound";
import React from "react";
import { numberWithCommas } from "utils/Utils";
import * as tooltip from 'Tooltip.json';
import CustomTooltip from "components/CommonComponent/CustomTooltip";

const PropertyInvestOuput = ({ data }) => {
  return (
      <Card elevation={2}>
          <CardContent>
              <Typography variant="h6" sx={{ mb: 2, display: 'flex' }} gutterBottom>
                  Summary Output
              </Typography>
              {data && Object.keys(data).length > 0 ? (
                  <Box id="outputWrapper" className="widget-full-width">
                      <Grid container spacing={2}>
                          {Object.keys(data).map((val, i) => {
                              return (
                                  <React.Fragment key={i + 'tipsoutput'}>
                                      <Grid item xs={12} md={4}>
                                          <OutputBox>
                                              <Typography variant="body2" className="outputLabel" component="p">
                                                  {val}
                                                  {tooltip.default?.[val] && (
                                                      <CustomTooltip
                                                          tooltipText={tooltip.default?.[val]}
                                                          customTooltipStyle={{ maxWidth: 450 }}
                                                          label={val}
                                                      />
                                                  )}
                                              </Typography>
                                              <Typography variant="h5">{numberWithCommas(data[val].toFixed(2))}</Typography>
                                          </OutputBox>
                                      </Grid>
                                  </React.Fragment>
                              );
                          })}
                      </Grid>
                  </Box>
              ) : (
                      <NoDataFound />
              )}
          </CardContent>
      </Card>
  );
};

export default PropertyInvestOuput;
