import React from 'react';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import { BannerBox, BannerSigninButton, ComputerBox, TypographySubHeading } from 'assets/jss/BannerStyle';
import { useNavigate } from 'react-router-dom';
import { getCookie } from 'utils/Utils';

const LandingBanner = (props) => {
    const navigate = useNavigate();
    const isMobile = window.innerWidth < 768;
    return (
        <BannerBox>
            <Box>
                <iframe
                    style={{
                        position: !isMobile ? 'absolute' : '',
                        width: !isMobile ? 'revert-layer' : '',
                        height: !isMobile ? '140px' : '',
                        top: !isMobile ? '200px' : '',
                        left: !isMobile ? '20px' : '',
                        display: isMobile ? 'none' : ''
                    }}
                    width="260"
                    height="115"
                    src="https://www.youtube.com/embed/z5m5NCfP5_k"
                    title="TPE video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen="allowfullscreen"
                    mozallowfullscreen="mozallowfullscreen"
                    msallowfullscreen="msallowfullscreen"
                    oallowfullscreen="oallowfullscreen"
                    webkitallowfullscreen="webkitallowfullscreen"
                ></iframe>
            </Box>

            <Box className="banner-text-box">
                <Typography variant="h1" align="center" sx={{ mt: -15, mb: 1.3 }}>
                    Welcome to The People's Economist
                </Typography>
                <TypographySubHeading variant="h6" align="center">
                    TPE is the world's first fully integrated lifecycle suite of personal finance tools and dashboards.
                </TypographySubHeading>
                <TypographySubHeading variant="h5" align="center">
                    Your Financial Future Starts With Us
                </TypographySubHeading>
                {!getCookie('auth_token') && (
                    <BannerSigninButton variant="contained" align="center" sx={{ mt: 2 }} onClick={() => navigate('/sign-up')}>
                        Sign Up - It’s Free
                    </BannerSigninButton>
                )}
                {/* <ComputerBox></ComputerBox> */}
                {/* <Box sx={{ position: 'relative', width: 'inherit', height: 'inherit' }}> */}
                <ComputerBox
                    alt="Tpe Desktop"
                    src="img/Computer.png"
                    //    style={{ position: 'relative', width: 'inherit', height: 'inherit' }}
                />
            </Box>
        </BannerBox>
    );
};

export default LandingBanner;
