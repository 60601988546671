import * as ActionTypes from '../actions/ActionTypes';

const initialState = {
    ecoDashboardDetail: {},
    ecoHistoryData: {},
    loader: false
};

export const EconomicDashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.FETCH_ECONOMIC_DASHBOARD_INIT:
        case ActionTypes.FETCH_ECO_HISTORY_INIT:
            return { ...state, loader: true };
        case ActionTypes.FETCH_ECONOMIC_DASHBOARD_SUCCESS:
            return {
                ...state,
                loader: false,
                ecoDashboardDetail: action.data
            };
        case ActionTypes.FETCH_ECO_HISTORY_SUCCESS:
            return {
                ...state,
                loader: false,
                ecoHistoryData: action.data
            };
        case ActionTypes.FETCH_ECONOMIC_DASHBOARD_ERROR:
        case ActionTypes.FETCH_ECO_HISTORY_ERROR:
            return { ...state, loader: false };
        default:
            return state;
    }
};
