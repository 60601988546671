import React from 'react';
import { Card, CardContent, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { QuesAnsBox } from 'assets/jss/RetirementStyle';
import { getCookie } from 'utils/Utils';
import CalSignupBlock from 'views/RetirementCalculator/CalSignupBlock';
const LifeInsDesc = (props) => {
    return (
        <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12} md={(getCookie('auth_token') && 12) || 8}>
                <Card>
                    <CardContent>
                        <QuesAnsBox>
                            <Typography variant="h2" id="description" sx={{ mb: 2, display: 'flex' }}>
                                About life insurance calculator
                            </Typography>
                            <Typography variant="body1" className="dashboard-subheading" sx={{ mb: 1.5 }}>
                                While many people view life insurance as insurance, the way TPE looks at whole life insurance is as an
                                investment. Whole life insurance is insurance that is in full effect for the life of the insured. It’s in
                                contrast to term-life which expires after a specific period. Whole life (AKA Universal Life) can be compared
                                to a long-term fixed income or bond instrument. This calculator computes the returns (AKA yield) based on
                                projections of mortality of the insured, the insured amount and the annual premium. Users can use this to
                                compare to returns they would get by buying the bonds of the insurer or long-term bonds of the US Treasury.
                                Life insurance, generally, is lower credit risk than the risk of the company providing the insurance.
                                Further, as most large life insurance companies are highly rated their insurance can be compared to high
                                grade corporate bonds or govt bonds. Future versions of our calculators will include term life (which
                                typically expires after a fixed period such as 10 years).
                            </Typography>
                        </QuesAnsBox>
                    </CardContent>
                </Card>
            </Grid>
            {!getCookie('auth_token') && (
                <Grid item xs={12} md={4}>
                    <CalSignupBlock />
                </Grid>
            )}
        </Grid>
    );
};

export default LifeInsDesc;
