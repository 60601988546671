import React, { Fragment, useEffect, useRef } from 'react';
import { makeStyles } from '@mui/styles';
import styles from 'assets/jss/material-kit-react/views/generalPage';
// nodejs library that concatenates classes
import { Link as RouterLink } from 'react-router-dom';
// Sections for this page
import CommonCalculator from 'components/CommonComponent/CommonCalculator.js';
import { useSelector } from 'react-redux';
import Loader from 'components/CommonComponent/Loader.js';
import { numberWithCommas } from 'utils/Utils';
import NoDataFound from 'components/CommonComponent/NoDataFound';
import { Box, Card, CardContent, Container, Grid, Typography } from '@mui/material';
import { CustomListItem } from 'assets/jss/HeaderStyle';
import { CustomListItemText } from 'assets/jss/HeaderStyle';
import LifeInsDesc from './LifeInsDesc';
import { MainBox } from 'assets/jss/GeneralStyle';
import LifeInsOutput from './LifeInsOutput';
import LifeInsForm from './LifeInsForm';
import { WidgetBox } from 'assets/jss/GeneralStyle';
import Meta from 'components/meta';
const useStyles = makeStyles(styles);

export default function LifeInsIndex(props) {
    const classes = useStyles();
    const generalCal = useSelector((state) => state.generalCal);

    const typographyRef = useRef(null);
    useEffect(() => {
        // Set the ID after the component mounts
        if (typographyRef.current) {
            typographyRef.current.id = 'lifeCal';
        }
    }, []);

    const render = () => {
        return (
            <>
                {generalCal.lifeInsData.loader ? <Loader /> : ''}
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4.5} className="widget-grid-item">
                        <LifeInsForm lifeinsCalData={generalCal.lifeInsData?.calData} classes={classes} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={7.5} className="widget-grid-item">
                        <LifeInsOutput />
                    </Grid>
                </Grid>
            </>
        );
    };
    const breadcrumbs = [
        <CustomListItem className="cal-banner-menu" key="1" sx={{ m: 0 }} color="text.light">
            <CustomListItemText primary={'Home'} />
        </CustomListItem>,
        <CustomListItem className="cal-banner-menu" key="2" sx={{ m: 0 }} color="text.light">
            <CustomListItemText primary={'Insurance'} />
        </CustomListItem>,
        <Typography ref={typographyRef} key="3" color="text.light" className="current-menu">
            Life Insurance Calculator
        </Typography>
    ];
    return (
        (props.widgetcal && <WidgetBox>{render()}</WidgetBox>) || (
            <>
               <Meta
                title="Life Insurance Calculator | TPE Hub"
                description="Calculate the amount of life insurance coverage you need with our easy-to-use Life Insurance Calculator. Input your financial details and get an estimate to help you make informed decisions about your coverage."
                page="/life-insurance-calculator"
            />
                <CommonCalculator breadcrumbs={breadcrumbs} variantText="Life Insurance Calculator" />
                <MainBox>
                    <Container maxWidth="lg">
                        {render()}
                        <LifeInsDesc />
                    </Container>
                </MainBox>
            </>
        )
    );
}
