import * as ActionTypes from "./ActionTypes";
import { apiCall, handleError } from "utils/Utils";
import Globals from "Globals.js";
import { toast } from "react-toastify";

//handle success of api
export const submitStudLoanCalSuccess = val => {
  return {
    type: ActionTypes.SUBMIT_STUD_LOAN_SUCCESS,
    data: val
  };
};
// handle api error
export const submitStudLoanCalError = () => {
  return {
    type: ActionTypes.SUBMIT_STUD_LOAN_ERROR
  };
};

/**
 * @author disha
 * @param {*} data
 * @use to calculate student loan
 * @returns
 */
export const submitStudLoanCal = data => async dispatch => {
  try {
      dispatch({
          type: ActionTypes.SUBMIT_STUD_LOAN_INIT,
      });
    const apiResponse = await apiCall(
      `${Globals.API_ROOT_URL}studentloancalculator`,"post",data);
    if (apiResponse !== undefined && apiResponse.status === 200) {
      dispatch(submitStudLoanCalSuccess(apiResponse.data.data));
    } else {
      handleError(apiResponse,dispatch);
      dispatch(submitStudLoanCalError());
    }
  } catch (error) {
    toast.error(Globals.errorMsg);
    dispatch(submitStudLoanCalError());
    throw error;
  }
};

//handle success of api
export const getStudLoanSavedCalSuccess = val => {
  return {
    type: ActionTypes.GET_STUD_LOAN_SAVED_SUCCESS,
    data: val
  };
};
// handle api error
export const getStudLoanSavedCalError = () => {
  return {
    type: ActionTypes.GET_STUD_LOAN_SAVED_ERROR
  };
};

/**
 * @author disha
 * @param {*} data
 * @use to get student loan
 * @returns
 */
export const getStudLoanSavedCal = data => async dispatch => {
  try {
      dispatch({
          type: ActionTypes.GET_STUD_LOAN_SAVED_INIT,
      });
    const apiResponse = await apiCall(`${Globals.API_ROOT_URL}studentloancalculator`,"get");
    if (apiResponse !== undefined && apiResponse.status === 200) {
      dispatch(getStudLoanSavedCalSuccess(apiResponse.data.data));
    } else {
      // handleError(apiResponse,dispatch);
      dispatch(getStudLoanSavedCalError());
    }
  } catch (error) {
    toast.error(Globals.errorMsg);
    dispatch(getStudLoanSavedCalError());
    throw error;
  }
};
