import * as ActionTypes from "../actions/ActionTypes";

const initialState = {
  studLoanData: {},
  loader: false,
  savedStudLoan: {},
};

export const StudLoanCalReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SUBMIT_STUD_LOAN_INIT:
    case ActionTypes.GET_STUD_LOAN_SAVED_INIT:
      return { ...state, loader: true };
    case ActionTypes.SUBMIT_STUD_LOAN_SUCCESS:
      return {
        ...state,
        loader: false,
        studLoanData: action.data,
      };
    case ActionTypes.GET_STUD_LOAN_SAVED_SUCCESS:
      return {
        ...state,
        loader: false,
        savedStudLoan: action.data,
      };
    case ActionTypes.SUBMIT_STUD_LOAN_ERROR:
    case ActionTypes.GET_STUD_LOAN_SAVED_ERROR:
      return { ...state, loader: false };
    default:
      return state;
  }
};
