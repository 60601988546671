import React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
const CustomRadio = (props) => {
    const handleChange = (param) => {
        if (props && props.onChange) {
            props.onChange(param);
        }
    };
    return (
        <FormControl>
            {!props.hideLabel ? <FormLabel id={props.id || props.name}>{props?.label}</FormLabel> : ''}
            <RadioGroup
                defaultValue={props.defaultValue}
                aria-labelledby="demo-controlled-radio-buttons-group"
                name={props.name}
                value={props.value}
                onChange={handleChange}
                row
            >
                {props.list.map((val, i) => {
                    return (
                        <FormControlLabel
                            value={val.value}
                            key={i + '_radiobutton_' + props.name}
                            control={<Radio />}
                            label={val.label}
                            {...val}
                        />
                    );
                })}
            </RadioGroup>
        </FormControl>
    );
};
export default CustomRadio;
