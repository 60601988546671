import * as ActionTypes from "../actions/ActionTypes";

const initialState = {
  loader: false,
  houseAffordDetails: {}
};

export const houseAffordablityCalReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.HOUSE_AFFORD_CAL_INIT:
      return { ...state, loader: true };
    case ActionTypes.HOUSE_AFFORD_CAL_SUCCESS:
      return { ...state, loader: false, houseAffordDetails: action?.data }; // Update this line
    case ActionTypes.HOUSE_AFFORD_CAL_ERROR:
      return { ...state, loader: false }; // Optionally handle error state
    default:
      return state;
  }
};
