import React from 'react';
import { Card, CardContent, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { QuesAnsBox } from 'assets/jss/RetirementStyle';
import { getCookie } from 'utils/Utils';
import CalSignupBlock from 'views/RetirementCalculator/CalSignupBlock';
const FixedIncomeDesc = () => {
    return (
        <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12} md={(getCookie('auth_token') && 12) || 8}>
                <Card>
                    <CardContent>
                        <QuesAnsBox>
                            <Typography variant="h2" id="description" sx={{ mb: 2, display: 'flex' }}>
                                About fixed income dashboard
                            </Typography>
                            <Typography variant="body1" className="dashboard-subheading" sx={{ mb: 1.5 }}>
                                Our fixed income dashboard shows interest rates on fixed-income (AKA debt) investments. This is important
                                information whether you’re an investor or a borrower. If you’re investing in a corporate bond or a bank CD
                                or a risk-free bond fund you can use our dashboard to quickly get a sense for a comparison. For example, you
                                should never consider investing in a bank CD without knowing government risk free interest rates.","This is
                                the type of information that Wall Street hopes you don’t know about.
                            </Typography>
                        </QuesAnsBox>
                    </CardContent>
                </Card>
            </Grid>
            {!getCookie('auth_token') && (
                <Grid item xs={12} md={4}>
                    <CalSignupBlock />
                </Grid>
            )}
        </Grid>
    );
};

export default FixedIncomeDesc;
