import { Card, CardContent, Grid } from '@mui/material';
import { CustomButton } from 'assets/jss/ButtonStyle';
import { CalCardActions } from 'assets/jss/RetirementStyle';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { submitCompoundCal } from 'redux/actions/GeneralCalActions';
import RenderField from 'utils/RenderField';
import { StudInputGrid, StudLabelGrid } from '../../assets/jss/CommonCalStyle';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';

const clearValue = (val) => (typeof val === 'string' ? parseFloat(val.replace(/,/g, '')) : null);

// const CompoundCalcForm = ({ classes, TipsCalcData, defaultoverrideData }) => {
const CompoundCalcForm = ({ classes, compoundCalData }) => {
    const dispatch = useDispatch();
    const {
        handleSubmit,
        control,
        formState: { isDirty, isSubmitting },
        setValue
    } = useForm({
        mode: 'onChange',
        defaultValues: {
            recurring_payment: '50', period_str: "M", nper: 10, interest_rate: 5, calc_type: 'recurring'
        }
    });
    //to submit the form data
    const onSubmit = (data) => {
        const objToSend = {
            interest_rate: parseFloat(data?.interest_rate),
            recurring_payment: Number(data?.recurring_payment.replace(/\,/g,'')),
            period_str: data?.period_str,
            nper: data?.nper,
            initial_payment: 0,
            calc_type: 'recurring'
        };
        dispatch(submitCompoundCal(objToSend));
    };

    // when any input field value will change it will call below method
    // const handleFormInputChange = (e) => {
    //     if (TipsCalcData && typeof TipsCalcData === 'object' && Object.keys(TipsCalcData).length > 0) {
    //         if (isDirty && Object.keys(TipsCalcData).length > 0) {
    //             dispatch(submitTipsCalSuccess({}));
    //         }
    //     }
    // };

    //   useEffect(() => {
    //     if (Object.keys(defaultoverrideData).length === 0) {
    //       dispatch(getDefaultOverride());
    //     }
    //     // reset output when route will change
    //     return () => {
    //       dispatch(submitTipsCalSuccess({}));
    //     }
    //   }, []);

    return (
        <Card className={`${classes?.widgetCalCard}`} elevation={2}>
            <CardContent>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container>
                        {/* <Grid item xs={12} sm={6}>
                            <RenderField
                                //   handleOnChange={handleFormInputChange}
                                name="initial_payment"
                                control={control}
                                label="Upfront Payment"
                                id="initial_payment"
                                type="number"
                                required
                                prefix={'$'}
                                thousandSeparator={true}
                                customPatternName="decimalRate"
                                placeholder="50,000"
                                setValue={setValue}
                            />
                        </Grid> */}
                        <Grid item xs={12} sm={12}>
                            <RenderField
                                //   handleOnChange={handleFormInputChange}
                                name="recurring_payment"
                                control={control}
                                label="Recurring/Subscription Expense"
                                id="recurring_payment"
                                type="number"
                                required
                                prefix={'$'}
                                thousandSeparator={true}
                                customPatternName="decimalRate"
                                placeholder="1000"
                                setValue={setValue}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Tooltip title="This is the tooltip text" arrow>
                            <RenderField
                                //   handleOnChange={handleFormInputChange}
                                name="period_str"
                                control={control}
                                label="Frequency Per Year"
                                id="period_str"
                                options={[
                                    { value: 'D', label: 'D' },
                                    { value: 'W', label: 'W' },
                                    { value: 'M', label: 'M' },
                                    { value: 'A', label: 'A' }
                                ]}
                                type="select"
                                required
                                // prefix={"$"}
                                //   thousandSeparator={true}
                                customPatternName="string"
                                placeholder="10"
                                setValue={setValue}
                                tooltipText={"Here you can set your frequency for your recurring payments (Daily, Weekly, Monthly or Annually)."}
                            />
                            </Tooltip>
                        </Grid>
                        <StudLabelGrid container direction="row" alignItems="center" spacing={2}>
                            <Grid item>
                                <Typography gutterBottom>
                                    Period (# of years)
                                </Typography>
                            </Grid>
                            <Grid item xs>
                                <RenderField
                                    name="nper"
                                    control={control}
                                    label="Time Period"
                                    id="nper"
                                    type="slider"
                                    max={50}
                                    min={0}
                                    step={1}
                                    // required
                                    valueLabelDisplay="on"
                                    prefix={'%'}
                                    placeholder="30"
                                />
                            </Grid>
                        </StudLabelGrid>
                        {/* <StudLabelGrid item xs={12}>
                            <Typography gutterBottom style={{ marginLeft: '18px' }}>
                                Annual Returns
                            </Typography>
                        </StudLabelGrid> */}
                        <StudLabelGrid container direction="row" alignItems="center" spacing={2}>
                            <Grid item>
                                <Typography gutterBottom>
                                    Annual Return
                                </Typography>
                            </Grid>
                            <Grid item xs>
                                <RenderField
                                    name="interest_rate"
                                    control={control}
                                    label="Annual Return"
                                    id="interest_rate"
                                    type="slider"
                                    max={30}
                                    min={0}
                                    step={0.1}
                                    // required
                                    valueLabelDisplay="on"
                                    prefix={'%'}
                                    placeholder="15"
                                />
                            </Grid>
                        </StudLabelGrid>
                    </Grid>
                    <CalCardActions className="contactus-cardaction">
                        <CustomButton
                            type="submit"
                            ps={'9px 30px'}
                            variant="contained"
                            // color="rose"
                            disabled={isSubmitting}
                        >
                            Calculate
                        </CustomButton>
                    </CalCardActions>
                </form>
            </CardContent>
        </Card>
    );
};

export default CompoundCalcForm;
