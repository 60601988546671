import React from 'react';
import { Card, CardContent, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { QuesAnsBox } from 'assets/jss/RetirementStyle';
import { getCookie } from 'utils/Utils';
import CalSignupBlock from 'views/RetirementCalculator/CalSignupBlock';
const MortgageRefinCalDesc = ({ classes, control, handleOnChange, setValue, ...rest }) => {
    return (
        <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12} md={(getCookie('auth_token') && 12) || 8}>
                <Card>
                    <CardContent>
                        <QuesAnsBox>
                            <Typography variant="h2" id="description" sx={{ mb: 2, display: 'flex' }}>
                                About Mortgage Refinance Calculator
                            </Typography>
                            <Typography variant="body1" className="dashboard-subheading" sx={{ mb: 1.5 }}>
                                This calculator determines whether it makes sense to refinance your mortgage. 
                                It looks at the current mortgage rate (TPE provides an initial suggestion but you can override it). 
                                We use a payback period of x years to determine whether it makes sense for you to refinance now based 
                                on the costs of refinancing and how long it will take you to earn back the fees.
                            </Typography>
                            <Typography variant="body1" className="dashboard-subheading" sx={{ mb: 1.5 }}>
                                We use 3 years as the default payback period, but you can override this based on your time horizon 
                                and how long you expect to be in your home. 
                            </Typography>
                            <Typography variant="body1" className="dashboard-subheading" sx={{ mb: 1.5 }}>
                                The inputs you need to provide include the mortgage amount term (in years), 
                                your current interest rate (borrower rate), market rate (for which we will provide a default suggestion), 
                                points and fees for refinancing and term to break even threshold (for which we use 3 years by default 
                                but the user can override this). 
                            </Typography>
                        </QuesAnsBox>
                    </CardContent>
                </Card>
            </Grid>
            {!getCookie('auth_token') && (
                <Grid item xs={12} md={4}>
                    <CalSignupBlock />
                </Grid>
            )}
        </Grid>
    );
};

export default MortgageRefinCalDesc;
