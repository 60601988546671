import { Card, CardContent, Dialog, DialogContent, Divider, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import NoDataFound from 'components/CommonComponent/NoDataFound';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { submitEconomicTickerSuccess } from 'redux/actions/SettingActions';
import { getEconomicTicker, handleDialogVisibility, submitEconomicTicker } from 'redux/actions/SettingActions';
import RenderField from 'utils/RenderField';
import CustomDialogTitle from 'components/DialogTitle';
import { CustomButton } from 'assets/jss/ButtonStyle';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Padding } from '@mui/icons-material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import axios from "axios";
import Globals from "../../../Globals";
import {updateEconomicDashboardData} from "../../../redux/actions/EconomicDashboardActions";
import {CustomTableRow} from "../../../assets/jss/TableStyle";


const EconomicTicker = (props) => {
    const { classes, setting } = props;
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const [stype, setStype] = React.useState('');
    const [category, setCategory] = React.useState('');
    const [economyTickerData, setEconomyTickerData] = React.useState([]);
    const {
        handleSubmit,
        control,
        reset,
        formState: { isSubmitting },
        setValue
    } = useForm({
        mode: 'onChange'
    });

    const handleDragEnd = async (result) => {
        if (!result.destination) {
            return; // Dragged outside the droppable area
        }
        const updatedData = Array.from(economyTickerData);
        const [movedItem] = updatedData.splice(result.source.index, 1);
        updatedData.splice(result.destination.index, 0, movedItem);
        const updatedResult = updatedData.map((item, index) => ({ sortable_state: index, id: item.id }));
        dispatch(updateEconomicDashboardData(updatedResult))
        setEconomyTickerData(setting.ecoDashboardOrder);
    };

    useEffect(() => {
        if (setting.ecoDashboardOrder.length > 0) {
            setEconomyTickerData(setting.ecoDashboardOrder);
        }
        else {
            setEconomyTickerData(setting.economyTickerData);
        }
    }, [setting.ecoDashboardOrder, setting.economyTickerData]);

    useEffect(() => {
        /// to fetch the economic ticker values on page load
        dispatch(getEconomicTicker());
    }, []);

    const handleTypeChange = (event) => {
        setStype(event.target.value);
      };

    const handleCategoryChange = (event) => {
        setCategory(event.target.value);
      };

    // submit the form
    const onSubmit = (data) => {
        const type = data.id ? 'put' : 'post';
        const objToSend = {
            ticker: data?.ticker,
            name: data?.name,
            type: stype,
            id: data?.id || undefined,
            units: data?.units,
            category: category
        };
        dispatch(submitEconomicTicker(objToSend, type));
    };
    useEffect(() => {
        // once traffic light popup will submit to close that popup when api return success
        if (setting.economyTickerFormDialog) {
            handleClose();
            dispatch(submitEconomicTickerSuccess(false));
        }
    }, [setting.economyTickerFormDialog]);

    const handleClickOpen = () => setOpen(true);
    // delete selected traffic module
    const deleteSelectedData = (id) => dispatch(submitEconomicTicker({ id: id }, 'delete'));

    // enable to edit traffic module
    const handleEditData = (val) => {
        setStype(val?.type);
        setCategory(val?.category);
        setOpen(true);
        // to load saved data in form
        for (const key in val) {
            if (Object.hasOwnProperty.call(val, key)) {
                let element = val[key];
                setValue(key, element);
            }
        }
    };

    //close popup
    const handleClose = () => {
        reset({ ticker: '', name: '', units: '' });
        setOpen(false);
        setStype("");
        setCategory("");
    };

    return (
        <>
            <Card elevation={2}>
                <CardContent>
                    <Tooltip title="Add" arrow>
                        <CustomButton
                            variant="text"
                            className="read-more"
                            startIcon={<AddIcon />}
                            aria-label="add"
                            onClick={() => {
                                handleClickOpen();
                                // handleCreateNewItem(); // Call handleCreateNewItem after opening the modal
                            }}
                        >
                            Add New Data
                        </CustomButton>
                    </Tooltip>
                    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                        <CustomDialogTitle id="form-dialog-title" onClose={handleClose}>
                            Add New Ticker
                        </CustomDialogTitle>
                        <Divider />
                        <DialogContent>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <RenderField
                                            name="ticker"
                                            control={control}
                                            label={'Fred Symbol'}
                                            id={'fredSymbol'}
                                            type={'text'}
                                            required={true}
                                            placeholder={'Enter Fred Symbol'}
                                            setValue={setValue}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <RenderField
                                            name="name"
                                            control={control}
                                            label={'Measure'}
                                            id={'measure'}
                                            type={'text'}
                                            required={true}
                                            placeholder={'Enter Measure'}
                                            setValue={setValue}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <RenderField
                                            name="units"
                                            control={control}
                                            label={'Units'}
                                            id={'units'}
                                            type={'text'}
                                            required={false}
                                            placeholder={'Enter Units or leave it blank to default value'}
                                            setValue={setValue}
                                            // defaultValue={"this is a test"}
                                        />
                                    </Grid>
                                    <Grid item xs={12} style={{margin: 8}}>
                                    <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Type</InputLabel>
                                        <Select
                                        // labelId="demo-simple-select-label"
                                        id={"type"}
                                        value={stype}
                                        label={'Type'}
                                        control={control}
                                        onChange={handleTypeChange}
                                        setValue={setValue}
                                        >
                                        <MenuItem value={'Actual'}>Actual</MenuItem>
                                        <MenuItem value={'Index Absolute'}>Index Absolute</MenuItem>
                                        <MenuItem value={'Index Percentage'}>Index Percentage</MenuItem>
                                        </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} style={{margin: 8}}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Category</InputLabel>
                                            <Select
                                                // labelId="demo-simple-select-label"
                                                id={"category"}
                                                value={category}
                                                label={'Category'}
                                                control={control}
                                                onChange={handleCategoryChange}
                                                setValue={setValue}
                                                // defaultValue={}
                                            >
                                                <MenuItem value={'Interest Rates'}>Interest Rates</MenuItem>
                                                <MenuItem value={'Labor Markets'}>Labor Markets</MenuItem>
                                                <MenuItem value={'Economic Growth'}>Economic Growth</MenuItem>
                                                <MenuItem value={'Housing'}>Housing</MenuItem>
                                                <MenuItem value={'Debt and Credit'}>Debt and Credit</MenuItem>
                                                <MenuItem value={'Inflation'}>Inflation</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid container direction="row" style={{ width: '100%', textAlign: 'center' }}>
                                    <Grid item xs={12}>
                                        <CustomButton type="submit" variant="contained" disabled={isSubmitting}>
                                            Submit
                                        </CustomButton>
                                    </Grid>
                                </Grid>
                            </form>
                        </DialogContent>
                    </Dialog>

                    {economyTickerData.length > 0 ? (
                        <Card className={`${classes.trafficlightCard} notiCard`}>
                            <CardContent className="tlcardcontent">
                                <Grid container spacing={2} className="tlContainer">
                                    <Grid item xs={15}>
                                        <Grid container>
                                            <Grid item xs={12} sm={1.5}>
                                                <Typography variant="h6">Actions</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={2} textAlign="center">
                                                <Typography variant="h6">Fred Symbol</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={2.5} textAlign="center">
                                                <Typography variant="h6">Measure</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={2} textAlign="center">
                                                <Typography variant="h6">Units</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={2} textAlign="center">
                                                <Typography variant="h6">Type</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={2} textAlign="center">
                                                <Typography variant="h6">Category</Typography>
                                            </Grid>
                                        </Grid>
                                        <Divider />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <DragDropContext onDragEnd={handleDragEnd}>
                                            <Droppable droppableId="droppable" direction="vertical" type="row">
                                                {(provided,) => (
                                                    <div ref={provided.innerRef} {...provided.droppableProps}>
                                                        {economyTickerData.map((row, index) => (
                                                            <Draggable key={row.id} draggableId={row.id.toString()} index={index}>
                                                                {(provided) => (
                                                                    <div
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                    >
                                                                        <Grid container style={{marginBottom: "10px" }} alignItems="center">
                                                                            <Grid item xs={12} sm={1.5}>
                                                                                <Tooltip title="Delete">
                                                                                    <IconButton
                                                                                        aria-label="delete"
                                                                                        onClick={() => deleteSelectedData(row.id)}
                                                                                    >
                                                                                        <DeleteIcon />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                                <Tooltip title="Edit">
                                                                                    <IconButton aria-label="edit" onClick={(e) => handleEditData(row)}>
                                                                                        <CreateIcon />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                            </Grid>
                                                                            <Grid item xs={12} sm={2}>
                                                                                <Typography component="p" textAlign={'center'}>
                                                                                    {row.ticker}
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid item xs={12} sm={2.5}>
                                                                                <Typography component="p" textAlign={'center'}>
                                                                                    {row.name}
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid item xs={12} sm={2}>
                                                                                <Typography component="p" textAlign={'center'}>
                                                                                    {row.units}
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid item xs={12} sm={2}>
                                                                                <Typography component="p" textAlign={'center'}>
                                                                                    {row.type}
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid item xs={12} sm={2}>
                                                                                <Typography component="p" textAlign={'center'}>
                                                                                    {row.category}
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </div>
                                                                )}
                                                            </Draggable>
                                                        ))}
                                                        {provided.placeholder}
                                                    </div>
                                                )}
                                            </Droppable>
                                        </DragDropContext>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                        ) : (
                        <NoDataFound msg="Please add new module to display here." />
                    )}
                </CardContent>
            </Card>
        </>
    );
};

export default EconomicTicker;
