import React from "react";
import { ResponsiveTreeMap } from "@nivo/treemap";
import Globals from "Globals.js";

const TreeChart = ({ chartData }) => {
  return (
      <ResponsiveTreeMap
        data={chartData}
        // outerPadding={4}
        colors={Globals.Colors}
        identity='name'
        value='loc'
        // tile='sliceDice'
        valueFormat='>-,.02s'
        // parentLabelPosition={'left'}
        nodeOpacity={0.3}
        margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
        labelSkipSize={15}
        // enableParentLabel={false}
        labelTextColor='black'
        label={(e) => `${e.id} ( ${e.formattedValue} )`}
        // labelTextColor={{
        //   from: "color",
        //   modifiers: [["darker", 10]],
        // }}
        // // parentLabelPosition='left'
        // parentLabelTextColor={{
        //   from: "color",
        //   modifiers: [["darker", 2]],
        // }}
        borderColor='#ffffff'
      />
  );
};

export default TreeChart;
