import React from 'react';
import { Card, CardContent, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { QuesAnsBox } from 'assets/jss/RetirementStyle';
import { getCookie } from 'utils/Utils';
import CalSignupBlock from 'views/RetirementCalculator/CalSignupBlock';
const MutualFundDesc = () => {
    return (
        <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12} md={(getCookie('auth_token') && 12) || 8}>
                <Card>
                    <CardContent>
                        <QuesAnsBox>
                            <Typography variant="h2" id="description" sx={{ mb: 2, display: 'flex' }}>
                                About Mutual Fund and ETF Tool
                            </Typography>
                            <Typography variant="body1" className="dashboard-subheading" sx={{ mb: 1.5 }}>
                                Mutual Funds and ETFs are similar investments that allow you to invest in a basket of stocks or bonds. These
                                can be further classified into categories but for now we will just describe passive and active investments.
                                Passive funds (also known as index funds because they track a known market index like the Dow or S&P 500)
                                generally are much lower cost, have much lower trading or turnover and are generally pretty tax efficient.
                                Contrary to passive funds, active funds charge a higher fee in the believe that they can outperform a
                                passive index. There is much research that shows that over the long term, passive funds win in the end.
                            </Typography>
                            <Typography variant="body1" className="dashboard-subheading" sx={{ mb: 1.5 }}>
                                Our Mutual Fund/ETF tool allows you to easily add, track and compare from thousands of funds.
                            </Typography>
                            <Typography variant="body1" className="dashboard-subheading" sx={{ mb: 1.5 }}>
                                To upload a fund simply click the + button and type in the ticker also known as a symbol (you can try SPY
                                which is one of the largest index funds tied to the S&P 500). We will then load the data into the mutual
                                fund table. If you want to focus on a particular fund you can click on the eye icon on the left and that
                                will bring up the fund information in an easily viewable vertical form.
                            </Typography>
                            <Typography variant="body1" className="dashboard-subheading" sx={{ mb: 1.5 }}>
                                If you want to compare funds side-by-side simply click on the compare tab. Using the search function you can
                                filter on fund types (eg “Gold”, “500”, “Inflation”, “Technology”, will help you find funds related to those
                                sectors). Note that you must first add the fund(s) using the “+Add New Data” button in the main section.
                            </Typography>
                            <Typography variant="body1" className="dashboard-subheading" sx={{ mb: 1.5 }}>
                                Our fund tool allows you to look at returns (v benchmark), country, industry and company concentrations. In
                                addition, we allow you to look at the duration and credit ratings for fixed income funds. The duration is a
                                measure of risk that is largely a function of the maturity of the underlying investments. A credit rating is
                                a measure of the risk of default of a company. A AAA rating is the best and indicates a near zero risk of
                                default. At the other end Single B, BB and CCC are known as speculative (sometimes call junk) investments
                                with a higher risk of default.
                            </Typography>
                        </QuesAnsBox>
                    </CardContent>
                </Card>
            </Grid>
            {!getCookie('auth_token') && (
                <Grid item xs={12} md={4}>
                    <CalSignupBlock />
                </Grid>
            )}
        </Grid>
    );
};

export default MutualFundDesc;
