import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @mui/material components
import { makeStyles } from '@mui/styles';
// core components
import styles from "assets/jss/material-kit-react/components/typographyStyle.js";

const useStyles = makeStyles(styles);

export default function Danger(props) {
  const classes = useStyles();
  const { children } = props;
  return (
    <div
      className={
        classes.defaultFontStyle + " " + classes.dangerText + " "+[props.className]
      }>
      {children}
    </div>
  );
}

Danger.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};
