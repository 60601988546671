import { Box, Card, CardContent, Dialog, DialogContent, Divider, Grid, IconButton, Paper, Tooltip, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EnhancedTable from 'components/CommonComponent/CommonTable/EnhancedTable';
import NoDataFound from 'components/CommonComponent/NoDataFound';
import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { fetchWidgetCalList, fetchWidgetUserCalList, submitWidgetDialog, submitWidgetUserCal } from 'redux/actions/SettingActions';
import RenderField from 'utils/RenderField';
import { CustomButton } from 'assets/jss/ButtonStyle';
import { CalCardActions } from 'assets/jss/RetirementStyle';
import CustomDialogTitle from 'components/DialogTitle';
const WidgetSetting = (props) => {
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const {
        handleSubmit,
        control,
        reset,
        formState: { isSubmitting },
        setValue
    } = useForm({ mode: 'onChange' });
    const setting = useSelector((state) => state.setting);

    // get widget cal user selected list
    useEffect(() => {
        dispatch(fetchWidgetUserCalList());
    }, []);

    // submit the form
    const onSubmit = (data) => {
        let type = 'post';
        const count = setting.userWidCalList.length;
        if (count < 4) {
            let objToPass = {
                widgetcal_id: data.cal_list
            };
            dispatch(submitWidgetUserCal(objToPass, type));
        } else {
            toast.error("You've reached the adding widget calculator limit. Please delete calculator to save new!", { autoClose: false });
        }
    };
    const columns = useMemo(() => {
        const result = [
            {
                Header: 'Action',
                width: 120,
                Cell: ({ row }) => (
                    <div className={'actionWrapper'}>
                        <Tooltip title="Delete">
                            <IconButton aria-label="delete" onClick={() => deleteSelectedData(row.original.id)}>
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                    </div>
                )
            },
            {
                Header: 'Name',
                accessor: 'name',
                disableFilters: true,
                Cell: ({ value }) => value || '-'
            }
        ];
        return result;
    });
    //open add new widget calculator popup
    const handleClickOpen = () => {
        dispatch(fetchWidgetCalList());
        setOpen(true);
    };

    // delete selected widget cal
    const deleteSelectedData = (id) => dispatch(submitWidgetUserCal({ id: id }, 'delete'));

    //close popup
    const handleClose = () => {
        reset({ cal_list: renderCalOptions() });
        setOpen(false);
    };
    const renderCalOptions = () =>
        setting.widgetCalList.map(
            (val, i) =>
                val.status && {
                    value: val.id,
                    label: val.name,
                    disabled: setting.userWidCalList.some((innerval) => innerval.widgetcal_id === val.id)
                }
        );

    useEffect(() => {
        // to close opened popup after submittion of api
        if (setting.widgetDialogFlag) {
            handleClose();
            dispatch(submitWidgetDialog(false));
        }
    }, [setting.widgetDialogFlag]);
    return (
        <Card elevation={2}>
            <CardContent>
                <Typography variant={'h6'}>Manage and customize your floating widgets.</Typography>
                <Divider sx={{ marginTop: '8px' }} />
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                            <Tooltip title="Add" arrow>
                                <CustomButton variant="text" className='read-more' startIcon={<AddIcon />} aria-label="add" onClick={handleClickOpen}>
                                    Add New Data
                                </CustomButton>
                            </Tooltip>
                        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                            <CustomDialogTitle id="form-dialog-title" onClose={handleClose}>
                                Add New Widget Calculator
                            </CustomDialogTitle>
                            <Divider />
                            <DialogContent>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <RenderField
                                                name="cal_list"
                                                control={control}
                                                label={'Select a calculator'}
                                                id={'wid_cal_list'}
                                                type={'select'}
                                                style={{ minWidth: 300 }}
                                                options={renderCalOptions()}
                                                required={true}
                                                placeholder={'Select...'}
                                                setValue={setValue}
                                            />
                                        </Grid>
                                    </Grid>
                                    <CalCardActions className="contactus-cardaction">
                                        <CustomButton type="submit" ps={'9px 30px'} variant="contained" disabled={isSubmitting}>
                                            Submit
                                        </CustomButton>
                                    </CalCardActions>
                                </form>
                            </DialogContent>
                        </Dialog>
                        {setting.userWidCalList.length > 0 ? (
                            <Paper elevation={0}>
                                <EnhancedTable
                                    enableSearch={false}
                                    // handleLoadData={getInvestDashboardData}
                                    columns={columns}
                                    data={setting.userWidCalList || []}
                                    enableSort={true}
                                />
                            </Paper>
                        ) : (
                            <NoDataFound msg="Result will be shown after adding widget calculator." />
                        )}{' '}
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};
export default WidgetSetting;
