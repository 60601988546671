import React from "react";
import { ResponsiveLine } from "@nivo/line";
import BarChart from "components/Charts/BarChart";
import { numberWithCommas } from "utils/Utils";
import { abbreviate_number } from "utils/Utils";
import { BoxBarChartTooltip } from "assets/jss/ChartStyle";
import { TipsChartBox } from "assets/jss/ChartStyle";
import { BarChartInnerBox } from "assets/jss/ChartStyle";
export const BiaxialInflationBondChart = ({ bardata, linedata }) => {
  /**
   * to display tooltip on hover of the
   * @param {*} param
   * @returns
   */
  const CustomTooltip = (tProps) => {
    if (tProps) {
      const x = tProps.point.data.x;
      const color = tProps.point.color;
      const currValueleft = numberWithCommas(tProps.point.data.y.toFixed(2));
      const currValueRight = numberWithCommas(
        bardata.data.filter((val) => val.label === x)[0]["Interest"].toFixed(2)
      );
      return (
          <BoxBarChartTooltip >
              <BarChartInnerBox>
                  <span>
                      <p className={'tooltipLabel'}>
                          {'Time'}:<strong>{x}</strong>
                      </p>
                  </span>
              </BarChartInnerBox>
              <BarChartInnerBox>
                  <span className={"colorBox"} style={{ background: '#10bed394' }}></span>
                  <span>
                      <p className={'tooltipLabel'}>
                          {'Interest'} : <strong>{currValueRight}</strong>
                      </p>
                  </span>
              </BarChartInnerBox>
              <BarChartInnerBox>
                  <span className={"colorBox"} style={{ background: color }}></span>
                  <span>
                      <p className={'tooltipLabel'}>
                          {tProps.point.serieId} : <strong>{currValueleft}</strong>
                      </p>
                  </span>
              </BarChartInnerBox>
          </BoxBarChartTooltip>
      );
    }
    return null;
  };
  return (
      <TipsChartBox >
          <div className={'wrapper'}>
              <div className={'graphContainer'}>
                  <BarChart
                      biaxialchart={true}
                      legendName="Interest"
                      halfGraph={true}
                      // bottomLegendName='Time'
                      chartData={bardata}
                      colorProps={['#10bed394']}
                      marginProp={{ top: 50, right: 50, bottom: 50, left: 50 }}
                  />
              </div>
              {/* <div className={'secondGraph'}>
                  <ResponsiveLine
                      data={[linedata]}
                      useMesh={true}
                      colors={['#e91e63']}
                      // colors={{ scheme: "pastel2" }}
                      margin={{ top: 50, right: 50, bottom: 50, left: 50 }}
                      axisRight={{
                          format: (tick) => numberWithCommas(abbreviate_number(tick)),
                          legend: 'Principal',
                          legendPosition: 'middle',
                          legendOffset: 40
                      }}
                      axisLeft={null}
                      enableGridX={false}
                      enableGridY={false}
                      axisBottom={null}
                      tooltip={(val) => CustomTooltip(val)}
                      legends={[
                          {
                              anchor: 'bottom',
                              direction: 'row',
                              itemWidth: 120,
                              translateX: 70,
                              translateY: 48,
                              itemHeight: 20,
                              itemsSpacing: 0,
                              itemOpacity: 0.8,
                              symbolSize: 10,
                              justify: false,
                              dataFrom: 'keys',
                              symbolShape: 'square'
                          }
                      ]}
                  />
              </div> */}
          </div>
      </TipsChartBox>
  );
};
