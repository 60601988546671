import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { numberWithCommas } from 'utils/Utils';
import NoDataFound from 'components/CommonComponent/NoDataFound';
import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import { OutputBox } from 'assets/jss/GeneralStyle';

export default function LifeInsOutput(props) {
    const generalCal = useSelector((state) => state.generalCal);

    return (
        <Card elevation={2} sx={{ mb: 3.5 }}>
            <CardContent>
                <Typography variant="h6" sx={{ mb: 2, display: 'flex' }} gutterBottom>
                    Summary Output
                </Typography>
                <Box id="outputWrapper" className="widget-full-width">
                        {generalCal.lifeInsData?.calData?.length > 0 ? 
                    <Grid container spacing={2}>
                            {generalCal.lifeInsData?.calData.map((val, i) => {
                                return (
                                    <Fragment key={i + 'lifeinsoutput'}>
                                        <Grid item xs={12} md={4}>
                                            <OutputBox>
                                                <Typography variant="body1" className="outputLabel" component="p">
                                                    {val.label}
                                                </Typography>
                                                <Typography variant="h6">{numberWithCommas(val.value.toFixed(2))}</Typography>
                                            </OutputBox>
                                        </Grid>
                                    </Fragment>
                                );
                            })}
                    </Grid> : (
                            <NoDataFound />
                        )}
                </Box>
            </CardContent>
        </Card>
    );
}
