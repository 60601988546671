import * as ActionTypes from "./ActionTypes";
import { apiCall, handleError } from "utils/Utils";
import Globals from "Globals.js";
import { toast } from "react-toastify";
// handle loader when api started to call

export const houseAffordCalInit = () => {
  return {
    type: ActionTypes.HOUSE_AFFORD_CAL_INIT
  };
};
//handle success of api
export const houseAffordCalSuccess = val => {
  return {
    type: ActionTypes.HOUSE_AFFORD_CAL_SUCCESS,
    data: val,
  };
};
// handle api error
export const houseAffordCalError = () => {
  return {
    type: ActionTypes.HOUSE_AFFORD_CAL_ERROR
  };
};
/**
 * @author Leena
 * @param {*} data
 * @use to get house affordablity cal
 * @returns
 */
export const SumbitHouseAffordablityCal = data => async dispatch => {
  try {
    dispatch(houseAffordCalInit());
    const apiResponse = await apiCall(
      `${Globals.API_ROOT_URL}houseaffordabilitycal`,
      "post",
      data
    );
    if (apiResponse !== undefined && apiResponse.status === 200) {
      dispatch(houseAffordCalSuccess(apiResponse?.data));
    } else {
      handleError(apiResponse,dispatch);
      dispatch(houseAffordCalError());
    }
  } catch (error) {
    toast.error(Globals.errorMsg);
    dispatch(houseAffordCalError());
    throw error;
  }
};
