import { Card, CardContent, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { QuesAnsBox } from 'assets/jss/RetirementStyle';
import { getCookie } from 'utils/Utils';
import CalSignupBlock from 'views/RetirementCalculator/CalSignupBlock';
import React from 'react';
const OptionPricingDesc = ({ classes, control, handleOnChange, setValue, ...rest }) => {
    return (
        <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12} md={(getCookie('auth_token') && 12) || 8}>
                <Card>
                    <CardContent>
                        <QuesAnsBox>
                            <Typography variant="h2" id="description" sx={{ mb: 2, display: 'flex' }}>
                                About option pricing calculator
                            </Typography>
                            <Typography variant="body1" className="dashboard-subheading" sx={{ mb: 1.5 }}>
                                This calculator uses the Nobel Prize winning Black Scholes calculator to value put and call options. Options
                                give you the right to buy or sell something at a specific price during a specific time period. Call options
                                give you an option to buy and put options give you an option to sell. You buy calls when you expect prices
                                to increase and puts and you expect prices to decline.
                            </Typography>
                        </QuesAnsBox>
                    </CardContent>
                </Card>
            </Grid>
            {!getCookie('auth_token') && (
                <Grid item xs={12} md={4}>
                    <CalSignupBlock />
                </Grid>
            )}
        </Grid>
    );
};

export default OptionPricingDesc;
