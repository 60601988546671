import { Box, Tab, Tabs, Typography, useTheme } from '@mui/material';
import { LargeContainer } from 'assets/jss/GeneralStyle';
import { MainBox } from 'assets/jss/GeneralStyle';
import { CustomListItemText } from 'assets/jss/HeaderStyle';
import { CustomListItem } from 'assets/jss/HeaderStyle';
import { Link as RouterLink } from 'react-router-dom';
import CommonCalculator from 'components/CommonComponent/CommonCalculator.js';
import Loader from 'components/CommonComponent/Loader.js';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SwipeableViews from 'react-swipeable-views';
import { fetchDebtCal } from 'redux/actions/DebtCalActions';
import { a11yProps } from 'utils/Utils';
import DebtCalDesc from './DebtCalDesc';
import { DebtTable } from './DebtTable';
import DebtCalTopBlock from './DebtCalTopBlock';
import { TopBlockBox } from 'assets/jss/PortfolioStyle';
import { RefreshBox } from 'assets/jss/PortfolioStyle';
import { AddUserOptionBox } from 'assets/jss/PortfolioStyle';
import AddNewDebt from './AddNewDebt';
import { WidgetBox } from 'assets/jss/GeneralStyle';
import AccessControl from 'views/Authorization/AccessControl';
import PermissionDenied from 'views/Authorization/PermissionDenied';
import BiaxialDebtChart from './BiaxialDebtChart';
import DoRegistration from 'views/DoRegistration';
import Tour from 'reactour';
import './index.css';
import Meta from 'components/meta';

const tabs = [{ label: 'Summary' }];

export default function DebtCalIndex(props) {
    const dispatch = useDispatch();
    const theme = useTheme();
    const [value, setValue] = useState(0);
    const [enableChart, setEnableChart] = useState({});
    const debt = useSelector((state) => state.debt);
    const setting = useSelector((state) => state.setting);
    const searchcontainer = useRef(null);
    const [isTourOpen, setIsTourOpen] = useState(false);
    const [currentStep, setCurrentStep] = useState(0);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [scrollPosition, setScrollPosition] = useState(0);
    const [activeTab, setActiveTab] = useState(0);

    const typographyRef = useRef(null);
    useEffect(() => {
        // Set the ID after the component mounts
        if (typographyRef.current) {
            typographyRef.current.id = 'debt';
        }
    }, []);

    const handleButtons = () => {
        return (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {currentStep !== 0 && (
                    <button
                        style={{
                            padding: '10px',
                            border: '1px solid black',
                            backgroundColor: 'transparent',
                            borderRadius: '10px',
                            fontSize: '18px',
                            cursor: 'pointer'
                        }}
                        onClick={() => setCurrentStep(currentStep - 1)}
                    >
                        Back
                    </button>
                )}
                {currentStep !== steps.length - 1 ? (
                    <button
                        style={{
                            padding: '10px',
                            backgroundColor: 'black',
                            color: 'white',
                            borderRadius: '10px',
                            fontSize: '18px',
                            border: 'none',
                            cursor: 'pointer'
                        }}
                        onClick={() => setCurrentStep(currentStep + 1)}
                    >
                        Continue
                    </button>
                ) : (
                    <button
                        style={{
                            padding: '10px',
                            backgroundColor: 'black',
                            color: 'white',
                            borderRadius: '10px',
                            fontSize: '18px',
                            border: 'none',
                            cursor: 'pointer'
                        }}
                        onClick={() => closeTour()}
                    >
                        Finish
                    </button>
                )}
            </div>
        );
    };

    const steps = [
        {
            selector: '#Summa',
            content: () => (
                <>
                    <h4>Summary</h4>
                    <p>
                        Welcome to Summary! here you can see the data cards after adding the debt data including Total Debt, Total Annual
                        Debt Payments, Total Lifetime Interest and Debt Ratio.
                    </p>
                    {handleButtons()}
                </>
            )
        },
        {
            selector: '#Lifet',
            content: () => (
                <>
                    <h4>Lifetime Interest</h4>
                    <p>
                        Now, let me introduce you to Lifetime Interest! here you can see the Lifetime Interest chart after adding the debt
                        data.
                    </p>
                    {handleButtons()}
                </>
            )
        },
        {
            selector: windowWidth < 605 ? '' : '#Cumul',
            content: () => (
                <>
                    <h4>Cumulative Interest</h4>
                    <p>
                        Now, let me introduce you to Cumulative Interest! here you can see the Cumulative Interest chart after adding the
                        debt data.
                    </p>
                    {handleButtons()}
                </>
            )
        },
        {
            selector: windowWidth < 600 ? '' : '#summary',
            content: () => (
                <>
                    <h4>Summary Table</h4>
                    <p>
                        Now, let me introduce you to Summary Table! here you can see the debt table after adding the debt data. you can also
                        delete, edit the debt data and see the chart for particular debt item by clicking on chart icon in table row.
                    </p>
                    {handleButtons()}
                </>
            )
        },
        {
            selector: windowWidth < 600 ? '' : '#tab-6',
            content: () => (
                <>
                    <h4>Add New Data</h4>
                    <p>Now, let me introduce you to Add New Data! here by clicking on this button, you can add new debt data.</p>
                    {handleButtons()}
                </>
            )
        },
        {
            selector: windowWidth < 600 ? '' : '#tab-7',
            content: () => (
                <>
                    <h4>Add Annual Income</h4>
                    <p>
                        Now, let me introduce you to Add Annual Income icon! here by clicking on this icon, you can add and update your
                        annual income, after adding income you can click on Add New Data link.
                    </p>
                    {handleButtons()}
                </>
            )
        },
        {
            selector: windowWidth < 600 ? '' : '#tab-8',
            content: () => (
                <>
                    <h4>Add Icon</h4>
                    <p>Now, let me introduce you to Add icon! here by clicking on this icon, you can add debt data.</p>
                    {handleButtons()}
                </>
            )
        }
    ];

    const closeTour = () => {
        setIsTourOpen(false);
        document.body.style.overflow = 'auto';
    };

    const handleTour = () => {
        setIsTourOpen(true);
        document.body.style.overflow = 'hidden';
    };

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [window.innerWidth]);

    // fetch debt detail on page load
    useEffect(() => {
        dispatch(fetchDebtCal());
    }, []);
    const handleChangeIndex = (index) => {
        setActiveTab(index);
        setValue(index);
    };

    /**
     * handle the tab switch view
     * @param {*} event
     * @param {*} newValue
     */
    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };
    // const [debtInput, setDebtInput] = useState([]);

    // const [filteredTableData, setFilteredData] = useState([]);
    // useEffect(() => {
    //     setDebtInput(filteredTableData.input);
    // }, [filteredTableData]);

    useEffect(() => {
        const scrollContainer = document.querySelector('.scroll');
        const lastTab = document.querySelector('.scroll > div:last-child');
        if (!scrollContainer || !lastTab) return;

        const containerRight = scrollContainer.getBoundingClientRect().right;
        const lastTabRight = lastTab.getBoundingClientRect().right;

        const isLastTabVisible = lastTabRight <= containerRight || lastTabRight <= window.innerWidth;
        if (isLastTabVisible) {
            setScrollPosition(3);
        }
    }, [tabs]);

    const handleScroll = (event) => {
        const { scrollLeft, scrollWidth, clientWidth } = event.target;
        const isEnd = scrollLeft + clientWidth >= scrollWidth - 1;
        if (scrollLeft === 0) {
            setScrollPosition(0);
        } else if (isEnd) {
            setScrollPosition(2);
        } else {
            setScrollPosition(1);
        }
    };

    const handleTabNavigation = (direction) => {
        const scrollContainer = document.querySelector('.scroll');
        if (!scrollContainer) return;

        const { scrollLeft, clientWidth } = scrollContainer;

        if (direction === 'backward') {
            scrollContainer.scrollTo({
                left: Math.max(0, scrollLeft - clientWidth),
                behavior: 'smooth'
            });
        } else if (direction === 'forward') {
            scrollContainer.scrollTo({
                left: scrollLeft + clientWidth,
                behavior: 'smooth'
            });
        }
    };

    const handleTabClick = (index) => {
        setActiveTab(index);
        setValue(index);
    };

    const render = () => {
        return (
            <>
                <div>
                    <Tour
                        steps={steps}
                        isOpen={isTourOpen}
                        onRequestClose={closeTour}
                        goToStep={currentStep}
                        showButtons={false}
                        showNavigation={true}
                        showNumber={false}
                        closeWithMask={false}
                        closeWithEsc={false}
                        rounded={10}
                        maskSpace={10}
                    />
                </div>
                <AccessControl allowedPermissions={['Debt_calc']} renderNoAccess={(plan) => <PermissionDenied planname={plan} />}>
                    {debt.loader || setting.loader ? <Loader /> : ''}
                    <DebtCalTopBlock />
                    {Object.keys(enableChart).length > 0 ? (
                        <BiaxialDebtChart backButtonClicked={() => setEnableChart({})} debt={debt} enableChart={enableChart} />
                    ) : (
                        <TopBlockBox>
                            <div style={{ display: 'flex', alignItems: 'center', background: 'rgb(231, 245, 255)' }}>
                                <div
                                    style={{
                                        cursor: 'pointer',
                                        marginLeft: '20px',
                                        marginRight: '20px',
                                        visibility: scrollPosition !== 0 && scrollPosition !== 3 ? 'visible' : 'hidden'
                                    }}
                                    onClick={() => handleTabNavigation('backward')}
                                >
                                    {' '}
                                    &lt;
                                </div>
                                <div
                                    style={{
                                        overflowX: 'auto',
                                        display: 'flex'
                                    }}
                                    className="scroll"
                                    onScroll={handleScroll}
                                >
                                    {tabs.map((tab, index) => (
                                        <div
                                            id="summary"
                                            key={index}
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                flex: '0 0 auto',
                                                padding: '10px 20px',
                                                cursor: 'pointer',
                                                borderBottom: '3px solid transparent',
                                                fontSize: '14px',
                                                fontWeight: 'bold',
                                                borderBottomColor: activeTab === index ? 'rgb(39, 169, 224)' : 'transparent',
                                                color: activeTab === index ? 'rgb(39, 169, 224)' : 'black'
                                            }}
                                            onClick={() => handleTabClick(index)}
                                        >
                                            {tab.label}
                                        </div>
                                    ))}
                                    <AddUserOptionBox style={{ display: 'flex', flex: 'none' }}>
                                        <AddNewDebt />
                                    </AddUserOptionBox>

                                    <RefreshBox>
                                        <Box ref={searchcontainer} />
                                    </RefreshBox>
                                </div>
                                <div
                                    style={{
                                        cursor: 'pointer',
                                        marginLeft: '20px',
                                        marginRight: '20px',
                                        visibility: scrollPosition !== 2 && scrollPosition !== 3 ? 'visible' : 'hidden'
                                    }}
                                    onClick={() => handleTabNavigation('forward')}
                                >
                                    &gt;
                                </div>
                            </div>
                            {/* <Tabs
                                className="topblock-tabs"
                                value={value}
                                onChange={handleTabChange}
                                indicatorColor="primary"
                                textColor="primary"
                                variant="scrollable"
                                allowScrollButtonsMobile={true}
                                aria-label="debt calculator summary tabs"
                            >
                                <Tab label="Summary" {...a11yProps(0)} id="tab-5" />
                                <AddUserOptionBox>
                                    <AddNewDebt />
                                </AddUserOptionBox>
                         
                                <RefreshBox>
                                    <Box ref={searchcontainer} />
                                </RefreshBox>
                            </Tabs> */}
                            <SwipeableViews
                                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                index={value}
                                onChangeIndex={handleChangeIndex}
                            >
                                <Box value={value} index={0} dir={theme.direction}>
                                    <DebtTable
                                        hideToolBar={true}
                                        searchcontainer={searchcontainer}
                                        dispatch={dispatch}
                                        debtdata={debt}
                                        profile={setting.profileDetail}
                                        setEnableChart={setEnableChart}
                                    />
                                </Box>
                            </SwipeableViews>
                        </TopBlockBox>
                    )}
                </AccessControl>
            </>
        );
    };
    const breadcrumbs = [
        <CustomListItem className="cal-banner-menu" key="1" sx={{ m: 0 }} color="text.light">
            <CustomListItemText primary={'Home'} />
        </CustomListItem>,
        <CustomListItem className="cal-banner-menu" key="2" sx={{ m: 0 }} color="text.light">
            <CustomListItemText primary={'Debt Calculators'} />
        </CustomListItem>,
        <Typography ref={typographyRef} key="3" color="text.light" className="current-menu">
            Debt Calculator
        </Typography>
    ];
    return (
        (props.widgetcal && <WidgetBox>{render()}</WidgetBox>) || (
            <>
               <Meta
                title="Debt Calculator | TPE Hub"
                description="Use our Debt Calculator to assess your debt situation and create a plan to manage it. Enter your debt details and get personalized recommendations to help you pay off your debt faster and more effectively."
                page="/debt-calculator"
            />
                <DoRegistration />
                <CommonCalculator breadcrumbs={breadcrumbs} variantText="Debt Calculator" handleTour={handleTour} />
                <MainBox>
                    <LargeContainer maxWidth="xl">
                        {render()}
                        <DebtCalDesc />
                    </LargeContainer>
                </MainBox>
            </>
        )
    );
}
