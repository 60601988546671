import * as ActionTypes from './ActionTypes';
import { apiCall, handleError } from 'utils/Utils';
import Globals from 'Globals.js';
import { toast } from 'react-toastify';
// handle loader when api started to call
export const fetchFixedIncomeDashInit = () => {
    return {
        type: ActionTypes.FETCH_FIXED_INCOME_HISTORY_INIT
    };
};
//handle success of api
export const fetchFixedIncomeDashSuccess = (val) => {
    return {
        type: ActionTypes.FETCH_FIXED_INCOME_HISTORY_SUCCESS,
        data: val
    };
};

// handle api error
export const fetchFixedIncomeDashError = () => {
    return {
        type: ActionTypes.FETCH_FIXED_INCOME_HISTORY_ERROR
    };
};

/**
 * @author Ulyana
 * @param {*} data
 * @returns
 * @use to fetch the fixed income dashboard data
 */
export const fetchFixedIncomeDashData = (data) => async (dispatch) => {
    try {
        dispatch(fetchFixedIncomeDashInit());
        const apiResponse = await apiCall(`${Globals.API_ROOT_URL}fixedincomedashistory`, 'post', data);
        if (apiResponse !== undefined && apiResponse.status === 200) {
            dispatch(fetchFixedIncomeDashSuccess(apiResponse.data.data));
        } else {
            handleError(apiResponse, dispatch);
            dispatch(fetchFixedIncomeDashError());
        }
    } catch (error) {
        toast.error(Globals.errorMsg);
        dispatch(fetchFixedIncomeDashError());
        throw error;
    }
};
