import React from 'react';
import { CardActions, CardContent, CardMedia } from '@mui/material';
import Typography from '@mui/material/Typography';
import { BannerSigninButton } from 'assets/jss/BannerStyle';
import { CalSignUpCard } from 'assets/jss/GeneralStyle';
import { useNavigate } from 'react-router-dom';
const CalSignupBlock = () => {
    const navigate = useNavigate();

    return (
        <CalSignUpCard >
            <CardMedia component="img" image="img/cal-signin-img.png" title="cal-signin-img" className="signin-img" />
            <CardContent>
                <Typography variant="heading" display={'block'}>
                    Looking for more features?
                </Typography>
                <Typography variant="subheading">Register your account now, save and track all your finances</Typography>
            </CardContent>
            <CardActions>
                <BannerSigninButton
                    variant="contained"
                    className="cal-signup-btn"
                    align="center"
                    sx={{ mb: 3.3 }}
                    onClick={() => navigate('/sign-up')}
                >
                    Sign Up
                </BannerSigninButton>
            </CardActions>
        </CalSignUpCard>
    );
};

export default CalSignupBlock;
