import * as ActionTypes from './ActionTypes';
import { apiCall, handleError } from 'utils/Utils';
import Globals from 'Globals.js';
import { toast } from 'react-toastify';

//handle success of api
export const sendChatBotAnalysisSuccess = (val, userData) => {
    return {
        type: ActionTypes.SEND_CHAT_BOT_ANALYSIS_MSG_SUCCESS,
        data: val,
        userData
    };
};
// handle api error
export const sendChatBotAnalysisError = () => {
    return {
        type: ActionTypes.SEND_CHAT_BOT_ANALYSIS_MSG_ERROR
    };
};

// handle loader when api started to call
export const sendChatBotAnalysisMessageInit = () => {
    return {
        type: ActionTypes.SEND_CHAT_BOT_ANALYSIS_MSG_INIT
    };
};

/**
 * @author ula
 * @param {*} data
 * @use to send a message to the document analysis bot
 * @returns
 */
export const sendChatBotAnalysisMessage = (data) => async (dispatch) => {
    try {
        dispatch(sendChatBotAnalysisMessageInit());
        const apiResponse = await apiCall(`${Globals.API_ROOT_URL}budget_analysis_bot`, 'post', data);
        if (apiResponse !== undefined && apiResponse.status === 200) {
            dispatch(sendChatBotAnalysisSuccess(apiResponse.data.data, data));
        } else {
            toast.error(Globals.errorMsg);
            dispatch(sendChatBotAnalysisError());
        }
    } catch (error) {
        toast.error(Globals.errorMsg);
        dispatch(sendChatBotAnalysisError());
        throw error;
    }
};