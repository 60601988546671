import { Box, Button, Grid, Typography } from '@mui/material';
import { styled } from '@mui/styles';
export const CustomButton = styled(Button)(({ ps,w,h, theme }) => {
    return {
        borderRadius: '8px',
        height: h || '30px',
        // width: 'max-content',
        width: w || '106px',
        padding: ps || 8,
        '&.login-btn': {
            width: '100%',
            marginBottom: '15px'
        },
        '&.register-btn': {
            width: '100%'
        },
        '&.read-more': {
            width: 'max-content'
        },
        '&.action-btn-viewoption': {
            width: 154,
            height: 40
        },
        '&.success-btn': {
            background: theme.palette.success.main
        },
        '&.Mui-disabled': {
            background: `${theme.palette.grey[100]} !important`,
            color: `${theme.palette.grey[600]} !important`
        }
    };
});
