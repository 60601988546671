import * as ActionTypes from './ActionTypes';
import { apiCall, handleError } from 'utils/Utils';
import Globals from 'Globals.js';
import { toast } from 'react-toastify';
import { setCookie } from 'utils/Utils';
import { eraseCookie } from 'utils/Utils';

//handle success of api
export const submitTipsCalSuccess = (val) => {
    return {
        type: ActionTypes.SUBMIT_TIPS_CAL_SUCCESS,
        data: val
    };
};
// handle api error
export const submitTipsCalError = () => {
    return {
        type: ActionTypes.SUBMIT_TIPS_CAL_ERROR
    };
};
//submit tips data
export const submitTipsCal = (data) => async (dispatch) => {
    try {
        dispatch({
            type: ActionTypes.SUBMIT_TIPS_CAL_INIT
        });
        const apiResponse = await apiCall(`${Globals.API_ROOT_URL}tipscalctool`, 'post', data);
        if (apiResponse !== undefined && apiResponse.status === 200) {
            dispatch(submitTipsCalSuccess(apiResponse.data.data));
        } else {
            handleError(apiResponse, dispatch);
            dispatch(submitTipsCalError());
        }
    } catch (error) {
        toast.error(Globals.errorMsg);
        dispatch(submitTipsCalError());
        throw error;
    }
};

export const submitCompoundCalSuccess = (val) => {
    return {
        type: ActionTypes.SUBMIT_COMPOUND_CAL_SUCCESS,
        data: val
    };
};
// handle api error
export const submitCompoundCalError = () => {
    return {
        type: ActionTypes.SUBMIT_COMPOUND_CAL_ERROR
    };
};

export const submitCompoundCal = (data) => async (dispatch) => {
    try {
        dispatch({
            type: ActionTypes.SUBMIT_COMPOUND_CAL_INIT
        });
        const apiResponse = await apiCall(`${Globals.API_ROOT_URL}compound_and_recurring_expense`, 'post', data);
        if (apiResponse !== undefined && apiResponse.status === 200) {
            dispatch(submitCompoundCalSuccess(apiResponse.data.data));
        } else {
            handleError(apiResponse, dispatch);
            dispatch(submitCompoundCalError());
        }
    } catch (error) {
        toast.error(Globals.errorMsg);
        dispatch(submitCompoundCalError());
        throw error;
    }
};

//handle success of api
export const submitInflationCalSuccess = (val) => {
    return {
        type: ActionTypes.SUBMIT_INFLATION_CAL_SUCCESS,
        data: val
    };
};
// handle api error
export const submitInflationCalError = () => {
    return {
        type: ActionTypes.SUBMIT_INFLATION_CAL_ERROR
    };
};
//submit tips data
export const submitInflationCal = (data) => async (dispatch) => {
    try {
        dispatch({
            type: ActionTypes.SUBMIT_INFLATION_CAL_INIT
        });
        const apiResponse = await apiCall(`${Globals.API_ROOT_URL}inflationbondcalculator`, 'post', data);
        if (apiResponse !== undefined && apiResponse.status === 200) {
            dispatch(submitInflationCalSuccess(apiResponse.data.data));
        } else {
            handleError(apiResponse, dispatch);
            dispatch(submitInflationCalError());
        }
    } catch (error) {
        toast.error(Globals.errorMsg);
        dispatch(submitInflationCalError());
        throw error;
    }
};

//handle success of api
export const submitPropertyInvestSuccess = (val) => {
    return {
        type: ActionTypes.SUBMIT_PROPERTY_INVEST_SUCCESS,
        data: val
    };
};
// handle api error
export const submitPropertyInvestError = () => {
    return {
        type: ActionTypes.SUBMIT_PROPERTY_INVEST_ERROR
    };
};
/**
 * @author disha
 * @param {*} data
 * @use to submit property investment data
 * @returns
 */
export const submitPropertyInvest = (data) => async (dispatch) => {
    try {
        dispatch({
            type: ActionTypes.SUBMIT_PROPERTY_INVEST_INIT
        });
        const apiResponse = await apiCall(`${Globals.API_ROOT_URL}propertyinvesttool`, 'post', data);
        if (apiResponse !== undefined && apiResponse.status === 200) {
            dispatch(submitPropertyInvestSuccess(apiResponse.data.data));
        } else {
            handleError(apiResponse, dispatch);
            dispatch(submitPropertyInvestError());
        }
    } catch (error) {
        toast.error(Globals.errorMsg);
        dispatch(submitPropertyInvestError());
        throw error;
    }
};

//handle success of api
export const subscirbeForPushNotiSuccess = () => {
    return {
        type: ActionTypes.SUBSCRIBE_PUSH_NOTI_SUCCESS
    };
};
// handle api error
export const subscirbeForPushNotiError = () => {
    return {
        type: ActionTypes.SUBSCRIBE_PUSH_NOTI_ERROR
    };
};
/**
 * @author disha
 * @param {*} data
 * @use to submit property investment data
 * @returns
 */
export const subscirbeForPushNoti =
    (data, type = 'post') =>
    async (dispatch) => {
        try {
            dispatch({
                type: ActionTypes.SUBSCRIBE_PUSH_NOTI_INIT
            });
            let apiResponse = await apiCall(`${Globals.API_ROOT_URL}notification/subscribe`, type, data);
            if (apiResponse !== undefined && apiResponse.status === 200) {
                if (type === 'delete') {
                    eraseCookie('subscription_json');
                } else {
                    setCookie('subscription_json', data.subscription_json);
                }
                dispatch(subscirbeForPushNotiSuccess());
                toast.success(apiResponse.data.data.message);
            } else {
                if (type === 'delete') {
                    eraseCookie('subscription_json');
                }
                handleError(apiResponse, dispatch);
                dispatch(subscirbeForPushNotiError());
            }
        } catch (error) {
            toast.error(Globals.errorMsg);
            dispatch(subscirbeForPushNotiError());
            throw error;
        }
    };

//handle success of api
export const submitLifeInsSuccess = (val) => {
    return {
        type: ActionTypes.SUBMIT_LIFE_INS_SUCCESS,
        data: val
    };
};
// handle api error
export const submitLifeInsError = () => {
    return {
        type: ActionTypes.SUBMIT_LIFE_INS_ERROR
    };
};
// api to submit life insurance data
export const submitLifeIns = (data) => async (dispatch) => {
    try {
        dispatch({
            type: ActionTypes.SUBMIT_LIFE_INS_INIT
        });
        const apiResponse = await apiCall(`${Globals.API_ROOT_URL}lifeinsurancetool`, 'post', data);
        if (apiResponse !== undefined && apiResponse.status === 200) {
            dispatch(submitLifeInsSuccess(apiResponse.data.data));
        } else {
            handleError(apiResponse, dispatch);
            dispatch(submitLifeInsError());
        }
    } catch (error) {
        toast.error(Globals.errorMsg);
        dispatch(submitLifeInsError());
        throw error;
    }
};
//handle success of api
export const submitFeedbackSuccess = () => {
    return {
        type: ActionTypes.SUBMIT_FEEDBACK_SUCCESS
    };
};
// handle api error
export const submitFeedbackError = () => {
    return {
        type: ActionTypes.SUBMIT_FEEDBACK_ERROR
    };
};
// api to submit feedback
export const submitFeedback = (data) => async (dispatch) => {
    try {
        dispatch({
            type: ActionTypes.SUBMIT_FEEDBACK_INIT
        });
        const apiResponse = await apiCall(`${Globals.API_ROOT_URL}feedback`, 'post', data);
        if (apiResponse !== undefined && apiResponse.status === 200) {
            toast.success(apiResponse.data.data.message);
            dispatch(submitFeedbackSuccess());
        } else {
            handleError(apiResponse, dispatch);
            dispatch(submitFeedbackError());
        }
    } catch (error) {
        toast.error(Globals.errorMsg);
        dispatch(submitFeedbackError());
        throw error;
    }
};

export const handleSignInPermissionDialog = (flag) => {
    return {
        type: ActionTypes.SIGNIN_REQUIRED_ACTION,
        data: flag
    };
};
//handle success of api
export const submitContactUsSuccess = () => {
    return {
        type: ActionTypes.SUBMIT_CONTACTUS_SUCCESS
    };
};
// handle api error
export const submitContactUsError = () => {
    return {
        type: ActionTypes.SUBMIT_CONTACTUS_ERROR
    };
};
// api to submit contact us
export const submitContactUs = (data) => async (dispatch) => {
    try {
        dispatch({
            type: ActionTypes.SUBMIT_CONTACTUS_INIT
        });
        const apiResponse = await apiCall(`${Globals.API_ROOT_URL}contactus`, 'post', data);
        if (apiResponse !== undefined && apiResponse.status === 200) {
            toast.success(apiResponse.data.data.message);
            dispatch(submitContactUsSuccess());
        } else {
            handleError(apiResponse, dispatch);
            dispatch(submitContactUsError());
        }
    } catch (error) {
        toast.error(Globals.errorMsg);
        dispatch(submitContactUsError());
        throw error;
    }
};
//handle success of api
export const submitDefaultOverrideSuccess = () => {
    return {
        type: ActionTypes.SUBMIT_DEFAULT_OVERRIDE_SUCCESS
    };
};
// handle api error
export const submitDefaultOverrideError = () => {
    return {
        type: ActionTypes.SUBMIT_DEFAULT_OVERRIDE_ERROR
    };
};
// api to submit contact us
export const submitDefaultOverride = (data) => async (dispatch) => {
    try {
        dispatch({
            type: ActionTypes.SUBMIT_DEFAULT_OVERRIDE_INIT
        });
        const apiResponse = await apiCall(`${Globals.API_ROOT_URL}defaultoverride`, 'put', data);
        if (apiResponse !== undefined && apiResponse.status === 200) {
            toast.success(apiResponse.data.data.message);
            dispatch(getDefaultOverride());
            dispatch(submitDefaultOverrideSuccess());
        } else {
            handleError(apiResponse, dispatch);
            dispatch(submitDefaultOverrideError());
        }
    } catch (error) {
        toast.error(Globals.errorMsg);
        dispatch(submitDefaultOverrideError());
        throw error;
    }
};
//handle success of api
export const getDefaultOverrideSuccess = (data) => {
    return {
        type: ActionTypes.GET_DEFAULT_OVERRIDE_SUCCESS,
        data: data
    };
};
// handle api error
export const getDefaultOverrideError = () => {
    return {
        type: ActionTypes.GET_DEFAULT_OVERRIDE_ERROR
    };
};
// api to submit contact us
export const getDefaultOverride = (data) => async (dispatch) => {
    try {
        dispatch({
            type: ActionTypes.GET_DEFAULT_OVERRIDE_INIT
        });
        const apiResponse = await apiCall(`${Globals.API_ROOT_URL}defaultoverride`, 'get');
        if (apiResponse !== undefined && apiResponse.status === 200) {
            dispatch(getDefaultOverrideSuccess(apiResponse.data.data));
        } else {
            handleError(apiResponse, dispatch);
            dispatch(getDefaultOverrideError());
        }
    } catch (error) {
        toast.error(Globals.errorMsg);
        dispatch(getDefaultOverrideError());
        throw error;
    }
};
//handle success of api
export const submitAnnuityCalSuccess = (data) => {
    return {
        type: ActionTypes.SUBMIT_ANNUITY_CAL_SUCCESS,
        data: data
    };
};
// handle api error
export const submitAnnuityCalError = () => {
    return {
        type: ActionTypes.SUBMIT_ANNUITY_CAL_ERROR
    };
};

export const submitAnnuityCal = (data) => async (dispatch) => {
    try {
        dispatch({
            type: ActionTypes.SUBMIT_ANNUITY_CAL_INIT
        });
        const apiResponse = await apiCall(`${Globals.API_ROOT_URL}annuitytool`, 'post', data);
        if (apiResponse !== undefined && apiResponse.status === 200) {
            dispatch(submitAnnuityCalSuccess(apiResponse.data.data));
        } else {
            handleError(apiResponse, dispatch);
            dispatch(submitAnnuityCalError());
        }
    } catch (error) {
        toast.error(Globals.errorMsg);
        dispatch(submitAnnuityCalError());
        throw error;
    }
};
//handle success of api
export const submitNewsletterSuccess = (data) => {
    return {
        type: ActionTypes.SUBMIT_NEWSLETTER_SUCCESS,
        data: data
    };
};
// handle api error
export const submitNewsletterError = () => {
    return {
        type: ActionTypes.SUBMIT_NEWSLETTER_ERROR
    };
};

export const submitNewsletter = (data) => async (dispatch) => {
    try {
        dispatch({
            type: ActionTypes.SUBMIT_NEWSLETTER_INIT
        });
        const apiResponse = await apiCall(`${Globals.API_ROOT_URL}user/news/subscribe`, 'post', data);
        if (apiResponse !== undefined && apiResponse.status === 200) {
            toast.success(apiResponse.data.data.message);
            dispatch(submitNewsletterSuccess());
        } else {
            handleError(apiResponse, dispatch);
            dispatch(submitNewsletterError());
        }
    } catch (error) {
        toast.error(Globals.errorMsg);
        dispatch(submitNewsletterError());
        throw error;
    }
};

//handle success of api
export const getBlogListSuccess = (data) => {
    return {
        type: ActionTypes.GET_BLOG_LIST_SUCCESS,
        data: data
    };
};
// handle api error
export const getBlogListError = () => {
    return {
        type: ActionTypes.GET_BLOG_LIST_ERROR
    };
};
// api to submit contact us
export const getBlogList = () => async (dispatch) => {
    try {
        dispatch({
            type: ActionTypes.GET_BLOG_LIST_INIT
        });
        const apiResponse = await apiCall(`${Globals.API_ROOT_URL}blogs_dashboard/`, 'get');
        if (apiResponse !== undefined && apiResponse.status === 200) {
            dispatch(getBlogListSuccess(apiResponse.data.data));
        } else {
            handleError(apiResponse, dispatch);
            dispatch(getBlogListError());
        }
    } catch (error) {
        toast.error(Globals.errorMsg);
        dispatch(getBlogListError());
        throw error;
    }
};
