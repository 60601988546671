import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { LinearProgress, ListItemIcon, Typography } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { withStyles } from '@mui/styles';
import { AnimationTickerPaper, PlayPauseIconButton, TickerHeader, TickerList, TickerValue } from 'assets/jss/LandingStyle';
import { infoColor } from 'assets/jss/material-kit-react';
import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchEquityDashboardData } from 'redux/actions/EquityDashboardActions';
const TickerLoader = withStyles({
    colorPrimary: {
        backgroundColor: '#9cd0d6'
    },
    root: { top: 23 },
    barColorPrimary: {
        backgroundColor: infoColor
    }
})(LinearProgress);
const LandingTicker = (props) => {
    const firstAnimRef = useRef(null);
    const secondAnimRef = useRef(null);
    const [pauseVal, setPauseVal] = useState(false);
    const equityDashboardData = useSelector((state) => state.equityDashboard);
    const dispatch = useDispatch();
    // fetch equity data
    useEffect(() => {
        if (Object.keys(equityDashboardData?.equityDashboardDetail).length === 0) {
            dispatch(fetchEquityDashboardData());
        }
    }, []);
    /**
     * handle the play and pause button click to handle animation of ticker
     */
    const handlePlayPauseTicker = () => {
        if (!pauseVal) {
            firstAnimRef.current.classList.add('playPauseAnimation');
            secondAnimRef.current.classList.add('playPauseAnimation');
        } else {
            firstAnimRef.current.classList.remove('playPauseAnimation');
            secondAnimRef.current.classList.remove('playPauseAnimation');
        }
        setPauseVal(!pauseVal);
    };
    // to add green or red color to text according to its value
    const colorText = (val) => {
        return Math.sign(val) === -1 ? (
            <>
                <ArrowDownwardIcon className="negativeTickerArr" /> <span className="negativeVal">{Math.abs(val.toFixed(2))}%</span>
            </>
        ) : Math.sign(val) === 1 ? (
            <>
                <ArrowUpwardIcon className="postitiveTickerArr" />
                <span className="positiveVal">{val.toFixed(2)}%</span>
            </>
        ) : (
            val
        );
    };
    return (
        <AnimationTickerPaper square={true} elevation={2}>
            <TickerHeader>
                <PlayPauseIconButton size="small" aria-label="play pause ticker" component="span" onClick={handlePlayPauseTicker}>
                    {pauseVal ? <PlayArrowIcon /> : <PauseIcon />}
                </PlayPauseIconButton>
            </TickerHeader>
            <div className={'tickerWrapper'}>
                <div className={'tickerWrapperInner'}>
                    {[...Array(2)].map((count, c) => {
                        let refname = firstAnimRef,
                            classList = `firstTicker firstTickerAnimation`;
                        if (c === 1) {
                            refname = secondAnimRef;
                            classList = `secondTicker secondTickerAnimation`;
                        }
                        return (
                            <TickerList
                                ref={refname}
                                key={c + '_listTicker'}
                                className={classList}
                                component="ul"
                                aria-label="main mailbox folders"
                            >
                                {Object.keys(equityDashboardData.equityDashboardDetail).length
                                    ? equityDashboardData.equityDashboardDetail.data.map((val, i) => {
                                          return (
                                              <ListItem component="li" className={'liItems'} key={i + 'subListTicker'}>
                                                  <ListItemIcon className="ticker-icon">
                                                      <img src={val.logo} alt="ticker-logo" />
                                                      {/* <img src="img/ticker-icon.png" /> */}
                                                  </ListItemIcon>
                                                  <ListItemText sx={{ mt: 2 }}>
                                                      <Typography variant="cardHeader" className="ticker-company-name">
                                                          {val['short_company_name'] != null
                                                              ? val['short_company_name']
                                                              : val['Company Name']}
                                                      </Typography>
                                                      {/* <Typography variant="cardHeader" sx={{ ml: 0.5 }}>
                                                          ({val.Symbol})
                                                      </Typography> */}
                                                      <Typography variant="cardHeader" sx={{ ml: 0.5 }}>
                                                          ${val['Latest Price']}
                                                      </Typography>
                                                      <TickerValue>{colorText(val['Daily Price Change %'])}</TickerValue>
                                                  </ListItemText>
                                              </ListItem>
                                          );
                                      })
                                    : ''}
                            </TickerList>
                        );
                    })}
                </div>
            </div>
            {equityDashboardData.loader ? <TickerLoader /> : ''}
        </AnimationTickerPaper>
    );
};

export default LandingTicker;
