import { Grid, CardActions, Box } from '@mui/material';
import { styled } from '@mui/styles';
export const TwoValSliderGrid = styled(Grid)(({  theme }) => {
    return {
        paddingTop:40
    }
});
export const SliderParentGrid = styled(Grid)(({ theme }) => {
           return {
               justifyContent:'space-between'
           };
       });
export const RemoveIconButtonGridItem = styled(Grid)(({ theme }) => {
           return {
               position: 'relative',
               display:'flex',
               justifyContent:'end',
               '& .MuiIconButton-root': {
                   color: theme.palette.secondary.main,
                   // top: "-18px",
                   bottom: 0,
                //    left: '280px',
                   padding: '4px',
                   position: 'absolute',
                   zIndex: 1
               }
           };
       });
export const AddIconButtonGridItem = styled(Grid)(({ theme }) => {
           return {
               padding: '16px 0 0 0px !important',
               textAlign: 'center',
               '& .MuiIconButton-root': {
                   color: theme.palette.primary.main
               }
           };
       });
export const WrapperChartDiv = styled('div')(({ theme }) => {
           return {
               height: '450px',
               position: 'relative',
               '& .graph-container': {
                   height: 'inherit',
                   position: 'absolute',
                   top: '0px',
                   width: '100%'
               }
           };
       });
export const SecondGraphDiv = styled('div')(({ theme }) => {
           return {
               height: 'inherit',
               top: '0px',
               width: '100%'
           };
       });
export const CalCardActions = styled(CardActions)(({ theme }) => {
           return {
             padding:5,
             "& .MuiButtonBase-root":{
                marginTop:8
             },'&.contactus-cardaction':{
                justifyContent:'center'
             }
           };
       });
export const QuesAnsBox = styled(Box)(({ theme }) => {
           return {
               marginBottom: 50,
               '& .MuiTypography-root': { display: 'inherit' }
           };
       });
