import { makeStyles } from '@mui/styles';
import todoPageStyle from "assets/jss/material-kit-react/views/todoPage";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { submitTodo, submitTodoError, updateTodo } from "redux/actions/TodoActions";
import QuickTodo from "./QuickTodo";
import { TodoCards } from "./TodoCards";
import { TodoForm } from "./TodoForm";
import { useSearchParams } from "react-router-dom"
let _popuptimeout = 0;
const todoUseStyles = makeStyles(todoPageStyle);
export const TodoCardWrapper = (props) => {
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams()
    const todoProps = useSelector((state) => state.todo);
    const todoClasses = todoUseStyles();
    const [todoProgress, setProgressTodoVal] = useState({});
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [editTodoPopup, setEditTodoPopup] = useState({});
    const [addTodoPopup, setAddTodoPopup] = useState({ open: false, name: "" });
  useEffect(() => {
    //reset the progress value state when api get success after updating
    if (todoProps.submitTodoSuccess) {
      setProgressTodoVal({});
      dispatch(submitTodoError());
    }
  }, [todoProps.submitTodoSuccess]);
    const handleCloseTodoForm = () => {
      if (searchParams.has("id")) {
        searchParams.delete("id")
        setSearchParams(searchParams)
      }
      setEditTodoPopup({});
      setAddTodoPopup({ open: false, name: "" });
    };
    const handleEditTodo = (data) => {
      setEditTodoPopup(data);
    };
    const handleAddTodo = (stagename) => {
      setAddTodoPopup({ open: !addTodoPopup.open, name: stagename });
    };
  const handleProgressChange = (e, data) => {
    setProgressTodoVal({ [data.id]: e });
    let objToPass = {
      progress: e,
      todo_id: data.id,
    };
    if (_popuptimeout) clearTimeout(_popuptimeout);
    _popuptimeout = setTimeout(() => {
      dispatch(updateTodo(objToPass));
    }, 1000);
  };
    return (
      <>
        {props.isPopup ? (
          <QuickTodo
            sendPopupVal={(val) => setPopupOpen(val)}
            todoProgress={todoProgress}
            handleProgressChange={handleProgressChange}
            handleEditTodo={handleEditTodo}
            handleAddTodo={handleAddTodo}
          />
        ) : (
          <TodoCards
            isPopupOpen={isPopupOpen}
            handleAddTodo={handleAddTodo}
            todoProgress={todoProgress}
            handleProgressChange={handleProgressChange}
            handleEditTodo={handleEditTodo}
          />
        )}
        {addTodoPopup.open || Object.keys(editTodoPopup).length ? (
          <TodoForm
            addTodoPopup={addTodoPopup}
            edit={editTodoPopup}
            updateTodo={(e) => dispatch(updateTodo(e, editTodoPopup))}
            addTodo={(e) => dispatch(submitTodo(e))}
            resetSubmitTodoFlag={(e) => dispatch(submitTodoError())}
            submitTodoSuccess={todoProps.submitTodoSuccess}
            categoryList={todoProps.categoryList}
            handleClose={handleCloseTodoForm}
            classes={todoClasses}></TodoForm>
        ) : (
          ""
        )}
      </>
    );
};
