import { styled } from '@mui/system';
import { Box, Button, ButtonBase, Card, CardContent, Divider, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';

export const PricingBox = styled(Box)(({ theme }) => ({
           textAlign: 'center',
           background: '#DAEBFF',
           minHeight: 540,
           margin: '0 100px 34px',
           borderRadius: theme.borderRadius.round,
           '& .dashboard-subheading': {
               fontWeight: 500,
               textTransform: 'capitalize',
               lineHeight: '25px',
               display: 'block'
           },
           '& .grid-container': {
               justifyContent: 'space-between',
               width: 'inherit',
               margin: 'auto 100px',
               textAlign: 'left',
                 [theme.breakpoints.between('md','lg')]: {
                       margin:0,
                       display:'flex'
                   },
                 [theme.breakpoints.down('md')]: {
                       margin:0,
                       textAlign:'center'
                   }
                },
                [theme.breakpoints.down('md')]: {
                      margin:0,
                  }
       }));


export const PricingCard = styled(Card)(({ row, theme }) => {
           return {
               // ...(row === 'second' ? { marginLeft: '12.5%' } : { marginRight: '12%' }),
               width: '216px',
               display: 'inline-block',
               height: '324px',
               margin: '0 10%',
               color: theme.palette.text.light,
               backgroundColor: theme.palette.secondary.main,
               //    boxShadow: '1.04499px 1.04499px 4.70244px rgba(0, 0, 0, 0.25)',
               //    borderRadius: '5.22493px',
               '& .card-icon': {
                   width: '25%',
                   height: 'auto',
                   margin: '18px auto'
               },
               '& .MuiCardContent-root': {
                   padding: '18px 20px',
                   '&:last-child': {
                       paddingBottom: 2
                   }
               },
               // [theme.breakpoints.down('xl')]: {
               //     ...(row === 'second' ? { marginLeft: '8%' } : { marginRight: '8%' })
               // },
               // [theme.breakpoints.down('md')]: {
               //     ...(row === 'second' ? { marginLeft: '2%' } : { marginRight: '2%' })
               // },
               [theme.breakpoints.down('sm')]: {
                   marginLeft: '10px',
                   marginBottom: '6px'
                   // ...(row === 'second' ? { marginLeft: '2%' } : { marginRight: '2%' })
               }
           };
       });
export const DashGrid = styled(Grid)(({ row, theme }) => {
    return {
        width: '140px',
        // display: 'inline-block',
        height: '144px',
        padding: '0 !important',
        '& .MuiPaper-root': {
            margin: 0
        },
        '&:nth-of-type(odd) .MuiTypography-root': {
            color: theme.palette.text.light,
        },
        '&:nth-of-type(even) .MuiTypography-root svg': {
            color: theme.palette.secondary.main
        },
        '&:nth-of-type(even)': {
            margin: '0',
            marginTop: '50px',
            '& .MuiTypography-root': {
                color: theme.palette.text.dark
            },
            '& .MuiPaper-root': {
                background: 'white'
            }
        },
        [theme.breakpoints.down('md')]: {
            display: 'inline-table',
            margin: '0 !important',
            '& .MuiPaper-root': {
                textAlign: 'start'
            }
        }
    };
});
export const PricingCardContent = styled(CardContent)(({ row, theme }) => {
           return {
            
               '& .title': {},
               '& .sub-title': {
                   fontSize: '2rem',
                   '& .sub-period': {
                       fontSize: '1.1rem',
                       position:'absolute',
                       marginTop:7
                   }
               },
               '& .price-feature-box': {
                marginBottom:'30px',
                display:'grid',
                maxHeight:'146px',
                   '& .MuiTypography-root': {
                    lineHeight:'1.8em',
                    //    fontWeight: theme.typography.fontWeightBold
                   },
                   svg: {
                       width: '0.6em',
                       height: '0.6em',
                       marginRight:9
                   }
               }
           };
       });
export const PricingBtn = styled(Button)(({ row, theme }) => {
           return {
               backgroundColor:theme.palette.button.dark,
               padding:9
           };
       });
export const MostPopularTypography = styled(Typography)(({ row, theme }) => {
           return {
            color:theme.palette.text.light,
            display:'flex',
            justifyContent:'center',alignItems:'center',height:'24px'
           };
       });
export const NeedHelpCard = styled(Card)(({ row, theme }) => {
           return {
               margin: 'auto',
               width: '40%',
               '& .get-started-box': {
                   margin: 'auto'
               },
               '& .needhelp-card-content': {
                   display: 'flex',
               },
               '& .text-box': {
                   maxWidth: 330,
               },
               [theme.breakpoints.down('sm')]: {
                   width: '100%',
               }
           };
       });
export const FAQGridContainer = styled(Grid)(({ row, theme }) => {
           return {
               width: '70%',
               margin: 'auto',
               alignItems: 'center',
               '& .MuiGrid-item': {
                   textAlign: 'justify'
               },
               '& .faq-grid-item': {
                   marginBottom: 8
               },
               [theme.breakpoints.down('sm')]: {
                   width: '92%',
                   margin:0
               }
           };
       });
export const PricingPageBox = styled(Box)(({ row, theme }) => {
           return {
               '& .pricing-card': {
                   minHeight: '450px',
                   border: `3px solid ` + theme.palette.background.paper,
                   marginTop:"5px",
                   '&:hover': {
                       border: `3px solid ${theme.palette.secondary.main}`
                   }
               },
               '& .selected .pricing-card': {
                   border: `3px solid ${theme.palette.secondary.main}`
               },
               '& .popular .pricing-card': {
                   '&:hover': {
                       border: `3px solid ${theme.palette.secondary.main}`
                   },
                   border: `3px solid ${theme.palette.primary.main}`
               },
               '& .pricing-card-content': {
                   padding: '12px',
                   textAlign: 'center',
                   marginTop: '10px'
               },
               '& .pricing-card-box-wrapper': {
                   margin: '-36vh 12vw 0',
                   [theme.breakpoints.down('sm')]: {
                       marginTop: '-43vh',
                   }
               }
           };
       });
export const TitleBox = styled(Box)(({ row, theme }) => {
           return {
               textAlign: 'center',
               margin: '57px auto 0',
               [theme.breakpoints.down('sm')]: {
                   margin:0
               }
           };
       });
export const PricingDivider = styled(Divider)(({ row, theme }) => {
           return {
            margin:'17px 0',
               border: `2px solid ${theme.palette.divider}`
           };
       });
export const CheckIconDiv = styled('div')(({ row, theme }) => {
           return {
               color: theme.palette.success.main,
               marginRight: '6px'
           };
       });
export const PricingToggleButtonGroup = styled(ToggleButtonGroup)(({ row, theme }) => {
           return {
               backgroundColor: theme.palette.background.paper,
               borderRadius: theme.borderRadius.fullround,
               '& .Mui-selected': {
                   color: `${theme.palette.text.light} !important`,
                   backgroundColor: `${theme.palette.primary.main} !important`,
                   borderRadius:'inherit'
               },
           };
       });
export const PricingToggleButton = styled(ToggleButton)(({ row, theme }) => {
           return {
               '&.MuiToggleButtonGroup-grouped:not(:last-of-type)': {
                   borderTopRightRadius: 'inherit !important',
                   borderBottomRightRadius: 'inherit !important',
                },
                '&.MuiToggleButtonGroup-grouped:not(:first-of-type)': {
                   borderTopLeftRadius: 'inherit !important',
                   borderBottomLeftRadius: 'inherit !important',
               },
               "&:hover":{
                borderRadius:'inherit'
               },
               border: '0',
               padding:'11px 24px'
           };
       });
export const PricingGrid = styled(Grid)(({ row, theme }) => {
    return {
        // position: 'relative',
        // '&.selected::after': {
        //     // content: "''",
        //     // position: 'absolute',
        //     // background: '#1e88e5',
        //     // width: '94%',
        //     // /* border: "1px solid", */
        //     // left: '16px',
        //     // height: '30px',
        //     // borderTopRightRadius: theme.borderRadius.round,
        //     // borderTopLeftRadius: theme.borderRadius.round,
        //     // top: '25px'
        // }
        marginBottom:'16px',
        '&:nth-of-type(2) .pricing-name': {
            color: theme.palette.success.main
        },
        '&:nth-of-type(3) .pricing-name': {
            color: theme.palette.others.blue
        },
        '&:nth-of-type(4) .pricing-name': {
            color: theme.palette.others.purple
        },
        '&:nth-of-type(5) .pricing-name': {
            color: theme.palette.secondary.main
        }
    };
       });
export const PricingBoxWrapper = styled(Box)(({ row, theme }) => {
           return {
               '&.selected': {
                   background: theme.palette.secondary.main,
                   borderRadius: '16px',
                   border: `1px solid ${theme.palette.secondary.main}`,
                   marginTop: '-24px',
                   color:theme.palette.text.light
               },
               '&.popular': {
                   background: theme.palette.primary.main,
                   borderRadius: '16px',
                   border: '1px solid ' + theme.palette.primary.main,
                   marginTop: '-50px'
               }
           };
       });

