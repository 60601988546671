import { Card, CardContent,  Grid, List, ListItem, Typography } from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import React,{ useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getBillingDetails, getSubcriptions, submitBillingUpdate } from "redux/actions/SettingActions";
import { getCookie } from "utils/Utils";
import { MainBox } from "assets/jss/GeneralStyle";
import { BannerBox } from "assets/jss/BannerStyle";
import { Box } from "@mui/system";
import { PricingPageBox } from "assets/jss/PricingStyle";
import { TitleBox } from "assets/jss/PricingStyle";
import { PricingToggleButtonGroup } from "assets/jss/PricingStyle";
import { PricingToggleButton } from "assets/jss/PricingStyle";
import { CustomButton } from "assets/jss/ButtonStyle";
import { PricingDivider } from "assets/jss/PricingStyle";
import { CheckIconDiv } from "assets/jss/PricingStyle";
import { PricingGrid } from "assets/jss/PricingStyle";
import FAQBlock from "./FAQBlock";
import NeedHelpBlock from "./NeedHelpBlock";
import { PricingBoxWrapper } from "assets/jss/PricingStyle";
import { MostPopularTypography } from "assets/jss/PricingStyle";
import { NewTaskRibbon } from "assets/jss/GeneralStyle";
import Meta from "components/meta";
const mostpopular='Gold';
export default function PricingIndex() {
    const setting = useSelector((state) => state.setting);
    const [alignment, setAlignment] = useState('monthly');

    const dispatch = useDispatch();
  useEffect(() => {
    /// to fetch the default permission list and subscription list values on page load
    dispatch(getSubcriptions());
     if (getCookie("auth_token")) {
       dispatch(getBillingDetails());
     }

  }, []);
  useEffect(() => {
    if (setting.billingDetails.plan_id) {
      if (setting.billingDetails?.plan_period !== 'month') {
        setAlignment('annually');
      }
    }

  }, [setting.billingDetails]);
  const handleCancelPlan = (val) => {
    dispatch(
      submitBillingUpdate(
        { sub_id: setting.billingDetails.sub_id },
        "put"
      )
    );
  }
    //handle toggle button
  const HandlePeriodSwitch = (e,newAlignment) => setAlignment(newAlignment);
  const HandlePlanChange = (val) => {
    let priceId = val.stripe_price_id_monthly;
    if (alignment === 'annually') {
        priceId = val.stripe_price_id_yearly;
    }
      dispatch(
        submitBillingUpdate(
          {
            bill_id: setting.billingDetails.id,
            price_id: priceId,
            plan_id: val.id,
            sub_id: setting.billingDetails.sub_id,
          },
          "post"
        )
      );
  };
  const checkUserLogin = (e) => {
    if (!getCookie("auth_token")) {
      e.preventDefault();
      return toast.error("Please login to subscribe the plan. ");
    }
  }

    // const val=[...Array(5)]
  return (
      <MainBox>
             <Meta
                title="Subscription | TPE Hub"
                description="Manage your subscription plans, view billing history, and update payment information on our Subscription page. Choose the best plan that fits your needs and enjoy uninterrupted services."
                page="/subscription"
            />
          <PricingPageBox position={'relative'}>
              <BannerBox img={'img/pricing-banner.png'} customheight={'76vh'} id="pricing-banner">
                  <TitleBox>
                      <Typography variant="h2" sx={{ mb: 1 }}>
                          Your pocket-friendly pricing plans
                      </Typography>
                      <Typography variant="h6" sx={{ mb: 4 }}>
                          Its free to get started, you only pay when you need it
                      </Typography>
                      <PricingToggleButtonGroup
                          color="primary"
                          value={alignment}
                          exclusive
                          onChange={HandlePeriodSwitch}
                          aria-label="Platform"
                      >
                          <PricingToggleButton value="monthly">Pay monthly</PricingToggleButton>
                          <PricingToggleButton value="annually">Pay annually</PricingToggleButton>
                      </PricingToggleButtonGroup>
                  </TitleBox>
              </BannerBox>
              <Box className="pricing-card-box-wrapper">
                  <Grid container spacing={2}>
                      {setting.subscriptionList.map((val, i) => {
                          let price = val.price_monthly,
                              paymentUrl = val.payment_link_monthly,
                              periodName = 'month';
                          if (alignment === 'annually') {
                              price = val.price_yearly;
                              paymentUrl = val.payment_link_yearly;
                              periodName = 'year';
                          }
                          let selectCard =
                              setting.billingDetails.plan_id === val.id &&
                              setting.billingDetails.plan_period === periodName &&
                              !setting.billingDetails.plan_cancel;
                          const isMostPopular = mostpopular.toLowerCase() === val.name.toLowerCase();
                          return (
                              <PricingGrid item xs={12} sm={6} md={4}>
                                  <NewTaskRibbon>
                                      <div className="wrap addRighPosition pricing-card-coming-soon">
                                          <span className="ribbonLabel">Coming Soon</span>
                                      </div>
                                  </NewTaskRibbon>
                                  <PricingBoxWrapper className={`${selectCard && 'selected'} ${isMostPopular && 'popular'}`}>
                                      {isMostPopular ? <MostPopularTypography variant="h6"> Most Popular</MostPopularTypography> : ''}
                                      {selectCard ? <MostPopularTypography variant="h6"> Current Plan</MostPopularTypography> : ''}
                                      <Card elevation={3} className="pricing-card">
                                          <CardContent className="pricing-card-content">
                                              <Typography variant="heading" className="pricing-name">
                                                  {val.name}
                                              </Typography>
                                              {/* <Typography variant="subtitle1" mb={1}>
                                                  No charge for checking
                                              </Typography> */}
                                              <Typography variant="h2" my={1}>
                                                  $ {price}
                                              </Typography>
                                              <Typography variant="body1" mb={1}>
                                                  per {alignment === 'annually' ? 'annum' : 'monthly'} / billed{' '}
                                                  {alignment === 'annually' ? 'annually' : 'monthly'}
                                              </Typography>
                                              {selectCard && !setting.billingDetails.plan_cancel ? (
                                                  <CustomButton
                                                      variant="contained"
                                                      disabled={setting.profileDetail.role === 'admin' ? false : true}
                                                      onClick={() => handleCancelPlan(val)}
                                                      // href={"https://buy.stripe.com/test_9AQ2bfa1KcXp4Rq4gh"}
                                                      // onClick={() => handlePlanSelection(val)}
                                                  >
                                                      Cancel plan
                                                  </CustomButton>
                                              ) : setting.billingDetails.plan_id && !setting.billingDetails.plan_cancel ? (
                                                  <CustomButton
                                                      //   variant="contained"
                                                      disabled={setting.profileDetail.role === 'admin' ? false : true}
                                                      variant="outlined"
                                                      style ={{ width:"max-content"}}
                                                      // href={paymentUrl}
                                                      onClick={() => HandlePlanChange(val)}
                                                  >
                                                      Switch to this plan
                                                  </CustomButton>
                                              ) : (
                                                  <CustomButton
                                                      ps={'9px 30px'}
                                                      variant="contained"
                                                      disabled={setting.profileDetail.role === 'admin' ? false : true}
                                                      onClick={(e) => checkUserLogin(e)}
                                                      href={paymentUrl}
                                                      // color="rose"
                                                  >
                                                      Choose Plan
                                                  </CustomButton>
                                              )}
                                              <PricingDivider />
                                              <List dense>
                                                  {val.plan_include.map((innerval, innerindex) => {
                                                      return (
                                                          <ListItem key={`${innerindex}_1`}>
                                                              <CheckIconDiv>
                                                                  <CheckIcon />
                                                              </CheckIconDiv>
                                                              <p>{innerval}</p>
                                                          </ListItem>
                                                      );
                                                  })}
                                              </List>{' '}
                                          </CardContent>
                                      </Card>{' '}
                                  </PricingBoxWrapper>
                              </PricingGrid>
                          );
                      })}
                  </Grid>
              </Box>
              <FAQBlock />
              <NeedHelpBlock />
          </PricingPageBox>

          {/* <div className={classes.pricingRoot}>
              <CssBaseline />
              <CommonCalculator isSetting={true} variantType="h4" />
              <div className={classNames(classes.main, classes.mainRaised, classes.root)} id="pricing-parent">
                  <div className={classes.container}>
                      {setting.loader ? <Loader /> : ''}
                      <Paper elevation={0} className={classes.pricingHeader}>
                          <div className="settingHeader">
                              <Typography variant={'h4'} component="h2">
                                  Ready to start with TPE?
                              </Typography>
                              <Typography variant={'h6'} component="p">
                                  Choose the plan that suits you.
                              </Typography>
                          </div>
                          <div className={classes.periodSwitch}>
                              Monthly
                              <Switch
                                  checked={webswitchVal}
                                  onChange={(event) => HandlePeriodSwitch(event)}
                                  value="webswitchVal"
                                  classes={{
                                      switchBase: classes.switchBase,
                                      checked: classes.switchChecked,
                                      thumb: classes.switchIcon,
                                      track: classes.switchBar
                                  }}
                              />{' '}
                              Yearly
                          </div>
                          <GridContainer>
                              {setting.subscriptionList.map((val, i) => {
                                  let price = val.price_monthly,
                                      paymentUrl = val.payment_link_monthly,
                                      periodName = 'month';
                                  if (webswitchVal) {
                                      price = val.price_yearly;
                                      paymentUrl = val.payment_link_yearly;
                                      periodName = 'year';
                                  }
                                  let selectCard =
                                      setting.billingDetails.plan_id === val.id && setting.billingDetails.plan_period === periodName;

                                  return (
                                      <GridItem xs={12} sm={4}>
                                          <Paper
                                              elevation={3}
                                              className={`${classes.priceCardParent} ${
                                                  // ${i == 1 && "selected"}
                                                  selectCard && 'selected'
                                              }`}
                                          >
                                              <Typography variant="h6" component="h6" className="planName">
                                                  {val.name}
                                              </Typography>
                                              <Typography variant="h3" component="h1" className={classes.priceheader}>
                                                  <small>$</small>
                                                  {price} <small>/{periodName}ly</small>
                                              </Typography>
                                            
                                              <List dense>
                                                  {val.plan_include.map((innerval, innerindex) => {
                                                      return (
                                                          <ListItem key={`${innerindex}_${i}`} className={classes.liOffertext}>
                                                              <b>
                                                                  <CheckIcon />
                                                              </b>
                                                              <p>{innerval}</p>
                                                          </ListItem>
                                                      );
                                                  })}
                                              </List>{' '}
                                              <div>
                                                  {selectCard && !setting.billingDetails.plan_cancel ? (
                                                      <Button
                                                          round
                                                          color={'rose'}
                                                          className="chooseplanbtn"
                                                          onClick={() => handleCancelPlan(val)}
                                                          // href={"https://buy.stripe.com/test_9AQ2bfa1KcXp4Rq4gh"}
                                                          // onClick={() => handlePlanSelection(val)}
                                                      >
                                                          Cancel plan
                                                      </Button>
                                                  ) : setting.billingDetails.plan_id ? (
                                                      <Button
                                                          round
                                                          color={'rose'}
                                                          className="chooseplanbtn"
                                                          // href={paymentUrl}
                                                          onClick={() => HandlePlanChange(val)}
                                                      >
                                                          Upgrade Plan
                                                      </Button>
                                                  ) : (
                                                      <Button
                                                          round
                                                          color={'rose'}
                                                          className="chooseplanbtn"
                                                          onClick={(e) => checkUserLogin(e)}
                                                          href={paymentUrl}
                                                      >
                                                          Choose Plan
                                                      </Button>
                                                  )}{' '}
                                              </div>
                                          </Paper>
                                      </GridItem>
                                  );
                              })}
                          </GridContainer>
                      </Paper>
                  </div>
              </div>
          </div> */}
      </MainBox>
  );
}
