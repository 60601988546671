import { styled } from '@mui/system';
import MuiAppBar from '@mui/material/AppBar';
import Paper from '@mui/material/Paper';
import { Box, Button, ListItem, ListItemText, Popover } from '@mui/material';
export const AppBar =  styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) =>  {
    return {
        // transition: theme.transitions.create(['margin', 'width'], {
        //     easing: theme.transitions.easing.sharp,
        //     duration: theme.transitions.duration.leavingScreen
        // }),
        //  ...(open && {
        // // width: `calc(100% - ${drawerWidth}px)`,
        // transition: theme.transitions.create(['margin', 'width'], {
        //     easing: theme.transitions.easing.easeOut,
        //     duration: theme.transitions.duration.enteringScreen
        // }),
        // marginRight: drawerWidth
        // }),
        backgroundColor: theme.palette.common.white,
        height: 60,
        display: 'flex',
        // flexDirection: 'row',
        // justifyContent: 'center',
        // alignItems: 'center',
        '& .loginsignupBtn': {
            '& .login': { width: 100, color: theme.palette.text.dark, fontWeight: 700 },
            
        }
    };
});

export const HeaderRightBtn = styled(Button)(({ theme }) => ({
    width: 100,
    color: theme.palette.text.dark,
    fontWeight: 700,
    '&.signup': {
        width: 150,
        border: `3px solid ${theme.palette.common.black}`,
        borderRadius: 63,
        color: theme.palette.text.dark,
        fontWeight: 700
    }
}));
export const CustomImg = styled('img')(({ theme }) => ({
    width: '18vw',
    height: '4vh',
    maxWidth: '150px',
    maxHeight: 50
}));
export const CustomPopoverBox = styled(Box)(({ theme }) => ({
    '& .MuiPaper-root': {
        left: '0 !important',
        top: '50px !important',
        borderRadius: 'unset',
        maxWidth: 'inherit',
        boxShadow: 'unset'
    },
    '& .popover-box': {
        height: '264px',
        display: 'flex'
    },
    '& .popover-container': {
        padding: '15px',
        display: 'flex',
        alignItems: 'center',
        '& .align-menu-dash': {
            //    margin: 0,
            display: 'contents',
            '& .MuiPaper-root': { margin: 'auto' }
        }
    },
    '& .align-menu-cal': {
        top: '0px',
        '& .MuiTypography-root': {
            whiteSpace: 'break-spaces'
        }
    },
    [theme.breakpoints.down('lg')]:{
        '& .align-menu-cal': {
            position: 'relative',
            top: '20px',
        }
    },
}));
export const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    // padding: theme.spacing(0),
    // textAlign: 'center',
    color: theme.palette.text.secondary,
    // border:'1px solid'
    "& .MuiMenuItem-root":{
      padding:"3px 0"
    },
    "& .MuiList-root":{
      padding:0,
        // paddingTop:"7px"
    }
}));
export const MenuBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
}));
export const CustomListItem = styled(ListItem)(({ theme }) => {
    return {
        lineHeight: '22px',
        display: 'flex',
        alignItems: 'center',
        letterSpacing: '-0.03em',
        color: theme.palette.text.secondary,
        margin: '0 10px',
        '& .listbutton': {
            paddingLeft: '6px',
            paddingRight: '6px',
            '&.Mui-selected': {
                backgroundColor: theme.palette?.primary.light,
                '&:hover': {
                    backgroundColor: theme.palette?.primary.dark
                }
            },
            '&:hover': {
                // backgroundColor: theme.palette?.primary.dark
            },
            '&.active': {
                backgroundColor: '#0000000a'
            }
        },
        '&.cal-banner-menu': {
            padding: 0
        }
    };
});
export const CustomListItemText = styled(ListItemText)(({ theme }) => ({
    width: 'max-content',
    '& .MuiTypography-root': {
        // fontSize:theme.typography.body1.fontSize
        fontWeight: theme.typography.fontWeightMedium
    }
}));

export const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start'
}));