import React, { useEffect ,useState} from "react";
import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import NoDataFound from "components/CommonComponent/NoDataFound";
import Danger from "components/Typography/Danger";
import Success from "components/Typography/Success";
import Warning from "components/Typography/Warning";
import CustomTooltip from "components/CommonComponent/CustomTooltip";
import PieChart from "components/Charts/PieChart";
import { numberWithCommas } from "utils/Utils";
import { OutputBox } from "assets/jss/GeneralStyle";

const msg =
  "This is our traffic light system warning you when your finances are OK (Green), warning zone (yellow) or danger zone (red). The threshold varies per calculator. You can improve your assumptions and the traffic light will improve when you recalculate.";
const components = {
  red: { name: Danger, msg: msg ,imgname:"red"},
  yellow: {name:Warning,msg:msg,imgname:"yellow"},
  green: {name:Success,msg:msg,imgname:"green"},
};
const BudgetOutput = ({ data ,...rest}) => {
    const xaxisArr = rest.widgetcal ? [-100, -90, -100, -70, -80] : [50, -30, -90, -50, -100];
  const CompoColorType = data && components[data["Traffic Light"]?.toLowerCase()];
  const [chartAllData, setChartAllData] = useState([])
  // const [chartAllData, setChartAllData] = useState([
  //   { id: "Housing", label: "Housing", value: 0.580046403712297, subvalue: [ {id: "Housing2", label: "Housing2", value: 0.46403712297,subvalue: [ {id: "Housing4", label: "Housing4", value: 20.46403712297 }, {id: "Housing5", label: "Housing5", value: 30.403712297 } ] }, {id: "Housing3", label: "Housing3", value: 10.403712297 } ]},
  //   { id: "Healthcare", label: "Healthcare", value: 0.2691415313225058 },
  //   { id: "Leisure", label: "Leisure", value: 0.02320185614849188 },
  //   { id: "Groceries", label: "Groceries", value: 0.027842227378190254 },
  //   { id: "Transport", label: "Transport", value: 0.02320185614849188 },
  //   { id: "Vacation", label: "Vacation", value: 0.04640371229698376 },
  //   { id: "Misc", label: "Misc", value: 0.030162412993039442 },
  // ]);
//   const [selectedChartData, setSelectedChartData] = useState([
//     { id: "Housing", label: "Housing", value: 0.580046403712297, subvalue: [ {id: "Housing2", label: "Housing2", value: 0.46403712297,subvalue: [ {id: "Housing4", label: "Housing4", value: 20.46403712297 }, {id: "Housing5", label: "Housing5", value: 30.403712297 } ] }, {id: "Housing3", label: "Housing3", value: 10.403712297 } ]},
//     { id: "Healthcare", label: "Healthcare", value: 0.2691415313225058 },
//     { id: "Leisure", label: "Leisure", value: 0.02320185614849188 },
//     { id: "Groceries", label: "Groceries", value: 0.027842227378190254 },
//     { id: "Transport", label: "Transport", value: 0.02320185614849188 },
//     { id: "Vacation", label: "Vacation", value: 0.04640371229698376 },
//     { id: "Misc", label: "Misc", value: 0.030162412993039442 },
//   ]);

    useEffect(() => {
        if (data && Object.keys(data).length > 0) {
          const formatChartData = [];
          if (Object.keys(data.budPieData).length) {
            Object.keys(data.budPieData).filter((objKey) =>
              formatChartData.push({
                id: objKey,
                label: objKey,
                value: data.budPieData[objKey],
              })
            );
          }
          setChartAllData(formatChartData);
          // setSelectedChartData(formatChartData);
        }
    }, [data]);
//   const handleOnClick = (node, event) => node.data.subvalue && setSelectedChartData(node.data.subvalue);
//   const handleReset = () => setSelectedChartData(chartAllData);
  return (
      <>
          <Card elevation={2} sx={{ mb: 3.5 }}>
              <CardContent>
                  <Typography variant="h6" gutterBottom sx={{ mb: 2, display: 'flex' }}>
                      Summary Output
                  </Typography>
                  <Box id="outputWrapper" className="widget-full-width">
                      {data && Object.keys(data).length > 0 ? (
                          <Grid container spacing={2}>
                              <Grid item xs={12} md={4}>
                                  <OutputBox>
                                      <Typography variant="body1" className="outputLabel" component="p">
                                          Total Annual Expenses
                                      </Typography>
                                      <Typography variant="h6">{numberWithCommas(data['Total Annual Expenses'].toFixed(2))}</Typography>
                                  </OutputBox>
                              </Grid>
                              <Grid item xs={12} md={4}>
                                  <OutputBox>
                                      <Typography variant="body1" className="outputLabel" component="p">
                                          Budget as a Percent of Income
                                      </Typography>
                                      {CompoColorType !== undefined ? (
                                          <CompoColorType.name className="value-with-signal">
                                              <Typography variant="h6">{numberWithCommas(data['budIncomePer'].toFixed(2))}</Typography>
                                              <CustomTooltip
                                                  tooltipText={CompoColorType.msg}
                                                  imgTooltip={true}
                                                  img={<img src={`img/Signal-${CompoColorType.imgname}.png`} alt='traffic signal' />}
                                              />
                                          </CompoColorType.name>
                                      ) : (
                                          ''
                                      )}
                                  </OutputBox>
                              </Grid>
                          </Grid>
                      ) : (
                          <NoDataFound />
                      )}
                  </Box>
              </CardContent>
          </Card>
          {data && Object.keys(data).length > 0 ? (
              <Card elevation={2}>
                  <CardContent>
                      <Typography variant="h6" sx={{ mb: 2, display: 'flex' }}>
                          Summary Graph
                      </Typography>
                      <Grid container spacing={2}>
                          {Object.keys(chartAllData).length > 0 ? (
                              <Grid item xs={12} md={12} style={{ height: '400px' }}>
                                  {/* <Button
                  variant="contained"
                  color="rose"
                  size='xs'
                  onClick={handleReset}
                  className={classes.button}
                  startIcon={<SettingsBackupRestoreIcon />}
                >
                  Reset
                </Button> */}
                                  <PieChart
                                      chartData={chartAllData}
                                      initCount={30}
                                      initYcount={{ 0: 220, 1024: 220, 768: 230, 425: 290, 375: 280 }}
                                      initXcount={xaxisArr}
                                      //   initXcount={[50, 30, -90, -50, -100]}
                                  />
                                  {/* <PieChart chartData={selectedChartData} handleOnClick={handleOnClick} /> */}
                              </Grid>
                          ) : (
                              ''
                          )}
                      </Grid>
                  </CardContent>
              </Card>
          ) : (
              ''
          )}
      </>
  );
};

export default BudgetOutput;
