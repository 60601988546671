import React from 'react';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { Checkbox, FormHelperText } from '@mui/material';
const CustomCheckbox = (props) => {
    const handleChange = (param) => {
        if (props && props.onChange) {
            props.onChange(param);
        }
    };
    return (
        <FormControl error={props.error} disabled={props.disabled} required={props.required} style={{...props.formstyle}}>
            {!props.hideLabel ? <FormLabel id={props.id || props.name}>{props?.label}</FormLabel> : ''}
            <Checkbox
                name={props.id || props.name}
                checked={props.value}
                inputProps={{ 'aria-label': 'controlled' }}
                onChange={(e) => {
                    props.handleOnChange && props.handleOnChange(e);
                    handleChange(e);
                }}
                {...props}
            />
            {props.helperText ? <FormHelperText>{props.helperText}</FormHelperText> : ''}
        </FormControl>
    );
};
export default CustomCheckbox;
