import {
    Accordion,
    AppBar,
    Badge,
    Box,
    Breadcrumbs,
    Card,
    Chip,
    Container,
    Dialog,
    DialogContent,
    Divider,
    FormHelperText,
    Grid,
    Hidden,
    Paper,
    Slider,
    TextareaAutosize,
} from '@mui/material';
import { styled } from '@mui/styles';
import Link from '@mui/material/Link';
import PhoneInput from 'react-phone-input-2';
import { CustomButton } from './ButtonStyle';

export const NewTaskRibbon = styled('div')(({ theme }) => ({
    position: 'relative',
    '& .profile-photo': {
        '&::after': {
            right: 6,
            top: '1px'
        }
    },
    '& .pricing-card-coming-soon': {
        '&::after': {
            left: '112px',
            top: '1px'
        }
    },
    '& .wrap': {
        width: '100%',
        height: 188,
        position: 'absolute',
        //    top: '-8px',
        top: '-5px',
        overflow: 'hidden',
        '&::before': {
            //    content: '""',
            //    position: 'absolute',
            //    left: '0',
            //    width: '13px',
            //    height: '59px',
            //    background: 'rgb(150, 34, 74)',
            //    borderRadius: '0 0 0 39px'
        },
        '&::after': {
            content: '""',
            position: 'absolute',
            width: '0',
            height: '0',
            background: 'transparent',
            borderRadius: '8px 0px 0px 8px',
            borderLeft: `15px solid ${theme.palette.primary.main}`,
            borderTop: '21px solid transparent',
            borderBottom: '17px solid transparent',
            right: '74.5px'
        },
        '&.addRighPosition': { right: 7 }
    },
    '& .ribbonLabel': {
        top: '8px',
        left: '10px',
        color: 'white',
        width: '100px',
        height: '25px',
        overflow: 'hidden',
        position: 'absolute',
        fontSize: '0.8rem',
        background: theme.palette.primary.main,
        boxShadow: `${theme.palette.primary.main} 0px 0px 0px 3px, rgb(0 0 0 / 60%) 0px 21px 5px -18px`,
        textAlign: 'center',
        lineHeight: '22px'
    }
}));

export const CalBannerBox = styled(Box)(({ paddingvalue, img, customheight, theme }) => ({
    backgroundImage: `url(${img})` || `url(/img/banner.svg)`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    minheight: customheight || '71vh',
    padding: paddingvalue || '24px 0',
    // height: 698,
    width: '100%',
    // display: 'flex',
    justifyContent: 'center',
    '& .cal-title': {
        color: theme.palette.text.light
    }
}));
export const TitleBox = styled(Box)(({ theme }) => ({
    marginLeft: '130px',
    '& .MuiTypography-root': {
        color: theme.palette.text.light
    },
    [theme.breakpoints.down('md')]: {
        marginLeft: '10px'
    }
}));
export const CustomDivider = styled(Divider)(({ theme }) => ({
    width: 'inherit'
}));
export const WidgetCalDiv = styled('div')(({ theme }) => ({
    maxWidth: '100%',
    '& .MuiGrid-item': {
        maxWidth: 'inherit',
        padding: 4
    },
    '& $widgetCalCard': {
        margin: 0
    },
    '& .MuiToolbar-root': { minHeight: 115 },
    '& .tablesearchbox': { top: 'unset' }
}));
export const MainBox = styled(Box)(({ theme }) => ({
    //   margin:'auto'
    paddingBottom: 20,
    backgroundColor: theme.palette.grey['50']
}));
export const StyledDialog = styled(Dialog)(({ theme }) => ({
    zIndex: theme.zIndex.drawer
}));
export const ChartParentGrid = styled(Grid)(({ theme }) => ({
    //   margin:'auto'
    position: 'relative',
    top: 'auto',
    right: 'auto',
    width: 'auto',
    height: '500px',
    zIndex: '0',
    '& .chartWapper': {
        position: 'absolute',
        top: '2px',
        bottom: '63px',
        width: '100%'
    }
}));
export const CalBreadcrumbs = styled(Breadcrumbs)(({ theme }) => ({
    fontWeight: theme.typography.fontWeightBold,
    '& .current-menu': {
        fontWeight: theme.typography.fontWeightBold
    }
}));
export const CalAccordion = styled(Accordion)(({ theme }) => ({
    border: `1px solid ${theme.palette.text.dark}`,
    borderRadius: `${theme.borderRadius.fullround} !important`,
    '& .accordion-title': {},
    '& #mortalityAge': {
        paddingTop: '22px'
    },
    '& #moralityageSliderParent': {
        [theme.breakpoints.down('xs')]: {
            paddingTop: '22px',
            paddingLeft: 27
        }
    }
}));
export const AccordianGridItem = styled(Grid)(({ theme }) => ({
    margin: 'auto 0',
    paddingTop: '0 !important'
}));
export const OutputBox = styled(Box)(({ theme }) => ({
    border: `1px solid ${theme.palette.text.dark}`,
    borderRadius: `${theme.borderRadius.round}`,
    minHeight: 100,
    display: 'grid',
    textAlign: 'center',
    padding: '3px',
    '& .value-with-signal': {
        display: 'flex',
        justifyContent: 'center'
    }
}));
export const CalSignUpCard = styled(Card)(({ theme }) => ({
    textAlign: 'center',
    '& .signin-img': {
        margin: 'auto',
        width: 'unset'
    },
    '& .cal-signup-btn': {
        margin: 'auto',
        width: 170
    }
}));
export const LargeContainer = styled(Container)(({ theme }) => ({
    [theme.breakpoints.down('xl')]: {
        padding: '0 75px'
    },
    [theme.breakpoints.down('md')]: {
        padding: '0 24px'
    },
    [theme.breakpoints.down('sm')]: {
        padding: '0 16px'
    }
}));

export const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
    paddingTop: 0
}));
export const StyledAtag = styled('a')(({ theme }) => ({
    margin: '10px auto',
    display: 'flex',
    justifyContent: 'center'
}));
export const SampleFilePaper = styled(Paper)(({ theme }) => ({
    width: '130px',
    height: '113px',
    margin: '0 10px',
    border: `1px solid ${theme.palette.text.dark}`
}));
export const SampleFileParentBox = styled(Paper)(({ theme }) => ({
    display: 'flex',
    marginTop: '30px'
}));
export const StyledTextareaAutosize = styled(TextareaAutosize)(({ theme }) => ({
    width: '100%'
}));
export const UploadStyledAppBar = styled(AppBar)(({ theme }) => ({
    boxShadow: 'none'
}));
export const UploadStyledAccordion = styled(Accordion)(({ theme }) => ({
    margin: '14px 0',
    border: `1px solid ${theme.palette.text.dark}`,
    borderRadius: `${theme.borderRadius.round} !important`,
    '&::before': {
        height: 0
    }
}));
export const TopHeadingPaper = styled(Paper)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    height: '60px',
    margin: '0 0 6px 0',
    '& .top-heading-text': {
        margin: 'auto 30px'
    }
}));
export const StyledTableSlider = styled(Slider)(({ theme }) => ({
    color: theme.palette.primary.main,
    height: 4,
    padding: '10px 0'
    //    thumb: {
    //      height: 16,
    //      width: 16,
    //      backgroundColor: "#fff",
    //      border: "2px solid currentColor",
    //      marginTop: -7,
    //      marginLeft: -9,
    //      "&:focus, &:hover, &$active": {
    //        boxShadow: "inherit",
    //      },
    //    },
    //    valueLabel: {
    //      left: "calc(-50% + 4px)",
    //    },
    //    track: {
    //      height: 4,
    //      borderRadius: 3,
    //    },
    //    rail: {
    //      height: 4,
    //      borderRadius: 3,
    //    },
}));
export const UploadCardParent = styled(Card)(({ theme }) => ({
    margin: '0 0 6px 0',
    '&.top-heading-block': {
        height: '60px'
    },
    '&.portoutput': {
        width: '100%',
        margin: '0',
        '& .MuiGrid-root': {
            width: '100%',
            margin: 0,
            padding: '0px 5px 5px 3px'
        },
        '& .MuiCardHeader-root': {
            float: 'right',
            padding: '0'
        },
        '& .MuiTypography-root': {
            float: 'left',
            width: 'inherit'
        }
    }
}));
export const StyledBreadCrumbLink = styled(Link)(({ theme }) => ({
    display: 'flex',
    fontSize: 'inherit',
    fontWeight: 600,
    cursor: 'pointer',
    '& .MuiBadge-root': {
        marginRight: '20px'
    },
    '& svg': {
        marginLeft: '0.5vh'
    },
    "& .list-link": {
        maxWidth: 145,
        width: 'min-content',
    },
    '& .title-list': {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis'
    }
}));

export const RatingBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 10,
    alignItems: 'center',
    '& > * + *': {
        marginTop: theme.spacing(1)
    },
    '& .MuiRating-root': {
        color: '#ffb400',
        cursor: 'pointer',
        display: 'inline-flex',
        position: 'relative',
        textAlign: 'left',
        WebkitTapHighlightColor: 'transparent'
    },
    '& label': {
        fontSize: '1.5rem',
        color: 'unset'
    }
}));

export const StyledStatusChip = styled(Chip)(({ theme }) => ({
    '& svg': {
        color: 'inherit',
        width: '0.8em'
    },
    '&.success': {
        color: theme.palette.success.dark,
        backgroundColor: theme.palette.success[200],
        '&.billing-chip svg': {
            color: theme.palette.success.dark,
            backgroundColor: theme.palette.success[200]
        }
    },
    '&.fail': {
        color: theme.palette.error.dark,
        backgroundColor: theme.palette.error[200],
        '&.billing-chip svg': {
            color: theme.palette.success.dark,
            backgroundColor: theme.palette.success[200]
        }
    },
    '&.cancelChip': {
        fontSize: '0.7rem'
    }
}));
export const StyledPermissionDialog = styled(Dialog)(({ theme }) => ({
    top: 90,
    '& .MuiBackdrop-root': {
        top: 90
    },
    '&.MuiDialog-root': {
        zIndex: '100000 !important'
    }
}));
export const StyledCloseDialog = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end'
}));
export const StyledDialogContentBox = styled(Box)(({ theme }) => ({
    padding: 20
}));
export const FeedbackContainerBox = styled(Box)(({ theme }) => ({
    '& .feedbackbtn': {
        transform: 'rotate(-90deg) translate(50%, -50%)',
        transformOrigin: '100% 50%',
        padding: '0 16px 0 14px',
        right: '-2px',
        top: '56%',
        marginTop: '-20px',
        bottom: 'auto',
        animation: 'feedbackSlideLeft .2s linear',
        borderBottom: '2px solid transparent',
        boxShadow: '0 0 30px rgb(0 0 0 / 20%)',
        display: 'block',
        position: 'fixed',
        zIndex: '1100',
        height: '40px',
        lineHeight: '40px',
        '&:hover': {
            content: "''",
            // position: "absolute",
            bottom: '0',
            backgroundColor: 'rgba(17,129,21,1)'
        }
    }
}));
export const StyledPhoneInput = styled(PhoneInput)(({ theme }) => ({
    '&.profile-phone': {
        margin: 0
    },
    '&.profile-phone #react-tel-input': {
        border: 0,
        borderRadius: 0,
        borderBottom: `1px solid ${theme.palette.text.dark}`,
        marginTop: 10
    }
}));
export const WidgetBox = styled(Box)(({ theme }) => ({
    '& .widget-grid-item': {
        flex: 'inherit',
        maxWidth: '100%',
        width: 'inherit'
    },
    '& .widget-full-width': {
        '& .MuiGrid-item': {
            flex: 'inherit',
            maxWidth: '100%',
        }
    }
}));
export const StyledFormHelperText = styled(FormHelperText)(({ theme }) => ({
    marginLeft: '6px',
    color: theme.palette.error.main
}));
export const PermissionPaper = styled(Paper)(({ theme }) => ({
    padding: 10,
    "& .permissionGridParent": {
        display: 'block', textAlign: 'center'
    },
    '& .lockGrid': { textAlign: 'center', marginBottom: 10 }
}));
export const TooltipWithImg = styled('p')(({ theme }) => ({
    margin: '-5px 0 0 5px',
    height: 26
}));
export const ResetButton = styled(CustomButton)(({ theme }) => ({
    background: theme.palette.primary.main,
    color: theme.palette.text.light,
    justifyContent: 'center',
    display: 'flex',
    padding: '10px 30px',
    '&:hover': {
        background: theme.palette.background.paper
    }
}));
export const StyledTooltipHelpIconSpan = styled('span')(({ theme, customcolor = null }) => {
    return {
        position: 'relative',
        top: customcolor ? 10 : 5,
        '& svg': {
            color: `${customcolor || theme.palette.primary.main} !important`,
            width: '1.3rem',
            height: '1.3rem'
        }
    };
});
export const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        border: '1px solid red',
        marginRight: '3px',
        borderRadius: '12px',
        padding: '5px 12px',
        textTransform: 'uppercase',
        fontSize: '10px',
        fontWeight: '500',
        lineHeight: '1',
        color: '#fff',
        textAlign: 'center',
        whiteSpace: 'nowrap',
        verticalAlign: 'baseline',
        display: 'inline-block'
    }
}));