import { Card, CardContent, Grid, Typography } from '@mui/material';
import { CustomButton } from 'assets/jss/ButtonStyle';
import { AnnuityAmtGrid } from 'assets/jss/CommonCalStyle';
import { SliderParentGrid } from 'assets/jss/RetirementStyle';
import { TwoValSliderGrid } from 'assets/jss/RetirementStyle';
import { CalCardActions } from 'assets/jss/RetirementStyle';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { getDefaultOverride, submitAnnuityCal, submitAnnuityCalSuccess } from 'redux/actions/GeneralCalActions';
import RenderField from 'utils/RenderField';
import { numberWithCommas } from 'utils/Utils';
const minYear = new Date().getFullYear(),
    maxYear = new Date().getFullYear() + 100;
const fieldsDetail = [
    { name: 'Years', label: 'Start Year', label2: 'End Year', type: 'slider', min: minYear, max: maxYear },
    {
        name: 'Risk_Free_Rate',
        label: 'Risk Free Rate',
        required: true,
        type: 'number',
        commaSep: true,
        custompattern: 'decimalRate',
        helperText: '(in Decimal)',
        placeholder: '0.03',
        customValidateLimit: 10
    },
    { name: 'Risk_Addon', label: 'Risk Addon', type: 'number', commaSep: true, custompattern: 'decimalRate', placeholder: '100,000' },
    { name: 'Infl_rate', label: 'Inflation', type: 'number', commaSep: true, custompattern: 'decimalRate', placeholder: '100,000' },
    {
        name: 'annuity_inflation_adj',
        label: 'Annuity Inflation Adj',
        type: 'number',
        commaSep: true,
        custompattern: 'decimalRate',
        placeholder: '100,000'
    },
    {
        label: 'Annuity Amount',
        child: [
            { name: 'Monthly_Annuity_Amount_slider', label: 'Amount', type: 'slider', min: 0, max: 200000 },
            {
                name: 'Monthly_Annuity_Amount',
                label: 'Amount',
                type: 'number',
                commaSep: true,
                custompattern: 'price',
                placeholder: '100,000',
                customValidateLimit: 200000
            },
            { fieldlabel: 'per' },
            {
                name: 'income_period',
                label: 'Period',
                options: [
                    { label: 'Yearly', value: 'yearly' },
                    { label: 'Monthly', value: 'monthly' }
                ],
                type: 'select',
                disableEmptyValue: true
            }
            // { name: "monthly_amt", label: "Per Month", disabled: true, type: "number", custompattern: 'decimalRate' }
        ]
    }
];
const AnnuityForm = ({ classes, annuityCalData, defaultoverrideData }) => {
    const dispatch = useDispatch();
    const {
        handleSubmit,
        control,
        formState: { isDirty, isSubmitting },
        setValue
    } = useForm({
        mode: 'onChange',
        defaultValues: {
            Monthly_Annuity_Amount_slider: 1379,
            Monthly_Annuity_Amount: 1379,
            Years: [2032, 2061],
            Risk_Free_Rate: 2,
            Risk_Addon: 0,
            Infl_rate: 2,
            annuity_inflation_adj: 1,
            income_period: 'monthly'
            //monthly_amt: 1379
        }
    });
    useEffect(() => {
        if (Object.keys(defaultoverrideData).length) {
            setValue('Infl_rate', defaultoverrideData.inflation);
        }
    }, [defaultoverrideData]);
    //to submit the form data
    const onSubmit = (data) => {
        const monAnn = data.Monthly_Annuity_Amount?.toString().replace(/,/g, '') || data.Monthly_Annuity_Amount;
        const objToSend = {
            Monthly_Annuity_Amount: data.Monthly_Annuity_AmountUnFormattedValue || monAnn,
            Start_Year: data.Years[0],
            End_Year: data.Years[1],
            Risk_Free_Rate: data.Risk_Free_RateUnFormattedValue || data.Risk_Free_Rate,
            Risk_Addon: data.Risk_AddonUnFormattedValue || data.Risk_Addon,
            Infl_rate: data.Infl_rateUnFormattedValue || data.Infl_rate,
            annuity_inflation_adj: data.annuity_inflation_adjUnFormattedValue || data.annuity_inflation_adj,
            income_period: data.income_period
        };
        dispatch(submitAnnuityCal(objToSend));
    };
    // when any input field value will change it will call below method
    const handleFormInputChange = (e, name = null) => {
        if (name === 'Monthly_Annuity_Amount_slider' || name === 'Monthly_Annuity_Amount') {
            const fieldVal = e?.target?.value.replace(/,/g, '') || e;
            if (name === 'Monthly_Annuity_Amount_slider') {
                setValue('Monthly_Annuity_Amount', numberWithCommas(fieldVal));
            } else {
                const unformattedval = fieldVal.toString().replace(/,/g, '');
                setValue('Monthly_Annuity_Amount_slider', parseInt(unformattedval));
            }
        }
        if (isDirty && annuityCalData && Object.keys(annuityCalData).length > 0) {
            // dispatch(submitAnnuityCalSuccess({}));
        }
    };
    useEffect(() => {
        dispatch(getDefaultOverride());
        // reset output when route will change
        return () => {
            dispatch(submitAnnuityCalSuccess({}));
        };
    }, []);

    return (
        <Card elevation={2}>
            <CardContent>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container>
                        {fieldsDetail.map((val, i) => {
                            if (val.child) {
                                return (
                                    <>
                                        {/* <CustomDivider /> */}
                                        <Grid item xs={12} sx={{ mb: 1 }} key={i + 'Monthly_Annuity_Amount'} mt={1}>
                                            <Typography className="bold-font"> {val.label}</Typography>
                                        </Grid>
                                        <AnnuityAmtGrid container>
                                            <Grid item xs={12} className={classes?.amountSlider}>
                                                <RenderField
                                                    handleOnChange={(e) => handleFormInputChange(e, 'Monthly_Annuity_Amount_slider')}
                                                    name={'Monthly_Annuity_Amount_slider'}
                                                    control={control}
                                                    label={'Amount'}
                                                    id={'Monthly_Annuity_Amount_slider'}
                                                    type={'slider'}
                                                    setValue={setValue}
                                                    valueLabelDisplay="on"
                                                    min={0}
                                                    max={200000}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={5}>
                                                <RenderField
                                                    handleOnChange={(e) => handleFormInputChange(e, 'Monthly_Annuity_Amount')}
                                                    name={'Monthly_Annuity_Amount'}
                                                    control={control}
                                                    label={'Amount'}
                                                    id={'Monthly_Annuity_Amount'}
                                                    type={'number'}
                                                    required={true}
                                                    thousandSeparator={true}
                                                    customPatternName={'price'}
                                                    placeholder={'100,000'}
                                                    setValue={setValue}
                                                    customValidateLimit={200000}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={2} className={'annuityAmountLbl'}>
                                                <Typography gutterBottom variant="body2">
                                                    per
                                                </Typography>{' '}
                                            </Grid>
                                            <Grid item xs={12} sm={5}>
                                                <RenderField
                                                    handleOnChange={(e) => handleFormInputChange(e, 'income_period')}
                                                    name={'income_period'}
                                                    control={control}
                                                    label={'Period'}
                                                    id={'income_period'}
                                                    type={'select'}
                                                    options={[
                                                        { label: 'Yearly', value: 'yearly' },
                                                        { label: 'Monthly', value: 'monthly' }
                                                    ]}
                                                    required={true}
                                                    setValue={setValue}
                                                />
                                            </Grid>
                                        </AnnuityAmtGrid>
                                    </>
                                );
                            } else {
                                if (val.type === 'slider') {
                                    return (
                                        <Grid item xs={12} key={i + 'annuityform_slider'}>
                                            <SliderParentGrid container>
                                                <Grid item xs={12} md={5} lg={5}>
                                                    <Typography gutterBottom className="bold-font">
                                                        {val.label}
                                                    </Typography>
                                                </Grid>
                                                {val.label2 ? (
                                                    <Grid item xs={12} md={5} lg={5} textAlign={'end'}>
                                                        <Typography gutterBottom className="bold-font">
                                                            {val.label2}
                                                        </Typography>
                                                    </Grid>
                                                ) : (
                                                    ''
                                                )}
                                                <TwoValSliderGrid item xs={12}>
                                                    <RenderField
                                                        type="slider"
                                                        control={control}
                                                        name={val.name}
                                                        id={val.name}
                                                        max={val.max}
                                                        min={val.min}
                                                        valueLabelDisplay="on"
                                                        handleOnChange={handleFormInputChange}
                                                        {...val}
                                                    />
                                                </TwoValSliderGrid>
                                            </SliderParentGrid>
                                        </Grid>
                                    );
                                }
                            }
                            return (
                                <Grid item xs={12} sm={6} key={i + 'lifeInsForm'}>
                                    <RenderField
                                        handleOnChange={handleFormInputChange}
                                        name={val.name}
                                        control={control}
                                        label={val.label}
                                        id={val.name}
                                        type={val.type}
                                        required={val.required || true}
                                        thousandSeparator={val.commaSep}
                                        customPatternName={val.custompattern}
                                        placeholder={val.placeholder}
                                        setValue={setValue}
                                        {...val}
                                    />
                                </Grid>
                            );
                        })}
                    </Grid>
                    <CalCardActions className="contactus-cardaction">
                        <CustomButton
                            type="submit"
                            ps={'9px 30px'}
                            variant="contained"
                            // color="rose"
                            disabled={isSubmitting}
                        >
                            Calculate
                        </CustomButton>
                    </CalCardActions>
                </form>
            </CardContent>
        </Card>
    );
};
export default AnnuityForm;
