import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import store from 'store';
import AppTheme from 'views/Routes/AppTheme';
import { createRoot } from 'react-dom/client';
import 'assets/scss/style.scss';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
const stripePromise = loadStripe(
    'pk_test_51KqJflLHYXguTXnfUrthObjqjyutisukEspYPSzsHbSqqOekT26Jjq23hvpGIBFDUEFGyjJAhBOdv9DOKeaoxNad00MRBXahLQ'
);
const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
    <Provider store={store}>
        <BrowserRouter>
            <Elements stripe={stripePromise}>
                <AppTheme />
            </Elements>
        </BrowserRouter>
    </Provider>
);
