import { Box, Button, Card, CardActions, CardHeader, Grid, Typography } from '@mui/material';
import { styled } from '@mui/styles';
export const DropzoneTextBox = styled(Box)(({ ps, theme }) => {
           return {
            margin:'auto',
            textAlign:'center'
           };
       });
export const StyledCardHeader = styled(CardHeader)(({ ps, theme }) => {
           return {
               padding: '0',
                        position: 'absolute',
                        right: '0'
           };
       });
export const DropzoneCardActions = styled(CardActions)(({ ps, theme }) => {
           return {
               padding: '0 7px',
               position: 'absolute',
               bottom: '4px',
               width: 'inherit',
               '& .tooltip-html-label': {
                   width: 'inherit'
               },
               '& .tooltip-html-button': {
                   width: 'inherit'
               },
               '& .tooltip-html-text': {
                   display: 'block',
                   textOverflow: 'ellipsis',
                   overflow: 'hidden'
               }
           };
       });
export const DropzonePreviewCard = styled(Card)(({ ps, theme }) => {
           return {
               margin: '24px 16px 15px',
                        width: '104px',
                        height: '128px',
                        color: '#6c757d',
                        whiteSpace: 'nowrap',
                        position: 'relative'
           };
       });
export const PreviewFileGrid = styled(Grid)(({ ps, theme }) => {
           return {
               '& .MuiGrid-spacing-xs-8 > .MuiGrid-item': {
             padding: '32px'
         },
         '& .MuiDropzonePreviewList-image': {
             height: 'auto',
             color: 'rgba(0, 0, 0, 0.87)',
             width: 'initial',
             maxWidth: '100%'
         },
         '& .MuiDropzonePreviewList-removeButton': {
             padding: '10px 10px 0px 1px',
             color: theme.palette.secondary.main,
             zIndex: 2
         }
           };
       });

//  const reactDropZoneStyle = {
//      previewContainer: {
//          zIindex: 10,
//          position: 'relative',
//          textAlign: 'center'
//      },
//      root: {
//          '& .MuiGrid-spacing-xs-8 > .MuiGrid-item': {
//              padding: '32px'
//          },
//          '& .MuiDropzonePreviewList-image': {
//              height: 'auto',
//              color: 'rgba(0, 0, 0, 0.87)',
//              width: 'initial',
//              maxWidth: '100%'
//          },
//          '& .MuiDropzonePreviewList-removeButton': {
//              padding: '10px 10px 0px 1px',
//              color: roseColor,
//              zIndex: 2
//          }
//      },
//      cardHeader: {
//          padding: '0',
//          position: 'absolute',
//          right: '0'
//      },
//      gridItem: {
//          top: '12px'
//      },
//      container: {
//          // margin: "inherit",
//          margin: 0
//      },
//      paper: {
//          margin: '24px 16px 15px',
//          width: '104px',
//          height: '128px',
//          color: '#6c757d',
//          whiteSpace: 'nowrap',
//          position: 'relative'
//      },
//      cardFooter: {
//          padding: '0 7px',
//          position: 'absolute',
//          bottom: '4px',
//          width: 'inherit'
//      },
//      fileName: {
//          display: 'block',
//          textOverflow: 'ellipsis',
//          overflow: 'hidden'
//      },
//      tooltip: {
//          wordWrap: 'break-word'
//      }
//  };

