import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Box, Typography, useTheme } from '@mui/material';
import { CustomButton } from 'assets/jss/ButtonStyle';
import { RangeButton, WatchListChartPaper } from 'assets/jss/DashboardStyle';
import { ChartCardParent } from 'assets/jss/PortfolioStyle';
import AreaCharts from 'components/Charts/AreaChart';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Label } from 'recharts';

const WatchListChart = (props) => {
    const [selectedRange, setSelectedRange] = useState('1m');
    const theme = useTheme();
    const rangeList = [
        { label: '5 Days', value: '5d' },
        { label: '1 Month', value: '1m' },
        { label: '6 Months', value: '6m' },
        { label: '1 Year', value: '1y' },
        { label: '3 Years', value: '3y' },
        { label: '5 Years', value: '5y' }
    ];
    const [chartData, setChartData] = useState([]);
    const [rangeMax, setRangeMax] = useState(0);
    const { secondary, warning, success, grey } = theme.palette;
    const equityDashboardData = useSelector((state) => state.equityDashboard);
    const isMobile = window.innerWidth < 768;
    const chartWidth = isMobile ? window.innerWidth * 0.9 : window.innerWidth * 0.88;

    // useEffect(() => {
    //     const a=[];
    //     if(equityDashboardData.chartdata.length){
    //  const formatedData = equityDashboardData.chartdata.map((val) => {
    //      a.push(val.close);
    //      return { x: val.date, y: val.close };
    //  });
    //  setRangeMax(Math.min(...a));
    //     setChartData([{ id: props.chartdetails.symbol, data: formatedData }]);
    // }
    // }, [equityDashboardData.chartdata])

    useEffect(() => {
        const closeValues = [];
        if (equityDashboardData.chartdata.length) {
            const formatedData = equityDashboardData.chartdata.map((val) => {
                closeValues.push(val.close);
                return {
                    name: moment(val.Date).format('YY MMM'),
                    [props.chartdetails.symbol]: val.close
                };
            });
            setRangeMax(Math.min(...closeValues));
            setChartData(formatedData);
        }
    }, [equityDashboardData.chartdata]);

    const isAnnualRangeSelected = ['1y', '3y', '5y'].includes(selectedRange) || false;
    const priceChangeColor = Math.sign(props.chartdetails['Daily Price Change %']) === 1 ? 'text.success' : 'text.error';

    // useEffect(() => {
    //     const handleResize = () => {
    //         setChartWidth(window.innerWidth * 0.8);
    //     };

    //     window.addEventListener('resize', handleResize);

    //     return () => {
    //         window.removeEventListener('resize', handleResize);
    //     };
    // }, []);

    const formatValue = (value) => {
        if (value >= 1e9) {
            return `${(value / 1e9).toFixed(1)}B`;
        } else if (value >= 1e6) {
            return `${(value / 1e6).toFixed(1)}M`;
        } else if (value >= 1e3) {
            return `${(value / 1e3).toFixed(1)}K`;
        }
        return value;
    };
    return (
        <WatchListChartPaper elevation={2}>
            <CustomButton
                variant="text"
                startIcon={<ArrowBackIosIcon />}
                className="read-more back-button"
                onClick={() => props.backButtonClicked()}
            >
                Back to table
            </CustomButton>
            <Box m={'16px 0 0 30px'}>
                <Typography variant="h5" gutterBottom>
                    {props.chartdetails['Company Name']
                        ? `${props.chartdetails['Company Name']} (${props.chartdetails.symbol})`
                        : props.chartdetails.symbol.toUpperCase()}
                </Typography>
                <Box display={'flex'}>
                    {/* <Typography variant="h3"> {props.chartdetails['Latest Price']}</Typography> */}
                    <Typography variant="h3">
                        {chartData && chartData[0]?.data?.[chartData[0].data.length - 1]?.y
                            ? chartData[0]?.data?.[chartData[0].data.length - 1]?.y
                            : props.chartdetails['Latest Price']}
                    </Typography>
                    <Typography m={'15px 0 0 15px'} color={priceChangeColor}>
                        {props.chartdetails['Daily Price Change %']?.toFixed(2)} %
                    </Typography>
                </Box>
            </Box>
            <Box textAlign={'center'}>
                {rangeList.map((val) => (
                    <RangeButton
                        variant="text"
                        onClick={() => {
                            setSelectedRange(val.value);
                            props.rangeSelectionClicked(val.value);
                        }}
                        className={`${selectedRange === val.value && 'selected'}`}
                    >
                        {val.label}
                    </RangeButton>
                ))}
            </Box>
            <ChartCardParent className="leave-space-dash" customheight={'450px'}>
                {/* <AreaCharts
                    dateType={true}
                    data={chartData}
                    enableArea={false}
                    enablePoint={false}
                    areamin={rangeMax}
                    rotateLabel={true}
                    isDateAxis={true}
                    annualData={isAnnualRangeSelected}
                    //   halfGraph={true}
                    customColor={[secondary.main, warning.dark, success.main, grey[500]]}
                    curveType={'linear'}
                /> */}

                <LineChart width={chartWidth} height={430} data={chartData || []}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" height={80} tick={{ angle: -45, textAnchor: 'middle' }} dy={10} />
                    <YAxis yAxisId="left" domain={[rangeMax, 'auto']} tickFormatter={formatValue} />
                    <YAxis yAxisId="right" orientation="right" tickFormatter={formatValue} />
                    <Tooltip />
                    {/* <Legend /> */}
                    <Line yAxisId="left" type="monotone" dataKey={props.chartdetails.symbol} stroke="blue" activeDot={{ r: 8 }} />
                </LineChart>
            </ChartCardParent>
        </WatchListChartPaper>
    );
};

export default WatchListChart;
