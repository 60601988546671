import React, { useState } from 'react';
import { Avatar, Collapse, Drawer, IconButton, List, ListItemIcon, Tooltip } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import MenuIcon from '@mui/icons-material/Menu';
import { CustomListItemText } from 'assets/jss/HeaderStyle';
import { CustomListItem } from 'assets/jss/HeaderStyle';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { hasChildren } from 'utils/Utils';
import { toolsDetails } from 'views/LandingPage/ToolDetails';
import LoginIcon from '@mui/icons-material/Login';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import { Box } from '@mui/system';
import { handleLogout } from 'redux/actions/AuthActions';
import { useDispatch } from 'react-redux';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { useSelector } from 'react-redux';

const ProfileDetails = [
    {
        parent: 'Avatar',
        children: [
            { to: '/settings', name: 'Profile', image: '' },
            { to: '/settings', name: 'Setting', image: '' },
            { to: '', name: 'logout', image: '' }
        ]
    }
];
const MenuItemComp = ({ item, ...rest }) => {
    const Component = hasChildren(item) ? MultiLevel : SingleLevel;
    return <Component item={item} rest={rest} />;
};

const MultiLevel = ({ item, rest }) => {
    const { children } = item;
    const [open, setOpen] = useState(false);

    const handleClick = () => {
        setOpen((prev) => !prev);
    };
    const menulabel = item.parent || item.title;
    return (
        <React.Fragment>
            {menulabel === 'Avatar' ? (
                <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center', marginLeft: 'auto' }}>
                    <Tooltip title="Account settings">
                        <IconButton onClick={handleClick} size="small" sx={{ ml: 2 }}>
                            <Avatar sx={{ width: 32, height: 32 }}>{rest.username}</Avatar>
                        </IconButton>
                    </Tooltip>
                </Box>
            ) : (
                <CustomListItem button onClick={handleClick}>
                    {menulabel === 'Calculators' ? (
                        <ListItemIcon>
                            <img src="img/calculator_menu.png" alt="calculator menu" width={20} height={20} />
                        </ListItemIcon>
                    ) : menulabel === 'Dashboards' ? (
                        <ListItemIcon>
                            <DashboardIcon />
                        </ListItemIcon>
                    ) : (
                        <ListItemIcon></ListItemIcon>
                    )}
                    <CustomListItemText primary={menulabel} />
                    {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </CustomListItem>
            )}
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {children.map((child, key) => {
                        return <MenuItemComp key={key} item={child} {...rest} />;
                    })}
                </List>
            </Collapse>
        </React.Fragment>
    );
};
const SingleLevel = ({ item, ...rest }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const handleMenuClick = (menulabel) => {
        if (menulabel === 'logout') {
            dispatch(handleLogout(navigate));
        }
        if (rest.rest.passSetOpenDrawer) rest.rest.passSetOpenDrawer(false);
    };
    const menulabel = item.parent || item.name;
    return (
        <CustomListItem button component={RouterLink} to={item.to}>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <CustomListItemText primary={menulabel} onClick={() => handleMenuClick(menulabel)} />
        </CustomListItem>
    );
};
const DrawerComp = ({ ListItemLink, apiToken, username, handleTour }) => {
    const [openDrawer, setOpenDrawer] = useState(false);
    const setting = useSelector((state) => state.setting);

    const closeDrawer = () => setOpenDrawer(false);
    return (
        <>
            <Drawer anchor="right" open={openDrawer} onClose={closeDrawer}>
                <List>
                    {toolsDetails.map((val, i) => {
                        return <MenuItemComp key={i + '_mobiparentMenus'} item={val} passSetOpenDrawer={closeDrawer} />;
                    })}
                </List>
                {window.location.pathname.length === 1 && setting.profileDetail.role === 'admin' && (
                    <button
                        style={{
                            padding: '10px',
                            backgroundColor: 'transparent',
                            border: '1px solid black',
                            borderRadius: '10px',
                            cursor: 'pointer',
                            marginLeft: '10px'
                        }}
                        onClick={() => {
                            closeDrawer();
                            handleTour();
                        }}
                    >
                        Take a tour
                    </button>
                )}

                {apiToken ? (
                    <List>
                        {ProfileDetails.map((val, i) => {
                            return (
                                <MenuItemComp
                                    key={i + '_mobiprofileMenus'}
                                    item={val}
                                    username={username}
                                    passSetOpenDrawer={closeDrawer}
                                />
                            );
                        })}
                    </List>
                ) : (
                    <>
                        <List>
                            <CustomListItem button>
                                <ListItemIcon>
                                    <LoginIcon />
                                </ListItemIcon>
                                <ListItemLink to={'/login'} primary={'Login'} />
                            </CustomListItem>
                        </List>
                        <List>
                            <CustomListItem button>
                                <ListItemIcon>
                                    <AppRegistrationIcon />
                                </ListItemIcon>
                                <ListItemLink to={'/sign-up'} primary={'Sign Up'} />
                            </CustomListItem>
                        </List>
                    </>
                )}
            </Drawer>
            <IconButton sx={{ marginLeft: 'auto' }} onClick={() => setOpenDrawer(!openDrawer)}>
                <MenuIcon />
            </IconButton>
        </>
    );
};

export default DrawerComp;
