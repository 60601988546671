import { Divider, Grid, IconButton, InputAdornment, Typography } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { submitResetPass } from 'redux/actions/SettingActions';
import RenderField from 'utils/RenderField';
import { PasswordGrid } from 'assets/jss/SettingsStyle';
import { CustomButton } from 'assets/jss/ButtonStyle';
export const ResetPasswordIndex = (props) => {
    const { setting } = props;
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = (e) => setShowPassword({ ...showPassword, [e]: !showPassword[e] });
    const dispatch = useDispatch();
    const {
        handleSubmit,
        control,
        getValues,
        reset,
        formState: {  isSubmitting },
        setValue
    } = useForm({
        mode: 'onChange',
        defaultValues: {
            email: '',
            oldpass: '',
            newpass: ''
        }
    });
    useEffect(() => {
        if (Object.keys(setting.profileDetail).length > 0) {
            const data = setting.profileDetail;
            setValue('email', data.email);
        }
    }, [setting.profileDetail]);
    const onSubmit = (data) => {
        if (data.newpass === data.oldpass) {
            toast.error('You have already used that password or new password should not match with old, try another.');
            return;
        }
        dispatch(submitResetPass(data));
        reset({ email: data.email, oldpass: '', newpass: '' });
    };

    return (
        <>
            <Typography variant={'subheading2'}>Reset password</Typography>
            <Divider sx={{ marginTop: '8px' }} />
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <RenderField
                            name="email"
                            control={control}
                            label="Email"
                            id="reset_email"
                            type="text"
                            disabled
                            required
                            customPatternName="email"
                            placeholder="Email"
                        />
                    </Grid>
                    <PasswordGrid item xs={12} sm={5}>
                        <RenderField
                            name="oldpass"
                            control={control}
                            label="Old Password"
                            asterisk
                            id="oldpass"
                            type={showPassword['oldpass'] ? 'text' : 'password'}
                            required
                            customPatternName="password"
                            helperText="Use 8 or more characters with a mix of capital and small letters, numbers & symbols"
                            placeholder="Old Password"
                            CustomInputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={(e) => handleClickShowPassword('oldpass')}
                                        >
                                            {showPassword['oldpass'] ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </PasswordGrid>
                    <PasswordGrid item xs={12} sm={5}>
                        <RenderField
                            name="newpass"
                            control={control}
                            label="New Password"
                            asterisk
                            id="newpass"
                            type={showPassword['newpass'] ? 'text' : 'password'}
                            required
                            getValues={getValues}
                            customPatternName="password"
                            placeholder="New Password"
                            helperText="Use 8 or more characters with a mix of capital and small letters, numbers & symbols"
                            CustomInputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={(e) => handleClickShowPassword('newpass')}
                                        >
                                            {showPassword['newpass'] ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                                autoComplete: 'off'
                            }}
                        />
                    </PasswordGrid>
                    <Grid item xs={12} sm={2}>
                        <CustomButton
                            type="submit"
                            ps={'9px 30px'}
                            variant="contained"
                            // color="rose"
                            disabled={isSubmitting}
                        >
                            Reset
                        </CustomButton>
                    </Grid>
                </Grid>
            </form>
        </>
    );
};
