import Typography from '@mui/material/Typography';
import React, { useEffect, useRef, useState } from 'react';
import { Box, ClickAwayListener, Fade, Grid, Popper } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import { CalBox, CalCard, CalculatorBox } from 'assets/jss/CalculatorStyle';
import { CustomImg, DashCard, DashGrid } from 'assets/jss/LandingStyle';
import { useNavigate } from 'react-router-dom';
import { toolsDetails } from './ToolDetails';

const CalculatorBlock = () => {
    const [openCard, setOpenCard] = useState({});
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const [placement, setPlacement] = React.useState();
    const handleCardSelection = (event, val, newPlacement) => {
        setOpenCard(val);
        setAnchorEl(event.currentTarget);
        setOpen(true);
        setPlacement(newPlacement);
    };
    useEffect(() => {
        const handleClickOutside = (event) => {
            const currentEleName = event.target.closest('.MuiPaper-root')?.getAttribute('data-title');
            if (
                !event.target.classList.contains(`parentMenu_${currentEleName}`) &&
                (event.target.classList.contains('cardSubGridItem') ||
                    event.target.classList.contains('forwardRef(Box)-root-2') ||
                    event.target.classList.contains('cardGridItem') ||
                    event.target.classList.contains('calBlock') ||
                    event.target.classList.contains('manwithlaptop') ||
                    event.target.classList.contains('man-laptop-img') ||
                    event.target.classList.contains('title-box') ||
                    event.target.classList.contains('cardGrid'))
            ) {
                setOpen(false);
                setOpenCard({});
            }
        };

        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [openCard]);
    return (
        <CalculatorBox textalign="left" style={{ minHeight: open ? 360 : 0 }} className="calBlock" onMouseLeave={() => setOpen(false)}>
            <Box className="title-box">
                <Typography variant="h3" sx={{ mt: 3.5, display: 'inline-block' }}>
                    TPE Calculators
                </Typography>
                {/* <Typography variant="heading" className="dashboard-subheading" sx={{ mb: 1.5 }}>
                    To make your life easier with simplistic view
                </Typography> */}
            </Box>
            <Grid container spacing={2} className="grid-container cardGrid">
                {/* <Slider {...settings}> */}
                {toolsDetails[0].children.map((val, i) => {
                    return (
                        <DashGrid
                            id={`calc-${i}`}
                            item
                            xs={4}
                            sm={2}
                            md={1.5}
                            disablepadd={true}
                            key={i + '_calblock_parentmenu'}
                            className="cardGridItem"
                        >
                            <CalCard
                                key={i + 'cal-parent-list'}
                                data-title={val.title}
                                onMouseEnter={(e) => handleCardSelection(e, val, 'bottom-start')}
                                // onMouseEnter={(e) => handleCardSelection(e, val, 'bottom-start')}
                                // onMouseLeave={(e) => handleCardSelection(e, {}, 'bottom-start')}
                                className={`${val.title === openCard?.title && 'selected'} parentMenu_${val.title}`}
                            >
                                <CustomImg src={`img/${val.image}`} alt="green iguana" />
                                <CardContent>
                                    <Typography gutterBottom variant="body1" component="div">
                                        {val.title}
                                    </Typography>
                                    {/* <Typography variant="cardSubHeader">
                                            A loan term is the duration of the loan, given that required minimum payments are made each
                                            month.
                                        </Typography> */}
                                </CardContent>
                            </CalCard>
                        </DashGrid>
                    );
                })}
                {/* </Slider> */}
            </Grid>
            {Object.keys(openCard).length > 0 ? (
                <Popper
                    open={open}
                    anchorEl={anchorEl}
                    placement={placement}
                    transition
                    sx={{ mt: '50px !important' }}
                    // paperProps={{ onMouseLeave: (e) => console.log(e, 'eeee popper') }}
                >
                    {({ TransitionProps }) => (
                        // <ClickAwayListener onClickAway={(e) => {
                        //     console.log('=-=--=')
                        //     handleCardSelection(e, openCard, 'bottom-start');}
                        // }>
                        <Fade {...TransitionProps} timeout={350}>
                            <Grid container spacing={2} className="grid-container  cardSubGridItem">
                                {openCard.children.map((val, i) => {
                                    const iconurl = val.image || 'loanicon.svg';
                                    return (
                                        <DashGrid
                                            item
                                            sx={{ mx: 1 }}
                                            key={i + '_calblock_subchildmenu'}
                                            onClick={() => navigate(val.to)}
                                            className="calBlock subMenu"
                                        >
                                            <DashCard elevation={1} bgcolor={true}>
                                                <CustomImg src={`img/${iconurl}`} alt="green iguana" />
                                                <CardContent>
                                                    <Typography gutterBottom variant="cardHeader" component="div">
                                                        {val.name}
                                                    </Typography>
                                                </CardContent>
                                            </DashCard>
                                        </DashGrid>
                                    );
                                })}
                            </Grid>
                        </Fade>
                        //  </ClickAwayListener>
                    )}
                </Popper>
            ) : (
                ''
            )}
            <CalBox className="manwithlaptop">
                {open && <img src="img/man-laptop.svg" style={{ height: 140 }} alt="tpe user laptop" className="man-laptop-img" />}{' '}
            </CalBox>
        </CalculatorBox>
    );
};

export default CalculatorBlock;
