import React from 'react';
import { Card, CardContent, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { QuesAnsBox } from 'assets/jss/RetirementStyle';
import { getCookie } from 'utils/Utils';
import CalSignupBlock from 'views/RetirementCalculator/CalSignupBlock';
const PeerToPeerDesc = ({ classes, control, handleOnChange, setValue, ...rest }) => {
    return (
        <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12} md={(getCookie('auth_token') && 12) || 8}>
                <Card>
                    <CardContent>
                        <QuesAnsBox>
                            <Typography variant="h2" id="description" sx={{ mb: 2, display: 'flex' }}>
                                About peer to peer calculator
                            </Typography>
                            <Typography variant="body1" className="dashboard-subheading" sx={{ mb: 1.5 }}>
                                This calculator allows related users (either friends or family) to connect online and negotiate a bilateral
                                (i.e., peer to peer) loan. Think of the rich uncle who wants to pay for his niece’s dental school but can’t
                                afford or doesn’t want to make a gift. Perhaps he is willing to lend his niece at a much better rate and
                                terms than she can get elsewhere. This tool allows the pair to negotiate and agree on loan terms in a
                                non-confrontational, transparent way. In this way, the burden of debt is reduced, and the uncle gets to
                                support his niece in win-win way – she gets the money on better returns, and he earns a return while at the
                                same time helping his niece. Our tool allows the uncle and his niece to run different loan terms and
                                ultimately agree on final terms via the app.
                            </Typography>
                            <Typography variant="body1" className="dashboard-subheading" sx={{ mb: 1.5 }}>
                                Further, once the loan terms are finalized, they can manage the loan via TPE including managing repayment
                                and any need to restructure the loan (these latter features future enhancements).
                            </Typography>
                            <Typography variant="body1" className="dashboard-subheading" sx={{ mb: 1.5 }}>
                                The process involves connecting your TPE account to your contact via email authentication. Both users must
                                be registered. The loan must be fixed rate but can be any maturity/term and can be interest only or
                                principal and interest.
                            </Typography>
                            <Typography variant="body1" className="dashboard-subheading" sx={{ mb: 1.5 }}>
                                To start the process of agreeing on loan terms either the borrower or lender would send a request to the
                                other party. Simply enter the user’s email in the collaborator box and click the submit button. If the other
                                party is registered with TPE they will receive the request and reply. Once the other party accepts the
                                invitation, the initiating party will be informed that their connection has been completed.
                            </Typography>
                            <Typography variant="body1" className="dashboard-subheading" sx={{ mb: 1.5 }}>
                                Now the fun begins. The borrower or lender will use our calculator to share recommended loan terms. The
                                other party can then either accept, reject, or revise the terms. Once this back and forth concludes with
                                agreed loan terms, the parties formally agree and a loan certificate is created to document the loan. TPE
                                does not get involved in the actual movement of money or processing the loan. We merely provide a platform
                                for the two parties to use a real calculator and real numbers to determine an acceptable and affordable loan
                                rate and to create a glidepath to negotiation that would otherwise be impossible absent the proper tools.
                                The parties can also use our terrific, fixed income and loan dashboards to guide them determining
                                appropriate fair terms.
                            </Typography>
                        </QuesAnsBox>
                    </CardContent>
                </Card>
            </Grid>
            {!getCookie('auth_token') && (
                <Grid item xs={12} md={4}>
                    <CalSignupBlock />
                </Grid>
            )}
        </Grid>
    );
};

export default PeerToPeerDesc;
