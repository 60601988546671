import * as ActionTypes from "./ActionTypes";
import { apiCall, handleError } from "utils/Utils";
import Globals from "Globals.js";
import { toast } from "react-toastify";
/**
 * @author Leena
 * @param {*} data
 * @use to fetch data of equity dashboard
 * @returns
 */
export const fetchCollegeData = (data) => async dispatch => {
    try {
        dispatch({ type: ActionTypes.FETCH_COLLEGE_CAL_INIT });
        const apiResponse = await apiCall(`${Globals.API_ROOT_URL}college_tool`, 'post', data);
        if (apiResponse !== undefined && apiResponse.status === 200) {
          dispatch(fetchCollegeCalSuccess(apiResponse.data));
        } else {
          handleError(apiResponse, dispatch);
          dispatch(fetchCollegeCalError());
        }
    } catch (error) {
        toast.error(Globals.errorMsg);
        dispatch(fetchCollegeCalError());
        throw error;
    }
}

//handle success of api
export const fetchCollegeCalSuccess = val => {
    return {
        type: ActionTypes.FETCH_COLLEGE_CAL_SUCCESS,
        data: val
    };
};

// handle api error
export const fetchCollegeCalError = () => {
    return {
        type: ActionTypes.FETCH_COLLEGE_CAL_ERROR
    };
};

//handle success of api

export const SearchSchoolSuccess = val => {
    return {
        type: ActionTypes.SEARCH_SCHOOL_NAMES,
        data: val
    };
};


// handle api error
export const fetchAllSchoolError = () => {
    return {
        type: ActionTypes.GET_SCHOOL_NAMES_ERROR
    };
};

export const SearchSchoolName = (data) => async dispatch => {
    try {
        dispatch({
            type: ActionTypes.GET_SCHOOL_NAMES_INIT
        });
        const apiResponse = await apiCall(`${Globals.API_ROOT_URL}school_names`, 'post', data);
        if (apiResponse !== undefined && apiResponse.status === 200) {
            dispatch(SearchSchoolSuccess(apiResponse.data));
        } else {
            handleError(apiResponse, dispatch);
            dispatch(fetchAllSchoolError());
        }
    } catch (error) {
        toast.error(Globals.errorMsg);
        dispatch(fetchAllSchoolError());
        throw error;
    }
};