/**
 * Color intention that you want to used in your theme
 * @param {JsonObject} theme Theme customization object
 */

export default function themePalette(theme) {
    return {
        mode: theme?.customization?.mode,
        ...(theme?.customization?.mode === 'light'
            ? {
                  common: {
                      black: theme.colors?.darkPaper,
                      white: theme.colors?.white
                  },
                  primary: {
                      light: theme.colors?.primaryLight,
                      main: theme.colors?.primaryMain,
                      dark: theme.colors?.primaryDark,
                      200: theme.colors?.primary200,
                      800: theme.colors?.primary800
                  },
                  secondary: {
                      light: theme.colors?.secondaryLight,
                      main: theme.colors?.secondaryMain,
                      dark: theme.colors?.secondaryDark,
                      200: theme.colors?.secondary200,
                      800: theme.colors?.secondary800
                  },
                  error: {
                      light: theme.colors?.errorLight,
                      200: theme.colors?.error200,
                      main: theme.colors?.errorMain,
                      dark: theme.colors?.errorDark
                  },
                  warning: {
                      light: theme.colors?.warningLight,
                      main: theme.colors?.warningMain,
                      dark: theme.colors?.warningDark
                  },
                  success: {
                      light: theme.colors?.successLight,
                      200: theme.colors?.success200,
                      main: theme.colors?.successMain,
                      dark: theme.colors?.successDark
                  },
                  grey: {
                      50: theme.colors?.greyBackground,
                      100: theme.colors?.greyLight,
                      400: theme.colors?.greyDark,
                      500: theme.colors?.greyMain,
                      600: theme.colors?.grey600,
                      700: theme.colors?.textBlack,
                      900: theme.colors?.textBlack
                  },
                  dark: {
                      light: theme.colors?.darkTextPrimary,
                      main: theme.colors?.darkLevel1,
                      dark: theme.colors?.darkLevel2,
                      800: theme.colors?.textBlack,
                      900: theme.colors?.textBlack
                  },
                  others: {
                      purple: theme.colors?.purpleMain,
                      blue: theme.colors?.blueMain
                  },
                  text: {
                      secondary: theme.colors?.textBlack,
                      primary: theme.colors?.textBlack,
                      light: theme.colors?.white,
                      dark: theme.colors?.textBlack,
                      hint: theme.colors?.greyLight,
                      orange: theme.colors?.secondaryMain,
                      error: theme.colors?.errorMain,
                      success: theme.colors?.successMain
                  },
                  background: {
                      paper: theme.colors.paper,
                      default: theme.colors.paper
                  },
                  button: {
                      light: theme.colors.white,
                      dark: theme.colors.black
                  }
              }
            : {
                  common: {
                      black: theme.colors?.darkPaper
                  },
                  primary: {
                      light: theme.colors?.primaryLight,
                      main: theme.colors?.secondaryDark,
                      dark: theme.colors?.primaryDark
                  },
                  secondary: {
                      light: theme.colors?.secondaryLight,
                      main: theme.colors?.secondaryMain,
                      dark: theme.colors?.secondaryDark
                  },
                  error: {
                      light: theme.colors?.errorLight,
                      main: theme.colors?.errorMain,
                      dark: theme.colors?.errorDark
                  },
                  warning: {
                      light: theme.colors?.warningLight,
                      main: theme.colors?.warningMain,
                      dark: theme.colors?.warningDark
                  },
                  success: {
                      light: theme.colors?.successLight,
                      main: theme.colors?.successMain,
                      dark: theme.colors?.successDark
                  },
                  grey: {
                      100: theme.colors?.greyLight,
                      500: theme.colors?.greyMain,
                      600: theme.colors?.textBlack,
                      700: theme.colors?.textBlack,
                      900: theme.colors?.textBlack
                  },
                  dark: {
                      light: theme.colors?.darkTextPrimary,
                      main: theme.colors?.darkLevel1,
                      dark: theme.colors?.darkLevel2,
                      900: theme.colors?.darkPaper
                  },
                  text: {
                      primary: theme.colors?.textBlack,
                      light: theme.colors?.white,
                      secondary: theme.colors?.greyMain,
                      dark: theme.colors?.textBlack,
                      hint: theme.colors?.greyLight
                  },
                  background: {
                      paper: theme.colors.paper,
                      default: theme.colors.paper
                  },
                  button: {
                      light: theme.colors.paper,
                      dark: theme.colors.paper
                  }
              })
    };
}
