import React, { useEffect, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { Box, Card, CardContent, Grid, List, ListItem, ListItemText, Paper, Typography } from '@mui/material';
import BarChart from 'components/Charts/BarChart';
import CommonTable from 'components/CommonComponent/CommonTable/CommonTable';
import NoDataFound from 'components/CommonComponent/NoDataFound';
import { AllocationOutputCard } from 'assets/jss/CommonCalStyle';
import { ChartParentGrid } from 'assets/jss/GeneralStyle';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPortfolioList } from 'redux/actions/PortfolioActions';

const transactionCellList = [
    ['assetType', 'Asset Type'],
    ['transactionType', 'Transaction Type'],
    ['amount', 'Amount']
];
const equityCellList = [
    ['Symbol', 'Symbol'],
    ['Broad_Type', 'Broad Type'],
    ['Narrow_Type', 'Narrow Type'],
    ['Asset_Class_Alloc', 'Asset Class Allocation']
];
const AllocationOutput = ({ classes, allocationData }) => {
    const dispatch = useDispatch();
    const [tansactionData, setTansactionData] = useState([]);
    const [tansactionMonth, setTansactionMonth] = useState('');
    const [equityData, setEquityData] = useState([]);
    const [alloBarData, setAlloBarData] = useState({});
    const { portfolioList } = useSelector((state) => state.portfolio);

    useEffect(() => {
        if (Object.keys(allocationData).length) {
            setTansactionData(allocationData.transaction);
            setTansactionMonth(allocationData.transactionMonth);
            const eqData = JSON.parse(allocationData.modelEquity);
            setEquityData(Object.keys(eqData).map((val) => eqData[val]));
            let formattedData = [],
                chartDataLists = {};
            let minVal = [];
            // to create structure to pass in bar chart
            allocationData.basicAllocation.forEach((val, i) => {
                Object.keys(val[Object.keys(val)[0]]).forEach((subKey) => {
                    const index = formattedData.findIndex((ele) => ele.label === subKey);
                    const currVal = val[Object.keys(val)[0]][subKey];
                    const subLabel = Object.keys(val)[0];
                    if (index > -1) {
                        formattedData[index] = {
                            ...formattedData[index],
                            [subLabel]: currVal
                        };
                    } else {
                        formattedData.push({ label: subKey, [subLabel]: currVal });
                    }
                    // to find every chart's min value from array and store it
                    minVal.push(currVal);
                });
            });
            chartDataLists['min'] = Math.min(...minVal);
            chartDataLists['data'] = formattedData;
            setAlloBarData(chartDataLists);
        }
    }, [allocationData]);

    useEffect(() => {
        dispatch(fetchPortfolioList());
    }, []);

    return (
        <>
            {Object.keys(allocationData).length ? (
                <Card elevation={2} sx={{ mb: 3.5 }}>
                    <CardContent>
                        <Typography variant="h6" sx={{ mb: 2, display: 'flex' }}>
                            Basic asset allocation graph
                        </Typography>
                        <Grid container spacing={2}>
                            {Object.keys(alloBarData).length > 0 ? (
                                <ChartParentGrid item xs={12}>
                                    <Box className={'chartWapper'}>
                                        {Object.keys(alloBarData).length && (
                                            <BarChart chartData={alloBarData} halfGraph={true} initCount={70} groupMode="grouped" />
                                        )}
                                    </Box>
                                </ChartParentGrid>
                            ) : (
                                ''
                            )}
                        </Grid>
                    </CardContent>
                </Card>
            ) : (
                ''
            )}
            <AllocationOutputCard elevation={2}>
                <CardContent>
                    <Typography variant="h6" sx={{ mb: 2, display: 'flex' }} gutterBottom>
                        Summary Output
                    </Typography>
                    <Box>
                        {Object.keys(allocationData).length ? (
                            <>
                                <Grid item xs={12}>
                                    <Typography> High Level transaction recommendations</Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        Thanks for the information. You are awesome!
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        Based on our analysis you need to:
                                    </Typography>
                                    <Typography gutterBottom variant="body2">
                                        (Please execute these over the next 
                                        <strong>{tansactionMonth}</strong> months).
                                    </Typography>
                                </Grid>
                                <Accordion>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="selected-content" id="selected-header">
                                        <Typography>Selected File</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container>
                                            <Grid item xs={12} sm={12}>
                                                <Paper elevation={0}>
                                                    {Object.values(portfolioList).map((file, index) => {
                                                        if (file?.is_saved === true) {
                                                            return (
                                                                <ListItem key={index}>
                                                                    <ListItemText secondary={`${index + 1}. ${file?.file_name}`} />
                                                                </ListItem>
                                                            );
                                                        }
                                                    })}
                                                </Paper>
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="transaction-content"
                                        id="transaction-header"
                                    >
                                        <Typography>Transaction Data</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container>
                                            <Grid item xs={12} sm={12}>
                                                <Paper elevation={0}>
                                                    <CommonTable
                                                        tableColumns={transactionCellList}
                                                        data={tansactionData}
                                                        enableHeader={false}
                                                        enableCellColor={true}
                                                    />
                                                </Paper>
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary
                                        // ref={container}
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="modelPortFolio-content"
                                        id="modelPortFolio-header"
                                    >
                                        <Typography>Model Portfolio Data</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails className={classes?.allocationOutputTable}>
                                        <Grid container>
                                            <Grid item xs={12} sm={12}>
                                                <Paper elevation={0}>
                                                    <CommonTable tableColumns={equityCellList} data={equityData} enableHeader={false} />
                                                </Paper>
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </>
                        ) : (
                            <>
                                <Accordion>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="selected-content" id="selected-header">
                                        <Typography>Selected File</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container>
                                            <Grid item xs={12} sm={12}>
                                                <Paper elevation={0}>
                                                    {Object.values(portfolioList).map((file, index) => {
                                                        if (file?.is_saved === true) {
                                                            return (
                                                                <ListItem key={index}>
                                                                    <ListItemText secondary={`${index + 1}. ${file?.file_name}`} />
                                                                </ListItem>
                                                            );
                                                        }
                                                    })}
                                                </Paper>
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                                <NoDataFound />
                            </>
                        )}
                    </Box>
                </CardContent>
            </AllocationOutputCard>
        </>
    );
};

export default AllocationOutput;
