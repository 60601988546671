import { combineReducers } from 'redux';
import { HomeCalReducers } from './HomeCalReducer';
import { InvestDashboardReducer } from './InvestDashboardReducer';
import { EquityDashboardReducer } from './EquityDashboardReducer';
import { EconomicDashboardReducer } from './EconomicDashboardReducer';
import { LoanDashboardReducer } from './LoanDashboardReducer';
import { OptionPricingCalReducer } from './OptionPricingCalReducer';
import { RetirementCalReducer } from './RetirementCalReducer';
import { PortfolioReducer } from './PortfolioReducer';
import { ChatBotReducer } from './ChatBotReducer';
import { FxCalReducer } from './FxCalReducer';
import { TodoReducer } from './TodoReducer';
import { StudLoanCalReducer } from './StudLoanCalReducer';
import { AuthReducer } from './AuthReducer';
import { BudgetReducer } from './BudgetReducer';
import { SettingReducer } from './SettingReducer';
import { DebtReducer } from './DebtReducer';
import { MutualFundReducer } from './MutualFundReducer';
import { GeneralCalReducer } from './GeneralCalReducer';
import { PeertoPeerCalReducer } from './PeertoPeerCalReducer';
import { CollegeCalReducer } from './CollegeReducer';
import { houseAffordablityCalReducer } from './houseAffordablityReducer';
import { BlogReducer } from './BlogReducer';
import { MortgageRefinanceReducer } from './MortgageRefinanceReducer';
import { FixedIncomeHistoricalReducer } from './FixedIncomeHistoricalReducer';
import { CreditCardReducer } from './CreditCardReducer';
import { ResearchTableReducer } from './ResearchTableReducer';

const appReducer = combineReducers({
    homecal: HomeCalReducers,
    investDashboard: InvestDashboardReducer,
    equityDashboard: EquityDashboardReducer,
    economicDashboard: EconomicDashboardReducer,
    loanDashboard: LoanDashboardReducer,
    optionPricingCal: OptionPricingCalReducer,
    retirementCal: RetirementCalReducer,
    portfolio: PortfolioReducer,
    chatBot: ChatBotReducer,
    fxcal: FxCalReducer,
    studLoanCal: StudLoanCalReducer,
    todo: TodoReducer,
    budget: BudgetReducer,
    setting: SettingReducer,
    debt: DebtReducer,
    creditCard: CreditCardReducer,
    mutualfund: MutualFundReducer,
    generalCal: GeneralCalReducer,
    login: AuthReducer,
    peertopeer: PeertoPeerCalReducer,
    collegeCal: CollegeCalReducer,
    houseAffordCal: houseAffordablityCalReducer,
    blogReducer: BlogReducer,
    mortgageRefinanceReducer: MortgageRefinanceReducer,
    fixedIncomeHistoricalReducer: FixedIncomeHistoricalReducer,
    ResearchTableReducer: ResearchTableReducer
});
const initialState = appReducer({}, {});
const rootReducer = (state, action) => {
    let innerState = state;
    if (action.type === 'HANDLE_LOGOUT_SUCCESS') {
        innerState = initialState;
    }
    return appReducer(innerState, action);
};

export default rootReducer;
