import React from 'react';
import { Card, CardContent, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { QuesAnsBox } from 'assets/jss/RetirementStyle';

const RecurringCalDesc = (props) => {
    return (
        <Grid container spacing={2} sx={{ mt: 2 }}>
                <Card>
                    <CardContent>
                        <QuesAnsBox>
                            <Typography variant="h2" id="description" sx={{ mb: 2, display: 'flex' }}>
                                Recurring Expense Calculator
                            </Typography>
                            <Typography variant="body1" className="dashboard-subheading" sx={{ mb: 1.5 }}>
                                This calculator helps you understand the long-term impact of regular payments or subscriptions. By providing a few key details, you can gain valuable insight into how your future finances are impacted by spending today. You can use this calculator for daily expenses (like coffee, restaurant dining and drinks) or monthly expenses such as your Apple TV, Hulu and Netflix subscriptions (do you really need all 3?)
                                <br></br><br></br><b>What it Does:</b><br></br><br></br>The calculator focuses on the concept of future value, which essentially asks: "How much do recurring expenses today impact my future networth?" It considers factors like:
                                <ul style={{paddingLeft:"50px"}}>
                                    <br></br><li><b>Payment Frequency: </b>This allows you to set the frequency of the payment. For example, use Daily or D to calculate how much that $8 Starbuck Java Chip Frappuccino or that $25 Appletini at that swish Lower East Sid speakeasy is really costing you over time. Use M for your monthly World of Warcraft subscription.</li><br></br>
                                    <li><b>Recurring Payment: </b>This is the regular payments you are making.</li><br></br>
                                    <li><b>Interest Rate: </b>This represents the annual returns you could make if you saved instead of spent this money. Money spent is gone forever. Money saved grows over time.</li><br></br>
                                    <li><b>Number of Payments (in Years): </b>This reflects the number of years you want to project in the future.</li><br></br>
                                </ul>
                                <br></br><b>How to Use It:</b><br></br>
                                <ol style={{paddingLeft:"50px"}}>
                                    <br></br><li><b>Input Your Details: </b>Enter the monthly payment amount, expected interest rate (as a yearly percentage), payment frequency, and the total number of years you want to forecast.</li><br></br>
                                    <li><b>Calculate: </b>Click the "Calculate" button.</li><br></br>
                                    <li><b>See the Future Value: </b>The calculator will display the future value of your payments. This represents the total amount your recurring payment will cost you at the end of the specified period, considering the impact of interest compounding.</li><br></br>
                                </ol>
                                <br></br><b>Remember: </b><br></br>
                                <br></br>
                                The accuracy of the future value depends on the accuracy of your interest rate estimate. Interest rates can fluctuate, so consider this a general planning tool.
                                <br></br>This future value calculator empowers you to make informed financial decisions about the true cost of your regular expenses.

                            </Typography>
                        </QuesAnsBox>
                    </CardContent>
                </Card>
            </Grid>
    );
};

export default RecurringCalDesc;
