import { Box, Popover, Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Collapse from "@mui/material/Collapse";
import Link from "@mui/material/Link";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { fetchSelectedHomePurch } from "redux/actions/HomeCalActions";
import { StyledBreadCrumbLink } from "assets/jss/GeneralStyle";
import { CustomButton } from "assets/jss/ButtonStyle";
import { StyledPortfolioBreadcrumb } from "assets/jss/CommonCalStyle";
import { BreadcrumbsTypography } from "assets/jss/CalculatorStyle";
import { StyledBadge } from "assets/jss/CalculatorStyle";

var refetchVal = false;
const HomePurchaseBreadcrumb = (props) => {
  const dispatch = useDispatch();
  const [selectedHomePurch, setSelectedHomePurch] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [checked, setChecked] = React.useState([]);
  const openBredcrumbDropdown = Boolean(anchorEl);
  const id = openBredcrumbDropdown ? "simple-popover" : undefined;

  useEffect(() => {
    //auto select first portfolio file
    if (Object.keys(selectedHomePurch).length === 0 || refetchVal) {
      if (props.list.length > 0) {
        refetchVal = false;
        fetchFirstPortValue();
      } else {
        refetchVal = false;
        setSelectedHomePurch({});
        setChecked([]);
      }
    }
  }, [props.list]);

  useEffect(() => {
    //when file will delete from file upload tab then to update breadcrumb list
    if (props.renderBreadCrumb.length > 0) {
      const isEmpty = checked.filter((val) => props.renderBreadCrumb.indexOf(val) === -1);
      setChecked(isEmpty);
      if (isEmpty.length === 0) {
        refetchVal = true;
      }
      props.resetRenderBreadCrumb();
    }
  }, [props.renderBreadCrumb]);

  useEffect(() => {
    // when data will update in table then need to fetch updated list from api
    if (props.renderInputList) {
      handleHomePurchSelection();
      props.resetInputList();
    }
  }, [props.renderInputList]);

  const fetchFirstPortValue = () => {
    handleToggle(props.list[0].id);
    setSelectedHomePurch(props.list[0]);
    handleHomePurchSelection([props.list[0].id]);
  }

  /**
   * trim file name with extention and return only file name
   * @param {*} name
   * @returns
   */
  const fetchFileName = (name) => {
    let fName = name;
    const currFileName = fName.split(".");
    const fileExt =
      currFileName.length > 1
        ? "." + currFileName[currFileName.length - 1]
        : "";
    return fName.replace(fileExt, "");
  };
  // call when need to fetch selected portfolio file
  const handleHomePurchSelection = (val = null, save = false) => {
    const idToPass = val || checked;
    setAnchorEl(null);
    if (idToPass.length > 0) {
      const updatedList = props.list.filter((val) => val.id === idToPass[0]);
      setSelectedHomePurch(updatedList[0]);
      const data = { "ids": idToPass, "is_saved": save || undefined }
      dispatch(fetchSelectedHomePurch(data));
    } else {
      toast.error("Please select at least one home purchase file.");
    }
  };

  const handleToggle = (value) => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  useMemo(() => {
    // Your code to handle changes in selectedHomePurch here
    if (props.list.length > 0 && props.updateSuccess === false && props.renderInputList !== false) {
      handleToggle(props.list[0].id);
      setSelectedHomePurch(props.list[0]);
      handleHomePurchSelection([props.list[0].id]);
    }
  }, [props.list, props.updateSuccess]); // Include selectedHomePurch as a dependency
  return (
    <>
      <StyledPortfolioBreadcrumb>
        <Link color="text.primary" underline="none" onClick={() => props.handleTabChange(1)} component="button">
          <Typography variant="subtitle1">
            {Object.keys(selectedHomePurch).length === 0 ? 'Click here to upload files' : 'My Home Purchase File(s)'}
          </Typography>
        </Link>
        {Object.keys(selectedHomePurch).length > 0 ? (
          <StyledBreadCrumbLink
            color="textPrimary"
            onClick={(event) => setAnchorEl(event.currentTarget)}
            aria-describedby={id}
            underline="none"
          >
            <BreadcrumbsTypography>{fetchFileName(selectedHomePurch.file_name)} </BreadcrumbsTypography>
            {checked.length > 1 ? <StyledBadge badgeContent={`+${checked.length - 1}`} color="secondary" /> : ''}
            {openBredcrumbDropdown ? <ExpandLess className={'arrow-icon'} /> : <ExpandMore className={'arrow-icon'} />}
          </StyledBreadCrumbLink>
        ) : (
          ''
        )}
      </StyledPortfolioBreadcrumb>
      <Popover
        id={id}
        open={openBredcrumbDropdown}
        anchorEl={anchorEl}
        onClose={(e) => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        {props.list.map((val, listIndex) => {
          return (
            val.file_name !== 'tpe_default' &&
            <List key={'breadcrumbList' + listIndex} component="nav" aria-labelledby="nested-list-subheader">
              <Collapse in={openBredcrumbDropdown} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem role={undefined} dense button onClick={() => handleToggle(val.id)}>
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={checked.indexOf(val.id) !== -1}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ 'aria-labelledby': val.id }}
                      />
                    </ListItemIcon>
                    <ListItemText id={val.id} primary={fetchFileName(val.file_name)} />
                  </ListItem>
                </List>
              </Collapse>
            </List>
          );
        })}
        <Box sx={{ textAlign: 'center' }}>
          <CustomButton variant="contained" size="sm" color="info" onClick={() => handleHomePurchSelection()}>
            Load Data!
          </CustomButton>
          <CustomButton sx={{ marginLeft: "10px" }} variant="contained" size="sm" color="success" onClick={() => handleHomePurchSelection(null, true)}>
            Save Settings
          </CustomButton>
        </Box>
      </Popover>
    </>
  );
};
export default HomePurchaseBreadcrumb;
