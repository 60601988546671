import AddIcon from '@mui/icons-material/Add';
import { Box, Divider, Grid, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import Tooltip from '@mui/material/Tooltip';
import { CustomButton } from 'assets/jss/ButtonStyle';
import { StyledDialogContent } from 'assets/jss/GeneralStyle';
import { CalCardActions } from 'assets/jss/RetirementStyle';
import CustomDialogTitle from 'components/DialogTitle';
import { CurrencyList } from 'Currency.json';
import React, { useEffect, useState, memo } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { smartTblPortfolio, smartTblPortfolioError } from 'redux/actions/PortfolioActions';
import RenderField from 'utils/RenderField';
import { dynamicSort } from 'utils/Utils';
import moment from 'moment';
var currList = [...CurrencyList];
currList.sort(dynamicSort('code'));
function handleOptions() {
    const list = currList.map((val) => {
        return {
            value: val.code,
            label: val.code + `(${val.name})`,
            symbol: val.symbol,
            flag: val.flag
        };
    });
    return list;
}

const AddUserDialog = (props) => {
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();
    const portfolioData = useSelector((state) => state.portfolio);
    const fileOptions = () => {
        return portfolioData.portfolioList.map((val) => ({ value: val.id, label: val.file_name }));
    };

    useEffect(() => {
        if (props?.open == true) {
            setOpen(true);
        }
    }, [props?.open]);

    const [fieldsDetail, setfieldsDetail] = useState([
        {
            name: 'symbol',
            label: 'Symbol',
            type: 'text',
            required: true,
            commaSep: true,
            custompattern: /^([a-z0-9A-Z#*&_ ]{1,20})$/g,
            placeholder: 'Enter text'
        },
        { name: 'portFileId', label: 'Portfolio File', type: 'select', required: true, disableEmptyValue: true },
        {
            name: 'who',
            label: 'Who',
            type: 'select',
            options: [
                { value: 'me', label: 'For Me' },
                { value: 'another', label: 'For Another' }
            ]
        },
        {
            name: 'what',
            label: 'What',
            type: 'select',
            options: [
                { value: 'bank', label: 'Bank' },
                { value: 'brokerage', label: 'Brokerage' },
                { value: 'other', label: 'Other' }
            ]
        },
        {
            name: 'tradeable',
            label: 'Tradeable',
            type: 'select',
            options: [
                { value: 'yes', label: 'Yes' },
                { value: 'no', label: 'No' }
            ]
        },
        {
            name: 'position_name',
            label: 'Position Name',
            type: 'text',
            commaSep: true,
            custompattern: 'validString',
            placeholder: 'Enter text'
        },
        {
            name: 'quantity',
            label: 'Quantity',
            type: 'number',
            required: true,
            commaSep: true,
            custompattern: 'decimalRate',
            placeholder: '100,000'
        },
        {
            name: 'price_override',
            label: 'Price Override',
            type: 'number',
            commaSep: true,
            custompattern: 'decimalRate',
            placeholder: '100,000',
            defaultValue: props?.homePrice?.priceOverride || null
        },
        { name: 'currency', label: 'Currency', type: 'searchSelect', options: handleOptions(), customAutoInput: { padding: 0 } },
        {
            name: 'account_type',
            label: 'Account Type',
            type: 'text',
            commaSep: true,
            custompattern: 'validString',
            placeholder: 'Enter text'
        },
        {
            name: 'institution_name',
            label: 'Institution Name',
            type: 'text',
            commaSep: true,
            custompattern: 'validString',
            placeholder: 'Enter text'
        },
        {
            name: 'primary_acc_holder',
            label: 'Primary Account Holder',
            type: 'text',
            commaSep: true,
            custompattern: 'validString',
            placeholder: 'Enter text'
        },
        {
            name: 'trust_flag',
            label: 'Trust Flag',
            type: 'select',
            options: [
                { value: 'yes', label: 'Yes' },
                { value: 'no', label: 'No' }
            ]
        },
        {
            name: 'asset_location',
            label: 'Asset Location',
            type: 'text',
            commaSep: true,
            custompattern: 'validString',
            placeholder: 'Enter text'
        },
        {
            name: 'broad_asset_type',
            label: 'Broad Asset Type',
            type: 'select',
            options: [
                { value: 'Equities', label: 'Equities' },
                { value: 'FX', label: 'FX' },
                { value: 'Commodities', label: 'Commodities' },
                { value: 'Inflation', label: 'Inflation' },
                { value: 'Cash', label: 'Cash' },
                { value: 'Fixed Income', label: 'Fixed Income' },
                { value: 'Real Estate', label: 'Real Estate' }
            ]
        },
        {
            name: 'narrow_asset_type',
            label: 'Narrow Asset Type',
            type: 'text',
            commaSep: true,
            custompattern: 'validString',
            placeholder: 'Enter text'
        },
        {
            name: 'security_type',
            label: 'Security Type',
            type: 'text',
            commaSep: true,
            custompattern: 'validString',
            placeholder: 'Enter text'
        },
        { name: 'employer', label: 'Employer', type: 'text', commaSep: true, custompattern: 'validString', placeholder: 'Enter text' },
        {
            name: 'short_des_override',
            label: 'Short Des Override',
            type: 'text',
            commaSep: true,
            custompattern: 'validString',
            placeholder: 'Enter text'
        },
        {
            name: 'category_override',
            label: 'Category Override',
            type: 'text',
            commaSep: true,
            custompattern: 'validString',
            placeholder: 'Enter text'
        },
        {
            name: 'maturity_date',
            label: 'Maturity Date',
            helperText: 'Maturity Date',
            type: 'datetime',
            timeFormat: false,
            placeholder: 'Select Date'
        }
    ]);
    useEffect(() => {
        if (props?.homePrice != undefined || props?.homePrice != null) {
            const updatedList = fieldsDetail.map((item) => {
                if (item && item?.name === 'symbol') {
                    return { ...item, defaultValue: props?.homePrice?.symbol }; // Adding 'status' key
                } else if (item && item?.name === 'quantity') {
                    return { ...item, defaultValue: props?.homePrice?.quantity }; // Adding 'status' key
                } else if (item && item?.name === 'tradeable') {
                    return { ...item, defaultValue: 'no' }; // Adding 'status' key
                }

                return item;
            });
            updatedList.push({
                name: 'home_val',
                type: 'select',
                label: 'Connect home valuation',
                options: [
                    { value: 'yes', label: 'Yes' },
                    { value: 'no', label: 'No' }
                ],
                defaultValue: 'yes'
            });
            setfieldsDetail(updatedList);
        }
    }, [props?.homePrice?.priceOverride]);

    const {
        handleSubmit,
        control,
        formState: { isSubmitting },
        setValue,
        reset
    } = useForm({
        mode: 'onChange'
    });
    const handleClickOpen = () => setOpen(true);
    const handleClose = () => {
        props?.setIsOpen && props.setIsOpen(false);
        setOpen(false);
    };
    useEffect(() => {
        // reset all state flag whenevery form is submitted
        if (portfolioData?.submitSmartTblSuccess) {
            handleClose();
            reset({});
            dispatch(smartTblPortfolioError());
        }
    }, [portfolioData?.submitSmartTblSuccess]);
    const onSubmit = (data) => {
        const objToPass = {
            symbol: data.symbol,
            portFileId: data.portFileId,
            who: data.who || null,
            what: data.what || null,
            tradeable: data.tradeable === 'no' ? false : true,
            position_name: data.home_val === 'yes' ? data.symbol : data.position_name || null,
            quantity: data.quantityUnFormattedValue || data.quantity,
            price_override: data.price_overrideUnFormattedValue || data.price_override || null,
            currency: data.currency?.value || null,
            account_type: data.account_type || null,
            institution_name: data.institution_name || null,
            primary_acc_holder: data.primary_acc_holder || null,
            trust_flag: data.trust_flag === 'yes' ? true : false,
            asset_location: data.asset_location || null,
            broad_asset_type: data.broad_asset_type || null,
            narrow_asset_type: data.narrow_asset_type || null,
            security_type: data.security_type || null,
            employer: data.employer || null,
            short_des_override: data.short_des_override || null,
            category_override: data.category_override || null,
            home_val: data.home_val === 'yes' ? true : false || false,
            // maturity_date: "2024/01/04"
            maturity_date:
                (data.maturity_date && moment(data.maturity_date).format('YYYY/MM/DD')) || moment(new Date()).format('YYYY/MM/DD')
        };
        dispatch(smartTblPortfolio(objToPass, 'post'));
        handleClose();
    };

    return (
        <Box>
            {!props.open && (
                <Tooltip title="Add" arrow>
                    <CustomButton
                        style={{ width: '100%' }}
                        id="new-data"
                        variant="text"
                        startIcon={<AddIcon />}
                        aria-label="add"
                        onClick={handleClickOpen}
                    >
                        Add New Data
                    </CustomButton>
                </Tooltip>
            )}
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <CustomDialogTitle id="form-dialog-title" onClose={handleClose}>
                    Add Portfolio
                </CustomDialogTitle>
                <Divider />
                <StyledDialogContent>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container>
                            {fieldsDetail.map((val, i) => {
                                const optionsList = val.name === 'portFileId' ? fileOptions() : val.options;
                                return (
                                    <Grid item xs={12} sm={6} key={i + 'addUserDialog'}>
                                        <RenderField
                                            name={val.name}
                                            control={control}
                                            label={val.label}
                                            id={val.name}
                                            type={val.type}
                                            options={optionsList}
                                            required={val.required || false}
                                            thousandSeparator={val.commaSep}
                                            customPatternName={val.custompattern}
                                            placeholder={val.placeholder}
                                            setValue={setValue}
                                            renderOption={(props, option) => {
                                                return (
                                                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                        {option.flag !== '' ? (
                                                            <img
                                                                loading="lazy"
                                                                width="20"
                                                                src={'img/flags/' + option.flag}
                                                                alt="country flags"
                                                            />
                                                        ) : (
                                                            ''
                                                        )}
                                                        <Typography variant="body2">{option.label}</Typography>
                                                    </Box>
                                                );
                                            }}
                                            {...val}
                                        />
                                    </Grid>
                                );
                            })}
                        </Grid>
                        <CalCardActions className="contactus-cardaction">
                            <Grid container direction="row" style={{ width: '100%', textAlign: 'center' }}>
                                <Grid item xs={12}>
                                    <CustomButton type="submit" variant="contained" disabled={isSubmitting}>
                                        Submit
                                    </CustomButton>
                                </Grid>
                            </Grid>
                        </CalCardActions>
                    </form>
                </StyledDialogContent>
            </Dialog>
        </Box>
    );
};
export default memo(AddUserDialog);
