import React, { useEffect, useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import MortgageRefinanceCalForm from './MortRefinCalForm.jsx';
import CommonCalculator from 'components/CommonComponent/CommonCalculator.js';
import { useDispatch, useSelector } from 'react-redux';
import Loader from 'components/CommonComponent/Loader.js';
import CalDescription from 'CalDescription';
import { Container, Grid, Typography } from '@mui/material';
import { MainBox } from 'assets/jss/GeneralStyle.jsx';
import MortgageRefinanceCalOutput from './MortRefinCalOutput.jsx';
import MortgageRefinCalDesc from './MortRefinCalDesc.jsx';
import { CustomListItem } from 'assets/jss/HeaderStyle.jsx';
import { CustomListItemText } from 'assets/jss/HeaderStyle.jsx';
import { WidgetBox } from 'assets/jss/GeneralStyle.jsx';
import AccessControl from 'views/Authorization/AccessControl.js';
import PermissionDenied from 'views/Authorization/PermissionDenied.js';
import { fetchLoanDashBoardData } from 'redux/actions/LoanDashBoardAction.js';
import Meta from 'components/meta.jsx';

export default function MortgageRefinanceCalculator() {
    const dispatch = useDispatch();
    const mortgageRefinanceData = useSelector((state) => state.mortgageRefinanceReducer);
    const profileDetail = useSelector((state) => state.setting.profileDetail);
    const [annualIncome, setAnnualIncome] = useState(50000);
    const loanDashData = useSelector((state) => state.loanDashboard);

    const typographyRef = useRef(null);
    useEffect(() => {
        // Set the ID after the component mounts
        if (typographyRef.current) {
            typographyRef.current.id = 'mrCal';
        }
    }, []);

    const loanList =
        loanDashData?.loanDashboardDetail?.data?.slice(0, 2)?.map((val) => ({
            label: `${val.mort_label} (${val.mort_Values})`,
            value: val.mort_Values
        })) || [];

    useEffect(() => {
        if (profileDetail?.annualincome != 0 || profileDetail?.annualincome != undefined) {
            setAnnualIncome(profileDetail?.annualincome);
        }
        dispatch(fetchLoanDashBoardData());
    }, []);

    const render = () => {
        return (
            <>
                {mortgageRefinanceData.loader ? <Loader /> : ''}
                <AccessControl>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={4.5} className="widget-grid-item">
                            <MortgageRefinanceCalForm mortgageRefinanceData={mortgageRefinanceData} loanList={loanList} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={7.5} className="widget-grid-item">
                            <MortgageRefinanceCalOutput mortgageRefinanceData={mortgageRefinanceData} />
                        </Grid>
                    </Grid>
                </AccessControl>
            </>
        );
    };
    const breadcrumbs = [
        <CustomListItem className="cal-banner-menu" key="1" sx={{ m: 0 }} color="text.light">
            <CustomListItemText primary={'Home'} />
        </CustomListItem>,
        <CustomListItem className="cal-banner-menu" key="2" sx={{ m: 0 }} color="text.light">
            <CustomListItemText primary={'Debt Calculators'} />
        </CustomListItem>,
        <Typography ref={typographyRef} key="3" color="text.light" className="current-menu">
            Mortgage Refinance Calculator
        </Typography>
    ];
    return (
        <>
          <Meta
                title="Mortgage Refinance Calculator | TPE Hub"
                description="Calculate your potential savings with our Mortgage Refinance Calculator. Input your current mortgage details and explore how refinancing can reduce your monthly payments or overall interest costs. Get personalized insights to make informed financial decisions."
                page="/mortgage-refinance-calculator"
            />
            <CommonCalculator
                breadcrumbs={breadcrumbs}
                variantText="Mortgage Refinance Calculator"
                desc={CalDescription['Mortgage Refinance Calculator']}
            />
            <MainBox>
                <Container>
                    {render()}
                    <MortgageRefinCalDesc />
                </Container>
            </MainBox>
        </>
    );
}
