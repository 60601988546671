import * as ActionTypes from "../actions/ActionTypes";

const initialState = {
  retirementCalDetail: {},
  savedRetirementDetail: {},
  loader: false,
};

export const RetirementCalReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SUBMIT_RETIRE_CAL_INIT:
      return { ...state, loader: true };
    case ActionTypes.SUBMIT_RETIRE_CAL_SUCCESS:
      return {
        ...state,
        loader: false,
        retirementCalDetail: action.data,
      };
    case ActionTypes.SUBMIT_RETIRE_CAL_ERROR:
      return { ...state, loader: false };

    case ActionTypes.FETCH_SAVED_DATA__RETIRE_INIT:
      return { ...state, loader: true };
    case ActionTypes.FETCH_SAVED_DATA__RETIRE_SUCCESS:
      return {
        ...state,
        loader: false,
        savedRetirementDetail: action.data,
      };
    case ActionTypes.FETCH_SAVED_DATA__RETIRE_ERROR:
      return { ...state, loader: false };

    default:
      return state;
  }
};
