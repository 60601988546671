import AddIcon from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Autocomplete, CircularProgress, Divider, Grid, IconButton, Typography, TextField } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Tooltip from '@mui/material/Tooltip';
import { CustomButton } from 'assets/jss/ButtonStyle';
import { CalCardActions } from 'assets/jss/RetirementStyle';
import { AddNewCatBlockBox, AddNewCatFieldBoxWrapper } from 'assets/jss/TodoStyle';
import CustomDialogTitle from 'components/DialogTitle';
import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { handleNewDataFetched, fetchTickerList } from 'redux/actions/EquityDashboardActions';
import { fetchWatchlistData, saveWatchListData } from 'redux/actions/EquityDashboardActions';
import RenderField from 'utils/RenderField';

// Debounce function for limiting API requests while typing
function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [value]);

    return debouncedValue;
}

const AddNewWatchlist = ({ watchlistData, breadCrumbValue }) => {
    const [open, setOpen] = React.useState(false);
    const [enableAddWatchlist, setEnableAddList] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const debouncedInput = useDebounce(inputValue, 300);
    const { tickers, loader } = useSelector((state) => state.equityDashboard);
    const [offset, setOffset] = useState(0);
    const limit = 20;
    const dispatch = useDispatch();
    const {
        handleSubmit,
        control,
        formState: { isSubmitting },
        setValue,
        reset
    } = useForm({
        mode: 'onChange'
    });
    useEffect(() => {
        if (Object.keys(breadCrumbValue).length) {
            setValue('watchlist', breadCrumbValue.id);
        }
    }, [breadCrumbValue]);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        reset({});
        setOpen(false);
        setEnableAddList(false);
        setValue('newWatchlist', '');
    };
    //to submit the form data
    const onSubmit = (data) => {
        let url = 'watchlist';
        let objToPass = {
            symbol: [data.symbol]
        };
        if (data.newWatchlist) {
            objToPass = {
                ...objToPass,
                name: data.newWatchlist
            };
        } else {
            url = 'subwatchlist';
            objToPass = {
                ...objToPass,
                watchlist_id: data.watchlist
            };
        }
        dispatch(saveWatchListData(objToPass, url));
        dispatch(handleNewDataFetched(true));
        handleClose();
    };

    // useEffect(() => {
    //     dispatch(fetchWatchlistData());
    // }, []);
    const handleCloseAddNewCat = () => {
        setEnableAddList(!enableAddWatchlist);
        setValue('newWatchlist', '');
    };

    useEffect(() => {
        dispatch(fetchTickerList({ filter: debouncedInput, offset: offset, limit: limit }));
    }, [debouncedInput]);

    useEffect(() => {
        if (debouncedInput) {
            dispatch(fetchTickerList({ filter: inputValue }));
        }
    }, [debouncedInput]);

    const handleScroll = (event) => {
        const threshold = 100;
        const bottomReached = event.target.scrollHeight - event.target.scrollTop <= event.target.clientHeight + threshold;

        if (bottomReached && !loader) {
            setOffset((prevOffset) => prevOffset + limit);
            dispatch(fetchTickerList({ filter: debouncedInput, offset: offset, limit: limit }));
        }
    };
    return (
        <>
            <Tooltip title="Add" arrow>
                <CustomButton
                    variant="text"
                    startIcon={<AddIcon />}
                    className="read-more add-new-list"
                    aria-label="add"
                    onClick={handleClickOpen}
                >
                    Add New List/Symbol
                </CustomButton>
            </Tooltip>
            <Dialog open={open} onClose={handleClose} aria-labelledby="ticker-dialog-title">
                <CustomDialogTitle id="ticker-dialog-title" onClose={handleClose}>
                    Add New List/Symbol
                </CustomDialogTitle>
                <Divider />
                <DialogContent>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container alignItems="baseline">
                            <Grid item xs={12} sm={5} md={5} lg={5} m={'auto 0'}>
                                <Typography>Add/Select Watchlist</Typography>
                            </Grid>
                            {/* <Grid item xs={12} sx={{ display: 'flex' }}> */}
                            <Grid item xs={12} sm={7} md={7} lg={7}>
                                <RenderField
                                    name="watchlist"
                                    control={control}
                                    label=""
                                    outputField
                                    fieldName="Watchlist"
                                    id="watchlist"
                                    required={!enableAddWatchlist}
                                    options={
                                        watchlistData?.map((val) => ({
                                            label: val.name,
                                            value: val.id
                                        })) || []
                                    }
                                    type="select"
                                    disableEmptyValue={true}
                                    disabled={enableAddWatchlist}
                                />
                                <AddNewCatBlockBox onClick={() => handleCloseAddNewCat()}>
                                    <AddIcon />
                                    <span>Add New Watchlist</span>
                                </AddNewCatBlockBox>
                                {enableAddWatchlist ? (
                                    <AddNewCatFieldBoxWrapper>
                                        <RenderField
                                            name="newWatchlist"
                                            control={control}
                                            label=""
                                            outputField
                                            required
                                            fieldName="Watchlist"
                                            id="newWatchlist"
                                            type="text"
                                            placeholder="Enter new watchlist"
                                        />
                                        <AddNewCatBlockBox onClick={() => handleCloseAddNewCat()}>
                                            <IconButton aria-label="close">
                                                <DeleteForeverIcon />
                                            </IconButton>
                                        </AddNewCatBlockBox>
                                    </AddNewCatFieldBoxWrapper>
                                ) : (
                                    ''
                                )}
                            </Grid>
                            <Grid item xs={12} sm={5} md={5} lg={5} m={'auto 0'}>
                                <Typography>Symbol</Typography>
                            </Grid>
                            <Grid item xs={12} sm={7} md={7} lg={7}>
                                {/* <RenderField
                                    name="symbol"
                                    control={control}
                                    label=""
                                    outputField
                                    fieldName="Symbol"
                                    id="symbol"
                                    type="text"
                                    required
                                    placeholder="Enter new symbol"
                                /> */}
                                <Controller
                                    name="symbol"
                                    control={control}
                                    defaultValue=""
                                    render={({ field: { onChange, value } }) => (
                                        <Autocomplete
                                            style={{ width: '150px' }}
                                            freeSolo
                                            options={tickers}
                                            getOptionLabel={(option) => option.ticker}
                                            loading={loader}
                                            inputValue={value || ''}
                                            onInputChange={(event, newInputValue) => {
                                                setInputValue(newInputValue);
                                                onChange(newInputValue);
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Search Symbol"
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        endAdornment: (
                                                            <>
                                                                {loader ? <CircularProgress color="inherit" size={20} /> : null}
                                                                {params.InputProps.endAdornment}
                                                            </>
                                                        )
                                                    }}
                                                />
                                            )}
                                            ListboxProps={{
                                                onScroll: handleScroll,
                                                style: { maxHeight: 200, overflow: 'auto' }
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                        <CalCardActions className="contactus-cardaction">
                            <CustomButton type="submit" ps={'9px 30px'} variant="contained" disabled={isSubmitting}>
                                Submit
                            </CustomButton>
                        </CalCardActions>
                    </form>
                </DialogContent>
            </Dialog>
        </>
    );
};
export default AddNewWatchlist;
