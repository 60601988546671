import Email from '@mui/icons-material/Email';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {
    CardContent, Grid,
    IconButton, MenuItem,
    Typography
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { makeStyles, withStyles } from '@mui/styles';
import { Box } from '@mui/system';
import { CustomButton } from 'assets/jss/ButtonStyle';
import { CustomImg } from 'assets/jss/HeaderStyle';
import { BgBox, LeftRightGrid, LoginBox, LoginCard, SubboxWrapperBox } from 'assets/jss/LoginStyle';
import styles from 'assets/jss/material-kit-react/views/loginPage.js';
import { CalCardActions } from 'assets/jss/RetirementStyle';
import { StyledInputAdornment } from 'assets/jss/TextFieldStyle';
import Loader from 'components/CommonComponent/Loader';
import useWindowDimensions from 'components/CommonComponent/UseWindowDimensions';
import Meta from 'components/meta';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { submitForgotResetPassword, submitLoginData } from 'redux/actions/AuthActions';
import RenderField from 'utils/RenderField';
import { getCookie } from 'utils/Utils';
const useStyles = makeStyles(styles);
const MyIconButton = withStyles({
    root: {
        padding: 0
    }
})(IconButton);
let _timeout = 0;
const emailReg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g;
export default function LoginIndex(props) {
    const { height } = useWindowDimensions();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const auth = useSelector((state) => state.login);
    const [showPassword, setShowPassword] = useState(false);
    const [forgotEmail, setForgotEmail] = useState('');
    // const [keepPass, setKeepPass] = useState(false);
    const [open, setOpen] = useState(false);
    const classes = useStyles();
    if (getCookie('auth_token') !== null) {
        navigate('/');
    }
    const {
        handleSubmit,
        control,
        formState: { isSubmitting },
        setValue
    } = useForm({
        mode: 'onChange',
        defaultValues: {
            email: '',
            helpdesc: '',
            name: '',
            number: ''
        }
    });
    useEffect(() => {
        if (getCookie('auth_token') !== null) {
            navigate('/');
        }
        if (getCookie('user_pass') !== null) {
            setValue('password', getCookie('user_pass'));
        }
    }, []);

    const handleClickShowPassword = (e) => setShowPassword({ ...showPassword, [e]: !showPassword[e] });
    const handleLogin = (data) => dispatch(submitLoginData(data, navigate, false));
    const handleClickOpen = () => setOpen(true);
    const handleForgotDialogClose = () => setOpen(false);
    const handleResetCode = () => {
        if (forgotEmail === '' || !forgotEmail.match(emailReg)) {
            toast.error('Please enter email id.');
        } else {
            setOpen(false);
            dispatch(submitForgotResetPassword({ email: forgotEmail }));
        }
    };
    const handleForgotEmail = (e) => {
        const val = e.target.value;
        if (_timeout) clearTimeout(_timeout);
        _timeout = setTimeout(() => {
            setForgotEmail(val);
        }, 1000);
    };
    return (
        <BgBox>
               <Meta
                title="Login | TPE Hub"
                description="Log in to your account to access personalized features and manage your settings. Enter your credentials to securely access your account."
                page="/login"
            />
            <ToastContainer />
            <Grid container>
                {auth.loader ? <Loader /> : ''}
                <LeftRightGrid item xs={12} md={5} className="form-grid">
                    <MenuItem component={Link} disableGutters to={'/'} className="logo-menu">
                        <IconButton
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            disableRipple={true}
                        >
                            <CustomImg src="img/TPE-logo.svg" className="login-tpe-logo" />
                        </IconButton>
                    </MenuItem>
                    <LoginBox minheight={height}>
                        <LoginCard>
                            <CardContent>
                                <Typography variant="subtitle1" sx={{ mb: 3.5 }} textAlign="center">
                                    Good to See You Again
                                </Typography>
                                {/* <Typography variant="body1" sx={{ mb: 1.5 }}>
                                    Login in to continue
                                </Typography> */}
                                {/* <Grid container className="gridcontainer-SSO">
                                    <SSOSignGrid item xs={12} sm={4}>
                                        <SSOSignBtn>
                                            <img src="img/facebook-login.svg" />
                                        </SSOSignBtn>
                                    </SSOSignGrid>
                                    <SSOSignGrid item xs={12} sm={4}>
                                        {' '}
                                        <SSOSignBtn>
                                            <img src="img/google-login.svg" />
                                        </SSOSignBtn>
                                    </SSOSignGrid>
                                </Grid> */}
                                {/* <DividerWithTextBox>
                                    <Divider>or login with</Divider>
                                </DividerWithTextBox> */}
                                <form onSubmit={handleSubmit(handleLogin)}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <RenderField
                                                name="email"
                                                control={control}
                                                label="Email Address"
                                                id="user_email"
                                                type="text"
                                                fullWidth
                                                BootstrapInput={true}
                                                required
                                                customPatternName="email"
                                                placeholder="trump@maralago.com"
                                                adornment={true}
                                                endAdornment={
                                                    <StyledInputAdornment position="end">
                                                        <Email className={classes.inputIconsColor} />
                                                    </StyledInputAdornment>
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <RenderField
                                                name="password"
                                                control={control}
                                                label="Password"
                                                asterisk
                                                id="password"
                                                fullWidth
                                                BootstrapInput={true}
                                                type={showPassword['password'] ? 'text' : 'password'}
                                                required
                                                adornment={true}
                                                customPatternName="password"
                                                placeholder="Enter password"
                                                helperText="Use 8 or more characters with a mix of capital and small letters, numbers & symbols"
                                                endAdornment={
                                                    <StyledInputAdornment position="end">
                                                        <MyIconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={(e) => handleClickShowPassword('password')}
                                                        >
                                                            {showPassword['password'] ? <Visibility /> : <VisibilityOff />}
                                                        </MyIconButton>
                                                    </StyledInputAdornment>
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs textAlign={'end'}>
                                            <CustomButton
                                                id="forgotPassBtn"
                                                onClick={handleClickOpen}
                                                simple
                                                size="small"
                                                component={Link}
                                                to=""
                                            >
                                                Forgot Password?
                                            </CustomButton>
                                        </Grid>
                                        <Dialog open={open} onClose={handleForgotDialogClose} aria-labelledby="form-dialog-title">
                                            <DialogTitle id="form-dialog-title">Forgot your password?</DialogTitle>
                                            <DialogContent>
                                                <TextField
                                                    autoFocus
                                                    margin="dense"
                                                    id="login_name"
                                                    label="Email Address"
                                                    type="email"
                                                    fullWidth
                                                    onChange={(e) => handleForgotEmail(e)}
                                                />
                                                <DialogContentText>
                                                    <Typography variant="caption">
                                                        Please enter the email you use to sign in to TPE.
                                                    </Typography>
                                                </DialogContentText>
                                            </DialogContent>
                                            <DialogActions>
                                                <CustomButton onClick={handleForgotDialogClose}>Cancel</CustomButton>
                                                <CustomButton onClick={handleResetCode} variant="contained">
                                                    Send Reset code
                                                </CustomButton>
                                            </DialogActions>
                                        </Dialog>
                                    </Grid>
                                    <CalCardActions>
                                        <CustomButton
                                            className="login-btn"
                                            type="submit"
                                            ps={'9px 30px'}
                                            variant="contained"
                                            disabled={isSubmitting}
                                        >
                                            Log In
                                        </CustomButton>
                                    </CalCardActions>
                                    <Box textAlign={'end'}>
                                        <Typography variant="h6">
                                            Not a member yet?{' '}
                                            <CustomButton simple size="small" component={Link} to="/sign-up">
                                                Register now
                                            </CustomButton>
                                        </Typography>
                                    </Box>
                                </form>
                            </CardContent>
                        </LoginCard>
                        {/* </SubboxWrapperBox> */}
                    </LoginBox>
                </LeftRightGrid>
                <LeftRightGrid item xs={12} md={7} className="right-side">
                    <SubboxWrapperBox>
                        <Typography variant="subtitle1">A financial move</Typography>
                        <Typography variant="subtitle1" ml={'37%'}>
                            is smart move
                        </Typography>
                        {/* <PiggyBox></PiggyBox> */}
                        <img src="/img/login-piggy.png" className="login-piggy" alt='tpe login piggy' />
                    </SubboxWrapperBox>
                </LeftRightGrid>
            </Grid>
        </BgBox>
    );
}
