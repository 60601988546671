export const getColumnName = (column) => {
    switch (column) {
        case '1 Year Returns':
            return 'one_year_returns';

        case '3 Year Returns':
            return 'three_year_return';

        case 'BM 1 Year Returns':
            return 'bm_annual_return';

        case 'BM 3 Year Returns':
            return 'bm_3_year_returns';

        case 'Beta':
            return 'beta';

        case 'CCC & Below B':
            return 'credit_rating_Below_B';

        case 'Cash & Net Other Assets':
            return 'credit_rating_cash_and_net_assets';

        case 'Country Exposure 1':
            return 'country_exposure_1';

        case 'Country Exposure 1 %':
            return 'country_exposure_1_per';

        case 'Country Exposure 2':
            return 'country_exposure_2';

        case 'Country Exposure 2 %':
            return 'country_exposure_2_per';

        case 'Country Exposure 3':
            return 'country_exposure_3';

        case 'Country Exposure 3 %':
            return 'country_exposure_3_per';

        case 'Country Exposure 4':
            return 'country_exposure_4';

        case 'Country Exposure 4 %':
            return 'country_exposure_4_per';

        case 'Country Exposure 5':
            return 'country_exposure_5';

        case 'Country Exposure 5 %':
            return 'country_exposure_5_per';

        case 'Country Exposure 6':
            return 'country_exposure_6';

        case 'Country Exposure 6 %':
            return 'country_exposure_6_per';

        case 'Country Exposure 7':
            return 'country_exposure_7';

        case 'Country Exposure 7 %':
            return 'country_exposure_7_per';

        case 'Country Exposure 8':
            return 'country_exposure_8';

        case 'Country Exposure 8 %':
            return 'country_exposure_8_per';

        case 'Country Exposure 9':
            return 'country_exposure_9';

        case 'Country Exposure 9 %':
            return 'country_exposure_9_per';

        case 'Country Exposure 10':
            return 'country_exposure_10';

        case 'Country Exposure 10 %':
            return 'country_exposure_10_per';

        case 'Duration':
            return 'duration';

        case 'Exp Ratio':
            return 'exp_ratio';

        case 'Fund Name':
            return 'fund_name';

        case 'Not Rated/Not Available':
            return 'credit_rating_not_rated';

        case 'Price':
            return 'price';

        case 'Legal type':
            return 'quote_type';

        case 'Broad Asset type':
            return 'broad_category';

        case 'Sector/Industry 1':
            return 'sector_or_industry_1';

        case 'Sector/Industry 1 %':
            return 'sector_or_industry_1_per';

        case 'Sector/Industry 2':
            return 'sector_or_industry_2';

        case 'Sector/Industry 2 %':
            return 'sector_or_industry_2_per';

        case 'Sector/Industry 3':
            return 'sector_or_industry_3';

        case 'Sector/Industry 3 %':
            return 'sector_or_industry_3_per';

        case 'Sector/Industry 4':
            return 'sector_or_industry_4';

        case 'Sector/Industry 4 %':
            return 'sector_or_industry_4_per';

        case 'Sector/Industry 5':
            return 'sector_or_industry_5';

        case 'Sector/Industry 5 %':
            return 'sector_or_industry_5_per';

        case 'Sharpe Ratio':
            return 'sharpe_ratio';

        case 'Short-Term Rated':
            return 'credit_rating_short_term_rated';

        case 'Ticker':
            return 'ticker';

        case 'Top holdings 1':
            return 'top_holdings_1';

        case 'Top holdings 1 %':
            return 'top_holdings_1_per';

        case 'Top holdings 2':
            return 'top_holdings_2';

        case 'Top holdings 2 %':
            return 'top_holdings_2_per';

        case 'Top holdings 3':
            return 'top_holdings_3';

        case 'Top holdings 3 %':
            return 'top_holdings_3_per';

        case 'Top holdings 4':
            return 'top_holdings_4';

        case 'Top holdings 4 %':
            return 'top_holdings_4_per';

        case 'Top holdings 5':
            return 'top_holdings_5';

        case 'Top holdings 5 %':
            return 'top_holdings_5_per';

        case 'Top holdings 6':
            return 'top_holdings_6';

        case 'Top holdings 6 %':
            return 'top_holdings_6_per';

        case 'Top holdings 7':
            return 'top_holdings_7';

        case 'Top holdings 7 %':
            return 'top_holdings_7_per';

        case 'Top holdings 8':
            return 'top_holdings_8';

        case 'Top holdings 8 %':
            return 'top_holdings_8_per';

        case 'Top holdings 9':
            return 'top_holdings_9';

        case 'Top holdings 9 %':
            return 'top_holdings_9_per';

        case 'Top holdings 10':
            return 'top_holdings_10';

        case 'Top holdings 10 %':
            return 'top_holdings_10_per';

        case 'U.S. Government':
            return 'credit_rating_US_gov';

        case 'credit rating A':
            return 'credit_rating_A';

        case 'credit rating AA':
            return 'credit_rating_AA';

        case 'credit rating AAA':
            return 'credit_rating_AAA';

        case 'credit rating B':
            return 'credit_rating_B';

        case 'credit rating BB':
            return 'credit_rating_BB';

        case 'credit rating BBB':
            return 'credit_rating_BBB';

        case 'owned fund':
            return 'owned_fund';
    }
};
