import {
  infoColor,
  roseColor,warningColor,
  successColor,
} from "assets/jss/material-kit-react";

const blogPageStyle = (theme)=>({
    addNewCatBlock: {
    float: "right",
    display: "flex",
    color: roseColor,
    cursor: "pointer",
    "&:hover": {
      color: "#e82366b3",
    },
    "& span": { fontWeight: 600 },
  },
  resizeSvg: {
    "& svg": {
      width: "0.8em",
      height: "0.8em",
    },
  },
  deleteAddNewCatWapper: {
    display: "flex",
    width: "60%",
    alignItems: "center",
    "& .deleteAddNewCat": {},
  },
})

export default blogPageStyle