import * as ActionTypes from '../actions/ActionTypes';

const initialState = {
    mortgageRefinanceData: {},
    loader: false
};

export const MortgageRefinanceReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.MORTGAGE_REFINANCE_CAL_INIT:
            return { ...state, loader: true };
        case ActionTypes.MORTGAGE_REFINANCE_CAL_SUCCESS:
            return {
                ...state,
                loader: false,
                mortgageRefinanceData: { data: action.data, sync_date: action.sync_date }
            };
        case ActionTypes.MORTGAGE_REFINANCE_CAL_ERROR:
            return { ...state, loader: false };

        default:
            return state;
    }
};
