import * as ActionTypes from "../actions/ActionTypes";

const initialState = {
  todoList: {},
  searchTodoList: {},
  loader: false,
  categoryList: [],
  submitTodoSuccess: false,
  lifeData: [],
  periodGraphData: [],
  enableGif: false,
};

export const TodoReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_TODOS_INIT:
    case ActionTypes.FETCH_TODO_CATEGORY_INIT:
    case ActionTypes.DELETE_TODO_INIT:
    case ActionTypes.TODO_PERIODICALLY_GRAPH_INIT:
    case ActionTypes.TODO_LIFE_GRAPH_INIT:
      return { ...state, loader: true };
    case ActionTypes.FETCH_TODOS_SUCCESS:
      return {
        ...state,
        loader: false,
        todoList: action.data,
      };
    case ActionTypes.FETCH_TODOS_ERROR:
    case ActionTypes.SEARCH_TODO_ERROR:
    case ActionTypes.FETCH_TODO_CATEGORY_ERROR:
    case ActionTypes.DELETE_TODO_SUCCESS:
    case ActionTypes.DELETE_TODO_ERROR:
      return { ...state, loader: false };

    case ActionTypes.FETCH_TODO_CATEGORY_SUCCESS:
      return {
        ...state,
        loader: false,
        categoryList: action.data,
      };
    case ActionTypes.SUBMIT_TODO_INIT:
    case ActionTypes.UPDATE_TODO_INIT:
      return { ...state, loader: false };
    case ActionTypes.SUBMIT_TODO_SUCCESS:
    case ActionTypes.UPDATE_TODO_SUCCESS:
      return {
        ...state,
        loader: false,
        submitTodoSuccess: true,
      };
    case ActionTypes.SUBMIT_TODO_ERROR:
    case ActionTypes.UPDATE_TODO_ERROR:
    case ActionTypes.TODO_LIFE_GRAPH_ERROR:
    case ActionTypes.TODO_PERIODICALLY_GRAPH_ERROR:
      return {
        ...state,
        loader: false,
        submitTodoSuccess: false,
      };
    case ActionTypes.SEARCH_TODO_INIT:
      return { ...state, loader: true };
    case ActionTypes.SEARCH_TODO_SUCCESS:
      return {
        ...state,
        loader: false,
        searchTodoList: action.data,
      };
    case ActionTypes.TODO_LIFE_GRAPH_SUCCESS:
      return {
        ...state,
        loader: false,
        lifeData: action.data,
      };
    case ActionTypes.TODO_PERIODICALLY_GRAPH_SUCCESS:
      return {
        ...state,
        loader: false,
        periodGraphData: action.data,
      };
    case ActionTypes.TRIGGER_TODO_GIF:
      return {
        ...state,
        enableGif: action.data,
      };

    default:
      return state;
  }
};
