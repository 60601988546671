const feedbackPage = (theme) => ({
  feedbackContainer: {
    "& .feedbackbtn": {
      transform: "rotate(-90deg) translate(50%, -50%)",
      transformOrigin: "100% 50%",
      padding: "0 16px 0 14px",
      right: "-2px",
      top: "56%",
      marginTop: "-20px",
      bottom: "auto",
      animation: "feedbackSlideLeft .2s linear",
      borderBottom: "2px solid transparent",
      boxShadow: "0 0 30px rgb(0 0 0 / 20%)",
      display: "block",
      position: "fixed",
      zIndex: "1100",
      height: "40px",
      lineHeight: "40px",
      "&:hover": {
        content: "''",
        // position: "absolute",
        bottom: "0",
        backgroundColor: "rgba(17,129,21,1)",
      },
    },
  },
  ratingdiv: {
    display: "flex",
      flexDirection: "column",
    marginBottom:10,alignItems:'center',
    "& > * + *": {
      marginTop: theme.spacing(1),
    },
    "& .MuiRating-root": {
      color: "#ffb400",
      cursor: "pointer",
      display: "inline-flex",
      position: "relative",
      textAlign: "left",
      WebkitTapHighlightColor: "transparent",
    },
    "& label": {
          fontSize: "1.5rem",color:'unset'

    }
  },
});

export default feedbackPage;
