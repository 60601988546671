import * as ActionTypes from "../actions/ActionTypes";

const initialState = {
  optionPricingCalDetail: {},
  loader: false,
};

export const OptionPricingCalReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.OPTION_PRICING_CAL_INIT:
      return { ...state, loader: true };
    case ActionTypes.OPTION_PRICING_CAL_SUCCESS:
      let formattedResponse = [];
      if (Object.keys(action.data).length > 0) {
        for (let index = 0; index < action.data.ans.length; index++) {
          const label = index == 0 ? "Call Price" : "Put Price";
          const value = action.data.ans[index];
          formattedResponse.push({ label, value });
        }
      }
      return {
        ...state,
        loader: false,
        optionPricingCalDetail: { ans: formattedResponse },
      };
    case ActionTypes.OPTION_PRICING_CAL_ERROR:
      return { ...state, loader: false };

    default:
      return state;
  }
};
