import * as ActionTypes from './ActionTypes';
import { apiCall, handleError } from 'utils/Utils';
import Globals from 'Globals.js';
import { toast } from 'react-toastify';
import { homePurchFiles, homePurchTool } from 'HomePurchase.json';

/**
 * @author disha
 * @param {*} data
 * @use to calculate home valuation
 * @returns
 */
export const submitHomeValuationCal =
    (data, cb = null) =>
    async (dispatch) => {
        try {
            dispatch(homeValuationCalInit());
            const apiResponse = await apiCall(`${Globals.API_ROOT_URL}homevaluationtool`, 'post', data);
            if (apiResponse !== undefined && apiResponse.status === 200) {
                if (cb) {
                    cb();
                }
                dispatch(homeValuationCalSuccess(apiResponse.data));
            } else {
                handleError(apiResponse, dispatch);
                dispatch(homeValuationCalError());
            }
        } catch (error) {
            toast.error(Globals.errorMsg);
            dispatch(homeValuationCalError());
            throw error;
        }
    };
// handle loader when api started to call
export const homeValuationCalInit = () => {
    return {
        type: ActionTypes.HOME_VALUATION_CAL_INIT
    };
};
//handle success of api
export const homeValuationCalSuccess = (val) => {
    return {
        type: ActionTypes.HOME_VALUATION_CAL_SUCCESS,
        data: val
    };
};
// handle api error
export const homeValuationCalError = () => {
    return {
        type: ActionTypes.HOME_VALUATION_CAL_ERROR
    };
};
/**
 * @author disha
 * @param {*} data
 * @use to get saved cal home valuation data
 * @returns
 */
export const fetchSavedData = () => async (dispatch) => {
    try {
        dispatch(fetchSavedDataInit());
        const apiResponse = await apiCall(`${Globals.API_ROOT_URL}homevaluationtool`, 'get');
        if (apiResponse !== undefined && apiResponse.status === 200) {
            dispatch(fetchSavedDataSuccess(apiResponse.data.data));
        } else {
            if (apiResponse.status === 401) {
                dispatch(fetchSavedDataSuccess({}));
            } else {
                const errMsg = apiResponse?.data?.error?.message;
                toast.error(errMsg || Globals.errorMsg);
                dispatch(fetchSavedDataError());
            }
        }
    } catch (error) {
        toast.error(Globals.errorMsg);
        dispatch(fetchSavedDataError());
        throw error;
    }
};
// handle loader when api started to call
export const fetchSavedDataInit = () => {
    return {
        type: ActionTypes.FETCH_SAVED_DATA_INIT
    };
};
//handle success of api
export const fetchSavedDataSuccess = (val) => {
    return {
        type: ActionTypes.FETCH_SAVED_DATA_SUCCESS,
        data: val
    };
};
// handle api error
export const fetchSavedDataError = () => {
    return {
        type: ActionTypes.FETCH_SAVED_DATA_ERROR
    };
};

//handle success of api
export const submitHomePurchSuccess = (val) => {
    return {
        type: ActionTypes.SUBMIT_HOME_PURCH_SUCCESS,
        data: val
    };
};

// handle api error
export const submitHomePurchError = () => {
    return {
        type: ActionTypes.SUBMIT_HOME_PURCH_ERROR
    };
};
/**
 * @author disha
 * @param {*} data
 * @returns
 * @use submit Home purchase tool
 */
export const submitHomePurch = (data, type) => async (dispatch) => {
    try {
        dispatch({
            type: ActionTypes.SUBMIT_HOME_PURCH_INIT
        });
        const apiResponse = await apiCall(`${Globals.API_ROOT_URL}homepurchaseuplaod`, type, data);
        if (apiResponse !== undefined && apiResponse.status === 200) {
            dispatch(submitHomePurchSuccess(true));
            dispatch(fetchHomePurchFiles());
            return toast.success(apiResponse.data.data.message);
        } else {
            handleError(apiResponse, dispatch);
            dispatch(submitHomePurchError());
        }
    } catch (error) {
        toast.error(Globals.errorMsg);
        dispatch(submitHomePurchError());
        throw error;
    }
};
//handle success of api
export const submitHomePurchSmartTableSuccess = (val) => {
    return {
        type: ActionTypes.SUBMIT_HOME_PURCH_SMART_TABLE_SUCCESS,
        data: val
    };
};

// handle api error
export const submitHomePurchSmartTableError = () => {
    return {
        type: ActionTypes.SUBMIT_HOME_PURCH_SMART_TABLE_ERROR
    };
};
/**
 * @author disha
 * @param {*} data
 * @returns
 * @use submit Home purchase tool
 */
export const submitHomePurchSmartTable = (data, type) => async (dispatch) => {
    try {
        dispatch({
            type: ActionTypes.SUBMIT_HOME_PURCH_SMART_TABLE_INIT
        });
        const apiResponse = await apiCall(`${Globals.API_ROOT_URL}homepurchasesmarttable`, type, data);
        if (apiResponse !== undefined && apiResponse.status === 200) {
            let dialogFlag = true;
            dispatch(submitHomePurchSmartTableSuccess(dialogFlag));
            return toast.success(apiResponse.data.data.message);
        } else {
            handleError(apiResponse, dispatch);
            dispatch(submitHomePurchSmartTableError());
        }
    } catch (error) {
        toast.error(Globals.errorMsg);
        dispatch(submitHomePurchSmartTableError());
        throw error;
    }
};
//handle success of api
export const fetchHomePurchFilesSuccess = (val) => {
    return {
        type: ActionTypes.FETCH_HOME_PURCH_FILES_SUCCESS,
        data: val
    };
};

// handle api error
export const fetchHomePurchFilesError = () => {
    return {
        type: ActionTypes.FETCH_HOME_PURCH_FILES_ERROR
    };
};
/**
 * @author disha
 * @param {*} data
 * @returns
 * @use submit Home purchase tool
 */
export const fetchHomePurchFiles = () => async (dispatch) => {
    try {
        dispatch({
            type: ActionTypes.FETCH_HOME_PURCH_FILES_INIT
        });
        const apiResponse = await apiCall(`${Globals.API_ROOT_URL}homepurchaseuplaod`, 'get');
        if (apiResponse !== undefined && apiResponse.status === 200) {
            dispatch(fetchHomePurchFilesSuccess(apiResponse.data.data));
        } else {
            if (apiResponse.status === 401) {
                dispatch(fetchHomePurchFilesSuccess(homePurchFiles));
            } else {
                handleError(apiResponse, dispatch);
                dispatch(fetchHomePurchFilesError());
            }
        }
    } catch (error) {
        toast.error(Globals.errorMsg);
        dispatch(fetchHomePurchFilesError());
        throw error;
    }
};
//handle success of api
export const fetchSelectedHomePurchSuccess = (val) => {
    return {
        type: ActionTypes.FETCH_HOME_PURCH_SELECTED_SUCCESS,
        data: val
    };
};

// handle api error
export const fetchSelectedHomePurchError = () => {
    return {
        type: ActionTypes.FETCH_HOME_PURCH_SELECTED_ERROR
    };
};
/**
 * @author disha
 * @param {*} data
 * @returns
 * @use submit Home purchase tool
 */
export const fetchSelectedHomePurch = (data) => async (dispatch) => {
    try {
        dispatch({
            type: ActionTypes.FETCH_HOME_PURCH_SELECTED_INIT
        });
        const apiResponse = await apiCall(`${Globals.API_ROOT_URL}homepurchasetool`, 'post', data);
        if (apiResponse !== undefined && apiResponse.status === 200) {
            dispatch(fetchSelectedHomePurchSuccess(apiResponse.data.data));
        } else {
            if (apiResponse.status === 401) {
                dispatch(fetchSelectedHomePurchSuccess(homePurchTool));
            } else {
                handleError(apiResponse, dispatch);
                dispatch(fetchSelectedHomePurchError());
            }
        }
    } catch (error) {
        toast.error(Globals.errorMsg);
        dispatch(fetchSelectedHomePurchError());
        throw error;
    }
};

//handle success of api
export const UpdateSelectedHomePurchSuccess = (val) => {
    return {
        type: ActionTypes.UPDATE_HOME_PURCH_SELECTED_SUCCESS,
        data: val
    };
};

export const fetchHomePurchaseCollaborationSuccess = (val) => {
    return {
        type: ActionTypes.FETCH_HOME_PURCHASE_COLL_SUCCESS,
        data: val
    };
};

// handle api error
export const fetchHomePurchaseCollaborationError = () => {
    return {
        type: ActionTypes.FETCH_HOME_PURCHASE_COLL_ERROR
    };
};

/**
 * @author disha
 * @param {*} data
 * @returns
 * @use to fetch the investment dashboard data
 */
export const fetchHomePurchaseCollaborationData = (data) => async (dispatch) => {
    try {
        dispatch({
            type: ActionTypes.FETCH_HOME_PURCHASE_COLL_INIT
        });
        const apiResponse = await apiCall(`${Globals.API_ROOT_URL}homepurchasecoll`, 'get');
        if (apiResponse !== undefined && apiResponse.status === 200) {
            dispatch(fetchHomePurchaseCollaborationSuccess(apiResponse.data.data));
        } else {
            if (apiResponse.status === 401) {
                dispatch(fetchHomePurchaseCollaborationSuccess({}));
            } else {
                handleError(apiResponse, dispatch);
                dispatch(fetchHomePurchaseCollaborationError());
            }
        }
    } catch (error) {
        toast.error(Globals.errorMsg);
        dispatch(fetchHomePurchaseCollaborationError());
        throw error;
    }
};

//handle success of api
export const submitHomePurchaseCollaborationSuccess = (val) => {
    return {
        type: ActionTypes.SUBMIT_HOME_PURCHASE_COLL_SUCCESS,
        data: val
    };
};

// handle api error
export const submitHomePurchaseCollaborationError = () => {
    return {
        type: ActionTypes.SUBMIT_HOME_PURCHASE_COLL_ERROR
    };
};

/**
 * @author disha
 * @param {*} data
 * @returns
 * @use to fetch the investment dashboard data
 */
export const submitHomePurchaseCollaborationData = (data, type) => async (dispatch) => {
    try {
        dispatch({
            type: ActionTypes.SUBMIT_HOME_PURCHASE_COLL_INIT
        });
        const apiResponse = await apiCall(`${Globals.API_ROOT_URL}homepurchasecoll`, type, data);
        if (apiResponse !== undefined && apiResponse.status === 200) {
            toast.success(apiResponse.data.data.message);
            dispatch(submitHomePurchaseCollaborationSuccess());
            dispatch(fetchHomePurchaseCollaborationData());
            //   dispatch(submitPeertoPeerSuccess(apiResponse.data.data));
        } else {
            handleError(apiResponse, dispatch);
            dispatch(submitHomePurchaseCollaborationError());
        }
    } catch (error) {
        toast.error(Globals.errorMsg);
        dispatch(submitHomePurchaseCollaborationError());
        throw error;
    }
};

//handle success of api
export const fetchHomePurchaseFileDataSuccess = (val) => {
    return {
        type: ActionTypes.FETCH_HOME_PURCHASE_FILE_DATA_SUCCESS,
        data: val
    };
};

// handle api error
export const fetchHomePurchaseFileDataError = () => {
    return {
        type: ActionTypes.FETCH_HOME_PURCHASE_FILE_DATA_ERROR
    };
};

/**
 * @author disha
 * @param {*} data
 * @returns
 * @use to fetch the investment dashboard data
 */
export const fetchHomePurchaseFileData = (data) => async (dispatch) => {
    try {
        dispatch({
            type: ActionTypes.FETCH_HOME_PURCHASE_FILE_DATA_INIT
        });
        const apiResponse = await apiCall(`${Globals.API_ROOT_URL}homepurchasecollfile`, 'get');
        if (apiResponse !== undefined && apiResponse.status === 200) {
            dispatch(fetchHomePurchaseFileDataSuccess(apiResponse.data.data));
        } else {
            handleError(apiResponse, dispatch);
            dispatch(fetchHomePurchaseFileDataError());
        }
    } catch (error) {
        toast.error(Globals.errorMsg);
        dispatch(fetchHomePurchaseFileDataError());
        throw error;
    }
};

//handle success of api
export const submitHomePurchaseFileDataSuccess = (val) => {
    return {
        type: ActionTypes.SUBMIT_HOME_PURCHASE_FILE_DATA_SUCCESS,
        data: val
    };
};

// handle api error
export const submitHomePurchaseFileDataError = () => {
    return {
        type: ActionTypes.SUBMIT_HOME_PURCHASE_FILE_DATA_ERROR
    };
};

/**
 * @author disha
 * @param {*} data
 * @returns
 * @use to fetch the investment dashboard data
 */
export const submitHomePurchaseFileData = (data, type) => async (dispatch) => {
    try {
        dispatch({
            type: ActionTypes.SUBMIT_HOME_PURCHASE_FILE_DATA_INIT
        });
        const apiResponse = await apiCall(`${Globals.API_ROOT_URL}homepurchasecollfile`, type, data);
        if (apiResponse !== undefined && apiResponse.status === 200) {
            toast.success(apiResponse.data.data.message);
            dispatch(submitHomePurchaseFileDataSuccess());
            // dispatch(fetchHomePurchaseFileData());
            //   dispatch(submitPeertoPeerSuccess(apiResponse.data.data));
        } else {
            handleError(apiResponse, dispatch);
            dispatch(submitHomePurchaseFileDataError());
        }
    } catch (error) {
        toast.error(Globals.errorMsg);
        dispatch(submitHomePurchaseFileDataError());
    }
};

export const UpdateSelectedHomePurchError = () => {
    return {
        type: ActionTypes.UPDATE_HOME_PURCH_SELECTED_ERROR
    };
};
/**
 * @author Leena
 * @param {*} data
 * @returns
 * @use submit Home purchase tool
 */

export const UpdateSelectedHomePurch = (data) => async (dispatch) => {
    try {
        dispatch({
            type: ActionTypes.UPDATE_HOME_PURCH_SELECTED_INIT
        });
        const apiResponse = await apiCall(`${Globals.API_ROOT_URL}homepurchasetool`, 'put', data);
        if (apiResponse !== undefined && apiResponse.status === 200) {
            dispatch(UpdateSelectedHomePurchSuccess(apiResponse.data.data));
            return toast.success(apiResponse.data.data.message);
        } else {
            handleError(apiResponse, dispatch);
            dispatch(UpdateSelectedHomePurchError());
        }
    } catch (error) {
        toast.error(Globals.errorMsg);
        dispatch(UpdateSelectedHomePurchError());
        throw error;
    }
};

export const HomePurchaseUrl = (data) => async (dispatch) => {
    try {
        const apiResponse = await apiCall(`${Globals.API_ROOT_URL}homepurchaseurl`, 'put', data);
        if (apiResponse !== undefined && apiResponse.status === 200) {
            return apiResponse.data.data;
        } else {
            handleError(apiResponse, dispatch);
        }
    } catch (error) {
        toast.error(Globals.errorMsg);
        throw error;
    }
};
