import {
    CardActions,
    CardContent,
    CardHeader,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Grid,
    Typography
} from '@mui/material';
import EnhancedTable from 'components/CommonComponent/CommonTable/EnhancedTable';
import NoDataFound from 'components/CommonComponent/NoDataFound';
import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { numberWithCommas } from 'utils/Utils';
import { CustomButton } from 'assets/jss/ButtonStyle';
import { BankDetailCard, BankTitleBox, PlaidCard, PlaidCardMedia } from 'assets/jss/SettingsStyle';
import { usePlaidLink } from 'react-plaid-link';
import { connectPlaid, generatePlaidToken, getConnectedAccountDetail } from 'redux/actions/SettingActions';
import { NewTaskRibbon } from 'assets/jss/GeneralStyle';

export const IntegrationIndex = (props) => {
    const dispatch = useDispatch();
    const setting = useSelector((state) => state.setting);
    const { link_token } = setting.plaidDetail?.plaid_token_detail;
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedBank, setSelectedBank] = useState();

    useEffect(() => {
        dispatch(getConnectedAccountDetail());
        dispatch(generatePlaidToken());
    }, []);

    const config = {
        token: link_token,
        onSuccess: (token) => dispatch(connectPlaid({ public_token: token }))
    };
    const { open, ready } = usePlaidLink(config);
    const columns = useMemo(() => {
        return [
            {
                Header: 'Account Name',
                accessor: 'name',
                disableFilters: true,
                Cell: ({ value, row }) => (row.original.official_name && `${value} ( ${row.original.official_name}`) || value || '-'
            },
            {
                Header: 'Type',
                accessor: 'type',
                disableFilters: true
            },
            {
                Header: 'Balance (Current)',
                accessor: 'balances',
                disableFilters: true,
                Cell: ({ value }) => '$' + numberWithCommas(value.toFixed(2)) || '-'
            }
        ];
    }, [setting.plaidDetail.user_detail]);

    // fires delete API of connectedbanks
    const disconnectConnectedBanks = (index) => {
        setSelectedBank(index);
        setOpenDialog(true);
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
        setSelectedBank(null);
    };

    const handleDialogConfirmDisconnect = () => {
        dispatch(
            getConnectedAccountDetail(
                {
                    id: setting.plaidDetail.user_detail[selectedBank].id
                },
                'delete'
            )
        );
        handleDialogClose();
    };

    return (
        <>  
            <CardContent>
            <Typography gutterBottom variant="h5" component="div">
                Coming Soon
                    </Typography>
            <Typography variant="body2" color="text.secondary">
            Remember you can always manually upload your portfolio and we will update the valuations automatically
            </Typography>
            </CardContent>

            <PlaidCard elevation={2}>
                {setting.profileDetail.role !== 'admin' &&
                <NewTaskRibbon>
                    <div className="wrap addRighPosition pricing-card-coming-soon">
                        <span className="ribbonLabel">Coming Soon</span>
                    </div>
                </NewTaskRibbon>}
                <PlaidCardMedia image="img/plaid-icon.svg" title="plaid icon" />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        Plaid
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        Safely connect your accounts and control access to them
                    </Typography>
                </CardContent>
                <CardActions className="plaid-action-wrapper">
                    {/* {Object.keys(setting.plaidDetail?.user_detail) === 0?  */}
                    <CustomButton
                        size="small"
                        variant="contained"
                        className="login-btn"
                        onClick={() => open()}
                        disabled={setting.profileDetail.role === 'admin' ? false : true}
                    >
                        Connect
                    </CustomButton>
                </CardActions>
            </PlaidCard>
            <BankDetailCard>
                <CardHeader title="Plaid Bank Information" />
                <CardContent>
                    {setting.plaidDetail.user_detail.length > 0 ? (
                        <Grid container spacing={2}>
                            {setting.plaidDetail.user_detail.map((val, i) => {
                                return (
                                    <Grid item xs="12" md="6" key={i + 'bank_list' + val.id}>
                                        <BankTitleBox>
                                            <Typography className="bankTitle">{val.bank_name} </Typography>
                                            <CustomButton size="small" variant="contained" onClick={() => disconnectConnectedBanks(i)}>
                                                Disconnect
                                            </CustomButton>
                                        </BankTitleBox>
                                        <Divider />
                                        <EnhancedTable
                                            enableSearch={false}
                                            columns={columns}
                                            data={val.accounts}
                                            enableSort={true}
                                            enablePagination={false}
                                        />
                                    </Grid>
                                );
                            })}
                        </Grid>
                    ) : (
                        <NoDataFound msg="Please connect your bank to view data." />
                    )}
                </CardContent>
            </BankDetailCard>
            <Dialog
                open={openDialog}
                onClose={handleDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{'Are you sure?'}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to remove the connection? We have saved your prior data so you will continue to have access to
                        that in our apps. If you would like to do delete your history please contact us at support@tpehub.com
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <CustomButton onClick={handleDialogClose}>Cancel</CustomButton>
                    <CustomButton autoFocus onClick={handleDialogConfirmDisconnect}>
                        Disconnect
                    </CustomButton>
                </DialogActions>
            </Dialog>
        </>
    );
};
