import { Card, CardContent, Divider, Grid, IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CachedIcon from '@mui/icons-material/Cached';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { CustomButton } from 'assets/jss/ButtonStyle';
import { CalCardActions } from 'assets/jss/RetirementStyle';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import RenderField from 'utils/RenderField';
import { getCookie } from 'utils/Utils';
import { SumbitHouseAffordablityCal } from 'redux/actions/HouseAffordablilityAction';
import { houseAffordCalSuccess } from 'redux/actions/HouseAffordablilityAction';

const ProductSection = (props) => {
    const defaultValues = {
        mortgage_rate: parseFloat(props.mortgage_rate[0]?.value, 10),
        term_years: 30,
        max_DTI: 36,
        monthly_fees: 600,
        down_payment_pct: 5,
        annual_income: props.annualIncome
    };
    // console.log(props.mortgage_rate[0]?.value, "dddddd");
    const dispatch = useDispatch();
    const [result, setResult] = useState([]);
    const [disabledLoadBtn, setDisabledLoadBtn] = useState(false);
    const {
        handleSubmit,
        control,
        formState: { isDirty, isSubmitting },
        setValue,
        getValues
    } = useForm({
        mode: 'onChange',
        defaultValues
    });

    const onSubmit = (data) => {
        let mortgage_rate = data.mortgage_rate.toString();
        let objToPass = {
            annual_income: data.annual_incomeUnFormattedValue || data.annual_income,
            mortgage_rate: mortgage_rate.replace('%', ''),
            term: data.term_yearsUnFormattedValue || data.term_years,
            down_payment_pct: data.down_payment_pctUnFormattedValue || data.down_payment_pct,
            max_DTI: data.max_DTIUnFormattedValue || data.max_DTI,
            monthly_fees: data.monthly_feesUnFormattedValue || data.monthly_fees
        };
        objToPass = { ...objToPass, save_data: data.save_data };
        dispatch(SumbitHouseAffordablityCal(objToPass));
    };

    const handleFormInputChange = () => {
        if (disabledLoadBtn) {
            setDisabledLoadBtn(false);
        }
        // reset saved output from redux state
        if (isDirty && result.length > 0) {
            dispatch(houseAffordCalSuccess({}));
        }
    };

    // to load saved data in form
    const handleLoadData = () => {
        setDisabledLoadBtn(true);
        const savedData = props.houseAffordData.houseAffordDetails;
        for (const key in savedData) {
            if (Object.hasOwnProperty.call(savedData, key)) {
                let element = savedData[key];
                setValue(key, element);
            }
        }
    };

    /**
     * @author Leena
     * @use to set the ouput of api into state
     */
    useEffect(() => {
        if (Object.keys(props.houseAffordData.houseAffordDetails).length) {
            setResult(props.houseAffordData.houseAffordDetails.data);
        } else {
            // to reset the output fields
            if (result.length > 0) setResult([]);
        }
    }, [props.houseAffordData]);

    /**
     * @author Ulyana
     * @use to set the mortage rate after we get it form the API
     */
    useEffect(() => {
        const data = getValues();
        if (props.mortgage_rate.length && !data.mortgage_rate) {
            setValue('mortgage_rate', parseFloat(props.mortgage_rate[0]?.value, 10));
        }
    }, [props.mortgage_rate]);

    return (
        <>
            <Card elevation={2}>
                <CardContent>
                    {Object.keys(props.houseAffordData.houseAffordDetails).length ? (
                        <Box color="text.primary">
                            <Typography variant="body2" component="p">
                                We have your saved data. Click button to load it.
                                <IconButton
                                    color="primary"
                                    aria-label="upload picture"
                                    component="label"
                                    disabled={disabledLoadBtn}
                                    onClick={handleLoadData}
                                >
                                    <CachedIcon color="white" />
                                </IconButton>
                                <Divider sx={{ mb: 1 }} />
                            </Typography>
                        </Box>
                    ) : (
                        ''
                    )}
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container>
                            <Grid item xs={12} md={6}>
                                <RenderField
                                    handleOnChange={handleFormInputChange}
                                    name="mortgage_rate"
                                    control={control}
                                    label="Mortgage Rate"
                                    id="mortgage_rate"
                                    options={props?.mortgage_rate}
                                    type="number"
                                    required
                                    customPatternName="decimalRate3"
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <RenderField
                                    handleOnChange={handleFormInputChange}
                                    name="term_years"
                                    control={control}
                                    label="Term in years"
                                    id="term_years"
                                    type="number"
                                    required
                                    thousandSeparator={true}
                                    customPatternName="price"
                                    placeholder="4"
                                    setValue={setValue}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <RenderField
                                    handleOnChange={handleFormInputChange}
                                    name="annual_income"
                                    control={control}
                                    label="Annual Income"
                                    id="annual_income"
                                    type="number"
                                    required
                                    prefix={'$'}
                                    thousandSeparator={true}
                                    customPatternName="price"
                                    placeholder="40,000"
                                    setValue={setValue}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <RenderField
                                    handleOnChange={handleFormInputChange}
                                    name="monthly_fees"
                                    control={control}
                                    label="Monthly Fees"
                                    id="monthly_fees"
                                    type="number"
                                    required
                                    prefix={'$'}
                                    thousandSeparator={true}
                                    customPatternName="price"
                                    placeholder="10,000"
                                    setValue={setValue}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <RenderField
                                    handleOnChange={handleFormInputChange}
                                    name="max_DTI"
                                    control={control}
                                    label="Max Debt to Income"
                                    id="max_DTI"
                                    type="number"
                                    required
                                    prefix={'%'}
                                    thousandSeparator={true}
                                    customPatternName="decimalRate"
                                    placeholder="0.6"
                                    setValue={setValue}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <RenderField
                                    handleOnChange={handleFormInputChange}
                                    name="down_payment_pct"
                                    control={control}
                                    label="Down Payment"
                                    id="down_payment_pct"
                                    type="number"
                                    required
                                    prefix={'%'}
                                    customPatternName="decimalRate"
                                    thousandSeparator={true}
                                    placeholder="0.3"
                                    setValue={setValue}
                                />
                            </Grid>
                        </Grid>
                        <CalCardActions>
                            <Grid container style={{ width: '100%', textAlign: 'center' }}>
                                <Grid item xs={12} md={6}>
                                    <CustomButton
                                        type="submit"
                                        ps={'9px 30px'}
                                        onClick={() => setValue('save_data', false)}
                                        variant="contained"
                                        // color="rose"
                                        disabled={isSubmitting}
                                    >
                                        Calculate
                                    </CustomButton>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <CustomButton
                                        type="button"
                                        ps={'9px 30px'}
                                        onClick={() => {
                                            setValue('save_data', true);
                                            onSubmit(getValues());
                                        }}
                                        variant="outlined"
                                        // color="success"
                                        disabled={isSubmitting}
                                    >
                                        Save
                                    </CustomButton>
                                </Grid>
                            </Grid>
                        </CalCardActions>
                    </form>
                </CardContent>
            </Card>
        </>
    );
};

export default ProductSection;
