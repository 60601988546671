import { Box } from '@mui/material';
import { styled } from '@mui/styles';

export const DescriptionBox = styled(Box)(({}) => ({
    margin: '10px',
    padding: '20px 10px',
    gap: '10px'
}));

export const CategoryRow = styled(Box)(({}) => ({
    display: 'flex',
    flexDirection: 'row',
    overflowX: 'scroll',
    alignItems: 'center',
    height: '560px'
}));

export const CategoryTitleBox = styled(Box)(({}) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    justifyContent: 'center',
    height: '530px',
    minWidth: '300px',
    borderRadius: '10px',
    gap: '20px',
    // backgroundColor: 'skyblue',
    margin: '10px',
    padding: '10px 10px 10px 70px'
}));

export const CategoryCard = styled(Box)(({ height, backgroundImage }) => ({
    height: height ? height : '150px',
    width: '100%',
    borderRadius: '0px',
    // backgroundColor: 'skyblue',
    // padding: '10px',
    margin: '0px',
    backgroundImage: `url(${backgroundImage})`,
    // backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    transition: 'all 0.3s ease-in-out'
    // '&:hover': {
    //     boxShadow: '0px 10px 10px 0px #00000054'
    // }
}));
export const CardTextBox = styled(Box)(({ backgroundImage }) => ({
    color: '#000',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'end',
    height: '100%',
    padding: '15px',
    // background: 'rgba(57, 57, 57, 0.1)'
    background: '#fff'
}));

export const BackArrowBox = styled(Box)(({ backgroundImage }) => ({
    position: 'absolute',
    zIndex: '7',
    // top: '50%',
    height: '60px',
    width: '60px',
    minWidth: '60px',
    borderRadius: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'
}));

export const PreviousArrowBox = styled(Box)(({ backgroundImage }) => ({
    position: 'absolute',
    zIndex: '7',
    right: '30px',
    // top: '50%',
    height: '60px',
    width: '60px',
    minWidth: '60px',
    borderRadius: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'
}));
