import { primaryColor, roseColor ,infoColor} from "assets/jss/material-kit-react.js";

const portfolioPageStyle = (theme) => ({
  portroot: {
    "& #sampleTitle.MuiTypography-gutterBottom": {
      color: primaryColor,
    },
    "& .MuiGrid-spacing-xs-8 > .MuiGrid-item": {
      padding: "32px",
    },
    "& .MuiDropzonePreviewList-image": {
      height: "auto",
      color: "rgba(0, 0, 0, 0.87)",
      width: "initial",
      maxWidth: "100%",
    },
    "& .MuiDropzonePreviewList-removeButton": {
      padding: "10px 10px 0px 1px",
      color: roseColor,
      zIndex: 2,
    },
    "& .MuiTypography-body1": {
      fontSize: "0.9rem",
      marginRight: "8px",
    },
    "& .MuiSnackbarContent-root": {
      color: "rgb(102, 60, 0)",
      backgroundColor: "rgb(255, 244, 229)",
      transition: ".5s ease",
      boxShadow:
        "0 12px 20px -10px rgb(255 244 229 / 28%), 0 4px 20px 0px rgb(0 0 0 / 12%), 0 7px 8px -5px rgb(255 152 0 / 20%)",
    },
    "& .MuiSnackbarContent-message .MuiSvgIcon-root": {
      color: "#ff9800",
    },
    "&  .MuiTabs-indicator": {
      backgroundColor: `${infoColor} !important`,
    },
    "& .MuiTab-textColorPrimary.Mui-selected": {
      color: `${infoColor} !important`,
    },
  },
  sampleImage: {
    width: "2.5em",
    height: "2.5em",
  },
  cardHeader: {
    padding: "0",
    position: "absolute",
    right: "0",
  },
  textarea: {
    width: "100%",
  },
  actionButton: {
    margin: "auto",
  },
  cardBody: {
    paddingBottom: "0",
    [theme.breakpoints.down("xs")]: {
      padding: "0.2rem 0.5rem",
    },
  },
  divider: {
    width: "100%",
    height: "2px",
    margin: "12px 0",
  },
  sampleContent: {
    padding: "0 !important",
    textAlign: "center",
    marginTop: "7px",
  },
  sampleCard: {
    margin: "0",
  },
  warningMsg: {
    // marginTop: "13px",
    fontWeight: "400",
  },
  tabBody: {
    boxShadow:
      "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
  },
  deletedFileDetail: {
    margin: "6px",
  },
  cardParent: {
    margin: "0",
  },
  summaryGridItem: {
    paddingLeft: "18px",
    [theme.breakpoints.down("xs")]: {
      padding: "0px 0px 16px 3px",
    },
  },
  portfolioContainer: {
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
  },
  main: {
    flexGrow: 1,
    "& .MuiCard-root": {
      borderRadius: "0",
    },
  },
  customTooltip: {
    margin: "0px",
    padding: "10px",
    backgroundColor: "rgb(255, 255, 255)",
    border: "1px solid rgb(204, 204, 204)",
    whiteSpace: "nowrap",
    display: "inline-flex",
  },
  customBarChartTooltip: {
    display: "inline-block",
    margin: "0px",
    padding: "10px",
    backgroundColor: "rgb(255, 255, 255)",
    border: "1px solid rgb(204, 204, 204)",
    whiteSpace: "nowrap",
  },
  barChartInnerParent: {
    display: "flex",
  },
  tooltipLabel: {
    margin: "0px",
  },
  colorBox: {
    width: "12px",
    height: "12px",
    margin: "4px 7px 0 0",
  },
  
  allocationOutputTable: {
    overflowY: "scroll",
  },
  allocationOutputGrid: {
    [theme.breakpoints.down("sm")]: {
      margin: "auto",
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: "-15px",
      marginRight: "-15px",
    },
  },
  transactionRecom: {
    marginLeft: "17px",
    textTransform: "initial",
  },
  transactionRecomWrapper: {
    marginBottom: "2em",
  },
  allocationOutputWrapper: {
    textTransform: "capitalize",
  },
  allocationForm: {
    "& #retirementAgelabel": { paddingTop: "35px" },
    "& #retirementAgeInput": { paddingTop: "39px" },
    "& #riskToleLabel": { marginTop: "24px" },
    "& #equityLabel p.MuiTypography-root": { marginTop: "17px" },
    "& .MuiInputAdornment-root": { marginBottom: "3px" },
  },
  chartParent: {
   "position":"relative","top":"auto","right":"auto","width":"auto","height":"500px","zIndex":"0"
  },
  chartWapper: {
   "position":"absolute","top":"2px","bottom":"63px","width":"100%"
  },
  portfolioTotalWrapper: {
    // paddingTop: "39px",
    width:'100%',
    "& .MuiTypography-root": {
      margin: "8px",
    },
    [theme.breakpoints.down("sm")]: {
      // paddingTop: "20px",
    },
    [theme.breakpoints.down("xs")]: {
      // paddingTop: "10px",
      "& .MuiTypography-root": {
        margin: "0 0 10px 0",
      },
    },
  },
  link: {
    display: "flex",
    fontSize: "inherit",
    fontWeight: 600,
    "& .MuiBadge-root": {
      marginRight: "20px",
    },
    "& svg": {
      marginLeft: "0.5vh",
    },
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
  submitPortSelectionBtn: {
    display: "flex",
    "& .MuiButtonBase-root": {
      margin: "auto",
      bottom: "6px",
    },
  },
  portfolioFileNameBread: {
    textOverflow: "ellipsis",
    maxWidth: "150px",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  portOutput: {
    width: "100%",
    margin: "0",
    "& .MuiGrid-root": {
      width: "100%",
      margin: 0,
      padding: "0px 5px 5px 3px",
    },
    "& .MuiCardHeader-root": {
      float: "right",
      padding: "0",
    },
    "& .MuiTypography-root": {
      float: "left",
      width: "inherit",
    },
  },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: "33.33%",
      flexShrink: 0,
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
    formControlLabel: {
      width: "100%",
      margin: 0,
  },
 offBtn: { minWidth: 52, display: 'flex', padding: '5px 0', margin: '0 auto' },
  sliderValue: { marginLeft: 11 }, sliderWrapperFilter: { display: 'flex' },
  appbarTab: {
    "& .MuiTab-wrapper": {
      fontWeight:600
    }
  },
  tabCardContent: {
    padding:0
  }, sampleFileLabel: { textAlign: 'center' },
  summaryToggleBtn: {
    '& .MuiCardHeader-action': {
      backgroundColor: '#e4e4e4',
    },
    '& .MuiButtonBase-root': {
borderRadius:'unset'
    },
    '& .subIconBtn.selected': {
          border:"1px #00acc1 solid",
    backgroundColor: "#00acc194"
    }
  }
});

export default portfolioPageStyle;
