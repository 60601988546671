import * as ActionTypes from '../actions/ActionTypes';

const initialState = {
    tableList: [],
    loader: false
};

export const ResearchTableReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.CREATE_NEW_TABLE_ERROR:
        case ActionTypes.CREATE_NEW_TABLE_SUCCESS:
        case ActionTypes.FETCH_ALL_TABLE_ERROR:
        case ActionTypes.DELETE_RESEARCH_TABLE_ERROR:
        case ActionTypes.DELETE_RESEARCH_TABLE_SUCCESS:
        case ActionTypes.UPDATE_TABLE_ERROR:
        case ActionTypes.UPDATE_TABLE_SUCCESS:
            return { ...state, loader: false };

        case ActionTypes.FETCH_ALL_TABLE_SUCCESS:
            return { ...state, tableList: action.data, loader: false };

        case ActionTypes.CREATE_NEW_TABLE_INIT:
        case ActionTypes.FETCH_ALL_TABLE_INIT:
        case ActionTypes.DELETE_RESEARCH_TABLE_INIT:
        case ActionTypes.UPDATE_TABLE_INIT:
            return { ...state, loader: true };
        default:
            return state;
    }
};
