import React, { useEffect, useState } from 'react';
import { Popover, Typography, Box, Tooltip } from '@mui/material';
import { fetchSelectedPortDetail } from 'redux/actions/PortfolioActions';
import { useDispatch, useSelector } from 'react-redux';
import Collapse from '@mui/material/Collapse';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Checkbox from '@mui/material/Checkbox';
import ListItemIcon from '@mui/material/ListItemIcon';
import { toast } from 'react-toastify';
import { StyledPortfolioBreadcrumb } from 'assets/jss/CommonCalStyle';
import { StyledBreadCrumbLink } from 'assets/jss/GeneralStyle';
import { CustomButton } from 'assets/jss/ButtonStyle';
import { StyledBadge } from 'assets/jss/CalculatorStyle';
import { fetchReloadPortfolio } from 'redux/actions/PortfolioActions';
import { submitProfile } from 'redux/actions/SettingActions';
import { useNavigate } from 'react-router-dom';
import { setActiveSettingsTab } from 'redux/actions/SettingActions';
import Button from 'components/CustomButtons/Button';

var refetchVal = false;
const PortfolioBreadcrumb = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { classes } = props;
    const setting = useSelector((state) => state.setting);
    const [selectedPortfolio, setSelectedPortfolio] = useState({});
    const reloadPortfolio = useSelector((state) => state.portfolio.reloadPortfolio);
    const [anchorEl, setAnchorEl] = useState(null);
    const [checked, setChecked] = React.useState([]);
    const openBredcrumbDropdown = Boolean(anchorEl);
    const id = openBredcrumbDropdown ? 'simple-popover' : undefined;
    // const [isInvestment, setIsInvestment] = useState(false);

    useEffect(() => {
        //auto select first portfolio file
        if (Object.keys(selectedPortfolio).length === 0 || refetchVal) {
            if (props.list.length > 0) {
                refetchVal = false;
                fetchFirstPortValue();
            } else {
                refetchVal = false;
                setSelectedPortfolio({});
                setChecked([]);
            }
        }
    }, [props.list]);

    useEffect(() => {
        //when file will delete from file upload tab then to update breadcrumb list
        if (props.renderBreadCrumb.length > 0) {
            const isEmpty = checked.filter((val) => props.renderBreadCrumb.indexOf(val) === -1);
            setChecked(isEmpty);
            if (isEmpty.length === 0) {
                refetchVal = true;
            }
            props.resetRenderBreadCrumb();
        }
    }, [props.renderBreadCrumb]);

    useEffect(() => {
        // when data will update in table then need to fetch updated list from api
        if (props.renderHoldingList) {
            handlePortfolioSelection();
            props.resetHoldingList();
        }
    }, [props.renderHoldingList]);

    useEffect(() => {
        // when data will update in table then need to fetch updated list from api
        if (props.manualLoad) {
            props.resetManualClick();
            // if (checked.length >= 3) {
            //     toast.error('Please select files less than 3 to speed the process or can have only 20-28 items for reload');
            // } else {
            dispatch(fetchReloadPortfolio({ ids: checked }));
            // }
        }
    }, [props.manualLoad]);

    useEffect(() => {
        if (reloadPortfolio == true) {
            dispatch(fetchSelectedPortDetail({ ids: checked }));
        }
    }, [reloadPortfolio]);

    // const fetchFirstPortValue = () => {
    //     const isSavedAvail = props.list.filter((val) => val.is_saved === true);
    //     if (isSavedAvail.length > 0) {
    //         const fid = props.list.filter((val) => val.is_saved === true).map(({ id }) => id);
    //         setChecked([fid[fid?.length - 1]]);
    //         setSelectedPortfolio(isSavedAvail[fid[fid?.length - 1]]);
    //         handlePortfolioSelection([fid[fid?.length - 1]]);
    //     } else {
    //         handleToggle(props.list[0].id);
    //         setSelectedPortfolio(props.list[0]);
    //         handlePortfolioSelection([props.list[0].id]);
    //     }
    // };

    const fetchFirstPortValue = () => {
        const isSavedAvail = props.list.filter((val) => val.is_saved === true);
        if (isSavedAvail.length > 0) {
            const fid = props.list.filter((val) => val.is_saved === true).map(({ id }) => id);
            setChecked(fid);
            setSelectedPortfolio(isSavedAvail[0]);
            handlePortfolioSelection(fid);
        } else {
            handleToggle(props.list[0].id);
            setSelectedPortfolio(props.list[0]);
            handlePortfolioSelection([props.list[0].id]);
        }
    };
    /**
     * trim file name with extention and return only file name
     * @param {*} name
     * @returns
     */
    const fetchFileName = (name) => {
        let fName = name;
        const currFileName = fName.split('.');
        const fileExt = currFileName.length > 1 ? '.' + currFileName[currFileName.length - 1] : '';
        return fName.replace(fileExt, '');
    };
    // call when need to fetch selected portfolio file
    const handlePortfolioSelection = (val = null, save = false) => {
        const idToPass = val || checked;
        setAnchorEl(null);
        if (idToPass.length > 0) {
            const updatedList = props.list.filter((val) => val.id === idToPass[0]);
            // props.setIsBankFile(updatedList[0]?.file_name?.toLowerCase().includes('bank'));
            let bankIds = [];
            if (checked.length > 0) {
                for (let id of checked) {
                    if (id.includes('*')) {
                        bankIds.push(id);
                    }
                }
                if (bankIds.length === checked.length) {
                    props.setIsBankFile(true);
                } else {
                    props.setIsBankFile(false);
                }
            }
            setSelectedPortfolio(updatedList[0]);
            props.selectedPortCount(checked);
            dispatch(fetchSelectedPortDetail({ ids: idToPass, is_saved: save || undefined }));
            // setIsInvestment(false);
        } else {
            toast.error('Please select at least one portfolio.');
        }
    };

    // const handleToggle = (value) => {
    //     for (let list of props.list) {
    //         if (list.id === value && list.type === 'investment') {
    //             setIsInvestment(true);
    //             props.setIsBankFile(true);
    //         } else {
    //             setIsInvestment(false);
    //             props.setIsBankFile(false);
    //         }
    //     }
    //     setChecked(value === (checked?.length && checked[0]) ? null : [value]);
    // };

    const handleToggle = (value) => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];
        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setChecked(newChecked);
    };

    const handleAllowAccess = () => {
        dispatch(submitProfile({ allow_investment_in_portfolio: true }));
    };
    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', flexWrap:'wrap' }}>
                <StyledPortfolioBreadcrumb>
                    <Link color="text.primary" underline="none" onClick={(e) => props.handleTabChange(e, 1)} component="button">
                        <Typography variant="subtitle1" id="my-portfolios">
                            {Object.keys(selectedPortfolio).length === 0 ? 'Click here to upload portfolio' : 'My Portfolios'}
                        </Typography>
                    </Link>
                    {Object.keys(selectedPortfolio).length > 0 ? (
                        <StyledBreadCrumbLink
                            color="textPrimary"
                            onClick={(event) => setAnchorEl(event.currentTarget)}
                            aria-describedby={id}
                            underline="none"
                        >
                            <Typography id="text-data" className={classes.portfolioFileNameBread}>
                                {fetchFileName(selectedPortfolio.file_name)}{' '}
                            </Typography>
                            {checked.length > 1 ? <StyledBadge badgeContent={`+${checked.length - 1}`} color="secondary" /> : ''}
                            {openBredcrumbDropdown ? <ExpandLess className={'arrow-icon'} /> : <ExpandMore className={'arrow-icon'} />}
                        </StyledBreadCrumbLink>
                    ) : (
                        ''
                    )}
                </StyledPortfolioBreadcrumb>
                {setting.plaidDetail.user_detail.length > 0 ? (
                    <Tooltip
                        title={
                            !setting.profileDetail.allow_investment_in_portfolio
                                ? 'We notced you already have linked a bank account. Would you like us to use this to access your portfolio files? '
                                : 'Already allowed'
                        }
                    >
                        <Box>
                            <Button
                                id="link"
                                size="small"
                                color="info"
                                onClick={handleAllowAccess}
                                disabled={setting.profileDetail.allow_investment_in_portfolio}
                            >
                                Allow to use External Account
                            </Button>
                        </Box>
                    </Tooltip>
                ) : (
                    <Tooltip title="Click here to use our highly secure parneter to connect you to your external acccount">
                        <Button
                            id="link"
                            size="small"
                            color="info"
                            onClick={() => {
                                navigate('/settings');
                                dispatch(setActiveSettingsTab(3));
                            }}
                        >
                            {setting.profileDetail.role === 'admin' ? 'Link External Account' : 'Coming Soon..'}
                        </Button>
                    </Tooltip>
                )}
            </Box>
            <Popover
                id={id}
                open={openBredcrumbDropdown}
                anchorEl={anchorEl}
                onClose={(e) => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
            >
                {props.list.map((val, listIndex) => {
                    return (
                        <List key={'breadcrumbList' + listIndex} component="nav" aria-labelledby="nested-list-subheader">
                            <Collapse in={openBredcrumbDropdown} timeout="auto" unmountOnExit>
                                <List
                                    component="div"
                                    disablePadding
                                    // onClick={() => handlePortfolioSelection(val)}
                                >
                                    <ListItem role={undefined} dense button onClick={() => handleToggle(val.id)}>
                                        <ListItemIcon>
                                            <Checkbox
                                                edge="start"
                                                checked={checked.indexOf(val.id) !== -1}
                                                tabIndex={-1}
                                                disableRipple
                                                inputProps={{ 'aria-labelledby': val.id }}
                                            />
                                        </ListItemIcon>
                                        <ListItemText id={val.id} primary={fetchFileName(val.file_name)} />
                                    </ListItem>
                                </List>
                            </Collapse>
                        </List>
                    );
                })}
                <Box sx={{ display: 'flex', justifyContent: 'space-evenly', marginBottom: 1 }}>
                    <CustomButton variant="contained" size="sm" color="info" onClick={() => handlePortfolioSelection()}>
                        Load Data!
                    </CustomButton>

                    <CustomButton variant="contained" size="sm" color="success" onClick={() => handlePortfolioSelection(null, true)}>
                        Save Settings
                    </CustomButton>
                </Box>
            </Popover>
        </>
    );
};

export default PortfolioBreadcrumb;
