import * as ActionTypes from './ActionTypes';
import { apiCall, handleError } from 'utils/Utils';
import Globals from 'Globals.js';
import { toast } from 'react-toastify';

// handle loader when api started to call
export const submitCreditCardCalInit = () => {
    return {
        type: ActionTypes.SUBMIT_CREDIT_CARD_CAL_INIT
    };
};
//handle success of api
export const submitCreditCardCalSuccess = (val) => {
    return {
        type: ActionTypes.SUBMIT_CREDIT_CARD_CAL_SUCCESS,
        data: val
    };
};
// handle api error
export const submitCreditCardCalError = () => {
    return {
        type: ActionTypes.SUBMIT_CREDIT_CARD_CAL_ERROR
    };
};
/**
 * @author disha
 * @param {*} data
 * @use to convert currency
 * @returns
 */
export const submitCreditCardCal = (data, type) => async (dispatch) => {
    const apiToken = localStorage.getItem('auth_token');
    try {
        dispatch(submitCreditCardCalInit());
        const apiResponse = await apiCall(`${Globals.API_ROOT_URL}creditcard_calculator`, type, data);
        if (apiResponse !== undefined && apiResponse.status === 200) {
            toast.success(apiResponse.data?.data?.message);
            if (apiToken) {
                dispatch(submitCreditCardCalSuccess());
                dispatch(fetchCreditCardCal());
            } else {
                dispatch({
                    type: ActionTypes.SUBMIT_CREDIT_CARD_DATA,
                    data: apiResponse?.data?.data
                });
            }
        } else {
            handleError(apiResponse, dispatch);
            dispatch(submitCreditCardCalError());
        }
    } catch (error) {
        toast.error(Globals.errorMsg);
        dispatch(submitCreditCardCalError());
        throw error;
    }
};

//handle success of api
export const fetchCreditCardCalSuccess = (val) => {
    return {
        type: ActionTypes.FETCH_CREDIT_CARD_CAL_SUCCESS,
        data: val
    };
};
// handle api error
export const fetchCreditCardCalError = () => {
    return {
        type: ActionTypes.FETCH_CREDIT_CARD_CAL_ERROR
    };
};
/**
 * @author disha
 * @param {*} data
 * @use to convert currency
 * @returns
 */
export const fetchCreditCardCal = () => async (dispatch) => {
    try {
        dispatch({
            type: ActionTypes.FETCH_CREDIT_CARD_CAL_INIT
        });
        const apiResponse = await apiCall(`${Globals.API_ROOT_URL}creditcard_calculator`, 'get');
        if (apiResponse !== undefined && apiResponse.status === 200) {
            dispatch(fetchCreditCardCalSuccess(apiResponse.data.data));
        } else {
            if (apiResponse.status === 401) {
                dispatch(fetchCreditCardCalSuccess({}));
            } else {
                handleError(apiResponse, dispatch);
                dispatch(fetchCreditCardCalError());
            }
        }
    } catch (error) {
        toast.error(Globals.errorMsg);
        dispatch(fetchCreditCardCalError());
        throw error;
    }
};

export const resetEditTableField = (val) => {
    return {
        type: ActionTypes.RESET_EDIT_TABLE_FIELD,
        data: val
    };
};
