import React, { useEffect, useState } from 'react';
import { Box, Card, CardContent, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { convertCurrencySuccess, convertCurrency } from 'redux/actions/FxCalActions';
import RenderField from 'utils/RenderField';
import { CurrencyList } from 'Currency.json';
import { useSelector } from 'react-redux';
import { CalCardActions } from 'assets/jss/RetirementStyle';
import { CustomButton } from 'assets/jss/ButtonStyle';
const FxCalForm = (props) => {
    const fxcalData = useSelector((state) => state.fxcal);
    const dispatch = useDispatch();
    const [currSign, setCurrSign] = useState({});
    const [result, setResult] = useState({});
    const {
        handleSubmit,
        control,
        formState: { isDirty, isSubmitting },
        setValue,
        getValues
    } = useForm({ mode: 'onChange' });

    /**
     * @author disha
     * @use to set the ouput of api into state
     */
    useEffect(() => {
        if (Object.keys(fxcalData.fxCalDetail).length) {
            setResult(fxcalData.fxCalDetail);
        } else {
            // to reset the output fields
            if (Object.keys(result).length > 0) setResult({});
        }
    }, [fxcalData]);

    // to reset redux stored value of result when page is changing
    useEffect(() => {
        return () => {
            dispatch(convertCurrencySuccess({}));
        };
    }, []);

    // handle submit button
    const onSubmit = (data) => {
        const objToPass = {
            Sell_Amt: data.sellAmountUnFormattedValue || data.sellAmount,
            FX_Sell_tk: data.currFrom.value,
            FX_Buy_tk: data.currTo.value
        };
        dispatch(convertCurrency(objToPass));
    };

    /**
     * @author disha
     * @use to reset the output if there is modification in form
     */
    const handleFormInputChange = (e, fromVal = null, name = null) => {
        // to save the currency symbol from "from" and "to " fields
        if (name !== null && fromVal !== null) {
            const newObj = { ...currSign, [name]: fromVal.symbol };
            setCurrSign(newObj);
            props.setCurrSignVal(newObj);
        }
        if (isDirty && Object.keys(result).length > 0) {
            // reset saved output from redux state
            // dispatch(convertCurrencySuccess({}));
        }
    };
    const handleOptionLabel = (option) => option.label;
    const handleOptions = () => {
        const list = CurrencyList.map((val) => {
            return {
                value: val.code,
                label: val.code + `(${val.name})`,
                symbol: val.symbol,
                flag: val.flag
            };
        });
        return list;
    };
    return (
        <Card elevation={2}>
            <CardContent>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container>
                        <Grid item xs={12} sm={5} margin="auto">
                            <Typography>Sell Amount *</Typography>
                        </Grid>
                        <Grid item xs={12} sm={7}>
                            <RenderField
                                handleOnChange={handleFormInputChange}
                                name="sellAmount"
                                fieldName="Sell Amount"
                                control={control}
                                label=""
                                id="sellAmount"
                                type="number"
                                required
                                prefix={currSign['currFrom']}
                                thousandSeparator={true}
                                customPatternName="decimalRate"
                                placeholder="100,000"
                                setValue={setValue}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <RenderField
                                handleOnChange={handleFormInputChange}
                                name="currFrom"
                                control={control}
                                label="From"
                                id="currFrom"
                                placeholder="Type to search..."
                                options={handleOptions()}
                                renderDisableOption={(option) => getValues().currTo?.value === option.value}
                                getOptionLabel={(option) => handleOptionLabel(option)}
                                renderOption={(props, option) => {
                                    return (
                                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                            {option.flag !== '' ? (
                                                <img loading="lazy" width="20" src={'img/flags/' + option.flag} alt="country flags" />
                                            ) : (
                                                ''
                                            )}
                                            <Typography variant="body2">{option.label}</Typography>
                                        </Box>
                                    );
                                }}
                                type="searchSelect"
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <RenderField
                                handleOnChange={handleFormInputChange}
                                name="currTo"
                                control={control}
                                label="To"
                                id="currTo"
                                getOptionLabel={(option) => handleOptionLabel(option)}
                                placeholder="Type to search..."
                                options={handleOptions()}
                                renderDisableOption={(option) => getValues().currFrom?.value === option.value}
                                renderOption={(props, option) => {
                                    return (
                                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                            {option.flag !== '' ? (
                                                <img loading="lazy" width="20" src={'img/flags/' + option.flag} alt="country flags" />
                                            ) : (
                                                ''
                                            )}
                                            <Typography variant="body2">{option.label}</Typography>
                                        </Box>
                                    );
                                }}
                                type="searchSelect"
                                required
                            />
                        </Grid>
                    </Grid>
                    <CalCardActions className="contactus-cardaction">
                        <CustomButton
                            type="submit"
                            ps={'9px 30px'}
                            variant="contained"
                            // color="rose"
                            disabled={isSubmitting}
                        >
                            Calculate
                        </CustomButton>
                    </CalCardActions>
                </form>
            </CardContent>
        </Card>
    );
};

export default FxCalForm;
