import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { CalculatorBox } from 'assets/jss/CalculatorStyle';
import { FAQGridContainer } from 'assets/jss/PricingStyle';

const FAQBlock = () => {
 
    return (
        <CalculatorBox disablemargin={true}>
            <Box className="title-box">
                <Typography variant="h3" sx={{ mt: 3.5, display: 'inline-block' }}>
                    Frequently Asked Questions
                </Typography>
                <FAQGridContainer container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} className='faq-grid-item'>
                                <Typography variant='h6'>Can I cancel my subscription anytime?</Typography>
                                <Typography variant='body1'>
                                    Yes, you can cancel your subscription, downgrade or upgrade your plan at any time. If you decide to
                                    cancel or downgrade your subscription within the first 15 days, you’ll get a full refund. No questions
                                    asked.{' '}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} className='faq-grid-item'>
                                <Typography variant='h6'>What is TPE’s refund policy?</Typography>
                                <Typography variant='body1'>
                                    You get a 7-day money-back guarantee on all plans. Submit your request via our Dashboard and we will
                                    refund the payment made if the request is made within 7 days after the plan comes into effect. In other
                                    words you get 15 days free, then the payment kicks in and you have a further 7 days to cancel.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} className='faq-grid-item'>
                                <Typography variant='h6'>What payment methods do you accept?</Typography>
                                <Typography variant='body1'>
                                    You can pay using the following payment methods: All major credit cards including Visa, Mastercard,
                                    Discover, American Express, and UnionPay
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} className='faq-grid-item'>
                            <Typography variant='h6'>Can I use TPE for free?</Typography>
                            <Typography variant='body1'>
                                Yes. With a free account, you can still use a lot of our tools, calculators and dashboards.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} className='faq-grid-item'>
                            <Typography variant='h6'>Am I charged when I enter my credit card?</Typography>
                            <Typography variant='body1'>
                                Our payment processor (Stripe) will charge a nominal amount to your credit card to confirm its validity;
                                once confirmed the test payment will immediately be refunded. Until your TPEHub trial period ends you won’t
                                be charged.
                            </Typography>
                        </Grid>
                    </Grid>
                </FAQGridContainer>
            </Box>
        </CalculatorBox>
    );
};
export default FAQBlock;
