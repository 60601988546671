import * as ActionTypes from "./ActionTypes";
import { apiCall, handleError } from "utils/Utils";
import Globals from "Globals.js";
import { toast } from "react-toastify";
// handle loader when api started to call
export const convertCurrencyInit = () => {
  return {
    type: ActionTypes.CONVERT_CURRENCY_INIT
  };
};
//handle success of api
export const convertCurrencySuccess = val => {
  return {
    type: ActionTypes.CONVERT_CURRENCY_SUCCESS,
    data: val
  };
};
// handle api error
export const convertCurrencyError = () => {
  return {
    type: ActionTypes.CONVERT_CURRENCY_ERROR
  };
};
/**
 * @author disha
 * @param {*} data
 * @use to convert currency
 * @returns
 */
export const convertCurrency = data => async dispatch => {
  try {
    dispatch(convertCurrencyInit());
    const apiResponse = await apiCall(
      `${Globals.API_ROOT_URL}fxconversiontool`,
      "post",
      data
    );
    if (apiResponse !== undefined && apiResponse.status === 200) {
      dispatch(convertCurrencySuccess(apiResponse.data.data));
    } else {
      handleError(apiResponse,dispatch);
      dispatch(convertCurrencyError());
    }
  } catch (error) {
    toast.error(Globals.errorMsg);
    dispatch(convertCurrencyError());
    throw error;
  }
};
