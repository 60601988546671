import React from 'react';
import { Helmet } from 'react-helmet';

const Meta = ({ title, description, page }) => {
    const isStaging = process.env.REACT_APP_NODE_ENV === 'staging';

    return (
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={description} />
            <link rel="canonical" href={`https://www.tpehub.com${page}`} />
            {isStaging && <meta name="robots" content="noindex, nofollow" />}
        </Helmet>
    );
};

export default Meta;
export const ArticlePage = ({ title, description, image, url }) => {
    return (
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={description} />

            {/* Google / Search Engine Tags */}
            <meta itemprop="name" content={title} />
            <meta itemprop="description" content={description} />
            <meta itemprop="image" content={image} />

            {/* Facebook Meta Tags */}
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={image} />
            <meta property="og:image:width" content="1200" />
            <meta property="og:image:height" content="630" />
            <meta property="og:url" content={url} />

            {/* Twitter Meta Tags */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content={image} />
        </Helmet>
    );
};
