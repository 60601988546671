import Typography from '@mui/material/Typography';
import React from 'react';
import { Card, Grid, ListItem } from '@mui/material';
import CardMedia from '@mui/material/CardMedia';
import { CustomButton } from 'assets/jss/ButtonStyle';
import { TodoBox, TodoCardContent, TodoListItemtext, TodoListTag } from 'assets/jss/LandingStyle';
import { useNavigate } from 'react-router-dom';

const TodoBlock = () => {
  const navigate = useNavigate();
    return (
        <TodoBox>
            <Card>
                <CardMedia
                    component="img"
                    onClick={() => navigate('/finance-task-list')}
                    className="todo-image add-pointer"
                    image="img/todo-landing.png"
                    alt="Live from space album cover"
                />
                <TodoCardContent>
                    <Typography
                        variant="h3"
                        sx={{ mt: 3.5, display: 'inline-block' }}
                        className="add-pointer"
                        onClick={() => navigate('/finance-task-list')}
                    >
                        TODO
                    </Typography>
                    <Typography variant="subtitle1" className="dashboard-subheading" sx={{ mb: 1.5, display: 'block' }}>
                        Organize your financial life.
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6} className="todo-grid-item">
                            <TodoListTag>
                                <ListItem>
                                    <TodoListItemtext primary="Add your tasks to Organize your life." />
                                </ListItem>
                                <ListItem>
                                    <TodoListItemtext primary="Achieve more every day." />
                                </ListItem>
                                <ListItem>
                                    <TodoListItemtext primary="Get mental peace you’ve been longing for." />
                                </ListItem>
                                <ListItem>
                                    <TodoListItemtext primary="Simple and easy to use." />
                                </ListItem>
                            </TodoListTag>
                        </Grid>
                    </Grid>
                    <CustomButton id="todo" variant="contained" ps={15} onClick={() => navigate('/finance-task-list')}>
                        Explore
                    </CustomButton>
                </TodoCardContent>
            </Card>
        </TodoBox>
    );
};

export default TodoBlock;
