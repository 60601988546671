import * as ActionTypes from "./ActionTypes";
import { apiCall, handleError } from "utils/Utils";
import Globals from "Globals.js";
import { toast } from "react-toastify";
// handle loader when api started to call
export const SubmitRetirementCalInit = () => {
  return {
    type: ActionTypes.SUBMIT_RETIRE_CAL_INIT
  };
};
//handle success of api
export const SubmitRetirementCalSuccess = (val) => {
  return {
    type: ActionTypes.SUBMIT_RETIRE_CAL_SUCCESS,
    data: val
  };
};

// handle api error
export const SubmitRetirementCalError = () => {
  return {
    type: ActionTypes.SUBMIT_RETIRE_CAL_ERROR
  };
};
/**
 * @author disha
 * @param {*} data
 * @returns
 * @use submit retirementcalculator data
 */
export const submitRetirementCal = (data,apiToken=null) => async dispatch => {
  try {
    dispatch(SubmitRetirementCalInit());
    const apiResponse = await apiCall(
      `${Globals.API_ROOT_URL}retirementcalculator`,
      "post",
      data
    );
    if (apiResponse !== undefined && apiResponse.status === 200) {
      dispatch(SubmitRetirementCalSuccess(apiResponse.data));
      apiToken && dispatch(fetchSavedData());
    } else {
      handleError(apiResponse, dispatch);
      dispatch(SubmitRetirementCalError());
    }
  } catch (error) {
    toast.error(Globals.errorMsg);
    dispatch(SubmitRetirementCalError());
    throw error;
  }
};

// handle loader when api started to call
export const fetchSavedDataInit = () => {
  return {
    type: ActionTypes.FETCH_SAVED_DATA__RETIRE_INIT
  };
};
//handle success of api
export const fetchSavedDataSuccess = (val) => {
  return {
    type: ActionTypes.FETCH_SAVED_DATA__RETIRE_SUCCESS,
    data: val
  };
};
// handle api error
export const fetchSavedDataError = () => {
  return {
    type: ActionTypes.FETCH_SAVED_DATA__RETIRE_ERROR
  };
};
/**
 * @author disha
 * @param {*} data
 * @use to get saved cal retirement data
 * @returns
 */
export const fetchSavedData = () => async dispatch => {
  try {
    dispatch(fetchSavedDataInit());
    const apiResponse = await apiCall(
      `${Globals.API_ROOT_URL}retirementcalculator`,
      "get"
    );
    if (apiResponse !== undefined && apiResponse.status === 200) {
      dispatch(fetchSavedDataSuccess(apiResponse.data.data));
    } else {
      handleError(apiResponse,dispatch);
      dispatch(fetchSavedDataError());
    }
  } catch (error) {
    toast.error(Globals.errorMsg);
    dispatch(fetchSavedDataError());
    throw error;
  }
};

export const fetchCurrentNetworth = async() => {
  try {
    const apiResponse = await apiCall(
      `${Globals.API_ROOT_URL}fetch_market_value`,
      "get"
    );
    if (apiResponse !== undefined && apiResponse.status === 200) {
      return apiResponse.data.data;
    } 
  } catch (error) {
    toast.error(Globals.errorMsg);
    throw error;
  }
};

