import { Box, Paper, Typography } from '@mui/material';
import { DescriptionBox } from 'assets/jss/KnowledgeResearchStyle';
import React from 'react';

const BlogCategoryDescription = ({ blogCategory }) => {
    return (
        <Paper elevation={3}>
            <DescriptionBox>
                <Typography variant="h2" style={{ textTransform: 'capitalize' }}>
                    {blogCategory}
                </Typography>
                <Typography variant="body1">
                    {blogCategory === 'All'
                        ? 'In this section, you can find all the interesting articles.'
                        : `In this section, you can find interesting articles related to ${blogCategory}.`}
                </Typography>
            </DescriptionBox>
        </Paper>
    );
};

export default BlogCategoryDescription;
