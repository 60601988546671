import { Card, CardContent, Grid } from '@mui/material';
import { CustomButton } from 'assets/jss/ButtonStyle';
import { CalCardActions } from 'assets/jss/RetirementStyle';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { getDefaultOverride, submitTipsCal, submitTipsCalSuccess } from 'redux/actions/GeneralCalActions';
import RenderField from 'utils/RenderField';
import { StudInputGrid, StudLabelGrid } from '../../assets/jss/CommonCalStyle';
import Typography from '@mui/material/Typography';

const clearValue = (val) => (typeof val === 'string' ? parseFloat(val.replace(/,/g, '')) : null);

const TipsCalcForm = ({ classes, TipsCalcData, defaultoverrideData }) => {
    const dispatch = useDispatch();
    const {
        handleSubmit,
        control,
        formState: { isDirty, isSubmitting },
        setValue
    } = useForm({
        mode: 'onChange',
        defaultValues: {
            total_bill: 100000,
            splitting_option: 2,
            tip_percent: 20
        }
    });
    //to submit the form data
    const onSubmit = (data) => {
        const objToSend = {
            total_bill: parseFloat(clearValue(data.total_bill)) || data.total_bill,
            splitting_option: data.splitting_option || 1,
            tip_percent: data.tip_percent || 0
        };
        dispatch(submitTipsCal(objToSend));
    };
    // when any input field value will change it will call below method
    const handleFormInputChange = (e) => {
        if (TipsCalcData && typeof TipsCalcData === 'object' && Object.keys(TipsCalcData).length > 0) {
            if (isDirty && Object.keys(TipsCalcData).length > 0) {
                dispatch(submitTipsCalSuccess({}));
            }
        }
    };

    useEffect(() => {
        if (Object.keys(defaultoverrideData).length === 0) {
            dispatch(getDefaultOverride());
        }
        // reset output when route will change
        return () => {
            dispatch(submitTipsCalSuccess({}));
        };
    }, []);

    return (
        <Card className={`${classes?.widgetCalCard}`} elevation={2}>
            <CardContent>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container>
                        <Grid item xs={12} sm={6}>
                            <RenderField
                                handleOnChange={handleFormInputChange}
                                name="total_bill"
                                control={control}
                                label="Total Bill"
                                id="total_bill"
                                type="number"
                                required
                                prefix={'$'}
                                thousandSeparator={true}
                                customPatternName="decimalRate"
                                placeholder="10,000"
                                setValue={setValue}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <RenderField
                                handleOnChange={handleFormInputChange}
                                name="splitting_option"
                                control={control}
                                label="Splitting Option"
                                id="splitting_option"
                                options={[
                                    { value: 1, label: 1 },
                                    { value: 2, label: 2 },
                                    { value: 3, label: 3 },
                                    { value: 4, label: 4 },
                                    { value: 5, label: 5 },
                                    { value: 6, label: 6 },
                                    { value: 7, label: 7 },
                                    { value: 8, label: 8 },
                                    { value: 9, label: 9 },
                                    { value: 10, label: 10 }
                                ]}
                                type="select"
                                // prefix={"$"}
                                thousandSeparator={true}
                                customPatternName="decimalRate"
                                placeholder="10"
                                setValue={setValue}
                            />
                        </Grid>
                        <StudLabelGrid item xs={12} md={6} lg={6}>
                            <Typography gutterBottom style={{ marginLeft: '18px' }}>
                                Tip Percent
                            </Typography>
                        </StudLabelGrid>
                        <StudInputGrid item xs={12} md={6} lg={6}>
                            <RenderField
                                handleOnChange={handleFormInputChange}
                                name="tip_percent"
                                control={control}
                                label="Tip Percent"
                                id="tip_percent"
                                type="slider"
                                max={30}
                                min={0}
                                step={1}
                                valueLabelDisplay="on"
                                // valueLabelFormat={(value) => value.toFixed(1)}
                                // required
                                prefix={'%'}
                                placeholder="10"
                            />
                        </StudInputGrid>
                    </Grid>
                    <CalCardActions className="contactus-cardaction">
                        <CustomButton
                            type="submit"
                            ps={'9px 30px'}
                            variant="contained"
                            // color="rose"
                            disabled={isSubmitting}
                        >
                            Calculate
                        </CustomButton>
                    </CalCardActions>
                </form>
            </CardContent>
        </Card>
    );
};

export default TipsCalcForm;
