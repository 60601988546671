import React from 'react';
import { Box, CardContent, FormControlLabel, Grid, Paper, Popover } from '@mui/material';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import NoDataFound from 'components/CommonComponent/NoDataFound';
import CustomSelect from 'components/CustomSelect/CustomSelect';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { UploadStyledAccordion } from 'assets/jss/GeneralStyle';
import { UploadCardParent } from 'assets/jss/GeneralStyle';
import { TopHeadingPaper } from 'assets/jss/GeneralStyle';
import EnhancedTable from 'components/CommonComponent/CommonTable/EnhancedTable';
import { CustomButton } from 'assets/jss/ButtonStyle';

const PortfolioOutput = (props) => {
    const { classes } = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectedFile, setSelectedFile] = React.useState([]);
    const [confirmDeleteDialog, setConfirmDeleteDialog] = React.useState(false);
    const container = React.useRef(null);
    const open = Boolean(anchorEl);
    const id = open ? 'action-popup' : undefined;

    // to open action popup
    const handlePopoverOpen = (event) => setAnchorEl(event.currentTarget);

    // to close action popup on outside click
    const handlePopoverClose = () => setAnchorEl(null);

    const handleDeleteConfirmation = () => setConfirmDeleteDialog(true);

    const handleCancelDeleteConfirmation = () => {
        handlePopoverClose();
        setConfirmDeleteDialog(false);
    };

    //handle selected files to delete and pass those ids to parent component
    const handleFileDelete = () => {
        props.deleteFiles(selectedFile);
        props.updateBreadCrumb(selectedFile);
        setSelectedFile([]);
        handleCancelDeleteConfirmation();
    };

    // store checked file
    const handleSelectedFileCheck = (e, id) => {
        if (e.target.checked === false) {
            const currId = selectedFile.indexOf(id);
            if (currId > -1) {
                setSelectedFile(selectedFile.filter((val) => val !== id));
            }
        } else {
            setSelectedFile([...selectedFile, id]);
        }
    };
    const columns = React.useMemo(() => {
        let arr=[];
        if (props.data.length > 0) {
              Object.keys(props.data[0].data[0]).forEach((val) => {
                      if (val !== 'pid') {
                          arr.push({
                              Header: val,
                              width: 100,
                              accessor: val,
                              disableFilters: true
                          });
                      }
              });
        }
        return arr;
    }, [props.data]);
    return (
        <>
            <TopHeadingPaper elevation={2}>
                <Typography variant="h6" sx={{ mb: 2, display: 'flex' }} gutterBottom className="top-heading-text">
                    My Portfolios
                </Typography>
                {props.data.length ? (
                    <IconButton aria-label="settings" onClick={handlePopoverOpen} aria-describedby={id}>
                        <MoreVertIcon />
                    </IconButton>
                ) : (
                    <></>
                )}
            </TopHeadingPaper>
            <UploadCardParent elevation={2} className={'portoutput'} id="portfolioOutputWrapper">
                <CardContent>
                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handlePopoverClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center'
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center'
                        }}
                    >
                        <List component="nav" aria-label="main mailbox folders">
                            <ListItem button onClick={(event) => handleDeleteConfirmation()}>
                                <ListItemText primary="Delete" />
                            </ListItem>
                        </List>
                    </Popover>
                    <Grid container>
                        <Grid item xs={12} md={12}>
                            {props.data.length > 0 ? (
                                props.data.map(({ data, file_name, id, who, what }, Uindex) => {
                                    const colList = [];
                                    if (data.length > 0) {
                                        for (const [key, value] of Object.entries(data[0])) {
                                            if (key !== 'pid') {
                                                // const KeyIndex = key.replace(/ /g, "_");
                                                // colList.push({
                                                //     Header: 'Company Name',
                                                //     accessor: 'Company Name',
                                                //     Cell: ({ value }) => value || '-'
                                                // });
                                                colList.push([key, key]);
                                            }
                                        }
                                    }
                                    return (
                                        <UploadStyledAccordion key={Uindex + 'uploadedFileDetail'}>
                                            <AccordionSummary
                                                ref={container}
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1bh-content"
                                                id="panel1bh-header"
                                            >
                                                <FormControlLabel
                                                    className={classes.formControlLabel}
                                                    aria-label="Acknowledge"
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <Checkbox
                                                            checked={selectedFile.indexOf(id) > -1 ? true : false}
                                                            inputProps={{
                                                                'aria-label': 'primary checkbox'
                                                            }}
                                                            name={id}
                                                            id={id}
                                                            onChange={(e) => handleSelectedFileCheck(e, id)}
                                                        />
                                                    }
                                                    label={
                                                        <>
                                                            <Grid container spacing={24}>
                                                                <Grid item xs={12}>
                                                                    <Box
                                                                        component="div"
                                                                        textOverflow="ellipsis"
                                                                        style={{ wordBreak: 'break-all' }}
                                                                    >
                                                                        {file_name}
                                                                    </Box>
                                                                </Grid>
                                                                {who && (
                                                                    <Grid item xs={12} sm={6} md={6}>
                                                                        <CustomSelect
                                                                            disableEmptyValue={true}
                                                                            labelText={'Who'}
                                                                            disabled
                                                                            defaultValue={who}
                                                                            name="who"
                                                                            id="who"
                                                                            formControlProps={{
                                                                                fullWidth: true
                                                                            }}
                                                                            options={[
                                                                                { value: 'me', label: 'For Me' },
                                                                                {
                                                                                    value: 'another',
                                                                                    label: 'For Another'
                                                                                }
                                                                            ]}
                                                                            size="small"
                                                                        />
                                                                    </Grid>
                                                                )}
                                                                {what && (
                                                                    <Grid item xs={12} sm={6} md={6}>
                                                                        <CustomSelect
                                                                            size="small"
                                                                            disableEmptyValue={true}
                                                                            labelText={'What'}
                                                                            name="what"
                                                                            disabled
                                                                            defaultValue={what}
                                                                            id="what"
                                                                            formControlProps={{
                                                                                fullWidth: true
                                                                            }}
                                                                            options={[
                                                                                { value: 'bank', label: 'Bank' },
                                                                                {
                                                                                    value: 'brokerage',
                                                                                    label: 'Brokerage'
                                                                                },
                                                                                { value: 'other', label: 'Other' }
                                                                            ]}
                                                                        />
                                                                    </Grid>
                                                                )}
                                                            </Grid>
                                                        </>
                                                    }
                                                />
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Grid container>
                                                    <Grid item xs={12} sm={12}>
                                                        <Paper elevation={0}>
                                                            {columns.length ? (
                                                                <EnhancedTable
                                                                    enableSearch={false}
                                                                    columns={columns || []}
                                                                    data={data}
                                                                    enableSort={false}
                                                                    hideToolBar={true}
                                                                    enablePagination={false}
                                                                    sampleTable={true}
                                                                />
                                                            ) : (
                                                                ''
                                                            )}
                                                        </Paper>
                                                    </Grid>
                                                </Grid>
                                            </AccordionDetails>
                                        </UploadStyledAccordion>
                                    );
                                })
                            ) : (
                                <NoDataFound msg="Results will be shown after uploading." />
                            )}
                        </Grid>
                    </Grid>
                    <Dialog
                        open={confirmDeleteDialog}
                        onClose={handleCancelDeleteConfirmation}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{'Are you sure?'}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Do you really want to delete these portfolio(s) ? This process can not be undone.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <CustomButton color="primary" onClick={handleCancelDeleteConfirmation}>
                                Cancel
                            </CustomButton>
                            <CustomButton color="primary" variant="contained" autoFocus onClick={handleFileDelete}>
                                Delete
                            </CustomButton>
                        </DialogActions>
                    </Dialog>
                </CardContent>
            </UploadCardParent>
        </>
    );
};
export default PortfolioOutput;
