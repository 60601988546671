import React, { useEffect, useRef } from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Fade from '@mui/material/Fade';
import IconButton from '@mui/material/IconButton';
import Popper from '@mui/material/Popper';
import { makeStyles } from '@mui/styles';
import ChatIcon from '@mui/icons-material/Chat';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import ChatBoxPopup from './ChatBoxPopup';
import styles from 'assets/jss/material-kit-react/views/chatBotPage';
import { openChatBotPopup } from 'redux/actions/ChatBotActions';
import { ChatbotParentBox } from 'assets/jss/ChatBotStyle';
import { ChatbotFabBtn } from 'assets/jss/ChatBotStyle';
import { BotCardHeader } from 'assets/jss/ChatBotStyle';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles(styles);
// const langList = [
//   { value: "en", label: "English" },
//   { value: "zh", label: "Chinese" },
//   { value: "es", label: "Spanish" },
//   { value: "pt", label: "Portuguese" },
//   { value: "de", label: "German" },
//   { value: "fr", label: "French" },
//   { value: "ja", label: "Japanese" },
//   { value: "ar", label: "Arabic" },
//   { value: "af", label: "Afrikaans" },
//   { value: "sq", label: "Albanian" },
//   { value: "hy", label: "Armenian" },
//   { value: "az", label: "Azerbaijani" },
//   { value: "ba", label: "Bashkir" },
//   { value: "eu", label: "Basque" },
//   { value: "be", label: "Belarusian" },
//   { value: "bn", label: "Bengali" },
//   { value: "bs", label: "Bosnian" },
//   { value: "bg", label: "Bulgarian" },
//   { value: "ca", label: "Catalan" },
//   { value: "za", label: "Chinese" },
//   { value: "hr", label: "Croatian" },
//   { value: "cs", label: "Czech" },
//   { value: "da", label: "Danish" },
//   { value: "nl", label: "Dutch" },
//   { value: "en", label: "English" },
//   { value: "et", label: "Estonian" },
//   { value: "fi", label: "Finnish" },
//   { value: "fr", label: "French" },
//   { value: "gl", label: "Galician" },
//   { value: "ka", label: "Georgian" },
//   { value: "de", label: "German" },
//   { value: "gu", label: "Gujarati" },
//   { value: "ht", label: "Haitian" },
//   { value: "he", label: "Hebrew" },
//   { value: "hi", label: "Hindi" },
//   { value: "hu", label: "Hungarian" },
//   { value: "id", label: "Indonesian" },
//   { value: "ga", label: "Irish" },
//   { value: "it", label: "Italian" },
//   { value: "ja", label: "Japanese" },
//   { value: "kn", label: "Kannada" },
//   { value: "kk", label: "Kazakh" },
//   { value: "ky", label: "Kirghiz" },
//   { value: "ko", label: "Korean" },
//   { value: "la", label: "Latin" },
//   { value: "lv", label: "Latvian" },
//   { value: "lt", label: "Lithuanian" },
//   { value: "mk", label: "Macedonian" },
//   { value: "mg", label: "Malagasy" },
//   { value: "ms", label: "Malay" },
//   { value: "mt", label: "Maltese" },
//   { value: "mn", label: "Mongolian" },
//   { value: "no", label: "Norwegian" },
//   { value: "fa", label: "Persian" },
//   { value: "pl", label: "Polish" },
//   { value: "pt", label: "Portuguese" },
//   { value: "pa", label: "Punjabi" },
//   { value: "ro", label: "Romanian" },
//   { value: "ru", label: "Russian" },
//   { value: "sr", label: "Serbian" },
//   { value: "si", label: "Sinhalese" },
//   { value: "sk", label: "Slovak" },
//   { value: "es", label: "Spanish" },
//   { value: "sw", label: "Swahili" },
//   { value: "sv", label: "Swedish" },
//   { value: "tl", label: "Tagalog" },
//   { value: "tg", label: "Tajik" },
//   { value: "ta", label: "Tamil" },
//   { value: "tt", label: "Tatar" },
//   { value: "th", label: "Thai" },
//   { value: "tr", label: "Turkish" },
//   { value: "uk", label: "Ukrainian" },
//   { value: "ur", label: "Urdu" },
//   { value: "uz", label: "Uzbek" },
//   { value: "cy", label: "Welsh" },
// ];

const ChatBot = () => {
    const chatBotData = useSelector((state) => state.chatBot);
    const classes = useStyles();
    const chatbotBtnRef = useRef(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [placement, setPlacement] = React.useState();
    const [open, setOpen] = React.useState(false);
    const dispatch = useDispatch();
    const handleClose = () => {
        setAnchorEl(null);
        setOpen(false);
        setPlacement('');
    };
    useEffect(() => {
        if (chatBotData.togglechatbotpopup) {
            handleClick('top-end');
            dispatch(openChatBotPopup(false));
        }
    }, [chatBotData.togglechatbotpopup]);
    // to support multilanguage
    // const handleLangChange = e => {
    //   var langOrig = null;
    //   var nativeVoiceOrig = null;
    //   var voiceOrig = null;
    //   if (nativeVoiceOrig == null && langOrig == null) {
    //     langOrig = web.lang;
    //     nativeVoiceOrig = web.nativeVoice;
    //     voiceOrig = web.voice;
    //   }
    //   web.lang = e.target.value;
    //   if (web.lang != "none") {
    //     web.nativeVoice = true;
    //     web.translate = true;
    //     web.voice = null;
    //   } else {
    //     web.translate = false;
    //     web.lang = langOrig;
    //     web.nativeVoice = nativeVoiceOrig;
    //     web.voice = voiceOrig;
    //   }
    // }
    const handleClick = (event, newPlacement) => {
        setAnchorEl(event.currentTarget);
        setOpen((prev) => placement !== newPlacement || !prev);
        setPlacement(newPlacement);
    };
    return (
        <ChatbotParentBox>
            <ChatbotFabBtn ref={chatbotBtnRef} aria-label={'Open chat bot'} color={'primary'} onClick={(e) => handleClick(e, 'top-end')} />
            <Popper
                open={open}
                anchorEl={anchorEl}
                placement={placement || 'top-end'}
                transition
                className={classNames('chatBotPopoverWrapper', classes.chatbot)}
            >
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <Card className={classNames(classes.root, classes.chatBotPopover)}>
                            <BotCardHeader
                                avatar={<ChatIcon />}
                                action={
                                    <IconButton aria-label="settings" onClick={handleClose}>
                                        <ExpandMoreIcon />
                                    </IconButton>
                                }
                                title="Finbot"
                                subheader={
                                    <Typography variant="body2">
                                        Welcome to TPE's Finance chatbot or finbot.
                                        <br />
                                        Please don't include any personal identifying information in the chat.
                                    </Typography>
                                }
                            />
                            <ChatBoxPopup chatBotData={chatBotData} classes={classes} />
                        </Card>
                    </Fade>
                )}
            </Popper>
        </ChatbotParentBox>
    );
};

export default ChatBot;
