import React, { useState } from 'react';
import { Button, InputAdornment, OutlinedInput } from '@mui/material';
import Typography from '@mui/material/Typography';
import { FooterFormControl } from 'assets/jss/FooterStyle';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { submitNewsletter } from 'redux/actions/GeneralCalActions';

const EmailSub = (props) => {
    const [newsemail, setNewsemail] = useState('');
    const dispatch = useDispatch();
    const handleChangeNewseltter = (e) => {
        setNewsemail(e.target.value);
    };
    const handleNewsSeltterSubmit = (e) => {
        const emailReg =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g;
        if (newsemail.toString().match(emailReg)) {
            dispatch(submitNewsletter({ email: newsemail }));
            setNewsemail('');
            props.handleSubscribeSubmit();
        } else {
            toast.error('Please enter valid email id.');
        }
    };
    return (
        <>
            <FooterFormControl id="subscribe" sx={{ m: 1, width: '25ch' }} variant="outlined" className="email-form-control">
                <OutlinedInput
                    id="outlined-adornment-weight"
                    type="email"
                    value={newsemail}
                    onChange={handleChangeNewseltter}
                    placeholder="Your Email"
                    endAdornment={
                        <InputAdornment position="end">
                            <Button variant="contained" onClick={handleNewsSeltterSubmit}>
                                Subscribe
                            </Button>
                        </InputAdornment>
                    }
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                        'aria-label': 'weight'
                    }}
                />
            </FooterFormControl>
            <Typography variant="cardHeader" className="dashboard-subheading" sx={{ mb: 1.5, display: 'block' }}>
                *Don't worry, we don’t spam.
            </Typography>
        </>
    );
};

export default EmailSub;
