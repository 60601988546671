import colors from 'assets/scss/themecolor.scss';
import { red, blue, green, orange,pink, purple, deepPurple, cyan, lime, amber, deepOrange, teal } from '@mui/material/colors';
class Globals {
    constructor(props) {
        this.API_ROOT_URL = '';
        if (process.env.REACT_APP_NODE_ENV === 'staging') {
            this.API_ROOT_URL = 'https://api-staging.tpehub.com/';
        } else if (process.env.REACT_APP_NODE_ENV === 'production') {
            this.API_ROOT_URL = 'https://api.tpehub.com/';
        } else {
            this.API_ROOT_URL = 'http://127.0.0.1:5000/';
        }
        this.errorMsg = 'Something went wrong! Please try again later';
        this.Colors = [
            '#FFA412',
            '#27A9E0',
            '#8D8D8D',
            colors.successDark,
            colors.warningDark,
            colors.errorDark,
            purple[500],
            pink[500],
            deepPurple[500],
            teal[500],
            deepOrange[500],
            red[200],
            blue[200],
            lime[200],
            amber[200],
            green[500],
            orange[700],
            cyan[500]
        ];
    }
}

export default new Globals();
