import { makeStyles } from "@mui/styles";
import Button from "components/CustomButtons/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { useDispatch } from "react-redux";
import { handleSignInPermissionDialog } from "redux/actions/GeneralCalActions";
import { useLocation } from 'react-router-dom';
import { StyledPermissionDialog } from "assets/jss/GeneralStyle";
import CustomDialogTitle from "components/DialogTitle";
import { Divider } from "@mui/material";

const useStyles = makeStyles(styles);
const SigningRequiredDialog = (props) => {
  const classes = useStyles();
    let location = useLocation();

  const dispatch = useDispatch();
  const displaySigninDialog = useSelector(
    (state) => state.generalCal.displaySigninDialog
  );
  const [confirmDeleteDialog, setConfirmDeleteDialog] = React.useState(false);

  useEffect(() => {
    setConfirmDeleteDialog(displaySigninDialog);
  }, [displaySigninDialog]);
  useEffect(() => {
      // to reset the flag when route will change
      dispatch(handleSignInPermissionDialog(false));
  }, [location]);

  return (
      <>
          <StyledPermissionDialog
              open={confirmDeleteDialog}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
          >
              <CustomDialogTitle id="alert-dialog-title">Sign In</CustomDialogTitle>
              <Divider/>
              <DialogContent>
                  <DialogContentText id="alert-dialog-description">Please sign in to use this feature.</DialogContentText>
              </DialogContent>
              <DialogActions className={classes.dialogActionBtn}>
                  <Button
                      component={Link}
                      onClick={() => dispatch(handleSignInPermissionDialog(false))}
                      color="primary"
                      size="xs"
                      to="/login"
                      variant="contained"
                      rel="noopener noreferrer"
                  >
                      Login
                  </Button>
              </DialogActions>
          </StyledPermissionDialog>
      </>
  );
};
export default SigningRequiredDialog;
