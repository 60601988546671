import React, { Fragment, useEffect, useState } from "react";
import { makeStyles } from '@mui/styles';
import styles from "assets/jss/material-kit-react/views/generalPage";
import CachedIcon from "@mui/icons-material/Cached";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import { Box, Card, CardContent, Container, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import RenderField from "utils/RenderField";
import { useForm } from "react-hook-form";
import Danger from "components/Typography/Danger";
import Success from "components/Typography/Success";
import Warning from "components/Typography/Warning";
import { submitHomePurchaseCollaborationData, fetchHomePurchaseCollaborationData, fetchHomePurchFiles, submitHomePurchaseFileData, fetchHomePurchaseFileData} from "redux/actions/HomeCalActions";
import { CustomButton } from "assets/jss/ButtonStyle";

const useStyles = makeStyles(styles);

const components = {
    declined: { name: Danger, msg: "Declined" },
    pending: { name: Warning, msg: "Pending" },
    accepted: { name: Success, msg: "Accepted" },
};

// const fileOptions = (props) => {
//     return props.list.map(val => ({value:val.id,label:val.file_name}))
// }


//Pending/Under Review/Declined/Accepted
const HomePurchaseCollBlock = (props) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [filename, setFileName ] = useState([]);
    const [triggerLoad, setTriggerLoad] = useState({});
    const [enableSendBtn, setEnableSendBtn] = useState(false);
    const peertopeer = useSelector((state) => state.peertopeer);
    const homecal = useSelector((state) => state.homecal);
    const [isChecked, setIsChecked] = useState(false);
    const { handleSubmit, control, setValue, getValues, formState: { isDirty, isSubmitting }, } = useForm({
        mode: "onChange",
      }
    );


    const onSubmit = (data) => {
        dispatch(submitHomePurchaseCollaborationData(data, 'post'));
    }
    const onSubmitData = (data) => {
        data['is_sharing_enabled'] = isChecked;
        dispatch(submitHomePurchaseFileData(data, 'post'));
    }
    useEffect(() => {
        if (Object.keys(homecal.homePurchaseCollDetails).length > 0) {
            const val = homecal.homePurchaseCollDetails?.collaborator;
            if (val !== undefined) {
                setValue('collmail', val?.collaborator_email);
                if (val.collaboration_status === 'accepted') {
                    setValue('collEmail', '');
                }
            }
        }
    }, [homecal.homePurchaseCollDetails]);

    useEffect(()=>{
        if (props?.homecal?.homePurchFiles !== undefined && Array.isArray(props?.homecal?.homePurchFiles) && props?.homecal?.homePurchFiles?.length > 0) {
            setFileName(props?.homecal?.homePurchFiles?.map((item) => ({
              value: item.id,
              label: item.file_name,
            })));
          }

    }, [props?.homecal?.homePurchFiles])

    const handleAcceptDecline = (data, from) => {
        dispatch(submitHomePurchaseCollaborationData({ 'id': data.instance_id, colla_status: from }, 'put'))
    };


    const selfData = Object.keys(homecal.homePurchaseCollDetails).length > 0 ? homecal.homePurchaseCollDetails.collaborated_by && homecal.homePurchaseCollDetails.collaborated_by || '' : '';
    const collData = Object.keys(homecal.homePurchaseCollDetails).length > 0 ? homecal.homePurchaseCollDetails?.collaborator && homecal.homePurchaseCollDetails.collaborator || '' : '';
    const statusComponent = selfData ? components[selfData.collaboration_status] : '';
    

    useEffect(() => {
        dispatch(fetchHomePurchaseCollaborationData());
        dispatch(fetchHomePurchFiles());
    }, [])

    useEffect(() => {
        if (collData.collaboration_status === 'accepted') {
            setValue('collEmail', '');
        }
    }, [collData.collaboration_status]);

    useEffect(() => {
        if (collData.receiver === true){
            dispatch(fetchHomePurchaseFileData());
        }
        
    }, [collData.receiver])

    const handleCheckboxChange = (e) => {
        setIsChecked(e.target.checked);
      };

      useEffect(() => {
        const data = getValues()
        if (props.homecal.homePurchaseCollDetails.collaborated_by && !data.coll_email) {
        setValue("coll_email", props?.homecal?.homePurchaseCollDetails?.collaborated_by?.collaborator_email)
        }
        }, [props?.homecal?.homePurchaseCollDetails?.collaborated_by?.collaborator_email]);
    

    // const optionsList = val.name === 'id' ? fileOptions() : val.options;
    // const optionsList = item.name === 'id' ? fileOptions() : item.options;
    return (
        <Grid container spacing={2} marginBottom={2}>
            <Grid item xs={12}>
                <Card className={classes.widgetCalCard} elevation={2}>
                    <CardContent>
                       <Grid container spacing={2}>
                            {selfData && selfData.collaboration_status === 'accepted' ? (
                            <Grid item xs={12} md={6}>
                                
                                <fieldset className={classes.investFieldSet}>
                                    <legend>
                                        <Typography gutterBottom className={classes.legendTitle} variant="body2">
                                            Send Home Purchase File Data
                                        </Typography>
                                    </legend>
                                    <Grid item xs={12} md={5}>
                                    <Typography gutterBottom>Enter your collaborator email id?</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={7}>
                                        <form onSubmit={handleSubmit(onSubmitData)}>
                                            <RenderField
                                                name="coll_email"
                                                control={control}
                                                label=""
                                                id="collEmail"
                                                type="text"
                                                valueLabelDisplay={'on'}
                                                fieldName={'Collaborator Email'}
                                                required
                                                customPatternName="email"
                                                placeholder="Enter Collaborator Email..."
                                                // defaultValue = {selfData.collaborator_email}
                                            />
                                            <Typography gutterBottom>Select file that you want to share?</Typography>
                                            <RenderField
                                                name="file_id"
                                                control={control}
                                                label=""
                                                id="fileName"
                                                type="select"
                                                valueLabelDisplay={'on'}
                                                fieldName={'File name'}
                                                options={filename}
                                                required
                                                // customPatternName="email"
                                                // placeholder="Enter Collaborator Email..."
                                            />
                                            <Typography gutterBottom>Sharing status</Typography>
                                            <label>
                                                <input
                                                name="is_sharing_enabled"
                                                type="checkbox"
                                                control={control}
                                                id="isSharingEnabled"
                                                label=""
                                                checked={isChecked}
                                                onChange={handleCheckboxChange}
                                                />
                                            </label>
                                            <br></br>
                                            <CustomButton
                                                type="submit"
                                                variant="contained"
                                                size="small"
                                                disabled={!isDirty || isSubmitting}
                                            >
                                                Submit
                                            </CustomButton>
                                        </form>
                                    </Grid>
                                </fieldset>
                            </Grid>
                            ) : (
                             <Grid item xs={12} md={6}>
                                <fieldset className={classes.investFieldSet}>
                                    <legend>
                                        <Typography gutterBottom className={classes.legendTitle} variant="body2">
                                            Add Collaborator
                                        </Typography>
                                    </legend>
                                     <Grid container spacing={2}>
                                        {selfData && selfData.collaboration_status === 'accepted' ? (
                                            <>
                                                <Grid item xs={12}>
                                                    <Typography gutterBottom>
                                                        You  are collaborating with {selfData.collaborator_name}
                                                    </Typography>
                                                </Grid>

                                            </>
                                        ) : (
                                            <>
                                                <Grid item xs={12} md={5}>
                                                    <Typography gutterBottom>Who is your collaborator?</Typography>
                                                </Grid>
                                                <Grid item xs={12} md={7}>
                                                    <form onSubmit={handleSubmit(onSubmit)}>
                                                        <RenderField
                                                            name="coll_email"
                                                            control={control}
                                                            label=""
                                                            id="collEmail"
                                                            type="text"
                                                            valueLabelDisplay={'on'}
                                                            fieldName={'Collaborator Email'}
                                                            required
                                                            customPatternName="email"
                                                            placeholder="Enter Collaborator Email..."
                                                            defaultValue = {selfData.collaborator_email}
                                                            
                                                        />
                                                        <CustomButton
                                                            type="submit"
                                                            variant="contained"
                                                            size="small"
                                                            disabled={!isDirty || isSubmitting}
                                                        >
                                                            Submit
                                                        </CustomButton>
                                                    </form>
                                                </Grid>
                                                {selfData && (
                                                    <>
                                                        <Grid item xs={12} md={6}>
                                                            <Typography gutterBottom component="div">
                                                                Status of collaboration request
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12} md={3}>
                                                            <statusComponent.name>{statusComponent.msg}</statusComponent.name>
                                                        </Grid>
                                                    </>
                                                )}

                                            </>
                                        )}
                                    </Grid>
                                </fieldset>
                            </Grid>  )}
                            <Grid item xs={12} md={6}>
                                <fieldset className={classes.investFieldSet}>
                                    <legend>
                                        <Typography gutterBottom className={classes.legendTitle} variant="body2">
                                            Requested
                                        </Typography>
                                    </legend>
                                    <Grid container spacing={2}>
                                        {collData ? (
                                            <>
                                                {collData.collaboration_status === 'accepted' ? (
                                                    <>
                                                        <Grid item xs={12}>
                                                            <Typography gutterBottom>You  are collaborating with {collData.name}.</Typography>
                                                        </Grid>

                                                    </>
                                                ) : (
                                                    <>
                                                        {collData.collaboration_status === 'pending' ? (
                                                            <>
                                                                <Grid item xs={12} sm={7} md={8} margin="auto">
                                                                    <Typography gutterBottom>
                                                                        Do you want to collaborate with {collData.name} ?
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={12} sm={3} md={4}>
                                                                    <Tooltip title="Accept">
                                                                        <IconButton
                                                                            aria-label="save"
                                                                            onClick={() => handleAcceptDecline(collData, 'accepted')}
                                                                        >
                                                                            <DoneIcon />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                    <Tooltip title="Decline">
                                                                        <IconButton
                                                                            aria-label="save"
                                                                            onClick={() => handleAcceptDecline(collData, 'declined')}
                                                                        >
                                                                            <CloseIcon />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </Grid>
                                                                {/* <Grid item xs={12}>
                                        <Typography gutterBottom>
                                          Loan term will display here when{" "}
                                          {collData.name} submit it.
                                        </Typography>
                                      </Grid> */}
                                                            </>
                                                        ) : collData.collaboration_status === 'declined' ? (
                                                            <Grid item xs={12}>
                                                                <Typography gutterBottom>
                                                                    You have declined the collaboration request from {collData.name}.
                                                                </Typography>
                                                            </Grid>
                                                        ) : (
                                                            <Grid item xs={12}>
                                                                <Typography gutterBottom>
                                                                    You have accepted the collaboration request from {collData.name}.
                                                                </Typography>
                                                            </Grid>
                                                        )}




                                                    </>
                                                )}
                                            </>
                                        ) : (
                                            <Grid item xs={12}>
                                                <Typography gutterBottom>You don't have any invites for collaboration requests.</Typography>
                                            </Grid>
                                        )}
                                    </Grid>

                                    
                                </fieldset>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
}
export default HomePurchaseCollBlock;