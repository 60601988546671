import React, { Fragment, useEffect, useState } from "react";
import { makeStyles } from '@mui/styles';
import styles from "assets/jss/material-kit-react/views/generalPage";
import CachedIcon from "@mui/icons-material/Cached";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import { Box, Card, CardContent, Container, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import RenderField from "utils/RenderField";
import { useForm } from "react-hook-form";
import Danger from "components/Typography/Danger";
import Success from "components/Typography/Success";
import Warning from "components/Typography/Warning";
import { submitPeertoPeerData } from "redux/actions/PeertoPeerCalAction";
import { CustomButton } from "assets/jss/ButtonStyle";
import { StyledPeerToPeerBadge } from "assets/jss/CalculatorStyle";
const useStyles = makeStyles(styles);
const components = {
  declined:{ name: Danger, msg: "Declined"},
  pending: {name:Warning,msg:"Pending"},
  accepted: {name:Success,msg:"Accepted"},
};
const progressCompo={
  send:{type:'success',msg:"Term Sent"},
  update:{type:'danger',msg:"Term Updated"}
}
//Pending/Under Review/Declined/Accepted
const PeerToPeerCollBlock=(props) =>{
  const dispatch = useDispatch();
  const classes = useStyles();
  const [triggerLoad,setTriggerLoad]=useState({});
  const [enableSendBtn,setEnableSendBtn]=useState(false);
  const peertopeer = useSelector((state) => state.peertopeer);
  const { handleSubmit, control, setValue,getValues,formState: { isDirty, isSubmitting }, } = useForm();

  const onSubmit=(data)=>{
dispatch(submitPeertoPeerData(data,'post'));
  }
  useEffect(() => {
    if(Object.keys(peertopeer.peertopeerDetails).length > 0){
      const val = peertopeer.peertopeerDetails?.self; 
      if (val !== undefined){
          setValue('collEmail', val?.collaborator_email);
          if(val.status === 'declined'){
              setValue('collEmail', '');
          }
      } 
    }
  }, [peertopeer.peertopeerDetails]);
  const handleLoadTerm=(val,from)=>{
    if((from ==='self' && !val.loan_term.self_acception_status) || (from ==='coll' && !val.loan_term.coll_acception_status)){
      props.handleEnableSendBtn(true);
    }
    props.handleTriggerLoad(val);

  }
  const handleTermAcception=(data,from)=>{
    dispatch(
      submitPeertoPeerData(
        { id: data.loan_term.id, [from + "_acception_status"]: true },
        "put"
      )
    );
  }
  const handleAcceptDecline = (data,from) => {
    dispatch(submitPeertoPeerData({'id':data.loan_term.id,status:from},'put'))
  };
  
  
  const collData = Object.keys(peertopeer.peertopeerDetails).length > 0?peertopeer.peertopeerDetails?.collaboration &&peertopeer.peertopeerDetails.collaboration || '':'';
  const selfData = Object.keys(peertopeer.peertopeerDetails).length > 0?peertopeer.peertopeerDetails?.self &&peertopeer.peertopeerDetails.self || '':'';
  const statusComponent=selfData ?components[selfData.status]:'';
  const badgeSelfColor=selfData && selfData.term_sent_from ===selfData.uid ? progressCompo['send']:progressCompo['update'];
  const badgeCollColor=collData && collData.term_sent_from ===collData.uid ? progressCompo['send']:progressCompo['update'];
  useEffect(() => {
    if(collData.status === 'declined'){
        setValue('collEmail', '');
    }
  }, [collData.status]);
   
  return (
      <Grid container spacing={2} marginBottom={2}>
          <Grid item xs={12}>
              <Card className={classes.widgetCalCard} elevation={2}>
                  <CardContent>
                      <Grid container spacing={2}>
                          <Grid item xs={12} md={6}>
                              <fieldset className={classes.investFieldSet}>
                                  <legend>
                                      <Typography gutterBottom className={classes.legendTitle} variant="body2">
                                          Add Collaborator
                                      </Typography>
                                  </legend>
                                  <Grid container spacing={2}>
                                      {selfData && selfData.loan_term.term_status === 'accepted' ? (
                                          <>
                                              <Grid item xs={12}>
                                                  <Typography gutterBottom>
                                                      You are collaborator with {selfData.collaborator_name}
                                                  </Typography>
                                              </Grid>
                                              <Grid item xs={12}>
                                                  <Typography gutterBottom variant="h6">
                                                      Agreed Loan Term
                                                  </Typography>
                                                  <Grid container>
                                                      <Grid item xs={12} sm={5} md={6}>
                                                          <Typography gutterBottom>Principal Amount</Typography>
                                                      </Grid>
                                                      <Grid item xs={12} sm={5} md={6}>
                                                          <Typography gutterBottom> {selfData.loan_term.loan_amount}</Typography>
                                                      </Grid>
                                                      <Grid item xs={12} sm={5} md={6}>
                                                          <Typography gutterBottom>Interest Rate</Typography>
                                                      </Grid>
                                                      <Grid item xs={12} sm={5} md={6}>
                                                          <Typography gutterBottom> {selfData.loan_term.interest_rate}</Typography>
                                                      </Grid>
                                                      <Grid item xs={12} sm={5} md={6}>
                                                          <Typography gutterBottom> Maturity Months</Typography>
                                                      </Grid>
                                                      <Grid item xs={12} sm={5} md={6}>
                                                          <Typography gutterBottom>{selfData.loan_term.maturity_years}</Typography>
                                                      </Grid>
                                                      <Grid item xs={12} sm={5} md={6}>
                                                          <Typography gutterBottom> Repayment Type</Typography>
                                                      </Grid>
                                                      <Grid item xs={12} sm={5} md={6}>
                                                          <Typography gutterBottom> {selfData.loan_term.payback_type}</Typography>
                                                      </Grid>
                                                      <Grid item xs={12} sm={5} md={6}>
                                                          <Typography gutterBottom> Repayment Frequency</Typography>
                                                      </Grid>
                                                      <Grid item xs={12} sm={5} md={6}>
                                                          <Typography gutterBottom> {selfData.loan_term.repayment_fq}</Typography>
                                                      </Grid>
                                                  </Grid>
                                              </Grid>
                                          </>
                                      ) : (
                                          <>
                                              <Grid item xs={12} md={5}>
                                                  <Typography gutterBottom>Who is your collaborator?</Typography>
                                              </Grid>
                                              <Grid item xs={12} md={7}>
                                                  <form onSubmit={handleSubmit(onSubmit)}>
                                                      <RenderField
                                                          name="collEmail"
                                                          control={control}
                                                          label=""
                                                          id="collEmail"
                                                          type="text"
                                                          valueLabelDisplay={'on'}
                                                          fieldName={'Collaborator Email'}
                                                          required
                                                          customPatternName="email"
                                                          placeholder="Enter Collaborator Email..."
                                                      />
                                                      <CustomButton
                                                          type="submit"
                                                          variant="contained"
                                                          size="small"
                                                          disabled={!isDirty || isSubmitting}
                                                      >
                                                          Submit
                                                      </CustomButton>
                                                  </form>
                                              </Grid>
                                              {selfData && (
                                                  <>
                                                      <Grid item xs={12} md={6}>
                                                          <Typography gutterBottom component="div">
                                                              Status of collaboration request
                                                          </Typography>
                                                      </Grid>
                                                      <Grid item xs={12} md={3}>
                                                          <statusComponent.name>{statusComponent.msg}</statusComponent.name>
                                                      </Grid>
                                                  </>
                                              )}
                                              {selfData && selfData?.loan_term.loan_amount && (
                                                  <>
                                                      <Grid item xs={12} md={7}>
                                                          <Typography gutterBottom>
                                                              Load the loan term
                                                              <Tooltip title="Load the term">
                                                                  <IconButton
                                                                      aria-label="save"
                                                                      onClick={() => handleLoadTerm(selfData, 'self')}
                                                                  >
                                                                      <CachedIcon color="white" />
                                                                  </IconButton>
                                                              </Tooltip>
                                                          </Typography>
                                                      </Grid>
                                                      {selfData && selfData.term_sent_from ? (
                                                          <Grid item xs={12} md={3}>
                                                              <StyledPeerToPeerBadge
                                                                  color={badgeSelfColor.type}
                                                                  badgeContent={badgeSelfColor.msg}
                                                              />
                                                          </Grid>
                                                      ) : (
                                                          ''
                                                      )}

                                                      {selfData && selfData.loan_term.self_acception_status ? (
                                                          //&& collData?.loan_term?.term_status !==
                                                          //   "accepted"
                                                          <Grid item xs={12}>
                                                              <Typography gutterBottom>
                                                                  Waiting for {selfData.collaborator_name} to accept the loan term to
                                                                  complete the agreement
                                                              </Typography>
                                                          </Grid>
                                                      ) : selfData && selfData.loan_term.coll_acception_status ? (
                                                          <>
                                                              {' '}
                                                              <Grid item xs={12} sm={5} md={6}>
                                                                  <Typography gutterBottom>
                                                                      {selfData.collaborator_name} is accepted the loan term. Please accept
                                                                      yours to complete the agreement.
                                                                  </Typography>
                                                              </Grid>{' '}
                                                              <Grid item xs={12} sm={3} md={4}>
                                                                  <Tooltip title="Accept">
                                                                      <IconButton
                                                                          aria-label="Accept"
                                                                          onClick={() => handleTermAcception(selfData, 'self')}
                                                                      >
                                                                          <DoneIcon />
                                                                      </IconButton>
                                                                  </Tooltip>
                                                              </Grid>
                                                          </>
                                                      ) : (
                                                          <>
                                                              <Grid item xs={12} sm={5} md={6} margin={'auto 0'}>
                                                                  <Typography gutterBottom>Happy with the loan term(s)?</Typography>
                                                              </Grid>
                                                              <Grid item xs={12} sm={3} md={4}>
                                                                  <Tooltip title="Accept">
                                                                      <IconButton
                                                                          aria-label="Accept"
                                                                          onClick={() => handleTermAcception(selfData, 'self')}
                                                                      >
                                                                          <DoneIcon />
                                                                      </IconButton>
                                                                  </Tooltip>
                                                              </Grid>
                                                          </>
                                                      )}
                                                  </>
                                              )}
                                          </>
                                      )}
                                  </Grid>
                              </fieldset>
                          </Grid>
                          <Grid item xs={12} md={6}>
                              <fieldset className={classes.investFieldSet}>
                                  <legend>
                                      <Typography gutterBottom className={classes.legendTitle} variant="body2">
                                          Requested
                                      </Typography>
                                  </legend>
                                  <Grid container spacing={2}>
                                      {collData ? (
                                          <>
                                              {collData.loan_term.term_status === 'accepted' ? (
                                                  <>
                                                      <Grid item xs={12}>
                                                          <Typography gutterBottom>You are collaborator with {collData.name}.</Typography>
                                                      </Grid>
                                                      <Grid item xs={12}>
                                                          <Typography gutterBottom variant="h6">
                                                              Agreed Loan Term
                                                          </Typography>
                                                          <Grid container>
                                                              <Grid item xs={12} sm={5} md={6}>
                                                                  <Typography gutterBottom>Interest Rate</Typography>
                                                              </Grid>
                                                              <Grid item xs={12} sm={5} md={6}>
                                                                  <Typography gutterBottom> {collData.loan_term.interest_rate}</Typography>
                                                              </Grid>
                                                              <Grid item xs={12} sm={5} md={6}>
                                                                  <Typography gutterBottom> Maturity Months</Typography>
                                                              </Grid>
                                                              <Grid item xs={12} sm={5} md={6}>
                                                                  <Typography gutterBottom>{collData.loan_term.maturity_years}</Typography>
                                                              </Grid>
                                                              <Grid item xs={12} sm={5} md={6}>
                                                                  <Typography gutterBottom> Repayment Type</Typography>
                                                              </Grid>
                                                              <Grid item xs={12} sm={5} md={6}>
                                                                  <Typography gutterBottom> {collData.loan_term.payback_type}</Typography>
                                                              </Grid>
                                                              <Grid item xs={12} sm={5} md={6}>
                                                                  <Typography gutterBottom> Repayment Frequency</Typography>
                                                              </Grid>
                                                              <Grid item xs={12} sm={5} md={6}>
                                                                  <Typography gutterBottom> {collData.loan_term.repayment_fq}</Typography>
                                                              </Grid>
                                                          </Grid>
                                                      </Grid>
                                                  </>
                                              ) : (
                                                  <>
                                                      {collData.status === 'pending' ? (
                                                          <>
                                                              <Grid item xs={12} sm={7} md={8} margin="auto">
                                                                  <Typography gutterBottom>
                                                                      Do you want to collaborator with {collData.name} ?
                                                                  </Typography>
                                                              </Grid>
                                                              <Grid item xs={12} sm={3} md={4}>
                                                                  <Tooltip title="Accept">
                                                                      <IconButton
                                                                          aria-label="save"
                                                                          onClick={() => handleAcceptDecline(collData, 'accepted')}
                                                                      >
                                                                          <DoneIcon />
                                                                      </IconButton>
                                                                  </Tooltip>
                                                                  <Tooltip title="Decline">
                                                                      <IconButton
                                                                          aria-label="save"
                                                                          onClick={() => handleAcceptDecline(collData, 'declined')}
                                                                      >
                                                                          <CloseIcon />
                                                                      </IconButton>
                                                                  </Tooltip>
                                                              </Grid>
                                                              {/* <Grid item xs={12}>
                                        <Typography gutterBottom>
                                          Loan term will display here when{" "}
                                          {collData.name} submit it.
                                        </Typography>
                                      </Grid> */}
                                                          </>
                                                      ) : collData.status === 'declined' ? (
                                                          <Grid item xs={12}>
                                                              <Typography gutterBottom>
                                                                  You have declined the collaboration request from {collData.name}.
                                                              </Typography>
                                                          </Grid>
                                                      ) : (
                                                          <Grid item xs={12}>
                                                              <Typography gutterBottom>
                                                                  You have accepted the collaboration request from {collData.name}.
                                                              </Typography>
                                                          </Grid>
                                                      )}
                                                      {collData.loan_term.loan_amount ? (
                                                          <>
                                                              <Grid item xs={12} md={7}>
                                                                  <Typography gutterBottom>
                                                                      Load the loan term
                                                                      <Tooltip title="Load the term">
                                                                          <IconButton
                                                                              aria-label="load"
                                                                              onClick={() => handleLoadTerm(collData, 'coll')}
                                                                          >
                                                                              <CachedIcon color="white" />
                                                                          </IconButton>
                                                                      </Tooltip>
                                                                  </Typography>
                                                              </Grid>
                                                              {collData && collData.term_sent_from ? (
                                                                  <Grid item xs={12} md={3}>
                                                                      <StyledPeerToPeerBadge color={badgeCollColor.type} badgeContent={badgeCollColor.msg}/>
                                                                  </Grid>
                                                              ) : (
                                                                  ''
                                                              )}
                                                          </>
                                                      ) : collData.status !== 'declined' ? (
                                                          <Grid item xs={12}>
                                                              <Typography gutterBottom>
                                                                  Loan term will display here when {collData.name} submit it.
                                                              </Typography>
                                                          </Grid>
                                                      ) : (
                                                          ''
                                                      )}
                                                      {collData.loan_term.coll_acception_status &&
                                                      collData.loan_term.term_status !== 'accepted' ? (
                                                          <Grid item xs={12}>
                                                              <Typography gutterBottom>
                                                                  Waiting for {collData?.name} to accept the loan term to complete the
                                                                  agreement
                                                              </Typography>
                                                          </Grid>
                                                      ) : collData.loan_term.self_acception_status ? (
                                                          <>
                                                              {' '}
                                                              <Grid item xs={12} sm={5} md={6}>
                                                                  <Typography gutterBottom>
                                                                      {collData?.name} is accepted the loan term. Please accept yours to
                                                                      complete the agreement.
                                                                  </Typography>
                                                              </Grid>{' '}
                                                              <Grid item xs={12} sm={3} md={4}>
                                                                  <Tooltip title="Accept">
                                                                      <IconButton
                                                                          aria-label="Accept"
                                                                          onClick={() => handleTermAcception(collData, 'coll')}
                                                                      >
                                                                          <DoneIcon />
                                                                      </IconButton>
                                                                  </Tooltip>
                                                              </Grid>
                                                          </>
                                                      ) : (
                                                          collData.loan_term.loan_amount && (
                                                              <>
                                                                  <Grid item xs={12} sm={5} md={6} margin={'auto 0'}>
                                                                      <Typography gutterBottom>Happy with the loan term(s)?</Typography>
                                                                  </Grid>
                                                                  <Grid item xs={12} sm={3} md={4}>
                                                                      <Tooltip title="Accept">
                                                                          <IconButton
                                                                              aria-label="Accept"
                                                                              onClick={() => handleTermAcception(collData, 'coll')}
                                                                          >
                                                                              <DoneIcon />
                                                                          </IconButton>
                                                                      </Tooltip>
                                                                  </Grid>
                                                              </>
                                                          )
                                                      )}
                                                  </>
                                              )}
                                          </>
                                      ) : (
                                          <Grid item xs={12}>
                                              <Typography gutterBottom>You don't have any invites for collaboration requests.</Typography>
                                          </Grid>
                                      )}
                                  </Grid>
                              </fieldset>
                          </Grid>
                      </Grid>
                  </CardContent>
              </Card>
          </Grid>
      </Grid>
  );
}
export default PeerToPeerCollBlock;