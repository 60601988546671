import React, { useEffect } from "react";
import { StyledPermissionDialog } from "assets/jss/GeneralStyle";
import Typography from '@mui/material/Typography';
import { CardActions, CardContent, CardMedia, IconButton } from '@mui/material';
import { BannerSigninButton } from "assets/jss/BannerStyle";
import { useNavigate } from 'react-router-dom';
import { CalSignUpCard } from "assets/jss/GeneralStyle";
import { StyledCloseDialog } from "assets/jss/GeneralStyle";
import { StyledDialogContentBox } from "assets/jss/GeneralStyle";
import { Close } from "@mui/icons-material";

function RegistrationModal(props) {
  const navigate = useNavigate();

  return (
    <StyledPermissionDialog open={props.show}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <CalSignUpCard >
        <StyledDialogContentBox>
          <StyledCloseDialog>
            <IconButton onClick={() => props.closeDialog()}>
              <Close />
            </IconButton>
          </StyledCloseDialog>
          <CardMedia component="img" image="img/cal-signin-img.png" title="cal-signin-img" className="signin-img" />
          <CardContent>
            <Typography variant="heading" display={'block'}>
              Looking for more features?
            </Typography>
            <Typography variant="subheading">Register your account now, save and track all your finances</Typography>
          </CardContent>
          <CardActions>
            <BannerSigninButton
              variant="contained"
              className="cal-signup-btn"
              align="center"
              sx={{ mb: 3.3 }}
              onClick={() => navigate('/sign-up')}
            >
              Sign Up
            </BannerSigninButton>
          </CardActions>
        </StyledDialogContentBox>
      </CalSignUpCard >
    </StyledPermissionDialog>
  )
}

export default RegistrationModal