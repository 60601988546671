import React, { useEffect, useRef, useState } from 'react';
import CommonCalculator from 'components/CommonComponent/CommonCalculator.js';
import { useSelector } from 'react-redux';
import Loader from 'components/CommonComponent/Loader.js';
import { MainBox } from 'assets/jss/GeneralStyle.jsx';
import { Container, Tab, Tabs, Typography, useTheme } from '@mui/material';
import { CustomListItem } from 'assets/jss/HeaderStyle.jsx';
import { CustomListItemText } from 'assets/jss/HeaderStyle.jsx';
import { Link as RouterLink } from 'react-router-dom';
import EconomicTable from './EconomicTable.jsx';
import EconomicDashDesc from './EconomicDashDesc.jsx';
import { LargeContainer } from 'assets/jss/GeneralStyle.jsx';
import Meta from 'components/meta.jsx';
export default function EcoDashBoard(props) {
    const economicDashboardData = useSelector((state) => state.economicDashboard);
    const typographyRef = useRef(null);
    useEffect(() => {
        // Set the ID after the component mounts
        if (typographyRef.current) {
            typographyRef.current.id = 'ecDash';
        }
    }, []);

    const breadcrumbs = [
        <CustomListItem className="cal-banner-menu" key="1" sx={{ m: 0 }} color="text.light">
            <CustomListItemText primary={'Home'} />
        </CustomListItem>,
        <CustomListItem className="cal-banner-menu" key="2" sx={{ m: 0 }} color="text.light">
            <CustomListItemText primary={'Dashboards'} />
        </CustomListItem>,
        <Typography ref={typographyRef} key="3" color="text.light" className="current-menu">
            Economic Dashboard
        </Typography>
    ];

    return (
        <>
         <Meta
                title="Economic Dashboard | TPE Hub"
                description="Explore key economic indicators and trends with our Economic Dashboard. Analyze data on GDP, inflation, unemployment rates, and other vital metrics to make informed decisions."
                page="/economic-dashboard"
            />
            <CommonCalculator breadcrumbs={breadcrumbs} variantText="Economic Dashboard" />
            <MainBox>
                {economicDashboardData.loader ? <Loader /> : ''}
                <LargeContainer maxWidth="xl">
                    <EconomicTable economicDashboardData={economicDashboardData} />
                    <EconomicDashDesc />
                </LargeContainer>
            </MainBox>
        </>
    );
}
