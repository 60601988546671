import React, { Fragment } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import { SummaryTabPaper } from 'assets/jss/PortfolioStyle';
import PortfolioCorrelationTable from './PortfolioCorrelationTable';
import CustomTooltip from 'components/CommonComponent/CustomTooltip';

const useStyles = makeStyles((theme) => ({
    widgetGridItem: {
        // Add your styles here if needed
    },
    summaryTextRank: {
        // Add your styles here if needed
    },
    companyName: {
        fontSize: '0.875rem', // smaller font size
        color: 'grey', // greyish color
    },
    sharpeSymbol: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start', // align to the start of the column
        width: '100%',
    },
    sharpeValue: {
        fontWeight: 'bold',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end', // align to the end of the column
    },
}));

const PortfolioCorrelation = ({ data }) => {
    const classes = useStyles();

    // Adding defensive checks
    const correlationData = data?.correlation || [];
    const sharpeRatioData = data?.sharpe_ratio || [];
    const correlationMatrix = data?.corr_matrix || {};

    // Split the Sharpe Ratio data into two groups
    const midIndex = Math.ceil(sharpeRatioData.length / 2);
    const firstHalf = sharpeRatioData.slice(0, midIndex);
    const secondHalf = sharpeRatioData.slice(midIndex);

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} sm={6} className={classes.widgetGridItem}>
                <SummaryTabPaper elevation={3}>
                    <Typography variant="subtitle1" className={classes.summaryTextRank}>
                        Correlation groupings  <CustomTooltip
                    tooltipText={
                        "Items on the same line are highly correlated, standalone items have lower correlation with other funds."
                    }
                    customIconTooltip={true}
                    textTooltip={true}
                >
                </CustomTooltip>
                    </Typography>
                    <Typography variant="body1" sx={{ fontSize: 14, fontStyle: 'italic', color: 'grey' }}>
                        The threshold for correlated items is 0.8.
                    </Typography>
                    <Grid container spacing={0.5}>
                        {correlationData.map((val, i) => (
                            <Fragment key={i + '_correlation'}>
                                <Grid item xs={12}>
                                    <Typography variant="body1">
                                        {val.map((item, index) => (
                                            <Tooltip
                                                key={item.symbol}
                                                title={item.company_name}
                                                placement="bottom"
                                            >
                                                <span
                                                    style={{
                                                        cursor: 'pointer',
                                                        marginRight: index < val.length - 1 ? 8 : 0,
                                                    }}
                                                >
                                                {item.symbol}{index<val.length-1?',':''}
                                                </span>
                                            </Tooltip>
                                        ))}
                                    </Typography>
                                </Grid>
                            </Fragment>
                        ))}
                    </Grid>
                    <Typography variant="body1" sx={{ fontSize: 12, fontStyle: 'italic', color: 'grey' }} >
                    *Hover over symbol to see fund name
                    </Typography>
                </SummaryTabPaper>
            </Grid>
            <Grid item xs={12} sm={6} className={classes.widgetGridItem}>
                <SummaryTabPaper elevation={3}>
                    <Typography variant="subtitle1" className={classes.summaryTextRank}>
                        Sharpe Ratio <CustomTooltip
                    tooltipText={
                        "Sharpe ratio measures the amount of excess returns given the amount of volatility in the stock - so it adjusts returns for the riskiness of the stock or mutual fund; it's calculated by dividing the returns minus risk free rate and dividing the quantity by the standard deviation of returns."
                    }
                    customIconTooltip={true}
                    textTooltip={true}
                >
                </CustomTooltip>
                    </Typography>
                    <Grid container spacing={0.5}>
                    <Grid item xs={6}>
                        <Grid container spacing={0.5}>
                            {firstHalf.map((val, i) => (
                                <Fragment key={i + '_sharpe1'}>
                                    <Grid item xs={6}>
                                        <Tooltip title={val.company_name} arrow>
                                            <Typography
                                                variant="body1"
                                                style={{ cursor: 'pointer' }}
                                            >
                                                {val.symbol}
                                            </Typography>
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="subHeading1" style={{ fontWeight: 'bold' }}>
                                            {val.sharpe_ratio.toFixed(3)} %
                                        </Typography>
                                    </Grid>
                                </Fragment>
                            ))}
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container spacing={0.5}>
                            {secondHalf.map((val, i) => (
                                <Fragment key={i + '_sharpe2'}>
                                    <Grid item xs={6}>
                                        <Tooltip title={val.company_name} arrow>
                                            <Typography
                                                variant="body1"
                                                style={{ cursor: 'pointer' }}
                                            >
                                                {val.symbol}
                                            </Typography>
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="subHeading1" style={{ fontWeight: 'bold' }}>
                                            {val.sharpe_ratio.toFixed(3)} %
                                        </Typography>
                                    </Grid>
                                    
                                </Fragment>
                            ))}
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                    <Typography variant="body1" sx={{ fontSize: 12, fontStyle: 'italic', color: 'grey' }} >
                    *Hover over symbol to see fund name
                    </Typography>
                   </Grid>
                </Grid>
                </SummaryTabPaper>
            </Grid>

            {/* Correlation Matrix Table */}
            <Grid item xs={12} justifyContent="center">
                <PortfolioCorrelationTable correlationMatrix={correlationMatrix} />
            </Grid>
        </Grid>
    );
};

export default PortfolioCorrelation;
