import React, { useState } from 'react';
import { Box, Collapse, Divider, IconButton, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import NoDataFound from 'components/CommonComponent/NoDataFound';
import CustomDialogTitle from 'components/DialogTitle';
import { StyledDialog } from 'assets/jss/GeneralStyle';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

export const MutualFundViewPopup = ({ viewData, handleClose, data, onColumnTitleClick, ...rest }) => {
    const [openRows, setOpenRows] = useState({});

    const getGroupedData = (data) => {
        const grouped = {};
        for (const key in data) {
            let mainKey = key.split(' ')[0];

            const yearKeys = ['1 Year Returns', '3 Year Returns', 'BM 1 Year Returns', 'BM 3 Year Returns'];
            if (yearKeys.includes(key)) {
                mainKey = '1 Year Returns';
            }
            const creditKeys = [
                'U.S. Government',
                'CCC & Below B',
                'Cash & Net Other Assets',
                'Not Rated/Not Available',
                'Short-Term Rated',
                'credit rating B',
                'credit rating BB',
                'credit rating BBB',
                'credit rating A',
                'credit rating AA',
                'credit rating AAA'
            ];

            if (creditKeys.includes(key)) {
                mainKey = 'credit rating AAA';
            }

            if (!grouped[mainKey]) {
                grouped[mainKey] = [];
            }
            grouped[mainKey].push({ key, value: data[key] });
        }
        return grouped;
    };

    const groupedData = getGroupedData(data);

    const handleToggle = (group) => {
        setOpenRows((prev) => ({ ...prev, [group]: !prev[group] }));
    };

    const handleTitleClick = (title, group) => {
        onColumnTitleClick(title);
        handleToggle(group);
        handleClose();
    };

    const isMultiGroupRow = (group) => {
        return groupedData[group].length > 1;
    };

    const ignoreFields = ['mid', 'id'];
    return (
        <StyledDialog
            sx={{
                '& .MuiDialog-paper': {
                    // maxWidth: '100%',
                    width: '75%'
                },
                '& .MuiTableRow-root': {
                    background: '#fff',
                    boxShadow: '0px 0px 2px black'
                },
                '& .MuiTableCell-root': {
                    padding: '5px',
                    width: '50%',
                    span: {
                        padding: '10px',
                        // borderBottom: '1px solid #ddd',
                        width: '100%',
                        display: 'block'
                    }
                },
                '& .multi-group-row': {
                    background: '#00acc194',
                    color: 'black'
                }
            }}
            open={viewData}
            onClose={handleClose}
            aria-labelledby="mutual-fund-view"
        >
            <CustomDialogTitle id="mutual-fund-view" onClose={handleClose}>
                View Ticker
            </CustomDialogTitle>
            <Divider />
            <DialogContent>
                {data && Object.keys(data).length ? (
                    <TableContainer>
                        <Table>
                            <TableBody>
                                {Object.keys(groupedData).map((group, idx) => {
                                    if (!ignoreFields.includes(groupedData[group][0].key)) {
                                        if (groupedData[group].length === 1) {
                                            return (
                                                <TableRow key={idx}>
                                                    <TableCell>
                                                        <a href="#" onClick={() => handleTitleClick(groupedData[group][0].key)}>
                                                            {groupedData[group][0].key}
                                                        </a>
                                                    </TableCell>
                                                    <TableCell>{groupedData[group][0].value ? groupedData[group][0].value : '-'}</TableCell>
                                                </TableRow>
                                            );
                                        } else {
                                            return (
                                                <React.Fragment key={idx}>
                                                    <TableRow className={isMultiGroupRow(group) ? 'multi-group-row' : ''}>
                                                        <TableCell>
                                                            <IconButton size="small" onClick={() => handleToggle(group)}>
                                                                {openRows[group] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                            </IconButton>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography>
                                                                {groupedData[group][0].key === '1 Year Returns'
                                                                    ? 'Historical Returns'
                                                                    : groupedData[group][0].key === 'U.S. Government'
                                                                    ? 'Credit Rating'
                                                                    : groupedData[group][0].key.replace(/\d+/g, '')}
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell colSpan={2} style={{ padding: 0, border: 0 }}>
                                                            <Collapse in={openRows[group]} timeout="auto" unmountOnExit>
                                                                <Box margin={1}>
                                                                    <Table size="small">
                                                                        <TableBody>
                                                                            {groupedData[group].map((item, i) => (
                                                                                <TableRow key={i}>
                                                                                    <TableCell>
                                                                                        <a
                                                                                            href="#"
                                                                                            onClick={() =>
                                                                                                handleTitleClick(item.key, group)
                                                                                            }
                                                                                        >
                                                                                            {item.key}
                                                                                        </a>
                                                                                    </TableCell>
                                                                                    <TableCell>{item.value ? item.value : '-'}</TableCell>
                                                                                </TableRow>
                                                                            ))}
                                                                        </TableBody>
                                                                    </Table>
                                                                </Box>
                                                            </Collapse>
                                                        </TableCell>
                                                    </TableRow>
                                                </React.Fragment>
                                            );
                                        }
                                    }
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                ) : (
                    <NoDataFound />
                )}
            </DialogContent>
        </StyledDialog>
    );
};
