/** @format */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Card, CardContent, Grid } from '@mui/material';
import { useDispatch } from 'react-redux';
import { submitOptionPricingCal, OptionPricingCalSuccess } from 'redux/actions/OptionPricingCalActions';
import RenderField from 'utils/RenderField';
import { CalCardActions } from 'assets/jss/RetirementStyle';
import { CustomButton } from 'assets/jss/ButtonStyle';

function ProductSection(props) {
    const dispatch = useDispatch();
    const { classes } = props;
    const { handleSubmit, control, formState, reset, setValue } = useForm({
        mode: 'onChange',
        defaultValues: {
            assetPrice: '',
            exercisePrice: '',
            riskFreeRate: '',
            termYears: '',
            volatility: ''
        }
    });
    const { isDirty, isSubmitting } = formState;
    let [result, setResult] = useState([]);
    const onSubmit = (data) => {
        const objToPass = {
            /*  yearuntiretirement: yur,
                  netgrowthannual: nga,
                  currentnetworth: cnw,
                  currentage: ca */

            s: data.assetPriceUnFormattedValue,
            x: data.exercisePriceUnFormattedValue,
            r: data.riskFreeRate,
            t: data.termYears,
            sig: data.volatility
        };
        dispatch(submitOptionPricingCal(objToPass));
    };

    /**
     * @author disha
     * @use to set the ouput of api into state
     */
    useEffect(() => {
        if (Object.keys(props.optionPricingCalData.optionPricingCalDetail).length) {
            setResult(props.optionPricingCalData.optionPricingCalDetail.ans);
        } else {
            // to reset the output fields
            if (result.length > 0) setResult([]);
        }
    }, [props.optionPricingCalData]);
    // to reset redux stored value of result when page is changing
    useEffect(() => {
        return () => {
            dispatch(OptionPricingCalSuccess({}));
        };
    }, []);
    /**
     * @author disha
     * @use to reset the output if there is modification in form
     */
    const handleFormInputChange = (e) => {
        // reset saved output from redux state
        if (isDirty && result.length > 0) {
            dispatch(OptionPricingCalSuccess({}));
        }
    };
    return (
        <Card className={classes.widgetCalCard} elevation={2}>
            <CardContent>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container>
                        <Grid item xs={12} md={6}>
                            <RenderField
                                handleOnChange={handleFormInputChange}
                                name="assetPrice"
                                control={control}
                                label="Asset Price"
                                id="assetPrice"
                                type="number"
                                required
                                thousandSeparator={true}
                                customPatternName="decimalRate"
                                placeholder="100,000"
                                setValue={setValue}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <RenderField
                                handleOnChange={handleFormInputChange}
                                name="exercisePrice"
                                control={control}
                                label="Exercise Price"
                                id="exercisePrice"
                                type="number"
                                required
                                thousandSeparator={true}
                                customPatternName="decimalRate"
                                placeholder="100,000"
                                setValue={setValue}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <RenderField
                                handleOnChange={handleFormInputChange}
                                name="riskFreeRate"
                                control={control}
                                label="Risk Free Rate"
                                id="riskFreeRate"
                                customValidateLimit={10}
                                type="number"
                                required
                                customPatternName="decimalRate"
                                helperText="(in Decimal)"
                                placeholder="0.03"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <RenderField
                                handleOnChange={handleFormInputChange}
                                name="termYears"
                                control={control}
                                label="Term"
                                id="termYears"
                                type="number"
                                required
                                placeholder="2"
                                customPatternName="decimalRate"
                                helperText="(in Years)"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <RenderField
                                handleOnChange={handleFormInputChange}
                                name="volatility"
                                control={control}
                                label="Volatility"
                                id="volatility"
                                type="number"
                                required
                                customPatternName="decimalRate"
                                helperText="(in Decimal)"
                                placeholder="0.30"
                            />
                        </Grid>
                    </Grid>
                    <CalCardActions className="contactus-cardaction">
                        <CustomButton
                            type="submit"
                            ps={'9px 30px'}
                            variant="contained"
                            // color="rose"
                            disabled={isSubmitting}
                        >
                            Calculate
                        </CustomButton>
                    </CalCardActions>
                </form>
            </CardContent>
        </Card>
    );
}

export default ProductSection;
