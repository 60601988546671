import React from 'react';
import { Card, CardContent, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { QuesAnsBox } from 'assets/jss/RetirementStyle';

const CompoundCalDesc = (props) => {
    return (
        <Grid container spacing={2} sx={{ mt: 2 }}>
                <Card>
                    <CardContent>
                        <QuesAnsBox>
                            <Typography variant="h2" id="description" sx={{ mb: 2, display: 'flex' }}>
                                Compound Interest Calculator
                            </Typography>
                            <Typography variant="body1" className="dashboard-subheading" sx={{ mb: 1.5 }}>
                                Compound interest or "interest on interest," illustrates the power of growth of money on your savings. This tool calculates the interest earned on both the initial amount you have saved, as well as ongoing/recurring savings and the accumulated interest from previous periods. Compound interest is like a snowball rolling downhill - it grows bigger and bigger as it picks up more snow.<br /><br /> The longer interest is compounded the more your money grows. This is why starting to save early is so important - you give your money more time to benefit from compounding. Compound interest is a powerful tool for growing your wealth over time.<br /><br />
                                Our tool allows you to input both today’s savings, future savings and the interest you expect to earn.
                            </Typography>
                        </QuesAnsBox>
                    </CardContent>
                </Card>
            </Grid>
    );
};

export default CompoundCalDesc;
