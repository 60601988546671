import React from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {
    Box, Divider, Grid
} from '@mui/material';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { FooterBox, FooterCard, FooterCardContent, GetStartedBtn } from 'assets/jss/FooterStyle';
import { useNavigate } from 'react-router-dom';

const GetStarted = (props) => {
    const navigate = useNavigate();

    return (
        <FooterBox boxheight={300}>
            <Box>
                <FooterCard elevation={0}>
                    <FooterCardContent>
                        <Grid container spacing={2} className="grid-container">
                            <Grid item xs={12} md={8} className="todo-grid-item">
                                <Typography variant="h2" sx={{ mt: 3.5, mb: 2, display: 'inline-block' }}>
                                    Ready to upgrade your personal finances?
                                </Typography>
                                <Typography variant="body1" className="dashboard-subheading" sx={{ mb: 1.5, display: 'block' }}>
                                    Your personal finance solution, always by your side.
                                </Typography>
                                <GetStartedBtn
                                    variant="contained"
                                    color="primary"
                                    onClick={() => navigate('/login')}
                                    endIcon={
                                        <>
                                            <Divider orientation="vertical" className="btn-divider" />
                                            <ArrowForwardIcon />
                                        </>
                                    }
                                >
                                    Get Started
                                </GetStartedBtn>
                            </Grid>
                        </Grid>
                    </FooterCardContent>
                    <CardMedia component="img" className="todo-image" image="img/footer.svg" alt="Live from space album cover" />
                </FooterCard>
            </Box>
        </FooterBox>
    );
};

export default GetStarted;
