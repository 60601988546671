import * as ActionTypes from "./ActionTypes";
import { apiCall } from "utils/Utils";
import Globals from "Globals.js";
import { toast } from "react-toastify";

/**
 * @author disha
 * @param {*} data
 * @returns
 * @use to chat message and get the robo reponse
 */
export const sendChatBotMessage = (data) => async (dispatch) => {
  try {
    dispatch(sendChatBotMessageInit());
    const apiResponse = await apiCall(
      `${Globals.API_ROOT_URL}finbot`,
      "post",
      data
    );
    if (apiResponse !== undefined && apiResponse.status === 200) {
      dispatch(sendChatBotMessageSuccess(apiResponse.data, data));
    } else {
      toast.error(Globals.errorMsg);
      dispatch(sendChatBotMessageError());
    }
  } catch (error) {
    toast.error(Globals.errorMsg);
    dispatch(sendChatBotMessageError());
    throw error;
  }
};
// handle loader when api started to call
export const sendChatBotMessageInit = () => {
  return {
    type: ActionTypes.SEND_CHAT_BOT_MSG_INIT,
  };
};
//handle success of api
export const sendChatBotMessageSuccess = (val,userData) => {
  return {
    type: ActionTypes.SEND_CHAT_BOT_MSG_SUCCESS,
    data: val,
    userData,
  };
};

// handle api error
export const sendChatBotMessageError = () => {
  return {
    type: ActionTypes.SEND_CHAT_BOT_MSG_ERROR,
  };
};
export const openChatBotPopup = (val) => {
    return {
        type: ActionTypes.OPEN_CHATBOT_POPUP,
        data: val
    };
};