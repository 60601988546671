import * as ActionTypes from '../actions/ActionTypes';

const initialState = {
    blogsList: [],
    dashboardBlogsList: [],
    blogDetails: {},
    dashboardBlogDetails: {},
    blogCategoryList: [],
    blogDashboardCategoryList: [],
    loader: false
};

export const BlogReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.FETCH_ALL_BLOGS_ERROR:
        case ActionTypes.FETCH_ALL_DASHBOARD_BLOGS_ERROR:
        case ActionTypes.FETCH_BLOG_BY_ID_ERROR:
        case ActionTypes.FETCH_DASHBOARD_BLOG_BY_ID_ERROR:
        case ActionTypes.CREATE_NEW_BLOG_SUCCESS:
        case ActionTypes.CREATE_NEW_BLOG_ERROR:
        case ActionTypes.UPDATE_BLOG_SUCCESS:
        case ActionTypes.UPLOAD_NEW_TABLE_SUCCESS:
        case ActionTypes.UPDATE_BLOG_ERROR:
        case ActionTypes.UPLOAD_NEW_TABLE_ERROR:
        case ActionTypes.UPDATE_BLOG_CATEGORY_SUCCESS:
        case ActionTypes.UPDATE_BLOG_CATEGORY_ERROR:
        case ActionTypes.DELETE_BLOG_SUCCESS:
        case ActionTypes.DELETE_BLOG_ERROR:
        case ActionTypes.DELETE_CHAT_MESSAGES_SUCCESS:
        case ActionTypes.DELETE_CHAT_MESSAGES_ERROR:
        case ActionTypes.DELETE_VALIDATE_CHAT_MESSAGES_SUCCESS:
        case ActionTypes.DELETE_VALIDATE_CHAT_MESSAGES_ERROR:
        case ActionTypes.DELETE_BLOG_CATEGORY_SUCCESS:
        case ActionTypes.DELETE_BLOG_CATEGORY_ERROR:
        case ActionTypes.FETCH_BLOG_CATEGORY_ERROR:
        case ActionTypes.FETCH_BLOG_DASHBOARD_CATEGORY_ERROR:
            return { ...state, loader: false };

        case ActionTypes.FETCH_ALL_BLOGS_INIT:
        case ActionTypes.FETCH_ALL_DASHBOARD_BLOGS_INIT:
        case ActionTypes.FETCH_BLOG_BY_ID_INIT:
        case ActionTypes.FETCH_DASHBOARD_BLOG_BY_ID_INIT:
        case ActionTypes.CREATE_NEW_BLOG_INIT:
        case ActionTypes.UPDATE_BLOG_INIT:
        case ActionTypes.UPLOAD_NEW_TABLE_INIT:
        case ActionTypes.UPDATE_BLOG_CATEGORY_INIT:
        case ActionTypes.DELETE_BLOG_INIT:
        case ActionTypes.DELETE_VALIDATE_CHAT_MESSAGES_INIT:
        case ActionTypes.DELETE_CHAT_MESSAGES_INIT:
        case ActionTypes.DELETE_BLOG_CATEGORY_INIT:
        case ActionTypes.FETCH_BLOG_CATEGORY_INIT:
        case ActionTypes.FETCH_BLOG_DASHBOARD_CATEGORY_INIT:
            return { ...state, loader: true };

        case ActionTypes.FETCH_BLOG_CATEGORY_SUCCESS:
            return {
                ...state,
                loader: false,
                blogCategoryList: action.data
            };
        case ActionTypes.FETCH_BLOG_DASHBOARD_CATEGORY_SUCCESS:
            return {
                ...state,
                loader: false,
                blogDashboardCategoryList: action.data
            };

        case ActionTypes.FETCH_ALL_BLOGS_SUCCESS:
            return { ...state, blogsList: action.data, loader: false };
        case ActionTypes.FETCH_ALL_DASHBOARD_BLOGS_SUCCESS:
            return { ...state, dashboardBlogsList: action.data, loader: false };

        case ActionTypes.FETCH_BLOG_BY_ID_SUCCESS:
            return { ...state, blogDetails: action.data, loader: false };
        case ActionTypes.FETCH_DASHBOARD_BLOG_BY_ID_SUCCESS:
            return { ...state, dahsboardBlogDetails: action.data, loader: false };

        default:
            return state;
    }
};
