import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
    CardContent,
    Dialog,
    DialogContent,
    DialogContentText,
    FormControlLabel,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Paper,
    Popover,
    Rating,
    Typography
} from '@mui/material';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Checkbox from '@mui/material/Checkbox';
import DialogActions from '@mui/material/DialogActions';
import { Box } from '@mui/system';
import { CustomButton } from 'assets/jss/ButtonStyle';
import { TopHeadingPaper, UploadCardParent, UploadStyledAccordion } from 'assets/jss/GeneralStyle';
import EnhancedTable from 'components/CommonComponent/CommonTable/EnhancedTable';
import NoDataFound from 'components/CommonComponent/NoDataFound';
import CustomDialogTitle from 'components/DialogTitle';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { submitHomePurch } from 'redux/actions/HomeCalActions';
export default function HomePurchaseOutput(props) {
    const dispatch = useDispatch();
    const homecal = useSelector((state) => state.homecal);

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const id = open ? 'action-popup' : undefined;
    // to open action popup
    const handlePopoverOpen = (event) => setAnchorEl(event.currentTarget);

    // const [anchorEl, setAnchorEl] = useState(null);
    const [selectedFile, setSelectedFile] = useState([]);
    const [sampleTable, setSampleTable] = useState({});
    const [confirmDeleteDialog, setConfirmDeleteDialog] = useState(false);
    const container = useRef(null);

    // to close action popup on outside click
    const handlePopoverClose = () => setAnchorEl(null);

    const handleDeleteConfirmation = () => setConfirmDeleteDialog(true);

    const handleCancelDeleteConfirmation = () => {
        handlePopoverClose();
        setConfirmDeleteDialog(false);
    };

    //handle selected files to delete and pass those ids to parent component
    const handleFileDelete = () => {
        dispatch(submitHomePurch(selectedFile, 'delete'));
    };

    useEffect(() => {
        if (props.homecal?.submitSuccess) {
            props.updateBreadCrumb(selectedFile);
            setSelectedFile([]);
            handleCancelDeleteConfirmation();
        }
    }, [props.homecal]);

    // store checked file
    const handleSelectedFileCheck = (e, id) => {
        if (e.target.checked === false) {
            const currId = selectedFile.indexOf(id);
            if (currId > -1) {
                setSelectedFile(selectedFile.filter((val) => val !== id));
            }
        } else {
            setSelectedFile([...selectedFile, id]);
        }
    };
    useEffect(() => {
        // make column list for output table
        if (homecal.homePurchFiles.length > 0) {
            const ignoreFields = ['id'];
            let formatChartData = [];
            homecal.homePurchFiles.forEach((val) => {
                const individualChartData = [];
                Object.keys(val?.purchFileRls[0]).forEach((subVal) => {
                    if (!ignoreFields.includes(subVal)) {
                        const formattedKey = subVal.replaceAll(/_/g, ' ');
                        individualChartData.push({
                            Header: formattedKey || subVal,
                            accessor: subVal,
                            disableFilters: true,
                            width: 100,
                            Cell: ({ value, row, cell }) =>
                                subVal === 'favorites' ? (
                                    <Rating
                                        key={'favorites' + row.original.id}
                                        name={'favorites' + row.original.id}
                                        value={value}
                                        precision={1}
                                        size="small"
                                        max={1}
                                        readOnly
                                    />
                                ) : (
                                    value || '-'
                                )
                        });
                    }
                });
                formatChartData.push(individualChartData);
            });
            setSampleTable({
                data: homecal.homePurchFiles,
                column: formatChartData
            });
        }
    }, [homecal.homePurchFiles]);

    return (
        <>
            <TopHeadingPaper elevation={2}>
                <Typography variant="h6" sx={{ mb: 2, display: 'flex' }} gutterBottom className="top-heading-text">
                    My Home Purchase file(s)
                </Typography>
                {homecal.homePurchFiles.length ? (
                    <IconButton aria-label="settings" onClick={handlePopoverOpen} aria-describedby={id}>
                        <MoreVertIcon />
                    </IconButton>
                ) : (
                    <></>
                )}
            </TopHeadingPaper>
            <UploadCardParent elevation={2} className={'portoutput'}>
                <CardContent>
                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handlePopoverClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center'
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center'
                        }}
                    >
                        <List component="nav" aria-label="action list">
                            <ListItem button onClick={(event) => handleDeleteConfirmation()} disabled={selectedFile.length === 0}>
                                <ListItemText primary="Delete" />
                            </ListItem>
                        </List>
                    </Popover>
                    <Grid container>
                        <Grid item xs={12} md={12}>
                            {homecal.homePurchFiles.length > 0 ? (
                                homecal.homePurchFiles.map(({ purchFileRls, file_name, id, who, what }, Uindex) => {
                                    return (
                                        <UploadStyledAccordion key={Uindex + 'uploadedFileDetailPurch'}>
                                            <AccordionSummary
                                                ref={container}
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="homepurchaccpanel"
                                                id="homepurch-header"
                                            >
                                                <FormControlLabel
                                                    aria-label="Acknowledge"
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <Checkbox
                                                            checked={selectedFile.indexOf(id) > -1 ? true : false}
                                                            inputProps={{
                                                                'aria-label': 'primary checkbox'
                                                            }}
                                                            name={id}
                                                            id={id}
                                                            onChange={(e) => handleSelectedFileCheck(e, id)}
                                                        />
                                                    }
                                                    label={
                                                        <>
                                                            <Grid container spacing={2}>
                                                                <Grid item xs={12}>
                                                                    <Box
                                                                        component="div"
                                                                        textOverflow="ellipsis"
                                                                        style={{ wordBreak: 'break-all' }}
                                                                    >
                                                                        {file_name}
                                                                    </Box>
                                                                </Grid>
                                                            </Grid>
                                                        </>
                                                    }
                                                />
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Grid container>
                                                    <Grid item xs={12} sm={12}>
                                                        <Paper elevation={0}>
                                                            {Object.keys(sampleTable).length > 0 ? (
                                                                sampleTable.column[Uindex] ? (
                                                                    <EnhancedTable
                                                                        enablePagination={false}
                                                                        enableSearch={false}
                                                                        columns={sampleTable.column[Uindex]}
                                                                        data={sampleTable?.data[Uindex]?.purchFileRls}
                                                                        enableSort={false}
                                                                        hideToolBar={true}
                                                                        sampleTable={true}
                                                                    />
                                                                ) : (
                                                                    ''
                                                                )
                                                            ) : (
                                                                ''
                                                            )}
                                                        </Paper>
                                                    </Grid>
                                                </Grid>
                                            </AccordionDetails>
                                        </UploadStyledAccordion>
                                    );
                                })
                            ) : (
                                <NoDataFound msg="Results will be shown after uploading." />
                            )}
                        </Grid>
                    </Grid>
                    <Dialog
                        open={confirmDeleteDialog}
                        onClose={handleCancelDeleteConfirmation}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <CustomDialogTitle id="alert-dialog-title" onClose={handleCancelDeleteConfirmation}>
                            {'Are you sure?'}
                        </CustomDialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Do you really want to delete these home purchase file(s) ? This process can not be undone.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <CustomButton color="primary" onClick={handleCancelDeleteConfirmation}>
                                Cancel
                            </CustomButton>

                            <CustomButton color="primary" variant="contained" autoFocus onClick={handleFileDelete}>
                                Delete
                            </CustomButton>
                        </DialogActions>
                    </Dialog>
                </CardContent>
            </UploadCardParent>
        </>
    );
}
