import React from "react";
import PropTypes from "prop-types";
import CustomTooltip from "../CustomTooltip";
import { Toolbar, Typography, Button } from "@mui/material";
import CachedIcon from "@mui/icons-material/Cached";
import classNames from "classnames";

const EnhancedTableToolbar = (props) => {
  return (
    <Toolbar className={classNames(props.classes.root)}>
      <Typography
        className={props.classes.title}
        variant='h6'
        id='tableTitle'
        component='div'>
        {props.title}
        {props.tooltip ? (
          <CustomTooltip
            className={props.classes.tooltipLabel}
            tooltipText={props.tooltip}
            label={props.title}
          />
        ) : (
          ""
        )}
      </Typography>
      <Button simple color='primary' size='lg' onClick={props.handleLoadData}>
        Load The Data
        <CachedIcon color='white' />
      </Button>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  handleLoadData: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};
export default EnhancedTableToolbar;
