import * as React from 'react';
import { alpha, styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import TextField from '@mui/material/TextField';
import { InputAdornment } from '@mui/material';
export const StyledTextField = styled(TextField)(({ theme }) => {
    return {
        display: 'flex',
        flexDirection: 'column',
        //   '& label.Mui-focused': {
        //     //   color: 'green'
        //   },
        //   '& .MuiInput-underline:after': {
        //       borderBottomColor: 'green'
        //   },
        //   '& .MuiOutlinedInput-root': {
        //       '& fieldset': {
        //           borderColor: 'red'
        //       },
        //       '&:hover fieldset': {
        //           borderColor: 'yellow'
        //       },
        //       '&.Mui-focused fieldset': {
        //           borderColor: 'green'
        //       }
        //   },
        '& .MuiFormLabel-root': {
            fontSize: theme.typography.htmlFontSize,
            color: theme.palette.text.dark
        }
    };
});
export const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(3),
        padding: '0 3px'
    },
    '&.text-with-adornment .MuiInputBase-input': {
        paddingRight: '44px'
    },
    // // display: 'grid',
    '& .MuiInputBase-input': {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
        border: `1px solid ${theme.palette.common.black}`,
        fontSize: 16,
        padding: '10px 12px',
        transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        '&:focus': {
            boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
            borderColor: theme.palette.primary.main
        }
    }
}));
export const StyledInputAdornment = styled(InputAdornment)(({ theme }) => ({
    position: 'absolute',
    right: '14px'
}));
