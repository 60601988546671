import * as ActionTypes from './ActionTypes';
import { apiCall, handleError } from 'utils/Utils';
import Globals from 'Globals.js';
import { toast } from 'react-toastify';
/**
 * @author disha
 * @param {*} data
 * @use to fetch data of equity dashboard
 * @returns
 */
export const fetchEquityDashboardData = () => async (dispatch) => {
    try {
        dispatch(fetchEquityDashboardInit());
        const apiResponse = await apiCall(`${Globals.API_ROOT_URL}equitydashboard`, 'get');
        if (apiResponse !== undefined && apiResponse.status === 200) {
            dispatch(fetchEquityDashboardSuccess(apiResponse.data));
        } else {
            handleError(apiResponse, dispatch);
            dispatch(fetchEquityDashboardError());
        }
    } catch (error) {
        toast.error(Globals.errorMsg);
        dispatch(fetchEquityDashboardError());
        throw error;
    }
};
// handle loader when api started to call
export const fetchEquityDashboardInit = () => {
    return {
        type: ActionTypes.FETCH_EQUITY_DASHBOARD_INIT
    };
};
//handle success of api
export const fetchEquityDashboardSuccess = (val) => {
    return {
        type: ActionTypes.FETCH_EQUITY_DASHBOARD_SUCCESS,
        data: val
    };
};

// handle api error
export const fetchEquityDashboardError = () => {
    return {
        type: ActionTypes.FETCH_EQUITY_DASHBOARD_ERROR
    };
};
/**
 * @author disha
 * @param {*} data
 * @use to fetch data of watchlist
 * @returns
 */
export const fetchWatchlistData = () => async (dispatch) => {
    try {
        dispatch({
            type: ActionTypes.FETCH_WATCHLIST_INIT
        });
        const apiResponse = await apiCall(`${Globals.API_ROOT_URL}watchlist`, 'get');
        if (apiResponse !== undefined && apiResponse.status === 200) {
            dispatch(fetchWatchlistSuccess(apiResponse.data.data));
        } else {
            handleError(apiResponse, dispatch);
            dispatch(fetchWatchlistError());
        }
    } catch (error) {
        toast.error(Globals.errorMsg);
        dispatch(fetchWatchlistError());
        throw error;
    }
};
//handle success of api
export const fetchWatchlistSuccess = (data) => {
    return {
        type: ActionTypes.FETCH_WATCHLIST_SUCCESS,
        data
    };
};

// handle api error
export const fetchWatchlistError = () => {
    return {
        type: ActionTypes.FETCH_WATCHLIST_ERROR
    };
};
/**
 * @author disha
 * @param {*} data
 * @use to fetch data of watchlist
 * @returns
 */
export const saveWatchListData =
    (data, url, type = 'post') =>
    async (dispatch) => {
        try {
            dispatch({
                type: ActionTypes.SAVE_WATCHLIST_INIT
            });
            const apiResponse = await apiCall(`${Globals.API_ROOT_URL}${url}`, type, data);
            if (apiResponse !== undefined && apiResponse.status === 200) {
                toast.success(apiResponse.data.data.message);
                dispatch(saveWatchListSuccess(apiResponse.data.data, url));
                // dispatch(fetchWatchlistData());
            } else {
                handleError(apiResponse, dispatch);
                dispatch(fetchWatchlistError());
            }
        } catch (error) {
            toast.error(Globals.errorMsg);
            dispatch(fetchWatchlistError());
            throw error;
        }
    };
//handle success of api
export const saveWatchListSuccess = (val, url) => {
    return {
        type: ActionTypes.SAVE_WATCHLIST_SUCCESS,
        data: val,
        txt: url
    };
};
//handle success of api
export const handleNewDataFetched = (flag) => {
    return {
        type: ActionTypes.HANDLE_DATA_FETCH,
        data: flag
    };
};

export const deleteWatchListData =
    (data, url, type = 'delete') =>
    async (dispatch) => {
        try {
            dispatch({ type: ActionTypes.DELETE_WATCHLIST_INIT });
            const apiResponse = await apiCall(`${Globals.API_ROOT_URL}${url}`, type, data);
            if (apiResponse !== undefined && apiResponse.status === 200) {
                toast.success(apiResponse.data.data.message);
                dispatch(deleteWatchListSuccess(apiResponse.data.data, url));
            } else {
                handleError(apiResponse, dispatch);
                dispatch(fetchWatchlistError());
            }
        } catch (error) {
            toast.error(Globals.errorMsg);
            dispatch(fetchWatchlistError());
            throw error;
        }
    };

export const deleteWatchListSuccess = (val, url) => {
    return {
        type: ActionTypes.DELETE_WATCHLIST_SUCCESS,
        data: val,
        txt: url
    };
};

export const updateWatchListData =
    (data, url, type = 'put') =>
    async (dispatch) => {
        try {
            dispatch({ type: ActionTypes.UPDATE_WATCHLIST_INIT });
            const apiResponse = await apiCall(`${Globals.API_ROOT_URL}${url}`, type, data);
            if (apiResponse !== undefined && apiResponse.status === 200) {
                toast.success(apiResponse.data.data.message);
                dispatch(updateWatchListSuccess(apiResponse.data.data));
            } else {
                handleError(apiResponse, dispatch);
                dispatch(fetchWatchlistError());
            }
        } catch (error) {
            toast.error(Globals.errorMsg);
            dispatch(fetchWatchlistError());
            throw error;
        }
    };

export const updateWatchListSuccess = (val) => {
    return {
        type: ActionTypes.UPDATE_WATCHLIST_SUCCESS,
        data: val
    };
};

/**
 * @author disha
 * @param {*} data
 * @use to fetch data of watchlist
 * @returns
 */
export const fetchWatchListChart =
    (symbol, range = '1m') =>
    async (dispatch) => {
        try {
            dispatch({
                type: ActionTypes.FETCH_WATCHLIST_CHART_INIT
            });
            const apiResponse = await apiCall(`${Globals.API_ROOT_URL}watchlist/chart?symbol=${symbol}&range=${range}`, 'GET');
            if (apiResponse !== undefined && apiResponse.status === 200) {
                dispatch(fetchWatchListChartSuccess(apiResponse.data.data));
            } else {
                handleError(apiResponse, dispatch);
                dispatch(fetchWatchListChartError());
            }
        } catch (error) {
            toast.error(Globals.errorMsg);
            dispatch(fetchWatchListChartError());
            throw error;
        }
    };
//handle success of api
export const fetchWatchListChartSuccess = (val) => {
    return {
        type: ActionTypes.FETCH_WATCHLIST_CHART_SUCCESS,
        data: val
    };
};
export const fetchWatchListChartError = () => {
    return {
        type: ActionTypes.FETCH_WATCHLIST_CHART_ERROR
    };
};

/**
 * @author Ulyana
 * @param {*} data, type
 * @use to fetch data of watchlist
 * @returns
 */
export const fetchWatchListCompanyName =
    (data, type = 'put') =>
    async (dispatch) => {
        try {
            dispatch({
                type: ActionTypes.FETCH_WATCHLIST_COMPANY_NAME_INIT
            });
            const apiResponse = await apiCall(`${Globals.API_ROOT_URL}watchlist/chart`, type, data);
            if (apiResponse !== undefined && apiResponse.status === 200) {
                dispatch(fetchWatchListCompanyNameSuccess(apiResponse.data.data));
            } else {
                handleError(apiResponse, dispatch);
                dispatch(fetchWatchListCompanyNameError());
            }
        } catch (error) {
            toast.error(Globals.errorMsg);
            dispatch(fetchWatchListCompanyNameError());
            throw error;
        }
    };
//handle success of api
export const fetchWatchListCompanyNameSuccess = (val) => {
    return {
        type: ActionTypes.FETCH_WATCHLIST_COMPANY_NAME_SUCCESS,
        data: val
    };
};
export const fetchWatchListCompanyNameError = () => {
    return {
        type: ActionTypes.FETCH_WATCHLIST_COMPANY_NAME_ERROR
    };
};

export const fetchTickerList =
    (data, type = 'put') =>
    async (dispatch) => {
        try {
            dispatch({
                type: ActionTypes.FETCH_TICKER_LIST_INIT
            });
            const apiResponse = await apiCall(`${Globals.API_ROOT_URL}tickers_api`, type, data);
            if (apiResponse !== undefined && apiResponse.status === 200) {
                dispatch(fetchTickerListSuccess(apiResponse.data.data));
            } else {
                handleError(apiResponse, dispatch);
                dispatch(fetchTickerListError());
            }
        } catch (error) {
            toast.error(Globals.errorMsg);
            dispatch(fetchTickerListError());
            throw error;
        }
    };
//handle success of api
export const fetchTickerListSuccess = (val) => {
    return {
        type: ActionTypes.FETCH_TICKER_LIST_SUCCESS,
        data: val
    };
};
export const fetchTickerListError = () => {
    return {
        type: ActionTypes.FETCH_TICKER_LIST_ERROR
    };
};
