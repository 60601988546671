import { Box, Button, Card, CardMedia, Grid, Paper, Tabs, Typography } from '@mui/material';
import { styled } from '@mui/styles';
export const SettingTabBox = styled(Box)(({ ps, theme }) => {
           return {
             
           };
       });
export const SettingTabs = styled(Tabs)(({ ps, theme }) => {
           return {
            margin:'0 18.5rem',
            background:'none',
           };
       });
export const StyledProfileGrid = styled(Grid)(({ ps, theme }) => {
           return {
          margin: 'auto',
    marginBottom: 0
           };
       });
export const PlaidCardMedia = styled(CardMedia)(({ ps, theme }) => {
    return {
        height:140,
        backgroundSize:'12rem',
    };
});
export const PlaidCard = styled(Card)(({ ps, theme }) => {
    return {
        maxWidth: '345px',
        '& .plaid-action-wrapper': {
            justifyContent: 'center'
        },
        '& .login-btn': {
            width: '70%',
        }
    };
});
export const BankDetailCard = styled(Card)(({ ps, theme }) => {
    return {
       marginTop:20
    };
});
export const BankTitleBox = styled(Box)(({ ps, theme }) => {
    return {
        display:'flex',
        justifyContent:'space-between',
        marginBottom:10,
        "& .bankTitle":{
            margin:'auto 0'
        }
    };
});
export const PasswordGrid = styled(Grid)(({ ps, theme }) => {
           return {
            '& .customtext-field-box':{
               boxShadow: theme.shadows[4],
               borderRadius: theme.borderRadius.regular,
            }
           };
       });
export const StyledFileInputBox = styled(Box)(({ ps, theme }) => {
           return {
               textAlign: 'center',
               display: 'inline-block',
               marginBottom: '9px',
               marginTop: 9,
               width:'9.1vw',
               '& input[type=file]': {
                   display: 'none'
               },

               '& .thumbnail': {
                   display: 'inline-block',
                   marginBottom: '10px',
                   overflow: 'hidden',
                   textAlign: 'center',
                   verticalAlign: 'middle',
                   maxWidth: '360px',
                   boxShadow: '0 5px 15px -8px rgb(0 0 0 / 24%), 0 8px 10px -5px rgb(0 0 0 / 20%)'
               },
               '& .thumbnail.img-circle': {
                   borderRadius: '50%',
                   maxWidth: '100px',
                   marginTop: 15
               },
               '& .thumbnail>img': {
                   maxHeight: '100%',
                   width: '100%',
                   minHeight: 100
               }
           };
       });
