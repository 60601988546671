import { Typography } from "@mui/material";
import React from 'react'

const NoDataFound = ({ msg,style }) => {
  const msgToPrint = msg || "Results will be shown after calculation.";
    return (
      <Typography variant='body2' style={{...style}}>
        {msgToPrint}
      </Typography>
    );
}

export default NoDataFound
