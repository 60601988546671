import { apiCall } from 'utils/Utils';
import { handleError } from 'utils/Utils';
import { toast } from 'react-toastify';
import * as ActionTypes from './ActionTypes';
import Globals from 'Globals.js';
import { apiCall2 } from 'utils/Utils';

// handle success api
export const createNewTableSuccess = (val) => {
    return {
        type: ActionTypes.CREATE_NEW_TABLE_SUCCESS,
        data: val
    };
};

// handle api  error
export const createNewTableError = () => {
    return {
        type: ActionTypes.CREATE_NEW_TABLE_ERROR
    };
};

// create new Table
export const createNewTable = (data, closeEditor) => async (dispatch) => {
    try {
        dispatch({
            type: ActionTypes.CREATE_NEW_TABLE_INIT
        });
        const apiResponse = await apiCall(`${Globals.API_ROOT_URL}admin_research_table`, 'post', data);
        if (apiResponse !== undefined && apiResponse.status === 200) {
            toast.success(apiResponse.data.data.message);
            dispatch(createNewTableSuccess(apiResponse.data));
            closeEditor();
        } else {
            handleError(apiResponse, dispatch);
            dispatch(createNewTableError());
        }
    } catch (error) {
        toast.error(Globals.errorMsg);
        dispatch(createNewTableError());
        throw error;
    }
};

// handle success of api
export const fetchTableListSuccess = (val) => {
    return {
        type: ActionTypes.FETCH_ALL_TABLE_SUCCESS,
        data: val
    };
};

// handle api error
export const fetchTableListError = () => {
    return {
        type: ActionTypes.FETCH_ALL_TABLE_ERROR
    };
};

// fetch all Table list
export const fetchTableList = () => async (dispatch) => {
    try {
        dispatch({
            type: ActionTypes.FETCH_ALL_TABLE_INIT
        });
        const apiResponse = await apiCall(`${Globals.API_ROOT_URL}admin_research_table`, 'get');
        if (apiResponse !== undefined && apiResponse.status === 200) {
            dispatch(fetchTableListSuccess(apiResponse.data.data));
        } else {
            handleError(apiResponse, dispatch);
            dispatch(fetchTableListError());
        }
    } catch (error) {
        toast.error(Globals.errorMsg);
        dispatch(fetchTableListError());
        throw error;
    }
};

// handle loader when api started to call
export const deleteTableInit = () => {
    return {
        type: ActionTypes.DELETE_RESEARCH_TABLE_INIT
    };
};
//handle success of api
export const deleteTableSuccess = (val) => {
    return {
        type: ActionTypes.DELETE_RESEARCH_TABLE_SUCCESS,
        data: val
    };
};
// handle api error
export const deleteTableError = () => {
    return {
        type: ActionTypes.DELETE_RESEARCH_TABLE_ERROR
    };
};

export const deleteReasearchTable = (data, type) => async (dispatch) => {
    try {
        dispatch(deleteTableInit());
        const apiResponse = await apiCall(`${Globals.API_ROOT_URL}admin_research_table/${data}`, type, data);
        if (apiResponse !== undefined && apiResponse.status === 200) {
            toast.success(apiResponse.data.data.message);
            dispatch(deleteTableSuccess());
            dispatch(fetchTableList());
        } else {
            handleError(apiResponse, dispatch);
            dispatch(deleteTableError());
        }
    } catch (error) {
        toast.error(Globals.errorMsg);
        dispatch(deleteTableError());
        throw error;
    }
};

// handle success of api
export const updateTableSuccess = (val) => {
    return {
        type: ActionTypes.UPDATE_TABLE_SUCCESS,
        data: val
    };
};

// handle api error
export const updateTableError = () => {
    return {
        type: ActionTypes.UPDATE_TABLE_ERROR
    };
};

// update blog by id
export const updateTable = (id, data, closeEditor) => async (dispatch) => {
    try {
        dispatch({
            type: ActionTypes.UPDATE_TABLE_INIT
        });
        const apiResponse = await apiCall(`${Globals.API_ROOT_URL}admin_research_table/${id}`, 'patch', data);
        if (apiResponse !== undefined && apiResponse.status === 200) {
            toast.success(apiResponse.data.data.message);
            dispatch(updateTableSuccess(apiResponse.data.data));
            closeEditor();
        } else {
            handleError(apiResponse, dispatch);
            dispatch(updateTableError());
        }
    } catch (error) {
        toast.error(Globals.errorMsg);
        dispatch(updateTableError());
        throw error;
    }
};

// handle success of api
export const uploadTableSuccess = (val) => {
    return {
        type: ActionTypes.UPLOAD_NEW_TABLE_SUCCESS,
        data: val
    };
};

// handle api error
export const uploadTableError = () => {
    return {
        type: ActionTypes.UPLOAD_NEW_TABLE_ERROR
    };
};

// update blog by id
export const uploadTable = (data, closeEditor) => async (dispatch) => {
    try {
        dispatch({
            type: ActionTypes.UPLOAD_NEW_TABLE_INIT
        });
        const apiResponse = await apiCall2(`${Globals.API_ROOT_URL}admin_research_table`, 'PUT', data);
        if (apiResponse !== undefined && apiResponse.status === 200) {
            toast.success(apiResponse.data.data.message);
            dispatch(uploadTableSuccess(apiResponse.data.data));
            dispatch(fetchTableList());
            closeEditor();
        } else {
            handleError(apiResponse, dispatch);
            dispatch(uploadTableError());
        }
    } catch (error) {
        toast.error(Globals.errorMsg);
        dispatch(uploadTableError());
        throw error;
    }
};
