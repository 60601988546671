import * as ActionTypes from './ActionTypes';
import { apiCall, handleError } from 'utils/Utils';
import Globals from 'Globals.js';
import { toast } from 'react-toastify';
import { portfolioFiles, portfolioData } from 'Portfolio.json';

// handle loader when api started to call
export const submitPortfolioDataInit = () => {
    return {
        type: ActionTypes.SUBMIT_PORTFOLIO_FILES_INIT
    };
};
//handle success of api
export const submitPortfolioDataSuccess = () => {
    return {
        type: ActionTypes.SUBMIT_PORTFOLIO_FILES_SUCCESS
    };
};

// handle api error
export const submitPortfolioDataError = () => {
    return {
        type: ActionTypes.SUBMIT_PORTFOLIO_FILES_ERROR
    };
};
/**
 * @author disha
 * @param {*} data
 * @returns
 * @use submit portfolio files data
 */
export const submitPortfolioData = (data) => async (dispatch) => {
    try {
        dispatch(submitPortfolioDataInit());
        const apiResponse = await apiCall(`${Globals.API_ROOT_URL}portfolioupload`, 'post', data);
        if (apiResponse !== undefined && apiResponse.status === 200) {
            toast.success(apiResponse.data.data.message);
            dispatch(submitPortfolioDataSuccess());
            dispatch(fetchPortfolioList());
        } else {
            handleError(apiResponse, dispatch);
            dispatch(submitPortfolioDataError());
        }
    } catch (error) {
        toast.error(Globals.errorMsg);
        dispatch(submitPortfolioDataError());
        throw error;
    }
};

// handle loader when api started to call
export const submitPortAllocationDataInit = () => {
    return {
        type: ActionTypes.SUBMIT_PORT_ALLOCATION_INIT
    };
};
//handle success of api
export const submitPortAllocationDataSuccess = (val) => {
    return {
        type: ActionTypes.SUBMIT_PORT_ALLOCATION_SUCCESS,
        data: val
    };
};

// handle api error
export const submitPortAllocationDataError = () => {
    return {
        type: ActionTypes.SUBMIT_PORT_ALLOCATION_ERROR
    };
};
/**
 * @author disha
 * @param {*} data
 * @use submit portfolio allocation data
 */
export const submitPortAllocationData = (data) => async (dispatch) => {
    try {
        dispatch(submitPortAllocationDataInit());
        const apiResponse = await apiCall(`${Globals.API_ROOT_URL}portfolioallocation`, 'post', data);
        if (apiResponse !== undefined && apiResponse.status === 200) {
            // enableToast && toast.success("Data submitted successfully!");
            dispatch(submitPortAllocationDataSuccess(apiResponse.data));
        } else {
            toast.error(Globals.errorMsg);
            dispatch(submitPortAllocationDataError());
        }
    } catch (error) {
        toast.error(Globals.errorMsg);
        dispatch(submitPortAllocationDataError());
        throw error;
    }
};

//handle success of api
export const fetchPortfolioListSuccess = (val) => {
    return {
        type: ActionTypes.FETCH_PORTFOLIO_LIST_SUCCESS,
        data: val
    };
};

// handle api error
export const fetchPortfolioListError = () => {
    return {
        type: ActionTypes.FETCH_PORTFOLIO_LIST_ERROR
    };
};
/**
 * @author disha
 * @param {*} data
 * @returns
 * @use fetch portfolio list
 */
export const fetchPortfolioList = (data) => async (dispatch) => {
    try {
        // handle loader when api started to call
        dispatch({
            type: ActionTypes.FETCH_PORTFOLIO_LIST_INIT
        });
        const apiResponse = await apiCall(`${Globals.API_ROOT_URL}portfolioupload`, 'get');
        if (apiResponse !== undefined && apiResponse.status === 200) {
            dispatch(fetchPortfolioListSuccess(apiResponse.data));
        } else {
            if (apiResponse.status === 401) {
                dispatch(fetchPortfolioListSuccess(portfolioFiles));
            } else {
                handleError(apiResponse, dispatch);
                dispatch(fetchPortfolioListError());
            }
        }
    } catch (error) {
        toast.error(Globals.errorMsg);
        dispatch(fetchPortfolioListError());
        throw error;
    }
};

//handle success of api
export const fetchSelectedPortDetailSuccess = (val) => {
    return {
        type: ActionTypes.FETCH_PORTFOLIO_DETAILS_SUCCESS,
        data: val
    };
};

// handle api error
export const fetchSelectedPortDetailError = () => {
    return {
        type: ActionTypes.FETCH_PORTFOLIO_DETAILS_ERROR
    };
};
/**
 * @author disha
 * @param {*} data
 * @returns
 * @use fetch selected portfolio details
 */
export const fetchSelectedPortDetail = (data) => async (dispatch) => {
    
    let payload;
    let bankIds = [];
    let textIds = [];
    for (let id of data?.ids) {
        if (id?.includes('*')) {
            bankIds.push(id);
        } else {
            textIds.push(id);
        }
    }
    try {
        // handle loader when api started to call
        dispatch({
            type: ActionTypes.FETCH_PORTFOLIO_DETAILS_INIT
        });
        let apiResponse;
        if (bankIds.length > 0 && textIds.length === 0) {
            // payload = { bank_ids: bankIds, is_saved: data?.is_saved };
            apiResponse = await apiCall(`${Globals.API_ROOT_URL}investment_holdings/${bankIds[0]}`, 'get');
        } else {
            payload = { ids: textIds, bank_ids: bankIds, is_saved: data?.is_saved };
            apiResponse = await apiCall(`${Globals.API_ROOT_URL}portfoliovaluation`, 'post', payload);
        }
        if (apiResponse !== undefined && apiResponse.status === 200) {
            dispatch(fetchSelectedPortDetailSuccess(apiResponse.data));
        } else {
            if (apiResponse.status === 401) {
                dispatch(fetchSelectedPortDetailSuccess(portfolioData));
            } else {
                handleError(apiResponse, dispatch);
                dispatch(fetchSelectedPortDetailError());
            }
        }
    } catch (error) {
        toast.error(Globals.errorMsg);
        dispatch(fetchSelectedPortDetailError());
        throw error;
    }
};

//handle success of api
export const deletePortfolioSuccess = () => {
    return {
        type: ActionTypes.DELETE_PORTFOLIO_SUCCESS
    };
};

// handle api error
export const deletePortfolioError = () => {
    return {
        type: ActionTypes.DELETE_PORTFOLIO_ERROR
    };
};
/**
 * @author disha
 * @param {*} data
 * @returns
 * @use fetch selected portfolio details
 */
export const deletePortfolio = (data) => async (dispatch) => {
    try {
        // handle loader when api started to call
        dispatch({
            type: ActionTypes.DELETE_PORTFOLIO_INIT
        });
        const apiResponse = await apiCall(`${Globals.API_ROOT_URL}portfoliovaluation`, 'delete', data);
        if (apiResponse !== undefined && apiResponse.status === 200) {
            toast.success(apiResponse.data.data.message);
            dispatch(deletePortfolioSuccess());
            dispatch(fetchPortfolioList());
        } else {
            handleError(apiResponse, dispatch);
            dispatch(deletePortfolioError());
        }
    } catch (error) {
        toast.error(Globals.errorMsg);
        dispatch(deletePortfolioError());
        throw error;
    }
};

//handle success of api
export const smartTblPortfolioSuccess = () => {
    return {
        type: ActionTypes.SMART_PORTFOLIO_SUCCESS
    };
};

// handle api error
export const smartTblPortfolioError = () => {
    return {
        type: ActionTypes.SMART_PORTFOLIO_ERROR
    };
};
//api to submit/delete/update data in holding table
export const smartTblPortfolio = (data, type) => async (dispatch) => {
    try {
        // handle loader when api started to call
        dispatch({
            type: ActionTypes.SMART_PORTFOLIO_INIT
        });
        const apiResponse = await apiCall(`${Globals.API_ROOT_URL}portfoliovaluation/smarttable`, type, data);
        if (apiResponse !== undefined && apiResponse.status === 200) {
            toast.success(apiResponse.data.data.message);
            dispatch(smartTblPortfolioSuccess());
            dispatch(fetchPortfolioList());
        } else {
            handleError(apiResponse, dispatch);
            dispatch(smartTblPortfolioError());
        }
    } catch (error) {
        toast.error(Globals.errorMsg);
        dispatch(smartTblPortfolioError());
        throw error;
    }
};
//handle success of api
export const fetchPortfolioChartSuccess = (val) => {
    return {
        type: ActionTypes.FETCH_PORTFOLIO_CHART_SUCCESS,
        data: val
    };
};

// handle api error
export const fetchPortfolioChartError = () => {
    return {
        type: ActionTypes.FETCH_PORTFOLIO_CHART_ERROR
    };
};
/**
 * @author disha
 * @param {*} data
 * @returns
 * @use fetch portfolio chart
 */
export const fetchPortfolioChart =
    (symbol, range = '6mo') =>
    async (dispatch) => {
        try {
            // handle loader when api started to call
            dispatch({
                type: ActionTypes.FETCH_PORTFOLIO_CHART_INIT
            });
            const apiResponse = await apiCall(`${Globals.API_ROOT_URL}portfoliovalchart?symbol=${symbol}&range=${range}`, 'get');
            if (apiResponse !== undefined && apiResponse.status === 200) {
                dispatch(fetchPortfolioChartSuccess(apiResponse.data.data));
            } else {
                handleError(apiResponse, dispatch);
                dispatch(fetchPortfolioChartError());
            }
        } catch (error) {
            toast.error(Globals.errorMsg);
            dispatch(fetchPortfolioChartError());
            throw error;
        }
    };

//handle success of api
export const fetchReloadPortfolioSuccess = (val) => {
    return {
        type: ActionTypes.FETCH_RELOAD_PORTFOLIO_SUCCESS,
        data: val
    };
};

// handle api error
export const fetchReloadPortfolioError = () => {
    return {
        type: ActionTypes.FETCH_RELOAD_PORTFOLIO_ERROR
    };
};
/**
 * @author Leena
 * @param {*} data
 * @returns
 * @use fetch portfolio chart
 */
export const fetchReloadPortfolio = (data) => async (dispatch) => {
    let payload;
    let textIds = [];
    for (let id of data.ids) {
        if (!id.includes('*')) {
            textIds.push(id);
        }
    }
    payload = { ids: textIds };
    try {
        // handle loader when api started to call
        dispatch({
            type: ActionTypes.FETCH_RELOAD_PORTFOLIO_INIT
        });
        const apiResponse = await apiCall(`${Globals.API_ROOT_URL}reload/portfolio`, 'post', payload);
        if (apiResponse !== undefined && apiResponse.status === 200) {
            if (apiResponse.data.code === 200) {
                toast.success(apiResponse.data.message);
            }
            dispatch(fetchReloadPortfolioSuccess(apiResponse.data.data));
        } else {
            handleError(apiResponse, dispatch);
            dispatch(fetchReloadPortfolioError());
        }
    } catch (error) {
        toast.error(Globals.errorMsg);
        dispatch(fetchReloadPortfolioError());
        throw error;
    }
};

export const getInvestmentHoldingsSuccess = (val) => {
    return {
        type: ActionTypes.FETCH_INVESTMENT_HOLDINGS_SUCCESS,
        data: val
    };
};

export const getInvestmentHoldingsError = () => {
    return {
        type: ActionTypes.FETCH_INVESTMENT_HOLDINGS_ERROR
    };
};

export const getInvestmentHoldings = (data) => async (dispatch) => {
    try {
        dispatch({ type: ActionTypes.FETCH_INVESTMENT_HOLDINGS_INIT });
        const details = [];
        await Promise.all(
            data.map(async (bank) => {
                const response = await apiCall(`${Globals.API_ROOT_URL}investment_holdings/${bank.id}`, 'get');
                if (response !== undefined && response.status === 200) {
                    const holdings = response.data.data;
                    // const securities = response.data.securities;
                    if (holdings && Array.isArray(holdings)) {
                        holdings.forEach((h) => {
                            details.push({
                                pid: null,
                                cost_basis: h.cost_basis,
                                institution_price: h.institution_price,
                                institution_price_as_of: h.institution_price_as_of,
                                institution_price_datetime: h.institution_price_datetime,
                                institution_value: h.institution_value,
                                currency: h.iso_currency_code,
                                quantity: h.quantity,
                                is_bank_data: true,
                                type: 'holdings & securities',
                                is_bank_data: true,
                                bank_name: bank.name,
                                symbol: h.ticker_symbol,
                                company_name: h.name,
                                security_type: h.type,
                                currency: h.iso_currency_code,
                                close_price: h.close_price,
                                close_price_as_of: h.close_price_as_of,
                                institution_id: h.institution_id,
                                is_cash_equivalent: h.is_cash_equivalent,
                                quantity: h.quantity,
                                gains: h.gains
                            });
                        });
                    }
                } else {
                    handleError(response, dispatch);
                    dispatch(getInvestmentHoldingsError());
                }
            })
        );
        dispatch(getInvestmentHoldingsSuccess(details));
    } catch (error) {
        toast.error(Globals.errorMsg);
        dispatch(getInvestmentHoldingsError());
        throw error;
    }
};

export const getInvestmentTransactionsSuccess = (val) => {
    return {
        type: ActionTypes.FETCH_INVESTMENT_TRANSACTIONS_SUCCESS,
        data: val
    };
};

export const getInvestmentTransactionsError = () => {
    return {
        type: ActionTypes.FETCH_INVESTMENT_TRANSACTIONS_ERROR
    };
};

export const getInvestmentTransactions = (data) => async (dispatch) => {
    try {
        dispatch({ type: ActionTypes.FETCH_INVESTMENT_TRANSACTIONS_INIT });
        const details = [];
        await Promise.all(
            data.map(async (bank) => {
                const response = await apiCall(`${Globals.API_ROOT_URL}investment_transactions/${bank.id}`, 'get');
                if (response !== undefined && response.status === 200) {
                    response.data.data.forEach((t) => {
                        details.push({ ...t, bank_name: bank.name });
                    });
                } else {
                    handleError(response, dispatch);
                    dispatch(getInvestmentTransactionsError());
                }
            })
        );
        dispatch(getInvestmentTransactionsSuccess(details));
    } catch (error) {
        toast.error(Globals.errorMsg);
        dispatch(getInvestmentTransactionsError());
        throw error;
    }
};

export const getPortfolioValuationDataSuccess = (val) => {
    return {
        type: ActionTypes.FETCH_PORTFOLIO_VALUATION_DATA_SUCCESS,
        data: val
    };
};

export const getPortfolioValuationDataError = () => {
    return {
        type: ActionTypes.FETCH_PORTFOLIO_VALUATION_DATA_ERROR
    };
};

export const getPortfolioValuationData = () => async (dispatch) => {
    try {
        dispatch({ type: ActionTypes.FETCH_PORTFOLIO_VALUATION_DATA_INIT });
        const apiResponse = await apiCall(`${Globals.API_ROOT_URL}get-portfolio-valuation-data`, 'get');
        if (apiResponse !== undefined && apiResponse.status === 200) {
            dispatch(getPortfolioValuationDataSuccess(apiResponse.data.data));
        } else {
            handleError(apiResponse, dispatch);
            dispatch(getPortfolioValuationDataError());
        }
    } catch (error) {
        toast.error(Globals.errorMsg);
        dispatch(getPortfolioValuationDataError());
        throw error;
    }
};
