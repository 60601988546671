import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import Collapse from "@mui/material/Collapse";
import { red } from "@mui/material/colors";
import IconButton from "@mui/material/IconButton";
import { makeStyles } from '@mui/styles';
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FavoriteIcon from "@mui/icons-material/Favorite";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ShareIcon from "@mui/icons-material/Share";
import clsx from "clsx";
import React from "react";
import Meta from "components/meta";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

export default function RecipeReviewCard() {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card className={classes.root} style={{ backgroundColor: "cyan" }}>
       <Meta 
                title="Stock Watch | TPE Hub"
                description="Stay updated with real-time data on your favorite stocks with our Stock Watch page. Track stock prices, view historical data, and get insights to make informed investment decisions."
                page="/stockwatch"
            />
      <CardHeader
        avatar={
          <Avatar
            alt='TPE logo'
            src='https://logo.clearbit.com/tesla.com'
            className={classes.avatar}
          />
        }
        action={
          <IconButton aria-label='settings'>
            <MoreVertIcon />
          </IconButton>
        }
        title='Tesla'
        subheader='Deep Dive'
      />
      <CardMedia
        className={classes.media}
        image='/static/images/cards/paella.jpg'
        title='Paella dish'
      />
      <CardContent>
        <Typography variant='body2' color='textSecondary' component='p'>
          Tesla's mission is to accelerate the world's transition to sustainable
          energy. Tesla was founded in 2003 by a group of engineers who wanted
          to prove that people didn't need to compromise to drive electric –
          that electric vehicles can be better, quicker and more fun to drive
          than gasoline cars.
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        <IconButton aria-label='add to favorites'>
          <FavoriteIcon />
        </IconButton>
        <IconButton aria-label='share'>
          <ShareIcon />
        </IconButton>
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label='show more'>
          <ExpandMoreIcon />
        </IconButton>
      </CardActions>
      <Collapse in={expanded} timeout='auto' unmountOnExit>
        <CardContent>
          <Typography paragraph>Latest Earnings:</Typography>
          <Typography paragraph>
            Tesla posts record net income of $438 million, revenue surges by 74%
            Tesla reported record net income of $438 million during the quarter,
            as well as earnings of 93 cents per share on $10.39 billion in
            revenue.Apr 26, 2021
          </Typography>
          <Typography paragraph>
            In its earnings release, the company said it has weathered chip
            shortages that have plagued the auto industry in part by "pivoting
            extremely quickly to new microcontrollers, while simultaneously
            developing firmware for new chips made by new suppliers."
          </Typography>
          <Typography paragraph>
            Net profit reached a quarterly record of $438 million on a GAAP
            basis, and the company recorded $518 million in revenue from sales
            of regulatory credits during the period. It also recorded a $101
            million positive impact from sales of bitcoin during the quarter.
          </Typography>
          <Typography>
            Tesla is now aiming to produce 2,000 Model S and X vehicles per week
            later this year. The company said Monday it expects more than 50%
            vehicle delivery growth in 2021 overall, which implies minimum
            deliveries around 750,000 vehicles this year.
          </Typography>
        </CardContent>
      </Collapse>
    </Card>
  );
}
