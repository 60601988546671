import React from "react";
import { Box } from "@mui/material";
import { StyledPhoneInput } from "assets/jss/GeneralStyle";
import { StyledFormHelperText } from "assets/jss/GeneralStyle";
export default function CustomPhone(props) {
  const { id, error, required, name, value, profilePhone, inputRef, inputProps, className, ...rest } = props;

  const handleChange = (param) => {
    if (props && props.onChange) {
      props.onChange(param);
    }
  };
  return (
      <Box className={`${error ? 'error-wrapper':''}`}>
          <StyledPhoneInput
              country={'us'}
              name={name || id}
              inputProps={inputProps}
              className={className || ''}
              containerClass
              inputRef={inputRef}
              value={value}
              onChange={(e) => handleChange(e)}
              {...rest}
          />
          {error && <StyledFormHelperText>{error.message}</StyledFormHelperText>}
      </Box>
  );
}
