import { Card, CardContent, Grid, Typography } from '@mui/material';
import { CustomButton } from 'assets/jss/ButtonStyle';
import { CalCardActions } from 'assets/jss/RetirementStyle';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { submitPropertyInvest, submitPropertyInvestSuccess } from 'redux/actions/GeneralCalActions';
import RenderField from 'utils/RenderField';
import * as tooltip from 'Tooltip.json';

const fieldsDetail = [
    {
        label: 'Upfront Purchase Expenses',
        child: [
            {
                name: 'Purchase_Price',
                label: 'Purchase Price',
                helpertext: '(Upfront purchase expenses)',
                type: 'number',
                required: true,
                commaSep: true,
                custompattern: 'decimalRate',
                placeholder: '100,000'
            },
            {
                name: 'Parking_Space',
                label: 'Parking Space',
                helpertext: '(Upfront purchase expenses)',
                type: 'number',
                commaSep: true,
                custompattern: 'decimalRate',
                placeholder: '100,000'
            },
            {
                name: 'Closing_Costs',
                label: 'Closing Costs',
                helpertext: '(Monthly Expenses)',
                type: 'number',
                commaSep: true,
                custompattern: 'decimalRate',
                placeholder: '100,000'
            },
            {
                name: 'Misc_Purch_Exp',
                label: 'Miscellaneous Purch Exp',
                helpertext: '(Monthly Expenses)',
                type: 'number',
                commaSep: true,
                custompattern: 'decimalRate',
                placeholder: '100,000'
            }
        ]
    },
    {
        label: 'Monthly Income and Expenses',
        child: [
            {
                name: 'Mo_Rental_Income',
                label: 'Monthly Rental Income',
                type: 'number',
                commaSep: true,
                custompattern: 'decimalRate',
                placeholder: '100,000'
            },
            {
                name: 'Monthly_Maint',
                label: 'Monthly Maintainance',
                type: 'number',
                commaSep: true,
                custompattern: 'decimalRate',
                placeholder: '100,000'
            },
            { name: 'Utilities', label: 'Utilities', type: 'number', commaSep: true, custompattern: 'decimalRate', placeholder: '100,000' },
            {
                name: 'Broadband',
                label: 'Phone and Broadband',
                type: 'number',
                commaSep: true,
                custompattern: 'decimalRate',
                placeholder: '100,000'
            },
            {
                name: 'Prop_Management',
                label: 'Property Management',
                type: 'number',
                commaSep: true,
                custompattern: 'decimalRate',
                placeholder: '100,000'
            },
            { name: 'Garbage', label: 'Garbage', type: 'number', commaSep: true, custompattern: 'decimalRate', placeholder: '100,000' },
            {
                name: 'Misc_Expenses',
                label: 'Miscellaneous Expenses',
                type: 'number',
                commaSep: true,
                custompattern: 'decimalRate',
                placeholder: '100,000'
            },
            {
                name: 'Prop_Tax',
                label: 'Property Tax',
                type: 'number',
                commaSep: true,
                custompattern: 'decimalRate',
                placeholder: '100,000'
            },
            {
                name: 'Tax_Prep_Services',
                label: 'Tax Prep Services',
                type: 'number',
                commaSep: true,
                custompattern: 'decimalRate',
                placeholder: '100,000'
            }
        ]
    },
    {
        label: 'Additional Assumptions',
        child: [
            {
                name: 'Occ_Rate',
                label: 'Occupancy Rate',
                type: 'number',
                commaSep: true,
                custompattern: 'decimalRate',
                placeholder: '100,000'
            },
            {
                name: 'Appreciation',
                label: 'Annual Appreciation',
                type: 'number',
                commaSep: true,
                custompattern: 'decimalRate',
                placeholder: '100,000'
            },
            {
                name: 'Income_Tax_Rate',
                label: 'Income Tax Rate',
                type: 'number',
                commaSep: true,
                custompattern: 'decimalRate',
                placeholder: '100,000'
            }
        ]
    }
];
const PropertyInvestForm = ({ classes, propertyInvestData }) => {
    const dispatch = useDispatch();
    const {
        handleSubmit,
        control,
        formState: { isDirty, isSubmitting },
        setValue
    } = useForm({
        mode: 'onChange',
        defaultValues: {
            Purchase_Price: 1000000,
            Parking_Space: 50000,
            Closing_Costs: 10000,
            Misc_Purch_Exp: 5000,
            Monthly_Maint: 500,
            Utilities: 100,
            Broadband: 100,
            Prop_Management: 100,
            Tax_Prep_Services: 100,
            Garbage: 50,
            Misc_Expenses: 100,
            Income_Tax_Rate: 25,
            Prop_Tax: 200,
            Mo_Rental_Income: 3000,
            Occ_Rate: 75,
            Appreciation: 5
        }
    });
    //to submit the form data
    const onSubmit = (data) => {
        const objToSend = {
            Purchase_Price: data.Purchase_PriceUnFormattedValue || data.Purchase_Price,
            Parking_Space: data.Parking_SpaceUnFormattedValue || data.Parking_Space,
            Closing_Costs: data.Closing_CostsUnFormattedValue || data.Closing_Costs,
            Misc_Purch_Exp: data.Misc_Purch_ExpUnFormattedValue || data.Misc_Purch_Exp,
            Monthly_Maint: data.Monthly_MaintUnFormattedValue || data.Monthly_Maint,
            Utilities: data.UtilitiesUnFormattedValue || data.Utilities,
            Broadband: data.BroadbandUnFormattedValue || data.Broadband,
            Prop_Management: data.Prop_ManagementUnFormattedValue || data.Prop_Management,
            Tax_Prep_Services: data.Tax_Prep_ServicesUnFormattedValue || data.Tax_Prep_Services,
            Garbage: data.GarbageUnFormattedValue || data.Garbage,
            Misc_Expenses: data.Misc_ExpensesUnFormattedValue || data.Misc_Expenses,
            Income_Tax_Rate: data.Income_Tax_RateUnFormattedValue || data.Income_Tax_Rate,
            Prop_Tax: data.Prop_TaxUnFormattedValue || data.Prop_Tax,
            Mo_Rental_Income: data.Mo_Rental_IncomeUnFormattedValue || data.Mo_Rental_Income,
            Occ_Rate: data.Occ_RateUnFormattedValue || data.Occ_Rate,
            Appreciation: data.AppreciationUnFormattedValue || data.Appreciation
        };
        dispatch(submitPropertyInvest(objToSend));
    };
    // when any input field value will change it will call below method
    const handleFormInputChange = (e) => {
        if (isDirty && propertyInvestData && Object.keys(propertyInvestData).length > 0) {
            // dispatch(submitPropertyInvestSuccess({}));
        }
    };
    useEffect(() => {
        // reset output when route will change
        return () => {
            dispatch(submitPropertyInvestSuccess({}));
        };
    }, []);

    return (
        <Card className={`${classes.widgetCalCard}`} elevation={2}>
            <CardContent>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container>
                        {fieldsDetail.map((val, i) => {
                            return (
                                <Grid item xs={12} key={i + 'propertyFormFields'}>
                                    {val.child ? (
                                        <>
                                            <Grid item xs={12}>
                                                <Typography className="bold-font" mt={1.5}>
                                                    {val.label}
                                                </Typography>
                                            </Grid>

                                            <Grid container>
                                                {val.child.map((subval, subi) => (
                                                    <Grid item xs={12} sm={6} key={subi + 'propertyinnerFormFields'}>
                                                        <RenderField
                                                            handleOnChange={handleFormInputChange}
                                                            name={subval.name}
                                                            control={control}
                                                            label={subval.label}
                                                            id={subval.name}
                                                            type={subval.type}
                                                            required={subval.required || true}
                                                            thousandSeparator={subval.commaSep}
                                                            customPatternName={subval.custompattern}
                                                            placeholder={subval.placeholder}
                                                            setValue={setValue}
                                                            {...subval}
                                                        />
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </>
                                    ) : (
                                        <RenderField
                                            handleOnChange={handleFormInputChange}
                                            name={val.name}
                                            control={control}
                                            label={val.label}
                                            id={val.name}
                                            type={val.type}
                                            required={val.required || true}
                                            thousandSeparator={val.commaSep}
                                            customPatternName={val.custompattern}
                                            placeholder={val.placeholder}
                                            setValue={setValue}
                                            helperText={val.helpertext || ''}
                                            {...val}
                                        />
                                    )}
                                </Grid>
                            );
                        })}
                    </Grid>
                    <CalCardActions className="contactus-cardaction">
                        <CustomButton
                            type="submit"
                            ps={'9px 30px'}
                            variant="contained"
                            // color="rose"
                            disabled={isSubmitting}
                        >
                            Calculate
                        </CustomButton>
                    </CalCardActions>
                </form>
            </CardContent>
        </Card>
    );
};

export default PropertyInvestForm;
