import * as ActionTypes from './ActionTypes';
import { apiCall, handleError } from 'utils/Utils';
import Globals from 'Globals.js';
import { toast } from 'react-toastify';
import { string } from 'prop-types';
import {UPDATE_ECONOMIC_DASHBOARD_INIT} from "./ActionTypes";
/**
 * @author disha
 * @param {*} data
 * @use to fetch data of econ dashboard
 * @returns
 */
export const fetchEconomicDashboardData = () => async (dispatch) => {
    try {
        dispatch({ type: ActionTypes.FETCH_ECONOMIC_DASHBOARD_INIT });
        const apiResponse = await apiCall(`${Globals.API_ROOT_URL}econ_dashboard`, 'get');
        if (apiResponse !== undefined && apiResponse.status === 200) {
            dispatch(fetchEconomicDashboardSuccess(apiResponse.data.data));
        } else {
            handleError(apiResponse, dispatch);
            dispatch(fetchEconomicDashboardError());
        }
    } catch (error) {
        toast.error(Globals.errorMsg);
        dispatch(fetchEconomicDashboardError());
        throw error;
    }
};

//handle success of api
export const fetchEconomicDashboardSuccess = (val) => {
    return {
        type: ActionTypes.FETCH_ECONOMIC_DASHBOARD_SUCCESS,
        data: val
    };
};

// handle api error
export const fetchEconomicDashboardError = () => {
    return {
        type: ActionTypes.FETCH_ECONOMIC_DASHBOARD_ERROR
    };
};


/**
 * @author Ulyana
 * @param {*} data
 * @use to update data (the sortable state) of the econ dashboard
 * @returns
 */
export const updateEconomicDashboardData = (data) => async (dispatch) => {
    try {
        dispatch({ type: ActionTypes.UPDATE_ECONOMIC_DASHBOARD_INIT });
        const apiResponse = await apiCall(`${Globals.API_ROOT_URL}econ_dashboard`, 'put', data);
        if (apiResponse !== undefined && apiResponse.status === 200) {
            dispatch(updateEconomicDashboardSuccess(apiResponse.data.data));
        } else {
            handleError(apiResponse, dispatch);
            dispatch(updateEconomicDashboardError());
        }
    } catch (error) {
        toast.error(Globals.errorMsg);
        dispatch(updateEconomicDashboardError());
        throw error;
    }
};

//handle success of api
export const updateEconomicDashboardSuccess = (val) => {
    return {
        type: ActionTypes.UPDATE_ECONOMIC_DASHBOARD_SUCCESS,
        data: val
    };
};

// handle api error
export const updateEconomicDashboardError = () => {
    return {
        type: ActionTypes.UPDATE_ECONOMIC_DASHBOARD_ERROR
    };
};


// /**
//  * @author disha
//  * @param {*} data
//  * @use to fetch data of watchlist
//  * @returns
//  */
// export const fetchWatchlistData = () => async dispatch => {
//   try {
//     dispatch({
//         type: ActionTypes.FETCH_WATCHLIST_INIT
//     });
//     const apiResponse = await apiCall(
//       `${Globals.API_ROOT_URL}watchlist`,
//       "get"
//     );
//     if (apiResponse !== undefined && apiResponse.status === 200) {
//       dispatch(fetchWatchlistSuccess(apiResponse.data.data));
//     } else {
//         handleError(apiResponse,dispatch);
//       dispatch(fetchWatchlistError());
//     }
//   } catch (error) {
//     toast.error(Globals.errorMsg);
//     dispatch(fetchWatchlistError());
//     throw error;
//   }
// };
// //handle success of api
// export const fetchWatchlistSuccess = data => {
//   return {
//     type: ActionTypes.FETCH_WATCHLIST_SUCCESS,
//     data
//   };
// };

// // handle api error
// export const fetchWatchlistError = () => {
//   return {
//     type: ActionTypes.FETCH_WATCHLIST_ERROR
//   };
// };
// /**
//  * @author disha
//  * @param {*} data
//  * @use to fetch data of watchlist
//  * @returns
//  */
// export const saveWatchListData = (data,url,type='post') => async dispatch => {
//   try {
//     dispatch({
//         type: ActionTypes.SAVE_WATCHLIST_INIT
//     });
//     const apiResponse = await apiCall(
//       `${Globals.API_ROOT_URL}${url}`,type,data
//     );
//     if (apiResponse !== undefined && apiResponse.status === 200) {
//       toast.success(apiResponse.data.data.message);
//       dispatch(saveWatchListSuccess());
//       dispatch(fetchWatchlistData());
//     } else {
//         handleError(apiResponse,dispatch);
//       dispatch(fetchWatchlistError());
//     }
//   } catch (error) {
//     toast.error(Globals.errorMsg);
//     dispatch(fetchWatchlistError());
//     throw error;
//   }
// };
// //handle success of api
// export const saveWatchListSuccess = () => {
//   return {
//     type: ActionTypes.SAVE_WATCHLIST_SUCCESS,
//   };
// };
// //handle success of api
// export const handleNewDataFetched = (flag) => {
//     return {
//         type: ActionTypes.HANDLE_DATA_FETCH,
//         data:flag
//     };
// };

/**
 * @author disha
 * @param {*} data
 * @use to fetch data of charts
 * @returns
 */
export const fetchEconomicHistoryChart = (symbol, range) => async (dispatch) => {
    try {
        dispatch({
            type: ActionTypes.FETCH_ECO_HISTORY_INIT
        });
        const apiResponse = await apiCall(`${Globals.API_ROOT_URL}econ/history/chart?ticker=${symbol}&range=${range}`, 'POST');
        if (apiResponse !== undefined && apiResponse.status === 200) {
            dispatch(fetchEconomicHistoryChartSuccess(apiResponse.data.data));
        } else {
            handleError(apiResponse, dispatch);
            dispatch(fetchEconomicHistoryChartError());
        }
    } catch (error) {
        toast.error(Globals.errorMsg);
        dispatch(fetchEconomicHistoryChartError());
        throw error;
    }
};
//handle success of api
export const fetchEconomicHistoryChartSuccess = (val) => {
    return {
        type: ActionTypes.FETCH_ECO_HISTORY_SUCCESS,
        data: val
    };
};
export const fetchEconomicHistoryChartError = () => {
    return {
        type: ActionTypes.FETCH_ECO_HISTORY_ERROR
    };
};
