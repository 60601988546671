import { connect, useSelector } from 'react-redux'
import { getCookie } from 'utils/Utils';
//to check that permission isavailable in allowed permission list
const checkPermissions = (userPermissions, allowedPermissions, moduleList) => {
    if (allowedPermissions.length === 0) {
        return true;
    }
    if (userPermissions.length > 0) {
        for (let index = 0; index < userPermissions.length; index++) {
            const element = userPermissions[index];
            if (allowedPermissions.includes(element.module_unique_id)) {
                return true;
            }
        }
    }
};
// to check that passed role has authorization or not
const checkRoles = (userRoles, allowedRoles) => {
  if (allowedRoles.length === 0) {
    return true;
  }
  if (userRoles) {
    if (allowedRoles.includes(userRoles) || userRoles === 'admin') {
      return true
    }
  };
}

const AccessControl = (props) => {
  const moduleList = useSelector((state) => state.setting.moduleList);
  const { allowedPermissions, children, renderNoAccess, allowedRoles } = props;
  const permissionRoleList = props.profileData.permission || [];
  const isOfferRunning =
      Object.keys(props.profileData).length>0 ?Object.keys(props.profileData?.trial_offer).length > 0 && props.profileData.trial_offer?.trial_applied : false;
  const roleType = props.profileData.role;
  const permittedPermission = checkPermissions(permissionRoleList, allowedPermissions, moduleList);
  let planName=null;
  if(!permittedPermission){
    planName = moduleList
        .filter((val) => allowedPermissions.includes(val.module_unique_id))
        .map(function (obj) {
            return obj.subscription.name;
        });
    planName= planName.length > 0 && planName[0];
  }
  const permittedRoles = checkRoles(roleType, allowedRoles);
  const apiToken = getCookie('auth_token');
  // console.log(apiToken, 'apiToken',permittedPermission);
  // if (apiToken) {
  //     if ((permittedPermission && permittedRoles) || roleType === 'admin' || isOfferRunning) {
  //         return children;
  //     }
  // } else {
      return children;
  // }
  // return renderNoAccess(planName);
};

AccessControl.defaultProps = {
  allowedPermissions: [],
  allowedRoles: [],
  permissions: [],
  renderNoAccess: () => null,
};
function mapStateToProps(state) {
    return { profileData:state.setting.profileDetail };
}
export default connect(mapStateToProps)(AccessControl);