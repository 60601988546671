import React, { useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import styled, { css } from 'styled-components';
import { updatePamentMethod } from 'redux/actions/SettingActions';
import { useDispatch } from 'react-redux';
import { getCardInfo } from 'redux/actions/SettingActions';

export const StripeCardInput = styled(CardElement)`
    &.StripeElement {
        box-sizing: border-box;
        height: ${(props) => (props.height ? props.height : '50px')};
        border-radius: 8px;
        border: 1px solid #d0d0d0;
        padding: 12px;
        background-color: #ffffff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        transition: border-color 0.3s ease, box-shadow 0.3s ease;

        &:focus-within {
            border-color: #007bff;
            box-shadow: 0 0 8px rgba(0, 123, 255, 0.2);
        }

        &::placeholder {
            color: #a0a0a0;
        }
    }

    &.StripeElement--focus {
        border-color: #007bff;
        box-shadow: 0 0 8px rgba(0, 123, 255, 0.2);
    }

    &.StripeElement--invalid {
        border-color: #dc3545;
    }

    &.StripeElement--webkit-autofill {
        background-color: #f0f8ff !important;
    }
`;

const FormWrapper = styled.form`
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-width: 400px;
    margin: 0 auto;
    padding: 24px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

    button {
        height: 48px;
        border: none;
        border-radius: 8px;
        background-color: #007bff;
        color: white;
        font-size: 16px;
        font-weight: bold;
        cursor: pointer;
        transition: background-color 0.3s ease;

        &:disabled {
            background-color: #d0d0d0;
            cursor: not-allowed;
        }

        &:hover:not(:disabled) {
            background-color: #0056b3;
        }
    }

    .error-message {
        color: #dc3545;
        font-size: 14px;
        margin-top: -12px;
    }
`;

const EditPaymentForm = ({ id, plan_id, sub_id, handleClose }) => {
    const dispatch = useDispatch();
    const stripe = useStripe();
    const elements = useElements();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        setLoading(true);
        setError(null);

        const cardElement = elements.getElement(CardElement);

        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement
        });

        if (error) {
            setError(error.message);
            setLoading(false);
            return;
        }

        const payload = {
            id: id,
            plan_id: plan_id,
            sub_id: sub_id,
            new_payment_method_id: paymentMethod.id
        };
        await dispatch(updatePamentMethod(payload));
        handleClose();
        await dispatch(getCardInfo({ sub_id: sub_id }));
        setLoading(false);
    };

    return (
        <FormWrapper onSubmit={handleSubmit}>
            <StripeCardInput />
            {error && <div className="error-message">{error}</div>}
            <button type="submit" disabled={!stripe || loading}>
                {loading ? 'Updating...' : 'Update Payment Information'}
            </button>
        </FormWrapper>
    );
};

export default EditPaymentForm;
