const budgetPage = (theme) => ({
  summaryHeader: {
    fontWeight: 600,
    margin: "0px 0 6px 0px",
  },
  summaryBudgetGridItem: {
    // paddingLeft: "18px",
    [theme.breakpoints.down("xs")]: {
      padding: "0px 19px 0 20px",
    },
  },
  summaryCard: {
    margin: "15px 0 15px 0",
    background: "#f5f5f5",
  },
  chartDivParent:{"position":"relative","top":"auto","right":"auto","width":"auto","height":"500px","zIndex":"0"},
  pieChartWrapper: { "position":"absolute","top":"2px","bottom":"63px","width":"100%" },
  budgetDate: {
    "& input": {
      fontSize: "1rem !important",
    },
  },
  budgAccordion: {
    display:"inherit"
  },
  noteWrapper: {
    display: 'flex',
    margin:'10px 0',
    "& .noteLabel":{fontWeight:600},
    "& .noteText":{marginLeft:5},

  }
});

export default budgetPage;
