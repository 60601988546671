import React, { useEffect, useState } from "react";
import SearchIcon from '@mui/icons-material/Search';
import { Box, Divider, Grid, InputAdornment, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import Tooltip from "@mui/material/Tooltip";
import { CustomButton } from "assets/jss/ButtonStyle";
import { StyledDialogContent } from "assets/jss/GeneralStyle";
import { ViewOptionGrid, ViewOptionSearchBox } from "assets/jss/PortfolioStyle";
import { CalCardActions } from "assets/jss/RetirementStyle";
import CustomInput from "components/CustomInput/CustomInput";
import CustomDialogTitle from "components/DialogTitle";
import { CurrencyList } from "Currency.json";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import RenderField from "utils/RenderField";
import { dynamicSort, getCookie, setCookie } from "utils/Utils";
var currList = [...CurrencyList];
currList.sort(dynamicSort("code"));

let _timeout = 0;

const HomePurchViewOptions = (props) => {
  const {columns}=props;
  const [open, setOpen] = React.useState(false);
  const [columnList, setColumnList] = useState([]);
  const [searchedResult, setSearchedResult] = useState([]);
  const {handleSubmit,control,reset,setValue,formState: {  isSubmitting },} = useForm({mode: 'onChange'});
  const onSubmit = (data) => {
      var expireDate = new Date();
      expireDate.setFullYear(expireDate.getFullYear() + 5);
      setCookie('selected_columns_homepurch', JSON.stringify(data), expireDate);
handleClose();
  };
  
  useEffect(() => {
      const hideColumnList = getCookie('selected_columns_homepurch') ? JSON.parse(getCookie('selected_columns_homepurch')) : columns.slice(2);
      if (Object.keys(hideColumnList).length>0){
          for (const key in hideColumnList) {
              if (Object.hasOwnProperty.call(hideColumnList, key)) {
                  let element = hideColumnList[key];
                  let valueKEY = key;
                  if (typeof element === 'object') {
                      valueKEY = element.Header;
                      element = true;
                  }
                  setValue(valueKEY, element);
              }
          }
        }
  }, [])
  
  const handleClickOpen = () => {
    props.reRenderColumn(false);
    setOpen(true);
  }
  const handleClose = () => {
    setOpen(false);
    props.reRenderColumn(true);
  }
  
  const handleSearchOnChange = (event) => {
      const inputValue = event.target.value;
      let filteredData = [];
      //call search logic after typing is stopped
      if (_timeout) clearTimeout(_timeout);
      _timeout = setTimeout(() => {
          columns.slice(2).forEach((value) => {
              const rowData = value.Header.toString().toLowerCase().includes(inputValue?.toLowerCase());
              if (rowData) {
                  filteredData.push(value);
              }
            });
            setSearchedResult(filteredData)
      }, 1000);
  };
  useEffect(() => {
     setColumnList(searchedResult);
  }, [searchedResult]);
  useEffect(() => {
      setColumnList(columns.slice(2));
  }, [columns]);
  const handleResetColumn=()=>{
    const resetObj = columns.slice(2).reduce((acc, cur) => ({ ...acc, [cur.Header]: '' }), {});
    reset(resetObj || {});
  }
  return (
      <Box>
          <Tooltip title="Click here to customize your view" arrow>
              <CustomButton style={{width:'100%'}} variant="text" aria-label="view options" onClick={handleClickOpen}>
                  View Options
              </CustomButton>
          </Tooltip>
          <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
              <CustomDialogTitle id="form-dialog-purch-title" onClose={handleClose}>
                  Select Viewing Options
              </CustomDialogTitle>
              <Divider />
              <StyledDialogContent>
                  <ViewOptionSearchBox>
                      <CustomInput
                          id="tablecolsearch"
                          type="search"
                          labelText=""
                          variant="outlined"
                          placeholder="Search"
                          onChange={(e) => handleSearchOnChange(e)}
                          CustomInputProps={{
                              startAdornment: (
                                  <InputAdornment position="start">
                                      <SearchIcon />
                                  </InputAdornment>
                              )
                          }}
                      />
                  </ViewOptionSearchBox>
                  <form onSubmit={handleSubmit(onSubmit)}>
                      {columnList.length ? (
                          <Grid container>
                              {columnList.map((val, i) => {
                                  return (
                                      <ViewOptionGrid item xs={12} sm={6} key={val.Header + '_ViewOptionscheckboxes'}>
                                          <RenderField
                                              control={control}
                                              id={val.Header}
                                              type="checkbox"
                                              name={val.Header}
                                              label={val.Header}
                                              hideLabel={false}
                                          />{' '}
                                          <Typography variant="body1" className="checkbox-label">
                                              {val.Header}
                                          </Typography>
                                      </ViewOptionGrid>
                                  );
                              })}
                          </Grid>
                      ) : (
                          'No column(s) found.'
                      )}
                      <CalCardActions className="contactus-cardaction">
                          <Grid container direction="row" style={{ width: '100%', textAlign: 'center' }}>
                              <Grid item xs>
                                  <CustomButton
                                      type="button"
                                      variant="contained"
                                      color="secondary"
                                      onClick={handleResetColumn}
                                      className="action-btn-viewoption"
                                      disabled={isSubmitting}
                                  >
                                      Reset
                                  </CustomButton>
                              </Grid>
                              <Grid item xs>
                                  <CustomButton type="submit" variant="contained" disabled={isSubmitting} className="action-btn-viewoption">
                                      Apply
                                  </CustomButton>
                              </Grid>
                          </Grid>
                      </CalCardActions>
                  </form>
              </StyledDialogContent>
          </Dialog>
      </Box>
  );
};
export default HomePurchViewOptions;
