import * as ActionTypes from '../actions/ActionTypes';

const initialState = {
    tipsCalData: [],
    compoundCalData: [],
    inflationCalData: [],
    budgetCalData: { loader: false, calData: {} },
    propertyInvestData: { loader: false, calData: {} },
    lifeInsData: { loader: false, calData: {} },
    loader: false,
    displaySigninDialog: false,
    submitContactUsSuccess: false,
    defaultoverrideData: {},
    annuityData: { loader: false, calData: {} },
    bloglist: []
};

export const GeneralCalReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.SUBMIT_INFLATION_CAL_INIT:
        case ActionTypes.SUBMIT_TIPS_CAL_INIT:
        case ActionTypes.SUBMIT_COMPOUND_CAL_INIT:
        case ActionTypes.SUBSCRIBE_PUSH_NOTI_INIT:
        case ActionTypes.SUBMIT_DEFAULT_OVERRIDE_INIT:
        case ActionTypes.SUBMIT_FEEDBACK_INIT:
        case ActionTypes.SUBMIT_CONTACTUS_INIT:
            return { ...state, loader: true };
        case ActionTypes.SUBMIT_TIPS_CAL_SUCCESS:
            return { ...state, loader: false, tipsCalData: action.data };
        case ActionTypes.SUBMIT_COMPOUND_CAL_SUCCESS:
            return { ...state, loader: false, compoundCalData: action.data };
        case ActionTypes.SUBMIT_INFLATION_CAL_SUCCESS:
            let outputArr = [];
            if (action.data.length) {
                let chartData = [];
                action.data.forEach((element) => {
                    if (typeof element.value === 'object') {
                        const keyName = element.label === 'Interest' ? 'label' : 'id';
                        chartData.push({
                            [keyName]: element.label,
                            data: element.value.map((val, i) => {
                                return element.label === 'Interest'
                                    ? { label: element.time[i], Interest: val }
                                    : { x: element.time[i], y: val };
                            })
                        });
                    } else {
                        outputArr.push(element);
                    }
                });
                outputArr.push({ label: 'chart', value: chartData });
            }
            return { ...state, loader: false, inflationCalData: outputArr };
        case ActionTypes.SUBMIT_INFLATION_CAL_ERROR:
        case ActionTypes.SUBMIT_TIPS_CAL_ERROR:
        case ActionTypes.SUBMIT_COMPOUND_CAL_ERROR:
        case ActionTypes.SUBSCRIBE_PUSH_NOTI_SUCCESS:
        case ActionTypes.SUBSCRIBE_PUSH_NOTI_ERROR:
        case ActionTypes.SUBMIT_FEEDBACK_SUCCESS:
        case ActionTypes.SUBMIT_FEEDBACK_ERROR:
        case ActionTypes.SUBMIT_DEFAULT_OVERRIDE_SUCCESS:
        case ActionTypes.SUBMIT_DEFAULT_OVERRIDE_ERROR:
            return { ...state, loader: false };
        case ActionTypes.SUBMIT_BUDGET_CAL_INIT:
            return { ...state, budgetCalData: { loader: true } };
        case ActionTypes.SUBMIT_BUDGET_CAL_SUCCESS:
            return {
                ...state,
                budgetCalData: { loader: false, calData: action.data }
            };
        case ActionTypes.SUBMIT_BUDGET_CAL_ERROR:
            return {
                ...state,
                budgetCalData: { ...state.budgetCalData, loader: false }
            };
        case ActionTypes.SUBMIT_PROPERTY_INVEST_INIT:
            return { ...state, propertyInvestData: { loader: true } };
        case ActionTypes.SUBMIT_PROPERTY_INVEST_SUCCESS:
            return {
                ...state,
                propertyInvestData: { loader: false, calData: action.data }
            };
        case ActionTypes.SUBMIT_PROPERTY_INVEST_ERROR:
            return {
                ...state,
                propertyInvestData: { ...state.propertyInvestData, loader: false }
            };
        case ActionTypes.SUBMIT_LIFE_INS_INIT:
            return { ...state, lifeInsData: { loader: true } };
        case ActionTypes.SUBMIT_LIFE_INS_SUCCESS:
            return { ...state, lifeInsData: { loader: false, calData: action.data } };
        case ActionTypes.SUBMIT_LIFE_INS_ERROR:
            return { ...state, lifeInsData: { ...state.lifeInsData, loader: false } };

        case ActionTypes.SIGNIN_REQUIRED_ACTION:
            return { ...state, displaySigninDialog: action.data };
        case ActionTypes.SUBMIT_CONTACTUS_SUCCESS:
            return { ...state, loader: false, submitContactUsSuccess: true };
        case ActionTypes.SUBMIT_CONTACTUS_ERROR:
            return { ...state, loader: false, submitContactUsSuccess: false };
        case ActionTypes.GET_DEFAULT_OVERRIDE_SUCCESS:
            return { ...state, loader: false, defaultoverrideData: action.data };
        case ActionTypes.GET_DEFAULT_OVERRIDE_ERROR:
        case ActionTypes.SUBMIT_NEWSLETTER_ERROR:
        case ActionTypes.GET_BLOG_LIST_ERROR:
        case ActionTypes.SUBMIT_NEWSLETTER_SUCCESS:
            return { ...state, loader: false };
        case ActionTypes.GET_DEFAULT_OVERRIDE_INIT:
        case ActionTypes.SUBMIT_NEWSLETTER_INIT:
        case ActionTypes.GET_BLOG_LIST_INIT:
            return { ...state, loader: false };
        case ActionTypes.SUBMIT_ANNUITY_CAL_SUCCESS:
            return { ...state, annuityData: { loader: false, calData: action.data } };
        case ActionTypes.SUBMIT_ANNUITY_CAL_ERROR:
            return { ...state, annuityData: { loader: false } };
        case ActionTypes.SUBMIT_ANNUITY_CAL_INIT:
            return { ...state, annuityData: { loader: true } };
        case ActionTypes.GET_BLOG_LIST_SUCCESS:
            return { ...state, loader: false, bloglist: action.data };
        default:
            return state;
    }
};
