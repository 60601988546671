import AddIcon from '@mui/icons-material/Add';
import { Box, Divider, Grid } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import Tooltip from '@mui/material/Tooltip';
import { CustomButton } from 'assets/jss/ButtonStyle';
import { CalCardActions } from 'assets/jss/RetirementStyle';
import CustomDialogTitle from 'components/DialogTitle';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import RenderField from 'utils/RenderField';
import { StyledDialogContent } from 'assets/jss/GeneralStyle';
import { Input, Chip, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { submitCreditCardCal } from 'redux/actions/CreditCardCalActions';
import { toast } from 'react-toastify';
import CustomTooltip from 'components/CommonComponent/CustomTooltip';
import InputAdornment from '@mui/material/InputAdornment';

const AddNewCreditCard = ({ editData, setEnableEdit, id }) => {
    const len = Object.keys(Object(editData)).length;
    const [open, setOpen] = React.useState(false);
    const [resetDropzoneValue, setResetDropzoneValue] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [chips, setChips] = useState(editData?.Multiplier || []);
    const [fieldsDetail, setFieldsDetail] = useState([
        {
            name: 'balance',
            label: 'Balance',
            type: 'number',
            commaSep: true,
            custompattern: 'decimalRate',
            placeholder: '10,000',
            // maxLength: 3,
            required: true
        },
        {
            name: 'interest_rate',
            label: 'Interest Rate',
            type: 'number',
            commaSep: false,
            custompattern: 'decimalRate',
            placeholder: '10',
            // maxLength: 3,
            required: true
        },

        {
            name: 'payment_type',
            label: 'Payment Type',
            required: true,
            type: 'select',
            options: [
                { label: 'USD', value: 'USD' },
                { value: 'PCT', label: 'PCT' }
            ]
        },
        {
            name: 'monthly_payment',
            label: 'Monthly Payment(USD/PCT)',
            type: 'number',
            commaSep: true,
            custompattern: 'decimalRate',
            placeholder: '10,000',
            // maxLength: 3,
            required: true
        }
    ]);

    /**
     * get the selected file from dropzone
     * @param {*} fileList
     */

    const dispatch = useDispatch();
    const {
        handleSubmit,
        control,
        formState: { isSubmitting },
        setValue,
        reset
    } = useForm({
        mode: 'onChange'
    });

    const handleClickOpen = () => {
        setOpen(true);
    };
    /**
     * save selected file with validating data
     * @param {*} e
     */

    const handleClose = () => {
        reset({});
        setOpen(false);
        setChips([]);
        if (len) {
            setEnableEdit({});
        }
    };
    //to submit the form data
    const onSubmit = (data) => {
        let objToSend = {
            balance: parseFloat(data.balanceUnFormattedValue || data.balance),
            interest_rate: parseFloat(data.interest_rate),
            payment_type: data.payment_type,
            monthly_payment: parseFloat(data.monthly_paymentUnFormattedValue || data.monthly_payment)
        };
        if (chips.length) {
            objToSend = {
                ...objToSend,
                scenario_mult: chips.join(', ')
            };
        }
        if (objToSend.payment_type === 'PCT' && objToSend.monthly_payment > 100) {
            return toast.error('Monthly payment should not be greater then 100 if payment type is PCT.');
        }
        if (len) {
            objToSend = {
                id,
                ...objToSend
            };
            const apiToken = localStorage.getItem('auth_token');
            if (!apiToken) {
                dispatch(submitCreditCardCal(objToSend, 'post'));
            } else {
                dispatch(submitCreditCardCal(objToSend, 'put'));
            }
        } else {
            dispatch(submitCreditCardCal(objToSend, 'post'));
        }
        handleClose();
    };

    useEffect(() => {
        return () => {
            setResetDropzoneValue(!resetDropzoneValue);
        };
    }, []);

    useEffect(() => {
        if (len) {
            setValue('balance', editData.balance);
            setValue('interest_rate', editData['interest Rate']);
            setValue('monthly_payment', editData.monthly_payment);
            setValue('payment_type', editData.payment_type);
        }
    }, [len]);

    const handleInputChange = (event) => {
        const { value } = event.target;
        if (/^\d*\.?\d*$/.test(value) || value === '') {
            setInputValue(value);
        }
    };

    const handleAddChip = () => {
        if (/^\d+(\.\d+)?$/.test(inputValue.trim())) {
            const numericValue = parseFloat(inputValue.trim());
            if (numericValue < 100 && numericValue >= 0 && !chips.includes(inputValue.trim())) {
                setChips([...chips, inputValue.trim()]);
                setInputValue('');
            }
        }
    };

    const handleDeleteChip = (index) => {
        const updatedChips = [...chips];
        updatedChips.splice(index, 1);
        setChips(updatedChips);
    };

    return (
        <Box>
            {len === 0 && (
                <Tooltip title="Add" arrow>
                    <CustomButton variant="text" startIcon={<AddIcon />} aria-label="add" onClick={handleClickOpen} id="tab-6">
                        Add New Data
                    </CustomButton>
                </Tooltip>
            )}
            <Dialog open={len || open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <CustomDialogTitle id="form-dialog-title" onClose={handleClose}>
                    {len ? 'Edit' : 'Add'} Credit Card
                </CustomDialogTitle>
                <Divider />
                <StyledDialogContent>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container>
                            {fieldsDetail.map((val, i) => {
                                return (
                                    <Grid item xs={12} sm={6} key={i + 'CreditCardFormFields'}>
                                        <RenderField
                                            name={val.name}
                                            disableEmptyValue={true}
                                            control={control}
                                            label={val.label}
                                            id={val.name}
                                            type={val.type}
                                            options={val.options}
                                            required={val.required || true}
                                            thousandSeparator={val.commaSep}
                                            customPatternName={val.custompattern}
                                            placeholder={val.placeholder}
                                            setValue={setValue}
                                            {...val}
                                        />
                                    </Grid>
                                );
                            })}
                        </Grid>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginBottom: '10px',
                                marginTop: '14px',
                                marginLeft: '8px'
                            }}
                        >
                            <Input
                                value={inputValue}
                                onChange={handleInputChange}
                                placeholder="Scenario multiplier"
                                inputProps={{
                                    pattern: '^\\d*\\.?\\d*$',
                                    title: 'Please enter only numbers or float numbers'
                                }}
                            />

                            <CustomTooltip tooltipText={'These multipliers apply to the monthly payments.'} />

                            <IconButton
                                onClick={handleAddChip}
                                style={{ backgroundColor: 'deepskyblue', borderRadius: '50%', color: 'white', marginLeft: '17px' }}
                            >
                                <AddIcon />
                            </IconButton>
                        </div>
                        {chips.map((chip, index) => (
                            <Chip
                                key={index}
                                label={chip}
                                onDelete={() => handleDeleteChip(index)}
                                style={{ margin: '5px' }}
                                deleteIcon={<CloseIcon />}
                            />
                        ))}
                        <CalCardActions className="contactus-cardaction">
                            <Grid container direction="row" style={{ width: '100%', textAlign: 'center' }}>
                                <Grid item xs={12}>
                                    <CustomButton type="submit" variant="contained" disabled={isSubmitting}>
                                        Submit
                                    </CustomButton>
                                </Grid>
                            </Grid>
                        </CalCardActions>
                    </form>
                </StyledDialogContent>
            </Dialog>
        </Box>
    );
};
export default AddNewCreditCard;
