import React from 'react';
import moment from 'moment';
import parse from 'html-react-parser';
import showdown from "showdown";

const ChatBubble = ({ chatData, classes }) => {
    return chatData.map((chat, i) => {
        let msgClass = '',
            displayName = '',
            bgColor = '';
        if (chat.type === 'robo') {
            msgClass = classes.messageRow;
            displayName = 'Finbot';
            bgColor = classes.messageBlue;
        } else {
            msgClass = classes.messageRowRight;
            displayName = 'You';
            bgColor = classes.messageOrange;
        }
        const date = moment.unix(chat.date).format('h:mm a');
        const showdown = require('showdown');
        const converter = new showdown.Converter();
        return (
            <div className={msgClass} key={chat.date + 'chatBotMsgWrapper' + i}>
                <div style={{ width: '80%' }}>
                    <div className={classes.displayName}>{displayName}</div>
                    <div className={bgColor}>
                        {Array.isArray(chat.msg) ? (
                            chat.msg.map((msgVal, mi) => {
                                return (
                                    <div key={mi + 'roboInnerMessages'}>
                                        <p className={classes.messageContent}>{parse(msgVal)}</p>
                                    </div>
                                );
                            })
                        ) : (
                            <div>
                                <p className={classes.messageContent}>{parse(converter.makeHtml(chat.msg))}</p>
                            </div>
                        )}
                        <div className={classes.messageTimeStampRight}>{date}</div>
                    </div>
                </div>
            </div>
        );
    });
};
export default ChatBubble;
