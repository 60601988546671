import { Box, Chip, Grid, IconButton, InputAdornment, Tooltip, Typography } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CloseIcon from '@mui/icons-material/Close';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import DoneIcon from '@mui/icons-material/Done';
import { Rating } from '@mui/material';
import EnhancedTable from 'components/CommonComponent/CommonTable/EnhancedTable';
import CustomInput from 'components/CustomInput/CustomInput';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { resetEditTableField } from 'redux/actions/DebtCalActions';
import { submitHomePurchSmartTable, submitHomePurchSmartTableSuccess } from 'redux/actions/HomeCalActions';
import { submitProfile } from 'redux/actions/SettingActions';
import { StateList } from 'States.json';
import RenderField from 'utils/RenderField';
import { numberWithCommas } from 'utils/Utils';
import { AnnualInputWrapperGrid } from 'assets/jss/CalculatorStyle';
import { RatingBox } from 'assets/jss/GeneralStyle';
import { getCookie } from 'utils/Utils';
import RestoreIcon from '@mui/icons-material/Restore';
import CustomTooltip from 'components/CommonComponent/CustomTooltip';
import * as tooltip from 'Tooltip.json';
import { limitWords } from 'utils/Utils';
import Link from '@mui/material/Link';
import styles from 'assets/jss/material-kit-react/components/customInputStyle.js';
import { makeStyles } from '@mui/styles';
import { HomePurchaseViewPopup } from './HomePurchaseViewPop';

const TooltipForEditIcon = ({ text, children }) => {
    const [showTooltip, setShowTooltip] = useState(false);

    const handleMouseEnter = () => {
        setShowTooltip(true);
    };

    const handleMouseLeave = () => {
        setShowTooltip(false);
    };

    return (
        <div
            style={{
                position: 'relative',
                display: 'inline-block'
            }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            {children}
            {showTooltip && (
                <div
                    style={{
                        position: 'absolute',
                        top: '130%',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        backgroundColor: '#A9A9A9',
                        color: '#fff',
                        padding: '3px 10px 3px 10px',
                        borderRadius: '4px',
                        whiteSpace: 'nowrap',
                        zIndex: 1,
                        display: 'block',
                        fontSize: '11px'
                    }}
                >
                    {text}
                </div>
            )}
        </div>
    );
};

const useStyles = makeStyles(styles);

const handleStateOptions = () => {
    const list = StateList.map((val) => {
        return {
            value: val.abb,
            label: val.name + ` (${val.abb})`
        };
    });
    return list;
};
export const includes = (rows, ids, filterValue) => {
    return rows.filter((row) => {
        return ids.some((id) => {
            const rowValue = row.values[id] || '-';
            return filterValue === 'all' ? true : rowValue ? rowValue.includes(filterValue) : rowValue === filterValue;
        });
    });
};
const numberOptions = (limit = 10, appendPlus = false) => {
    return [...Array(limit)].map((e, i) => {
        let val = ++i;
        const value = val;
        if (appendPlus && val === limit) {
            val = val + '+';
        }
        return { value: value, label: val };
    });
};
let renderOnce = false;
export const HomePurchaseTable = (props) => {
    const classes = useStyles();

    const { setInputValue, setRowValue, homecal, dispatch, profile, isSharedData, tableData } = props;
    const [enableEdit, setEnableEdit] = useState({});
    const container = React.useRef(null);
    const [updatedVal, setUpdatedVal] = useState({});
    const [assumptionVal, setAssuptionVal] = useState({});
    const [enableEditAssumption, setEnableEditAssumption] = useState({});
    const [viewData, setOpenViewDialog] = useState(false);
    const [fundData, setFundData] = useState({});
    // const [setRow, setRowValue] = useState('');
    const requiredFields = ['PrinAmount', 'Maturity Years', 'Repay Type', 'Interest Rate', 'Debt Type'];
    const { handleSubmit, control, setValue, reset } = useForm({ mode: 'onChange' });
    useEffect(() => {
        if (columns.length > 0 && renderOnce === false) {
            renderOnce = true;
            props.tableColumns(columns);
        }
        return () => {
            renderOnce = false;
        };
    }, []);

    const handleInput = (row) => {
        // const newValue = event.target.value;
        setInputValue(true);
        setRowValue(row.original);
    };

    const selfData =
        Object.keys(homecal.homePurchaseCollDetails).length > 0
            ? (homecal.homePurchaseCollDetails.collaborated_by && homecal.homePurchaseCollDetails.collaborated_by) || ''
            : '';
    const collData =
        Object.keys(homecal.homePurchaseCollDetails).length > 0
            ? (homecal.homePurchaseCollDetails?.collaborator && homecal.homePurchaseCollDetails.collaborator) || ''
            : '';

    // submit smart table edited data
    const onSubmit = (data) => {
        if (enableEdit.id) {
            const objToPass = {
                sold_listed_date: (data.sold_listed_date && moment(data.sold_listed_date, 'MM/DD/YYYY').format('YYYY/MM/DD')) || null,
                view_date: (data.view_date && moment(data.view_date, 'MM/DD/YYYY').format('YYYY/MM/DD')) || null,
                address: data.address,
                favorites: data.favorites || false,
                state: data.state?.value,
                zipcode: data.zipcode,
                price: data.priceUnFormattedValue || data.price.replace(/,/g, ''),
                size: Math.round(data.sizeUnFormattedValue) || Math.round(data?.size.replace(/,/g, '')),
                listing_status: data.listing_status,
                beds: data.beds || undefined,
                baths: data.baths || undefined,
                year: data.year,
                floor: data.floor || undefined,
                comments: data.comments,
                fees_tax_insu_monthly: data.fees_tax_insu_monthlyUnFormattedValue || data.fees_tax_insu_monthly.replace(/,/g, ''),
                condition: data.condition,
                view: data.view,
                location: data.location,
                viewing_status: data.viewing_status || undefined,
                id: enableEdit.id,
                parking: data.parking,
                amenities: data.amenities.join(','),
                property_link: data.property_link,
                agent_name: data.agent_name,
                neighbour_hood: data.neighbour_hood,
                broker: data.broker,
                property_id: data.property_id
            };
            dispatch(submitHomePurchSmartTable(objToPass, 'put'));
        }
    };

    const handleClose = () => {
        setFundData({});
        setOpenViewDialog(false);
    };

    useEffect(() => {
        // reset all state flag whenever form is submitted
        if (props.homecal?.submitSuccess) {
            reset();
            setEnableEdit({});
            setUpdatedVal({});
            props.setInputRenderList(true);
            dispatch(submitHomePurchSmartTableSuccess(false));
        }
    }, [props.homecal?.submitSuccess]);
    useEffect(() => {
        // reset all state flag whenever form is submitted
        if (props.disableEditTblField) {
            reset();
            setEnableEdit({});
            setUpdatedVal({});
            dispatch(resetEditTableField(false));
        }
    }, [props.disableEditTblField]);
    // Create an editable cell renderer
    const EditableCell = (initialValue, type, row, cell, options = [], extra = { commaSep: true, chipSelect: false }) => {
        let valToPass = initialValue || '',
            custompattern = '';
        // We need to keep and update the state of the cell normally
        let value = updatedVal;
        switch (type) {
            case 'number':
                custompattern = 'decimalRate';
                valToPass = extra.commaSep
                    ? !isNaN(initialValue) && initialValue?.toString().indexOf('.') !== -1
                        ? numberWithCommas(initialValue?.toFixed(2))
                        : numberWithCommas(initialValue)
                    : initialValue;
                break;
            case 'select':
            case 'searchselect':
                if (extra.chipSelect) {
                    break;
                }
                valToPass = initialValue?.toString().toLowerCase();
                break;
            default:
                custompattern = 'validString';
                break;
        }
        valToPass = valToPass || '';
        if (value[cell.column.id] !== undefined && value[cell.column.id] !== null) {
            valToPass = value[cell.column.id];
        }
        if (type === 'datetime') {
            valToPass = (valToPass && moment(valToPass, 'YYYY/MM/DD').format('MM/DD/YYYY')) || null;
        }
        const id = [row.original.id, cell.column.id].join('_');
        return (
            <RenderField
                label={''}
                id={id}
                defaultValue={valToPass}
                name={cell.column.id}
                type={type}
                fieldName={cell.column.Header || cell.column.id}
                options={options}
                control={control}
                precision={1}
                required={requiredFields.includes(cell.column.Header)}
                thousandSeparator={extra?.commaSep || false}
                customPatternName={extra?.custompattern || custompattern}
                setValue={setValue}
                chipSelect={extra.chipSelect}
                renderOption={(props, option) => {
                    return (
                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                            <Typography variant="body2">{option.label}</Typography>
                        </Box>
                    );
                }}
                {...extra}
            />
        );
    };
    // when rating star will click from table then to submit its value
    const handleRatingChange = (value, id) => {
        dispatch(submitHomePurchSmartTable({ favorites: value === 1 ? true : false, id }, 'put'));
    };

    const sortDate = (rowA, rowB, id, desc) => {
        const a = moment(rowA.values[id]);
        const b = moment(rowB.values[id]);

        if (a > b) return 1;
        if (a < b) return -1;
        return 0;
    };

    // delete smart table data
    const deleteSelectedData = (ids) => dispatch(submitHomePurchSmartTable([ids], 'delete'));
    const hideColumnList = getCookie('selected_columns_homepurch') ? JSON.parse(getCookie('selected_columns_homepurch')) : {};
    const columns = useMemo(() => {
        const emptySelectionOfColumn = Object.keys(hideColumnList).length === 0;
        const list1 = [
            {
                Header: () => null,
                accessor: 'id',
                isVisible: false
            }
        ];
        const list2 = [
            {
                Header: 'Action',
                Cell: ({ row }) => {
                    if (enableEdit.id === row.original.id) {
                        return (
                            <div className={'actionWrapper'}>
                                <Tooltip title="Cancel">
                                    <IconButton
                                        aria-label="cancel"
                                        onClick={(e) => {
                                            reset();
                                            setEnableEdit({});
                                        }}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Save">
                                    <IconButton type="submit" aria-label="save">
                                        <DoneIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        );
                    } else {
                        return (
                            <div className={'actionWrapper'}>
                                <Tooltip title="view">
                                    <IconButton
                                        aria-label="view"
                                        onClick={() => {
                                            setFundData(row.original);
                                            setOpenViewDialog(true);
                                        }}
                                    >
                                        <VisibilityIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Delete">
                                    <IconButton aria-label="delete" onClick={() => deleteSelectedData(row.original.id)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Tooltip>
                                {/* <Tooltip title="Edit"> */}
                                <TooltipForEditIcon text="Edit">
                                    <IconButton
                                        aria-label="edit"
                                        onClick={() => {
                                            // reset();
                                            // setUpdatedVal({});
                                            // setEnableEdit(row.original);
                                            handleInput(row);
                                            // handleChange()
                                        }}
                                    >
                                        <CreateIcon />
                                    </IconButton>
                                </TooltipForEditIcon>
                                {/* </Tooltip> */}
                            </div>
                        );
                        // }
                    }
                }
            },
            {
                Header: 'favorites',
                width: 150,
                isVisible: hideColumnList['favorites'] === true || emptySelectionOfColumn || false,
                accessor: 'favorites',
                disableFilters: true,
                Cell: ({ value, row, cell }) => (
                    <RatingBox>
                        {enableEdit.id === row.original.id
                            ? EditableCell(value, 'rating', row, cell, [], { size: 'large', maxStar: 1 })
                            : (
                                  <Rating
                                      key={'favorites' + row.original.id}
                                      name={'favorites' + row.original.id}
                                      onChange={(event, newValue) => handleRatingChange(newValue, row.original.id)}
                                      value={value}
                                      precision={1}
                                      size="large"
                                      max={1}
                                  />
                              ) || '-'}
                    </RatingBox>
                )
            }
        ];
        const list3 = [
            {
                Header: 'Date (Sold/Listed)',
                accessor: 'sold_listed_date',
                isVisible: hideColumnList['Date (Sold/Listed)'] === true || emptySelectionOfColumn || false,
                disableFilters: true,
                sortType: sortDate,
                Cell: ({ value, row, cell }) =>
                    enableEdit.id === row.original.id
                        ? EditableCell(value, 'datetime', row, cell, [], { timeFormat: false })
                        : (value && moment(value, 'YYYY/MM/DD').format('MM/DD/YYYY')) || '-'
            },
            {
                Header: 'View Date',
                accessor: 'view_date',
                isVisible: hideColumnList['View Date'] === true || emptySelectionOfColumn || false,
                disableFilters: true,
                sortType: sortDate,
                Cell: ({ value, row, cell }) =>
                    enableEdit.id === row.original.id
                        ? EditableCell(value, 'datetime', row, cell, [], { timeFormat: false })
                        : (value && moment(value, 'YYYY/MM/DD').format('MM/DD/YYYY')) || '-'
            },
            {
                Header: 'Created Date',
                accessor: 'created_at',
                disableFilters: true,
                sortType: sortDate,
                Cell: ({ value }) => moment(value, 'YYYY-MM-DD HH:mm:ss').format('MM/DD/YYYY') || '-'
            },
            {
                Header: 'Address',
                accessor: 'address',
                isVisible: hideColumnList['Address'] === true || emptySelectionOfColumn || false,
                disableFilters: true,
                width: 200,
                Cell: ({ value, row, cell }) =>
                    enableEdit.id === row.original.id
                        ? EditableCell(value, 'text', row, cell, [], {
                              multiline: true,
                              maxRows: 4,
                              maxLength: 150,
                              custompattern: /^([a-z0-9A-Z,-_@~`&# ]{0,150})$/g
                          })
                        : value || '-'
            },
            {
                Header: 'State',
                accessor: 'state',
                isVisible: hideColumnList['State'] === true || emptySelectionOfColumn || false,
                disableFilters: true,
                width: 200,
                Cell: ({ value, row, cell }) => {
                    const option = handleStateOptions();
                    return enableEdit.id === row.original.id
                        ? EditableCell(value, 'searchSelect', row, cell, option)
                        : option.filter((val) => {
                              // Check if val and value are both strings before calling toLowerCase()
                              if (typeof val?.value === 'string' && typeof value === 'string') {
                                  return val?.value?.toLowerCase() === value.toLowerCase();
                              }
                              return false; // Return false if either val or value is not a string
                          })[0]?.label || '-';
                }
            },
            {
                Header: 'Zipcode',
                accessor: 'zipcode',
                isVisible: hideColumnList['Zipcode'] === true || emptySelectionOfColumn || false,
                disableFilters: true,
                width: 200,
                Cell: ({ value, row, cell }) =>
                    enableEdit.id === row.original.id
                        ? EditableCell(value, 'number', row, cell, [], { commaSep: false, custompattern: /^[0-9]{0,6}$/g, maxLength: 6 })
                        : value || '-'
            },
            {
                Header: 'Amenities',
                accessor: 'amenities',
                isVisible: hideColumnList['Amenities'] === true || emptySelectionOfColumn || false,
                disableFilters: true,
                width: 300,
                Cell: ({ value, row, cell }) => {
                    const option = {
                        ['Balcony']: 'Balcony',
                        ['Pool']: 'Pool',
                        ['Washer/Dryer']: 'Washer/Dryer',
                        ['Garden/Outdoor space']: 'Garden/Outdoor space'
                    };
                    const amenities = value !== '' ? value?.split(',') || [] : [];
                    return enableEdit.id === row.original.id ? (
                        EditableCell(amenities, 'select', row, cell, option, { chipSelect: true })
                    ) : (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {amenities.map((value, i) => {
                                return <Chip key={i + '_ChipSelect'} label={option[value]} className={classes.chip} />;
                            })}
                        </Box>
                    );
                }
            },
            {
                Header: 'Parking',
                accessor: 'parking',
                isVisible: hideColumnList['Parking'] === true || emptySelectionOfColumn || false,
                disableFilters: true,
                width: 200,
                Cell: ({ value, row, cell }) => {
                    const option = [
                        { value: '0', label: '0' },
                        { value: '1', label: '1' },
                        { value: '2', label: '2' }
                    ];
                    return enableEdit.id === row.original.id
                        ? EditableCell(value, 'select', row, cell, option)
                        : option.filter((val) => val.value).label || value
                        ? value
                        : '-';
                }
            },
            {
                Header: 'Price per sq foot ranking',
                accessor: 'per_square_foot_ranking',
                isVisible: hideColumnList['Price per sq foot ranking'] === true || emptySelectionOfColumn || false,
                disableFilters: true,
                width: 200
            },
            {
                Header: 'Buyer Score ranking',
                accessor: 'buyer_score_ranking',
                isVisible: hideColumnList['Buyer Score ranking'] === true || emptySelectionOfColumn || false,
                disableFilters: true,
                width: 200
            },
            {
                Header: 'Property Link',
                accessor: 'property_link',
                isVisible: hideColumnList['Property Link'] === true || emptySelectionOfColumn || false,
                disableFilters: true,
                width: 200,
                Cell: ({ value, row, cell }) =>
                    enableEdit.id === row.original.id ? (
                        EditableCell(value, 'text', row, cell, [], {
                            multiline: true,
                            maxRows: 4,
                            maxLength: 150,
                            custompattern: /^(https?:\/\/)?([\w-]+\.)+[\w-]+(\/[\w-./?%&=]*)?$/g
                        })
                    ) : value ? (
                        <Link href={value} color="primary" underline="hover" target="_blank">
                            url
                        </Link>
                    ) : (
                        '-'
                    )
            },
            {
                Header: 'Property ID',
                accessor: 'property_id',
                isVisible: hideColumnList['Property ID'] === true || emptySelectionOfColumn || false,
                disableFilters: true,
                width: 200,
                Cell: ({ value, row, cell }) =>
                    enableEdit.id === row.original.id
                        ? EditableCell(value, 'text', row, cell, [], {
                              multiline: true,
                              maxRows: 4,
                              maxLength: 150,
                              custompattern: /^([a-z0-9A-Z,-_@~`&# ]{0,150})$/g
                          })
                        : value || '-'
            },
            {
                Header: 'Broker',
                accessor: 'broker',
                isVisible: hideColumnList['Broker'] === true || emptySelectionOfColumn || false,
                disableFilters: true,
                width: 200,
                Cell: ({ value, row, cell }) =>
                    enableEdit.id === row.original.id
                        ? EditableCell(value, 'text', row, cell, [], {
                              multiline: true,
                              maxRows: 4,
                              maxLength: 150,
                              custompattern: /^([a-z0-9A-Z,-_@~`&# ]{0,150})$/g
                          })
                        : value || '-'
            },
            {
                Header: 'TPE Property ID',
                accessor: 'tpe_property_id',
                isVisible: hideColumnList['TPE Property ID'] === true || emptySelectionOfColumn || false,
                disableFilters: true,
                width: 200,
                Cell: ({ value, row, cell }) => value || '-'
            },
            {
                Header: 'Neighbourhood',
                accessor: 'neighbour_hood',
                isVisible: hideColumnList['Neighbourhood'] === true || emptySelectionOfColumn || false,
                disableFilters: true,
                width: 200,
                Cell: ({ value, row, cell }) =>
                    enableEdit.id === row.original.id
                        ? EditableCell(value, 'text', row, cell, [], {
                              multiline: true,
                              maxRows: 4,
                              maxLength: 150,
                              custompattern: /^[a-zA-Z0-9@&]+$/
                          })
                        : value || '-'
            },
            {
                Header: 'Agent name',
                accessor: 'agent_name',
                isVisible: hideColumnList['Agent name'] === true || emptySelectionOfColumn || false,
                disableFilters: true,
                width: 200,
                Cell: ({ value, row, cell }) =>
                    enableEdit.id === row.original.id
                        ? EditableCell(value, 'text', row, cell, [], {
                              multiline: true,
                              maxRows: 4,
                              maxLength: 150,
                              custompattern: /^[a-zA-Z0-9@&()]+$/
                          })
                        : value || '-'
            },
            {
                Header: 'Price Per Sq Ft',
                headerColor: '#ffc107',
                width: 150,
                isVisible: hideColumnList['Price Per Sq Ft'] === true || emptySelectionOfColumn || false,
                accessor: 'Price Per Sq Ft',
                disableFilters: true,
                Cell: ({ value }) => numberWithCommas(value.toFixed(2)) || '-'
            },
            {
                Header: 'Mortgage Amount',
                headerColor: '#20c997',
                width: 150,
                isVisible: hideColumnList['Mortgage Amount'] === true || emptySelectionOfColumn || false,
                accessor: 'mortgageAmount',
                disableFilters: true,
                Cell: ({ value }) => numberWithCommas(value.toFixed(2)) || '-'
            },
            {
                Header: 'Estimated Mortgage Payment',
                headerColor: '#20c997',
                width: 150,
                isVisible: hideColumnList['Estimated Mortgage Payment'] === true || emptySelectionOfColumn || false,
                accessor: 'Estimated Mortgage Payment',
                disableFilters: true,
                Cell: ({ value }) => numberWithCommas(value.toFixed(2)) || '-'
            },
            {
                Header: 'Total Monthly Payment',
                headerColor: '#20c997',
                width: 150,
                isVisible: hideColumnList['Total Monthly Payment'] === true || emptySelectionOfColumn || false,
                accessor: 'Total Monthly Payment',
                disableFilters: true,
                Cell: ({ value }) => numberWithCommas(value.toFixed(2)) || '-'
            },
            {
                Header: 'Debt Ratio',
                headerColor: '#fd7e14',
                width: 150,
                isVisible: hideColumnList['Debt Ratio'] === true || emptySelectionOfColumn || false,
                accessor: 'Debt Ratio',
                disableFilters: true,
                Cell: ({ value }) => (value && numberWithCommas(value.toFixed(2))) || '-'
            },
            {
                Header: 'Price',
                accessor: 'price',
                isVisible: hideColumnList['Price'] === true || emptySelectionOfColumn || false,
                disableFilters: true,
                width: 200,
                Cell: ({ value, row, cell }) =>
                    enableEdit.id === row.original.id ? EditableCell(value, 'number', row, cell) : numberWithCommas(value.toFixed(2)) || '-'
            },
            {
                Header: 'Size (sq ft)',
                accessor: 'size',
                isVisible: hideColumnList['Size (sq ft)'] === true || emptySelectionOfColumn || false,
                disableFilters: true,
                width: 200,
                Cell: ({ value, row, cell }) =>
                    enableEdit.id === row.original.id
                        ? EditableCell(value, 'number', row, cell)
                        : numberWithCommas(Math.round(value)) || '-'
            },
            {
                Header: 'Listing status  (Active/Sold/In Contract)',
                accessor: 'listing_status',
                isVisible: hideColumnList['Listing status  (Active/Sold/In Contract)'] === true || emptySelectionOfColumn || false,
                disableFilters: true,
                width: 200,
                Cell: ({ value, row, cell }) => {
                    const option = [
                        { value: 'inContract', label: 'In Contract' },
                        { value: 'sold', label: 'Sold' },
                        { value: 'active', label: 'Active' }
                    ];
                    return enableEdit.id === row.original.id
                        ? EditableCell(value, 'select', row, cell, option)
                        : option.filter((val) => val.value === value?.toLowerCase())[0]?.label || '-';
                }
            },
            {
                Header: 'Beds',
                accessor: 'beds',
                isVisible: hideColumnList['Beds'] === true || emptySelectionOfColumn || false,
                disableFilters: true,
                width: 70,
                Cell: ({ value, row, cell }) => {
                    const option = numberOptions(10);
                    return enableEdit.id === row.original.id
                        ? EditableCell(value, 'select', row, cell, option)
                        : option.filter((val) => val.value === value)[0]?.label || '-';
                }
            },
            {
                Header: 'Baths',
                accessor: 'baths',
                isVisible: hideColumnList['Baths'] === true || emptySelectionOfColumn || false,
                disableFilters: true,
                width: 70,
                Cell: ({ value, row, cell }) => {
                    const option = numberOptions(10);
                    return enableEdit.id === row.original.id
                        ? EditableCell(value, 'select', row, cell, option)
                        : option.filter((val) => val.value === value)[0]?.label || '-';
                }
            },
            {
                Header: 'Year',
                accessor: 'year',
                isVisible: hideColumnList['Year'] === true || emptySelectionOfColumn || false,
                disableFilters: true,
                width: 100,
                Cell: ({ value, row, cell }) =>
                    enableEdit.id === row.original.id
                        ? EditableCell(value, 'number', row, cell, [], { commaSep: false, maxLength: 4 })
                        : value || '-'
            },
            {
                Header: 'Floor',
                accessor: 'floor',
                isVisible: hideColumnList['Floor'] === true || emptySelectionOfColumn || false,
                disableFilters: true,
                width: 100,
                Cell: ({ value, row, cell }) => {
                    const option = numberOptions(50, true);
                    return enableEdit.id === row.original.id
                        ? EditableCell(value, 'select', row, cell, option)
                        : option.filter((val) => val.value === value)[0]?.label || '-';
                }
            },
            {
                Header: 'Comments',
                accessor: 'comments',
                isVisible: hideColumnList['Comments'] === true || emptySelectionOfColumn || false,
                disableFilters: true,
                width: 100,
                Cell: ({ value, row, cell }) =>
                    enableEdit.id === row.original.id ? (
                        EditableCell(value, 'text', row, cell, [], {
                            multiline: true,
                            maxRows: 4,
                            maxLength: 150,
                            custompattern: /^([a-z0-9A-Z,-_@~`& ]{0,150})$/g
                        })
                    ) : value && limitWords(value, 5) !== value ? (
                        <CustomTooltip tooltipText={value} textTooltip={false} textLimit={5} align="left" />
                    ) : (
                        value || '-'
                    )
            },
            {
                Header: 'Monthly Fees, Taxes and Insurance',
                accessor: 'fees_tax_insu_monthly',
                isVisible: hideColumnList['Monthly Fees, Taxes and Insurance'] === true || emptySelectionOfColumn || false,
                disableFilters: true,
                width: 200,
                Cell: ({ value, row, cell }) =>
                    enableEdit.id === row.original.id ? EditableCell(value, 'number', row, cell) : numberWithCommas(value.toFixed(2)) || '-'
            },
            {
                Header: 'Condition',
                accessor: 'condition',
                isVisible: hideColumnList['Condition'] === true || emptySelectionOfColumn || false,
                disableFilters: true,
                width: 100,
                Cell: ({ value, row, cell }) => {
                    const option = numberOptions(10);
                    return enableEdit.id === row.original.id
                        ? EditableCell(value, 'select', row, cell, option)
                        : option.filter((val) => val.value === value)[0]?.label || '-';
                }
            },
            {
                Header: 'View',
                accessor: 'view',
                isVisible: hideColumnList['View'] === true || emptySelectionOfColumn || false,
                disableFilters: true,
                width: 100,
                Cell: ({ value, row, cell }) => {
                    const option = numberOptions(10);
                    return enableEdit.id === row.original.id
                        ? EditableCell(value, 'select', row, cell, option)
                        : option.filter((val) => val.value === value)[0]?.label || '-';
                }
            },
            {
                Header: 'Location',
                accessor: 'location',
                isVisible: hideColumnList['Location'] === true || emptySelectionOfColumn || false,
                disableFilters: true,
                width: 100,
                Cell: ({ value, row, cell }) => {
                    const option = numberOptions(10);
                    return enableEdit.id === row.original.id
                        ? EditableCell(value, 'select', row, cell, option)
                        : option.filter((val) => val.value === value)[0]?.label || '-';
                }
            },
            {
                Header: 'Buyer Score',
                headerColor: '#20c997',
                width: 150,
                isVisible: hideColumnList['Buyer Score'] === true || emptySelectionOfColumn || false,
                accessor: 'Buyer Score',
                disableFilters: true,
                Cell: ({ value, row, cell }) =>
                    enableEdit.id === row.original.id
                        ? EditableCell(value, 'number', row, cell, [], { commaSep: false, custompattern: /^[0-9]{0,6}$/g, maxLength: 2 })
                        : value || '-'
            },
            {
                Header: 'Viewing Status',
                accessor: 'viewing_status',
                isVisible: hideColumnList['Viewing Status'] === true || emptySelectionOfColumn || false,
                disableFilters: true,
                width: 100,
                Cell: ({ value, row, cell }) => {
                    const option = [
                        { value: 'viewed', label: 'Viewed' },
                        { value: 'scheduled', label: 'Scheduled' },
                        { value: 'na', label: 'NA' }
                    ];
                    return enableEdit.id === row.original.id
                        ? EditableCell(value, 'select', row, cell, option)
                        : option.filter((val) => val.value === value?.toLowerCase())[0]?.label || '-';
                }
            }
        ];
        let combinedList = [];
        if (isSharedData) {
            combinedList = [...list1, ...list3];
        } else {
            combinedList = [...list1, ...list2, ...list3];
        }

        // const result = result
        return combinedList;
    }, [enableEdit, updatedVal, getCookie('selected_columns_homepurch')]);

    // submit the value of assumption
    const handleSaveBtnAssumption = (from, resetData = null) => {
        const finalValue = resetData || assumptionVal;
        const fieldNames = { annualIncome: 'annualincome', downpaymentId: 'downpayment', mortgageRate: 'mortagage_rate' };
        if (Object.keys(finalValue).length > 0) {
            if (finalValue['unformattedVal' + from]) {
                if (finalValue['unformattedVal' + from].match(/^[0-9,]*?(\.[0-9]{1,2})?$/g)) {
                    dispatch(submitProfile({ [fieldNames[from]]: finalValue['unformattedVal' + from] || 0 }));
                    props.setInputRenderList(true);
                    handleCancelAssumption();
                } else {
                    return toast.error('Please enter numeric value.');
                }
            } else {
                return toast.error('Please enter data. It is required.');
            }
        }
    };
    //remove the comma
    const inputChangedHandler = (e) => e?.target?.value.replace(/,/g, '') || e?.toString().replace(/,/g, '') || '';

    // store the data when input field changes
    const handleAssumptionChange = (e) => {
        setAssuptionVal({
            ...assumptionVal,
            [`unformattedVal${e.target.id}`]: inputChangedHandler(e) || '',
            [e.target.id]: numberWithCommas(e.target.value)
        });
    };
    const updateFieldValues = () => {
        let loanData = 0;
        if (Object.keys(props.loanDashboardData.loanDashboardDetail).length) {
            loanData = props.loanDashboardData.loanDashboardDetail?.data[0]['30 YR Fixed Conforming Mtg']?.replace(/%/g, '');
            if (!isNaN(loanData) && loanData.toString().indexOf('.') !== -1) {
                loanData = Number(loanData).toFixed(2);
            }
        }
        const val = {
            unformattedValannualIncome: profile.annualincome,
            annualIncome: numberWithCommas(profile.annualincome) || 0,
            unformattedValmortgageRate: profile.mortagage_rate || loanData,
            mortgageRate: numberWithCommas(profile.mortagage_rate) || loanData || 0,
            unformattedValdownpaymentId: profile.downpayment,
            downpaymentId: numberWithCommas(profile.downpayment) || 0
        };
        setAssuptionVal(val);
    };
    //to reset value to default
    const handleResetToDefault = (from) => {
        let loanData = 0;
        if (Object.keys(props.loanDashboardData.loanDashboardDetail).length) {
            loanData = props.loanDashboardData.loanDashboardDetail?.data[0]['30 YR Fixed Conforming Mtg']?.replace(/%/g, '');
            if (!isNaN(loanData) && loanData.toString().indexOf('.') !== -1) {
                loanData = Number(loanData).toFixed(2);
            }
        }
        const objToPass = {
            ...assumptionVal,
            [`unformattedVal${from}`]: inputChangedHandler(loanData) || '',
            [from]: numberWithCommas(loanData)
        };
        setAssuptionVal(objToPass);
        handleSaveBtnAssumption(from, objToPass);
    };
    useEffect(() => {
        // assign the default assumption value when profile data is retrived
        if (profile) {
            updateFieldValues();
        }
    }, [profile, props.loanDashboardData]);

    // reset all flag and saved values when field editing will cancel
    const handleCancelAssumption = (from = null) => {
        updateFieldValues();
        setEnableEditAssumption({});
    };
    return (
        <>
            {!isSharedData && (
                <Grid container>
                    {
                        <AnnualInputWrapperGrid item xs>
                            <CustomInput
                                value={assumptionVal['annualIncome'] || ''}
                                id="annualIncome"
                                labelText="Income"
                                disabled={!enableEditAssumption['annualIncome']}
                                type="text"
                                inputProps={{ maxLength: 13 }}
                                CustomLabelProps={{ shrink: true }}
                                placeholder="Annual Income"
                                CustomInputProps={
                                    tooltip.default?.['Income']
                                        ? // tooltipText
                                          {
                                              endAdornment: (
                                                  <InputAdornment position="end">
                                                      <CustomTooltip tooltipText={tooltip.default?.['Income']} label={'Income'} />
                                                  </InputAdornment>
                                              )
                                          }
                                        : ''
                                }
                                onChange={(e) => handleAssumptionChange(e)}
                            />
                            {enableEditAssumption['annualIncome'] ? (
                                <div className={'actionWrapperAnnual'}>
                                    <Tooltip title="Cancel">
                                        <IconButton aria-label="cancel" onClick={(e) => handleCancelAssumption('annualIncome')}>
                                            <CloseIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Save">
                                        <IconButton aria-label="save" onClick={() => handleSaveBtnAssumption('annualIncome')}>
                                            <DoneIcon />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            ) : (
                                <div className={'actionWrapperAnnual'}>
                                    <Tooltip title="Edit">
                                        <IconButton
                                            aria-label="edit"
                                            onClick={() => {
                                                // if (Object.keys(enableEditAssumption).length === 0) {
                                                setEnableEditAssumption({
                                                    annualIncome: true
                                                    // ...enableEditAssumption,
                                                });
                                                updateFieldValues();
                                                // } else {
                                                //   return toast.error("Please save your data.");
                                                // }
                                            }}
                                        >
                                            <CreateIcon />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            )}
                        </AnnualInputWrapperGrid>
                    }
                    <AnnualInputWrapperGrid item xs>
                        <CustomInput
                            value={assumptionVal['downpaymentId'] || ''}
                            id="downpaymentId"
                            labelText="Down Payment"
                            disabled={!enableEditAssumption['downpaymentId']}
                            type="text"
                            CustomLabelProps={{ shrink: true }}
                            inputProps={{ maxLength: 13 }}
                            placeholder="Down Payment"
                            CustomInputProps={
                                tooltip.default?.['Down Payment']
                                    ? // tooltipText
                                      {
                                          endAdornment: (
                                              <InputAdornment position="end">
                                                  <CustomTooltip tooltipText={tooltip.default?.['Down Payment']} label={'Down Payment'} />
                                              </InputAdornment>
                                          )
                                      }
                                    : ''
                            }
                            onChange={(e) => handleAssumptionChange(e)}
                        />

                        {enableEditAssumption['downpaymentId'] ? (
                            <div className={'actionWrapperAnnual'}>
                                <Tooltip title="Cancel">
                                    <IconButton aria-label="cancel" onClick={(e) => handleCancelAssumption('downpaymentId')}>
                                        <CloseIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Save">
                                    <IconButton aria-label="save" onClick={() => handleSaveBtnAssumption('downpaymentId')}>
                                        <DoneIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        ) : (
                            <div className={'actionWrapperAnnual'}>
                                <Tooltip title="Edit">
                                    <IconButton
                                        aria-label="edit"
                                        onClick={() => {
                                            setEnableEditAssumption({
                                                downpaymentId: true
                                                // ...enableEditAssumption,
                                            });
                                            updateFieldValues();
                                        }}
                                    >
                                        <CreateIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        )}
                    </AnnualInputWrapperGrid>
                    <AnnualInputWrapperGrid item xs>
                        <CustomInput
                            value={assumptionVal['mortgageRate'] || ''}
                            CustomLabelProps={{ shrink: true }}
                            id="mortgageRate"
                            labelText="Mortgage Rate"
                            disabled={!enableEditAssumption['mortgageRate']}
                            type="text"
                            inputProps={{ maxLength: 13 }}
                            placeholder="Mortgage Rate"
                            CustomInputProps={
                                tooltip.default?.['Mortgage Rate']
                                    ? // tooltipText
                                      {
                                          endAdornment: (
                                              <InputAdornment position="end">
                                                  <CustomTooltip tooltipText={tooltip.default?.['Mortgage Rate']} label={'Mortgage Rate'} />
                                              </InputAdornment>
                                          )
                                      }
                                    : ''
                            }
                            onChange={(e) => handleAssumptionChange(e)}
                        />
                        {enableEditAssumption['mortgageRate'] ? (
                            <div className={'actionWrapperAnnual'}>
                                <Tooltip title="Cancel">
                                    <IconButton aria-label="cancel" onClick={(e) => handleCancelAssumption('mortgageRate')}>
                                        <CloseIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Save">
                                    <IconButton aria-label="save" onClick={() => handleSaveBtnAssumption('mortgageRate')}>
                                        <DoneIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        ) : (
                            <div className={'actionWrapperAnnual'}>
                                <Tooltip title="Edit">
                                    <IconButton
                                        aria-label="edit"
                                        onClick={() => {
                                            setEnableEditAssumption({
                                                mortgageRate: true
                                                // ...enableEditAssumption,
                                            });
                                            updateFieldValues();
                                        }}
                                    >
                                        <CreateIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Reset">
                                    <IconButton
                                        aria-label="reset"
                                        onClick={() => {
                                            handleResetToDefault('mortgageRate');
                                        }}
                                    >
                                        <RestoreIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        )}
                    </AnnualInputWrapperGrid>
                    <Grid item xs="auto">
                        <Box ref={container} />
                    </Grid>
                </Grid>
            )}
            <HomePurchaseViewPopup handleClose={handleClose} viewData={viewData} data={fundData} />
            <form onSubmit={handleSubmit(onSubmit)}>
                <EnhancedTable
                    searchcontainer={props.searchcontainer}
                    enableSearch={true}
                    columns={columns}
                    container={container}
                    data={tableData}
                    enableSort={true}
                />
            </form>
        </>
    );
};
